export const CLASS_TITLES = [
  'Amazon',
  'Assassin',
  'Barbarian',
  'Druid',
  'Necromancer',
  'Paladin',
  'Sorceress',
  'Act 1 Mercenary',
  'Act 2 Mercenary',
  'Act 3 Mercenary',
  'Act 5 Mercenary',
];
export const BREAKPOINT_DATA = [
  [
    // amazon - 0
    [
      'Faster Hit Recovery',
      '0% : 11 frames',
      '6% : 10 frames',
      '13% : 9 frames',
      '20% : 8 frames',
      '32% : 7 frames',
      '52% : 6 frames',
      '86% : 5 frames',
      '174% : 4 frames',
      '600% : 3 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 19 frames',
      '7% : 18 frames',
      '14% : 17 frames',
      '22% : 16 frames',
      '32% : 15 frames',
      '48% : 14 frames',
      '68% : 13 frames',
      '99% : 12 frames',
      '152% : 11 frames',
    ],
    [
      'Faster Block Rate (Hit by 1 or 2 handed weapon wielded in 1 hand)',
      '0% : 17 frames',
      '4% : 16 frames',
      '6% : 15 frames',
      '11% : 14 frames',
      '15% : 13 frames',
      '23% : 12 frames',
      '29% : 11 frames',
      '40% : 10 frames',
      '56% : 9 frames',
      '80% : 8 frames',
      '120% : 7 frames',
      '200% : 6 frames',
      '480% : 5 frames',
    ],
    ['Faster Block Rate (Hit by any other weapon)', '0% 5 frames', '13% : 4 frames', '32% : 3 frames', '86% : 2 frames', '600% : 1 frame'],
  ],
  [
    // Assassin - 1
    [
      'Faster Hit Recovery',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
      '4680% : 2 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 16 frames',
      '8% 15 frames',
      '16% : 14 frames',
      '27% : 13 frames',
      '42% : 12 frames',
      '65% : 11 frames',
      '102% : 10 frames',
      '174% : 9 frames',
    ],
    ['Faster Block Rate', '0% : 5 frames', '13% : 4 frames', '32% : 3 frames', '86% : 2 frames', '600% : 1 frame'],
  ],
  [
    // Barbarian - 2
    [
      'Faster Hit Recovery',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
      '4680% : 2 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 13 frames',
      '9% : 12 frames',
      '20% : 11 frames',
      '37% : 10 frames',
      '63% : 9 frames',
      '105% : 8 frames',
      '200% : 7 frames',
    ],
    ['Faster Block Rate', '0% : 7 frames', '9% : 6 frames', '20% : 5 frames', '42% : 4 frames', '86% : 3 frames', '280% : 2 frames'],
  ],
  [
    // Druid -3
    [
      'Faster Hit Recovery (Hit by 1 or 2 handed weapon wielded in 1 hand)',
      '0% : 14 frames',
      '3% : 13 frames',
      '7% : 12 frames',
      '13% : 11 frames',
      '19% : 10 frames',
      '29% : 9 frames',
      '42% : 8 frames',
      '63% : 7 frames',
      '99% : 6 frames',
      '174% : 5 frames',
      '456% : 4 frames',
    ],
    [
      'Faster Hit Recovery (Hit by any other weapon',
      '0% : 13 frames',
      '5% : 12 frames',
      '10% : 11 frames',
      '16% : 10 frames',
      '26% : 9 frames',
      '39% : 8 frames',
      '56% : 7 frames',
      '86% : 6 frames',
      '152% : 5 frames',
      '377% : 4 frames',
    ],
    [
      'Faster Hit Recovery (Wolf)',
      '0% : 7 frames',
      '9% : 6 frames',
      '20% : 5 frames',
      '42% : 4 frames',
      '86% : 3 frames',
      '280% : 2 frames',
    ],
    [
      'Faster Hit Recovery (Bear)',
      '0% : 13 frames',
      '5% : 12 frames',
      '10% : 11 frames',
      '16% : 10 frames',
      '24% : 9 frames',
      '37% : 8 frames',
      '54% : 7 frames',
      '86% : 6 frames',
      '152% : 5 frames',
      '360% : 4 frames',
    ],
    [
      'Faster Cast Rate (Human)',
      '0% : 18 frames',
      '4% : 17 frames',
      '10% : 16 frames',
      '19% : 15 frames',
      '30% : 14 frames',
      '46% : 13 frames',
      '68% : 12 frames',
      '99% : 11 frames',
      '163% : 10 frames',
    ],
    [
      'Faster Cast Rate (Wolf)',
      '0% : 16 frames',
      '6% : 15 frames',
      '14% : 14 frames',
      '26% : 13 frames',
      '40% : 12 frames',
      '60% : 11 frames',
      '95% : 10 frames',
      '157% : 9 frames',
    ],
    [
      'Faster Cast Rate (Bear)',
      '0% : 16 frames',
      '7% : 15 frames',
      '15% : 14 frames',
      '26% : 13 frames',
      '40% : 12 frames',
      '63% : 11 frames',
      '99% : 10 frames',
      '163% : 9 frames',
    ],
    [
      'Faster Block Rate (Human)',
      '0% : 11 frames',
      '6% : 10 frames',
      '13% : 9 frames',
      '20% : 8 frames',
      '32% : 7 frames',
      '52% : 6 frames',
      '86% : 5 frames',
      '174% : 4 frames',
      '600% : 3 frames',
    ],
    [
      'Faster Block Rate (Wolf)',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
    ],
    [
      'Faster Block Rate (Bear)',
      '0% : 12 frames',
      '5% : 11 frames',
      '10% : 10 frames',
      '16% : 9 frames',
      '27% : 8 frames',
      '40% : 7 frames',
      '65% : 6 frames',
      '109% : 5 frames',
      '223% : 4 frames',
    ],
  ],
  [
    // Necromancer - 4
    [
      'Faster Hit Recovery',
      '0% : 13 frames',
      '5% : 12 frames',
      '10% : 11 frames',
      '16% : 10 frames',
      '26% : 9 frames',
      '39% : 8 frames',
      '56% : 7 frames',
      '86% : 6 frames',
      '152% : 5 frames',
      '377% : 4 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 15 frames',
      '9% : 14 frames',
      '18% : 13 frames',
      '30% : 12 frames',
      '48% : 11 frames',
      '75% : 10 frames',
      '125% : 9 frames',
    ],
    [
      'Faster Cast Rate (Vampire)',
      '0% : 23 frames',
      '6% : 22 frames',
      '11% : 21 frame',
      '18% : 20 frames',
      '24% : 19 frames',
      '35% : 18 frames',
      '48% : 17 frames',
      '65% : 16 frames',
      '86% : 15 frames',
      '120% : 14 frames',
      '180% : 13 frames',
    ],
    [
      'Faster Block Rate',
      '0% : 11 frames',
      '6% : 10 frames',
      '13% : 9 frames',
      '20% : 8 frames',
      '32% : 7 frames',
      '52% : 6 frames',
      '86% : 5 frames',
      '174% : 4 frames',
      '600% : 3 frames',
    ],
  ],
  [
    // Paladin - 5
    [
      'Faster Hit Recovery',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
      '4680% : 2 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 15 frames',
      '9% : 14 frames',
      '18% : 13 frames',
      '30% : 12 frames',
      '48% : 11 frames',
      '75% : 10 frames',
      '125% : 9 frames',
    ],
    ['Faster Block Rate (With Holy Shield)', '0% : 2 frames', '86% : 1 frame'],
    ['Faster Block Rate (Without Holy Shield)', '0% : 5 frames', '13% : 4 frames', '32% : 3 frames', '86% : 2 frames', '600% : 1 frame'],
  ],
  [
    // Sorceress - 6
    [
      'Faster Hit Recovery',
      '0% : 15 frames',
      '5% : 14 frames',
      '9% : 13 frames',
      '14% : 12 frames',
      '20% : 11 frames',
      '30% : 10 frames',
      '42% : 9 frames',
      '60% : 8 frames',
      '86% : 7 frames',
      '142% : 6 frames',
      '280% : 5 frames',
      '1480% : 4 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 13 frames',
      '9% : 12 frames',
      '20% : 11 frames',
      '37% : 10 frames',
      '63% : 9 frames',
      '105% : 8 frames',
      '200% : 7 frames',
    ],
    [
      'Faster Cast Rate (Lightning / Chain Lightning)',
      '0% : 19 frames',
      '7% : 18 frames',
      '15% : 17 frames',
      '23% : 16 frames',
      '35% : 15 frames',
      '52% : 14 frames',
      '78% : 13 frames',
      '117% : 12 frames',
      '194% : 11 frames',
    ],
    [
      'Faster Block Rate',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
      '4680% : 2 frames',
    ],
  ],
  [
    // Act 1 Mercenary - 7
    [
      'Faster Hit Recovery',
      '0% : 11 frames',
      '6% : 10 frames',
      '13% : 9 frames',
      '20% : 8 frames',
      '32% : 7 frames',
      '52% : 6 frames',
      '86% : 5 frames',
      '174% : 4 frames',
      '600% : 3 frames',
    ],
  ],
  [
    // Act 2 Mercenary - 8
    [
      'Faster Hit Recovery',
      '0% : 15 frames',
      '5% : 14 frames',
      '9% 13 frames',
      '14% : 12 frames',
      '20% : 11 frames',
      '30% : 10 frames',
      '42% : 9 frames',
      '60% : 8 frames',
      '86% : 7 frames',
      '142% : 6 frames',
      '280% : 5 frames',
      '1480% : 4 frames',
    ],
  ],
  [
    // Act 3 Mercenary - 9
    [
      'Faster Hit Recovery',
      '0% : 17 frames',
      '5% : 16 frames',
      '8% : 15 frames',
      '13% : 14 frames',
      '18% : 13 frames',
      '24% : 12 frames',
      '32% : 11 frames',
      '46% : 10 frames',
      '63% : 9 frames',
      '86% : 8 frames',
      '133% : 7 frames',
      '232% : 6 frames',
      '600% : 5 frames',
    ],
    [
      'Faster Cast Rate',
      '0% : 17 frames',
      '8% : 16 frames',
      '15% : 15 frames',
      '26% : 14 frames',
      '39% : 13 frames',
      '58% : 12 frames',
      '86% : 11 frames',
      '138% : 10 frames',
    ],
  ],
  [
    // Act 5 Mercenary - 10
    [
      'Faster Hit Recovery',
      '0% : 9 frames',
      '7% : 8 frames',
      '15% : 7 frames',
      '27% : 6 frames',
      '48% : 5 frames',
      '86% : 4 frames',
      '200% : 3 frames',
      '4680% : 2 frames',
    ],
  ],
];

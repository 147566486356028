import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { Runeword } from '../../common/types';
import { findRunewords } from './calculator';
import { useAppSelector } from '../../store/store';
import { selectorRunewords } from '../../store/activeData/selector';
import styled from 'styled-components/macro';
import { ViewRuneword } from '../../common/components/ViewRuneword/ViewRuneword';
import { RuneContext } from '../../common/providers/RuneProvider/RuneProvider';

export const RunewordCalcResults: React.FC = () => {
  const { section: defaultSection } = useParams<{ section: 'can' | 'almost' }>();
  const { runes } = useContext(RuneContext);
  const allRunewords = useAppSelector(selectorRunewords);

  const [section, setSection] = useState(defaultSection);
  const [results, setResults] = useState<Runeword[][]>([[], []]);

  useEffect(() => {
    setResults(findRunewords(allRunewords, runes));
  }, [runes]);

  return (
    <AppPage padding={true} title={'Results'}>
      <IonSegment value={section} onIonChange={e => setSection(e.target.value as 'can' | 'almost')} mode={'md'}>
        <IonSegmentButton value={'can'}>
          <IonLabel color={'primary'}>Can make</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={'almost'}>
          <IonLabel color={'primary'}>Can almost make</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <Container>
        {results[section === 'can' ? 0 : 1].map(rw => (
          <ViewRuneword key={`rw-${rw.id}`} runeword={rw} />
        ))}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

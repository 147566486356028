import React from 'react';
import styled from 'styled-components/macro';
import { Rune } from '../../../common/components/Rune';
import { ALL_RUNES } from '../../../common/data/runes/all_runes';
import { useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';

export const RunesOverview: React.FC = () => {
  const { runes } = useAppSelector(selectorHomeSettings);
  const router = useIonRouter();
  return (
    <Container onClick={() => router.push(appRoutes.runewordCalc.path(), 'forward')}>
      {ALL_RUNES.map(r => (
        <Rune key={`home-rune-${r.id}`} id={r.id} size={`${runes.size}px`} withCount={runes.showCount} withName={runes.showName} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
`;

import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { GemImage } from '../../common/components/GemImage';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings } from '../../store/settings/selectors';

export const CubeItemRolling: React.FC = () => {
  const settings = useAppSelector(selectorCraftingSettings);
  const router = useIonRouter();
  return (
    <AppPage
      title={'Item Rolling'}
      padding={true}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
    >
      <Row>
        <div>
          Re-roll <span className={'rare'}>Rare Item</span>
        </div>
        <div>
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'skull'} size={'30px'} />
        </div>
        <div className={'notes'}>
          <sup>*</sup>Because this uses 6 skulls, the item must be 3x2 slots or smaller.
          <br /> <br />
          The ilvl of the item will decrease each roll, stabilising at ~67.5% of your clvl after 5 rolls. The formula is:
          <br /> <br />
          <span>(ilvl + clvl) x 0.4 = new ilvl </span>
        </div>
      </Row>

      <Row>
        <div>
          Re-roll <span className={'magic'}>Magic Item</span>
        </div>
        <div>
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'all'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'all'} size={'30px'} />
          <GemImage withName={settings.showGemNames} quality={'perfect'} type={'all'} size={'30px'} />
        </div>

        <div className='notes'>
          Can be used for jewels and charms.
          <br />
          iLvl will stay the same.
        </div>
      </Row>
    </AppPage>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 10px;
  color: white;
  padding: 20px 0;

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  > div.notes {
    color: var(--muted);
    font-size: 14px;
    text-align: center;
  }

  & + & {
    border-top: 1px solid var(--border-color);
  }
`;

import React from 'react';
import { SettingsPageOrder } from '../../store/settings/settingsSlice';
import { IonAccordion, IonIcon, IonItem, IonLabel, IonTabButton, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import {
  appsOutline,
  body,
  calculator,
  cubeSharp,
  cut,
  exit,
  folderOpen,
  gitMerge,
  hammerOutline,
  handLeft,
  helpOutline,
  homeOutline,
  refreshCircle,
  walkSharp,
} from 'ionicons/icons';
import { useAppSelector } from '../../store/store';
import { selectorPatch } from '../../store/settings/selectors';

interface Props {
  page: SettingsPageOrder;
  asTab?: boolean;
}

const AppLink: React.FC<Props> = ({ page, asTab }) => {
  const router = useIonRouter();
  const patch = useAppSelector(selectorPatch);

  if (page === 'Runewords') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.runewords.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.runewords.route ? 'primary' : 'light'} icon={'/images/icons/jah.svg'} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.runewords.path()} mode={'md'} lines={'none'}>
        <IonIcon icon='/images/icons/jah.svg' slot={'start'} color={'light'} />
        <IonLabel>Runewords</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Runeword Calculator') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.runewordCalc.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.runewordCalc.route ? 'primary' : 'light'} icon={appsOutline} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.runewordCalc.path()} mode={'md'} lines={'none'}>
        <IonIcon icon={appsOutline} slot={'start'} color={'light'} />
        <IonLabel>Runeword Calculator</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Drop Calculator') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.dropCalc.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.dropCalc.route ? 'primary' : 'light'} icon={calculator} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} onClick={() => router.push(appRoutes.dropCalc.path(), 'forward')} mode={'md'} lines={'none'}>
        <IonIcon icon={calculator} slot={'start'} color={'light'} />
        <IonLabel>Drop Calculator</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Mules') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.mules.path(), 'root')}>
        <IonIcon
          color={location.pathname === appRoutes.mules.route ? 'primary' : 'light'}
          icon={'/images/icons/mule.svg'}
          style={{ transform: 'scaleX(-1) rotate(-10deg)' }}
        />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.mules.path()} mode={'md'} lines={'none'}>
        <IonIcon icon='/images/icons/mule.svg' slot={'start'} style={{ transform: 'scaleX(-1) rotate(-10deg)' }} color={'light'} />
        <IonLabel>Mules</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Run/Walk Calculator') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.runWalkCalc.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.runewordCalc.route ? 'primary' : 'light'} icon={walkSharp} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.runWalkCalc.path()} mode={'md'} lines={'none'}>
        <IonIcon icon={walkSharp} slot={'start'} color={'light'} />
        <IonLabel>Run/Walk Calculator</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Item Catalog') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.runWalkCalc.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.runewordCalc.route ? 'primary' : 'light'} icon={folderOpen} />
      </IonTabButton>
    ) : (
      <IonAccordion value={'Item Catalog'}>
        <IonItem slot={'header'} color={'light'} lines={'none'} mode={'md'}>
          <IonIcon icon={folderOpen} slot={'start'} color={'light'} />
          <IonLabel>Item Catalog</IonLabel>
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.catalog.children.uniqueTypes.path()}
        >
          <IonIcon icon={'/images/icons/unique.svg'} slot={'start'} color={'light'} />
          Uniques
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.catalog.children.setTypes.path()}
        >
          <IonIcon icon={'/images/icons/set.svg'} slot={'start'} color={'light'} />
          Sets
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.catalog.children.baseTypes.path()}
        >
          <IonIcon icon={body} slot={'start'} color={'light'} />
          Bases
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.catalog.children.runes.path()}
        >
          <IonIcon icon={'/images/icons/jah.svg'} slot={'start'} color={'light'} />
          Runes
        </IonItem>
      </IonAccordion>
    );
  }

  if (page === 'Attack Calculator') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.attackCalc.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.attackCalc.route ? 'primary' : 'light'} icon={'/images/icons/attack.svg'} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.attackCalc.path()} mode={'md'} lines={'none'}>
        <IonIcon icon='/images/icons/attack.svg' slot={'start'} color={'light'} />
        <IonLabel>Attack Calculator</IonLabel>
      </IonItem>
    );
  }

  if (page === 'App Info') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.appInfo.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.appInfo.route ? 'primary' : 'light'} icon={helpOutline} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.appInfo.path()} mode={'md'} lines={'none'}>
        <IonIcon icon={helpOutline} slot={'start'} color={'light'} />
        <IonLabel>App Info</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Terror Zones') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.terrorZones.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.terrorZones.route ? 'primary' : 'light'} src={'/images/icons/terrorzone.svg'} />
      </IonTabButton>
    ) : (
      <>
        {patch >= 2 && (
          <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.terrorZones.path()} mode={'md'} lines={'none'}>
            <IonIcon icon='/images/icons/terrorzone.svg' slot={'start'} color={'light'} />
            <IonLabel>Terror Zones</IonLabel>
          </IonItem>
        )}
      </>
    );
  }

  if (page === 'Home Page') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.home.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={homeOutline} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} href={'#'} routerLink={appRoutes.home.path()} mode={'md'} lines={'none'}>
        <IonIcon icon={homeOutline} slot={'start'} color={'light'} />
        <IonLabel>Home Page</IonLabel>
      </IonItem>
    );
  }

  if (page === 'Horadric Cube') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.home.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={cubeSharp} />
      </IonTabButton>
    ) : (
      <IonAccordion value='Horadric Cube'>
        <IonItem slot='header' color='light' lines={'none'} mode={'md'}>
          <IonIcon icon={cubeSharp} slot={'start'} color={'light'} />
          <IonLabel>Horadric Cube</IonLabel>
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.runeCombining.path()}
        >
          <IonIcon icon='/images/icons/odal.svg' slot={'start'} color={'light'} />
          Rune Combining
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.socketRolling.path()}
        >
          <IonIcon icon={exit} slot={'start'} color={'light'} />
          Socket Rolling
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.baseUpgrading.path()}
        >
          <IonIcon icon='/images/icons/armor-upgrade.svg' slot={'start'} color={'light'} />
          Base Upgrading
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.utility.path()}
        >
          <IonIcon icon={hammerOutline} slot={'start'} color={'light'} />
          Utility Recipes
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.crafting.path()}
        >
          <IonIcon icon={cut} slot={'start'} color={'light'} />
          Crafting
        </IonItem>
        <IonItem
          className='ion-padding-start'
          slot='content'
          lines={'none'}
          mode={'md'}
          routerLink={appRoutes.cube.children.itemRolling.path()}
        >
          <IonIcon icon={refreshCircle} slot={'start'} color={'light'} />
          Item Rolling
        </IonItem>
      </IonAccordion>
    );
  }

  if (page === 'Breakpoints') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.home.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={handLeft} />
      </IonTabButton>
    ) : (
      <IonAccordion value={'Breakpoints'}>
        <IonItem slot={'header'} color={'light'} lines={'none'} mode={'md'}>
          <IonIcon icon={handLeft} slot={'start'} color={'light'} />
          <IonLabel>Breakpoints</IonLabel>
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(0)}>
          <IonIcon icon={'/images/icons/amazon.svg'} slot={'start'} color={'light'} />
          Amazon
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(1)}>
          <IonIcon icon={'/images/icons/assassin.svg'} slot={'start'} color={'light'} />
          Assassin
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(2)}>
          <IonIcon icon={'/images/icons/barbarian.svg'} slot={'start'} color={'light'} />
          Barbarian
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(3)}>
          <IonIcon icon={'/images/icons/druid.svg'} slot={'start'} color={'light'} />
          Druid
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(4)}>
          <IonIcon icon={'/images/icons/necromancer.svg'} slot={'start'} color={'light'} />
          Necromancer
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(5)}>
          <IonIcon icon={'/images/icons/paladin.svg'} slot={'start'} color={'light'} />
          Paladin
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(6)}>
          <IonIcon icon={'/images/icons/sorceress.svg'} slot={'start'} color={'light'} />
          Sorceress
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.breakpoints.path(7)}>
          <IonIcon icon={'/images/icons/merc.svg'} slot={'start'} color={'light'} />
          Mercenaries
        </IonItem>
      </IonAccordion>
    );
  }

  if (page === 'Areas') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.home.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={gitMerge} />
      </IonTabButton>
    ) : (
      <IonAccordion value={'Areas'}>
        <IonItem slot={'header'} color={'light'} mode={'md'} lines={'none'}>
          <IonIcon icon={gitMerge} slot={'start'} color={'light'} />
          <IonLabel>Areas</IonLabel>
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path(1)}>
          Act 1
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path(2)}>
          Act 2
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path(3)}>
          Act 3
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path(4)}>
          Act 4
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path(5)}>
          Act 5
        </IonItem>
        <IonItem className='ion-padding-start' slot='content' lines={'none'} mode={'md'} routerLink={appRoutes.area.path('all')}>
          All Acts
        </IonItem>
      </IonAccordion>
    );
  }

  if (page === 'Holy Grail') {
    return asTab ? (
      <IonTabButton tab={'tab1'} onClick={() => router.push(appRoutes.home.path(), 'root')}>
        <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={'/images/icons/grail.svg'} />
      </IonTabButton>
    ) : (
      <IonItem style={{ background: '#222' }} onClick={() => router.push(appRoutes.holyGrail.path(), 'forward')} lines={'none'} mode={'md'}>
        <IonIcon icon={'/images/icons/grail.svg'} slot={'start'} color={'light'} />
        <IonLabel>Holy Grail</IonLabel>
      </IonItem>
    );
  }
  return null;
};
AppLink.displayName = 'AppLink';
export default React.memo(AppLink);

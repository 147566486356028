import React, { useContext, useMemo, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonSearchbar, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorSetBonuses, selectorSets } from '../../store/activeData/selector';
import { ViewSet } from '../../common/components/ViewSet/ViewSet';
import { HolyGraiLContext } from '../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { settingsOutline } from 'ionicons/icons';
import { selectorItemCatalogSettings } from '../../store/settings/selectors';

export const SetCatalog: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const setItems = useAppSelector(selectorSets);
  const bonuses = useAppSelector(selectorSetBonuses);
  const { grailSets } = useContext(HolyGraiLContext);
  const showGrailProgress = useAppSelector(selectorItemCatalogSettings).showSetGrailProgress;
  const router = useIonRouter();

  const searchResults = useMemo(() => {
    if (!searchTerm) return undefined;

    const resultIds: number[] = [];

    for (const item of setItems) {
      if (item.name.toLowerCase().includes(searchTerm)) {
        resultIds.push(item.id);
        continue;
      }
      for (const prop of item.props) {
        if (prop.toLowerCase().includes(searchTerm)) {
          resultIds.push(item.id);
          break;
        }
      }
    }
    return resultIds;
  }, [searchTerm, setItems, bonuses]);

  const calcSetCompletion = (setId: number) => {
    if (!showGrailProgress) return null;
    const all = setItems.filter(s => s.set_id === setId);
    const found = all.reduce((total, current) => (grailSets.some(s => s.item_id === current.id) ? total + 1 : total), 0);
    return `(${found}/${all.length})`;
  };

  return (
    <AppPage
      padding={true}
      navBar={true}
      title={'Set Items'}
      headerContent={
        <IonSearchbar
          animated={true}
          placeholder={'Search anything...'}
          mode={'ios'}
          color={'medium'}
          debounce={200}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSearchTerm(e.detail?.value.toLowerCase() ?? ('' as string));
          }}
        />
      }
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '20px' }} onClick={() => router.push(appRoutes.settings.path('item-catalog'))} />
      }
    >
      {!searchTerm && (
        <>
          <IonList lines={'none'} inset>
            <IonListHeader className={'set no-inset'}>LOD Sets</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(26, 'Set', "M'avina's Battle Hymn")}>
              <IonLabel>
                M&apos;avina&apos;s Battle Hymn <span className={'set f-14'}>{calcSetCompletion(26)}</span>
              </IonLabel>
              <div>Amazon Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(28, 'Set', "Natalya's Odium")}>
              <IonLabel>
                Natalya&apos;s Odium <span className={'set f-14'}>{calcSetCompletion(28)}</span>
              </IonLabel>
              <div>Assassin Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(25, 'Set', 'Immortal King')}>
              <IonLabel>
                Immortal King <span className={'set f-14'}>{calcSetCompletion(25)}</span>
              </IonLabel>
              <div>Barbarian Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(17, 'Set', "Bul-Kathos' Children")}>
              <IonLabel>
                Bul-Kathos&apos; Children <span className={'set f-14'}>{calcSetCompletion(17)}</span>
              </IonLabel>
              <div>Barbarian Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(16, 'Set', "Aldur's Watchtower")}>
              <IonLabel>
                Aldur&apos;s Watchtower <span className={'set f-14'}>{calcSetCompletion(16)}</span>
              </IonLabel>
              <div>Druid Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(33, 'Set', "Trang-Oul's Avatar")}>
              <IonLabel>
                Trang-Oul&apos;s Avatar <span className={'set f-14'}>{calcSetCompletion(33)}</span>
              </IonLabel>
              <div>Necromancer Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(22, 'Set', "Griswold's Legacy")}>
              <IonLabel>
                Griswold&apos;s Legacy <span className={'set f-14'}>{calcSetCompletion(22)}</span>
              </IonLabel>
              <div>Paladin Set</div>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(32, 'Set', "Tal Rasha's Wrappings")}>
              <IonLabel>
                Tal Rasha&apos;s Wrappings <span className={'set f-14'}>{calcSetCompletion(32)}</span>
              </IonLabel>
              <div>Sorceress Set</div>
            </IonItem>

            <IonItem routerLink={appRoutes.catalog.children.view.path(18, 'Set', "Cow King's Leathers")}>
              <IonLabel>
                Cow King&apos;s Leathers <span className={'set f-14'}>{calcSetCompletion(18)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(21, 'Set', 'The Disciple')}>
              <IonLabel>
                The Disciple <span className={'set f-14'}>{calcSetCompletion(21)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(23, 'Set', "Heaven's Brethren")}>
              <IonLabel>
                Heaven&apos;s Brethren <span className={'set f-14'}>{calcSetCompletion(23)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(24, 'Set', "Hwanin's Majesty")}>
              <IonLabel>
                Hwanin&apos;s Majesty <span className={'set f-14'}>{calcSetCompletion(24)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(29, 'Set', "Orphan's Call")}>
              <IonLabel>
                Orphan&apos;s Call <span className={'set f-14'}>{calcSetCompletion(29)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(27, 'Set', "Naj's Ancient Vestige")}>
              <IonLabel>
                Naj&apos;s Ancient Vestige <span className={'set f-14'}>{calcSetCompletion(27)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(30, 'Set', "Sander's Folly")}>
              <IonLabel>
                Sander&apos;s Folly <span className={'set f-14'}>{calcSetCompletion(30)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(31, 'Set', "Sazabi's Grand Tribute")}>
              <IonLabel>
                Sazabi&apos;s Grand Tribute <span className={'set f-14'}>{calcSetCompletion(31)}</span>
              </IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'set no-inset'}>Classic Sets</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(2, 'Set', 'Arctic Gear')}>
              <IonLabel>
                Arctic Gear <span className={'set f-14'}>{calcSetCompletion(2)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(15, 'Set', "Vidala's Rig")}>
              <IonLabel>
                Vidala&apos;s Rig <span className={'set f-14'}>{calcSetCompletion(15)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(1, 'Set', "Arcanna's Tricks")}>
              <IonLabel>
                Arcanna&apos;s Tricks <span className={'set f-14'}>{calcSetCompletion(1)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(3, 'Set', "Berserker's Arsenal")}>
              <IonLabel>
                Berserker&apos;s Arsenal <span className={'set f-14'}>{calcSetCompletion(3)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(9, 'Set', 'Infernal Tools')}>
              <IonLabel>
                Infernal Tools <span className={'set f-14'}>{calcSetCompletion(9)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(12, 'Set', "Milabrega's Regalia")}>
              <IonLabel>
                Milabrega&apos;s Regalia <span className={'set f-14'}>{calcSetCompletion(12)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(4, 'Set', "Cathan's Traps")}>
              <IonLabel>
                Cathan&apos;s Traps <span className={'set f-14'}>{calcSetCompletion(4)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(0, 'Set', 'Angelic Raiment')}>
              <IonLabel>
                Angelic Raiment <span className={'set f-14'}>{calcSetCompletion(0)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(5, 'Set', "Civerb's Vestments")}>
              <IonLabel>
                Civerb&apos;s Vestments <span className={'set f-14'}>{calcSetCompletion(5)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(6, 'Set', "Cleglaw's Brace")}>
              <IonLabel>
                Cleglaw&apos;s Brace <span className={'set f-14'}>{calcSetCompletion(6)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(7, 'Set', "Death's Disguise")}>
              <IonLabel>
                Death&apos; Disguise <span className={'set f-14'}>{calcSetCompletion(7)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(8, 'Set', "Hsarus' Defense")}>
              <IonLabel>
                Hsarus&apos; Defense <span className={'set f-14'}>{calcSetCompletion(8)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(10, 'Set', "Iratha's Finery")}>
              <IonLabel>
                Iratha&apos;s Finery <span className={'set f-14'}>{calcSetCompletion(10)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(11, 'Set', "Isenhart's Armory")}>
              <IonLabel>
                Isenhart&apos;s Armory <span className={'set f-14'}>{calcSetCompletion(11)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(13, 'Set', "Sigon's Complete Steel")}>
              <IonLabel>
                Sigon&apos;s Complete Steel <span className={'set f-14'}>{calcSetCompletion(13)}</span>
              </IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(14, 'Set', "Tancred's Battlegear")}>
              <IonLabel>
                Tancred&apos;s Battlegear <span className={'set f-14'}>{calcSetCompletion(14)}</span>
              </IonLabel>
            </IonItem>
          </IonList>
        </>
      )}

      {searchTerm && searchResults && (
        <>
          {searchResults.map((r, i) => (
            <ViewSet key={`search-item-${i}`} itemId={r} />
          ))}
        </>
      )}
    </AppPage>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import {
  CREATE_ITEM_TABLE,
  CREATE_ITEM_TABLE_INDEXES,
  DROP_ITEMS_TABLE,
  EXECUTE_DELETE_ITEMS_BY_ID,
  EXECUTE_DELETE_ITEMS_BY_ITEM_IDS,
  INSERT_ITEM,
  QUERY_ALL_ITEMS,
  QUERY_FIND_ITEM,
  QUERY_FIND_ITEMS_BY_IDS,
  QUERY_FIND_RECENT_ITEMS,
  QUERY_ITEMS_BY_MULE_ID,
  QUERY_SEARCH_ITEMS,
  SELECT_ALL_ITEMS,
  UPDATE_ITEM_NAME,
  UPDATE_MULE_ID,
} from './sql_utils';
import { useAppDispatch } from '../../../store/store';
import { setOpenAddMuleModal } from '../../../store/app/appSlice';
import { sqlite } from '../../../App';
import { MuleContext } from '../MuleProvider/MuleProvider'; // export type ItemType = 'unique' | 'set' | 'runeword' | 'rune' | 'rare' | 'magic' | 'base' | 'misc' | 'gem';

// export type ItemType = 'unique' | 'set' | 'runeword' | 'rune' | 'rare' | 'magic' | 'base' | 'misc' | 'gem';
export const itemTypes = ['unique', 'set', 'runeword', 'rune', 'rare', 'magic', 'base_armor', 'base_weapon', 'misc', 'gem'] as const;
export type ItemType = (typeof itemTypes)[number];

export interface StorageItem {
  id: number;
  mule_id: number | null;
  mule_name: string | null;
  item_type: ItemType;
  item_id: number | null;
  name: string | null;
  base_id: number | null;
  base_name: string | null;
  ethereal: boolean;
  sockets: number | null;
  props: string | null;
}

interface ItemStorage {
  ready: boolean;
  items: () => Promise<StorageItem[]>;
  addItem: (item: StorageItem) => Promise<void>;
  openAddItem: (type?: ItemType, id?: number) => void;
  getItemsByMuleId: (muleId: number) => Promise<StorageItem[]>;
  findItem: (type: ItemType, item_id: number) => Promise<StorageItem[]>;
  findItemsByIds: (ids: number[]) => Promise<StorageItem[]>;
  selectRecentItems: (count: number) => Promise<StorageItem[]>;
  deleteItemsByItemIds: (item_ids: number[]) => Promise<void>;
  deleteItemById: (id: number) => Promise<void>;
  searchItems: (term: string) => Promise<StorageItem[]>;
  hasItems: () => Promise<boolean>;
  updateItemName: (item: StorageItem) => Promise<void>;
  getAllItems: () => Promise<StorageItem[]>;
  resetItemsTable: () => Promise<void>;
  transferMule: (id: number, newMuleId: number) => Promise<void>;
}

export const ItemContext = React.createContext<ItemStorage>({} as ItemStorage);

export const ItemProvider: React.FC<{
  db: SQLiteDBConnection | undefined;
  isWeb: boolean;
  children: React.ReactNode;
}> = ({ db, isWeb, children }) => {
  const [initialised, setInitialised] = useState(false); // Not ready until tables initialised and mule provider is ready
  const [ready, setReady] = useState(false);
  const { queryMules, ready: mulesReady } = useContext(MuleContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (db) {
      void initializeTables();
    }
  }, [db]);

  const initializeTables = async () => {
    if (db) {
      await db.open();
      const exists = await db.isTable('items');
      if (!exists.result) {
        await db.execute(CREATE_ITEM_TABLE);

        await db.execute(CREATE_ITEM_TABLE_INDEXES);
      }
      setInitialised(true);
    }
  };

  useEffect(() => {
    if (initialised && mulesReady) {
      setReady(true);
    }
  }, [initialised, mulesReady]);

  useEffect(() => {
    console.log('--ready--', ready);
  }, [ready]);

  const queryAllItems = async (): Promise<StorageItem[]> => {
    const res = await db?.query(QUERY_ALL_ITEMS);
    console.log(res);
    return res?.values ?? [];
  };

  const openAddItem = (type?: ItemType, id?: number) => {
    dispatch(setOpenAddMuleModal(type && id ? { type, id } : true));
  };

  const addItem = async (item: StorageItem) => {
    setReady(false);
    try {
      await db?.run(INSERT_ITEM, [
        item.mule_id,
        item.item_type,
        item.item_id,
        item.name,
        item.base_id,
        item.base_name,
        item.ethereal ? 1 : 0,
        item.sockets,
        item.props,
      ]);
      if (isWeb) {
        await sqlite.saveToStore('td2');
      }
      queryMules();
    } catch {
      return Promise.reject();
    } finally {
      // forces refetch of items
      setReady(true);
    }
  };

  const findItem = async (type: ItemType, item_id: number): Promise<StorageItem[]> => {
    const res = await db?.query(QUERY_FIND_ITEM(type, item_id));
    if (res?.values?.length) console.log(res?.values);
    return res?.values ?? [];
  };

  const getItemsByMuleId = async (muleId: number): Promise<StorageItem[]> => {
    const res = await db?.query(QUERY_ITEMS_BY_MULE_ID(muleId));
    return res?.values ?? [];
  };

  const findItemsByIds = async (ids: number[]) => {
    const res = await db?.query(QUERY_FIND_ITEMS_BY_IDS(ids));
    return res?.values ?? [];
  };

  const selectRecentItems = async (count: number): Promise<StorageItem[]> => {
    const res = await db?.query(QUERY_FIND_RECENT_ITEMS(count));
    return res?.values ?? [];
  };

  const deleteItemsByItemIds = async (item_ids: number[]) => {
    await db?.execute(EXECUTE_DELETE_ITEMS_BY_ITEM_IDS(item_ids));
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
    queryMules();
  };

  const deleteItemById = async (id: number) => {
    await db?.execute(EXECUTE_DELETE_ITEMS_BY_ID(id));
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
    queryMules();
  };

  const searchItems = async (term: string) => {
    term = `%${term}%`;
    const res = await db?.query(QUERY_SEARCH_ITEMS, [term, term]);
    return res?.values ?? [];
  };

  const hasItems = async () => {
    return !!(await selectRecentItems(1)).length;
  };

  const updateItemName = async (item: StorageItem) => {
    try {
      await db?.run(UPDATE_ITEM_NAME(item.id, item.name ?? ''));
      if (isWeb) {
        await sqlite.saveToStore('td2');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getAllItems = async (): Promise<StorageItem[]> => {
    try {
      const res = await db?.query(SELECT_ALL_ITEMS);
      return res?.values ?? [];
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  const transferMule = async (id: number, newMuleId: number) => {
    setReady(false);
    try {
      await db?.run(UPDATE_MULE_ID(id, newMuleId));
    } catch (e) {
      console.error(e);
    } finally {
      setReady(true);
    }
  };

  const resetItemsTable = async () => {
    await db?.execute(DROP_ITEMS_TABLE);
    await initializeTables();
  };

  return (
    <ItemContext.Provider
      value={{
        ready,
        items: queryAllItems,
        addItem,
        openAddItem,
        getItemsByMuleId,
        findItem,
        findItemsByIds,
        selectRecentItems,
        deleteItemsByItemIds,
        deleteItemById,
        searchItems,
        hasItems,
        updateItemName,
        getAllItems,
        transferMule,
        resetItemsTable,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/store';
import { selectorPlatform } from '../../store/app/selectors';
import { LocalStorage } from '../Storage';
import { AlertButton, useIonAlert } from '@ionic/react';
import { App } from '@capacitor/app';

interface Alert {
  forceQuit: true;
  header: string;
  subHeader?: string;
  message: string;
  date: string;
  id: string;
}

interface AlertResponse {
  apple: Alert;
  android: Alert;
}

const URL = 'http://alexanderjs.co.uk/emergency_alerts/td2_alert.json';

export const AlertService: React.FC = () => {
  const [alert, setAlert] = useState<AlertResponse>();
  const platform = useAppSelector(selectorPlatform);
  const [present] = useIonAlert();

  useEffect(() => {
    fetchAlert();
  }, []);

  useEffect(() => {
    if (platform && alert) {
      handleAlert();
    }
  }, [platform, alert]);

  const fetchAlert = async () => {
    try {
      const res = await fetch(URL, { cache: 'no-store' });

      if (!res.ok || res.status !== 200) return;

      const json = await res.json();

      setAlert(json);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAlert = () => {
    if (platform === 'ios' && alert!.apple && !LocalStorage.getHideAlert(alert!.apple.id)) {
      return presentAlert(alert!.apple);
    }
    if (platform === 'android' && alert!.android && !LocalStorage.getHideAlert(alert!.android.id)) {
      return presentAlert(alert!.android);
    }
  };

  const presentAlert = async (a: Alert) => {
    const buttons: AlertButton[] = [];

    if (a.forceQuit) {
      buttons.push({
        text: 'Ok',
        handler: () => App.exitApp(),
      });
    } else {
      buttons.push({
        text: 'Ok',
        role: 'cancel',
      });
      buttons.push({
        text: "Don't show again",
        role: 'destructive',
        handler: () => LocalStorage.setHideAlert(a.id),
      });
    }

    await present({
      header: a.header,
      subHeader: a.subHeader,
      message: a.message,
      buttons,
    });
  };

  return <div></div>;
};

import { ItemContext, ItemType, StorageItem } from './ItemProvider/ItemProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HolyGrail, HolyGraiLContext } from './HolyGrailProvider/HolyGrailProvider';
import { setOpenAddMuleModal, setSyncGrailItems } from '../../store/app/appSlice';
import { AlertButton, type AlertInput, useIonAlert, useIonToast } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectorSyncGrailItemStore } from '../../store/app/selectors';
import { RuneContext } from './RuneProvider/RuneProvider';
import { selectorSets, selectorUniques } from '../../store/activeData/selector';
import { Filter, SetItem, UniqueItem } from '../types';
import { LocalStorage, SessionsStorage } from '../Storage';
import { MuleContext } from './MuleProvider/MuleProvider';

export const useGrailItem = (item_type: ItemType, item_id?: number) => {
  const [togglePending, setTogglePending] = useState(false);
  const grail = useContext(HolyGraiLContext);
  const { deleteItemsByItemIds } = useContext(ItemContext);
  const syncGrailItemStore = useAppSelector(selectorSyncGrailItemStore);
  const [presentAlert] = useIonAlert();
  const dispatch = useAppDispatch();

  const grailItem = useMemo(() => {
    if (item_type === 'unique') return grail.grailUniques.find(item => item.item_id === item_id);
    if (item_type === 'set') return grail.grailSets.find(item => item.item_id === item_id);
  }, [grail.grailUniques, grail.grailSets, item_type, item_id]);

  useEffect(() => {
    if (togglePending && syncGrailItemStore !== null) {
      toggle();
    }
  }, [togglePending, syncGrailItemStore]);

  function toggle() {
    setTogglePending(false);

    if (syncGrailItemStore === undefined) {
      return presentSyncAlert();
    }

    if (!SessionsStorage.getDontPresentMuleOption()) {
      return presentMuleItemOption();
    }

    doItemToggle();
  }

  function doItemToggle() {
    if (item_id === undefined || (item_type !== 'unique' && item_type !== 'set')) return;

    const data: HolyGrail = {
      id: grailItem?.id ?? -1,
      item_type,
      item_id,
    };

    grail.toggleGrailItem(data);
  }

  const presentSyncAlert = async () => {
    await presentAlert({
      header: 'Include mule items in Holy Grail?',
      subHeader: 'You can change this setting later in the Holy Grail page',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            LocalStorage.setSyncGrailItemStore(true);
            dispatch(setSyncGrailItems(true));
            setTogglePending(true);
          },
        },
        {
          text: 'Not now',
          handler: () => {
            LocalStorage.setSyncGrailItemStore(false);
            dispatch(setSyncGrailItems(false));
            setTogglePending(true);
          },
        },
      ],
    });
  };

  const presentMuleItemOption = async () => {
    await presentAlert({
      header: 'Add as mule item instead?',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            dispatch(setOpenAddMuleModal({ type: item_type, id: item_id! }));
          },
        },
        {
          text: 'No',
          handler: () => {
            doItemToggle();
          },
        },
        {
          text: "No (don't ask again this session)",
          handler: () => {
            SessionsStorage.setDontPresentMuleOption();
            doItemToggle();
          },
        },
      ],
    });
  };

  const presentRemoveFromMules = async () => {
    if (syncGrailItemStore) {
      await presentAlert({
        message: 'Delete from mules too?',
        subHeader: 'This will remove all saved instances of this item',
        buttons: [
          {
            text: 'Delete',
            role: 'destructive',
            handler: () => {
              deleteItemsByItemIds([item_id!]);
            },
          },
          {
            text: 'Grail only',
          },
        ],
      });
    }
  };

  return { grailItem, toggle };
};

export const useStorageItems = (item_type: ItemType, item_id?: number) => {
  const [storageItems, setStorageItems] = useState<StorageItem[]>([]);
  const { ready, findItem } = useContext(ItemContext);

  useEffect(() => {
    if (ready && item_id !== undefined) findItem(item_type, item_id).then(setStorageItems);
  }, [item_type, ready, item_id]);

  return { storageItems };
};

export const useAddItem = () => {
  const { addItem } = useContext(ItemContext);
  const { incRune } = useContext(RuneContext);
  const { addGrailItem } = useContext(HolyGraiLContext);
  const [presentAlert] = useIonAlert();

  const addItemToDb = async (item: StorageItem) => {
    await addItem(item);

    const addToRc = LocalStorage.getAddMuleRunesToRc();
    if (item.item_type === 'rune' && addToRc === null) {
      return presentAddRuneToRuneCalc(item);
    }

    if (item.item_type === 'rune' && addToRc && item.item_id) {
      await incRune(item.item_id);
    }

    const syncGrailItems = LocalStorage.getSyncGrailItemStore();

    if (item.item_type === 'unique' || item.item_type === 'set') {
      if (syncGrailItems === null) {
        return presentAddItemToGrail(item.item_id!, item.item_type);
      } else if (syncGrailItems) {
        addGrailItem({ item_id: item.item_id!, item_type: item.item_type, id: -1 });
      }
    }
  };

  const presentAddRuneToRuneCalc = async (item: StorageItem) => {
    let action = '';
    await presentAlert({
      message: 'Add rune to Runeword Calculator runes?',
      buttons: [
        {
          text: 'Done',
          handler: () => {
            if (action === 'always') {
              LocalStorage.setAddMuleRunesToRc(true);
              incRune(item.item_id!);
            }
            if (action === 'never') {
              LocalStorage.setAddMuleRunesToRc(false);
            }
            if (action === 'this time') {
              incRune(item.item_id!);
            }
          },
        },
      ],
      inputs: [
        {
          label: 'Always',
          type: 'radio',
          handler: () => (action = 'always'),
        },
        {
          label: 'Never',
          type: 'radio',
          handler: () => (action = 'never'),
        },
        {
          label: 'Just this time',
          type: 'radio',
          handler: () => (action = 'this time'),
        },
        {
          label: 'Not this time',
          type: 'radio',
          handler: () => (action = 'not this time'),
        },
      ],
    });
  };

  const presentAddItemToGrail = async (item_id: number, type: Filter<ItemType, 'set' | 'unique'>) => {
    let action = '';
    await presentAlert({
      header: 'Add to Grail?',
      buttons: [
        {
          text: 'Done',
          handler: () => {
            if (action === 'always') {
              LocalStorage.setSyncGrailItemStore(true);
              addGrailItem({ item_id, item_type: type, id: -1 });
            }
            if (action === 'never') {
              LocalStorage.setSyncGrailItemStore(false);
            }
            if (action === 'this time') {
              addGrailItem({ item_id, item_type: type, id: -1 });
            }
          },
        },
      ],
      mode: 'ios',
      inputs: [
        {
          label: 'Always',
          type: 'radio',
          handler: () => (action = 'always'),
        },
        {
          label: 'Never',
          type: 'radio',
          handler: () => (action = 'never'),
        },
        {
          label: 'Just this time',
          type: 'radio',
          handler: () => (action = 'this time'),
        },
        {
          label: 'Not this time',
          type: 'radio',
          handler: () => (action = 'not this time'),
        },
      ],
    });
  };

  return addItemToDb;
};

export const useGrailUniques = () => {
  const { grailUniques } = useContext(HolyGraiLContext);
  const uniques = useAppSelector(selectorUniques);
  return useMemo(() => {
    let totalArmor = 0;
    let totalWeapons = 0;
    let totalOther = 0;
    const foundArmor: UniqueItem[] = [];
    const unFoundArmor: UniqueItem[] = [];
    const foundWeapons: UniqueItem[] = [];
    const unFoundWeapons: UniqueItem[] = [];
    const foundOther: UniqueItem[] = [];
    const unFoundOther: UniqueItem[] = [];

    for (const item of uniques) {
      if (item.category_id <= 1 || item.category_id === 6 || (item.category_id >= 18 && item.category_id <= 31)) {
        totalWeapons++;
        if (grailUniques.find(gi => gi.item_id === item.id)) {
          foundWeapons.push(item);
        } else {
          unFoundWeapons.push(item);
        }
        continue;
      }

      if ((item.category_id >= 2 && item.category_id <= 5) || (item.category_id >= 12 && item.category_id <= 17)) {
        totalArmor++;

        if (grailUniques.find(gi => gi.item_id === item.id)) {
          foundArmor.push(item);
        } else {
          unFoundArmor.push(item);
        }
        continue;
      }

      if (grailUniques.find(gi => gi.item_id === item.id)) {
        foundOther.push(item);
      } else {
        unFoundOther.push(item);
      }
      totalOther++;
    }

    return {
      totalArmor,
      totalWeapons,
      foundArmor,
      foundWeapons,
      totalOther,
      foundOther,
      unFoundWeapons,
      unFoundArmor,
      unFoundOther,
    };
  }, [uniques, grailUniques]);
};

export const useGrailSets = () => {
  const { grailSets } = useContext(HolyGraiLContext);
  const sets = useAppSelector(selectorSets);

  return useMemo(() => {
    let totalArmor = 0;
    let totalWeapons = 0;
    let totalOther = 0;
    const foundArmor: SetItem[] = [];
    const foundWeapons: SetItem[] = [];
    const foundOther: SetItem[] = [];
    const unFoundArmor: SetItem[] = [];
    const unFoundWeapons: SetItem[] = [];
    const unFoundOther: SetItem[] = [];

    for (const item of sets) {
      if (item.category_id <= 1 || item.category_id === 6 || (item.category_id >= 18 && item.category_id <= 30)) {
        totalWeapons++;
        if (grailSets.find(gi => gi.item_id === item.id)) {
          foundWeapons.push(item);
        } else {
          unFoundWeapons.push(item);
        }
        continue;
      }

      if ((item.category_id >= 2 && item.category_id <= 5) || (item.category_id >= 12 && item.category_id <= 17)) {
        totalArmor++;

        if (grailSets.find(gi => gi.item_id === item.id)) {
          foundArmor.push(item);
        } else {
          unFoundArmor.push(item);
        }
        continue;
      }

      if (grailSets.find(gi => gi.item_id === item.id)) {
        foundOther.push(item);
      } else {
        unFoundOther.push(item);
      }
      totalOther++;
    }

    return {
      totalArmor,
      totalWeapons,
      foundArmor,
      foundWeapons,
      totalOther,
      foundOther,
      unFoundArmor,
      unFoundWeapons,
      unFoundOther,
    };
  }, [sets, grailSets]);
};

export const useDeleteMuleItem = () => {
  const { deleteItemById } = useContext(ItemContext);
  const { isGrailItem, removeGrailItems } = useContext(HolyGraiLContext);
  const [alert] = useIonAlert();
  const [toast] = useIonToast();
  const deleteItem = async (item: StorageItem) => {
    return new Promise<void>((resolve, reject) => {
      const header = `Delete ${item.name}?`;
      let subHeader = '';
      let buttons: AlertButton[] = [
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => deleteFromMule(item, resolve),
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ];

      if ((item.item_type === 'unique' || item.item_type === 'set') && item.item_id) {
        isGrailItem(item.item_id).then(isGrail => {
          if (isGrail) {
            subHeader = `${item.name} is in your Holy Grail. Do you wish to remove it from your Holy Grail, too?`;
            buttons = [
              {
                text: 'From mule only',
                role: 'destructive',
                handler: () => deleteFromMule(item, resolve),
              },
              {
                text: 'Delete from both',
                role: 'destructive',
                handler: () => deleteFromGrail(item, resolve),
              },
              {
                text: 'Cancel',
                role: 'cancel',
              },
            ];
          }
          presentAlert(header, subHeader, buttons);
        });
      } else {
        presentAlert(header, subHeader, buttons);
      }
    });
  };

  const presentAlert = async (header: string, subHeader: string, buttons: AlertButton[]) => {
    await alert({ header, subHeader, buttons });
  };

  const deleteFromMule = async (item: StorageItem, resolve: () => void) => {
    await deleteItemById(item.id);
    await toast({ message: 'Item Deleted!', duration: 1500 });
    resolve();
  };

  const deleteFromGrail = async (item: StorageItem, resolve: () => void) => {
    if (item.item_type === 'unique' || item.item_type === 'set') {
      await removeGrailItems([{ id: -1, item_id: item.item_id!, item_type: item.item_type }]);
    }
    await deleteFromMule(item, resolve);
  };

  return deleteItem;
};

export const useTransferMule = (item: StorageItem) => {
  const { mules } = useContext(MuleContext);
  const { transferMule } = useContext(ItemContext);
  const [alert] = useIonAlert();

  return useCallback(() => {
    if (!mules.length || !transferMule || !item) return;

    let selectedMule: number | undefined;

    const radios: AlertInput[] = mules.map(mule => ({
      label: mule.mule_name,
      type: 'radio',
      checked: mule.mule_id == item.mule_id,
      handler: () => (selectedMule = mule.mule_id),
    }));

    const handleTransfer = async () => {
      if (selectedMule !== undefined) {
        await transferMule(item.id, selectedMule);
      }
    };

    alert({
      header: `Move ${item.name} from ${item.mule_name} to:`,
      inputs: radios,
      buttons: [{ text: 'OK', handler: handleTransfer }, { text: 'cancel' }],
    });
  }, [mules, transferMule, item]);
};

import React, { useContext, useEffect, useState } from 'react';
import { HomeSection } from '../../../store/settings/settingsSlice';
import { useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../../AppRoutes';
import { QuickSettings } from './QuickSettings';
import { SectionWrapper } from '../../../common/components/StyledComponents';
import { ItemContext } from '../../../common/providers/ItemProvider/ItemProvider';
import { RecentlyMuled } from './RecentlyMuled';
import { RunesOverview } from './RunesOverview';
import HgTable from '../../holy-grail/components/HGTable';
import HgAllTable from '../../holy-grail/components/HGAllTable';
import { useGrailSets, useGrailUniques } from '../../../common/providers/providerHooks';
import { LocalStorage } from '../../../common/Storage';
import { DropCalcHistory } from './DropCalcHistory';
import { FaveRunewords } from './FaveRunewords';

interface Props {
  section: HomeSection;
}

export const Section: React.FC<Props> = ({ section }) => {
  const [hasMuleItems, setHasMuleItems] = useState(false);
  const homeSettings = useAppSelector(selectorHomeSettings);
  const hgUniques = useGrailUniques();
  const hgSets = useGrailSets();
  const router = useIonRouter();

  const { ready, hasItems } = useContext(ItemContext);

  useEffect(() => {
    if (ready) hasItems().then(setHasMuleItems);
  }, [ready]);

  if (!homeSettings[section].show) return null;

  if (section === 'game')
    return (
      <SectionWrapper
        label={'Game Settings'}
        end={<IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('game'))} />}
      >
        <QuickSettings />
      </SectionWrapper>
    );

  if (section === 'mule' && hasMuleItems) {
    return (
      <SectionWrapper
        label={'Recently Muled'}
        end={<IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('mule'))} />}
      >
        <RecentlyMuled />
      </SectionWrapper>
    );
  }

  if (section === 'runes') {
    return (
      <SectionWrapper
        label={'Runes'}
        end={<IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('runes'))} />}
      >
        <RunesOverview />
      </SectionWrapper>
    );
  }

  if (section === 'grail') {
    return (
      <SectionWrapper
        label={'Holy Grail Progress'}
        end={<IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('grail'))} />}
      >
        {homeSettings.grail.showUniques && <HgTable {...hgUniques} type={'unique'} />}
        {homeSettings.grail.showSets && <HgTable {...hgSets} type={'set'} />}
        {homeSettings.grail.showSummary && <HgAllTable />}
      </SectionWrapper>
    );
  }

  if (section === 'dropCalc' && LocalStorage.getSavedDropCalcData()) {
    return (
      <SectionWrapper
        label={'Drop Calculator'}
        end={
          <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('dropCalc'))} />
        }
      >
        <DropCalcHistory />
      </SectionWrapper>
    );
  }

  if (section === 'faveRunewords' && LocalStorage.getAllFaveRunewords().length) {
    return (
      <SectionWrapper
        label={'Favorite Runewords'}
        end={
          <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('dropCalc'))} />
        }
      >
        <FaveRunewords />
      </SectionWrapper>
    );
  }

  return null;
};

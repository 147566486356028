import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArmorBase, DropCalcZone, Runeword, SetBonus, SetItem, UniqueItem, WeaponBase } from '../../common/types';
import { All_runewords_114d } from '../../common/data/runewords/all_runewords_1.14d';
import { All_runewords_24 } from '../../common/data/runewords/all_runewords_2.4';
import { All_runewords_26 } from '../../common/data/runewords/all_runewords_2.6';
import { WEAPON_BASES } from '../../common/data/base-items/weapon-bases';
import { ARMOR_BASES } from '../../common/data/base-items/armor-bases';
import { dropCalcZones } from '../../common/data/drop-calc/Zones';
import { dropCalcZones_24 } from '../../common/data/drop-calc/Zones_2.4';
import { AREA_LEVEL_DATA_114, IAreaLevel } from '../../common/data/areas/areas_114d';
import { AREA_LEVEL_DATA_24 } from '../../common/data/areas/areas_24';
import { UNIQUES } from '../../common/data/uniques/unqiues_114d';
import { UNIQUES_26 } from '../../common/data/uniques/uniques_2.6';
import { SET_BONUSES, SETS_114d } from '../../common/data/sets/sets_114d';
import { SET_BONUSES_24, SETS_24 } from '../../common/data/sets/sets_24';
import { TERROR_ZONES_26, TerrorZones } from '../../common/data/terror-zones/terror_zones_2.6';

interface activeDataState {
  runewords: Runeword[];
  weaponBases: WeaponBase[];
  armorBases: ArmorBase[];
  dropCalcZones: DropCalcZone;
  areas: IAreaLevel[];
  uniques: UniqueItem[];
  sets: SetItem[];
  setBonuses: SetBonus[];
  terrorZones?: TerrorZones;
}

const initialState: activeDataState = {
  runewords: [],
  weaponBases: [],
  armorBases: [],
  dropCalcZones: [],
  areas: [],
  uniques: [],
  sets: [],
  setBonuses: [],
};

const activeDataSlice = createSlice({
  name: 'activeDataSlice',
  initialState,
  reducers: {
    setRunewords: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
          state.runewords = All_runewords_114d;
          break;
        case 1:
          state.runewords = All_runewords_24.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 2:
          state.runewords = All_runewords_26.sort((a, b) => a.name.localeCompare(b.name));
          break;
        default:
          state.runewords = All_runewords_26.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    setWeaponBases: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
        case 1:
        case 2:
          state.weaponBases = WEAPON_BASES;
          break;
        default:
          state.weaponBases = WEAPON_BASES;
      }
    },
    setArmorBases: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
        case 1:
        case 2:
          state.armorBases = ARMOR_BASES;
          break;
        default:
          state.armorBases = ARMOR_BASES;
      }
    },
    setDropCalcZones: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
          state.dropCalcZones = dropCalcZones;
          break;
        case 1:
        case 2:
          state.dropCalcZones = dropCalcZones_24;
          break;
        default:
          state.dropCalcZones = dropCalcZones_24;
      }
    },
    setAreas: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
          state.areas = AREA_LEVEL_DATA_114;
          break;
        case 1:
        case 2:
          state.areas = AREA_LEVEL_DATA_24;
          break;
        default:
          state.areas = AREA_LEVEL_DATA_24;
      }
    },
    setUniques: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
        case 1:
          state.uniques = UNIQUES;
          break;
        case 2:
          state.uniques = UNIQUES_26;
          break;
        default:
          state.uniques = UNIQUES_26;
      }
    },
    setSets: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
          state.sets = SETS_114d;
          state.setBonuses = SET_BONUSES;
          break;
        case 1:
        case 2:
          state.sets = SETS_24;
          state.setBonuses = SET_BONUSES_24;
          break;
        default:
          state.sets = SETS_24;
          state.setBonuses = SET_BONUSES_24;
      }
    },
    setTerrorZones: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 2:
          state.terrorZones = TERROR_ZONES_26;
          break;
        default:
          state.terrorZones = undefined;
      }
    },
  },
});

export default activeDataSlice.reducer;
export const { setRunewords, setWeaponBases, setArmorBases, setDropCalcZones, setAreas, setUniques, setSets, setTerrorZones } =
  activeDataSlice.actions;

import React, { useRef } from 'react';
import { SettingsContainer } from '../Settings';
import { IonItem, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorItemCatalogSettings } from '../../../store/settings/selectors';
import { ItemCatalogSetting, setItemCatalogSetting } from '../../../store/settings/settingsSlice';
import { useScrollToContainerAndHighlight } from './useScrollToContainerAndHighlight';

export const ItemCatalogSettings: React.FC = () => {
  const settings = useAppSelector(selectorItemCatalogSettings);
  const dispatch = useAppDispatch();

  const self = useRef<HTMLDivElement>(null);
  const highlight = useScrollToContainerAndHighlight(self, 'item-catalog');

  const handleToggle = (key: keyof ItemCatalogSetting) => {
    dispatch(setItemCatalogSetting({ ...settings, [key]: !settings[key] }));
  };

  return (
    <SettingsContainer ref={self} highlight={highlight}>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showSetGrailProgress} onIonChange={() => handleToggle('showSetGrailProgress')}>
          Show Set Grail Progress
        </IonToggle>
      </IonItem>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showUniqueGrailProgress} onIonChange={() => handleToggle('showUniqueGrailProgress')}>
          Show Unique Grail Progress
        </IonToggle>
      </IonItem>
    </SettingsContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { SectionWrapper } from '../../common/components/StyledComponents';
import styled from 'styled-components/macro';
import { IonInput, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { ARMOR_BASES } from '../../common/data/base-items/armor-bases';

const baseWalkSpeed = 6;
const baseRunSpeed = 9;
export const RunWalkCalc: React.FC = () => {
  const [skill, setSkill] = useState(0);
  const [items, setItems] = useState(0);
  const [armour, setArmour] = useState(0);
  const [shield, setShield] = useState(0);
  const [walk, setWalk] = useState(0);
  const [run, setRun] = useState(0);

  useEffect(() => {
    calc();
  }, [skill, items, armour, shield]);

  const calc = () => {
    let resultWalk = baseWalkSpeed * (1 + skill / 100 + Math.floor((items * 150) / (items + 150)) / 100 + (armour + shield) / 100);
    if (resultWalk < baseWalkSpeed / 4) {
      resultWalk = baseWalkSpeed / 4;
    }

    let resultRun = resultWalk + 3;
    if (resultRun < baseWalkSpeed / 4) {
      resultRun = baseWalkSpeed / 4;
    }

    setWalk(Math.floor(resultWalk * 100) / 100);
    setRun(Math.floor(resultRun * 100) / 100);
  };

  return (
    <AppPage padding={true} title={'Run/Walk Calculator'}>
      <CalcContainer>
        <IonInput
          label={'FRW from skills'}
          type={'tel'}
          value={skill}
          className={'slim'}
          onIonChange={e => (e.detail.value?.length ? setSkill(parseInt(e.detail.value as string, 10)) : setSkill(0))}
        />
        <IonInput
          label={'FRW from items'}
          className={'slim'}
          type={'tel'}
          value={items}
          onIonChange={e => (e.detail.value?.length ? setItems(parseInt(e.detail.value as string, 10)) : setSkill(0))}
        />

        <IonSelect
          label={'Armor type: '}
          justify={'space-between'}
          value={armour}
          className={'slim'}
          onIonChange={e => setArmour(parseInt(e.detail.value, 10))}
          interface={'popover'}
        >
          <IonSelectOption value={0}>Light</IonSelectOption>
          <IonSelectOption value={-5}>Medium</IonSelectOption>
          <IonSelectOption value={-10}>Heavy</IonSelectOption>
        </IonSelect>

        <IonSelect
          label={'Shield type: '}
          justify={'space-between'}
          value={shield}
          className={'slim'}
          onIonChange={e => setShield(parseInt(e.detail.value, 10))}
          interface={'popover'}
        >
          <IonSelectOption value={0}>None / Light</IonSelectOption>
          <IonSelectOption value={-5}>Medium</IonSelectOption>
          <IonSelectOption value={-10}>Heavy</IonSelectOption>
        </IonSelect>

        <IonInput className={'slim'} label={'Walk Speed: '} value={`${walk} yrd/s`} readonly={true} />
        <IonInput className={'slim'} label={'Run Speed: '} value={`${run} yrd/s`} readonly={true} />
      </CalcContainer>

      <Info>
        Faster Run / Walk (FRW) is calculated in yards per second. Unlike attack speed or cast rate, there are no break points since
        it&apos;s not dependent on frames.
        <br />
        <br />
        One yard is 32 horizontal pixels and 16 vertical pixels.
        <br />
        <br />
        No matter how much you are slowed, the minimum movement speed is 1.5 yards per second.
      </Info>

      <Container>
        <SectionWrapper label={'Light Armors'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 1 && a.qualifiedType === 'Armors').map(a => (
              <div key={`light_arm_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>

        <SectionWrapper label={'Medium Armors'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 2 && a.qualifiedType === 'Armors').map(a => (
              <div key={`med_arm_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>

        <SectionWrapper label={'Heavy Armors'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 3 && a.qualifiedType === 'Armors').map(a => (
              <div key={`heavy_arm_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>

        <SectionWrapper label={'Light Shields'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 1 && a.qualifiedType === 'Shields').map(a => (
              <div key={`light_she_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>

        <SectionWrapper label={'Medium Shields'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 2 && a.qualifiedType === 'Shields').map(a => (
              <div key={`med_she_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>

        <SectionWrapper label={'Heavy Shields'}>
          <ArmorTypeContainer>
            {ARMOR_BASES.filter(a => a.armorType == 3 && a.qualifiedType === 'Shields').map(a => (
              <div key={`heavy_she_${a.id}`}>{a.name}</div>
            ))}
          </ArmorTypeContainer>
        </SectionWrapper>
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Info = styled.div`
  color: var(--muted);
  font-size: 13px;
`;

const CalcContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  margin-bottom: 30px;
`;

const ArmorTypeContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

import React, { useEffect } from 'react';
import { useIonModal } from '@ionic/react';
import { AddMuleItemBottomSheet } from '../../../pages/mules/components/AddMuleItemBottomSheet';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  selectorOpenAddItemModal,
  selectorPickItemModal,
  selectorViewMuleDialog,
  selectorViewMuledItemsDialog,
} from '../../../store/app/selectors';
import { setOpenAddMuleModal, setPickItemModal } from '../../../store/app/appSlice';
import { PickItemModal } from '../ItemSelectModal/PickItem';
import { DialogViewMuleItems } from './DialogViewMuleItems';
import { DialogViewMuledItems } from './DialogViewMuledItems';

export const ModalContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const openAddItem = useAppSelector(selectorOpenAddItemModal);
  const [presentAddItem, dismissAddItem] = useIonModal(AddMuleItemBottomSheet, {
    onDismiss: (data: any) => {
      console.log('dismiss');
      dismissAddItem();
      dispatch(setOpenAddMuleModal(false));
    },
    inputItem: typeof openAddItem !== 'boolean' ? openAddItem : undefined,
  });
  useEffect(() => {
    if (openAddItem) {
      presentAddItem({ ...modalOptions, onDidDismiss: () => dispatch(setOpenAddMuleModal(false)) });
    }
  }, [openAddItem]);

  const pickItem = useAppSelector(selectorPickItemModal);
  const [presentPickItem, dismissPickItem] = useIonModal(PickItemModal, {
    ...pickItem,
    onDismiss: () => {
      if (pickItem) {
        pickItem.onDismiss();
      }
      dismissPickItem();
    },
  });
  useEffect(() => {
    if (pickItem) {
      presentPickItem({
        initialBreakpoint: 0.6,
        breakpoints: [0.6],
        backdropDismiss: true,
        onDidDismiss: () => dispatch(setPickItemModal(undefined)),
      });
    } else {
      dismissPickItem();
    }
  }, [pickItem]);

  const viewMuleDialog = useAppSelector(selectorViewMuleDialog);
  if (viewMuleDialog) return <DialogViewMuleItems muleId={viewMuleDialog} />;

  const viewMuledItemsDialog = useAppSelector(selectorViewMuledItemsDialog);
  if (viewMuledItemsDialog) return <DialogViewMuledItems ids={viewMuledItemsDialog} />;

  return null;
};

const modalOptions = {
  breakpoints: [0, 0.5, 0.9, 1],
  initialBreakpoint: 0.9,
  cssClass: 'rounded-modal',
  backdropDismiss: true,
};

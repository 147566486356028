import { ArmorBase } from '../../types';

export const ARMOR_BASES: ArmorBase[] = [
  {
    name: 'Skull Cap',
    minDef: 8,
    maxDef: 11,
    reqStr: 15,
    durability: 18,
    sockets: 2,
    type: 'helm',
    quality: 5,
    filename: 'skullcap.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 0,
    item_type: 'base_armor',
  },
  {
    name: 'Helm',
    minDef: 15,
    maxDef: 18,
    reqStr: 26,
    durability: 24,
    sockets: 2,
    type: 'helm',
    quality: 11,
    filename: 'helm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 1,
    item_type: 'base_armor',
  },
  {
    name: 'Full Helm',
    minDef: 23,
    maxDef: 26,
    reqStr: 41,
    durability: 30,
    sockets: 2,
    type: 'helm',
    quality: 15,
    filename: 'fullhelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 2,
    item_type: 'base_armor',
  },
  {
    name: 'Great Helm',
    minDef: 30,
    maxDef: 35,
    reqStr: 63,
    durability: 40,
    sockets: 3,
    type: 'helm',
    quality: 23,
    filename: 'greathelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 3,
    item_type: 'base_armor',
  },
  {
    name: 'Crown',
    minDef: 25,
    maxDef: 45,
    reqStr: 55,
    durability: 50,
    sockets: 3,
    type: 'helm',
    quality: 29,
    filename: 'crown.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 4,
    item_type: 'base_armor',
  },
  {
    name: 'Mask',
    minDef: 9,
    maxDef: 27,
    reqStr: 23,
    durability: 20,
    sockets: 3,
    type: 'helm',
    quality: 19,
    filename: 'mask.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 5,
    item_type: 'base_armor',
  },
  {
    name: 'Quilted Armor',
    minDef: 8,
    maxDef: 11,
    reqStr: 12,
    durability: 20,
    sockets: 2,
    type: 'tors',
    quality: 1,
    filename: 'quilted.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 6,
    item_type: 'base_armor',
  },
  {
    name: 'Leather Armor',
    minDef: 14,
    maxDef: 17,
    reqStr: 15,
    durability: 24,
    sockets: 2,
    type: 'tors',
    quality: 3,
    filename: 'leatherarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 7,
    item_type: 'base_armor',
  },
  {
    name: 'Hard Leather Armor',
    minDef: 21,
    maxDef: 24,
    reqStr: 20,
    durability: 28,
    sockets: 2,
    type: 'tors',
    quality: 5,
    filename: 'hardleather.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 8,
    item_type: 'base_armor',
  },
  {
    name: 'Studded Leather ',
    minDef: 32,
    maxDef: 35,
    reqStr: 27,
    durability: 32,
    sockets: 2,
    type: 'tors',
    quality: 8,
    filename: 'studded.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    armorType: 1,
    id: 9,
    item_type: 'base_armor',
  },
  {
    name: 'Ring Mail',
    minDef: 45,
    maxDef: 48,
    reqStr: 36,
    durability: 26,
    sockets: 3,
    type: 'tors',
    quality: 11,
    filename: 'ringmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 10,
    item_type: 'base_armor',
  },
  {
    name: 'Scale Mail',
    minDef: 57,
    maxDef: 60,
    reqStr: 44,
    durability: 36,
    sockets: 2,
    type: 'tors',
    quality: 13,
    filename: 'scalemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 3,
    id: 11,
    item_type: 'base_armor',
  },
  {
    name: 'Chain Mail',
    minDef: 72,
    maxDef: 75,
    reqStr: 48,
    durability: 45,
    sockets: 2,
    type: 'tors',
    quality: 15,
    filename: 'chainmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 2,
    id: 12,
    item_type: 'base_armor',
  },
  {
    name: 'Breast Plate',
    minDef: 65,
    maxDef: 68,
    reqStr: 30,
    durability: 50,
    sockets: 3,
    type: 'tors',
    quality: 18,
    filename: 'breastplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 13,
    item_type: 'base_armor',
  },
  {
    name: 'Splint Mail',
    minDef: 90,
    maxDef: 95,
    reqStr: 51,
    durability: 30,
    sockets: 2,
    type: 'tors',
    quality: 20,
    filename: 'splintmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 2,
    id: 14,
    item_type: 'base_armor',
  },
  {
    name: 'Plate Mail',
    minDef: 108,
    maxDef: 116,
    reqStr: 65,
    durability: 60,
    sockets: 2,
    type: 'tors',
    quality: 24,
    filename: 'platemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 3,
    id: 15,
    item_type: 'base_armor',
  },
  {
    name: 'Field Plate',
    minDef: 101,
    maxDef: 105,
    reqStr: 55,
    durability: 48,
    sockets: 2,
    type: 'tors',
    quality: 28,
    filename: 'fieldplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 2,
    id: 16,
    item_type: 'base_armor',
  },
  {
    name: 'Gothic Plate',
    minDef: 128,
    maxDef: 135,
    reqStr: 70,
    durability: 55,
    sockets: 4,
    type: 'tors',
    quality: 32,
    filename: 'gothicplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 17,
    item_type: 'base_armor',
  },
  {
    name: 'Full Plate Mail',
    minDef: 150,
    maxDef: 161,
    reqStr: 80,
    durability: 70,
    sockets: 4,
    type: 'tors',
    quality: 37,
    filename: 'fullplatemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 3,
    id: 18,
    item_type: 'base_armor',
  },
  {
    name: 'Ancient Armor',
    minDef: 218,
    maxDef: 233,
    reqStr: 100,
    durability: 60,
    sockets: 4,
    type: 'tors',
    quality: 40,
    filename: 'ancientarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 19,
    item_type: 'base_armor',
  },
  {
    name: 'Light Plate',
    minDef: 90,
    maxDef: 107,
    reqStr: 41,
    durability: 60,
    sockets: 3,
    type: 'tors',
    quality: 35,
    filename: 'lightplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 20,
    item_type: 'base_armor',
  },
  {
    name: 'Buckler',
    minDef: 4,
    maxDef: 6,
    reqStr: 12,
    durability: 12,
    sockets: 1,
    type: 'shie',
    quality: 1,
    block: 0,
    filename: 'buckler.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    armorType: 1,
    id: 21,
    item_type: 'base_armor',
  },
  {
    name: 'Small Shield',
    minDef: 8,
    maxDef: 10,
    reqStr: 22,
    durability: 16,
    sockets: 2,
    type: 'shie',
    quality: 5,
    block: 5,
    filename: 'smallshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 22,
    item_type: 'base_armor',
  },
  {
    name: 'Large Shield',
    minDef: 12,
    maxDef: 14,
    reqStr: 34,
    durability: 24,
    sockets: 3,
    type: 'shie',
    quality: 11,
    block: 12,
    filename: 'largeshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 23,
    item_type: 'base_armor',
  },
  {
    name: 'Kite Shield',
    minDef: 16,
    maxDef: 18,
    reqStr: 47,
    durability: 30,
    sockets: 3,
    type: 'shie',
    quality: 15,
    block: 8,
    filename: 'kiteshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 24,
    item_type: 'base_armor',
  },
  {
    name: 'Tower Shield',
    minDef: 22,
    maxDef: 25,
    reqStr: 75,
    durability: 60,
    sockets: 3,
    type: 'shie',
    quality: 22,
    block: 24,
    filename: 'towershield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 3,
    id: 25,
    item_type: 'base_armor',
  },
  {
    name: 'Gothic Shield',
    minDef: 30,
    maxDef: 35,
    reqStr: 60,
    durability: 40,
    sockets: 3,
    type: 'shie',
    quality: 30,
    block: 16,
    filename: 'gothicshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 26,
    item_type: 'base_armor',
  },
  {
    name: 'Leather Gloves',
    minDef: 2,
    maxDef: 3,
    durability: 12,
    type: 'glov',
    quality: 3,
    filename: 'leathergloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 0,
    id: 27,
    item_type: 'base_armor',
  },
  {
    name: 'Heavy Gloves',
    minDef: 5,
    maxDef: 6,
    durability: 14,
    type: 'glov',
    quality: 7,
    filename: 'heavygloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 0,
    id: 28,
    item_type: 'base_armor',
  },
  {
    name: 'Chain Gloves',
    minDef: 8,
    maxDef: 9,
    reqStr: 25,
    durability: 16,
    type: 'glov',
    quality: 12,
    filename: 'chaingloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 0,
    id: 29,
    item_type: 'base_armor',
  },
  {
    name: 'Light Gauntlets',
    minDef: 9,
    maxDef: 11,
    reqStr: 45,
    durability: 18,
    type: 'glov',
    quality: 20,
    filename: 'lightgauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 0,
    id: 30,
    item_type: 'base_armor',
  },
  {
    name: 'Gauntlets',
    minDef: 12,
    maxDef: 15,
    reqStr: 60,
    durability: 24,
    type: 'glov',
    quality: 27,
    filename: 'gauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 0,
    id: 31,
    item_type: 'base_armor',
  },
  {
    name: 'Boots',
    minDef: 2,
    maxDef: 3,
    durability: 12,
    type: 'boot',
    quality: 3,
    filename: 'boots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 0,
    id: 32,
    item_type: 'base_armor',
  },
  {
    name: 'Heavy Boots',
    minDef: 5,
    maxDef: 6,
    reqStr: 18,
    durability: 14,
    type: 'boot',
    quality: 7,
    filename: 'heavyboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 0,
    id: 33,
    item_type: 'base_armor',
  },
  {
    name: 'Chain Boots',
    minDef: 8,
    maxDef: 9,
    reqStr: 30,
    durability: 16,
    type: 'boot',
    quality: 12,
    filename: 'chainboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 0,
    id: 34,
    item_type: 'base_armor',
  },
  {
    name: 'Light Plated Boots',
    minDef: 9,
    maxDef: 11,
    reqStr: 50,
    durability: 18,
    type: 'boot',
    quality: 20,
    filename: 'lightplatedboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 0,
    id: 35,
    item_type: 'base_armor',
  },
  {
    name: 'Greaves',
    minDef: 12,
    maxDef: 15,
    reqStr: 70,
    durability: 24,
    type: 'boot',
    quality: 27,
    filename: 'greaves.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 0,
    id: 36,
    item_type: 'base_armor',
  },
  {
    name: 'Sash',
    minDef: 2,
    maxDef: 2,
    durability: 12,
    type: 'belt',
    quality: 3,
    filename: 'sash.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 0,
    beltRows: 2,
    id: 37,
    item_type: 'base_armor',
  },
  {
    name: 'Light Belt',
    minDef: 3,
    maxDef: 3,
    durability: 14,
    type: 'belt',
    quality: 7,
    filename: 'lightbelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 0,
    beltRows: 2,
    id: 38,
    item_type: 'base_armor',
  },
  {
    name: 'Belt',
    minDef: 5,
    maxDef: 5,
    reqStr: 25,
    durability: 16,
    type: 'belt',
    quality: 12,
    filename: 'belt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 0,
    beltRows: 3,
    id: 39,
    item_type: 'base_armor',
  },
  {
    name: 'Heavy Belt',
    minDef: 6,
    maxDef: 6,
    reqStr: 45,
    durability: 18,
    type: 'belt',
    quality: 20,
    filename: 'heavybelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 0,
    beltRows: 3,
    id: 40,
    item_type: 'base_armor',
  },
  {
    name: 'Plated Belt',
    minDef: 8,
    maxDef: 11,
    reqStr: 60,
    durability: 24,
    type: 'belt',
    quality: 27,
    filename: 'girdle.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 0,
    beltRows: 4,
    id: 41,
    item_type: 'base_armor',
  },
  {
    name: 'Bone Helm',
    minDef: 33,
    maxDef: 36,
    reqStr: 25,
    durability: 40,
    sockets: 2,
    type: 'helm',
    quality: 22,
    filename: 'bonehelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 42,
    item_type: 'base_armor',
  },
  {
    name: 'Bone Shield',
    minDef: 10,
    maxDef: 30,
    reqStr: 25,
    durability: 40,
    sockets: 2,
    type: 'shie',
    quality: 19,
    block: 20,
    filename: 'boneshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 43,
    item_type: 'base_armor',
  },
  {
    name: 'Spiked Shield',
    minDef: 15,
    maxDef: 25,
    reqStr: 30,
    durability: 40,
    sockets: 2,
    type: 'shie',
    quality: 11,
    block: 10,
    filename: 'spikedshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 44,
    item_type: 'base_armor',
  },
  {
    name: 'War Hat',
    minDef: 45,
    maxDef: 53,
    reqStr: 20,
    durability: 12,
    sockets: 2,
    type: 'helm',
    reqLvl: 22,
    quality: 34,
    filename: 'cap.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 45,
    item_type: 'base_armor',
  },
  {
    name: 'Sallet',
    minDef: 52,
    maxDef: 62,
    reqStr: 43,
    durability: 18,
    sockets: 2,
    type: 'helm',
    reqLvl: 25,
    quality: 37,
    filename: 'skullcap.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 46,
    item_type: 'base_armor',
  },
  {
    name: 'Casque',
    minDef: 63,
    maxDef: 72,
    reqStr: 59,
    durability: 24,
    sockets: 2,
    type: 'helm',
    reqLvl: 25,
    quality: 42,
    filename: 'helm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 47,
    item_type: 'base_armor',
  },
  {
    name: 'Basinet',
    minDef: 75,
    maxDef: 84,
    reqStr: 82,
    durability: 30,
    sockets: 2,
    type: 'helm',
    reqLvl: 25,
    quality: 45,
    filename: 'fullhelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 48,
    item_type: 'base_armor',
  },
  {
    name: 'Winged Helm',
    minDef: 85,
    maxDef: 98,
    reqStr: 115,
    durability: 40,
    sockets: 3,
    type: 'helm',
    reqLvl: 25,
    quality: 51,
    filename: 'greathelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 49,
    item_type: 'base_armor',
  },
  {
    name: 'Grand Crown',
    minDef: 78,
    maxDef: 113,
    reqStr: 103,
    durability: 50,
    sockets: 3,
    type: 'helm',
    reqLvl: 25,
    quality: 55,
    filename: 'crown.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 50,
    item_type: 'base_armor',
  },
  {
    name: 'Death Mask',
    minDef: 54,
    maxDef: 86,
    reqStr: 55,
    durability: 20,
    sockets: 3,
    type: 'helm',
    reqLvl: 25,
    quality: 48,
    filename: 'mask.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 51,
    item_type: 'base_armor',
  },
  {
    name: 'Ghost Armor',
    minDef: 102,
    maxDef: 117,
    reqStr: 38,
    durability: 20,
    sockets: 2,
    type: 'tors',
    reqLvl: 22,
    quality: 34,
    filename: 'quilted.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 52,
    item_type: 'base_armor',
  },
  {
    name: 'Serpentskin Armor',
    minDef: 111,
    maxDef: 126,
    reqStr: 43,
    durability: 24,
    sockets: 2,
    type: 'tors',
    reqLvl: 24,
    quality: 36,
    filename: 'leatherarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 53,
    item_type: 'base_armor',
  },
  {
    name: 'Demonhide Armor',
    minDef: 122,
    maxDef: 136,
    reqStr: 50,
    durability: 28,
    sockets: 2,
    type: 'tors',
    reqLvl: 25,
    quality: 37,
    filename: 'hardleather.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 54,
    item_type: 'base_armor',
  },
  {
    name: 'Trellised Armor',
    minDef: 138,
    maxDef: 153,
    reqStr: 61,
    durability: 32,
    sockets: 2,
    type: 'tors',
    reqLvl: 25,
    quality: 40,
    filename: 'studded.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 55,
    item_type: 'base_armor',
  },
  {
    name: 'Linked Mail',
    minDef: 158,
    maxDef: 172,
    reqStr: 74,
    durability: 26,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 42,
    filename: 'ringmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 56,
    item_type: 'base_armor',
  },
  {
    name: 'Tigulated Mail',
    minDef: 176,
    maxDef: 190,
    reqStr: 86,
    durability: 36,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 43,
    filename: 'scalemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 3,
    id: 57,
    item_type: 'base_armor',
  },
  {
    name: 'Mesh Armor',
    minDef: 198,
    maxDef: 213,
    reqStr: 92,
    durability: 45,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 45,
    filename: 'chainmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 58,
    item_type: 'base_armor',
  },
  {
    name: 'Cuirass',
    minDef: 188,
    maxDef: 202,
    reqStr: 65,
    durability: 50,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 47,
    filename: 'breastplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 59,
    item_type: 'base_armor',
  },
  {
    name: 'Russet Armor',
    minDef: 225,
    maxDef: 243,
    reqStr: 97,
    durability: 30,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 49,
    filename: 'splintmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 60,
    item_type: 'base_armor',
  },
  {
    name: 'Templar Coat',
    minDef: 252,
    maxDef: 274,
    reqStr: 118,
    durability: 60,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 52,
    filename: 'platemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 3,
    id: 61,
    item_type: 'base_armor',
  },
  {
    name: 'Sharktooth Armor',
    minDef: 242,
    maxDef: 258,
    reqStr: 103,
    durability: 48,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 55,
    filename: 'fieldplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 62,
    item_type: 'base_armor',
  },
  {
    name: 'Embossed Plate',
    minDef: 282,
    maxDef: 303,
    reqStr: 125,
    durability: 55,
    sockets: 4,
    type: 'tors',
    reqLvl: 25,
    quality: 58,
    filename: 'gothicplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 63,
    item_type: 'base_armor',
  },
  {
    name: 'Chaos Armor',
    minDef: 315,
    maxDef: 342,
    reqStr: 140,
    durability: 70,
    sockets: 4,
    type: 'tors',
    reqLvl: 25,
    quality: 61,
    filename: 'fullplatemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 3,
    id: 64,
    item_type: 'base_armor',
  },
  {
    name: 'Ornate Plate',
    minDef: 417,
    maxDef: 450,
    reqStr: 170,
    durability: 60,
    sockets: 4,
    type: 'tors',
    reqLvl: 25,
    quality: 64,
    filename: 'ancientarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    armorType: 2,
    id: 65,
    item_type: 'base_armor',
  },
  {
    name: 'Mage Plate',
    minDef: 225,
    maxDef: 261,
    reqStr: 55,
    durability: 60,
    sockets: 3,
    type: 'tors',
    reqLvl: 25,
    quality: 60,
    filename: 'lightplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 66,
    item_type: 'base_armor',
  },
  {
    name: 'Defender',
    minDef: 41,
    maxDef: 49,
    reqStr: 38,
    durability: 68,
    sockets: 1,
    type: 'shie',
    reqLvl: 22,
    quality: 34,
    block: 10,
    filename: 'buckler.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    armorType: 1,
    id: 67,
    item_type: 'base_armor',
  },
  {
    name: 'Round Shield',
    minDef: 47,
    maxDef: 55,
    reqStr: 53,
    durability: 64,
    sockets: 2,
    type: 'shie',
    reqLvl: 25,
    quality: 37,
    block: 12,
    filename: 'smallshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 68,
    item_type: 'base_armor',
  },
  {
    name: 'Scutum',
    minDef: 53,
    maxDef: 61,
    reqStr: 71,
    durability: 62,
    sockets: 3,
    type: 'shie',
    reqLvl: 25,
    quality: 42,
    block: 14,
    filename: 'largeshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 69,
    item_type: 'base_armor',
  },
  {
    name: 'Dragon Shield',
    minDef: 59,
    maxDef: 67,
    reqStr: 91,
    durability: 76,
    sockets: 3,
    type: 'shie',
    reqLvl: 25,
    quality: 45,
    block: 18,
    filename: 'kiteshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 70,
    item_type: 'base_armor',
  },
  {
    name: 'Pavise',
    minDef: 68,
    maxDef: 78,
    reqStr: 133,
    durability: 72,
    sockets: 3,
    type: 'shie',
    reqLvl: 25,
    quality: 50,
    block: 24,
    filename: 'towershield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 3,
    id: 71,
    item_type: 'base_armor',
  },
  {
    name: 'Ancient Shield',
    minDef: 80,
    maxDef: 93,
    reqStr: 110,
    durability: 80,
    sockets: 3,
    type: 'shie',
    reqLvl: 25,
    quality: 56,
    block: 16,
    filename: 'gothicshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 72,
    item_type: 'base_armor',
  },
  {
    name: 'Demonhide Gloves',
    minDef: 28,
    maxDef: 35,
    reqStr: 20,
    durability: 12,
    type: 'glov',
    reqLvl: 21,
    quality: 33,
    filename: 'leathergloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 1,
    id: 73,
    item_type: 'base_armor',
  },
  {
    name: 'Sharkskin Gloves',
    minDef: 33,
    maxDef: 39,
    reqStr: 20,
    durability: 14,
    type: 'glov',
    reqLvl: 25,
    quality: 39,
    filename: 'heavygloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 1,
    id: 74,
    item_type: 'base_armor',
  },
  {
    name: 'Heavy Bracers',
    minDef: 37,
    maxDef: 44,
    reqStr: 58,
    durability: 16,
    type: 'glov',
    reqLvl: 25,
    quality: 43,
    filename: 'chaingloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 1,
    id: 75,
    item_type: 'base_armor',
  },
  {
    name: 'Battle Gauntlets',
    minDef: 39,
    maxDef: 47,
    reqStr: 88,
    durability: 18,
    type: 'glov',
    reqLvl: 25,
    quality: 49,
    filename: 'lightgauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 1,
    id: 76,
    item_type: 'base_armor',
  },
  {
    name: 'War Gauntlets',
    minDef: 43,
    maxDef: 53,
    reqStr: 110,
    durability: 24,
    type: 'glov',
    reqLvl: 25,
    quality: 54,
    filename: 'gauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 1,
    id: 77,
    item_type: 'base_armor',
  },
  {
    name: 'Demonhide Boots',
    minDef: 28,
    maxDef: 35,
    reqStr: 20,
    durability: 12,
    type: 'boot',
    reqLvl: 24,
    quality: 36,
    filename: 'boots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 1,
    id: 78,
    item_type: 'base_armor',
  },
  {
    name: 'Sharkskin Boots',
    minDef: 33,
    maxDef: 39,
    reqStr: 47,
    durability: 14,
    type: 'boot',
    reqLvl: 25,
    quality: 39,
    filename: 'heavyboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 1,
    id: 79,
    item_type: 'base_armor',
  },
  {
    name: 'Mesh Boots',
    minDef: 37,
    maxDef: 44,
    reqStr: 65,
    durability: 16,
    type: 'boot',
    reqLvl: 25,
    quality: 43,
    filename: 'chainboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 1,
    id: 80,
    item_type: 'base_armor',
  },
  {
    name: 'Battle Boots',
    minDef: 39,
    maxDef: 47,
    reqStr: 95,
    durability: 18,
    type: 'boot',
    reqLvl: 25,
    quality: 49,
    filename: 'lightplatedboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 1,
    id: 81,
    item_type: 'base_armor',
  },
  {
    name: 'War Boots',
    minDef: 43,
    maxDef: 53,
    reqStr: 125,
    durability: 24,
    type: 'boot',
    reqLvl: 25,
    quality: 54,
    filename: 'greaves.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 1,
    id: 82,
    item_type: 'base_armor',
  },
  {
    name: 'Demonhide Sash',
    minDef: 29,
    maxDef: 34,
    reqStr: 20,
    durability: 12,
    type: 'belt',
    reqLvl: 24,
    quality: 36,
    filename: 'sash.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 1,
    beltRows: 4,
    id: 83,
    item_type: 'base_armor',
  },
  {
    name: 'Sharkskin Belt',
    minDef: 31,
    maxDef: 36,
    reqStr: 20,
    durability: 14,
    type: 'belt',
    reqLvl: 25,
    quality: 39,
    filename: 'lightbelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 1,
    beltRows: 4,
    id: 84,
    item_type: 'base_armor',
  },
  {
    name: 'Mesh Belt',
    minDef: 35,
    maxDef: 40,
    reqStr: 58,
    durability: 16,
    type: 'belt',
    reqLvl: 25,
    quality: 43,
    filename: 'belt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 1,
    beltRows: 4,
    id: 85,
    item_type: 'base_armor',
  },
  {
    name: 'Battle Belt',
    minDef: 37,
    maxDef: 42,
    reqStr: 88,
    durability: 18,
    type: 'belt',
    reqLvl: 25,
    quality: 49,
    filename: 'heavybelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 1,
    beltRows: 4,
    id: 86,
    item_type: 'base_armor',
  },
  {
    name: 'War Belt',
    minDef: 41,
    maxDef: 52,
    reqStr: 110,
    durability: 24,
    type: 'belt',
    reqLvl: 25,
    quality: 54,
    filename: 'girdle.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 1,
    beltRows: 4,
    id: 87,
    item_type: 'base_armor',
  },
  {
    name: 'Grim Helm',
    minDef: 60,
    maxDef: 125,
    reqStr: 58,
    durability: 40,
    sockets: 2,
    type: 'helm',
    reqLvl: 25,
    quality: 50,
    filename: 'bonehelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 88,
    item_type: 'base_armor',
  },
  {
    name: 'Grim Shield',
    minDef: 50,
    maxDef: 150,
    reqStr: 58,
    durability: 70,
    sockets: 2,
    type: 'shie',
    reqLvl: 25,
    quality: 48,
    block: 20,
    filename: 'boneshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 89,
    item_type: 'base_armor',
  },
  {
    name: 'Barbed Shield',
    minDef: 58,
    maxDef: 78,
    reqStr: 65,
    durability: 55,
    sockets: 2,
    type: 'shie',
    reqLvl: 25,
    quality: 42,
    block: 17,
    filename: 'spikedshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 90,
    item_type: 'base_armor',
  },
  {
    name: 'Wolf Head',
    minDef: 8,
    maxDef: 11,
    reqStr: 16,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 3,
    quality: 4,
    filename: 'wolfhead.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 91,
    item_type: 'base_armor',
  },
  {
    name: 'Hawk Helm',
    minDef: 4,
    maxDef: 15,
    reqStr: 20,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 6,
    quality: 8,
    filename: 'hawkhelm.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 92,
    item_type: 'base_armor',
  },
  {
    name: 'Antlers',
    minDef: 18,
    maxDef: 24,
    reqStr: 24,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 12,
    quality: 16,
    filename: 'antlers.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 93,
    item_type: 'base_armor',
  },
  {
    name: 'Falcon Mask',
    minDef: 12,
    maxDef: 28,
    reqStr: 28,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 15,
    quality: 20,
    filename: 'falconmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 94,
    item_type: 'base_armor',
  },
  {
    name: 'Spirit Mask',
    minDef: 22,
    maxDef: 35,
    reqStr: 30,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 18,
    quality: 24,
    filename: 'spiritmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 95,
    item_type: 'base_armor',
  },
  {
    name: 'Jawbone Cap',
    minDef: 10,
    maxDef: 15,
    reqStr: 25,
    durability: 25,
    sockets: 3,
    type: 'phlm',
    reqLvl: 3,
    quality: 4,
    filename: 'jawbonecap.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 96,
    item_type: 'base_armor',
  },
  {
    name: 'Fanged Helm',
    minDef: 15,
    maxDef: 20,
    reqStr: 35,
    durability: 35,
    sockets: 3,
    type: 'phlm',
    reqLvl: 6,
    quality: 8,
    filename: 'fangedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 97,
    item_type: 'base_armor',
  },
  {
    name: 'Horned Helm',
    minDef: 25,
    maxDef: 30,
    reqStr: 45,
    durability: 45,
    sockets: 3,
    type: 'phlm',
    reqLvl: 12,
    quality: 16,
    filename: 'hornedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 98,
    item_type: 'base_armor',
  },
  {
    name: 'Assault Helmet',
    minDef: 30,
    maxDef: 35,
    reqStr: 55,
    durability: 50,
    sockets: 3,
    type: 'phlm',
    reqLvl: 15,
    quality: 20,
    filename: 'assualthelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 99,
    item_type: 'base_armor',
  },
  {
    name: 'Avenger Guard',
    minDef: 35,
    maxDef: 50,
    reqStr: 65,
    durability: 55,
    sockets: 3,
    type: 'phlm',
    reqLvl: 18,
    quality: 24,
    filename: 'avengerguard.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 100,
    item_type: 'base_armor',
  },
  {
    name: 'Targe',
    minDef: 8,
    maxDef: 12,
    reqStr: 16,
    durability: 20,
    sockets: 4,
    type: 'ashd',
    reqLvl: 3,
    quality: 4,
    block: 10,
    smiteMin: 2,
    smiteMax: 6,
    filename: 'targe.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 101,
    item_type: 'base_armor',
  },
  {
    name: 'Rondache',
    minDef: 10,
    maxDef: 18,
    reqStr: 26,
    durability: 30,
    sockets: 4,
    type: 'ashd',
    reqLvl: 6,
    quality: 8,
    block: 15,
    smiteMin: 2,
    smiteMax: 8,
    filename: 'rondache.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 102,
    item_type: 'base_armor',
  },
  {
    name: 'Heraldic Shield',
    minDef: 16,
    maxDef: 26,
    reqStr: 40,
    durability: 40,
    sockets: 4,
    type: 'ashd',
    reqLvl: 12,
    quality: 16,
    block: 20,
    smiteMin: 4,
    smiteMax: 10,
    filename: 'heraldicshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 103,
    item_type: 'base_armor',
  },
  {
    name: 'Aerin Shield',
    minDef: 26,
    maxDef: 36,
    reqStr: 50,
    durability: 50,
    sockets: 4,
    type: 'ashd',
    reqLvl: 15,
    quality: 20,
    block: 22,
    smiteMin: 4,
    smiteMax: 10,
    filename: 'aerinshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 104,
    item_type: 'base_armor',
  },
  {
    name: 'Crown Shield',
    minDef: 30,
    maxDef: 40,
    reqStr: 65,
    durability: 60,
    sockets: 4,
    type: 'ashd',
    reqLvl: 18,
    quality: 24,
    block: 25,
    smiteMin: 4,
    smiteMax: 12,
    filename: 'crownshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 105,
    item_type: 'base_armor',
  },
  {
    name: 'Preserved Head',
    minDef: 2,
    maxDef: 5,
    reqStr: 12,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 3,
    quality: 4,
    block: 3,
    filename: 'preservedhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 106,
    item_type: 'base_armor',
  },
  {
    name: 'Zombie Head',
    minDef: 4,
    maxDef: 8,
    reqStr: 14,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 6,
    quality: 8,
    block: 5,
    filename: 'zombiehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 107,
    item_type: 'base_armor',
  },
  {
    name: 'Unraveller Head',
    minDef: 6,
    maxDef: 10,
    reqStr: 18,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 12,
    quality: 16,
    block: 8,
    filename: 'unravellerhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 108,
    item_type: 'base_armor',
  },
  {
    name: 'Gargoyle Head',
    minDef: 10,
    maxDef: 16,
    reqStr: 20,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 15,
    quality: 20,
    block: 10,
    filename: 'gargoylehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 109,
    item_type: 'base_armor',
  },
  {
    name: 'Demon Head',
    minDef: 15,
    maxDef: 20,
    reqStr: 25,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 18,
    quality: 24,
    block: 12,
    filename: 'demonhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 110,
    item_type: 'base_armor',
  },
  {
    name: 'Circlet',
    minDef: 20,
    maxDef: 30,
    durability: 35,
    sockets: 2,
    type: 'circ',
    reqLvl: 16,
    quality: 24,
    filename: 'circlet.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 111,
    item_type: 'base_armor',
  },
  {
    name: 'Coronet',
    minDef: 30,
    maxDef: 40,
    durability: 30,
    sockets: 2,
    type: 'circ',
    reqLvl: 39,
    quality: 52,
    filename: 'coronet.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 1,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 112,
    item_type: 'base_armor',
  },
  {
    name: 'Tiara',
    minDef: 40,
    maxDef: 50,
    durability: 25,
    sockets: 3,
    type: 'circ',
    reqLvl: 52,
    quality: 70,
    filename: 'tiara.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 1,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 113,
    item_type: 'base_armor',
  },
  {
    name: 'Diadem',
    minDef: 50,
    maxDef: 60,
    durability: 20,
    sockets: 3,
    type: 'circ',
    reqLvl: 64,
    quality: 85,
    filename: 'diadem.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 1,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 114,
    item_type: 'base_armor',
  },
  {
    name: 'Shako',
    minDef: 98,
    maxDef: 141,
    reqStr: 50,
    durability: 12,
    sockets: 2,
    type: 'helm',
    reqLvl: 43,
    quality: 58,
    filename: 'cap.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 115,
    item_type: 'base_armor',
  },
  {
    name: 'Hydraskull',
    minDef: 101,
    maxDef: 145,
    reqStr: 84,
    durability: 18,
    sockets: 2,
    type: 'helm',
    reqLvl: 47,
    quality: 63,
    filename: 'skullcap.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 116,
    item_type: 'base_armor',
  },
  {
    name: 'Armet',
    minDef: 105,
    maxDef: 149,
    reqStr: 109,
    durability: 24,
    sockets: 2,
    type: 'helm',
    reqLvl: 51,
    quality: 68,
    filename: 'helm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 117,
    item_type: 'base_armor',
  },
  {
    name: 'Giant Conch',
    minDef: 110,
    maxDef: 154,
    reqStr: 142,
    durability: 30,
    sockets: 2,
    type: 'helm',
    reqLvl: 40,
    quality: 54,
    filename: 'fullhelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 118,
    item_type: 'base_armor',
  },
  {
    name: 'Spired Helm',
    minDef: 114,
    maxDef: 159,
    reqStr: 192,
    durability: 40,
    sockets: 3,
    type: 'helm',
    reqLvl: 59,
    quality: 79,
    filename: 'greathelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 119,
    item_type: 'base_armor',
  },
  {
    name: 'Corona',
    minDef: 111,
    maxDef: 165,
    reqStr: 174,
    durability: 50,
    sockets: 3,
    type: 'helm',
    reqLvl: 66,
    quality: 85,
    filename: 'crown.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 120,
    item_type: 'base_armor',
  },
  {
    name: 'Demonhead',
    minDef: 101,
    maxDef: 154,
    reqStr: 102,
    durability: 20,
    sockets: 3,
    type: 'helm',
    reqLvl: 55,
    quality: 74,
    filename: 'mask.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 121,
    item_type: 'base_armor',
  },
  {
    name: 'Dusk Shroud',
    minDef: 361,
    maxDef: 467,
    reqStr: 77,
    durability: 20,
    sockets: 4,
    type: 'tors',
    reqLvl: 49,
    quality: 65,
    filename: 'quilted.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 122,
    item_type: 'base_armor',
  },
  {
    name: 'Wyrmhide',
    minDef: 364,
    maxDef: 470,
    reqStr: 84,
    durability: 24,
    sockets: 4,
    type: 'tors',
    reqLvl: 50,
    quality: 67,
    filename: 'leatherarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 123,
    item_type: 'base_armor',
  },
  {
    name: 'Scarab Husk',
    minDef: 369,
    maxDef: 474,
    reqStr: 95,
    durability: 28,
    sockets: 4,
    type: 'tors',
    reqLvl: 51,
    quality: 68,
    filename: 'hardleather.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 124,
    item_type: 'base_armor',
  },
  {
    name: 'Wire Fleece',
    minDef: 375,
    maxDef: 481,
    reqStr: 111,
    durability: 32,
    sockets: 4,
    type: 'tors',
    reqLvl: 53,
    quality: 70,
    filename: 'studded.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 125,
    item_type: 'base_armor',
  },
  {
    name: 'Diamond Mail',
    minDef: 383,
    maxDef: 489,
    reqStr: 131,
    durability: 26,
    sockets: 4,
    type: 'tors',
    reqLvl: 54,
    quality: 72,
    filename: 'ringmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 126,
    item_type: 'base_armor',
  },
  {
    name: 'Loricated Mail',
    minDef: 390,
    maxDef: 496,
    reqStr: 149,
    durability: 36,
    sockets: 4,
    type: 'tors',
    reqLvl: 55,
    quality: 73,
    filename: 'scalemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 3,
    id: 127,
    item_type: 'base_armor',
  },
  {
    name: 'Boneweave',
    minDef: 399,
    maxDef: 505,
    reqStr: 158,
    durability: 45,
    sockets: 4,
    type: 'tors',
    reqLvl: 47,
    quality: 62,
    filename: 'chainmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 128,
    item_type: 'base_armor',
  },
  {
    name: 'Great Hauberk',
    minDef: 395,
    maxDef: 501,
    reqStr: 118,
    durability: 50,
    sockets: 4,
    type: 'tors',
    reqLvl: 56,
    quality: 75,
    filename: 'breastplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 129,
    item_type: 'base_armor',
  },
  {
    name: 'Balrog Skin',
    minDef: 410,
    maxDef: 517,
    reqStr: 165,
    durability: 30,
    sockets: 4,
    type: 'tors',
    reqLvl: 57,
    quality: 76,
    filename: 'splintmail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 130,
    item_type: 'base_armor',
  },
  {
    name: 'Hellforge Plate',
    minDef: 421,
    maxDef: 530,
    reqStr: 196,
    durability: 60,
    sockets: 4,
    type: 'tors',
    reqLvl: 59,
    quality: 78,
    filename: 'platemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    armorType: 3,
    id: 131,
    item_type: 'base_armor',
  },
  {
    name: 'Kraken Shell',
    minDef: 417,
    maxDef: 523,
    reqStr: 174,
    durability: 48,
    sockets: 4,
    type: 'tors',
    reqLvl: 61,
    quality: 81,
    filename: 'fieldplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 132,
    item_type: 'base_armor',
  },
  {
    name: 'Lacquered Plate',
    minDef: 433,
    maxDef: 541,
    reqStr: 208,
    durability: 55,
    sockets: 4,
    type: 'tors',
    reqLvl: 62,
    quality: 82,
    filename: 'gothicplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 133,
    item_type: 'base_armor',
  },
  {
    name: 'Shadow Plate',
    minDef: 446,
    maxDef: 557,
    reqStr: 230,
    durability: 70,
    sockets: 4,
    type: 'tors',
    reqLvl: 64,
    quality: 83,
    filename: 'fullplatemail.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 3,
    id: 134,
    item_type: 'base_armor',
  },
  {
    name: 'Sacred Armor',
    minDef: 487,
    maxDef: 600,
    reqStr: 232,
    durability: 60,
    sockets: 4,
    type: 'tors',
    reqLvl: 66,
    quality: 85,
    filename: 'ancientarmor.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 2,
    id: 135,
    item_type: 'base_armor',
  },
  {
    name: 'Archon Plate',
    minDef: 410,
    maxDef: 524,
    reqStr: 103,
    durability: 60,
    sockets: 4,
    type: 'tors',
    reqLvl: 63,
    quality: 84,
    filename: 'lightplate.gif',
    slotId: 2,
    qualifiedType: 'Armors',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 136,
    item_type: 'base_armor',
  },
  {
    name: 'Heater',
    minDef: 95,
    maxDef: 110,
    reqStr: 77,
    durability: 88,
    sockets: 2,
    type: 'shie',
    reqLvl: 43,
    quality: 58,
    block: 22,
    filename: 'buckler.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 137,
    item_type: 'base_armor',
  },
  {
    name: 'Luna',
    minDef: 108,
    maxDef: 123,
    reqStr: 100,
    durability: 84,
    sockets: 2,
    type: 'shie',
    reqLvl: 45,
    quality: 61,
    block: 20,
    filename: 'smallshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 138,
    item_type: 'base_armor',
  },
  {
    name: 'Hyperion',
    minDef: 119,
    maxDef: 135,
    reqStr: 127,
    durability: 82,
    sockets: 3,
    type: 'shie',
    reqLvl: 48,
    quality: 64,
    block: 24,
    filename: 'largeshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 2,
    id: 139,
    item_type: 'base_armor',
  },
  {
    name: 'Monarch',
    minDef: 133,
    maxDef: 148,
    reqStr: 156,
    durability: 86,
    sockets: 4,
    type: 'shie',
    reqLvl: 54,
    quality: 72,
    block: 22,
    filename: 'kiteshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 4,
    armorType: 1,
    id: 140,
    item_type: 'base_armor',
  },
  {
    name: 'Aegis',
    minDef: 145,
    maxDef: 161,
    reqStr: 219,
    durability: 92,
    sockets: 4,
    type: 'shie',
    reqLvl: 59,
    quality: 79,
    block: 24,
    filename: 'towershield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 4,
    armorType: 3,
    id: 141,
    item_type: 'base_armor',
  },
  {
    name: 'Ward',
    minDef: 153,
    maxDef: 170,
    reqStr: 185,
    durability: 100,
    sockets: 4,
    type: 'shie',
    reqLvl: 63,
    quality: 84,
    block: 24,
    filename: 'gothicshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 4,
    armorType: 2,
    id: 142,
    item_type: 'base_armor',
  },
  {
    name: 'Bramble Mitts',
    minDef: 54,
    maxDef: 62,
    reqStr: 50,
    durability: 12,
    type: 'glov',
    reqLvl: 42,
    quality: 57,
    filename: 'leathergloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 2,
    id: 143,
    item_type: 'base_armor',
  },
  {
    name: 'Vampirebone Gloves',
    minDef: 56,
    maxDef: 65,
    reqStr: 50,
    durability: 14,
    type: 'glov',
    reqLvl: 47,
    quality: 63,
    filename: 'heavygloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 2,
    id: 144,
    item_type: 'base_armor',
  },
  {
    name: 'Vambraces',
    minDef: 59,
    maxDef: 67,
    reqStr: 106,
    durability: 16,
    type: 'glov',
    reqLvl: 51,
    quality: 69,
    filename: 'chaingloves.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 2,
    id: 145,
    item_type: 'base_armor',
  },
  {
    name: 'Crusader Gauntlets',
    minDef: 59,
    maxDef: 68,
    reqStr: 151,
    durability: 18,
    type: 'glov',
    reqLvl: 57,
    quality: 76,
    filename: 'lightgauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 2,
    id: 146,
    item_type: 'base_armor',
  },
  {
    name: 'Ogre Gauntlets',
    minDef: 62,
    maxDef: 71,
    reqStr: 185,
    durability: 24,
    type: 'glov',
    reqLvl: 64,
    quality: 85,
    filename: 'gauntlets.gif',
    slotId: 6,
    qualifiedType: 'Gloves',
    quality_id: 2,
    id: 147,
    item_type: 'base_armor',
  },
  {
    name: 'Wyrmhide Boots',
    minDef: 54,
    maxDef: 62,
    reqStr: 50,
    durability: 12,
    type: 'boot',
    reqLvl: 45,
    quality: 60,
    filename: 'boots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 2,
    id: 148,
    item_type: 'base_armor',
  },
  {
    name: 'Scarabshell Boots',
    minDef: 56,
    maxDef: 65,
    reqStr: 91,
    durability: 14,
    type: 'boot',
    reqLvl: 49,
    quality: 66,
    filename: 'heavyboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 2,
    id: 149,
    item_type: 'base_armor',
  },
  {
    name: 'Boneweave Boots',
    minDef: 59,
    maxDef: 67,
    reqStr: 118,
    durability: 16,
    type: 'boot',
    reqLvl: 54,
    quality: 72,
    filename: 'chainboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 2,
    id: 150,
    item_type: 'base_armor',
  },
  {
    name: 'Mirrored Boots',
    minDef: 59,
    maxDef: 68,
    reqStr: 163,
    durability: 18,
    type: 'boot',
    reqLvl: 60,
    quality: 81,
    filename: 'lightplatedboots.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 2,
    id: 151,
    item_type: 'base_armor',
  },
  {
    name: 'Myrmidon Greaves',
    minDef: 62,
    maxDef: 71,
    reqStr: 208,
    durability: 24,
    type: 'boot',
    reqLvl: 65,
    quality: 85,
    filename: 'greaves.gif',
    slotId: 5,
    qualifiedType: 'Boots',
    quality_id: 2,
    id: 152,
    item_type: 'base_armor',
  },
  {
    name: 'Spiderweb Sash',
    minDef: 55,
    maxDef: 62,
    reqStr: 50,
    durability: 12,
    type: 'belt',
    reqLvl: 46,
    quality: 61,
    filename: 'sash.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 2,
    beltRows: 4,
    id: 153,
    item_type: 'base_armor',
  },
  {
    name: 'Vampirefang Belt',
    minDef: 56,
    maxDef: 63,
    reqStr: 50,
    durability: 14,
    type: 'belt',
    reqLvl: 51,
    quality: 68,
    filename: 'lightbelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 2,
    beltRows: 4,
    id: 154,
    item_type: 'base_armor',
  },
  {
    name: 'Mithril Coil',
    minDef: 58,
    maxDef: 65,
    reqStr: 106,
    durability: 16,
    type: 'belt',
    reqLvl: 56,
    quality: 75,
    filename: 'belt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 2,
    beltRows: 4,
    id: 155,
    item_type: 'base_armor',
  },
  {
    name: 'Troll Belt',
    minDef: 59,
    maxDef: 66,
    reqStr: 151,
    durability: 18,
    type: 'belt',
    reqLvl: 62,
    quality: 82,
    filename: 'heavybelt.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 2,
    beltRows: 4,
    id: 156,
    item_type: 'base_armor',
  },
  {
    name: 'Colossus Girdle',
    minDef: 61,
    maxDef: 71,
    reqStr: 185,
    durability: 24,
    type: 'belt',
    reqLvl: 67,
    quality: 85,
    filename: 'girdle.gif',
    slotId: 7,
    qualifiedType: 'Belts',
    quality_id: 2,
    beltRows: 4,
    id: 157,
    item_type: 'base_armor',
  },
  {
    name: 'Bone Visage',
    minDef: 100,
    maxDef: 157,
    reqStr: 106,
    durability: 40,
    sockets: 3,
    type: 'helm',
    reqLvl: 63,
    quality: 84,
    filename: 'bonehelm.gif',
    slotId: 1,
    qualifiedType: 'Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 3,
    id: 158,
    item_type: 'base_armor',
  },
  {
    name: 'Troll Nest',
    minDef: 158,
    maxDef: 173,
    reqStr: 106,
    durability: 74,
    sockets: 3,
    type: 'shie',
    reqLvl: 57,
    quality: 76,
    block: 20,
    filename: 'boneshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 159,
    item_type: 'base_armor',
  },
  {
    name: 'Blade Barrier',
    minDef: 147,
    maxDef: 163,
    reqStr: 118,
    durability: 83,
    sockets: 3,
    type: 'shie',
    reqLvl: 51,
    quality: 68,
    block: 20,
    filename: 'spikedshield.gif',
    slotId: 4,
    qualifiedType: 'Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    armorType: 1,
    id: 160,
    item_type: 'base_armor',
  },
  {
    name: 'Alpha Helm',
    minDef: 52,
    maxDef: 62,
    reqStr: 44,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 26,
    quality: 35,
    filename: 'wolfhead.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 161,
    item_type: 'base_armor',
  },
  {
    name: 'Griffon Headdress',
    minDef: 46,
    maxDef: 68,
    reqStr: 50,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 30,
    quality: 40,
    filename: 'hawkhelm.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 1,
    id: 162,
    item_type: 'base_armor',
  },
  {
    name: "Hunter's Guise",
    minDef: 67,
    maxDef: 81,
    reqStr: 56,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 29,
    quality: 46,
    filename: 'antlers.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 163,
    item_type: 'base_armor',
  },
  {
    name: 'Sacred Feathers',
    minDef: 58,
    maxDef: 87,
    reqStr: 62,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 32,
    quality: 50,
    filename: 'falconmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 164,
    item_type: 'base_armor',
  },
  {
    name: 'Totemic Mask',
    minDef: 73,
    maxDef: 98,
    reqStr: 65,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 41,
    quality: 55,
    filename: 'spiritmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 165,
    item_type: 'base_armor',
  },
  {
    name: 'Jawbone Visor',
    minDef: 55,
    maxDef: 68,
    reqStr: 58,
    durability: 25,
    sockets: 3,
    type: 'phlm',
    reqLvl: 25,
    quality: 33,
    filename: 'jawbonecap.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 166,
    item_type: 'base_armor',
  },
  {
    name: 'Lion Helm',
    minDef: 63,
    maxDef: 75,
    reqStr: 73,
    durability: 35,
    sockets: 3,
    type: 'phlm',
    reqLvl: 29,
    quality: 38,
    filename: 'fangedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 167,
    item_type: 'base_armor',
  },
  {
    name: 'Rage Mask',
    minDef: 78,
    maxDef: 90,
    reqStr: 88,
    durability: 45,
    sockets: 3,
    type: 'phlm',
    reqLvl: 29,
    quality: 44,
    filename: 'hornedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 168,
    item_type: 'base_armor',
  },
  {
    name: 'Savage Helmet',
    minDef: 85,
    maxDef: 98,
    reqStr: 103,
    durability: 50,
    sockets: 3,
    type: 'phlm',
    reqLvl: 32,
    quality: 49,
    filename: 'assualthelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 169,
    item_type: 'base_armor',
  },
  {
    name: 'Slayer Guard',
    minDef: 93,
    maxDef: 120,
    reqStr: 118,
    durability: 55,
    sockets: 3,
    type: 'phlm',
    reqLvl: 40,
    quality: 54,
    filename: 'avengerguard.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 170,
    item_type: 'base_armor',
  },
  {
    name: 'Akaran Targe',
    minDef: 101,
    maxDef: 125,
    reqStr: 44,
    durability: 20,
    sockets: 4,
    type: 'ashd',
    reqLvl: 26,
    quality: 35,
    block: 10,
    smiteMin: 12,
    smiteMax: 16,
    filename: 'targe.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 171,
    item_type: 'base_armor',
  },
  {
    name: 'Akaran Rondache',
    minDef: 113,
    maxDef: 137,
    reqStr: 59,
    durability: 30,
    sockets: 4,
    type: 'ashd',
    reqLvl: 30,
    quality: 40,
    block: 15,
    smiteMin: 15,
    smiteMax: 20,
    filename: 'rondache.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 172,
    item_type: 'base_armor',
  },
  {
    name: 'Protector Shield',
    minDef: 129,
    maxDef: 153,
    reqStr: 69,
    durability: 40,
    sockets: 4,
    type: 'ashd',
    reqLvl: 34,
    quality: 46,
    block: 20,
    smiteMin: 18,
    smiteMax: 24,
    filename: 'heraldicshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 173,
    item_type: 'base_armor',
  },
  {
    name: 'Gilded Shield',
    minDef: 144,
    maxDef: 168,
    reqStr: 89,
    durability: 50,
    sockets: 4,
    type: 'ashd',
    reqLvl: 38,
    quality: 51,
    block: 22,
    smiteMin: 20,
    smiteMax: 28,
    filename: 'aerinshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 1,
    armorType: 1,
    id: 174,
    item_type: 'base_armor',
  },
  {
    name: 'Royal Shield',
    minDef: 156,
    maxDef: 181,
    reqStr: 114,
    durability: 60,
    sockets: 4,
    type: 'ashd',
    reqLvl: 41,
    quality: 55,
    block: 25,
    smiteMin: 24,
    smiteMax: 32,
    filename: 'crownshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 175,
    item_type: 'base_armor',
  },
  {
    name: 'Mummified Trophy',
    minDef: 38,
    maxDef: 48,
    reqStr: 38,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 24,
    quality: 33,
    block: 3,
    filename: 'preservedhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 176,
    item_type: 'base_armor',
  },
  {
    name: 'Fetish Trophy',
    minDef: 41,
    maxDef: 52,
    reqStr: 41,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 29,
    quality: 39,
    block: 5,
    filename: 'zombiehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 177,
    item_type: 'base_armor',
  },
  {
    name: 'Sexton Trophy',
    minDef: 44,
    maxDef: 55,
    reqStr: 47,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 33,
    quality: 45,
    block: 8,
    filename: 'unravellerhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 178,
    item_type: 'base_armor',
  },
  {
    name: 'Cantor Trophy',
    minDef: 50,
    maxDef: 64,
    reqStr: 50,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 36,
    quality: 49,
    block: 10,
    filename: 'gargoylehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 179,
    item_type: 'base_armor',
  },
  {
    name: 'Hierophant Trophy',
    minDef: 58,
    maxDef: 70,
    reqStr: 58,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 40,
    quality: 54,
    block: 12,
    filename: 'demonhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 1,
    armorType: 1,
    id: 180,
    item_type: 'base_armor',
  },
  {
    name: 'Blood Spirit',
    minDef: 101,
    maxDef: 145,
    reqStr: 86,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 46,
    quality: 62,
    filename: 'wolfhead.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 2,
    id: 181,
    item_type: 'base_armor',
  },
  {
    name: 'Sun Spirit',
    minDef: 98,
    maxDef: 147,
    reqStr: 95,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 51,
    quality: 69,
    filename: 'hawkhelm.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 182,
    item_type: 'base_armor',
  },
  {
    name: 'Earth Spirit',
    minDef: 107,
    maxDef: 152,
    reqStr: 104,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 57,
    quality: 76,
    filename: 'antlers.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 183,
    item_type: 'base_armor',
  },
  {
    name: 'Sky Spirit',
    minDef: 103,
    maxDef: 155,
    reqStr: 113,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 62,
    quality: 83,
    filename: 'falconmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 184,
    item_type: 'base_armor',
  },
  {
    name: 'Dream Spirit',
    minDef: 109,
    maxDef: 159,
    reqStr: 118,
    durability: 20,
    sockets: 3,
    type: 'pelt',
    reqLvl: 66,
    quality: 85,
    filename: 'spiritmask.gif',
    slotId: 1,
    qualifiedType: 'Druid Pelts',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 185,
    item_type: 'base_armor',
  },
  {
    name: 'Carnage Helm',
    minDef: 102,
    maxDef: 147,
    reqStr: 106,
    durability: 25,
    sockets: 3,
    type: 'phlm',
    reqLvl: 45,
    quality: 60,
    filename: 'jawbonecap.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 186,
    item_type: 'base_armor',
  },
  {
    name: 'Fury Visor',
    minDef: 105,
    maxDef: 150,
    reqStr: 129,
    durability: 35,
    sockets: 3,
    type: 'phlm',
    reqLvl: 49,
    quality: 66,
    filename: 'fangedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 187,
    item_type: 'base_armor',
  },
  {
    name: 'Destroyer Helm',
    minDef: 111,
    maxDef: 156,
    reqStr: 151,
    durability: 45,
    sockets: 3,
    type: 'phlm',
    reqLvl: 54,
    quality: 73,
    filename: 'hornedhelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 188,
    item_type: 'base_armor',
  },
  {
    name: 'Conqueror Crown',
    minDef: 114,
    maxDef: 159,
    reqStr: 174,
    durability: 50,
    sockets: 3,
    type: 'phlm',
    reqLvl: 60,
    quality: 80,
    filename: 'assualthelm.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 2,
    id: 189,
    item_type: 'base_armor',
  },
  {
    name: 'Guardian Crown',
    minDef: 117,
    maxDef: 168,
    reqStr: 196,
    durability: 55,
    sockets: 3,
    type: 'phlm',
    reqLvl: 65,
    quality: 85,
    filename: 'avengerguard.gif',
    slotId: 1,
    qualifiedType: 'Barbarian Helms',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 190,
    item_type: 'base_armor',
  },
  {
    name: 'Sacred Targe',
    minDef: 126,
    maxDef: 158,
    reqStr: 86,
    durability: 45,
    sockets: 4,
    type: 'ashd',
    reqLvl: 47,
    quality: 63,
    block: 30,
    smiteMin: 22,
    smiteMax: 70,
    filename: 'targe.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 191,
    item_type: 'base_armor',
  },
  {
    name: 'Sacred Rondache',
    minDef: 138,
    maxDef: 164,
    reqStr: 109,
    durability: 68,
    sockets: 4,
    type: 'ashd',
    reqLvl: 52,
    quality: 70,
    block: 28,
    smiteMin: 35,
    smiteMax: 58,
    filename: 'rondache.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 192,
    item_type: 'base_armor',
  },
  {
    name: 'Kurast Shield',
    minDef: 154,
    maxDef: 172,
    reqStr: 124,
    durability: 55,
    sockets: 4,
    type: 'ashd',
    reqLvl: 55,
    quality: 74,
    block: 25,
    smiteMin: 10,
    smiteMax: 82,
    filename: 'heraldicshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 193,
    item_type: 'base_armor',
  },
  {
    name: 'Zakarum Shield',
    minDef: 169,
    maxDef: 193,
    reqStr: 142,
    durability: 65,
    sockets: 4,
    type: 'ashd',
    reqLvl: 61,
    quality: 82,
    block: 22,
    smiteMin: 46,
    smiteMax: 46,
    filename: 'aerinshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 194,
    item_type: 'base_armor',
  },
  {
    name: 'Vortex Shield',
    minDef: 182,
    maxDef: 225,
    reqStr: 148,
    durability: 90,
    sockets: 4,
    type: 'ashd',
    reqLvl: 66,
    quality: 85,
    block: 19,
    smiteMin: 5,
    smiteMax: 87,
    filename: 'crownshield.gif',
    slotId: 4,
    qualifiedType: 'Paladin Shields',
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    armorType: 1,
    id: 195,
    item_type: 'base_armor',
  },
  {
    name: 'Minion Skull',
    minDef: 95,
    maxDef: 139,
    reqStr: 77,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 44,
    quality: 59,
    block: 3,
    filename: 'preservedhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 196,
    item_type: 'base_armor',
  },
  {
    name: 'Hellspawn Skull',
    minDef: 96,
    maxDef: 141,
    reqStr: 82,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 50,
    quality: 67,
    block: 5,
    filename: 'zombiehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 197,
    item_type: 'base_armor',
  },
  {
    name: 'Overseer Skull',
    minDef: 98,
    maxDef: 142,
    reqStr: 91,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 49,
    quality: 66,
    block: 8,
    filename: 'unravellerhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 198,
    item_type: 'base_armor',
  },
  {
    name: 'Succubus Skull',
    minDef: 100,
    maxDef: 146,
    reqStr: 95,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 60,
    quality: 81,
    block: 10,
    filename: 'gargoylehead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 2,
    armorType: 1,
    id: 199,
    item_type: 'base_armor',
  },
  {
    name: 'Bloodlord Skull',
    minDef: 103,
    maxDef: 148,
    reqStr: 106,
    durability: 20,
    sockets: 2,
    type: 'head',
    reqLvl: 65,
    quality: 85,
    block: 12,
    filename: 'demonhead.gif',
    slotId: 4,
    qualifiedType: 'Necromancer Shrunken Heads',
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    armorType: 1,
    id: 200,
    item_type: 'base_armor',
  },
];

import React, { useContext, useEffect, useState } from 'react';
import { Mule } from '../../../common/providers/MuleProvider/MuleProvider';
import styled from 'styled-components/macro';
import { ItemContext, ItemType, StorageItem } from '../../../common/providers/ItemProvider/ItemProvider';
import { ItemMuleName } from '../../../common/components/StyledComponents';

interface Props {
  mule: Mule;
}

export const MuleOverview: React.FC<Props> = ({ mule }) => {
  const [items, setItems] = useState<StorageItem[]>([]);
  const { ready, getItemsByMuleId } = useContext(ItemContext);

  useEffect(() => {
    if (mule && ready) {
      getItemsByMuleId(mule.mule_id).then(setItems);
    }
  }, [mule, ready]);

  const count = (type: ItemType) => {
    return items.filter(item => item.item_type === type).length;
  };

  return (
    <Container>
      <ItemMuleName name={mule.mule_name} />

      <Overview>
        <div className={'unique'}>Uniques: {count('unique')}</div>
        <div className={'set'}>Sets: {count('set')}</div>
        <div className={'runeword'}>Runewords: {count('runeword')}</div>

        <div className={'rune'}>Runes: {count('rune')}</div>
        <div className={'rare'}>Rares: {count('rare')}</div>
        <div className={'magic'}>Magic: {count('magic')}</div>

        <div className={'base'}>Armors: {count('base_armor')}</div>
        <div className={'base'}>Weapons: {count('base_weapon')}</div>
        <div className={'misc'}>Misc: {count('misc')}</div>
      </Overview>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  background-color: black;
  border-radius: 4px;

  > div:first-of-type {
    font-size: 20px;
    color: white;
    flex-grow: 1;
  }
`;

const Overview = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 8px;
`;

interface Patch {
  name: string;
  id: number;
  release: string;
  isD2R: boolean;
}

export const patches: Patch[] = [
  { name: '1.14d', id: 0, release: 'June 2016', isD2R: false },
  { name: '2.4', id: 1, release: 'April 2022', isD2R: true },
  { name: '2.6', id: 2, release: 'Feb 2023', isD2R: true },
];

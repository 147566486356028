import React from 'react';
import { IonItem, IonLabel, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react';
import styled from 'styled-components/macro';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorPageOrder } from '../../../store/settings/selectors';
import { setPageOrder } from '../../../store/settings/settingsSlice';

export const TabPagesReorder: React.FC = () => {
  const order = useAppSelector(selectorPageOrder);
  const dispatch = useAppDispatch();

  const handleReorder = (e: CustomEvent<ItemReorderEventDetail>) => {
    const from = e.detail.from;
    const to = e.detail.to;
    dispatch(setPageOrder(arrayMove(order, from, to)));
    e.detail.complete();
  };

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
      {order.map(o => (
        <IonItem key={`order_${o}`} style={{ background: '#222' }}>
          <IonLabel>{o}</IonLabel>
          <IonReorder slot='start'></IonReorder>
        </IonItem>
      ))}
    </IonReorderGroup>
  );
};

const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: brightness(100%) invert(1);
`;

function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const newArr = [...arr];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
}

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { IonAccordion, IonIcon, IonItem, IonLabel, IonRange, IonToggle } from '@ionic/react';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import styled from 'styled-components/macro';
import { add, remove } from 'ionicons/icons';
import { Filter } from '../../../common/types';

interface Props {
  value: Filter<HomeSection, 'mule'>;
}

export const HomeMulesSettings: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();

  const handleToggleShow = () => {
    dispatch(setHomeSettings({ ...homeSettings, mule: { ...homeSettings.mule, show: !homeSettings.mule.show } }));
  };

  const handleChangeCount = (count: number) => {
    dispatch(setHomeSettings({ ...homeSettings, mule: { ...homeSettings.mule, count } }));
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Recently Muled Settings</IonLabel>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.mule.show} onIonChange={handleToggleShow}>
          Show
        </IonToggle>
      </IonItem>
      <IonRange
        slot={'content'}
        value={homeSettings.mule.count}
        min={1}
        max={20}
        snaps={true}
        pin={true}
        mode={'md'}
        onIonChange={e => handleChangeCount(e.detail.value as number)}
      >
        <IonIcon slot={'start'} icon={remove} color={'light'} />
        <IonIcon slot={'end'} icon={add} color={'light'} />
      </IonRange>
    </IonAccordion>
  );
};

const RangeWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #222;

  &:before {
    content: 'Rune Size';
    color: white;
    font-size: 16px;
  }
`;

import React, { useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import { About } from './components/About';
import { ChangeLog } from './components/ChangeLog';
import { useLocation } from 'react-router';
import { BackUpRestore } from './components/BackUpRestore';
import styled from 'styled-components/macro';
import { RestoreLegacyData } from './components/RestoreLegacyData';

export const AppInfo: React.FC = () => {
  const [section, setSection] = useState('about');
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setSection(location.hash.slice(1));
    }
  }, [location.hash]);

  return (
    <AppPage
      padding={true}
      title={'App Info'}
      headerContent={
        <IonSegment value={section} onIonChange={e => setSection(e.detail.value as string)} mode={'md'}>
          <IonSegmentButton value={'about'}>About</IonSegmentButton>
          <IonSegmentButton value={'change-log'}>Change Log</IonSegmentButton>
          <IonSegmentButton value={'backup'}>Backup</IonSegmentButton>
        </IonSegment>
      }
    >
      {section === 'about' && <About />}
      {section === 'change-log' && <ChangeLog />}
      {section === 'backup' && (
        <Container>
          <SectionWrap>
            <SectionTitle>Restore legacy data (app version 4)</SectionTitle>
            <RestoreLegacyData />
          </SectionWrap>
          <SectionWrap>
            <SectionTitle>Current data (app version 5)</SectionTitle>
            <BackUpRestore />
          </SectionWrap>
        </Container>
      )}
    </AppPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const SectionWrap = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 4px;
`;

const SectionTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

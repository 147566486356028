import React from 'react';
import { Filter } from '../../../common/types';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { IonAccordion, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';

interface Props {
  value: Filter<HomeSection, 'general'>;
}

export const HomeGeneral: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();
  const handleArrowsToggle = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        general: { ...homeSettings.general, sliderArrows: !homeSettings.general.sliderArrows },
      }),
    );
  };

  const handleHeightToggle = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        general: { ...homeSettings.general, autoHeight: !homeSettings.general.autoHeight },
      }),
    );
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>General Settings</IonLabel>
      </IonItem>

      <IonItem slot={'content'} lines={'none'}>
        <IonToggle labelPlacement={'stacked'} checked={homeSettings.general.sliderArrows} onIonChange={handleArrowsToggle}>
          Show Previous/Next arrows on item sliders
        </IonToggle>
      </IonItem>

      <IonItem lines={'none'} slot={'content'}>
        <IonToggle labelPlacement={'stacked'} checked={homeSettings.general.autoHeight} onIonChange={handleHeightToggle}>
          Auto adjust slider height to content
        </IonToggle>
      </IonItem>
    </IonAccordion>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { useAppSelector } from '../../store/store';
import { selectorRunewords } from '../../store/activeData/selector';
import { ALL_RUNES } from '../../common/data/runes/all_runes';
import styled from 'styled-components/macro';
import { selectorIsD2r } from '../../store/settings/selectors';
import { Runeword } from '../../common/types';
import { findRunewords } from './calculator';
import { chevronForward } from 'ionicons/icons';
import { IonIcon, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { Rune } from '../../common/components/Rune';
import { RuneContext } from '../../common/providers/RuneProvider/RuneProvider';

export const RunewordCalc: React.FC = () => {
  const { runes, incRune, decRune } = useContext(RuneContext);
  const [results, setResults] = useState<Runeword[][]>([[], []]);
  const allRunewords = useAppSelector(selectorRunewords);
  const isD2r = useAppSelector(selectorIsD2r);
  const router = useIonRouter();


  useEffect(() => {
    setResults(findRunewords(allRunewords, runes));
  }, [runes]);

  return (
    <AppPage
      padding={true}
      title={'Runeword Calculator'}
      headerContent={
        <ResultsContainer>
          <div onClick={() => router.push(appRoutes.runewordCalc.children.results.path('can'))}>
            <span>Can make: {results.at(0)?.length ?? ''}</span>
            <IonIcon icon={chevronForward} />
          </div>
          <div onClick={() => router.push(appRoutes.runewordCalc.children.results.path('almost'))}>
            <span>Can almost make: {results.at(1)?.length ?? ''}</span>
            <IonIcon icon={chevronForward} />
          </div>
        </ResultsContainer>
      }
    >
      <Container>
        {ALL_RUNES.map(rune => (
          <RuneContainer key={rune.name}>
            <IncDec onClick={() => decRune(rune.id)}>-</IncDec>
            <Rune size={'12vw'} id={rune.id} />
            <IncDec onClick={() => incRune(rune.id)}>+</IncDec>
            <div className={'rune'}>
              {rune.name} ({runes.find(r => r.rune_id === rune.id)?.count ?? 0})
            </div>
          </RuneContainer>
        ))}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(11, 1fr);
  column-gap: 20px;
  row-gap: 30px;
  color: white;
  justify-items: center;
`;

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  background: #222;

  > div {
    flex: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border: 1px solid var(--border-color);
    padding: 0 15px;
  }

  > div:last-of-type {
    border-left: none;
  }
`;

const RuneContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: 20px;

  > div:last-of-type {
    grid-column: 1 / span 3;
    font-size: 14px;
  }
`;

const IncDec = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 80%;
  height: 70%;
  user-select: none;
  cursor: pointer;
`;

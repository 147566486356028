import React from 'react';
import styled from 'styled-components/macro';
import { CHANGE_LOG } from './change_log';
import { useAppSelector } from '../../../store/store';
import { selectorPlatform } from '../../../store/app/selectors';

export const ChangeLog: React.FC = () => {
  const platform = useAppSelector(selectorPlatform);
  return (
    <Container>
      {CHANGE_LOG.map(l =>
        l.platforms !== 'all' && l.platforms !== platform ? null : (
          <Item key={`change-${l.version}-${l.platforms}`}>
            <div>{l.version}</div>
            <ul>
              {l.changes.map((c, i) => (
                <li key={`change-${l.version}-${l.platforms}-${i}`} dangerouslySetInnerHTML={{ __html: c }} />
              ))}
            </ul>
          </Item>
        ),
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: white;
`;

const Item = styled.div`
  > div {
    font-size: 20px;
  }

  > ul > li {
    line-height: 140%;
  }
`;

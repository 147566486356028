import React, { useEffect } from 'react';
import { App } from '@capacitor/app';
import { useIonRouter } from '@ionic/react';
import { useAppSelector } from '../../store/store';
import { selectorPlatform } from '../../store/app/selectors';

export const AndroidBackButtonHandler: React.FC = () => {
  const router = useIonRouter();
  const platform = useAppSelector(selectorPlatform);

  useEffect(() => {
    if (platform !== 'android') return;
    App.addListener('backButton', () => {
      if (!router.canGoBack() && confirm('Exit app?')) void App.exitApp();
    });
  }, [platform]);

  return null;
};

import styled from 'styled-components/macro';

export const Table = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  justify-items: center;
  row-gap: 8px;
  grid-template-areas:
    '. h1 h2 h3'
    'r1 d1-1 d1-2 d1-3'
    'r2 d2-1 d2-2 d2-3'
    'r3 d3-1 d3-2 d3-3'
    'r4 d4-1 d4-2 d4-3';
`;

export const Cell = styled.div<{ area: string }>`
  grid-area: ${props => props.area};
  color: white;
  padding: 4px 12px;

  &.end {
    justify-self: end;
  }
`;

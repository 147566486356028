import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ItemContext, StorageItem } from '../../providers/ItemProvider/ItemProvider';
import { useAppDispatch } from '../../../store/store';
import { setViewMuledItems } from '../../../store/app/appSlice';

interface Props {
  muleId: number;
}

export const DialogViewMuleItems: React.FC<Props> = ({ muleId }) => {
  const [items, setItems] = useState<StorageItem[]>([]);
  const { getItemsByMuleId } = useContext(ItemContext);

  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log(items); // Stop GH actions complaing
  }, []);

  const handleClose = () => {
    dispatch(setViewMuledItems(undefined));
  };

  useEffect(() => {
    getItemsByMuleId(muleId).then(setItems);
  }, []);

  return (
    <Dialog onClose={handleClose} onCancel={handleClose}>
      MULED ITEMs
    </Dialog>
  );
};

const Dialog = styled.dialog``;

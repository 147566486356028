import { IRune } from '../../../common/types';

export const ALL_RUNES: IRune[] = [
  {
    name: 'El',
    rq_level: 11,
    props_weapon: '+50 To Attack Rating, +1 Light Radius',
    props_armor: '+15 Defense, +1 To Light Radius',
    id: 0,
    item_type: 'rune',
  },
  {
    name: 'Eld',
    rq_level: 11,
    props_weapon: '+75% Damage To Undead, +50 Attack Rating Against Undead',
    props_armor: '15% Slower Stamina Drain/7% Increased Chance of Blocking(Shields)',
    id: 1,
    item_type: 'rune',
  },
  {
    name: 'Tir',
    rq_level: 13,
    props_weapon: '+2 To Mana After Each Kill',
    props_armor: '+2 To Mana After Each Kill',
    id: 2,
    item_type: 'rune',
  },
  {
    name: 'Nef',
    rq_level: 13,
    props_weapon: 'Knockback',
    props_armor: '+30 Defense Vs. Missile',
    id: 3,
    item_type: 'rune',
  },
  {
    name: 'Eth',
    rq_level: 15,
    props_weapon: '-25% To Target Defense',
    props_armor: 'Regenerate Mana 15%',
    id: 4,
    item_type: 'rune',
  },
  {
    name: 'Ith',
    rq_level: 15,
    props_weapon: '+9 To Maximum Damage',
    props_armor: '15% Damage Taken Goes to Mana',
    id: 5,
    item_type: 'rune',
  },
  {
    name: 'Tal',
    rq_level: 17,
    props_weapon: '+75 Poison Damage Over 5 Seconds',
    props_armor: 'Poison Resist 30%/Poison Resist 35%(Shields)',
    id: 6,
    item_type: 'rune',
  },
  {
    name: 'Ral',
    rq_level: 19,
    props_weapon: 'Adds 5-30 Fire Damage',
    props_armor: 'Fire Resist 30%/Fire Resist 35%(Shields)',
    id: 7,
    item_type: 'rune',
  },
  {
    name: 'Ort',
    rq_level: 21,
    props_weapon: 'Adds 1-50 Lightning Damage',
    props_armor: 'Lightning Resist 30%/Lightning Resist 35%(Shields)',
    id: 8,
    item_type: 'rune',
  },
  {
    name: 'Thul',
    rq_level: 23,
    props_weapon: 'Adds 3-14 Cold Damage - 3 Second Duration',
    props_armor: 'Cold Resist 30%/Cold Resist 35%(Shields)',
    id: 9,
    item_type: 'rune',
  },
  {
    name: 'Amn',
    rq_level: 25,
    props_weapon: '7% Life Stolen Per Hit',
    props_armor: 'Attacker Takes Damage of 14',
    id: 10,
    item_type: 'rune',
  },
  {
    name: 'Sol',
    rq_level: 27,
    props_weapon: '+9 To Minimum Damage',
    props_armor: 'Damage Reduced By 7',
    id: 11,
    item_type: 'rune',
  },
  {
    name: 'Shael',
    rq_level: 29,
    props_weapon: '20% Increased Attack Speed',
    props_armor: '20% Faster Hit Recovery/20% Faster Block Rate(Shields)',
    id: 12,
    item_type: 'rune',
  },
  {
    name: 'Dol',
    rq_level: 31,
    props_weapon: 'Hit Causes Monster To Flee 25%',
    props_armor: 'Replenish Life +7',
    id: 13,
    item_type: 'rune',
  },
  {
    name: 'Hel',
    rq_level: 0,
    props_weapon: 'Requirements -20%',
    props_armor: 'Requirements -20%',
    id: 14,
    item_type: 'rune',
  },
  {
    name: 'Io',
    rq_level: 35,
    props_weapon: '+10 To Vitality',
    props_armor: '+10 To Vitality',
    id: 15,
    item_type: 'rune',
  },
  {
    name: 'Lum',
    rq_level: 37,
    props_weapon: '+10 To Energy',
    props_armor: '+10 To Energy',
    id: 16,
    item_type: 'rune',
  },
  {
    name: 'Ko',
    rq_level: 39,
    props_weapon: '+10 To Dexterity',
    props_armor: '+10 To Dexterity',
    id: 17,
    item_type: 'rune',
  },
  {
    name: 'Fal',
    rq_level: 41,
    props_weapon: '+10 To Strength',
    props_armor: '+10 To Strength',
    id: 18,
    item_type: 'rune',
  },
  {
    name: 'Lem',
    rq_level: 43,
    props_weapon: '75% Extra Gold From Monsters',
    props_armor: '50% Extra Gold From Monsters',
    id: 19,
    item_type: 'rune',
  },
  {
    name: 'Pul',
    rq_level: 45,
    props_weapon: '+75% Damage To Demons, +100 Attack Rating Against Demons',
    props_armor: '+30% Enhanced Defense',
    id: 20,
    item_type: 'rune',
  },
  {
    name: 'Um',
    rq_level: 47,
    props_weapon: '25% Chance of Open Wounds',
    props_armor: 'All Resistances +15(Armor/Helms) +22(Shields)',
    id: 21,
    item_type: 'rune',
  },
  {
    name: 'Mal',
    rq_level: 49,
    props_weapon: 'Prevent Monster Heal',
    props_armor: 'Magic Damage Reduced By 7',
    id: 22,
    item_type: 'rune',
  },
  {
    name: 'Ist',
    rq_level: 51,
    props_weapon: '30% Better Chance of Getting Magic Items',
    props_armor: '25% Better Chance of Getting Magic Items',
    id: 23,
    item_type: 'rune',
  },
  {
    name: 'Gul',
    rq_level: 53,
    props_weapon: '20% Bonus To Attack Rating',
    props_armor: '5% To Maximum Poison Resist',
    id: 24,
    item_type: 'rune',
  },
  {
    name: 'Vex',
    rq_level: 55,
    props_weapon: '7% Mana Stolen Per Hit',
    props_armor: '5% To Maximum Fire Resist',
    id: 25,
    item_type: 'rune',
  },
  {
    name: 'Ohm',
    rq_level: 57,
    props_weapon: '+50% Enhanced Damage',
    props_armor: '5% To Maximum Cold Resist',
    id: 26,
    item_type: 'rune',
  },
  {
    name: 'Lo',
    rq_level: 59,
    props_weapon: '20% Deadly Strike',
    props_armor: '5% To Maximum Lightning Resist',
    id: 27,
    item_type: 'rune',
  },
  {
    name: 'Sur',
    rq_level: 61,
    props_weapon: 'Hit Blinds Target',
    props_armor: 'Maximum Mana 5%/+50 To Mana (Shields)',
    id: 28,
    item_type: 'rune',
  },
  {
    name: 'Ber',
    rq_level: 63,
    props_weapon: '20% Chance of Crushing Blow',
    props_armor: 'Damage Reduced by 8%',
    id: 29,
    item_type: 'rune',
  },
  {
    name: 'Jah',
    rq_level: 65,
    props_weapon: "Ignore Target's Defense",
    props_armor: 'Increase Maximum Life 5%/+50 Life (Shields)',
    id: 30,
    item_type: 'rune',
  },
  {
    name: 'Cham',
    rq_level: 67,
    props_weapon: 'Freeze Target +3',
    props_armor: 'Cannot Be Frozen',
    id: 31,
    item_type: 'rune',
  },
  {
    name: 'Zod',
    rq_level: 69,
    props_weapon: 'Indestructible',
    props_armor: 'Indestructible',
    id: 32,
    item_type: 'rune',
  },
];

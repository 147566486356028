import React, { useState } from 'react';
import { IonCheckbox, IonIcon, IonItem, IonModal, IonRange, IonSelect, IonSelectOption } from '@ionic/react';
import { refreshCircle } from 'ionicons/icons';
import styled from 'styled-components/macro';
import { initialFilterData, RunewordsFilterData, SocketOperator } from '../Runewords';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  filterData: RunewordsFilterData;
  setFilterData: React.Dispatch<React.SetStateAction<RunewordsFilterData>>;
}

export const FilterModal: React.FC<Props> = ({ isOpen, onClose, filterData, setFilterData }) => {
  const [range, setRange] = useState<{ lower: number; upper: number }>();

  const handleChange = (lower: number, upper: number) => {
    setRange({ lower, upper });
    setFilterData(prev => ({ ...prev, minLevel: lower, maxLevel: upper }));
  };

  const handleReset = () => {
    setFilterData({ ...initialFilterData });
    setRange({ lower: filterData.minLevel, upper: filterData.maxLevel });
    onClose();
  };

  return (
    <IonModal
      trigger={'filter-as'}
      className={'window'}
      isOpen={isOpen}
      onDidDismiss={() => onClose()}
      onDidPresent={() => setRange({ lower: filterData.minLevel, upper: filterData.maxLevel })}
    >
      {/*<dialog ref={dialog}>*/}
      {isOpen && (
        <DialogContainer>
          <DialogHeader>
            <div>Filters</div>
            <IonIcon size={'large'} icon={refreshCircle} onClick={handleReset} />
          </DialogHeader>
          <div className={'range'}>
            <div>Required Level</div>
            <IonRange
              className={'scroll'}
              dualKnobs={true}
              value={range}
              snaps={true}
              pin={true}
              mode={'md'}
              min={12}
              max={70}
              onIonChange={e => {
                handleChange((e.detail.value as any).lower, (e.detail.value as any).upper);
              }}
            />
          </div>
          <IonItem lines={'none'}>
            <IonSelect
              label={'Slot'}
              interface={'popover'}
              value={filterData.slot}
              onIonChange={e => setFilterData(prev => ({ ...prev, slot: ~~(e.detail.value as string) }))}
            >
              <IonSelectOption value={0}>All</IonSelectOption>
              <IonSelectOption value={1}>Head</IonSelectOption>
              <IonSelectOption value={2}>Chest</IonSelectOption>
              <IonSelectOption value={3}>Weapon</IonSelectOption>
              <IonSelectOption value={4}>Shield</IonSelectOption>
            </IonSelect>
          </IonItem>

          <div>
            <IonItem style={{ flexGrow: 1 }} lines={'none'}>
              <IonSelect
                label={'Sockets: '}
                interface={'popover'}
                value={filterData.socketOperator}
                onIonChange={e =>
                  setFilterData(prev => ({
                    ...prev,
                    socketOperator: e.detail.value as SocketOperator,
                  }))
                }
              >
                <IonSelectOption value={'Any'}>Any</IonSelectOption>
                <IonSelectOption value={'Less than'}>Less than</IonSelectOption>
                <IonSelectOption value={'Equal to'}>Equal to</IonSelectOption>
                <IonSelectOption value={'Greater than'}>Greater than</IonSelectOption>
              </IonSelect>

              {filterData.socketOperator !== 'Any' && (
                <IonItem lines={'none'}>
                  <IonSelect
                    interface={'popover'}
                    value={filterData.socketNumber}
                    onIonChange={e =>
                      setFilterData(prev => ({
                        ...prev,
                        socketNumber: ~~(e.detail.value as string),
                      }))
                    }
                  >
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                    <IonSelectOption value={6}>6</IonSelectOption>
                  </IonSelect>
                </IonItem>
              )}
            </IonItem>
          </div>
          <IonItem lines={'none'}>
            <IonCheckbox
              color={'primary'}
              checked={filterData.includeLadder}
              onIonChange={e => setFilterData(prev => ({ ...prev, includeLadder: e.detail.checked }))}
            >
              Include ladder:
            </IonCheckbox>
          </IonItem>
          <IonItem lines={'none'}>
            <IonCheckbox
              color={'primary'}
              checked={filterData.highlightInStock}
              onIonChange={() =>
                setFilterData(prev => ({
                  ...prev,
                  highlightInStock: !filterData.highlightInStock,
                }))
              }
            >
              Highlight runes in inventory:
            </IonCheckbox>
          </IonItem>
        </DialogContainer>
      )}
      {/*</dialog>*/}
    </IonModal>
  );
};

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  padding-bottom: 20px;

  .range {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 16px;

    > div {
      padding-top: 28px;
      color: white;
    }
  }
`;

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #646161;
  padding: 8px 12px;
  color: white;
`;

import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useOpenCloseDialog } from '../../common/hooks';

interface Props {
  open: boolean;
  close: () => void;
}

export const ImmunitiesDialog: React.FC<Props> = ({ open, close }) => {
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, open, undefined, close);
  const container = useRef<HTMLDivElement>(null);
  // useOnClickOutside(container, close);
  return (
    <Dialog ref={self}>
      <Close onClick={close} />
      <Container ref={container}>
        <span>The letters: </span>
        <div>
          <span className={'l'}>L</span>
          <span className={'c'}>C</span>
          <span className={'p'}>P</span>
          <span className={'f'}>F</span>
          <span className={'ph'}>Ph</span>
          <span className={'m'}>M</span>
        </div>
        <span>indicate the types of immunity monsters are likely to have.</span>

        <Legend>
          <div>
            <span className={'l'}>L</span> - Lightning
          </div>
          <div>
            <span className={'c'}>C</span> - Cold
          </div>
          <div>
            <span className={'p'}>P</span> - Poison
          </div>
          <div>
            <span className={'f'}>F</span> - Fire
          </div>
          <div>
            <span className={'ph'}>Ph</span> - Physical
          </div>
          <div>
            <span className={'m'}>M</span> - Magic
          </div>
        </Legend>
      </Container>
    </Dialog>
  );
};

const Dialog = styled.dialog`
  width: 90vw;
  color: white;
  padding: 60px 10px 40px;
  position: relative;
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: url('/images/icons/close.svg');
  background-size: contain;
  background-repeat: no-repeat;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;

  > div {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  > div > span {
    width: 20px;
  }
`;

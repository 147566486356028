import React, { useEffect, useRef, useState } from 'react';
import { Result } from '../DropCalc';
import styled from 'styled-components/macro';
import { useOpenCloseDialog } from '../../../common/hooks';
import { ArmorBase, IRune, SetItem, UniqueItem, WeaponBase } from '../../../common/types';
import { ALL_RUNES } from '../../../common/data/runes/all_runes';
import { Rune } from '../../../common/components/Rune';
import { useAppSelector } from '../../../store/store';
import { selectorArmorBases, selectorSets, selectorUniques, selectorWeaponBases } from '../../../store/activeData/selector';
import { ViewUnique } from '../../../common/components/ViewUnique/ViewUnique';
import { ViewSet } from '../../../common/components/ViewSet/ViewSet';
import { ViewBase } from '../../../common/components/ViewBase/ViewBase';

interface Props {
  result?: Result;
  onClose: () => void;
}

export const ViewDropCalcItemDialog: React.FC<Props> = ({ result, onClose }) => {
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, !!result, undefined, cleanUp);

  const uniques = useAppSelector(selectorUniques);
  const sets = useAppSelector(selectorSets);
  const armorBases = useAppSelector(selectorArmorBases);
  const weaponBases = useAppSelector(selectorWeaponBases);

  const [rune, setRune] = useState<IRune>();
  const [unique, setUnique] = useState<UniqueItem>();
  const [setItem, setSetItem] = useState<SetItem>();
  const [baseArmor, setBaseArmor] = useState<ArmorBase>();
  const [baseWeapon, setBaseWeapon] = useState<WeaponBase>();

  useEffect(() => {
    if (result) {
      const name = result.at(0) as string;
      console.log(result);
      if (result?.at(3) === 'rune') {
        const rName = name.slice(0, name.indexOf(' '));
        setRune(ALL_RUNES.find(r => r.name === rName));
      } else if (result?.at(3) === 'unique') {
        setUnique(uniques.find(u => u.name.toLowerCase() == name.toLowerCase()));
      } else if (result?.at(3) === 'set') {
        const [set, item] = name.split(':');
        setSetItem(sets.find(s => s.name.toLowerCase() === item.trim().toLowerCase()));
      } else if (result?.at(3) === 'base') {
        const base = armorBases.find(a => a.name.toLowerCase() === name.toLowerCase());
        if (base) {
          setBaseArmor(base);
        } else {
          setBaseWeapon(weaponBases.find(w => w.name.toLowerCase() === name.toLowerCase()));
        }
      }
    }
  }, [result]);

  useEffect(() => {
    console.log(unique);
  }, [unique]);

  function cleanUp() {
    setRune(undefined);
    setUnique(undefined);
    setSetItem(undefined);
    setBaseArmor(undefined);
    setBaseWeapon(undefined);
  }

  return (
    <Dialog ref={self}>
      <Container>
        <Close onClick={onClose} />
        {rune && <Rune id={rune.id} size={'40px'} withName withCount withProps />}
        {unique && <ViewUnique id={unique.id} />}
        {setItem && <ViewSet itemId={setItem.id} inDialog={true} />}
        {(baseArmor || baseWeapon) && <ViewBase armorBase={baseArmor} weaponBase={baseWeapon} />}
      </Container>
    </Dialog>
  );
};

const Dialog = styled.dialog``;
const Container = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/close.svg');
    background-size: cover;
  }
`;

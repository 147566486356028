import React, { useState } from 'react';
import { DropCalcData, Result } from '../DropCalc';
import styled from 'styled-components/macro';
import { ViewDropCalcItemDialog } from './ViewDropCalcItemDialog';

interface Props {
  results: Result[];
  resultsSearch: string;
  data: DropCalcData;
}

export const DropCalcResultsTable: React.FC<Props> = ({ results, resultsSearch, data }) => {
  const [dialog, setDialog] = useState<Result>();
  return (
    <>
      <ResultsContainer>
        <tbody>
          {results
            .filter(r => r[0].toLowerCase().includes(resultsSearch))
            .sort((a, b) => {
              if (data.sort === 'alpha') return a[0].localeCompare(b[0]);
              else return b[1] - a[1];
            })
            .map((r, i) => (
              <tr key={`${r[0]}-${r[1]}-${i}`} onClick={() => (r.at(3) ? setDialog(r) : undefined)}>
                <td className={r[3] === 'gem' ? r[0] : r[3]}>{r[0]}</td>
                <td>{r[1]}%</td>
                <td>{r[2]}</td>
              </tr>
            ))}
        </tbody>
      </ResultsContainer>
      <ViewDropCalcItemDialog result={dialog} onClose={() => setDialog(undefined)} />
    </>
  );
};

export const ResultsContainer = styled.table`
  width: 100%;
  max-width: 100%;
  margin: 0 0 20px;
  color: white;

  > div {
    width: 100%;

    > * {
      width: 100%;
    }
  }

  th,
  td {
    max-width: 130px;
    word-break: keep-all;
    white-space: pre-wrap;
    padding: 10px 10px;
    border: 1px solid var(--border-color);
    font-size: 16px;
  }
`;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../store/store';
import { selectorFindSet } from '../../../store/activeData/selector';
import { ItemCard, ItemNotes, ItemStats, ItemTitle } from '../StyledComponents';
import { ItemImage } from '../ItemImage';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { SetBonus, SetItem } from '../../types';
import { IonChip } from '@ionic/react';
import { ItemDBStatus } from '../ItemDBStatus';

interface Props {
  itemId?: number;
  setId?: number;
  inDialog?: boolean;
}

export const ViewSet: React.FC<Props> = ({ itemId, setId, inDialog }) => {
  const { items, bonuses } = useAppSelector(state => selectorFindSet(state, itemId, setId));
  const swiperWrapper = useRef<HTMLDivElement>(null);
  const swiper = useRef<SwiperRef>(null);
  const [swiperHeight, setSwiperHeight] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  useLayoutEffect(() => {
    if (itemId && swiperWrapper.current) {
      setSwiperHeight(swiperWrapper.current.getBoundingClientRect().height);
    }

    if (itemId && swiper.current) {
      setActiveIndex(findInitialSlideIndex());
    }
  }, [itemId]);

  useEffect(() => {
    if (swiper.current) {
      swiper.current.swiper.slideTo(activeIndex, 500);
    }
  }, [activeIndex]);

  const findInitialSlideIndex = () => {
    if (items && itemId) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === itemId) {
          console.log('index', i);
          return i;
        }
      }
    }
    return 0;
  };

  return (
    <>
      {setId !== undefined && setId >= 0 && (
        <>
          {items && items.map(item => <Item key={`${item.name}-${item.id}`} item={item} style={{ marginTop: '10px' }} />)}

          {bonuses && <Bonus bonuses={bonuses} />}
        </>
      )}

      {itemId && !!items.length && (
        <>
          <div>
            {items.map((item, i) => (
              <IonChip color={activeIndex === i ? 'success' : 'primary'} key={`item-chip-${item.id}`} onClick={() => setActiveIndex(i)}>
                {item.name}
              </IonChip>
            ))}
            <IonChip
              color={activeIndex === items.length ? 'success' : 'primary'}
              key={`item-chip-bonus`}
              onClick={() => setActiveIndex(items.length)}
            >
              Bonuses
            </IonChip>
          </div>
          <div ref={swiperWrapper} style={{ marginBottom: '40px' }}>
            <Swiper ref={swiper} onSlideChange={s => setActiveIndex(s.activeIndex)} className={inDialog ? 'dialog-swiper' : ''}>
              {items.map(item => (
                <SwiperSlide key={`slide-${item.name}-${item.id}`}>
                  <div style={{ margin: 'auto', height: `${swiperHeight ? swiperHeight : 'auto'}` }}>
                    <Item item={item} style={{ marginTop: '10px' }} />
                  </div>
                </SwiperSlide>
              ))}

              {bonuses && (
                <SwiperSlide key={`slide-bonus-${bonuses.set_id}`}>
                  <Bonus bonuses={bonuses} />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
};

const Item: React.FC<{ item: SetItem; style?: React.CSSProperties }> = ({ item, style }) => {
  return (
    <ItemCard key={`set-item-${item.id}`} style={style}>
      <ItemTitle color={'var(--set)'}>{item.name}</ItemTitle>
      <div className={'base'}>{item.type}</div>
      <ItemImage setFilename={item.filename}></ItemImage>
      <ItemStats>
        {item.props.map((p, i) => {
          if (p.includes(':'))
            return (
              <div key={`${item.name}-${p}`}>
                <span>{p} </span>
                {item.props[i + 1]}
                <br />
              </div>
            );
          if (item.props.at(i - 1)?.includes(':')) return null;
          return (
            <React.Fragment key={`${item.name}-${p}`}>
              {p}
              <br />
            </React.Fragment>
          );
        })}
      </ItemStats>
      <ItemDBStatus type={'set'} itemId={item.id} />
    </ItemCard>
  );
};

const Bonus: React.FC<{ bonuses: SetBonus }> = ({ bonuses }) => {
  return (
    <ItemCard style={{ marginTop: '10px' }}>
      <ItemTitle color={'var(--set)'}>Partial Bonuses</ItemTitle>
      <ItemStats>{bonuses.partial}</ItemStats>
      <br />
      <br />
      <ItemTitle color={'var(--set)'}>Complete Bonuses</ItemTitle>
      <ItemStats>{bonuses.complete}</ItemStats>
      {bonuses.filename && <img src={`/images/item-images/sets/${bonuses.filename}`} />}
      {bonuses.notes && (
        <>
          <br />
          <ItemNotes>* {bonuses.notes}</ItemNotes>
        </>
      )}
    </ItemCard>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import {
  setAreas,
  setArmorBases,
  setDropCalcZones,
  setRunewords,
  setSets,
  setTerrorZones,
  setUniques,
  setWeaponBases,
} from '../activeData/activeDataSlice';
import { patches } from '../../common/data/patches/patchData';
import { LocalStorage } from '../../common/Storage';
import { Filter } from '../../common/types';

export const homeSections = ['game', 'mule', 'runes', 'grail', 'dropCalc', 'faveRunewords', 'order', 'general'] as const;
export type HomeSection = (typeof homeSections)[number];

interface HomeSetting {
  show: boolean;
  section: HomeSection;
}

interface GameSettings extends HomeSetting {
  section: Filter<HomeSection, 'game'>;
  useLatestPatch: boolean;
}

interface MuleSettings extends HomeSetting {
  section: Filter<HomeSection, 'mule'>;
  count: number;
}

interface RuneSettings extends HomeSetting {
  size: number;
  showCount: boolean;
  showName: boolean;
  section: Filter<HomeSection, 'runes'>;
}

interface GrailSettings extends HomeSetting {
  showUniques: boolean;
  showSets: boolean;
  showSummary: boolean;
  section: Filter<HomeSection, 'grail'>;
}

interface DropCalcSettings extends HomeSetting {
  results: number;
  section: Filter<HomeSection, 'dropCalc'>;
}

interface OrderSettings extends HomeSetting {
  order: HomeSection[];
  section: Filter<HomeSection, 'order'>;
  show: true;
}

interface FaveRunewords extends HomeSetting {
  section: Filter<HomeSection, 'faveRunewords'>;
  show: boolean;
}

interface GeneralSettings extends HomeSetting {
  section: Filter<HomeSection, 'general'>;
  show: true;
  sliderArrows: boolean;
  autoHeight: boolean;
}

export type HomeSettingsState = {
  [K in HomeSection]: K extends 'game'
    ? GameSettings
    : K extends 'mule'
    ? MuleSettings
    : K extends 'runes'
    ? RuneSettings
    : K extends 'grail'
    ? GrailSettings
    : K extends 'dropCalc'
    ? DropCalcSettings
    : K extends 'order'
    ? OrderSettings
    : K extends 'faveRunewords'
    ? FaveRunewords
    : K extends 'general'
    ? GeneralSettings
    : HomeSetting;
};

export interface CraftingSettingsState {
  showRuneNames: boolean;
  showRuneCount: boolean;
  showRunesInStock: boolean;
  showGemNames: boolean;
}

const initialCraftingState: CraftingSettingsState = {
  showRuneNames: false,
  showRuneCount: false,
  showRunesInStock: false,
  showGemNames: false,
};

export interface ItemCatalogSetting {
  showSetGrailProgress: boolean;
  showUniqueGrailProgress: boolean;
}

const initialItemCatalogState: ItemCatalogSetting = {
  showSetGrailProgress: false,
  showUniqueGrailProgress: false,
};

export const settingsPageOrder = [
  'Runewords',
  'Runeword Calculator',
  'Drop Calculator',
  'Mules',
  'Run/Walk Calculator',
  'Item Catalog',
  'Attack Calculator',
  'App Info',
  'Terror Zones',
  'Home Page',
  'Horadric Cube',
  'Breakpoints',
  'Areas',
  'Holy Grail',
] as const;

export type SettingsPageOrder = (typeof settingsPageOrder)[number];

const initialHomeSettings: HomeSettingsState = {
  game: {
    show: true,
    useLatestPatch: true,
    section: 'game',
  },
  mule: {
    show: true,
    count: 10,
    section: 'mule',
  },
  runes: {
    show: true,
    size: 30,
    showCount: false,
    showName: false,
    section: 'runes',
  },
  grail: {
    show: true,
    showUniques: false,
    showSets: false,
    showSummary: true,
    section: 'grail',
  },
  dropCalc: {
    show: true,
    results: 5,
    section: 'dropCalc',
  },
  order: {
    order: [...homeSections.filter(s => s !== 'order' && s !== 'general')],
    section: 'order',
    show: true,
  },
  faveRunewords: {
    section: 'faveRunewords',
    show: true,
  },
  general: {
    section: 'general',
    show: true,
    sliderArrows: true,
    autoHeight: false,
  },
};

const getInitialPatch = () => {
  const isD2r = LocalStorage.getLastGameMode();
  const dontUseLatest = LocalStorage.getDontUseLatestPatch();
  const latestUsed = LocalStorage.getLastPatchUsed();
  if (!isD2r) return patches.at(0)!.id;
  if (dontUseLatest && latestUsed) return latestUsed;
  return patches.at(-1)?.id ?? 2;
};

const storedSettings = LocalStorage.getStoredHomeSettings();

const mergeSavedHomeSettings = () => {
  if (!storedSettings) return { ...initialHomeSettings };
  const order = [...storedSettings.order.order];

  for (const section of homeSections) {
    if (!order.includes(section)) {
      order.push(section);
    }
  }

  return {
    ...initialHomeSettings,
    ...storedSettings,
    order: {
      ...storedSettings.order,
      order,
    },
  };
};

const mergeSavedCraftingSettings = () => {
  const settings = LocalStorage.getCraftingSettings();
  if (!settings) return { ...initialCraftingState };
  return { ...initialCraftingState, ...settings };
};

const mergeSavedPageOrder = () => {
  const order: SettingsPageOrder[] = LocalStorage.getPagesOrder() ?? [...settingsPageOrder];
  for (const o of settingsPageOrder) {
    if (!order.includes(o)) order.push(o);
  }
  return order;
};

const mergeItemCatalog = () => {
  const saved = LocalStorage.getItemCatalogSettings();
  return saved ? { ...initialItemCatalogState, ...saved } : { ...initialItemCatalogState };
};

interface SettingsState {
  isD2r: boolean;
  patch: number;
  homeSettings: HomeSettingsState;
  craftingSettings: CraftingSettingsState;
  pageOrder: SettingsPageOrder[];
  tabBarAction: SettingsPageOrder | 'none';
  tabBarAction2: SettingsPageOrder | 'none';
  itemCatalog: ItemCatalogSetting;
}

const initialState: SettingsState = {
  isD2r: LocalStorage.getLastGameMode() === 'd2r',
  patch: getInitialPatch(),
  //allows for addition of settings without breaking by loading older version of settings from storage
  homeSettings: mergeSavedHomeSettings(),
  craftingSettings: mergeSavedCraftingSettings(),
  pageOrder: mergeSavedPageOrder(),
  tabBarAction: LocalStorage.getTabBarAction() ?? 'Home Page',
  tabBarAction2: LocalStorage.getTabBarAction2() ?? 'none',
  itemCatalog: mergeItemCatalog(),
};

export const setIsD2r = createAsyncThunk<
  boolean,
  boolean,
  {
    dispatch: AppDispatch;
  }
>('settings/setIsD2r', async (isD2r, { dispatch }) => {
  if (isD2r) {
    const p = patches.at(-1)?.id ?? 2;
    dispatch(changePatch(p));
  } else {
    const p = patches.at(0)?.id ?? 0;
    dispatch(changePatch(p));
  }
  LocalStorage.setLastGameMode(isD2r ? 'd2r' : 'classic');
  return isD2r;
});

export const changePatch = createAsyncThunk<
  number,
  number,
  {
    dispatch: AppDispatch;
  }
>('settings/changePatch', async (id, { dispatch }) => {
  dispatch(setRunewords(id));
  dispatch(setWeaponBases(id));
  dispatch(setArmorBases(id));
  dispatch(setDropCalcZones(id));
  dispatch(setAreas(id));
  dispatch(setUniques(id));
  dispatch(setSets(id));
  dispatch(setTerrorZones(id));
  LocalStorage.setLastPatchedUsed(id);
  return id;
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setHomeSettings: (state, action: PayloadAction<HomeSettingsState>) => {
      state.homeSettings = action.payload;
      LocalStorage.setStoredHomeSettings(action.payload);
    },
    setCraftingSettings: (state, action: PayloadAction<CraftingSettingsState>) => {
      state.craftingSettings = action.payload;
      LocalStorage.setCraftingSettings(action.payload);
    },
    setPageOrder: (state, action: PayloadAction<SettingsPageOrder[]>) => {
      state.pageOrder = action.payload;
      LocalStorage.setPagesOrder(action.payload);
    },
    setTabBarAction: (state, action: PayloadAction<{ action: SettingsPageOrder; tab: 1 | 2 }>) => {
      if (action.payload.tab === 1) {
        state.tabBarAction = action.payload.action;
        LocalStorage.setTabBarAction(action.payload.action);
      }
      if (action.payload.tab === 2) {
        state.tabBarAction2 = action.payload.action;
        LocalStorage.setTabBarAction2(action.payload.action);
      }
    },
    setItemCatalogSetting: (state, action: PayloadAction<ItemCatalogSetting>) => {
      state.itemCatalog = action.payload;
      LocalStorage.setItemCatalogSettings(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changePatch.fulfilled, (state, action: PayloadAction<number>) => {
        state.patch = action.payload;
      })
      .addCase(setIsD2r.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isD2r = action.payload;
      });
  },
});

export const { setHomeSettings, setCraftingSettings, setPageOrder, setTabBarAction, setItemCatalogSetting } = settingsSlice.actions;
export default settingsSlice.reducer;

import { RootState } from '../store';

export const selectorIsD2r = (state: RootState) => state.settings.isD2r;
export const selectorPatch = (state: RootState) => state.settings.patch;
export const selectorHomeSettings = (state: RootState) => state.settings.homeSettings;
export const selectorCraftingSettings = (state: RootState) => state.settings.craftingSettings;
export const selectorPageOrder = (state: RootState) => state.settings.pageOrder;
export const selectorAppBarAction = (state: RootState) => state.settings.tabBarAction;
export const selectorAppBarAction2 = (state: RootState) => state.settings.tabBarAction2;
export const selectorItemCatalogSettings = (state: RootState) => state.settings.itemCatalog;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonButton, useIonToast, useIonViewWillEnter } from '@ionic/react';
import { MuleContext } from '../../common/providers/MuleProvider/MuleProvider';
import { ItemContext, StorageItem } from '../../common/providers/ItemProvider/ItemProvider';
import { isDevEnv } from '../../common/utils';
import styled from 'styled-components/macro';
import { Clipboard } from '@capacitor/clipboard';

export const Debug: React.FC = () => {
  const [muledItems, setMuledItems] = useState<StorageItem[]>([]);
  const { mules } = useContext(MuleContext);
  const { items, ready } = useContext(ItemContext);
  const hasOpenedMailClient = useRef(false);
  const logs = useRef<string[]>([]);
  const errors = useRef<string[]>([]);
  const [present] = useIonToast();

  useEffect(() => {
    console.log('starting logs');
    if (!isDevEnv()) {
      console.log = log;
      console.error = error;

      // clear logs every 20 mins
      setInterval(
        () => {
          logs.current = [];
          errors.current = [];
        },
        1000 * 60 * 20,
      );
    }
  }, []);

  useIonViewWillEnter(() => {
    items().then(setMuledItems);
  }, [ready]);

  const log = (...data: any[]) => {
    logs.current = [...logs.current, JSON.stringify(data)];
  };

  const error = (...data: any[]) => {
    errors.current = [...errors.current, JSON.stringify(data)];
  };

  const handleDump = async (key: 'mules' | 'items' | 'logs') => {
    // const data = JSON.stringify({
    //   mules: mules,
    //   items: muledItems,
    //   logs: logs.current,
    //   errors: errors.current,
    // });

    const data = {
      [key]:
        key === 'mules'
          ? mules
          : key === 'items'
          ? muledItems
          : {
              logs: logs.current,
              errors: errors.current,
            },
    };

    await Clipboard.write({ string: JSON.stringify(data) });
    await present({
      message: 'Debug data copied to clipboard',
      duration: 2000,
    });

    if (!hasOpenedMailClient.current) {
      const a = document.createElement('a');
      a.href = `mailto:dev.ajsoftware@gmail.com?subject=Tome of D2 debug dump`;
      a.click();
      hasOpenedMailClient.current = true;
    }
  };

  return (
    <AppPage title={'Debug'} padding>
      <Container>
        <IonButton color={'primary'} expand={'block'} onClick={() => handleDump('logs')}>
          Dump Log Data
        </IonButton>
        <IonButton color={'primary'} expand={'block'} onClick={() => handleDump('mules')}>
          Dump Mule Data
        </IonButton>
        <IonButton color={'primary'} expand={'block'} onClick={() => handleDump('items')}>
          Dump Item Data
        </IonButton>
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

import { ItemType } from './providers/ItemProvider/ItemProvider';

export const isDevEnv = () => process.env.REACT_APP_ENV === 'dev';

export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const mapItemTypeToString = (type: ItemType) => {
  switch (type) {
    case 'base_armor':
      return 'Armor Base';
    case 'base_weapon':
      return 'Weapon Base';
    case 'rare':
      return 'Rare';
    case 'gem':
      return 'Gem';
    case 'magic':
      return 'Magic';
    case 'runeword':
      return 'Runeword';
    case 'misc':
      return 'Misc Item';
    case 'unique':
      return 'Unique';
    case 'rune':
      return 'Rune';
    case 'set':
      return 'Set';
  }
};

export const mergeArrayOfObjects = <T>(original: T[], newdata: T[], selector: keyof T) => {
  newdata.forEach(dat => {
    const foundIndex = original.findIndex(ori => ori[selector] == dat[selector]);
    if (foundIndex >= 0) original.splice(foundIndex, 1, dat);
    else original.push(dat);
  });

  return original;
};

export interface ChangeLog {
  version: string;
  changes: string[];
  platforms: 'ios' | 'android' | 'all';
}

export const CHANGE_LOG: ChangeLog[] = [
  {
    version: '5.0.20',
    platforms: 'all',
    changes: ['Add Privacy Policy'],
  },
  {
    version: '5.0.19',
    platforms: 'ios',
    changes: [
      'Added option to delete a mule',
      'Added option to rename a mule',
      'Added option to move an item to a different mule',
      'Fixed area maps not showing waypoint location',
      'Fixed Item Catalog sometimes showing items twice',
    ],
  },
  {
    version: '5.0.19',
    platforms: 'android',
    changes: [
      'Fixed hardware back button going back 2 pages',
      'Added option to delete a mule',
      'Added option to rename a mule',
      'Added option to move an item to a different mule',
      'Fixed area maps not showing waypoint location',
      'Fixed Item Catalog sometimes showing items twice',
    ],
  },
  {
    version: '5.0.18',
    platforms: 'all',
    changes: ['Fixed Oath level requirement', 'Fix Paladin shield auto-mods'],
  },
  {
    version: '5.0.17',
    platforms: 'all',
    changes: ['Re-added ability to restore legacy data'],
  },
  {
    version: '5.0.15',
    platforms: 'all',
    changes: ['Add backup/restore feature', 'Fixed IK maul missing cold damage bonus'],
  },
  {
    version: '5.0.14',
    platforms: 'android',
    changes: ['Add setting option to show Grail progress in Item Catalog', 'Add handler for Android back button to exit app'],
  },
  {
    version: '5.0.14',
    platforms: 'ios',
    changes: ['Add setting option to show Grail progress in Item Catalog'],
  },
  {
    version: '5.0.13',
    platforms: 'all',
    changes: ['Fix bug with view mule detail that sometimes caused data not to load'],
  },
  {
    version: '5.0.10',
    platforms: 'all',
    changes: ['Fix Metamorphosis'],
  },
  {
    version: '5.0.8',
    platforms: 'all',
    changes: [
      'Fix various Drop Calculator bugs',
      'Fix Greyform not saving to to Grail',
      'Fix alert title color',
      "Fix Gloom's Trap graphic (d2r)",
      'Fix Arachnid Mesh graphic (classic)',
    ],
  },
  {
    version: '5.0.7',
    platforms: 'all',
    changes: [
      'Add option to view all area levels',
      'Fix Metamorphosis required level',
      'Fix issue in Act Maps when a zone had multiple side zones',
      'Fix various bugs with Attack Speed Calculator',
    ],
  },
  {
    version: '5.0.6',
    platforms: 'all',
    changes: [
      'Add base item type to Uniques/Sets',
      'Add setting option to show rune name/count/inventory status on crafting recipes',
      'Add setting option to show gem names on crafting recipes',
      'Add setting option to customize tab bar (navigation bar)',
    ],
  },
  {
    version: '5.0.5',
    platforms: 'all',
    changes: [
      'Add option to add item to mule from Item Catalog',
      'Fix unique Grand Charm images',
      'Fix sunder charms',
      'Fixed unique helms missing item quality sections',
      'Fixed missing image for TP scroll',
      'Fix missing image for Rejuvenation Potion',
      'Runewords are now sorted alphabetically',
      'Fixed Sorceress orbs not showing Base Item Catalog',
    ],
  },
  {
    version: '5.0.3',
    platforms: 'all',
    changes: [
      "Fixed bug where some items couldn't be added to Holy Grail",
      'Fixed bug with perfect gems in mules',
      'Fixed runeword bases not being calculated correctly',
      'Fixed issue with selecting monsters in the Drop Calculator',
      'Fixed Terror Zones font color',
      'Fixed issue with pick item modal on devices with narrow displays',
    ],
  },
  {
    version: '5.0.2',
    platforms: 'all',
    changes: ['Fix Angelic Set item catalog', 'Add armor wights to base item catalog', 'Fix Unique item catalog missing mule/HG info'],
  },
  {
    version: '5.0.0',
    platforms: 'all',
    changes: [
      'Complete remake of app',
      '<span class="magic">Holy Grail</span>' +
        '<ul>' +
        '<li>Now uses a local database</li>' +
        '<li>Data is now guarenteed to persist</li>' +
        '<li>Simplified the adding/removing of items</li>' +
        '<li>Add a Grail Overview to the new Home page</li>' +
        '</ul>',
      '<span class="magic">Mule Tracker</span>' +
        '<ul>' +
        '<li>Mule Data can now be linked to Holy Grail</li>' +
        '<li>Muled runes can be linked to the Runeword Calculator</li>' +
        "<li>Viewing an item in the Drop Calculator or in the Item Catalog will show an indicator if that item exists in a mule's inventory</li>" +
        '<li>Tapping the indicator will show that item in a popup</li>' +
        '<li>Added an extra option to add sockets to a mule item</li>' +
        '<li>Muled items are now searchable</li>' +
        '<li>Mules now show an overview with a count of each item type</li>' +
        '<li>The overview is slideable to reveal edit/delete options</li>' +
        '<li>Recently added items will show on the Home page</li>' +
        '</ul>',
      '<span class="magic">Runewords</span>' +
        '<ul>' +
        '<li>Runes currently in inventory will be highlighted</li>' +
        '<li>Tap a runeword to show suitable bases</li>' +
        '<li>If a base with the right amount of sockets exists on a mule an indicator will be shown</li>' +
        '</ul>',
      '<span class="magic">Item Catalog</span>' + '<ul>' + '<li>Added ability to search for items by name or property</li>' + '</ul>',
      '<span class="ruby">Temporarily removed skill calculator (if you know where to get accurate data for the latest patches please email me!)</span>',
      '<span class="ruby">Temporarily removed mercenaries (if you know where to get accurate data for the latest patches please email me!)</span>',
    ],
  },
  {
    version: '4.0.16',
    platforms: 'all',
    changes: ['Fix Skill Metamorphosis rune order'],
  },
  {
    version: '4.0.15',
    platforms: 'all',
    changes: ['Fix Skill calculator'],
  },
  {
    version: '4.0.14',
    platforms: 'all',
    changes: ['Fix Hustle and Moziac'],
  },
  {
    version: '4.0.13',
    platforms: 'all',
    changes: ['Fix runeword calc not using new runewords'],
  },
  {
    version: '4.0.12',
    platforms: 'all',
    changes: ['Fix Mosaic runeword', 'Fix Area Level'],
  },
  {
    version: '4.0.11',
    platforms: 'all',
    changes: ['Fix Hustle runeword'],
  },
  {
    version: '4.0.10',
    platforms: 'all',
    changes: ['Added patch 2.6 runewords and sunder charms'],
  },
  {
    version: '4.0.9',
    platforms: 'all',
    changes: ['Added average damage values to base weapons'],
  },
  {
    version: '4.0.8',
    platforms: 'all',
    changes: ['Fixed Pus Spitter stat', 'Fixed Djin Slayer stat', 'Fixed Normal unique armor upgrade recipe'],
  },
  {
    version: '4.0.7',
    platforms: 'all',
    changes: ['Fixed 2.4 ladder runewords'],
  },
  {
    version: '4.0.6',
    platforms: 'all',
    changes: [
      'Fixed Area Level map',
      'Fixed Runewords search',
      'Updated Mercenary section to account for equipable items changes. (If you have any detailed information about how the stats/skills changed please email me!)',
    ],
  },
  {
    version: '4.0.5',
    platforms: 'ios',
    changes: ['Updated Drop Calculator to reflect the new level 85 areas', 'Fixed Boneweave armor type'],
  },
  {
    version: '4.0.5',
    platforms: 'android',
    changes: [
      'Updated Drop Calculator to reflect the new level 85 areas',
      'Fixed Boneweave armor type',
      'Fixed Remove Ads option not showing',
    ],
  },
  {
    version: '4.0.4',
    platforms: 'all',
    changes: [
      'Fixed boneweave armor incorrectly labeled as exceptional',
      'Fixed Pattern  being incorrectly labeled as needing 5 sockets',
      "Fixed 'All Acts' not working in Area Level",
    ],
  },
  {
    version: '4.0.3',
    platforms: 'all',
    changes: ['Fixed side menu on small screen devices', 'Fixed Mist being incorrectly labeled as needing 4 sockets'],
  },
  {
    version: '4.0.2',
    platforms: 'all',
    changes: ['Fixed Runeword filtering by level'],
  },
  {
    version: '4.0.1',
    platforms: 'all',
    changes: ['Fixed Area Levels not showing patch 2.4 data'],
  },
  {
    version: '4.0.0',
    platforms: 'all',
    changes: [
      'Updates will now be delivered automatically OTA',
      'The side menu is now re-orderable <ul><li>Tap the lock icon in the side menu to toggle re-order mode</li></ul>',
      "Removed 'Trade' section",
      'Removed gestures from the Skill Calculator as it was causing issues for some users (can still long press for +20)',
      '<span class="Emerald">Added support for patch 2.4</span> <ul>' +
        '<li class="magic">Switch patch versions form the side menu</li>' +
        '<li class="Ruby">The Skill Calculator has not yet been updated. It will be updated when more information is available</li>' +
        '<li class="Ruby">Mercenaries have not yet been updated. They will be updated when more information is available</li>' +
        '</ul>',
      'Fixed Broad Axe and derivatives missing sockets',
      'Removed durability from bows/xbows',
      "Fixed Tigulated Mail incorrectly labeled as 'light'",
      'Fixed set bonuses to be on individual lines',
      'Fixed Fire Golem damage',
    ],
  },
  {
    version: '3.5.12',
    platforms: 'all',
    changes: ['Skill Calculator: Fixed scrolling/gesture interaction'],
  },
  {
    version: '3.5.11',
    platforms: 'all',
    changes: ['Attack Speed Calculator: fixed off weapon IAS not showing', 'Skill Calculator: fixed Frost Nova synergies'],
  },
  {
    version: '3.5.10',
    platforms: 'android',
    changes: ['Fixed scrolling in Holy Grail Item list'],
  },
  {
    version: '3.5.9',
    platforms: 'all',
    changes: [
      'Skill Calculator: Added option to save builds',
      'Skill Calculator: Added swipe up/down gesture to skill icons to decrease/increase hard points',
      'Skill Calculator: Added swipe left/right gesture to skill icons to decrease/increase soft points',
      'Skill Calculator: Long pressing a skill will now add 20 hard points instead of opening the skill window',
      'Skill Calculator: Added visual indication for  maximum possible skill points exceed (>110)',
      'Skill Calculator: Added button to skill window to reset points (hard and soft together)',
      'Runeword Base view: Fixed min damage numbers',
      'Runeword Base view: Added weapon speed',
      "Item Catalog - Bases: Added information about 'Auto Mod' properties",
    ],
  },
  {
    version: '3.5.7',
    platforms: 'all',
    changes: ['Fixed bug in Drop Calculator', 'Fixed Mule Tracker search'],
  },
  {
    version: '3.5.6',
    platforms: 'all',
    changes: [
      'Attack Speed Calculator: Fixed bug that sometimes prevented changing selected weapon',
      'Attack Speed Calculator: Fixed Whirlwind calculation',
      'Mule Tracker: Fixed bug that could sometimes cause items to appear to be added to the wrong mule',
      "Mule Tracker: Fixed rune images for 'Original'",
      'App will now open here after update',
    ],
  },
  {
    version: '3.5.5',
    platforms: 'all',
    changes: [
      'Added countess rune drop info to Item Catalog > Runes > Countess',
      'Fixed Mule Tracker item popover positioning',
      'Fixed Immolation Arrow synergies',
      'Cleaned up Amazon skill tree text formatting',
    ],
  },
  {
    version: '3.5.4',
    platforms: 'all',
    changes: [
      'Mule Tracker: Fixed mule order not saving',
      'Mule Tracker: Fixed edited items not saving',
      'Mule Tracker: Fixed deleting a searched-for item removing additional items from mule',
      'Skill Calculator: Fixed Energy Shield values',
      'Trading: Various bug fixes',
      'Fixed broken images for various Skulls',
    ],
  },
  {
    version: '3.5.3',
    platforms: 'all',
    changes: ['Mule Tracker will no longer reset the selected mule after adding an item', 'Fixed bug in Drop Calculator'],
  },
  {
    version: '3.5.2',
    platforms: 'all',
    changes: [
      'Added gems, essences, über keys, body parts, and token to Trading and Mule Tracker',
      'Added option to rearrange mules in Mule Tracker',
      'Runewords can now be starred to make the appear at the top of the list',
      'Fixed bug with collapsing mules in Mule Tracker Item view',
      'Fixed The Scalper incorrect artwork',
      'Fixed Earth Shifter incorrectly labeled as one-handed',
    ],
  },
  {
    version: '3.5.1',
    platforms: 'all',
    changes: [
      'Added rings and amulets to Mule Tracker',
      'Fixed charm images in Trading',
      'Fixed bug with muled items sometimes crashing Item Catalog',
    ],
  },
  {
    version: '3.5.0',
    platforms: 'all',
    changes: ['New feature: Mule tracker', 'Fixed trading socketed uniques'],
  },
  {
    version: '3.4.6',
    platforms: 'all',
    changes: ['Fixed a bug in Skill Calculator caused by changing trees too quickly after closing a skill window'],
  },
  {
    version: '3.4.4',
    platforms: 'all',
    changes: [
      'Fixed various bugs in Trading',
      'Added platforms to Trading',
      'Reset Trading database',
      'Added block percentages to shields',
      "Added 'All Acts' option to Area Level",
      'Changed Area Level sort to High - Low',
      'Fixed Holy Grail not working for sets in Item Catalog',
      "Fixed Paladin's Concentration skill icon",
      'Fixed Frozen Orb synergy',
      "Fixed Death's Web stats",
      'Updated many D2R images',
    ],
  },
  {
    version: '3.4.3',
    platforms: 'all',
    changes: [
      'Fixes to Necromancer tree',
      "Sorceress' masteries are now accounted for in damage calculations",
      'Added range values to melee bases',
    ],
  },
  {
    version: '3.4.2',
    platforms: 'android',
    changes: [
      'Fixed bug that caused ads to be shown to paid users when keyboard is closed',
      'Fixed Cranebeak being incorrectly labelled as 2-handed',
      'Fixed Bone Spear synergies',
    ],
  },
  {
    version: '3.4.1',
    platforms: 'all',
    changes: ['Fixed a bug in Holy Grail'],
  },
  {
    version: '3.4.0',
    platforms: 'all',
    changes: [
      "Skill Calculator: Fixes to Barbarian's Concentrate Skill",
      "Skill Calculator: Fixes to Druid's summon synergy's",
      "Added Backup feature to 'About App'",
    ],
  },
  {
    version: '3.3.1',
    platforms: 'all',
    changes: [
      'Skill Calculator: Various bug fixes',
      'Item Catalog: Added missing circlets to Bases',
      'Fixed circlets not being considered for runeword bases',
      'Drop Calculator: Removed duplicate results from table',
      'Runeword Calculator: Fixed rare bug that caused reset button to be misaligned',
    ],
  },
  {
    version: '3.3.0',
    platforms: 'all',
    changes: [
      'New Feature: Skill Calculator!',
      'Runeword Calc: Added visual indicator for runes in/out of inventory',
      'Runeword Calc: Added button on results page to remove runes from inventory',
      'Runeword Calc: Fixed Bone being incorrectly calculated',
      'Holy Grail: Improved performance (app load may be slightly longer if you have a lot of items found)',
      'Holy Grail: Fixed adding/removing items via Item Catalog',
      'Fixed Original/Resurrected toggle label bug',
      'Fixed bug where app would sometimes incorrectly report a new version available',
      'Fixed Chaos runeword damage modifier',
      "Removed 'Resurrected Info'",
      'Fixed some typos',
    ],
  },
  {
    version: '3.2.6',
    platforms: 'all',
    changes: [
      'Area level now saves sorting preference',
      'Necromancer heads are now considered as potential runeword bases',
      'Fixed D2R necromancer heads images',
    ],
  },
  {
    version: '3.2.5',
    platforms: 'all',
    changes: [
      'App will now open on the last page viewed',
      'Fixed Holy Grail item popover showing incorrect item',
      "Fixed items with 'Speed: 0' not showing speed",
    ],
  },
  {
    version: '3.2.4',
    platforms: 'all',
    changes: [
      'Added rune numbers to Item Catalog',
      'Fixed first line of stats on unique charms/jewels not showing',
      'Holy Grail lists now sorted alphabetically',
      'Holy Grail items can now be added/removed from Item Catalog',
      'Fixed hammers missing from Item Catalog',
      'Fixed various Blood recipes requiring wrong rune',
    ],
  },
  {
    version: '3.2.3',
    changes: [
      'Added this change log to About section',
      'Added option to not use the Diablo font',
      'Added magic items to trading (use for charms/jewels)',
      'Added immunities to level 85 areas',
      'Added rune images to runewords',
      'Fixed bug in Drop Calculator',
      'Fixed class-specific weapons not being accounted for in potential runeword bases',
      'Fixed Leaf runeword attributes not breaking onto new line',
      'Fixed safety ring requiring wrong rune',
      'Adjusted app accent color for better contrast',
    ],
    platforms: 'all',
  },
  {
    version: '3.2.2',
    platforms: 'all',
    changes: [
      'Fixed hammers being treated as maces',
      'Fixed Drop Calculator monster list not showing',
      'Added smite damage to Paladin shields',
      'Added missing Kurast Shield',
      'Adjusted set bonus contrast',
      'Runewords now searchable by base type',
    ],
  },
  {
    version: '3.2.0',
    platforms: 'all',
    changes: ['Added D2R items images', 'Fixed shapeshift skill forms of Attack Speed Calculator'],
  },
];

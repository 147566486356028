import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { SectionWrapper } from '../../common/components/StyledComponents';
import styled from 'styled-components/macro';
import { Rune } from '../../common/components/Rune';
import { GemImage } from '../../common/components/GemImage';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings, selectorPatch } from '../../store/settings/selectors';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';

export const CubeBaseUpgrading: React.FC = () => {
  const patch = useAppSelector(selectorPatch);
  const router = useIonRouter();
  const settings = useAppSelector(selectorCraftingSettings);

  return (
    <AppPage
      padding={true}
      title={'Base Upgrading'}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
    >
      <SectionWrapper label={'Weapons'}>
        <Row>
          <div>
            <span className='rare'>Normal Weapon</span>
            <span className='arrow' />
            <span className='rare'>Exceptional Weapon</span>
          </div>
          <span>
            <Rune
              id={8}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={10}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'sapphire'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='unique'>Normal Weapon</span>
            <span className='arrow' />
            <span className='unique'>Exceptional Weapon</span>
          </div>
          <span>
            <Rune
              id={7}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={11}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'emerald'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='rare'>Exceptional Weapon</span>
            <span className='arrow' />
            <span className='rare'>Elite Weapon</span>
          </div>
          <span>
            <Rune
              id={18}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={21}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'sapphire'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='unique'>Exceptional Weapon</span>
            <span className='arrow' />
            <span className='unique'>Elite Weapon</span>
          </div>
          <span>
            <Rune
              id={16}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={20}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'emerald'} size={'30px'} />
          </span>
        </Row>

        {patch > 0 && (
          <>
            <Row>
              <div>
                <span className='set'>Normal Weapon</span>
                <span className='arrow' />
                <span className='set'>Exceptional Weapon</span>
              </div>
              <span>
                <Rune
                  id={7}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <Rune
                  id={11}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <GemImage withName={settings.showGemNames} quality={'perfect'} type={'emerald'} size={'30px'} />
              </span>
            </Row>

            <Row>
              <div>
                <span className='set'>Exceptional Weapon</span>
                <span className='arrow' />
                <span className='set'>Elite Weapon</span>
              </div>
              <span>
                <Rune
                  id={16}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <Rune
                  id={20}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <GemImage withName={settings.showGemNames} quality={'perfect'} type={'emerald'} size={'30px'} />
              </span>
            </Row>
          </>
        )}
      </SectionWrapper>
      <SectionWrapper label={'Armors'} style={{ marginTop: '42px' }}>
        <Row>
          <div>
            <span className='rare'>Normal Armor</span>
            <span className='arrow' />
            <span className='rare'>Exceptional Armor</span>
          </div>
          <span>
            <Rune
              id={7}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={9}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'amethyst'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='unique'>Normal Armor</span>
            <span className='arrow' />
            <span className='unique'>Exceptional Armor</span>
          </div>
          <span>
            <Rune
              id={6}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={12}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'diamond'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='rare'>Exceptional Armor</span>
            <span className='arrow' />
            <span className='rare'>Elite Armor</span>
          </div>
          <span>
            <Rune
              id={17}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={20}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'amethyst'} size={'30px'} />
          </span>
        </Row>

        <Row>
          <div>
            <span className='unique'>Exceptional Armor</span>
            <span className='arrow' />
            <span className='unique'>Elite Armor</span>
          </div>
          <span>
            <Rune
              id={17}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <Rune
              id={19}
              size={'30px'}
              forceInStock={!settings.showRunesInStock}
              withCount={settings.showRuneCount}
              withName={settings.showRuneNames}
            />
            <span>+</span>
            <GemImage withName={settings.showGemNames} quality={'perfect'} type={'diamond'} size={'30px'} />
          </span>
        </Row>

        {patch > 0 && (
          <>
            <Row>
              <div>
                <span className='set'>Normal Armor</span>
                <span className='arrow' />
                <span className='set'>Exceptional Armor</span>
              </div>
              <span>
                <Rune
                  id={6}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <Rune
                  id={12}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <GemImage withName={settings.showGemNames} quality={'perfect'} type={'diamond'} size={'30px'} />
              </span>
            </Row>

            <Row>
              <div>
                <span className='set'>Exceptional Armor</span>
                <span className='arrow' />
                <span className='set'>Elite Armor</span>
              </div>
              <span>
                <Rune
                  id={17}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <Rune
                  id={19}
                  size={'30px'}
                  forceInStock={!settings.showRunesInStock}
                  withCount={settings.showRuneCount}
                  withName={settings.showRuneNames}
                />
                <span>+</span>
                <GemImage withName={settings.showGemNames} quality={'perfect'} type={'diamond'} size={'30px'} />
              </span>
            </Row>
          </>
        )}
      </SectionWrapper>
    </AppPage>
  );
};

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 6px;
  color: white;
  padding: 18px 0;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  & + & {
    border-top: 1px solid var(--border-color);
  }
`;

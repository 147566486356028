import React, { useEffect, useRef, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { IonButton, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import {
  ACItem,
  berechneBreakpoints,
  berechneWerte,
  setzeBarbschwert,
  setzeCharform,
  setzeIAS,
  setzeSIAS,
  setzeSkillOption,
  setzeWaffe,
  setzeZweitwaffe,
} from './methods';
import { PickItem } from '../../common/components/ItemSelectModal/PickItem';
import { BreakPointDialog, DialogProps } from './components/BreakPointDialog';
import { SectionWrapper } from '../../common/components/StyledComponents';
import { ItemType } from '../../common/providers/ItemProvider/ItemProvider';
import { ArmorBase } from '../../common/types';

interface Item {
  name: string;
  value: number;
}

export interface AttackCalcState {
  classes_selected: number;
  char_form_selected: number;
  skill_selected: number;
  weapon_selected: number;
  swordIsUsedTwoHanded: boolean;
  weapon_selected_second: number;
  fanta_selected: number;
  frenzy_selected: number;
  wolf_selected: number;
  burst_selected: number;
  holy_freeze_selected: number;
  ias_selected: number;
  wIAS1_selected: number;
  wIAS2_selected: number;
  isPrimaryWeapon: boolean;
  isDecrepify: boolean;
}

const initialState: AttackCalcState = {
  classes_selected: 0,
  char_form_selected: 0,
  skill_selected: 0,
  weapon_selected: 0,
  swordIsUsedTwoHanded: false,
  weapon_selected_second: 0,
  fanta_selected: 0,
  frenzy_selected: 0,
  wolf_selected: 0,
  burst_selected: 0,
  holy_freeze_selected: 0,
  ias_selected: 0,
  wIAS1_selected: 0,
  wIAS2_selected: 0,
  isPrimaryWeapon: false,
  isDecrepify: false,
};

const classes_list = [
  'Amazon',
  'Assassin',
  'Barbarian',
  'Druid',
  'Necromancer',
  'Paladin',
  'Sorceress',
  'Merc (Act 1)',
  'Merc (Act 2)',
  'Merc (Act 5)',
];

const initialCharFormList: string[] = ['Unchanged'];

export const AttackCalc: React.FC = () => {
  const [state, setState] = useState({ ...initialState });
  const [charFormList, setCharFormList] = useState(initialCharFormList);
  const [weaponList, setWeaponList] = useState<ACItem[]>([]);
  const [primaryWeapon, setPrimaryWeapon] = useState<ACItem>();
  const [secondWeaponList, setSecondWeaponList] = useState<ACItem[]>([]);
  const [secondaryWeapon, setSecondaryWeapon] = useState<ACItem>({ name: 'None', value: -1 });
  const statischIAS = useRef(true);
  const mIAS = useRef(1);
  const statischFana = useRef(true);
  const statischFrost = useRef(true);
  const [iasOptions, setIasOptions] = useState<{
    offWeapon: ACItem[];
    onWeapon: ACItem[];
    onOffHandWeapon: ACItem[];
  }>();
  const [skillOptions, setSkillOptions] = useState<ACItem[]>([]);
  const [skillIasOptions, setSkillIasOptions] = useState<{
    fanta: Item[];
    frenzy: Item[];
    wolf: Item[];
    burst: Item[];
    holyFreeze: Item[];
  }>({ fanta: [], frenzy: [], wolf: [], burst: [], holyFreeze: [] });
  const [anzMax, setAnzMax] = useState('');
  const [resultFpa, setResultFpa] = useState('');
  const [resultFrq, setResultFrq] = useState('');

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [itemModalOpen2, setItemModalOpen2] = useState(false);

  const [dialog, setDialog] = useState<Omit<DialogProps, 'onClose'>>();

  useEffect(() => {
    neuChar();
  }, [state.classes_selected]);

  function neuChar() {
    setCharFormList(setzeCharform(state));
    setWeaponList(setzeWaffe(state));

    const result = setzeIAS(statischIAS.current, mIAS.current);
    statischIAS.current = result.statischIAS;
    setIasOptions({ offWeapon: result.ias_list, onWeapon: result.wIAS1_list, onOffHandWeapon: result.wIAS2_list });
    setzeSkill();

    const result2 = setzeSIAS(state, statischFana.current, statischFrost.current);
    statischFana.current = result2.statischFana;
    statischFrost.current = result2.statischFrost;
    setSkillIasOptions({
      fanta: result2.fanta_list,
      frenzy: result2.frenzy_list,
      wolf: result2.wolf_list,
      burst: result2.burst_list,
      holyFreeze: result2.holy_freeze_list,
    });
    console.log(result2.fanta_list);

    // doBerechneWerte();
  }

  // useEffect(() => {
  //   if (primaryWeapon) {
  //     // neuWaffe
  //     setzeBarbschwert(state);
  //     setzeSkill();
  //     doBerechneWerte();
  //     setSkillOptions(setzeSkillOption(state));
  //   }
  // }, [
  //   state.weapon_selected,
  //   state.frenzy_selected,
  //   state.burst_selected,
  //   state.fanta_selected,
  //   state.wolf_selected,
  //   state.holy_freeze_selected,
  //   state.ias_selected,
  //   state.char_form_selected,
  //   state.classes_selected,
  //   state.wIAS1_selected,
  //   state.wIAS2_selected,
  //   state.skill_selected,
  // ]);

  useEffect(() => {
    setzeBarbschwert(state);
    setzeSkill();
    doBerechneWerte();
    setSkillOptions(setzeSkillOption(state));
    setState(prev => ({ ...initialState, classes_selected: prev.classes_selected }));
    setPrimaryWeapon(undefined);
    setSecondaryWeapon({ name: 'None', value: -1 });
  }, [state.classes_selected, state.wolf_selected]);

  useEffect(() => {
    setSkillOptions(setzeSkillOption(state));
  }, [state.weapon_selected]);

  useEffect(() => {
    doBerechneWerte();
  }, [state]);

  function doBerechneWerte() {
    const res = berechneWerte(state, anzMax, secondaryWeapon.value !== -1, resultFpa, resultFrq);
    setAnzMax(res.anzMax);
    setResultFpa(res.result_fpa);
    setResultFrq(res.result_frqu);
  }

  useEffect(() => {
    setSecondWeaponList(setzeZweitwaffe(state, secondaryWeapon?.value !== -1));
  }, [state.weapon_selected]);

  useEffect(() => {
    console.log('second weapon list', secondWeaponList);
  }, [secondWeaponList]);

  function setzeSkill() {
    setState(prev => ({ ...prev, skill_selected: 0 }));
  }

  // useEffect(() => {
  //   setzeSkillOption(state);
  // }, [state.skill_selected]);

  function neuCharform() {
    setState(state => ({ ...state, skill_selected: 0 }));
    setSkillOptions(setzeSkillOption(state));
    // doBerechneWerte();
  }

  useEffect(() => {
    neuCharform();
  }, [state.char_form_selected]);

  useEffect(() => {
    console.log(state);
    console.log(resultFrq);
  }, [state, resultFrq]);

  const presentToast = (val: string) => {
    //
  };

  return (
    <AppPage title={'Attack Speed Calculator'} padding={true}>
      <Container>
        <SectionWrapper label={'Class '}>
          <Row>
            <IonSelect
              value={state.classes_selected}
              onIonChange={e => setState(prev => ({ ...prev, classes_selected: e.detail.value }))}
              interface={'popover'}
              mode={'md'}
            >
              {classes_list.map((c, i) => (
                <IonSelectOption key={`class_${c}`} value={i}>
                  {c}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonSelect
              value={state.char_form_selected}
              onIonChange={e => setState(prev => ({ ...prev, char_form_selected: e.detail.value }))}
              interface={'popover'}
              mode={'md'}
            >
              {charFormList.map((c, i) => (
                <IonSelectOption key={`char_form_${c}`} value={i}>
                  {c}
                </IonSelectOption>
              ))}
            </IonSelect>
          </Row>
        </SectionWrapper>

        <SectionWrapper label={'Primary Weapon'}>
          <PickItem
            isOpen={itemModalOpen}
            onClick={() => setItemModalOpen(prev => !prev)}
            selected={{ name: primaryWeapon?.name ?? 'Select Primary Weapon...', type: 'base_armor' }}
            classOverride={'white'}
            onDismiss={() => setItemModalOpen(false)}
            items={weaponList.map(w => ({ name: w.name, id: w.value, item_type: 'base_armor' }) as ArmorBase)}
            onSelect={item => {
              setPrimaryWeapon({ name: item.name, value: item.id });
              setState(prev => ({ ...prev, weapon_selected: item.id }));
              setItemModalOpen(false);
            }}
            filters={{
              quality: true,
            }}
          />

          <IonInput
            label={'On Weapon IAS %'}
            type={'number'}
            value={state.wIAS1_selected}
            onIonInput={e => setState(prev => ({ ...prev, wIAS1_selected: ~~(e.detail.value as string) }))}
          />
        </SectionWrapper>

        {secondWeaponList.length > 0 && (
          <SectionWrapper label={'Secondary Weapon'}>
            <PickItem
              isOpen={itemModalOpen2}
              onClick={() => setItemModalOpen2(prev => !prev)}
              selected={{ name: secondaryWeapon?.name, type: 'base_weapon' }}
              classOverride={'white'}
              onDismiss={() => setItemModalOpen2(false)}
              items={
                [
                  {
                    name: 'None',
                    id: -1,
                    item_type: 'base_armor',
                  },
                  ...secondWeaponList.map(w => ({
                    name: w.name,
                    id: w.value,
                    item_type: 'base_armor' as ItemType,
                  })),
                ] as ArmorBase[]
              }
              onSelect={item => {
                setSecondaryWeapon({ name: item.name, value: item.id });
                setState(prev => ({ ...prev, weapon_selected_second: item.id }));
                setItemModalOpen2(false);
              }}
            />

            <IonInput
              label={'On Weapon IAS %'}
              value={state.wIAS2_selected}
              type={'number'}
              onIonInput={e => setState(prev => ({ ...prev, wIAS2_selected: ~~(e.detail.value as string) }))}
            />
          </SectionWrapper>
        )}

        <SectionWrapper label={'Modifiers'}>
          <IonSelect
            label={'Off Weapon IAS %'}
            interface={'popover'}
            onIonChange={e => setState(prev => ({ ...prev, ias_selected: e.detail.value }))}
            mode={'md'}
          >
            {new Array(30).fill('').map((_, i) => (
              <IonSelectOption key={`ofwias-${i}`} value={i * 5}>
                {i * 5}
              </IonSelectOption>
            ))}
          </IonSelect>

          <IonSelect
            label={'Skill:'}
            interface={'popover'}
            onIonChange={e => setState(prev => ({ ...prev, skill_selected: e.detail.value }))}
            selectedText={skillOptions.find(s => s.value === state.skill_selected)?.name ?? ''}
            mode={'md'}
          >
            {skillOptions.map(skill => (
              <IonSelectOption key={`skill-opt-${skill.name}`} value={skill.value}>
                {skill.name}
              </IonSelectOption>
            ))}
          </IonSelect>

          <IonInput
            label={'Fanaticism: '}
            value={state.fanta_selected}
            type={'tel'}
            onIonChange={e =>
              setState(prev => ({
                ...prev,
                fanta_selected: parseInt(e.detail.value as string, 10),
              }))
            }
            className={'end'}
          />
          <IonInput
            label={'Holy Freeze: '}
            value={state.holy_freeze_selected}
            type={'tel'}
            onIonChange={e =>
              setState(prev => ({
                ...prev,
                holy_freeze_selected: parseInt(e.detail.value as string, 10),
              }))
            }
            className={'end'}
          />
          {state.classes_selected === 2 && (
            <IonInput
              label={'Frenzy: '}
              value={state.frenzy_selected}
              type={'tel'}
              onIonChange={e =>
                setState(prev => ({
                  ...prev,
                  frenzy_selected: parseInt(e.detail.value as string, 10),
                }))
              }
              className={'end'}
            />
          )}
          {skillIasOptions.wolf.length > 0 && (
            <IonInput
              label={'Wolf: '}
              value={state.wolf_selected}
              type={'tel'}
              onIonChange={e =>
                setState(prev => ({
                  ...prev,
                  wolf_selected: parseInt(e.detail.value as string, 10),
                }))
              }
              className={'end'}
            />
          )}
          {skillIasOptions.burst.length > 0 && (
            <IonInput
              label={'Burst of Speed: '}
              value={state.burst_selected}
              type={'tel'}
              onIonChange={e =>
                setState(prev => ({
                  ...prev,
                  burst_selected: parseInt(e.detail.value as string, 10),
                }))
              }
              className={'end'}
            />
          )}
        </SectionWrapper>

        <SectionWrapper label={'Result'}>
          <IonInput label={`Frames per attack: `} value={`${resultFpa} ${anzMax}`} readonly={true} />
          <IonInput label={`Attacks per second: `} value={resultFrq} readonly={true} />

          <IonButton onClick={() => berechneBreakpoints(state, anzMax, secondaryWeapon.value > 0, setDialog, presentToast)}>
            Show Table
          </IonButton>
        </SectionWrapper>
      </Container>
      <BreakPointDialog {...dialog} onClose={() => setDialog(undefined)} />
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #222;
  color: white;
`;

const Row = styled.div`
  width: 100%;
  display: flex;

  > * {
    flex: 1;
  }
`;

import { useIonViewWillEnter } from '@ionic/react';
import { useLocation } from 'react-router';
import { RefObject } from 'react';
import { SettingsHash } from '../../../AppRoutes';

export const useScrollToContainerAndHighlight = (ref: RefObject<HTMLElement>, id: SettingsHash) => {
  const { hash } = useLocation();
  useIonViewWillEnter(() => {
    if (hash === `#${id}` && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [hash]);
  return hash === `#${id}`;
};

import { HolyGrail } from './HolyGrailProvider';

export const CREATE_GRAIL_TABLE = `
    CREATE TABLE IF NOT EXISTS grail (
        id INTEGER PRIMARY KEY NOT NULL,
        item_type VARCHAR(250) NOT NULL,
        item_id INTEGER NOT NULL
    );
`;

export const UPDATE_GRAIL_TABLE_RUN_ONLY_ONCE = `
        ALTER TABLE grail RENAME to grail_old;
        CREATE TABLE grail (
            id INTEGER PRIMARY KEY NOT NULL,
            item_type VARCHAR(250) NOT NULL,
            item_id INTEGER NOT NULL
        );
        INSERT INTO grail SELECT * from grail_old;
        DROP TABLE grail_old;
`;

export const QUERY_ALL_GRAIL = `
	SELECT * FROM grail;
`;

export const INSERT_GRAIL_ITEM = `
	INSERT INTO grail (
		item_type,
		item_id
	)
	VALUES
		(?, ?)
	;
`;

// export const INSERT_GRAIL_ITEMS = (items: HolyGrail[]) => `
//     ${items.map(it => `INSERT INTO grail (item_type, item_id) VALUES ('${it.item_type}', ${it.item_id});`).join(' ')}
// `;

export const INSERT_GRAIL_ITEMS = (items: HolyGrail[]) => `
    INSERT INTO grail
        (item_type, item_id)
    VALUES
        ${items.map(item => `('${item.item_type}', ${item.item_id})`).join(', ')}
`;

export const DELETE_GRAIL_ITEMS = (item_type: 'set' | 'unique', item_ids: number[]) => `
    DELETE FROM 
        grail
    WHERE
        item_type = '${item_type}'
    AND
        item_id
    IN
        (${item_ids})
`;

export const DELETE_GRAIL_ITEM = (id: number) => `
	DELETE FROM grail WHERE id = ${id};
`;

export const QUERY_GRAIL_ITEM = (itemId: number) => `
    SELECT * FROM grail WHERE item_id = ${itemId};
`;

export const DROP_GRAIL_TABLE = `DROP TABLE grail`;

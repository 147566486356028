import styled from 'styled-components/macro';
import React from 'react';

export const ItemCard = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 12px;
  border-radius: 6px;
  gap: 8px;
  margin-bottom: 10px;
`;

export const ItemTitle = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 700;
`;

export const ItemSubType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--base);
  font-size: 14px;
  font-weight: 600;
`;

export const ItemStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  font-size: 16px;
  white-space: pre-wrap;
  text-align: center;
  margin: 8px 0;
  word-break: keep-all;
  line-height: 22px;

  > div > span {
    color: #808080ff;
  }
`;

export const ItemNotes = styled.div`
  color: var(--muted);
  font-size: 14px;
  white-space: pre-wrap;
`;

export const ItemFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;

  > * {
    cursor: pointer;
  }
`;

const Wrapper = styled.div<{ label: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //border: 2px solid #3eb3bf;
    border-radius: 8px;
    padding: 20px 12px;
    margin: 20px 0 16px;
    position: relative;
    box-shadow: inset #3eb3bf 0 0 10px 4px;

    &:before {
        content: '${props => props.label}';
        position: absolute;
        top: 0;
        background: #222;
        padding: 2px 8px;
        color: #0bff9b;
        max-width: 90%;
        white-space: break-spaces;
        transform: translateY(-50%);
        font-size: 18px;
        border-radius: 10px;
        overflow: visible;
        box-shadow: #3eb3bf 0 0 5px 1px,
        inset #3eb3bf 0 0 3px 1px;
        text-align: center;
    }
}`;

const SectionEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 10px;
  background: #222;
  padding: 6px 6px;
  color: white;
  max-width: 90%;
  white-space: break-spaces;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset #3eb3bf 0 0 8px 0px;
  aspect-ratio: 1 / 1;
`;

export const SectionWrapper: React.FC<{
  label: string;
  style?: React.CSSProperties;
  end?: React.ReactElement;
  children: React.ReactNode;
}> = ({ label, style, end, children }) => {
  return (
    <Wrapper label={label} style={style}>
      {children}
      {end && <SectionEnd>{end}</SectionEnd>}
    </Wrapper>
  );
};

export const ItemMuleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ItemMule = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &:before {
    content: '';
    background-image: url('/images/icons/mule.svg');
    background-size: contain;
    background-repeat: no-repeat;
    filter: var(--filter-base);
    transform: scaleX(-1) rotate(-10deg);
  }
`;

export const ItemMuleCount = styled(ItemMule)<{ count: number }>`
  &:before {
    width: 40px;
    height: 40px;
  }

  &:after {
    content: '${props => props.count}';
    font-size: 18px;
    color: var(--base);
    font-weight: 700;
  }
`;

export const ItemMuleName = styled(ItemMule)<{ name: string | null }>`
  &:before {
    width: 25px;
    height: 25px;
  }

  &:after {
    content: '${props => props.name ?? ''}';
    font-size: 20px;
    font-weight: 700;
    color: var(--base);
  }
`;

export const TitleButton = styled.button`
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-primary);
  background: transparent;
`;

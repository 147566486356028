import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { useGrailSets, useGrailUniques } from '../../common/providers/providerHooks';
import HgTable from './components/HGTable';
import HgAllTable from './components/HGAllTable';

export const HolyGrail: React.FC = () => {
  const uniqueData = useGrailUniques();
  const setData = useGrailSets();
  return (
    <AppPage padding={true} title={'Holy Grail'}>
      <Container>
        <Header className={'unique'}>Uniques</Header>
        <HgTable {...uniqueData} type={'unique'} />
        <Header className={'set'}>Sets</Header>
        <HgTable {...setData} type={'set'} />

        <Header>All</Header>
        <HgAllTable />
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;

  & + & {
    margin-top: 10px;
  }
`;

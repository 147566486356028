import React from 'react';
import { IonAccordion, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { Filter } from '../../../common/types';

interface Props {
  value: Filter<HomeSection, 'grail'>;
}

export const HomeGrailSettings: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();

  const handleToggleShow = () => {
    dispatch(setHomeSettings({ ...homeSettings, grail: { ...homeSettings.grail, show: !homeSettings.grail.show } }));
  };
  const handleToggleShowUniques = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        grail: { ...homeSettings.grail, showUniques: !homeSettings.grail.showUniques },
      }),
    );
  };
  const handleToggleShowSets = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        grail: { ...homeSettings.grail, showSets: !homeSettings.grail.showSets },
      }),
    );
  };
  const handleToggleShowSummary = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        grail: { ...homeSettings.grail, showSummary: !homeSettings.grail.showSummary },
      }),
    );
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Holy Grail Progress</IonLabel>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.grail.show} onIonChange={handleToggleShow}>
          Show
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.grail.showUniques} onIonChange={handleToggleShowUniques}>
          Show Uniques
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.grail.showSets} onIonChange={handleToggleShowSets}>
          Show Sets
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.grail.showSummary} onIonChange={handleToggleShowSummary}>
          Show Summary
        </IonToggle>
      </IonItem>
    </IonAccordion>
  );
};

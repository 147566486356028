import { CraftingSettingsState, HomeSettingsState, ItemCatalogSetting, SettingsPageOrder } from '../store/settings/settingsSlice';
import { DropCalcData, Result } from '../pages/drop-calc/DropCalc';

export interface SavedDropCalcData {
  data: DropCalcData;
  results: Result[];
  segment: string;
  monsterName?: string;
}

export class LocalStorage {
  private static readonly KEY_SYNC_GRAIL_ITEM_STORE = 'sync_grail_item_store';
  private static readonly KEY_ADD_MULE_RUNES_TO_RC = 'add_mule_runes_to_rc';
  private static readonly KEY_HOME_SETTINGS_STATE = `home_settings`;
  private static readonly KEY_LAST_PATCH_USED = 'last_patch_used';
  private static readonly KEY_LAST_GAME_MODE = 'last_game_mode';
  private static readonly KEY_FAVE_RW = 'fave_rw_';
  private static readonly KEY_LAST_ROUTE = 'last_route';
  private static readonly KEY_DROP_CALC_DATA = 'drop_calc_data';
  private static readonly KEY_IS_PRO = 'is_pro';
  private static readonly HIDE_ALERT = 'hide_alert_';
  private static readonly GRAIL_TABLE_VERSION = 'grail_table_version';
  private static readonly KEY_CRAFTING_SETTINGS_STATE = 'crafting_settings';
  private static readonly KEY_PAGES_ORDER = 'pages_order';
  private static readonly KEY_TAB_BAR_ACTION = 'tab_bar_action';
  private static readonly KEY_TAB_BAR_ACTION2 = 'tab_bar_action2';
  private static readonly KEY_DB_REPAIR = 'db_repair';
  private static readonly KEY_ITEM_CATALOG_SETTINGS = 'item_catalog_settings';

  public static setSyncGrailItemStore = (sync: boolean) => localStorage.setItem(this.KEY_SYNC_GRAIL_ITEM_STORE, sync ? 'true' : 'false');
  public static getSyncGrailItemStore = (): boolean | null => {
    const sync = localStorage.getItem(this.KEY_SYNC_GRAIL_ITEM_STORE);
    if (sync === 'true') return true;
    if (sync === 'false') return false;
    return null;
  };

  public static setAddMuleRunesToRc = (value: boolean) => localStorage.setItem(this.KEY_ADD_MULE_RUNES_TO_RC, value ? 'true' : 'false');
  public static getAddMuleRunesToRc = () => {
    const val = localStorage.getItem(this.KEY_ADD_MULE_RUNES_TO_RC);
    if (val === null) return val;
    return val === 'true';
  };

  public static getStoredHomeSettings = (): HomeSettingsState | undefined => {
    const res = localStorage.getItem(this.KEY_HOME_SETTINGS_STATE);
    if (res) {
      return JSON.parse(res);
    }
  };
  public static setStoredHomeSettings = (val: HomeSettingsState) => localStorage.setItem(this.KEY_HOME_SETTINGS_STATE, JSON.stringify(val));

  public static getDontUseLatestPatch = () => {
    const settings = this.getStoredHomeSettings();
    if (settings) return !settings.game.useLatestPatch;
    return false;
  };

  public static setLastPatchedUsed = (val: number) => localStorage.setItem(this.KEY_LAST_PATCH_USED, val.toString(10));
  public static getLastPatchUsed = () => {
    const res = localStorage.getItem(this.KEY_LAST_PATCH_USED);
    if (res) return ~~res;
  };

  public static setLastGameMode = (val: 'd2r' | 'classic') => localStorage.setItem(this.KEY_LAST_GAME_MODE, val);
  public static getLastGameMode = () => localStorage.getItem(this.KEY_LAST_GAME_MODE) ?? 'd2r';

  public static getFaveRuneword = (id: number) => !!localStorage.getItem(`${this.KEY_FAVE_RW}${id}`);

  public static setFaveRuneword = (id: number) => {
    if (this.getFaveRuneword(id)) localStorage.removeItem(`${this.KEY_FAVE_RW}${id}`);
    else localStorage.setItem(`${this.KEY_FAVE_RW}${id}`, 'true');
  };

  public static getAllFaveRunewords = () => {
    const keys = Object.entries(localStorage).map(([k]) => (k.startsWith(this.KEY_FAVE_RW) ? k : null));
    return keys.filter(k => !!k).map(k => ~~k!.substring(this.KEY_FAVE_RW.length));
  };

  public static setLastRoute = (route: string) => localStorage.setItem(this.KEY_LAST_ROUTE, route);
  public static getLastRoute = () => localStorage.getItem(this.KEY_LAST_ROUTE);

  public static getSavedDropCalcData = (): SavedDropCalcData | null => {
    const res = localStorage.getItem(this.KEY_DROP_CALC_DATA);
    if (res) return JSON.parse(res);
    return null;
  };

  public static setSavedDropCalcData = (data: SavedDropCalcData) => localStorage.setItem(this.KEY_DROP_CALC_DATA, JSON.stringify(data));

  public static setIsPro = () => localStorage.setItem(this.KEY_IS_PRO, 'true');
  public static getIsPro = () => localStorage.getItem(this.KEY_IS_PRO) !== null;

  public static getHideAlert = (id: string) => localStorage.getItem(`${this.HIDE_ALERT}${id}`) !== null;
  public static setHideAlert = (id: string) => localStorage.setItem(`${this.HIDE_ALERT}${id}`, 'true');

  public static getGrailTableVersion = () => localStorage.getItem(this.GRAIL_TABLE_VERSION);
  public static setGrailTableVersion = (version: string) => localStorage.setItem(this.GRAIL_TABLE_VERSION, version);

  public static getCraftingSettings = (): CraftingSettingsState | null => {
    const res = localStorage.getItem(this.KEY_CRAFTING_SETTINGS_STATE);
    if (res) return JSON.parse(res);
    return null;
  };
  public static setCraftingSettings = (settings: CraftingSettingsState) =>
    localStorage.setItem(this.KEY_CRAFTING_SETTINGS_STATE, JSON.stringify(settings));

  public static getPagesOrder = (): SettingsPageOrder[] | null => {
    const res = localStorage.getItem(this.KEY_PAGES_ORDER);
    if (res) return JSON.parse(res) as SettingsPageOrder[];
    return null;
  };

  public static setPagesOrder = (order: SettingsPageOrder[]) => localStorage.setItem(this.KEY_PAGES_ORDER, JSON.stringify(order));

  public static getTabBarAction = (): SettingsPageOrder | null => localStorage.getItem(this.KEY_TAB_BAR_ACTION) as SettingsPageOrder | null;
  public static setTabBarAction = (action: SettingsPageOrder) => localStorage.setItem(this.KEY_TAB_BAR_ACTION, action);

  public static getTabBarAction2 = (): SettingsPageOrder | null =>
    localStorage.getItem(this.KEY_TAB_BAR_ACTION2) as SettingsPageOrder | null;
  public static setTabBarAction2 = (action: SettingsPageOrder) => localStorage.setItem(this.KEY_TAB_BAR_ACTION2, action);

  public static getItemCatalogSettings = (): ItemCatalogSetting | null => {
    const res = localStorage.getItem(this.KEY_ITEM_CATALOG_SETTINGS);
    if (res) return JSON.parse(res);
    return null;
  };

  public static setItemCatalogSettings = (val: ItemCatalogSetting) =>
    localStorage.setItem(this.KEY_ITEM_CATALOG_SETTINGS, JSON.stringify(val));

  public static getDbRepair = () => localStorage.getItem(this.KEY_DB_REPAIR);
  public static setDbRepair = (version: string) => localStorage.setItem(this.KEY_DB_REPAIR, version);
}

export class SessionsStorage {
  private static KEY_DONT_PRESENT_MULE_OPTION = 'dont_present_mule_option';

  public static setDontPresentMuleOption = () => sessionStorage.setItem(this.KEY_DONT_PRESENT_MULE_OPTION, 'true');
  public static getDontPresentMuleOption = () => !!sessionStorage.getItem(this.KEY_DONT_PRESENT_MULE_OPTION);
}

import { Gem } from '../../types';

export const ALL_GEMS: Gem[] = [
  {
    name: 'Chipped Topaz',
    quality: 'chipped',
    type: 'topaz',
    item_type: 'gem',
    id: 1,
    props: {
      armor: '+9% Chance to Find Magic Items',
      shield: '12% Resist Lightning',
      weapon: '1-8 Lightning Damage',
    },
  },
  {
    name: 'Chipped Sapphire',
    quality: 'chipped',
    type: 'sapphire',
    item_type: 'gem',
    id: 2,
    props: {
      armor: '+10 Max Mana',
      shield: '12% Resist Cold',
      weapon: '1-3 Cold Damage, 1.0 sec Duration',
    },
  },
  {
    name: 'Chipped Emerald',
    quality: 'chipped',
    type: 'emerald',
    item_type: 'gem',
    id: 3,
    props: {
      armor: '+3 to Dexterity',
      shield: '12% Resist Poison',
      weapon: '+10 Poison Damage over 3 Seconds',
    },
  },
  {
    name: 'Chipped Ruby',
    quality: 'chipped',
    type: 'ruby',
    item_type: 'gem',
    id: 4,
    props: {
      armor: '+10 to Life',
      shield: '12% Resist Fire',
      weapon: '3-4 Fire Damage',
    },
  },
  {
    name: 'Chipped Diamond',
    quality: 'chipped',
    type: 'diamond',
    item_type: 'gem',
    id: 5,
    props: {
      armor: '+20 to attack Rating',
      shield: 'All Resistances +6%',
      weapon: '+28% Damage vs. Undead',
    },
  },
  {
    name: 'Chipped Amethyst',
    quality: 'chipped',
    type: 'amethyst',
    item_type: 'gem',
    id: 6,
    props: {
      armor: '+3 to Strength',
      shield: '+8 to Defense',
      weapon: '+40 to Attack Rating',
    },
  },
  {
    name: 'Chipped Skull',
    quality: 'chipped',
    type: 'skull',
    item_type: 'gem',
    id: 7,
    props: {
      armor: 'Replenish Life +2, Regenerate Mana 8%',
      shield: 'Attacker Takes 4 Damage',
      weapon: 'Steals 2% life, 1% Mana',
    },
  },
  {
    name: 'Flawed Topaz',
    quality: 'flawed',
    type: 'topaz',
    item_type: 'gem',
    id: 8,
    props: {
      armor: '+13% Chance to Find Magic Items',
      shield: '16% Resist Lightning',
      weapon: '1-14 Lightning Damage',
    },
  },
  {
    name: 'Flawed Sapphire',
    quality: 'flawed',
    type: 'sapphire',
    item_type: 'gem',
    id: 9,
    props: {
      armor: '+17 Max Mana',
      shield: '16% Resist Cold',
      weapon: '3-5 Cold Damage, 1.4 sec Duration',
    },
  },
  {
    name: 'Flawed Emerald',
    quality: 'flawed',
    type: 'emerald',
    item_type: 'gem',
    id: 10,
    props: {
      armor: '+4 to Dexterity',
      shield: '16% Resist Poison',
      weapon: '+20 Poison Damage over 4 Seconds',
    },
  },
  {
    name: 'Flawed Ruby',
    quality: 'flawed',
    type: 'ruby',
    item_type: 'gem',
    id: 11,
    props: {
      armor: '+17 to Life',
      shield: '16% Resist Fire',
      weapon: '5-8 Fire Damage',
    },
  },
  {
    name: 'Flawed Diamond',
    quality: 'flawed',
    type: 'diamond',
    item_type: 'gem',
    id: 12,
    props: {
      armor: '+40 to attack Rating',
      shield: 'All Resistances +8%',
      weapon: '+34% Damage vs. Undead',
    },
  },
  {
    name: 'Flawed Amethyst',
    quality: 'flawed',
    type: 'amethyst',
    item_type: 'gem',
    id: 13,
    props: {
      armor: '+4 to Strength',
      shield: '+12 to Defense',
      weapon: '+60 to Attack Rating',
    },
  },
  {
    name: 'Flawed Skull',
    quality: 'flawed',
    type: 'skull',
    item_type: 'gem',
    id: 14,
    props: {
      armor: 'Replenish Life +3, Regenerate Mana 8%',
      shield: 'Attacker Takes 8 Damage',
      weapon: 'Steals 2% life, 2% Mana',
    },
  },
  {
    name: 'Topaz',
    quality: 'normal',
    type: 'topaz',
    item_type: 'gem',
    id: 15,
    props: {
      armor: '+16% Chance to Find Magic Items',
      shield: '22% Resist Lightning',
      weapon: '1-22 Lightning Damage',
    },
  },
  {
    name: 'Sapphire',
    quality: 'normal',
    type: 'sapphire',
    item_type: 'gem',
    id: 16,
    props: {
      armor: '+24 Max Mana',
      shield: '22% Resist Cold',
      weapon: '4-7 Cold Damage, 2.0 sec Duration',
    },
  },
  {
    name: 'Emerald',
    quality: 'normal',
    type: 'emerald',
    item_type: 'gem',
    id: 17,
    props: {
      armor: '+6 to Dexterit',
      shield: '22% Resist Poison',
      weapon: '+40 Poison Damage over 5 Seconds',
    },
  },
  {
    name: 'Ruby',
    quality: 'normal',
    type: 'ruby',
    item_type: 'gem',
    id: 18,
    props: {
      armor: '+24 to Life',
      shield: '22% Resist Fire',
      weapon: '8-12 Fire Damage',
    },
  },
  {
    name: 'Diamond',
    quality: 'normal',
    type: 'diamond',
    item_type: 'gem',
    id: 19,
    props: {
      armor: '+60 to attack Rating',
      shield: 'All Resistances +11%',
      weapon: '+44% Damage vs. Undead ',
    },
  },
  {
    name: 'Amethyst',
    quality: 'normal',
    type: 'amethyst',
    item_type: 'gem',
    id: 20,
    props: {
      armor: '+6 to Strength',
      shield: '+18 to Defense',
      weapon: '+80 to Attack Rating',
    },
  },
  {
    name: 'Skull',
    quality: 'normal',
    type: 'skull',
    item_type: 'gem',
    id: 21,
    props: {
      armor: 'Replenish Life +3, Regenerate Mana 12%',
      shield: 'Attacker Takes 12 Damage',
      weapon: 'Steals 3% life, 2% Mana',
    },
  },
  {
    name: 'Flawless Topaz',
    quality: 'flawless',
    type: 'topaz',
    item_type: 'gem',
    id: 22,
    props: {
      armor: '+20% Chance to Find Magic Items',
      shield: '28% Resist Lightning',
      weapon: '1-30 Lightning Damage',
    },
  },
  {
    name: 'Flawless Sapphire',
    quality: 'flawless',
    type: 'sapphire',
    item_type: 'gem',
    id: 23,
    props: {
      armor: '+31 Max Mana',
      shield: '28% Resist Cold',
      weapon: '6-10 Cold Damage, 2.4 sec Duration',
    },
  },
  {
    name: 'Flawless Emerald',
    quality: 'flawless',
    type: 'emerald',
    item_type: 'gem',
    id: 24,
    props: {
      armor: '+8 to Dexterity',
      shield: '28% Resist Poison',
      weapon: '+60 Poison Damage over 6 Seconds',
    },
  },
  {
    name: 'Flawless Ruby',
    quality: 'flawless',
    type: 'ruby',
    item_type: 'gem',
    id: 25,
    props: {
      armor: '+31 to Life',
      shield: '28% Resist Fire',
      weapon: '10-16 Fire Damage',
    },
  },
  {
    name: 'Flawless Diamond',
    quality: 'flawless',
    type: 'diamond',
    item_type: 'gem',
    id: 26,
    props: {
      armor: '+80 to attack Rating',
      shield: 'All Resistances +14%',
      weapon: '+54% Damage vs. Undead',
    },
  },
  {
    name: 'Flawless Amethyst',
    quality: 'flawless',
    type: 'amethyst',
    item_type: 'gem',
    id: 27,
    props: {
      armor: '+8 to Strength',
      shield: '+24 to Defense',
      weapon: '+100 to Attack Rating',
    },
  },
  {
    name: 'Flawless Skull',
    quality: 'flawless',
    type: 'skull',
    item_type: 'gem',
    id: 28,
    props: {
      armor: 'Replenish Life +4, Regenerate Mana 12%',
      shield: 'Attacker Takes 16 Damage',
      weapon: 'Steals 3% life, 3% Mana',
    },
  },
  {
    name: 'Perfect Topaz',
    quality: 'perfect',
    type: 'topaz',
    item_type: 'gem',
    id: 29,
    props: {
      armor: '+24% Chance to Find Magic Items',
      shield: '40% Resist Lightning',
      weapon: '1-40 Lightning Damage',
    },
  },
  {
    name: 'Perfect Sapphire',
    quality: 'perfect',
    type: 'sapphire',
    item_type: 'gem',
    id: 30,
    props: {
      armor: '+38 Max Mana',
      shield: '40% Resist Cold',
      weapon: '10-14 Cold Damage, 3.0 sec Duration',
    },
  },
  {
    name: 'Perfect Emerald',
    quality: 'perfect',
    type: 'emerald',
    item_type: 'gem',
    id: 31,
    props: {
      armor: '+10 to Dexterity',
      shield: '40% Resist Poison',
      weapon: '+100 Poison Damage over 7 Seconds',
    },
  },
  {
    name: 'Perfect Ruby',
    quality: 'perfect',
    type: 'ruby',
    item_type: 'gem',
    id: 32,
    props: {
      armor: '+38 to Life',
      shield: '40% Resist Fire',
      weapon: '15-20 Fire Damage',
    },
  },
  {
    name: 'Perfect Diamond',
    quality: 'perfect',
    type: 'diamond',
    item_type: 'gem',
    id: 33,
    props: {
      armor: '+100 to attack Rating',
      shield: 'All Resistances +19%',
      weapon: '+68% Damage vs. Undead',
    },
  },
  {
    name: 'Perfect Amethyst',
    quality: 'perfect',
    type: 'amethyst',
    item_type: 'gem',
    id: 34,
    props: {
      armor: '+10 to Strength',
      shield: '+30 to Defense',
      weapon: '+150 to Attack Rating',
    },
  },
  {
    name: 'Perfect Skull',
    quality: 'perfect',
    type: 'skull',
    item_type: 'gem',
    id: 35,
    props: {
      armor: 'Replenish Life +5, Regenerate Mana 19%',
      shield: 'Attacker Takes 20 Damage',
      weapon: 'Steals 4% life, 3% Mana',
    },
  },
];

import { GemQuality, GemType } from '../../components/GemImage';

export interface Craft {
  name: string;
  base_item: string;
  rune: number;
  gem: {
    type: GemType;
    quality: GemQuality;
  };
  result: string;
  notes?: string;
}

export const blood_recipes: Craft[] = [
  {
    name: 'Blood Weapon',
    base_item: 'Any Axe',
    rune: 8,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-4% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + '+35-60% Damage',
  },
  {
    name: 'Blood Headgear',
    base_item: 'Helm' + '<br>' + 'Casque' + '<br>' + 'Armet',
    rune: 7,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-4% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + '5-10% chance of Deadly Strike',
  },
  {
    name: ' Blood Armor',
    base_item: 'Plate Mail' + '<br>' + 'Templar Plate' + '<br>' + 'Hellforge Plate',
    rune: 9,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-3% Life Steal' + '<br>' + '+10-20 Hit points' + '<br>' + '1-3 Demon Heal',
    notes: '"Demon Heal" means you gain 1-3 hit points for every Demon death, similar to the +mana per kill modifiers',
  },
  {
    name: 'Blood Gloves',
    base_item: 'Heavy Gloves' + '<br>' + 'Sharkskin Gloves' + '<br>' + 'Vampirebone Gloves',
    rune: 3,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-3% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + '5-10% Crushing Blow',
  },
  {
    name: 'Blood Belt',
    base_item: 'Belt' + '<br>' + 'Mesh Belt' + '<br>' + 'Mithril Coil',
    rune: 6,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-3% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + 'Open Wounds 5-10%',
  },
  {
    name: ' Blood Boots',
    base_item: 'Light Plated Boots' + '<br>' + 'Battle Boots' + '<br>' + 'Mirrored Boots',
    rune: 4,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-3% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + '+5-10 Life Regeneration',
  },
  {
    name: ' Blood Shield',
    base_item: 'Spiked Shield' + '<br>' + 'Barbed Shield' + '<br>' + 'Blade Barrier',
    rune: 5,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-4% Life Steal' + '<br>' + '+10-20 Hit Points' + '<br>' + 'Attacker takes 4-7 damage',
  },
  {
    name: 'Blood Amulet',
    base_item: ' Amulet',
    rune: 10,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-4% Life Steal' + '<br>' + '+10-20 Hit Points' + '<br>' + '5-10% Faster Walk/Run',
  },
  {
    name: 'Blood Ring',
    base_item: 'Ring',
    rune: 11,
    gem: {
      type: 'ruby',
      quality: 'perfect',
    },
    result: '1-3% Life Leech' + '<br>' + '+10-20 Hit Points' + '<br>' + '+1-5 Strength',
  },
];

export const caster_recipes: Craft[] = [
  {
    name: 'Caster Weapon',
    base_item: 'Rod',
    rune: 2,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + '+1-5% Mana Steal',
    notes: ' Rod = Scepter, Staff, or Wand (Not Orbs). ',
  },
  {
    name: 'Caster Headgear',
    base_item: 'Mask' + '<br>' + 'Death Mask' + '<br>' + 'Demonhead',
    rune: 3,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + '1-4% Mana Steal',
  },
  {
    name: 'Caster Armor',
    base_item: 'Light Plate' + '<br>' + 'Mage Plate' + '<br>' + 'Archon Plate',
    rune: 6,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: 'Regenerate Mana 4-12%' + '<br>' + '+10-20 Mana' + '<br>' + '+1-3 Mana Per Kill',
  },
  {
    name: 'Caster Gloves',
    base_item: 'Leather Gloves' + '<br>' + 'Demonhide Gloves' + '<br>' + 'or Bramble Mitts',
    rune: 8,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '+4-10% Faster Mana Regen' + '<br>' + '+10-20 Mana' + '<br>' + '+1-3 Mana Per Kill',
  },
  {
    name: 'Caster Belt',
    base_item: 'Light Belt' + '<br>' + 'Sharkskin Belt' + '<br>' + ' Vampirefang Belt',
    rune: 5,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + '+5-10% Faster Cast',
  },
  {
    name: 'Caster Boots',
    base_item: 'Boots' + '<br>' + 'Demonhide Boots' + '<br>' + 'Wyrmhide Boots',
    rune: 9,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: 'Regenerate Mana 4-10%' + '<br>' + 'Mana +10-20' + '<br>' + 'Max Mana +2-5%',
  },
  {
    name: 'Caster Shield',
    base_item: 'Small Shield' + '<br>' + 'Round Shield' + '<br>' + 'Luna',
    rune: 4,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '+4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + '+5-10 Blocking Percentage',
  },
  {
    name: 'Caster Amulet',
    base_item: 'Amulet',
    rune: 7,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + 'Faster Cast 5-10%',
  },
  {
    name: 'Caster Ring',
    base_item: 'Ring',
    rune: 10,
    gem: {
      type: 'amethyst',
      quality: 'perfect',
    },
    result: '4-10% Regenerate Mana' + '<br>' + '+10-20 Mana' + '<br>' + '+1-5 Energy',
  },
];

export const hitpower_recipes: Craft[] = [
  {
    name: 'Hitpower Weapon',
    base_item: 'Blunt Weapon',
    rune: 2,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker Takes 3-7 Damage' + '<br>' + '+35-60% Enhanced Damage',
    notes: 'Blunt = All maces, rods, etc. Everything that has 150% damage vs. Undead excluding Orbs.',
  },
  {
    name: 'Hitpower Headgear',
    base_item: 'Full Helm' + '<br>' + 'Basinet' + '<br>' + 'Giant Conch',
    rune: 5,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result:
      '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-7 damage' + '<br>' + '+25-50 Defense vs. Missiles',
  },
  {
    name: 'Hitpower Armor',
    base_item: 'Field Plate' + '<br>' + 'Sharktooth' + '<br>' + ' Kraken Shell',
    rune: 3,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result:
      '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-10 damage' + '<br>' + '10-20% Faster Hit Recovery',
  },
  {
    name: 'Hitpower Gloves',
    base_item: 'Chain Gloves' + '<br>' + 'Heavy Bracers' + '<br>' + 'Vambraces',
    rune: 8,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + '+3-7 Damage to Attacker' + '<br>' + 'Knockback',
  },
  {
    name: 'Hitpower Belt',
    base_item: 'Heavy Belt' + '<br>' + 'Battle Belt' + '<br>' + 'Troll Belt',
    rune: 6,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-7 damage' + '<br>' + '5-10% Damage to Mana',
  },
  {
    name: 'Hitpower Boots',
    base_item: 'Chain Boots' + '<br>' + 'Mesh Boots' + '<br>' + 'Boneweave',
    rune: 7,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-7 damage' + '<br>' + '+25-60 Defense vs. Melee',
  },
  {
    name: 'Hitpower Shield',
    base_item: 'Gothic Shield' + '<br>' + 'Ancient' + '<br>' + 'or Ward',
    rune: 4,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-10 damage' + '<br>' + '+5-10% Blocking',
  },
  {
    name: 'Hitpower Amulet',
    base_item: 'Amulet',
    rune: 9,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result:
      '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-10 damage' + '<br>' + '5-15% Chance of Monster Flee ',
  },
  {
    name: 'Hitpower Ring',
    base_item: 'Ring',
    rune: 10,
    gem: {
      type: 'sapphire',
      quality: 'perfect',
    },
    result: '5% chance of casting Slvl 4 Frost Nova when hit' + '<br>' + 'Attacker takes 3-6 damage' + '<br>' + '+1-5 Dexterity',
  },
];

export const safety_recipes: Craft[] = [
  {
    name: 'Safety Weapon',
    base_item: 'Spear',
    rune: 11,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduced Damage 1-4' + '<br>' + 'Reduced Magic Damage 1-2' + '<br>' + '+5-10% Defense',
  },
  {
    name: 'Safety headgear',
    base_item: 'Crown' + '<br>' + 'Grand Crown' + '<br>' + ' Corona',
    rune: 5,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result:
      'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '+5-10% Lightning Resistance' + '<br>' + '+10-30% Defense',
  },
  {
    name: 'Safety Armor',
    base_item: 'Breast Plate' + '<br>' + 'Curiass' + '<br>' + 'Great Hauberk',
    rune: 4,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Magic Damage 2-5' + '<br>' + 'Half Freeze Duration' + '<br>' + 'Increased Defense 10-30%',
  },
  {
    name: 'Saefty Gloves',
    base_item: 'Gauntlets' + '<br>' + 'War Gauntlets' + '<br>' + 'Ogre Gauntlets',
    rune: 7,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '+5-10% Resist Cold' + '<br>' + '+10-33% Defense',
  },
  {
    name: 'Safety Belt',
    base_item: 'Sash' + '<br>' + 'Demonhide Sash' + '<br>' + 'Spiderweb Sash',
    rune: 6,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 3-9' + '<br>' + 'Reduces Magic Damage 2-5' + '<br>' + '5-10% Resist Poison' + '<br>' + '+10-30% Defense',
  },
  {
    name: 'Safety Boots',
    base_item: 'Greaves' + '<br>' + 'War Boots' + '<br>' + 'Myrmidon Boots',
    rune: 8,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '5-10% Fire Resistance' + '<br>' + '+10-30% Defense',
  },
  {
    name: 'Safety Shields',
    base_item: 'Kite Shield' + '<br>' + 'Dragon Shield' + '<br>' + 'Monarch',
    rune: 3,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '5-10% Resist Magic' + '<br>' + '+10-30% Defense',
  },
  {
    name: 'Safety Amulet',
    base_item: 'Amulet',
    rune: 9,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '+1-10% Blocking',
  },
  {
    name: 'Safety Ring',
    base_item: 'Ring',
    rune: 10,
    gem: {
      type: 'emerald',
      quality: 'perfect',
    },
    result: 'Reduces Damage 1-4' + '<br>' + 'Reduces Magic Damage 1-2' + '<br>' + '+1-5 Vitality',
  },
];

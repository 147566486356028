import React, { useContext } from 'react';
import { BaseItem, Filter, Item } from '../../../common/types';
import { useAppDispatch } from '../../../store/store';
import { HolyGraiLContext } from '../../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { setPickItemModal } from '../../../store/app/appSlice';
import { Cell, Table } from './styled';
import { ItemType } from '../../../common/providers/ItemProvider/ItemProvider';

interface Props {
  totalArmor: number;
  totalWeapons: number;
  totalOther: number;
  foundArmor: BaseItem[];
  foundWeapons: BaseItem[];
  foundOther: BaseItem[];
  unFoundWeapons: BaseItem[];
  unFoundArmor: BaseItem[];
  unFoundOther: BaseItem[];
  type: Filter<ItemType, 'unique' | 'set'>;
}

const HgTable: React.FC<Props> = ({
  totalArmor,
  totalWeapons,
  totalOther,
  foundArmor,
  foundWeapons,
  foundOther,
  unFoundWeapons,
  unFoundArmor,
  unFoundOther,
  type,
}) => {
  const dispatch = useAppDispatch();
  const { addGrailItems, removeGrailItems } = useContext(HolyGraiLContext);

  const existArmorsClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: [...foundArmor, ...unFoundArmor],
        itemType: type,
        multipleSelect: {
          selectedIds: foundArmor.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundArmor.filter(a => !newItems.some(n => n.id === a.id));
            const added = newItems.filter(n => !foundArmor.some(a => a.id === n.id));
            console.log(added, deleted);
            doDeleteItems(deleted).then(() => doAddItems(added));
          },
        },
      }),
    );
  };

  const existWeaponsClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: [...foundWeapons, ...unFoundWeapons],
        itemType: type,
        multipleSelect: {
          selectedIds: foundWeapons.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundWeapons.filter(a => !newItems.some(n => n.id === a.id));
            const added = newItems.filter(n => !foundWeapons.some(a => a.id === n.id));
            doDeleteItems(deleted).then(() => doAddItems(added));
          },
        },
      }),
    );
  };

  const existOtherClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: [...foundOther, ...unFoundOther],
        itemType: type,
        multipleSelect: {
          selectedIds: foundOther.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundOther.filter(a => !newItems.some(n => n.id === a.id));
            const added = newItems.filter(n => !foundOther.some(a => a.id === n.id));
            doDeleteItems(deleted).then(() => doAddItems(added));
          },
        },
      }),
    );
  };

  const foundArmorClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: foundArmor,
        itemType: type,
        multipleSelect: {
          selectedIds: foundArmor.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundArmor.filter(a => !newItems.some(n => n.id === a.id));
            doDeleteItems(deleted);
          },
        },
      }),
    );
  };

  const foundWeaponClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: foundWeapons,
        itemType: type,
        multipleSelect: {
          selectedIds: foundWeapons.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundWeapons.filter(a => !newItems.some(n => n.id === a.id));
            doDeleteItems(deleted);
          },
        },
      }),
    );
  };

  const foundOtherClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: foundOther,
        itemType: type,
        multipleSelect: {
          selectedIds: foundOther.map(a => a.id),
          onSelect: newItems => {
            const deleted = foundOther.filter(a => !newItems.some(n => n.id === a.id));
            doDeleteItems(deleted);
          },
        },
      }),
    );
  };

  const remainingArmorClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: unFoundArmor,
        itemType: type,
        multipleSelect: {
          selectedIds: [],
          onSelect: newItems => {
            doAddItems(newItems);
          },
        },
      }),
    );
  };
  const remainingWeaponClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: unFoundWeapons,
        itemType: type,
        multipleSelect: {
          selectedIds: [],
          onSelect: newItems => {
            doAddItems(newItems);
          },
        },
      }),
    );
  };
  const remainingOtherClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: unFoundOther,
        itemType: type,
        multipleSelect: {
          selectedIds: [],
          onSelect: newItems => {
            doAddItems(newItems);
          },
        },
      }),
    );
  };

  const doAddItems = async (items: Item[]) => {
    await addGrailItems(items.map(item => ({ id: -1, item_id: item.id, item_type: type })));
  };
  const doDeleteItems = async (items: Item[]) => {
    await removeGrailItems(items.map(item => ({ id: -1, item_id: item.id, item_type: type })));
  };

  const calcPercent = (numerator: number, denominator: number) => {
    const val = Math.round((numerator / denominator) * 100);
    return isNaN(val) ? 0 : val;
  };

  return (
    <Table>
      <Cell area={'h1'} className={type}>
        Armors
      </Cell>
      <Cell area={'h2'} className={type}>
        Weapons
      </Cell>
      <Cell area={'h3'} className={type}>
        Other
      </Cell>

      <Cell area={'r1'} className={'end'}>
        Exist
      </Cell>
      <Cell area={'d1-1'} onClick={existArmorsClick}>
        {totalArmor}
      </Cell>
      <Cell area={'d1-2'} onClick={existWeaponsClick}>
        {totalWeapons}
      </Cell>
      <Cell area={'d1-3'} onClick={existOtherClick}>
        {totalOther}
      </Cell>

      <Cell area={'r2'} className={'end'}>
        Found
      </Cell>
      <Cell area={'d2-1'} onClick={foundArmorClick}>
        {foundArmor.length}
      </Cell>
      <Cell area={'d2-2'} onClick={foundWeaponClick}>
        {foundWeapons.length}
      </Cell>
      <Cell area={'d2-3'} onClick={foundOtherClick}>
        {foundOther.length}
      </Cell>

      <Cell area={'r3'} className={'end'}>
        Remaining
      </Cell>
      <Cell area={'d3-1'} onClick={remainingArmorClick}>
        {totalArmor - foundArmor.length}
      </Cell>
      <Cell area={'d3-2'} onClick={remainingWeaponClick}>
        {totalWeapons - foundWeapons.length}
      </Cell>
      <Cell area={'d3-3'} onClick={remainingOtherClick}>
        {totalOther - foundOther.length}
      </Cell>

      <Cell area={'r4'} className={'end'}>
        % Complete
      </Cell>
      <Cell area={'d4-1'}>{calcPercent(foundArmor.length, totalArmor)} %</Cell>
      <Cell area={'d4-2'}>{calcPercent(foundWeapons.length, totalWeapons)} %</Cell>
      <Cell area={'d4-3'}>{calcPercent(foundOther.length, totalOther)} %</Cell>
    </Table>
  );
};
HgTable.displayName = 'HgTable';
export default React.memo(HgTable);

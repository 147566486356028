// todo - test id is unique in device environ
import { ItemType } from './ItemProvider';

export const CREATE_ITEM_TABLE = `
    CREATE TABLE IF NOT EXISTS items (
        id INTEGER PRIMARY KEY NOT NULL,
        mule_id INTEGER,
        item_type VARCHAR(250) NOT NULL,
        item_id INTEGER,
        name VARCHAR(250),
        base_id INTEGER,
        base_name VARCHAR(250),
        ethereal INTEGER NOT NULL,
        sockets INTEGER,
        props VARCHAR(255)
    );

`;

export const CREATE_ITEM_TABLE_INDEXES = `
   
    CREATE INDEX idx_name ON items (name);
    CREATE INDEX idx_props ON items (props);
`;

export const QUERY_ALL_ITEMS = `
    SELECT
        items.*, mules.*
    FROM
        items
    LEFT JOIN
        mules ON mules.mule_id = items.mule_id;
`;

export const INSERT_ITEM = `
    INSERT INTO items (
        mule_id, 
        item_type, 
        item_id, 
        name, 
        base_id,
        base_name,
        ethereal, 
        sockets, 
        props
    ) 
    VALUES 
        (?, ?, ?, ?, ?, ?, ?, ?, ?)
    ;
`;

export const QUERY_ITEMS_BY_MULE_ID = (muleId: number) => `
    SELECT 
        items.*, mules.*
    FROM
        items
    LEFT JOIN 
        mules ON mules.mule_id = items.mule_id
    WHERE
        items.mule_id = ${muleId}
    ORDER BY 
        items.item_type;
`;

export const QUERY_FIND_ITEM = (type: ItemType, item_id: number) => `
    SELECT 
        items.*, mules.*
    FROM
        items
    LEFT JOIN 
        mules ON mules.mule_id = items.mule_id
    WHERE
        items.item_type = "${type}" AND items.item_id = ${item_id};
`;

export const QUERY_FIND_ITEMS_BY_IDS = (ids: number[]) => `
    SELECT 
        items.*, mules.*
    FROM
        items
    LEFT JOIN 
        mules ON mules.mule_id = items.mule_id
    WHERE
        items.id in (${ids});
`;

export const QUERY_FIND_RECENT_ITEMS = (count: number) => `
    SELECT * FROM (
                    SELECT 
                        items.*, mules.*
                    FROM
                        items
                    LEFT JOIN 
                        mules ON mules.mule_id = items.mule_id
                    ORDER BY
                        id DESC 
                    LIMIT
                        ${count}
                    )
    ORDER BY
        id DESC;

`;

export const EXECUTE_DELETE_ITEMS_BY_ITEM_IDS = (item_ids: number[]) => `
  DELETE FROM items WHERE item_id in (${item_ids});
`;
export const EXECUTE_DELETE_ITEMS_BY_ID = (id: number) => `
  DELETE FROM items WHERE id = ${id};
`;

export const QUERY_SEARCH_ITEMS = `
  SELECT
    items.*, mules.*
  FROM
    items
  LEFT JOIN
    mules ON mules.mule_id = items.mule_id
  WHERE
    items.name LIKE ?
  OR
    items.props LIKE ?;
`;

export const UPDATE_ITEM_NAME = (id: number, name: string) => `
    UPDATE items 
    SET
        name = '${name}'
    WHERE
        id = ${id}
    ;
`;

export const SELECT_ALL_ITEMS = `
  SELECT
    items.*, mules.*
  FROM
    items
  LEFT JOIN
    mules ON mules.mule_id = items.mule_id
`;

export const UPDATE_MULE_ID = (id: number, mule_id: number) => `
  UPDATE items
  SET
    mule_id = ${mule_id}
  WHERE
    id = ${id}
  ;
`;

export const DROP_ITEMS_TABLE = `DROP TABLE items`;

import React, { useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { ALL_RUNES } from '../../common/data/runes/all_runes';
import styled from 'styled-components/macro';
import { Rune } from '../../common/components/Rune';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import { RuneCombining } from '../horadric-cube/CubeRuneCombining';

export const RuneCatalog: React.FC = () => {
  const [segment, setSegment] = useState<string>('runes');
  return (
    <AppPage
      padding={true}
      title={'Runes'}
      headerContent={
        <IonSegment value={segment} onIonChange={e => setSegment(e.detail.value as string)} mode={'md'}>
          <IonSegmentButton value={'runes'}>Runes</IonSegmentButton>
          <IonSegmentButton value={'countess'}>Countess</IonSegmentButton>
          <IonSegmentButton value={'combine'}>Upgrading</IonSegmentButton>
        </IonSegment>
      }
    >
      {segment === 'runes' && (
        <Container>
          {ALL_RUNES.map((r, i) => (
            <Wrapper key={`rune-${r.id}`}>
              <RuneWrapper>
                <div className={'rune'}>{i + 1}</div>
                <Rune id={r.id} size={'45px'} withName={true} withCount={false} forceInStock={true} />
              </RuneWrapper>
              <InfoWrapper>
                <div>Weapon</div>
                <div>{r.props_weapon}</div>
                <div>Armor</div>
                <div>{r.props_armor}</div>
              </InfoWrapper>
            </Wrapper>
          ))}
        </Container>
      )}

      {segment === 'countess' && (
        <Container>
          <div>
            <div style={{ width: '100%', textAlign: 'center' }}>The Countess almost always drops a rune</div>
            <br />
            <div className='muted' style={{ width: '100%', textAlign: 'center' }}>
              (1/64 chance of no rune drop)
            </div>
            <br />
            <br />
            From her normal drop table she can drop:
            <br />
            <ul>
              <li>
                <span>Normal: </span> <span className='rune'>Ral</span> (8)
              </li>
              <li>
                <span>Nightmare: </span> <span className='rune'>Ko</span> (18)
              </li>
              <li>
                <span>Hell: </span> <span className='rune'>Lo</span> (28)
              </li>
            </ul>
            <br />
            She also has a special rune drop bonus:
            <ul>
              <li>
                <span>Normal: </span> <span className='rune'>Ral</span> (8)
              </li>
              <li>
                <span>Nightmare: </span> <span className='rune'>Io</span> (16)
              </li>
              <li>
                <span>Hell: </span> <span className='rune'>Ist</span> (24)
              </li>
            </ul>
            <br />
            Drops from her normal drop table are calculated as usual and are very rare (see the Drop Calculator). From her special rune drop
            bonus, the odds are:
            <br />
            <br />
            <table>
              <tr>
                <td>Rune</td>
                <td>Chance per Kill</td>
              </tr>
              <tr>
                <td>Ist</td>
                <td>1 : 289</td>
              </tr>
              <tr>
                <td>Mal</td>
                <td>1 : 193</td>
              </tr>
              <tr>
                <td>Um</td>
                <td>1 : 196</td>
              </tr>
              <tr>
                <td>Pul</td>
                <td>1 : 131</td>
              </tr>
              <tr>
                <td>Lem</td>
                <td>1 : 100</td>
              </tr>
              <tr>
                <td>Fal</td>
                <td>1 : 66</td>
              </tr>
              <tr>
                <td>Ko</td>
                <td>1 : 51</td>
              </tr>
              <tr>
                <td>Lum</td>
                <td>1 : 34</td>
              </tr>
              <tr>
                <td>Io</td>
                <td>1 : 27</td>
              </tr>
              <tr>
                <td>Hel</td>
                <td>1 : 18</td>
              </tr>
              <tr>
                <td>Dol</td>
                <td>1 : 15</td>
              </tr>
              <tr>
                <td>Shael</td>
                <td>1 : 10</td>
              </tr>
              <tr>
                <td>Sol</td>
                <td>1 : 9</td>
              </tr>
              <tr>
                <td>Amn</td>
                <td>1 : 6</td>
              </tr>
              <tr>
                <td>Thul</td>
                <td>1 : 6.96</td>
              </tr>
              <tr>
                <td>Ort</td>
                <td>1 : 4.64</td>
              </tr>
              <tr>
                <td>Ral</td>
                <td>1 : 6.96</td>
              </tr>
              <tr>
                <td>Tal</td>
                <td>1 : 4.64</td>
              </tr>
              <tr>
                <td>Ith</td>
                <td>1 : 9.94</td>
              </tr>
              <tr>
                <td>Eth</td>
                <td>1 : 6.62</td>
              </tr>
              <tr>
                <td>Tir</td>
                <td>1 : 13.9</td>
              </tr>
              <tr>
                <td>Nef</td>
                <td>1 : 9.27</td>
              </tr>
              <tr>
                <td>Eld</td>
                <td>1 : 34.78</td>
              </tr>
              <tr>
                <td>El</td>
                <td>1 : 23.18</td>
              </tr>
            </table>
          </div>
        </Container>
      )}
      {segment === 'combine' && <RuneCombining />}
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  li > span:first-of-type {
    font-weight: 600;
  }

  table {
    margin: auto;

    td {
      padding: 6px 8px;
    }
  }

  table,
  th,
  td {
    border: 1px solid var(--border-color);
    border-collapse: collapse;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  background: black;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
`;

const RuneWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 18px;

  > div:nth-of-type(odd) {
    color: var(--muted);
  }

  > div:nth-of-type(even) {
    margin-top: -8px;
  }
`;

export interface AutoModData {
  tables: AutoModTable[];
  notes: string[];
}

export interface AutoModTable {
  tableHeaders: string[];
  tableRows: string[][];
}

export const AMAZON_AUTO_MODS: AutoModData = {
  tables: [
    {
      tableHeaders: ['Bow/XBow Prefixes<sup>*</sup>', 'Level <br /> Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ["Fletcher's", '15', '3', '+1 Bow And Crossbow Skills'],
        ["Bowyer's", '30', '2', '+2 Bow And Crossbow Skills'],
        ["Archer's<sup>*</sup>", '45', '1', '+3 Bow And Crossbow Skills'],
      ],
    },
    {
      tableHeaders: ['Javelin/Spear Prefixes<sup>*</sup>', 'Level <br /> Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ["Harpoonist's", '15', '3', '+1 Javelin and Spear Skills'],
        ["Spearmaiden's", '30', '2', '+2 Javelin and Spear Skills'],
        ["Lancer's<sup>*</sup>", '45', '1', '+3 Javelin and Spear Skills'],
      ],
    },
  ],
  notes: [
    'Names are not used for display, only Auto Mod Properties are shown',
    'The Level Requirement of the auto mod is never applied to non-magic items',
    "'Archer's' and 'Lancer's' cannot spawn on rare quality Amazon Weapon",
  ],
};

export const ASSASSIN_AUTO_MODS: AutoModData = {
  tables: [],
  notes: [
    'Certain Exceptional and Elite Katars can have from 1 to 3 skills (bonuses to specific Assassin skills), each ranging from +1 to +3 to skill level. Higher Katars have a better chance of having higher level skills and higher bonuses to those skills',
    'Some Exceptional Katars cannot have +Skills',
    'Magic and Rare Katars may additionally get +1 or +2 to Assassin Skills or from +1 to +3 to an Assassin Skill Tree',
    'Only Magic Katars can have +3 to an Assassin Skill Tree.',
  ],
};

export const BARBARIAN_AUTO_MODS: AutoModData = {
  tables: [],
  notes: ['Barbarian Helms can have 1-3 bonuses of +1-3 to specific Barbarian skills', 'Barbarian Helms can be worn by Act 5 mercenaries'],
};

export const DRUID_AUTO_MODS: AutoModData = {
  tables: [],
  notes: ['Druid Pelts can have 1-3 bonuses of +1-3 to specific Druid skills'],
};

export const PALADIN_AUTO_MODS: AutoModData = {
  tables: [
    {
      tableHeaders: ['Prefixes<sup>*</sup>', 'Level Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ['Shimmering', '3', '8', 'All Resistances +5 to +10'],
        ['Rainbow', '13', '7', 'All Resistances +8 to +15'],
        ['Scintillating', '21', '6', 'All Resistances +16 to +30'],
        ['Prismatic', '31', '5', 'All Resistances +25 to +35'],
        ['Chromatic<sup>*</sup>', '42', '4', 'All Resistances +35 to +45'],
        ['Sharp', '5', '3', '+10% to +20% Enhanced Damage\n +15 to +30 to Attack Rating'],
        ['Fine', '13', '3', '+21% to +30% Enhanced Damage\n +31 to +60 to Attack Rating'],
        ["Warrior's", '23', '2', '+31% to +40% Enhanced Damage\n +61 to +80 to Attack Rating'],
        ["Soldier's", '34', '2', '+41% to +50% Enhanced Damage\n +81 to +100 to Attack Rating'],
        ["Knight's", '42', '2', '+51% to +65% Enhanced Damage\n +101 to +121 to Attack Rating'],
      ],
    },
  ],
  notes: [
    'Auto prefix names are not used for display, only Auto Mod Properties are shown',
    'The Level Requirement of the auto mod is never applied to non-magic items',
    "'Chromatic' cannot spawn on rare quality Paladin Shields",
  ],
};

export const NECROMANCER_AUTO_MODS: AutoModData = {
  tables: [
    {
      tableHeaders: ['Suffixes<sup>*</sup>', 'Level Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ['of Blight', '3', '3', 'Adds 2-7 poison damage over 3 seconds'],
        ['of Venom', '11', '2', 'Adds 5-14 poison damage over 3 seconds'],
        ['of Pestilence', '18', '2', 'Adds 13-28 poison damage over 4 seconds'],
        ['of Anthrax<sup>*</sup>', '25', '2', 'Adds 25-50 poison damage over 4 seconds'],
      ],
    },
  ],
  notes: [
    'Shrunken Heads can have 1-3 bonuses of +1-3 to specific Necromancer skills',
    'Auto prefix names are not used for display, only Auto Mod Properties are shown',
    'The Level Requirement of the auto mod is never applied to non-magic items',
    "'of Anthrax' cannot appear on rare quality Necromancer Shrunken Heads",
  ],
};

export const SORCERESS_AUTO_MODS: AutoModData = {
  tables: [
    {
      tableHeaders: ['Prefixes<sup>*</sup>', 'Level Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ["Lizard's", '2', '4', '+1 to +5 to Mana'],
        ["Snake's", '9', '3', '+5 to +10 to Mana'],
        ["Serpent's", '16', '2', '+11 to +20 to Mana'],
        ["Drake's", '26', '1', '+21 to +30 to Mana'],
        ["Dragon's", '37', '1', '+31 to +40 to Mana'],
        ["Wyrm's", '48', '1', '+41 to +60 to Mana'],
        ["Great Wyrm's<sup>*</sup>", '59', '1', '+61 to +80 to Mana'],
      ],
    },
    {
      tableHeaders: ['Suffixes<sup>*</sup>', 'Level Req.<sup>*</sup>', 'Frequency', 'Auto Mod Properties'],
      tableRows: [
        ['of the Jackal', '3', '6', '+1 to +5 to Life'],
        ['of the Fox', '8', '5', '+6 to +10 to Life'],
        ['of the Wolf', '20', '4', '+11 to +20 to Life'],
        ['of the Tiger', '35', '3', '+21 to +30 to Life'],
        ['of the Mammoth', '51', '2', '+31 to +40 to Life'],
        ['of the Colossus<sup>*</sup>', '67', '1', '+41 to +60 to Life'],
      ],
    },
  ],
  notes: [
    'Higher Orbs have a better chance of having + To Skills, higher level skills, and better bonuses to skills as well as having a better chance of "good" Auto Mods',
    'Auto prefix and auto suffix names are not used for display, only Auto Mod Properties are shown',
    'You cannot have an auto prefix and an auto suffix together, as they are from the same group',
    "'Great Wyrm's' and 'of the Colossus' cannot appear on rare quality Sorceress Orbs",
    'The Level Requirement of the auto mod is never applied to non-magic items',
  ],
};

import React, { useContext, useEffect, useState } from 'react';
import { ItemContext, StorageItem } from '../../../common/providers/ItemProvider/ItemProvider';
import { ViewStorageItemSlider } from '../../../common/components/ViewStorageItemSlider';
import { useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';

export const RecentlyMuled: React.FC = () => {
  const [items, setItems] = useState<StorageItem[]>([]);
  const { selectRecentItems, ready } = useContext(ItemContext);
  const homeSettings = useAppSelector(selectorHomeSettings);

  useEffect(() => {
    getItems();
  }, [ready]);

  const getItems = () => {
    if (ready) selectRecentItems(homeSettings.mule.count).then(setItems);
  };

  return (
    <ViewStorageItemSlider
      items={items}
      onItemDelete={getItems}
      autoHeight={homeSettings.general.autoHeight}
      navigation={homeSettings.general.sliderArrows}
    />
  );
};

import React, { useEffect, useMemo } from 'react';
import { ItemType } from '../../../common/providers/ItemProvider/ItemProvider';
import { ArmorBase, Item, Runeword, SetItem, UniqueItem, WeaponBase } from '../../../common/types';
import { IonTextarea } from '@ionic/react';
import styled from 'styled-components/macro';
import { ALL_RUNES } from '../../../common/data/runes/all_runes';

interface Props {
  itemType: ItemType;
  ethereal: boolean;
  selectedItem: object & Item;
  onChange: (props: string) => void;
  value: string | null;
}

export const AddMuleItemTextArea: React.FC<Props> = ({ itemType, selectedItem, onChange, ethereal, value }) => {
  const props = useMemo(() => {
    switch (itemType) {
      case 'unique':
        return parsePropsArray([...(selectedItem as UniqueItem).props]);
      case 'set':
        return parsePropsArray([...(selectedItem as SetItem).props]);

      case 'runeword':
        return `${(selectedItem as Runeword).stats}\nRequired Level: ${(selectedItem as Runeword).cLvlReq} ${
          ethereal ? '\n Ethereal' : ''
        } `;

      case 'rune': {
        const rune = ALL_RUNES.find(r => r.id === selectedItem.id);
        return `Weapon: ${rune?.props_weapon}\n \n Armor: ${rune?.props_armor}\n \nRequired Level: ${rune?.rq_level}`;
      }

      case 'magic':
      case 'rare': {
        if (selectedItem) {
          if (selectedItem.item_type === 'base_armor') {
            return `Defense: ${(selectedItem as ArmorBase).minDef} ~ ${(selectedItem as ArmorBase).maxDef} \n${
              (selectedItem as ArmorBase).reqLvl ? `Required Level: ${(selectedItem as WeaponBase).reqLvl}` : ''
            } ${ethereal ? '\n Ethereal' : ''}
           `;
          }
          if (selectedItem.item_type === 'base_weapon') {
            return `Damage: ${(selectedItem as WeaponBase).minDam} ~ ${(selectedItem as WeaponBase).maxDam} \n${
              (selectedItem as WeaponBase).reqLvl ? `Required Level: ${(selectedItem as WeaponBase).reqLvl}` : ''
            } ${ethereal ? '\n Ethereal' : ''}

            `;
          }
        }
      }
    }
  }, [itemType, selectedItem, ethereal]);

  useEffect(() => {
    // force change to ensure props gets set to the data object to be stored in db
    if (props) {
      onChange(props);
    }
  }, [props]);

  function parsePropsArray(props: string[]) {
    const temp: string[] = [];
    // eslint-disable-next-line react/prop-types
    props.forEach(p => {
      if (p.includes(':')) return temp.push(p);
      temp.push((p += '\n'));
    });

    let res = temp.join(' ');

    if (ethereal) {
      res += '\n Ethereal';
    }

    // onChange(res);
    return res;
  }

  return (
    <TextArea
      style={{ color: 'white', whiteSpace: 'prewrap' }}
      value={value || props}
      autoGrow={true}
      onIonInput={e => onChange(e.detail.value as string)}
      placeholder={'Edit item props here...'}
    />
  );
};

const TextArea = styled(IonTextarea)`
  color: white;
  white-space: pre-wrap;
  text-align: center;
`;

import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { Rune } from '../../common/components/Rune';
import { GemImage } from '../../common/components/GemImage';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings, selectorIsD2r } from '../../store/settings/selectors';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';

export const CubeSocketRolling: React.FC = () => {
  const isD2r = useAppSelector(selectorIsD2r);
  const settings = useAppSelector(selectorCraftingSettings);
  const router = useIonRouter();
  return (
    <AppPage
      padding={true}
      title={'Socket Rolling'}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
    >
      <Row>
        <div className={'magic'}>Socketed Magic Weapon (iLVL 25)</div>
        <span>
          <GemImage withName={settings.showGemNames} quality={'chipped'} type={'all'} size={'45px'} />
          <GemImage withName={settings.showGemNames} quality={'chipped'} type={'all'} size={'45px'} />
          <GemImage withName={settings.showGemNames} quality={'chipped'} type={'all'} size={'45px'} />+{' '}
          <span className='magic'>Magic Weapon</span>
          {/*3 <span className={'rainbow'}>chipped gems</span> + <span className='magic'>Magic Weapon</span>*/}
        </span>
      </Row>
      <Row>
        <div className={'magic'}>Socketed Magic Weapon (iLVL 30)</div>
        <span>
          <GemImage withName={settings.showGemNames} quality={'flawless'} type={'all'} size={'35px'} />
          <GemImage withName={settings.showGemNames} quality={'flawless'} type={'all'} size={'35px'} />
          <GemImage withName={settings.showGemNames} quality={'flawless'} type={'all'} size={'35px'} />+{' '}
          <span className='magic'>Magic Weapon</span>
        </span>
      </Row>
      <Row>
        <div className={'base'}>Socketed Armor</div>
        <span>
          <Rune
            id={6}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          +{' '}
          <Rune
            id={9}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          + <GemImage withName={settings.showGemNames} quality={'perfect'} type={'topaz'} size={'35px'} />
          <div>+ Normal Armor *</div>
        </span>
      </Row>
      <Row>
        <div className={'base'}>Socketed Weapon</div>
        <span>
          <Rune
            id={7}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          +{' '}
          <Rune
            id={10}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          + <GemImage withName={settings.showGemNames} quality={'perfect'} type={'amethyst'} size={'35px'} />
          <div>+ Normal Weapon *</div>
        </span>
      </Row>
      <Row>
        <div className={'base'}>Socketed Helm</div>
        <span>
          <Rune
            id={7}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          +{' '}
          <Rune
            id={9}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          + <GemImage withName={settings.showGemNames} quality={'perfect'} type={'sapphire'} size={'35px'} />
          <div>+ Normal Helm *</div>
        </span>
      </Row>
      <Row>
        <div className={'base'}>Socketed Shield</div>
        <span>
          <Rune
            id={6}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          +{' '}
          <Rune
            id={10}
            size={'30px'}
            forceInStock={!settings.showRunesInStock}
            withName={settings.showRuneNames}
            withCount={settings.showRuneCount}
          />{' '}
          + <GemImage withName={settings.showGemNames} quality={'perfect'} type={'ruby'} size={'35px'} />
          <div>+ Normal Shield *</div>
        </span>
      </Row>

      <Disclaimer>* Normal means not broken, cracked, low quality, superior etc</Disclaimer>
      {!isD2r && (
        <Disclaimer>
          * There is a bug when rolling sockets into ethereal armors where the ethereal bonus defense (50%) is applied again. This is known
          as &ldquo;ebug&ldquo;.
        </Disclaimer>
      )}
    </AppPage>
  );
};

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 6px;
  color: white;
  padding: 18px 0;

  > div {
    font-size: 18px;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }

  & + & {
    border-top: 1px solid var(--border-color);
  }
`;

const Disclaimer = styled.div`
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: white;
`;

import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorCraftingSettings } from '../../../store/settings/selectors';
import { SettingsContainer } from '../Settings';
import { IonItem, IonToggle } from '@ionic/react';
import { CraftingSettingsState, setCraftingSettings } from '../../../store/settings/settingsSlice';
import { useScrollToContainerAndHighlight } from './useScrollToContainerAndHighlight';

export const CraftingSettings: React.FC = () => {
  const settings = useAppSelector(selectorCraftingSettings);
  const dispatch = useAppDispatch();
  const handleToggle = (key: keyof CraftingSettingsState) => {
    dispatch(setCraftingSettings({ ...settings, [key]: !settings[key] }));
  };
  const self = useRef<HTMLDivElement>(null);
  const highlight = useScrollToContainerAndHighlight(self, 'crafting');

  return (
    <SettingsContainer ref={self} highlight={highlight}>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showRuneNames} onIonChange={() => handleToggle('showRuneNames')}>
          Show rune names
        </IonToggle>
      </IonItem>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showRuneCount} onIonChange={() => handleToggle('showRuneCount')}>
          Show rune count
        </IonToggle>
      </IonItem>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showRunesInStock} onIonChange={() => handleToggle('showRunesInStock')}>
          Highlight runes in inventory
        </IonToggle>
      </IonItem>
      <IonItem lines={'none'}>
        <IonToggle checked={settings.showGemNames} onIonChange={() => handleToggle('showGemNames')}>
          Show gem names
        </IonToggle>
      </IonItem>
    </SettingsContainer>
  );
};

import React from 'react';
import { StorageItem } from '../../providers/ItemProvider/ItemProvider';
import { useAppSelector } from '../../../store/store';
import { selectorFindUnique } from '../../../store/activeData/selector';
import { ItemCard, ItemStats, ItemTitle } from '../StyledComponents';
import { ItemImage } from '../ItemImage';
import { ItemDBStatus } from '../ItemDBStatus';

interface Props {
  id?: number;
  item?: StorageItem;
  style?: React.CSSProperties;
}

export const ViewUnique: React.FC<Props> = ({ item, id, style }) => {
  const unique = useAppSelector(state => selectorFindUnique(state, id ?? item?.item_id ?? null));

  return (
    <>
      {unique && (
        <ItemCard style={style}>
          <ItemTitle color={'var(--unique)'}>{unique.name}</ItemTitle>
          <div className={'base'}>{unique.type}</div>
          {item && <ItemStats>{item.props}</ItemStats>}
          <ItemImage uniqueFilename={unique.filename} />
          {unique && (
            <ItemStats>
              {unique.props.map((p, i) => {
                if (p.includes(':'))
                  return (
                    <div key={`${unique.name}-prop-${i}`}>
                      <span>{p} </span>
                      {unique.props[i + 1]}
                      <br />
                    </div>
                  );
                if (unique.props.at(i - 1)?.includes(':')) return null;
                return (
                  <React.Fragment key={`${unique.name}-prop-${i}`}>
                    {p}
                    <br />
                  </React.Fragment>
                );
              })}
            </ItemStats>
          )}
          <ItemDBStatus type={'unique'} itemId={unique.id} />
        </ItemCard>
      )}
    </>
  );
};

import React, { useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useParams } from 'react-router';
import { useAppSelector } from '../../store/store';
import { selectorFindRuneword } from '../../store/activeData/selector';
import { ViewBase } from '../../common/components/ViewBase/ViewBase';
import { ViewRuneword } from '../../common/components/ViewRuneword/ViewRuneword';
import { useRunewordBases } from './runeword_base_hook';

export const RunewordBases: React.FC = () => {
  const { runewordId } = useParams<{ runewordId: string }>();
  const [segment, setSegment] = useState(1);
  const runeword = useAppSelector(state => selectorFindRuneword(state, ~~runewordId));
  const bases = useRunewordBases(~~runewordId);

  return (
    <AppPage
      title={`${runeword?.name} Bases`}
      headerContent={
        <>
          {runeword && (
            <IonAccordionGroup>
              <IonAccordion value={'first'}>
                <IonItem slot={'header'} lines={'none'}>
                  <IonLabel className={'runeword'}>{runeword.name}</IonLabel>
                </IonItem>
                <div slot={'content'}>
                  <ViewRuneword runeword={runeword} />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          )}
          <IonSegment onIonChange={e => setSegment(~~(e.detail.value as string))} value={segment.toString(10)} mode={'md'}>
            <IonSegmentButton value={'0'}>
              <IonLabel color={'primary'}>Normal</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'1'}>
              <IonLabel color={'primary'}>Exceptional</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'2'}>
              <IonLabel color={'primary'}>Elite</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </>
      }
    >
      <Container className={'ion-padding'}>
        {bases.armors.map(ab => ab.quality_id === segment && <ViewBase key={`ab-${ab.id}`} armorBase={ab} />)}
        {bases.weapons.map(wb => wb.quality_id === segment && <ViewBase key={`wb-${wb.id}`} weaponBase={wb} />)}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

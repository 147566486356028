export interface IAct {
  filename: string;
  filename2: string;
  zone_name: string;
  levels: string | null;
  related: string | null;
  waypoint: string | boolean;
  additional: string | null;
  isSideZone: boolean;
}

export const ACT_ONE: IAct[] = [
  {
    filename: 'act1-rogue.gif',
    filename2: 'act1-rogue.webp',
    zone_name: 'Rogue Encampment',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-blood.gif',
    filename2: 'act1-blood.webp',
    zone_name: 'Blood Moor',
    levels: null,
    related: 'Den of Evil',
    waypoint: false,
    additional: 'Den of Evil / Caves (if Quest 1 has been completed)',
    isSideZone: false,
  },
  {
    filename: 'act1-cold.gif',
    filename2: 'act1-cold.jpeg',
    zone_name: 'Cold Plains',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Caves (2 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act1-burial.gif',
    filename2: 'act1-burial.avif',
    zone_name: 'Burial Grounds',
    levels: null,
    related: 'Blood Raven',
    waypoint: false,
    additional: 'The Crypt - The Mausoleum',
    isSideZone: true,
  },
  {
    filename: 'act1-stoney.gif',
    filename2: 'act1-stoney.jpg',
    zone_name: 'Stony Field',
    levels: null,
    related: 'Cairn Stones, Tower Tome',
    waypoint: true,
    additional: 'The Underground Passage Entry',
    isSideZone: false,
  },
  {
    filename: 'act1-tristram.gif',
    filename2: 'act1-tristram.webp',
    zone_name: 'Tristram',
    levels: null,
    related: 'Deckard Cain',
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act1-underground.gif',
    filename2: 'act1-underground.webp',
    zone_name: 'The Underground Passage',
    levels: '2',
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-dark.gif',
    filename2: 'act1-dark.jpg',
    zone_name: 'Dark Wood',
    levels: null,
    related: 'Inifuss Tree',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-black.gif',
    filename2: 'act1-black.jpg',
    zone_name: 'Black Marsh',
    levels: null,
    related: 'The Forgotten Tower (5 Levels)',
    waypoint: true,
    additional: 'The Hole (2 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act1-tamoe.gif',
    filename2: 'act1-tamoe.png',
    zone_name: 'Tamoe Highland',
    levels: null,
    related: null,
    waypoint: false,
    additional: 'The Pit (2 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act1-monastary.gif',
    filename2: 'act1-monastary.jpg',
    zone_name: 'Monastery Gate',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-outer.gif',
    filename2: 'act1-outer.jpg',
    zone_name: 'Outer Cloister',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-barracks.gif',
    filename2: 'act1-barracks.webp',
    zone_name: 'Barracks',
    levels: null,
    related: 'The Horadric Malus',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-jail.gif',
    filename2: 'act1-jail.jpg',
    zone_name: 'Jail',
    levels: '3',
    related: null,
    waypoint: 'Yes (on Jail Level 1)',
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-inner.gif',
    filename2: 'act1-inner.jpg',
    zone_name: 'Inner Cloister',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-cathedral.gif',
    filename2: 'act1-cathedral.jpg',
    zone_name: 'Cathedral',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act1-catacombs.gif',
    filename2: 'act1-catacombs.jpg',
    zone_name: 'Catacombs',
    levels: '4',
    related: 'Andariel (on Catacombs Level 4)',
    waypoint: 'Yes (on Catacombs Level 2)',
    additional: null,
    isSideZone: false,
  },
];
export const ACT_TWO: IAct[] = [
  {
    filename: 'act2-lutgholein.gif',
    filename2: 'act2-lutgholein.jpg',
    zone_name: 'Lut Gholein',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-sewers.gif',
    filename2: 'act2-sewers.webp',
    zone_name: 'Sewers',
    levels: '3',
    related: 'Radament - Horadric Scroll (on Level 3)',
    waypoint: 'Yes (on Level 2)',
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act2-rockywaste.gif',
    filename2: 'act2-rockywaste.jpg',
    zone_name: 'Rocky Waste',
    levels: null,
    related: null,
    waypoint: false,
    additional: 'The Stony Tomb (2 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act2-dryhills.gif',
    filename2: 'act2-dryhills.jpg',
    zone_name: 'Dry Hills',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-hallsofthedead.gif',
    filename2: 'act2-hallsofthedead.jpg',
    zone_name: 'Halls of the Dead',
    levels: '3',
    related: 'Horadric Cube (on Level 3)',
    waypoint: 'Yes (on Level 2)',
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act2-faroasis.gif',
    filename2: 'act2-faroasis.jpg',
    zone_name: 'Far Oasis',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-maggotslair.gif',
    filename2: 'act2-maggotslair.jpg',
    zone_name: 'Maggot Lair',
    levels: '3',
    related: 'Staff of the Kings',
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act2-lostcity.gif',
    filename2: 'act2-lostcity.jpg',
    zone_name: 'Lost City',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Ancient Tunnels (1 Level)',
    isSideZone: false,
  },
  {
    filename: 'act2-valleyofthesnakes.gif',
    filename2: 'act2-valleyofthesnakes.jpg',
    zone_name: 'Valley of Snakes',
    levels: null,
    related: 'Claw Viper Temple',
    waypoint: false,
    additional: 'Claw Viper Temple (2 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act2-lutgholein.gif',
    filename2: 'act2-lutgholein.jpg',
    zone_name: 'Lut Gholein',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-harem.jpg',
    filename2: 'act2-harem.jpg',
    zone_name: 'Harem',
    levels: '2',
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-palace.webp',
    filename2: 'act2-palace.png',
    zone_name: 'The Palace Cellar',
    levels: '3',
    related: null,
    waypoint: 'Yes (On Level 1)',
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-arcanesanctuary.png',
    filename2: 'act2-arcanesanctuary.jpg',
    zone_name: 'Arcane Sanctuary',
    levels: null,
    related: 'The Summoner',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-canyon.png',
    filename2: 'act2-canyon.jpg',
    zone_name: 'Canyon of the Magi',
    levels: null,
    related: null,
    waypoint: true,
    additional: "Tal Rasha's Tombs (6 False Tombs)",
    isSideZone: false,
  },
  {
    filename: 'act2-talz.png',
    filename2: 'act2-talz.png',
    zone_name: "Tal Rasha's Tomb",
    levels: null,
    related: 'The Orifice for the Horadric Staff',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act2-duriel.jpeg',
    filename2: 'act2-duriel.avif',
    zone_name: "Tal Rasha's Chamber",
    levels: null,
    related: 'Durial',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
];
export const ACT_THREE: IAct[] = [
  {
    filename: 'act3-kurastdocks.gif',
    filename2: 'act3-kurastdocks.jpg',
    zone_name: 'Kurast Docks',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act3-spiderforest.gif',
    filename2: 'act3-spiderforest.webp',
    zone_name: 'Spider Forest',
    levels: null,
    related: 'Jade Figurine',
    waypoint: true,
    additional: 'Arachnid Lair',
    isSideZone: false,
  },
  {
    filename: 'act3-spidercavern.gif',
    filename2: 'act3-spidercavern.webp',
    zone_name: 'Spider Cavern',
    levels: '1',
    related: "Khalim's Eye",
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act3-greatmarsh.gif',
    filename2: 'act3-greatmarsh.webp',
    zone_name: 'Great Marsh',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act3-flayerjungle.gif',
    filename2: 'act3-flayerjungle.jpg',
    zone_name: 'Flayer Jungle',
    levels: null,
    related: 'The Gidbinn',
    waypoint: true,
    additional: 'Swampy Pit (3 Levels)',
    isSideZone: false,
  },
  {
    filename: 'act3-flayerdungeon.gif',
    filename2: 'act3-flayerdungeon.webp',
    zone_name: 'Flayer Dungeon',
    levels: '3',
    related: "Khalim's Brain",
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act3-lowerkurast.gif',
    filename2: 'act3-lowerkurast.jpg',
    zone_name: 'Lower Kurast',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act3-kurastbazaar.gif',
    filename2: 'act3-kurastbazaar.jpg',
    zone_name: 'Kurast Bazaar',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Disused Fane - Ruined Temple',
    isSideZone: false,
  },
  {
    filename: 'act3-sewers.gif',
    filename2: 'act3-sewers.webp',
    zone_name: 'Sewers',
    levels: '2',
    related: "Khalim's Heart",
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act3-upperkurast.gif',
    filename2: 'act3-upperkurast.png',
    zone_name: 'Upper Kurast',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Forgotten Temple - Forgotten Reliquary',
    isSideZone: false,
  },
  {
    filename: 'act3-kurastcauseway.gif',
    filename2: 'act3-kurastcauseway.webp',
    zone_name: 'Kurast Causeway',
    levels: null,
    related: null,
    waypoint: false,
    additional: 'Disused Reliquary - Ruined Fane',
    isSideZone: false,
  },
  {
    filename: 'act3-travincal.gif',
    filename2: 'act3-travincal.jpg',
    zone_name: 'Travincal',
    levels: null,
    related: "Khalim's Flail - The Compelling Orb",
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act3-duranceofhate.gif',
    filename2: 'act3-duranceofhate.jpg',
    zone_name: 'Durance of Hate',
    levels: '3',
    related: 'Mephisto',
    waypoint: 'Yes (on Level 2)',
    additional: null,
    isSideZone: false,
  },
];
export const ACT_FOUR: IAct[] = [
  {
    filename: 'act4-pandemoniumfortress.gif',
    filename2: 'act4-pandemoniumfortress.webp',
    zone_name: 'Pandemonium Fortress',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act4-outersteppes.gif',
    filename2: 'act4-outersteppes.jpg',
    zone_name: 'Outer Steppes',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act4-plainsofdespair.gif',
    filename2: 'act4-plainsofdespair.jpg',
    zone_name: 'Plains of Despair',
    levels: null,
    related: 'Izual',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act4-cityofthedamned.gif',
    filename2: 'act4-cityofthedamned.png',
    zone_name: 'City of the Damned',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act4-riverofflames.gif',
    filename2: 'act4-riverofflames.png',
    zone_name: 'River of Flame',
    levels: null,
    related: 'Hellforge',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act4-chaossanctuary.gif',
    filename2: 'act4-chaossanctuary.jpg',
    zone_name: 'Chaos Sanctuary',
    levels: null,
    related: 'Diablo',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
];
export const ACT_FIVE: IAct[] = [
  {
    filename: 'act5-harrogath01.gif',
    filename2: 'act5-harrogath01.jpg',
    zone_name: 'Harrogath',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-bloodyfoothills.gif',
    filename2: 'act5-bloodyfoothills.png',
    zone_name: 'Bloody Foothills',
    levels: null,
    related: 'Shenk the Overseer',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-frigidhighlands.gif',
    filename2: 'act5-frigidhighlands.webp',
    zone_name: 'Frigid Highlands',
    levels: null,
    related: 'Imprisoned Soldiers',
    waypoint: true,
    additional: 'Abaddon',
    isSideZone: false,
  },
  {
    filename: 'act5-abaddon.gif',
    filename2: 'act5-abaddon.webp',
    zone_name: 'Abaddon',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-arreatplateau.gif',
    filename2: 'act5-arreatplateau.webp',
    zone_name: 'Arreat Plateau',
    levels: null,
    related: 'Shenk the Overseer',
    waypoint: true,
    additional: 'Pit of Acheron',
    isSideZone: false,
  },
  {
    filename: 'act5-acheron.gif',
    filename2: 'act5-acheron.webp',
    zone_name: 'Pit of Acheron',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-crystallinepassage.gif',
    filename2: 'act5-crystallinepassage.png',
    zone_name: 'Crystalline Passage',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Frozen River',
    isSideZone: false,
  },
  {
    filename: 'act5-frozenriver.gif',
    filename2: 'act5-frozenriver2.png',
    zone_name: 'Frozen River',
    levels: null,
    related: 'Anya',
    waypoint: true,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-harrogath02.gif',
    filename2: 'act5-harrogath01.jpg',
    zone_name: 'Harrogath',
    levels: null,
    related: 'Speak to NPCs for Quest Assignments',
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-nihlathaktemple.gif',
    filename2: 'act5-nihlathaktemple.jpg',
    zone_name: "Nihlathak's Temple",
    levels: null,
    related: null,
    waypoint: false,
    additional: 'Pindleskin',
    isSideZone: false,
  },
  {
    filename: 'act5-hallsofanguish.gif',
    filename2: 'act5-hallsofanguish.webp',
    zone_name: 'Halls of Anguish',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-hallsofpain.gif',
    filename2: 'act5-hallsofpain.webp',
    zone_name: 'Halls of Pain',
    levels: null,
    related: null,
    waypoint: true,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-hallsofvaught.gif',
    filename2: 'act5-hallsofvaught.webp',
    zone_name: 'Halls of Vaught',
    levels: null,
    related: 'Nihlathak',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-crystallinepassage.gif',
    filename2: 'act5-crystallinepassage.webp',
    zone_name: 'Crystalline Passage',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Frozen River',
    isSideZone: false,
  },
  {
    filename: 'act5-frozenriver.gif',
    filename2: 'act5-frozenriver2.png',
    zone_name: 'Frozen River',
    levels: null,
    related: 'Anya',
    waypoint: true,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-glacialtrail.gif',
    filename2: 'act5-glacialtrail.jpg',
    zone_name: 'Glacial Trail',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Drifter Cavern',
    isSideZone: false,
  },
  {
    filename: 'act5-driftercavern.gif',
    filename2: 'act5-driftercavern.png',
    zone_name: 'Drifter Cavern',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-frozentundra.gif',
    filename2: 'act5-frozentundra.webp',
    zone_name: 'Frozen Tundra',
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Infernal Pit',
    isSideZone: false,
  },
  {
    filename: 'act5-infernalpit.gif',
    filename2: 'act5-infernalpit.png',
    zone_name: 'Infernal Pit',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-ancientsway.gif',
    filename2: 'act5-ancientsway.webp',
    zone_name: "The Ancients' Way",
    levels: null,
    related: null,
    waypoint: true,
    additional: 'Icy Cellar',
    isSideZone: false,
  },
  {
    filename: 'act5-icycellar.gif',
    filename2: 'act5-icycellar.png',
    zone_name: 'Icy Cellar',
    levels: null,
    related: null,
    waypoint: false,
    additional: null,
    isSideZone: true,
  },
  {
    filename: 'act5-arreatsummit.gif',
    filename2: 'act5-arreatsummit.webp',
    zone_name: 'Arreat Summit',
    levels: null,
    related: 'Altar of the Heavens',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-worldstonekeep.gif',
    filename2: 'act5-worldstonekeep.webp',
    zone_name: 'Worldstone Keep',
    levels: '3',
    related: null,
    waypoint: 'Yes (on Level 2)',
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-throneofdestruction.gif',
    filename2: 'act5-throneofdestruction.webp',
    zone_name: 'Throne of Destruction',
    levels: null,
    related: "Baal's Minions",
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
  {
    filename: 'act5-worldstonechamber.gif',
    filename2: 'act5-worldstonechamber.webp',
    zone_name: 'The Worldstone Chamber',
    levels: null,
    related: 'Baal',
    waypoint: false,
    additional: null,
    isSideZone: false,
  },
];

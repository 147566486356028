/* eslint-disable */
import {DataItem, DropCalcData, Result} from './DropCalc';
import {
  dropCalcBaseItems,
  dropCalcSetItems,
  dropCalcUniques,
  JUNK_ITEM_CODES,
  sets
} from '../../common/data/drop-calc/Items';
import {TCs} from '../../common/data/drop-calc/TreasureClasses';
import {DropCalcZone, Item} from '../../common/types';
import {dropCalcMonsters} from '../../common/data/drop-calc/Monsters';

interface ResultData {
  name: string;
  chance: number;
  odds: string;
}

const cowsetitems = [32, 33, 34];
const cowking = 290;
const cow = 169;
const cowset = 9;
const ratio = [
  [400, 1, 6400, 160, 2, 5600, 100, 2, 3200, 34, 3, 192, 12, 8, 2, 2],
  [400, 1, 6400, 160, 2, 5600, 100, 2, 3200, 34, 3, 192, 12, 8, 1, 1],
  [240, 3, 6400, 120, 3, 5600, 80, 3, 3200, 17, 6, 192, 9, 8, 2, 2],
  [240, 3, 6400, 120, 3, 5600, 80, 3, 3200, 17, 6, 192, 9, 8, 1, 1],
];

let cm_unique = 0;
let cm_set = 0;
let cm_rare = 0;
let cm_magic = 0;

let chance_unique = 0;
let chance_set = 0;
let chance_rare = 0;
let chance_magic = 0;
let chance_high = 0;
let chance_norm = 0;
let chance_low = 0;
let chance_nodrop = 0;
let UniqueList: any[] = [];
let SetitemList: any[] = [];

export function calculateAllItemChancesByForm(data: DropCalcData, itemList: Item[], zones: DropCalcZone) {
  // sortby = 0 - alphabetical, 1 - chance
  // alldif = 0 - selceted, -1 - all
  const playerbonus = readplayerbonus(data.playersAll, data.playersParty);

  // var monstertype = parseInt(form.monstertype.value);

  const results = calculateAllItemChances(
    -1,
    data.itemType,
    data.allItemsIndex,
    data.mf,
    playerbonus,
    1,
    false,
    itemList,
    zones,
    data.selectedQuality,
  );
  return results as Result[];
}

function readplayerbonus(playersAll: number, playersParty: number) {
  if (playersParty > playersAll) {
    return -1;
  } else {
    return Math.floor(playersParty + (playersAll - playersParty) / 2);
  }
}

function calculateAllItemChances(
  difficulty: number,
  itemtype: number,
  allItemIndex: number,
  mf: number,
  playerbonus: number,
  sortby: number,
  textonly: boolean,
  itemList: Item[],
  zones: DropCalcZone,
  selectedQuality: number,
) {
  const resultData: ResultData[] = [];
  // Fix for Annihilus
  if (itemList.at(allItemIndex)?.name === 'Annihilus') {
    return [{ name: 'Uber Diablo', chance: 100, odds: '1:1' }];
  }

  let i;
  let j;

  let results: Result[] = [];

  const qlvl = 0;
  let bitem = 0;

  // seems to be dealing with setting the subtitle to  be item name and and base item etc
  if (itemtype == 0) {
    bitem = allItemIndex;
    const myItem = dropCalcBaseItems[allItemIndex];
  } else if (itemtype == 1) {
    bitem = dropCalcUniques[allItemIndex][4];
  } else if (itemtype == 2) {
    bitem = dropCalcSetItems[allItemIndex][4];
  } else {
    return;
  }

  const Monsterlist = [];

  let diffstart = difficulty;
  let diffend = difficulty;
  if (difficulty == -1) {
    diffstart = 0;
    diffend = 2;
  }

  let diff;

  for (diff = diffstart; diff <= diffend; diff++) {
    for (i = 0; i < TCs.length - 1; i++) {
      TCs[i][9] = -1;
    }

    // precalculate TC chances
    let monster;
    let monstertype;
    for (let k = 0; k <= 4; k++) {
      // loop through all monster types
      monstertype = k;
      for (monster = 0; monster < dropCalcMonsters.length - 1; monster++) {
        if (
          (monstertype < 4 && dropCalcMonsters[monster][1] == 0 && dropCalcMonsters[monster][3 + diff] > 0) ||
          (monstertype == 4 && dropCalcMonsters[monster][1] == 1)
        ) {
          for (i = 0; i < dropCalcMonsters[monster][15].length - 1; i++) {
            // dropCalcMonsters[15] is sub array

            const level = dropCalcMonsters[monster][15][i];

            const arealvl = zones[level][diff + 1] as number;
            const mlvl = readmlvl(diff, monstertype, monster, arealvl, zones);

            const TC = readTC(diff, monstertype, monster, mlvl, zones);
            if (TCs[TC][9] == -1) {
              simulateDrops(TC, playerbonus);
              const TCinfo = [dropCalcBaseItems[bitem][16], cm_unique, cm_set, cm_rare, cm_magic];
              // @ts-ignore
              TCs[TC][9] = TCinfo;
            }
          }
        }
      }

      for (monster = 0; monster < dropCalcMonsters.length - 1; monster++) {
        if (
          (monstertype < 4 && dropCalcMonsters[monster][1] == 0 && dropCalcMonsters[monster][3 + diff] > 0) ||
          (monstertype == 4 && dropCalcMonsters[monster][1] == 1)
        ) {
          for (i = 0; i < dropCalcMonsters[monster][15].length - 1; i++) {
            const level = dropCalcMonsters[monster][15][i];
            const arealvl = zones[level][diff + 1] as number;
            const mlvl = readmlvl(diff, monstertype, monster, arealvl, zones);

            const TC = readTC(diff, monstertype, monster, mlvl, zones);
            // @ts-ignore
            const basechance = TCs[TC][9][0] as number;

            if (basechance > 0) {
              if (itemtype == 0) {
                Monsterlist[Monsterlist.length] = [monster, basechance, diff, level];
              } else {
                // @ts-ignore
                cm_unique = TCs[TC][9][1];
                // @ts-ignore
                cm_set = TCs[TC][9][2];
                // @ts-ignore
                cm_rare = TCs[TC][9][3];
                // @ts-ignore
                cm_magic = TCs[TC][9][4];
                calculateColorChances(bitem, mlvl, mf);
                // uniques
                if (itemtype == 1 && mlvl >= dropCalcUniques[allItemIndex][3]) {
                  let mychance = 0;
                  const totalrarity = getUniqueList(bitem, mlvl);
                  if (totalrarity > 0) {
                    mychance = (dropCalcUniques[allItemIndex][2] / totalrarity) * basechance * chance_unique;
                  }
                  if (mychance > 0) {
                    Monsterlist[Monsterlist.length] = [monster, mychance, diff, level];
                  }
                }
                // sets
                if (itemtype == 2 && mlvl >= dropCalcSetItems[allItemIndex][3]) {
                  let mychance = 0;
                  let setmonster = monster;
                  if (monstertype > 4) {
                    setmonster = -1;
                  }

                  const totalrarity = getSetitemList(bitem, mlvl, setmonster, selectedQuality);
                  if (totalrarity > 0) {
                    mychance = (dropCalcSetItems[allItemIndex][2] / totalrarity) * basechance * chance_set;
                  }
                  if (mychance > 0) {
                    const newItem = [monster, mychance, diff, level];
                    Monsterlist[Monsterlist.length] = newItem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  if (Monsterlist.length > 0) {
    // output everything
    if (textonly == false) {
      for (i = 0; i < Monsterlist.length; i++) {
        results[i] = [dropCalcMonsters[Monsterlist[i][0]][0] + ' ' + parseDifficultyAffix(Monsterlist[i][2]), 0, '0', ''];
        results[i][1] = parseFloat(chance(Monsterlist[i][1]));
        results[i][2] = rChance(Monsterlist[i][1]);
      }
    }
  }
  // this.allResults = results;

  // Fix for non-cow dropCalcMonsters being included in for Cow King Set calculations
  if (difficulty == -1 && itemtype == 2 && allItemIndex >= 32 && allItemIndex <= 34) {
    results = results.filter(monster => monster[0].includes('The Cow King') || monster[0].includes('Bovine'));
  }

  // for (let k = 0; k < this.resultsMax; k++) {
  //   // prevents empty rows if resultsMax > results
  //   if (k == results.length) {
  //     break;
  //   }
  //   this.resultData[k] = results[k];
  // }
  //
  //
  // this.resultData = _.uniqWith(this.resultData, _.isEqual);
  //
  // this.finalResultData = this.resultData;
  return results;
}

function readmlvl(difficulty: number, monstertype: number, monster: number, arealvl: any, zones: DropCalcZone) {
  let mlvl;
  switch (monstertype) {
    case 0: // normal
      mlvl = dropCalcMonsters[monster][3 + difficulty];
      if (difficulty == 1 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl);
      }
      if (difficulty == 2 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl);
      }
      break;
    case 1: // champion
      // @ts-ignore
      mlvl = dropCalcMonsters[monster][3 + difficulty] + 2;
      if (difficulty == 1 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl) + 2;
      }
      if (difficulty == 2 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl) + 2;
      }
      break;
    case 2: // boss
      // @ts-ignore
      mlvl = dropCalcMonsters[monster][3 + difficulty] + 3;
      if (difficulty == 1 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl, 10) + 3;
      }
      if (difficulty == 2 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl, 10) + 3;
      }
      break;
    case 3: // diener
      // @ts-ignore
      mlvl = dropCalcMonsters[monster][3 + difficulty] + 3;
      if (difficulty == 1 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl, 10) + 3;
      }
      if (difficulty == 2 && dropCalcMonsters[monster][16] == 0) {
        mlvl = parseInt(arealvl, 10) + 3;
      }
      break;
    case 4: // superunique
      mlvl = dropCalcMonsters[monster][3 + difficulty];
      if (difficulty == 1 && dropCalcMonsters[monster][16] == 0) {
        // @ts-ignore
        mlvl = zones[dropCalcMonsters[monster][15][0]][2] + 3;
      }
      if (difficulty == 2 && dropCalcMonsters[monster][16] == 0) {
        // @ts-ignore
        mlvl = zones[dropCalcMonsters[monster][15][0]][3] + 3;
      }
      break;
    case 5: // truhe
      mlvl = zones[monster][1 + difficulty];
      break;
    case 6: // tc
      mlvl = 99;
      break;
    default:
      mlvl = 0;
  }
  return mlvl;
}

function readTC(difficulty: number, monstertype: number, monster: number, monsterlevel: number, zones: DropCalcZone) {
  let TC;
  switch (monstertype) {
    case 0: // normal
      TC = dropCalcMonsters[monster][6 + difficulty] as number;
      if (difficulty > 0) {
        // @ts-ignore
        while (TCs[1 + TC][10] == TCs[TC][10] && TCs[1 + TC][11] <= monsterlevel) {
          TC++;
        }
      }
      break;
    case 1: // champion
      TC = dropCalcMonsters[monster][6 + difficulty + 3] as number;
      if (difficulty > 0) {
        // @ts-ignore
        while (TCs[1 + TC][10] == TCs[TC][10] && TCs[1 + TC][11] <= monsterlevel) {
          TC++;
        }
      }
      break;
    case 2: // boss
      TC = dropCalcMonsters[monster][6 + difficulty + 6] as number;
      if (difficulty > 0) {
        // @ts-ignore
        while (TCs[1 + TC][10] == TCs[TC][10] && TCs[1 + TC][11] <= monsterlevel) {
          TC++;
        }
      }
      break;
    case 3: // diener
      TC = dropCalcMonsters[monster][6 + difficulty] as number;
      if (difficulty > 0) {
        // @ts-ignore
        while (TCs[1 + TC][10] == TCs[TC][10] && TCs[1 + TC][11] <= monsterlevel) {
          TC++;
        }
      }
      break;
    case 4: // superunique
      TC = dropCalcMonsters[monster][6 + difficulty + 6];
      break;
    case 5: // truhe
      TC = zones[monster][4 + difficulty];
      break;
    case 6: // tc
      TC = monster;
      break;
    default:
      TC = 0;
  }
  return TC as number;
}

function simulateDrops(TC: number, playerbonus: number) {
  // clear chance modifiers
  let cm_unique = 0;
  let cm_set = 0;
  let cm_rare = 0;
  let cm_magic = 0;

  // clear nodrop chance
  let chance_nodrop = 0;
  // clear item possibilities
  let i;
  for (i = 0; i < dropCalcBaseItems.length - 1; i++) {
    dropCalcBaseItems[i][16] = 0;
  }

  simulateDropsRec(TC, playerbonus, 1, 1);
}

function simulateDropsRec(TC: number, playerbonus: number, chance: number, totalpicks: number) {
  const myTC = TCs[TC];
  let sumprob = myTC[5] as number;
  let nodrop = myTC[6] as number;

  let picks = Math.abs(myTC[7]);
  let sortedpicks = 0;
  if (myTC[7] < 0) {
    sortedpicks = 1;
  }
  let i;

  // playerbonus = readplayerbonus(this.playersall, this.playersparty);
  if (picks == 7 && playerbonus > 1) {
    picks = 6;
  } // notlösung für dropbegrenzung, leicht ungenau

  // increase ChanceModifier

  cm_unique = Math.max(myTC[1], cm_unique);

  cm_set = Math.max(myTC[2], cm_set);

  cm_rare = Math.max(myTC[3], cm_rare);

  cm_magic = Math.max(myTC[4], cm_magic);

  if (sumprob == 0) {
    return;
  }

  // modify nodrop based on players
  if (playerbonus > 1) {
    let fraction = nodrop / sumprob;
    fraction = Math.pow(fraction, playerbonus);
    const new_nodrop = Math.floor(((sumprob - nodrop) * fraction) / (1 - fraction));
    sumprob = sumprob - nodrop + new_nodrop;
    nodrop = new_nodrop;
  }

  chance_nodrop += (chance * nodrop) / sumprob;

  const drops = myTC[8] as any[];
  if (sortedpicks == 0) {
    totalpicks *= picks;
    for (i = 0; i < drops.length - 1; i += 3) {
      // i = type, i+1 = prob, i+2 = target
      const newchance = (drops[i + 1] / sumprob) * chance;

      if (drops[i] == 1) {
        // Item
        dropCalcBaseItems[drops[i + 2]][16] += 1 - Math.pow(1 - newchance, totalpicks);
      }
      if (drops[i] == 2) {
        // TreasureClass
        simulateDropsRec(drops[i + 2], playerbonus, newchance, totalpicks);
      }
    }
  } else {
    for (i = 0; i < drops.length - 1 && picks > 0; i += 3) {
      // i = type, i+1 = prob, i+2 = target
      let picked = drops[i + 1];
      if (picked > picks) {
        picked = picks;
      }
      picks -= picked;

      if (drops[i] == 1) {
        // Item
        dropCalcBaseItems[drops[i + 2]][16] += 1 - Math.pow(1 - chance, picked * totalpicks);
      }
      if (drops[i] == 2) {
        // TreasureClass
        simulateDropsRec(drops[i + 2], playerbonus, chance, picked * totalpicks);
      }
    }
  }
}

function calculateColorChances(item: number, mlvl: number, MF: number) {
  // returning values by setting global vars

  // clear results
  chance_unique = 0;
  chance_set = 0;
  chance_rare = 0;
  chance_magic = 0;
  chance_high = 0;
  chance_norm = 0;
  chance_low = 0;

  // go for restrictions
  // normal
  if (dropCalcBaseItems[item][6] == 1) {
    chance_norm = 1;
    return;
  }
  // magic, not rare
  if (dropCalcBaseItems[item][7] == 1 && dropCalcBaseItems[item][8] == 0) {
    if (getUniqueList(item, mlvl) == 0) {
      chance_magic = 1;
      return;
    }
  }
  calculateColorChancesMF(item, mlvl, MF);
  // magic
  if (dropCalcBaseItems[item][7] == 1) {
    chance_magic += chance_high + chance_norm + chance_low;
    chance_high = chance_norm = chance_low = 0;
  }
  // not rare
  if (dropCalcBaseItems[item][8] == 0) {
    chance_magic += chance_rare;
    chance_rare = 0;
  }
}

function getUniqueList(item: number, ilvl: number) {
  UniqueList = [];

  const myItemUniques = dropCalcBaseItems[item][14];
  let i;
  let totalrarity = 0;

  for (i = 0; i < myItemUniques.length - 1; i++) {
    if (dropCalcUniques[myItemUniques[i]][3] <= ilvl) {
      totalrarity += dropCalcUniques[myItemUniques[i]][2];
    }
  }
  if (totalrarity > 0) {
    for (i = 0; i < myItemUniques.length - 1; i++) {
      if (dropCalcUniques[myItemUniques[i]][3] <= ilvl) {
        UniqueList[UniqueList.length] = [myItemUniques[i], dropCalcUniques[myItemUniques[i]][2] / totalrarity];
      }
    }
  }
  return totalrarity;
}

function getSetitemList(item: number, ilvl: number, monster: number, selectedQuality: number) {
  SetitemList = [];

  const myItemSets = dropCalcBaseItems[item][15];
  let i;
  let totalrarity = 0;
  let isCowItem;

  const FormSubitem = selectedQuality;

  if (
    typeof cowsetitems != 'undefined' &&
    (FormSubitem == cowsetitems[0] || FormSubitem == cowsetitems[1] || FormSubitem == cowsetitems[2])
  ) {
    isCowItem = true;
  } else {
    isCowItem = false;
  }

  for (i = 0; i < myItemSets.length - 1; i++) {
    if (dropCalcSetItems[myItemSets[i]][3] <= ilvl) {
      if (!isCowItem || monster == cow || monster == cowking) {
        totalrarity += dropCalcSetItems[myItemSets[i]][2];
      }
    }
  }
  if (totalrarity > 0) {
    for (i = 0; i < myItemSets.length - 1; i++) {
      if (!isCowItem || monster == cow || monster == cowking) {
        if (dropCalcSetItems[myItemSets[i]][3] <= ilvl) {
          SetitemList[SetitemList.length] = [myItemSets[i], dropCalcSetItems[myItemSets[i]][2] / totalrarity];
        }
      }
    }
  }
  return totalrarity;
}

function parseDifficultyAffix(n: number) {
  switch (n) {
    case 0:
      return '(Normal)';
    case 1:
      return '(Nightmare)';
    case 2:
      return '(Hell)';
  }
}

function chance(val: number) {
  if (val == 0) {
    return '0';
  }
  val = Number(val) * 100;
  if (val < 0.0000001) {
    return '< 0.0000001';
  }

  val = Math.round(val * 10000000) / 10000000;
  return String(val);
}

function rChance(val: number) {
  if (val == 0) {
    return '0:1';
  }
  if (val > 0.1) {
    return '1:' + Math.round(10 / val) / 10;
  }
  return '1:' + Math.round(1 / val);
}

function calculateColorChancesMF(item: number, mlvl: number, MF: number) {
  // getting chance modifiers from global vars
  // returning values by setting global vars
  const qlvl = dropCalcBaseItems[item][4];
  const type = dropCalcBaseItems[item][5];
  const myratio = ratio[type];

  let chance_left = 1;

  let chance;

  let mf_unique = MF;
  let mf_set = MF;
  let mf_rare = MF;
  const mf_magic = MF;
  if (MF > 10) {
    mf_unique = Math.floor((250 * MF) / (MF + 250));
    mf_set = Math.floor((500 * MF) / (MF + 500));
    mf_rare = Math.floor((600 * MF) / (MF + 600));
  }

  // unique
  chance = 128 * (myratio[0] - Math.floor((mlvl - qlvl) / myratio[1])); // ratio (basechance)
  chance = Math.floor((chance * 100) / (100 + mf_unique)); // mf
  if (chance < myratio[2]) {
    chance = myratio[2];
  } // ratiomin
  chance = chance - Math.floor((chance * cm_unique) / 1024); // tc-modifier

  if (chance <= 128) {
    chance_unique = 1;
    return;
  }
  chance_unique = 128 / chance;
  chance_left -= chance_unique;

  // set
  chance = 128 * (myratio[3] - Math.floor((mlvl - qlvl) / myratio[4])); // ratio (basechance)
  chance = Math.floor((chance * 100) / (100 + mf_set)); // mf
  if (chance < myratio[5]) {
    chance = myratio[5];
  } // ratiomin
  chance = chance - Math.floor((chance * cm_set) / 1024); // tc-modifier

  if (chance <= 128) {
    chance_set = chance_left;
    return;
  }
  chance_set = (128 / chance) * chance_left;
  chance_left -= chance_set;

  // rare
  chance = 128 * (myratio[6] - Math.floor((mlvl - qlvl) / myratio[7])); // ratio (basechance)
  chance = Math.floor((chance * 100) / (100 + mf_rare)); // mf
  if (chance < myratio[8]) {
    chance = myratio[8];
  } // ratiomin
  chance = chance - Math.floor((chance * cm_rare) / 1024); // tc-modifier

  if (chance <= 128) {
    chance_rare = chance_left;
    return;
  }
  chance_rare = (128 / chance) * chance_left;
  chance_left -= chance_rare;

  // magic
  chance = 128 * (myratio[9] - Math.floor((mlvl - qlvl) / myratio[10])); // ratio (basechance)
  chance = Math.floor((chance * 100) / (100 + mf_magic)); // mf
  if (chance < myratio[11]) {
    chance = myratio[11];
  } // ratiomin
  chance = chance - Math.floor((chance * cm_magic) / 1024); // tc-modifier

  if (chance <= 128) {
    chance_magic = chance_left;
    return;
  }
  chance_magic = (128 / chance) * chance_left;
  chance_left -= chance_magic;

  // high quality
  chance = 128 * (myratio[12] - Math.floor((mlvl - qlvl) / myratio[13])); // ratio (basechance)

  if (chance <= 128) {
    chance_high = chance_left;
    return;
  }
  chance_high = (128 / chance) * chance_left;
  chance_left -= chance_high;

  // normal
  chance = 128 * (myratio[14] - Math.floor((mlvl - qlvl) / myratio[15])); // ratio (basechance)

  if (chance <= 128) {
    chance_norm = chance_left;
    return;
  }
  chance_norm = (128 / chance) * chance_left;
  chance_left -= chance_norm;

  // low quality
  chance_low = chance_left;
}

// MONSTER CALC //

export function initMonsters(
  data: DropCalcData,
  lastdifficulty: number,
  lastact: number,
  lastmonstertype: number,
  zones: DropCalcZone,
  dataChange: (data: DropCalcData) => void,
  actsChange: (acts: DataItem[]) => void,
) {
  let i = 0;
  const type = data.monstertype;
  const act = data.act;
  const difficulty = data.difficulty;

  lastmonstertype = type;
  lastact = act;
  lastdifficulty = difficulty;

  if (type > 3) {
    actsChange([{ name: '---', value: 1 }]);
    dataChange({ ...data, act: 1 });
  } else {
    actsChange([
      { name: '1', value: 1 },
      { name: '2', value: 2 },
      { name: '3', value: 3 },
      { name: '4', value: 4 },
      { name: '5', value: 5 },
    ]);
  }

  const monsterlist: DataItem[] = [];

  // create monsterlist
  if (type < 4) {
    for (i = 0; i < dropCalcMonsters.length - 1; i++) {
      if (dropCalcMonsters[i][1] == 0 && dropCalcMonsters[i][2] == act && dropCalcMonsters[i][3 + difficulty] > 0) {
        monsterlist.push({
          name: parseMonsterName(dropCalcMonsters[i][0]),
          value: i,
        });
      }
    }
  } else if (type == 4) {
    for (i = 0; i < dropCalcMonsters.length - 1; i++) {
      if (dropCalcMonsters[i][1] == 1) {
        monsterlist.push({
          name: parseMonsterName(dropCalcMonsters[i][0]),
          value: i,
        });
      }
    }
  } else if (type == 5) {
    for (i = 0; i < zones.length - 1; i++) {
      monsterlist.push({
        name: zones[i][0] as string,
        value: i,
      });
    }
  } else if (type == 6) {
    for (i = 0; i < TCs.length - 1; i++) {
      monsterlist.push({
        name: TCs[i][0],
        value: i,
      });
    }
  }

  // If selected monster is in list, use that
  // Else, use first monster from list
  const monster = monsterlist.find(m => m.value === data.selectedMonster)?.value ?? monsterlist.at(0)!.value;

  const levellist: DataItem[] = [];
  if (type < 4) {
    for (i = 0; i < dropCalcMonsters[monster][15].length - 1; i++) {
      const name = (zones[dropCalcMonsters[monster][15][i]][0] as string).substring(6);
      if (levellist.some(l => l.name === name)) continue;
      levellist.push({
        name,
        value: dropCalcMonsters[monster][15][i],
      });
    }
  } else {
    levellist.push({
      name: '---',
      value: 0,
    });
  }

  const newData = { ...data, selectedLevel: levellist[0].value };

  // If the selected monster is not in the list, then the first monster in the list is used
  // Update data with the monster used for the calculation (first in list)
  if (!monster) {
    newData.selectedMonster = monsterlist.at(0)!.value;
    newData.selectedMonsterIndex = 0;
  }

  dataChange(newData);

  return { lastmonstertype, lastact, lastdifficulty, monsterlist, levellist };
}

function parseMonsterName(name: string) {
  const i = name.indexOf('(');
  if (i < 0) return name;

  return name.substring(0, i);
}

export function calculateMonsterDrops(droptype: number, sort: number, data: DropCalcData, zones: DropCalcZone) {
  const playerbonus = readplayerbonus(data.playersAll, data.playersParty);
  let i;
  let item;
  const arealvl = data.selectedLevel;
  const mlvl = readmlvl(data.difficulty, data.monstertype, data.selectedMonster, arealvl, zones);
  const mname = readmname(data.difficulty, data.monstertype, data.selectedMonster, zones);
  const TC = readTC(data.difficulty, data.monstertype, data.selectedMonster, mlvl, zones);
  let setmonster = data.selectedMonster;
  if (data.monstertype > 4) {
    setmonster = -1;
  }

  const results = [];

  if (playerbonus == -1) {
    return;
  }

  simulateDrops(TC, playerbonus);

  const Itemlist = [];

  for (item = 0; item < dropCalcBaseItems.length - 1; item++) {
    if (!data.showJunk) {
      if (JUNK_ITEM_CODES.includes(dropCalcBaseItems[item][2])) {
        continue;
      }
    }
    const myItem = dropCalcBaseItems[item];
    const basechance = myItem[16];

    if (basechance > 0) {
      if (droptype == 0) {
        Itemlist[Itemlist.length] = [0, item, basechance, item];
      }
      calculateColorChances(item, mlvl, data.mf);
      // uniques
      if (droptype == 0 || droptype == 1) {
        if (getUniqueList(item, mlvl)) {
          for (i = 0; i < UniqueList.length; i++) {
            Itemlist[Itemlist.length] = [
              1,
              UniqueList[i][0],
              UniqueList[i][1] * chance_unique * basechance,
              dropCalcUniques[UniqueList[i][0]][4],
            ];
          }
        }
      }
      // sets
      if (droptype == 0 || droptype == 2) {
        if (getSetitemList(item, mlvl, setmonster, data.selectedQuality)) {
          for (i = 0; i < SetitemList.length; i++) {
            Itemlist[Itemlist.length] = [
              2,
              SetitemList[i][0],
              SetitemList[i][1] * chance_set * basechance,
              dropCalcSetItems[SetitemList[i][0]][4],
              sets[dropCalcSetItems[SetitemList[i][0]][1]],
            ];
          }
        }
      }
      // runes
      if (droptype == 3 && myItem[2] == 'rune') {
        Itemlist[Itemlist.length] = [0, item, basechance, item];
      }
    }
  }
  // sort the list
  if (sort == 1) {
    Itemlist.sort(CompareItemlistByChance);
  }
  // no need to sort for baseitems or runes (already sorted)
  else if (droptype == 1) {
    Itemlist.sort(CompareItemlistByUnique);
  } else if (droptype == 2) {
    Itemlist.sort(CompareItemlistBySet);
  }

  let space = '';
  if (droptype == 0 && sort == 0) {
    space = '&nbsp;&nbsp;&nbsp;';
  }

  // output everything

  for (i = 0; i < Itemlist.length; i++) {
    // results.push(difficulty + "," + monstertype + "," + monster + ",0," + Itemlist[i][3] + "," + mf + "," + playerbonus + "," + arealvl);
    if (Itemlist[i][0] == 0) {
      results.push([
        dropCalcBaseItems[Itemlist[i][1]][0],
        chance(Itemlist[i][2]),
        rChance(Itemlist[i][2]),
        dropCalcBaseItems[Itemlist[i][1]][2] == 'rune' ? 'rune' : dropCalcBaseItems[Itemlist[i][1]][2].includes('gem') ? 'gem' : 'base',
      ]);
    } else if (Itemlist[i][0] == 1) {
      results.push([dropCalcUniques[Itemlist[i][1]][0], chance(Itemlist[i][2]), rChance(Itemlist[i][2]), 'unique']);
    } else {
      results.push([Itemlist[i][4] + ': ' + dropCalcSetItems[Itemlist[i][1]][0], chance(Itemlist[i][2]), rChance(Itemlist[i][2]), 'set']);
    }
  }

  // this.tempResultData = results;
  // this.allResults = results;
  // this.filterResultByItemType();
  return results ?? [[]];
}

function readmname(difficulty: number, monstertype: number, monster: number, zones: DropCalcZone) {
  const diff = difficulty;
  let mname;
  switch (monstertype) {
    case 0: // normal
      mname = dropCalcMonsters[monster][0] + ' (' + diff + ')';
      break;
    case 1: // champion
      mname = 'Champion ' + dropCalcMonsters[monster][0] + ' (' + diff + ')';
      break;
    case 2: // boss
      mname = 'Boss ' + dropCalcMonsters[monster][0] + ' (' + diff + ')';
      break;
    case 3: // diener
      mname = dropCalcMonsters[monster][0] + ' Diener (' + diff + ')';
      break;
    case 4: // superunique
      mname = dropCalcMonsters[monster][0] + ' (' + diff + ')';
      break;
    case 5: // truhe
      mname = 'Truhe in ' + zones[monster][0] + ' (' + difficulty + ')';
      break;
    case 6: // tc
      mname = 'TC ' + TCs[monster][0];
      break;
    default:
      mname = '';
  }
  return mname;
}

function CompareItemlistByChance(a: any, b: any) {
  return b[2] - a[2];
}

function CompareItemlistByUnique(a: any, b: any) {
  return dropCalcUniques[b[1]][0].toLowerCase() < dropCalcUniques[a[1]][0].toLowerCase() ? 1 : -1;
}

function CompareItemlistBySet(a: any, b: any) {
  return dropCalcSetItems[b[1]][0].toLowerCase() < dropCalcSetItems[a[1]][0].toLowerCase() ? 1 : -1;
}

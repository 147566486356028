export const dropCalcMonsters: [
  string,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number[],
  number,
][] = [
  ['Abominable', 0, 5, 41, 61, 83, 397, 411, 425, 649, 664, 678, 734, 748, 762, [108, 110, 111, 0], 0],
  ['Achmel the Cursed', 1, -1, 43, 67, 88, 650, 665, 679, 650, 665, 679, 650, 665, 679, [123, 0], 0],
  ['Afflicted (act1)', 0, 1, 12, 43, 73, 429, 443, 457, 639, 653, 668, 724, 738, 752, [33, 34, 0], 0],
  // ["Afflicted (act5)",0,5,0,58,70,396,410,424,648,663,677,733,747,761,[108,110,0],0],
  ['Ancient Kaa the Soulless', 1, -1, 23, 52, 83, 856, 874, 890, 856, 871, 886, 856, 871, 886, [61, 0], 0],
  ['Andariel (Quest)', 1, -1, 12, 49, 75, 788, 789, 790, 788, 789, 790, 788, 789, 790, [34, 0], 1],
  ['Andariel', 1, -1, 12, 49, 75, 785, 786, 787, 785, 786, 787, 785, 786, 787, [34, 0], 1],
  ['Apparition', 0, 2, 20, 49, 80, 516, 530, 544, 642, 656, 671, 727, 741, 755, [61, 62, 63, 64, 64, 66, 67, 0], 0],
  ['Arach (act1)', 0, 1, 11, 42, 73, 387, 401, 415, 639, 653, 668, 724, 738, 752, [32, 0], 0],
  ['Arach (act5)', 0, 5, 0, 53, 84, 392, 406, 420, 644, 658, 673, 729, 743, 757, [116, 0], 0],
  ['Baal (Quest)', 1, -1, 60, 75, 99, 839, 840, 841, 839, 840, 841, 839, 840, 841, [124, 0], 1],
  ['Baal', 1, -1, 60, 75, 99, 836, 837, 838, 836, 837, 838, 836, 837, 838, [124, 0], 1],
  ['Balrog', 0, 5, 30, 57, 84, 395, 409, 423, 647, 661, 676, 732, 746, 760, [117, 118, 119, 0], 0],
  ['Bartuc the Bloody', 1, -1, 43, 71, 96, 650, 670, 679, 650, 665, 679, 650, 665, 679, [123, 0], 0],
  ['Battlemaid Sarina', 1, -1, 26, 56, 87, 858, 875, 892, 858, 873, 888, 858, 873, 888, [88, 0], 0],
  ['Beetleburst', 1, -1, 19, 48, 79, 855, 872, 889, 855, 870, 885, 855, 870, 885, [39, 0], 0],
  ['Bishibosh', 1, -1, 5, 39, 71, 851, 866, 885, 851, 866, 881, 851, 866, 881, [1, 0], 0],
  ['Black Archer', 0, 1, 10, 41, 72, 471, 485, 499, 639, 653, 668, 724, 738, 752, [29, 0], 0],
  ['Black Locusts', 0, 2, 17, 45, 84, 779, 781, 783, 641, 655, 670, 726, 740, 754, [57, 58, 59, 0], 0],
  ['Black Raptor Nest', 0, 2, 15, 45, 76, 430, 444, 458, 640, 654, 669, 725, 739, 753, [39, 0], 0],
  ['Black Raptor', 0, 2, 16, 45, 76, 431, 445, 459, 641, 655, 670, 726, 740, 754, [39, 0], 0],
  ['Black Rogue', 0, 1, 9, 40, 70, 387, 401, 415, 639, 653, 668, 724, 738, 752, [24, 25, 0], 0],
  ['Blood Boss', 0, 5, 37, 62, 83, 397, 411, 425, 649, 664, 678, 734, 748, 762, [119, 122, 0], 0],
  ['Blood Bringer', 0, 5, 38, 60, 81, 398, 412, 425, 650, 665, 678, 735, 749, 762, [104, 0], 0],
  ['Blood Clan (act1)', 0, 1, 7, 38, 75, 386, 400, 414, 638, 652, 667, 723, 737, 751, [18, 19, 20, 21, 22, 0], 0],
  ['Blood Clan (act5)', 0, 5, 0, 62, 81, 397, 411, 425, 649, 664, 678, 734, 748, 762, [115, 0], 0],
  ['Blood Diver', 0, 3, 24, 54, 85, 392, 406, 420, 644, 658, 673, 729, 743, 757, [91, 92, 93, 0], 0],
  ['Blood Hawk Nest', 0, 1, 8, 38, 69, 428, 442, 456, 638, 652, 667, 723, 737, 751, [4, 0], 0],
  ['Blood Hawk', 0, 1, 6, 38, 69, 428, 442, 456, 638, 652, 667, 723, 737, 751, [4, 0], 0],
  ['Blood Lord (vampire)', 0, 3, 25, 55, 83, 435, 449, 463, 645, 659, 674, 730, 744, 758, [96, 119, 0], 0],
  ['Blood Lord', 0, 5, 36, 61, 82, 398, 412, 426, 650, 665, 679, 735, 749, 763, [105, 117, 118, 0], 0],
  ['Blood Maggot', 0, 4, 27, 57, 85, 394, 408, 422, 646, 660, 675, 731, 745, 759, [100, 115, 0], 0],
  ['Blood Raven', 1, -1, 10, 43, 88, 842, 843, 844, 842, 843, 844, 842, 843, 844, [15, 0], 1],
  ['Blood Temptress', 0, 5, 42, 64, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [109, 0], 0],
  ['Blood Wing', 0, 3, 24, 53, 85, 780, 782, 784, 644, 658, 673, 729, 743, 757, [87, 0], 0],
  ['Bloodwitch the Wild', 1, -1, 17, 46, 78, 854, 871, 889, 854, 869, 884, 854, 869, 884, [55, 0], 0],
  ['Blunderbore (act2)', 0, 2, 18, 47, 78, 389, 403, 417, 641, 655, 670, 726, 740, 754, [46, 47, 48, 49, 0], 0],
  ['Blunderbore (act5)', 0, 5, 0, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [103, 0], 0],
  ['Bog Creature', 0, 3, 22, 50, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [71, 0], 0],
  ['Bone Archer (act1)', 0, 1, 9, 40, 70, 471, 485, 499, 639, 653, 668, 724, 738, 752, [25, 26, 27, 28, 0], 0],
  ['Bone Archer (act5)', 0, 5, 0, 60, 71, 396, 410, 424, 648, 663, 677, 733, 747, 761, [109, 0], 0],
  ['Bone Ash', 1, -1, 17, 47, 82, 853, 871, 890, 853, 868, 883, 853, 868, 883, [30, 0], 0],
  ['Bone Mage (act1)', 0, 1, 10, 40, 70, 429, 443, 457, 639, 653, 668, 724, 738, 752, [25, 26, 27, 28, 29, 0], 0],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ['Bone Mage (act5)', 0, 5, 0, 15, 61, 82, 438, 452, 466, 648, 663, 677, 733, 747, 761, [105, 106, 107, 108, 110, 0], 0],
  ['Bone Scarab', 0, 3, 24, 54, 85, 392, 406, 420, 644, 658, 673, 729, 743, 757, [91, 92, 93, 0], 0],
  ['Bone Warrior', 0, 1, 7, 39, 85, 386, 400, 414, 638, 652, 667, 723, 737, 751, [10, 14, 53, 56, 0], 0],
  ['Bonebreaker', 1, -1, 5, 40, 71, 851, 866, 885, 851, 866, 881, 851, 866, 881, [16, 0], 0],
  ['Bonesaw Breaker', 1, -1, 36, 65, 85, 863, 879, 893, 863, 878, 893, 863, 878, 893, [107, 0], 0],
  ['Bramble Hulk', 0, 3, 22, 50, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [71, 0], 0],
  ['Bremm Sparkfist', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [96, 0], 0],
  ['Brute', 0, 1, 5, 38, 69, 386, 400, 414, 638, 652, 667, 723, 737, 751, [3, 4, 9, 13, 0], 0],
  ['Burning Dead Archer (act2)', 0, 2, 13, 43, 74, 472, 486, 500, 640, 654, 669, 725, 739, 753, [43, 44, 0], 0],
  ['Burning Dead Archer (act5)', 0, 5, 0, 58, 80, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 103, 0], 0],
  ['Burning Dead Mage (act2)', 0, 2, 14, 44, 75, 430, 444, 458, 640, 654, 669, 725, 739, 753, [45, 50, 54, 0], 0],
  ['Burning Dead Mage (act5)', 0, 5, 0, 59, 81, 438, 452, 466, 648, 663, 677, 733, 747, 761, [118, 0], 0],
  ['Burning Dead', 0, 2, 13, 43, 74, 388, 402, 416, 640, 654, 669, 725, 739, 753, [43, 44, 45, 61, 0], 0],
  ['Burning Soul (act4)', 0, 4, 26, 56, 83, 520, 534, 548, 643, 657, 672, 728, 742, 756, [98, 0], 0],
  ['Burning Soul (act5)', 0, 5, 0, 61, 83, 521, 535, 549, 648, 663, 677, 733, 747, 761, [123, 0], 0],
  ['Cadaver', 0, 3, 25, 55, 83, 393, 407, 421, 645, 659, 674, 730, 744, 758, [94, 95, 0], 0],
  ['Cantor', 0, 3, 23, 51, 81, 434, 448, 462, 644, 658, 673, 729, 743, 757, [75, 0], 0],
  ['Carver Shaman', 0, 1, 6, 38, 69, 428, 442, 456, 638, 652, 667, 723, 737, 751, [2, 3, 4, 9, 13, 35, 0], 0],
  ['Carver', 0, 1, 5, 38, 68, 386, 400, 414, 638, 652, 667, 723, 737, 751, [2, 3, 4, 8, 9, 12, 13, 35, 0], 0],
  ['Cave Leaper (act2)', 0, 2, 15, 44, 76, 388, 402, 416, 640, 654, 669, 725, 739, 753, [38, 0], 0],
  ['Cave Leaper (act5)', 0, 5, 0, 61, 82, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 0], 0],
  ['Claw Viper (act2)', 0, 2, 15, 45, 76, 388, 402, 416, 640, 654, 669, 725, 739, 753, [53, 56, 0], 0],
  ['Claw Viper (act5)', 0, 5, 0, 61, 82, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 106, 0], 0],
  ['Cliff Lurker', 0, 4, 26, 56, 82, 394, 408, 422, 646, 660, 675, 731, 745, 759, [97, 0], 0],
  ['Coldcrow', 1, -1, 7, 40, 71, 851, 866, 885, 851, 866, 881, 851, 866, 881, [7, 0], 0],
  ['Coldworm the Burrower', 1, -1, 14, 49, 84, 855, 872, 891, 855, 870, 885, 855, 870, 885, [59, 0], 0],
  ['Colenzo the Annihilator', 1, -1, 43, 58, 86, 650, 665, 679, 650, 665, 679, 650, 665, 679, [123, 0], 0],
  ['Corpse Spitter', 0, 4, 27, 57, 84, 394, 408, 422, 646, 660, 675, 731, 745, 759, [99, 0], 0],
  ['Corpsefire', 1, -1, 4, 39, 70, 851, 866, 885, 851, 866, 881, 851, 866, 881, [6, 0], 0],
  ['Council Member (act3)', 0, 3, 25, 56, 88, 818, 819, 820, 818, 819, 820, 818, 819, 820, [77, 96, 0], 0],
  ['Council Member (act5)', 0, 5, 40, 68, 93, 397, 411, 426, 649, 664, 678, 734, 748, 762, [123, 0], 0],
  ['Creeping Feature', 1, -1, 18, 47, 82, 854, 871, 890, 854, 869, 884, 854, 869, 884, [54, 0], 0],
  ['Crush Beast', 0, 5, 38, 60, 81, 398, 412, 425, 650, 665, 678, 735, 749, 762, [109, 0], 0],
  ['Crusher', 0, 2, 19, 48, 79, 390, 404, 418, 642, 656, 671, 727, 741, 755, [42, 0], 0],
  ['Dac Farren', 1, -1, 37, 62, 84, 864, 879, 894, 864, 879, 894, 864, 879, 894, [102, 0], 0],
  ['Damned', 0, 4, 27, 57, 84, 436, 450, 464, 646, 660, 675, 731, 745, 759, [99, 0], 0],
  ['Dark Elder', 1, -1, 20, 49, 80, 855, 872, 889, 855, 870, 885, 855, 870, 885, [40, 0], 0],
  ['Dark Familiar', 0, 4, 27, 57, 84, 393, 407, 421, 645, 659, 674, 730, 744, 758, [99, 0], 0],
  ['Dark Hunter', 0, 1, 2, 36, 67, 385, 399, 413, 637, 651, 666, 722, 736, 750, [1, 0], 0],
  ['Dark Lancer (act1)', 0, 1, 8, 39, 69, 386, 400, 414, 638, 652, 667, 723, 737, 751, [5, 0], 0],
  ['Dark Lancer (act5)', 0, 5, 0, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [106, 110, 0], 0],
  ['Dark Lord (act3)', 0, 3, 24, 55, 83, 434, 448, 462, 644, 658, 673, 729, 743, 757, [94, 95, 0], 0],
  ['Dark Lord (act5)', 0, 5, 0, 55, 83, 435, 449, 463, 645, 659, 674, 730, 744, 758, [123, 0], 0],
  ['Dark One (act1)', 0, 1, 10, 41, 71, 387, 401, 415, 639, 653, 668, 724, 738, 752, [26, 27, 28, 29, 30, 31, 32, 34, 0], 0],
  ['Dark One (act5)', 0, 5, 0, 61, 83, 396, 410, 424, 648, 663, 677, 733, 747, 761, [117, 0], 0],
  ['Dark Ranger', 0, 1, 4, 37, 68, 469, 483, 497, 637, 651, 666, 722, 736, 750, [2, 7, 11, 0], 0],
  ['Dark Shaman (act1)', 0, 1, 11, 42, 72, 429, 443, 457, 639, 653, 668, 724, 738, 752, [30, 31, 32, 34, 0], 0],
  ['Dark Shaman (act5)', 0, 5, 0, 61, 83, 438, 452, 466, 648, 663, 677, 733, 747, 761, [117, 0], 0],
  ['Dark Stalker', 0, 1, 8, 39, 69, 387, 401, 415, 639, 653, 668, 724, 738, 752, [5, 10, 14, 22, 23, 0], 0],
  ['Death Beetle', 0, 3, 16, 45, 76, 389, 403, 417, 641, 655, 670, 726, 740, 754, [39, 57, 58, 59, 0], 0],
  ['Death Berserker', 0, 5, 40, 61, 82, 397, 411, 425, 649, 664, 678, 734, 748, 762, [109, 0], 0],
  ['Death Brawler', 0, 5, 35, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [103, 0], 0],
  ['Death Brigadier', 0, 5, 41, 62, 83, 398, 412, 426, 650, 665, 679, 735, 749, 763, [114, 0], 0],
  ['Death Clan', 0, 1, 10, 40, 70, 387, 401, 415, 639, 653, 668, 724, 738, 752, [25, 26, 27, 28, 0], 0],
  ['Death Lord', 0, 5, 43, 65, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [120, 122, 123, 0], 0],
  ['Death Mage', 0, 5, 14, 43, 74, 438, 452, 466, 648, 663, 677, 733, 747, 761, [123, 0], 0],
  ['Death Mauler', 0, 5, 32, 58, 80, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 103, 0], 0],
  ['Death Slasher', 0, 5, 38, 60, 81, 397, 411, 425, 649, 664, 678, 734, 748, 762, [104, 0], 0],
  ['Decayed', 0, 2, 15, 44, 79, 388, 402, 416, 640, 654, 669, 725, 739, 753, [51, 52, 54, 55, 0], 0],
  ['Defiled Warrior', 0, 5, 42, 65, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [113, 0], 0],
  ['Demon Gremlin', 0, 5, 34, 59, 81, 439, 453, 467, 649, 664, 678, 734, 748, 762, [102, 103, 104, 0], 0],
  ['Demon Imp', 0, 5, 31, 58, 80, 438, 452, 466, 648, 663, 677, 733, 747, 761, [102, 103, 104, 109, 0], 0],
  ['Demon Imp', 0, 5, 38, 63, 85, 440, 454, 468, 650, 665, 679, 735, 749, 763, [117, 118, 0], 0],
  ['Demon Rascal', 0, 5, 33, 60, 83, 438, 452, 466, 648, 663, 677, 733, 747, 761, [109, 0], 0],
  ['Demon Sprite', 0, 5, 38, 63, 85, 440, 454, 468, 650, 665, 679, 735, 749, 763, [122, 0], 0],
  ['Demon Trickster', 0, 5, 36, 62, 84, 439, 453, 467, 649, 664, 678, 734, 748, 762, [119, 0], 0],
  ['Devilkin Shaman', 0, 1, 9, 40, 70, 429, 443, 457, 639, 653, 668, 724, 738, 752, [10, 14, 24, 25, 0], 0],
  ['Devilkin', 0, 1, 7, 39, 85, 386, 400, 414, 638, 652, 667, 723, 737, 751, [5, 10, 14, 18, 19, 20, 21, 22, 24, 25, 0], 0],
  ['Devourer', 0, 2, 19, 48, 79, 389, 403, 417, 641, 655, 670, 726, 740, 754, [42, 0], 0],
  ['Diablo (Quest)', 1, -1, 40, 62, 94, 812, 813, 814, 812, 813, 814, 812, 813, 814, [101, 0], 1],
  ['Diablo', 1, -1, 40, 62, 94, 809, 810, 811, 809, 810, 811, 809, 810, 811, [101, 0], 1],
  ['Disfigured', 0, 1, 8, 40, 70, 428, 442, 455, 638, 652, 667, 723, 737, 751, [23, 0], 0],
  ['Doom Ape', 0, 3, 22, 52, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [73, 0], 0],
  ['Doom Caster', 0, 4, 24, 56, 82, 520, 535, 548, 646, 660, 675, 731, 745, 759, [97, 98, 0], 0],
  ['Dried Corpse', 0, 2, 13, 43, 74, 388, 402, 416, 640, 654, 669, 725, 739, 753, [43, 44, 45, 51, 52, 53, 61, 0], 0],
  ['Drowned Carcass', 0, 3, 22, 50, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [71, 81, 0], 0],
  ['Dune Beast', 0, 2, 18, 47, 78, 389, 403, 417, 641, 655, 670, 726, 740, 754, [46, 47, 48, 49, 0], 0],
  ['Dung Soldier', 0, 2, 14, 43, 75, 388, 402, 416, 640, 654, 669, 725, 739, 753, [37, 50, 54, 0], 0],
  ['Duriel (Quest)', 1, -1, 22, 55, 88, 947, 948, 949, 947, 948, 949, 947, 948, 949, [68, 0], 1],
  ['Duriel', 1, -1, 22, 55, 88, 944, 945, 946, 944, 945, 946, 944, 945, 946, [68, 0], 1],
  ['Eldritch the Rectifier', 1, -1, 34, 61, 82, 863, 878, 893, 863, 878, 893, 863, 878, 893, [103, 0], 0],
  ['Embalmed', 0, 2, 18, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [53, 56, 60, 0], 0],
  ['Enslaved', 0, 5, 31, 58, 79, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 103, 109, 0], 0],
  ['Eyeback the Unleashed', 1, -1, 35, 61, 83, 863, 878, 893, 863, 878, 893, 863, 878, 893, [103, 0], 0],
  ['Faithful', 0, 3, 22, 51, 81, 392, 406, 420, 644, 658, 673, 729, 743, 757, [74, 75, 0], 0],
  ['Fallen Shaman', 0, 1, 2, 36, 68, 427, 441, 455, 637, 651, 666, 722, 736, 750, [1, 2, 6, 7, 8, 11, 12, 0], 0],
  ['Fallen', 0, 1, 1, 36, 67, 385, 399, 413, 637, 651, 666, 722, 736, 750, [0, 1, 2, 6, 7, 8, 11, 12, 0], 0],
  ['Fangskin', 1, -1, 21, 49, 80, 855, 872, 889, 855, 870, 885, 855, 870, 885, [56, 0], 0],
  ['Feeder', 0, 3, 23, 51, 82, 780, 782, 784, 644, 658, 673, 729, 743, 757, [86, 0], 0],
  ['Fetid Defiler', 0, 5, 38, 63, 82, 439, 453, 467, 649, 664, 678, 734, 748, 762, [120, 0], 1],
  ['Fiend (act3)', 0, 3, 19, 50, 79, 389, 403, 417, 641, 655, 670, 726, 740, 754, [78, 79, 80, 81, 84, 0], 0],
  ['Fiend (act5)', 0, 5, 0, 57, 84, 393, 407, 421, 645, 659, 674, 740, 740, 757, [121, 0], 0],
  ['Fire Boar', 0, 5, 36, 60, 81, 397, 411, 425, 649, 664, 678, 734, 748, 762, [104, 119, 122, 0], 0],
  ['Fire Eye', 1, -1, 21, 50, 81, 855, 873, 890, 855, 870, 885, 855, 870, 885, [49, 0], 0],
  ['Flame Spider', 0, 3, 22, 50, 79, 391, 405, 419, 643, 657, 672, 728, 742, 756, [78, 79, 0], 0],
  ['Flayer (act3)', 0, 3, 22, 50, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [72, 82, 83, 84, 85, 0], 0],
  ['Flayer (act5)', 0, 5, 0, 54, 83, 392, 406, 420, 644, 658, 673, 729, 743, 757, [114, 116, 0], 0],
  ['Flayer Shaman (act3)', 0, 3, 23, 50, 81, 434, 448, 462, 644, 658, 673, 729, 743, 757, [72, 82, 83, 85, 0], 0],
  ['Flayer Shaman (act5)', 0, 5, 0, 53, 84, 434, 448, 462, 644, 658, 673, 729, 743, 757, [116, 0], 0],
  ['Flesh Hunter', 0, 3, 23, 53, 84, 392, 406, 420, 644, 658, 673, 729, 743, 757, [88, 89, 90, 0], 0],
  ['Flesh Spawner (act4)', 0, 4, 24, 56, 82, 436, 450, 464, 646, 660, 675, 731, 745, 759, [97, 98, 0], 0],
  ['Flesh Spawner (act5)', 0, 5, 0, 57, 85, 437, 451, 465, 647, 661, 676, 732, 746, 760, [120, 0], 0],
  ['Foul Crow (act1)', 0, 1, 4, 37, 68, 427, 441, 455, 637, 651, 666, 722, 736, 750, [2, 0], 0],
  ['Foul Crow (act5)', 0, 5, 0, 58, 80, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 0], 0],
  ['Foul Crow Nest', 0, 1, 3, 37, 68, 427, 441, 455, 637, 651, 666, 722, 736, 750, [2, 0], 0],
  ['Frozen Abyss', 0, 5, 43, 63, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [106, 0], 0],
  ['Frozen Creeper', 0, 5, 37, 61, 82, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 106, 107, 111, 0], 0],
  ['Frozen Terror', 0, 5, 38, 61, 83, 397, 411, 425, 649, 664, 678, 734, 748, 762, [108, 110, 111, 0], 0],
  ['Frozenstein', 1, -1, 46, 66, 88, 865, 880, 895, 865, 880, 895, 865, 880, 895, [106, 0], 0],
  ['Gargantuan Beast', 0, 1, 2, 36, 68, 385, 399, 413, 637, 651, 666, 722, 736, 750, [1, 6, 0], 0],
  ['Geleb Flamefinger', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [77, 0], 0],
  ['Ghost (act1)', 0, 1, 7, 38, 75, 512, 526, 540, 638, 652, 667, 723, 737, 751, [18, 19, 20, 21, 22, 0], 0],
  ['Ghost (act5)', 0, 5, 0, 61, 82, 512, 526, 546, 648, 660, 675, 733, 747, 761, [105, 106, 108, 111, 116], 0],
  ['Ghoul Lord (act2)', 0, 2, 19, 49, 80, 432, 446, 460, 642, 656, 671, 727, 741, 755, [61, 69, 0], 0],
  ['Ghoul Lord (act5)', 0, 5, 0, 55, 83, 435, 449, 463, 645, 659, 674, 730, 744, 758, [120, 0], 0],
  ['Ghoul', 0, 1, 12, 43, 73, 390, 404, 418, 642, 656, 671, 727, 741, 755, [33, 34, 0], 0],
  ['Gloombat (act3)', 0, 3, 22, 51, 81, 391, 405, 419, 643, 657, 672, 728, 742, 756, [82, 85, 86, 0], 0],
  ['Gloombat (act5)', 0, 5, 0, 57, 84, 393, 407, 421, 645, 659, 674, 730, 744, 758, [107, 111, 0], 0],
  ['Gorebelly', 0, 2, 20, 49, 80, 390, 404, 418, 642, 656, 671, 727, 741, 755, [61, 62, 63, 64, 65, 66, 67, 0], 0],
  ['Grand Vizier of Chaos', 1, -1, 33, 61, 88, 861, 877, 892, 861, 876, 891, 861, 876, 891, [101, 0], 0],
  ['Greater Hell Spawn', 0, 5, 42, 66, 82, 397, 411, 426, 649, 664, 679, 734, 748, 763, [121, 0], 0],
  ['Griswold', 1, -1, 8, 42, 87, 821, 822, 823, 821, 822, 823, 821, 822, 823, [35, 0], 1],
  ['Grotesque (act4)', 0, 4, 30, 57, 85, 437, 451, 465, 647, 661, 676, 732, 746, 760, [100, 0], 0],
  ['Grotesque (act5)', 0, 5, 0, 57, 85, 437, 451, 465, 647, 661, 676, 732, 746, 760, [115, 0], 0],
  ['Heirophant (act3)', 0, 3, 24, 53, 82, 434, 448, 462, 644, 658, 673, 729, 743, 757, [76, 77, 0], 0],
  ['Heirophant (act5)', 0, 5, 0, 57, 84, 434, 448, 462, 644, 658, 673, 729, 743, 757, [115, 120, 0], 0],
  ['Hell Bovine', 0, 1, 28, 61, 81, 824, 825, 826, 647, 661, 676, 732, 746, 760, [36, 0], 0],
  ['Hell Buzzard', 0, 3, 22, 52, 80, 780, 782, 784, 644, 658, 673, 729, 743, 757, [73, 0], 0],
  ['Hell Cat', 0, 2, 19, 48, 79, 390, 404, 418, 642, 656, 671, 727, 741, 755, [42, 0], 0],
  ['Hell Clan (act2)', 0, 2, 19, 48, 79, 390, 404, 418, 642, 656, 671, 727, 741, 755, [69, 0], 0],
  ['Hell Clan (act5)', 0, 5, 0, 61, 84, 397, 411, 425, 649, 664, 678, 734, 748, 762, [118, 0], 0],
  ['Hell Lord', 0, 5, 36, 62, 82, 397, 412, 426, 649, 665, 679, 734, 749, 763, [110, 111, 0], 0],
  ['Hell Slinger', 0, 2, 19, 48, 80, 474, 488, 502, 642, 656, 671, 727, 741, 755, [42, 0], 0],
  ['Hell Swarm', 0, 3, 22, 52, 81, 780, 782, 784, 644, 658, 673, 729, 743, 757, [74, 0], 0],
  ['Hell Temptress', 0, 5, 40, 63, 84, 398, 411, 425, 650, 664, 678, 735, 748, 762, [111, 116, 122, 0], 0],
  ['Hell Witch', 0, 5, 42, 64, 85, 440, 454, 468, 650, 665, 679, 735, 749, 763, [119, 123, 0], 0],
  ['Hephasto The Armorer', 1, -1, 28, 63, 91, 830, 831, 832, 830, 831, 832, 830, 831, 832, [100, 0], 0],
  ['Hollow One', 0, 2, 15, 44, 79, 430, 445, 464, 640, 654, 669, 725, 739, 753, [51, 52, 55, 0], 0],
  ['Horadrim Ancient (act5)', 0, 3, 40, 64, 85, 439, 453, 467, 649, 664, 678, 734, 748, 762, [121, 123, 0], 0],
  ['Horror Archer (act5)', 0, 5, 0, 63, 85, 398, 412, 425, 650, 665, 678, 735, 749, 762, [117, 0], 0],
  ['Horror Archer', 0, 2, 18, 47, 78, 473, 487, 501, 641, 655, 670, 726, 740, 754, [46, 47, 48, 49, 0], 0],
  ['Horror Mage (act2)', 0, 2, 17, 47, 78, 431, 445, 459, 641, 655, 670, 726, 740, 754, [45, 46, 47, 48, 49, 60, 0], 0],
  ['Horror Mage (act5)', 0, 5, 0, 47, 78, 431, 445, 459, 641, 655, 670, 726, 740, 754, [114, 121, 122, 0], 0],
  ['Horror', 0, 2, 14, 44, 78, 388, 402, 416, 640, 654, 669, 725, 739, 753, [43, 44, 45, 50, 54, 0], 0],
  ['Hungry Dead', 0, 1, 2, 37, 68, 385, 399, 413, 637, 651, 666, 722, 736, 750, [2, 7, 11, 15, 16, 17, 0], 0],
  ['Huntress', 0, 2, 14, 43, 75, 388, 402, 416, 640, 654, 669, 725, 739, 753, [37, 45, 55, 0], 0],
  ['Ice Spawn', 0, 5, 40, 64, 81, 398, 412, 425, 650, 665, 678, 735, 749, 762, [116, 0], 0],
  ['Icehawk Riftwing', 1, -1, 25, 54, 84, 857, 875, 891, 857, 872, 887, 857, 872, 887, [43, 0], 0],
  ['Infector of Souls', 1, -1, 33, 60, 87, 861, 877, 892, 861, 876, 891, 861, 876, 891, [101, 0], 0],
  ['Infidel (act3)', 0, 3, 24, 53, 81, 391, 405, 419, 643, 657, 672, 728, 742, 756, [76, 0], 0],
  ['Infidel (act5)', 0, 5, 0, 60, 72, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 108, 115, 0], 0],
  ['Invader (act2)', 0, 2, 18, 47, 78, 389, 403, 417, 641, 655, 670, 726, 740, 754, [46, 47, 48, 49, 60, 0], 0],
  ['Invader (act5)', 0, 5, 0, 62, 82, 397, 411, 425, 649, 664, 678, 734, 748, 762, [120, 0], 0],
  ['Ismail Vilehand', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [77, 0], 0],
  ['Itchies', 0, 2, 16, 45, 76, 779, 781, 783, 641, 655, 670, 726, 740, 754, [39, 0], 0],
  ['Izual', 1, -1, 29, 60, 86, 848, 849, 850, 848, 849, 850, 848, 849, 850, [98, 0], 1],
  ['Jungle Hunter', 0, 3, 21, 49, 79, 391, 405, 419, 643, 657, 672, 728, 742, 756, [70, 0], 0],
  ['Lasher', 0, 5, 34, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [103, 0], 0],
  ['Lister the Tormentor', 1, -1, 58, 71, 95, 650, 670, 679, 650, 665, 679, 650, 665, 679, [123, 0], 0],
  ['Lord De Seis', 1, -1, 33, 61, 88, 862, 877, 892, 862, 877, 892, 862, 877, 892, [101, 0], 0],
  ['Maffer Dragonhand', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [96, 0], 0],
  ['Marauder', 0, 2, 17, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [40, 0], 0],
  ['Mauler (act3)', 0, 3, 25, 55, 83, 393, 409, 421, 645, 659, 674, 730, 744, 758, [94, 95, 0], 0],
  ['Mauler (act5)', 0, 5, 0, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [118, 0], 0],
  ['Mephisto (Quest)', 1, -1, 26, 59, 87, 806, 807, 808, 806, 807, 808, 806, 807, 808, [96, 0], 1],
  ['Mephisto', 1, -1, 26, 59, 87, 803, 804, 805, 803, 804, 805, 803, 804, 805, [96, 0], 1],
  ['Minion of Destruction', 0, 5, 55, 68, 92, 398, 412, 426, 650, 665, 679, 735, 749, 763, [123, 0], 0],
  ['Misshapen', 0, 1, 5, 37, 69, 428, 442, 456, 638, 652, 667, 723, 737, 751, [8, 9, 12, 13, 0], 0],
  ['Moon Lord', 0, 5, 36, 61, 82, 398, 412, 426, 650, 665, 679, 735, 749, 763, [107, 108, 0], 0],
  ['Night Clan (act1)', 0, 1, 6, 38, 69, 386, 400, 414, 638, 652, 667, 723, 737, 751, [4, 35, 0], 0],
  ['Night Clan (act5)', 0, 5, 0, 61, 83, 396, 410, 424, 648, 663, 677, 733, 747, 761, [104, 108, 0], 0],
  ['Night Lord (Blood Lord)', 0, 5, 35, 61, 82, 397, 411, 425, 649, 664, 678, 734, 748, 762, [114, 115, 119, 0], 0],
  ['Night Lord (Vampire)', 0, 3, 23, 54, 82, 434, 448, 462, 644, 658, 673, 729, 743, 757, [77, 88, 89, 90, 0], 0],
  ['Night Slinger', 0, 2, 17, 46, 77, 473, 487, 501, 641, 655, 670, 726, 740, 754, [40, 0], 0],
  ['Night Tiger', 0, 2, 17, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [40, 0], 0],
  ['Nihlathak', 1, -1, 68, 73, 95, 833, 834, 835, 833, 834, 835, 833, 834, 835, [116, 0], 1],
  ['Oblivion Knight (act4)', 0, 4, 30, 58, 85, 395, 409, 423, 647, 661, 676, 732, 746, 760, [101, 0], 0],
  ['Oblivion Knight (act5)', 0, 5, 0, 58, 85, 395, 409, 423, 647, 661, 676, 732, 746, 760, [119, 122, 123, 0], 0],
  ['Overlord', 0, 5, 36, 61, 82, 397, 411, 425, 649, 664, 678, 734, 748, 762, [118, 0], 0],
  ['Pindleskin', 1, -1, 45, 68, 88, 910, 883, 940, 910, 925, 940, 910, 925, 940, [113, 0], 0],
  ['Pitspawn Fouldog', 1, -1, 14, 45, 75, 852, 870, 888, 852, 867, 882, 852, 867, 882, [27, 0], 0],
  ['Plague Bearer', 0, 2, 17, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [40, 60, 0], 0],
  ['Poison Spinner', 0, 3, 21, 49, 78, 391, 405, 419, 643, 657, 672, 728, 742, 756, [78, 79, 0], 0],
  ['Preserved Dead', 0, 2, 23, 49, 80, 392, 406, 420, 644, 658, 673, 729, 743, 757, [61, 0], 0],
  ['Preserved Dead', 0, 3, 23, 49, 80, 392, 406, 420, 644, 658, 673, 729, 743, 757, [80, 86, 87, 0], 0],
  ['Prowling Dead', 0, 5, 35, 63, 83, 397, 411, 425, 649, 664, 678, 734, 748, 762, [113, 114, 115, 0], 0],
  ['Quill Rat', 0, 1, 1, 36, 67, 764, 769, 774, 637, 651, 666, 722, 736, 750, [0, 0], 0],
  ['Radament', 1, -1, 19, 52, 86, 797, 798, 799, 797, 798, 799, 797, 798, 799, [45, 0], 1],
  ['Rakanishu', 1, -1, 8, 41, 71, 852, 867, 885, 852, 867, 882, 852, 867, 882, [2, 0], 0],
  ['Rancid Defiler', 0, 5, 39, 64, 83, 439, 453, 467, 649, 664, 678, 734, 748, 762, [122, 0], 1],
  ['Rat Man (act1)', 0, 1, 10, 42, 72, 387, 401, 415, 639, 653, 668, 724, 738, 752, [31, 0], 0],
  ['Rat Man (act3)', 0, 3, 10, 42, 72, 387, 401, 415, 639, 653, 668, 724, 738, 752, [72, 0], 0],
  ['Razor Spine (act1)', 0, 1, 9, 40, 70, 767, 772, 777, 639, 653, 668, 724, 738, 752, [24, 0], 0],
  ['Razor Spine (act5)', 0, 5, 0, 58, 80, 764, 769, 774, 638, 651, 666, 722, 736, 750, [115, 0], 0],
  ['Reanimated Horde', 0, 5, 33, 62, 82, 397, 411, 425, 648, 663, 678, 733, 747, 762, [110, 0], 0],
  ['Returned Archer (act5)', 0, 5, 0, 62, 83, 397, 411, 425, 649, 664, 678, 734, 748, 762, [116, 0], 0],
  ['Returned Archer', 0, 1, 8, 39, 69, 470, 484, 498, 638, 652, 667, 723, 737, 751, [5, 23, 0], 0],
  ['Returned Mage (act1)', 0, 1, 8, 39, 69, 428, 442, 456, 638, 652, 667, 723, 737, 751, [5, 23, 0], 0],
  ['Returned Mage (act5)', 0, 5, 0, 59, 81, 438, 452, 466, 648, 663, 677, 733, 747, 761, [104, 109, 0], 0],
  ['Returned', 0, 2, 6, 38, 69, 386, 400, 414, 638, 652, 667, 723, 737, 751, [51, 52, 55, 0], 0],
  ['Rock Worm', 0, 2, 17, 45, 84, 388, 402, 416, 640, 654, 669, 725, 739, 753, [57, 58, 59, 0], 0],
  ['Rot Walker', 0, 5, 31, 61, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [106, 107, 0], 0],
  ['Saber Cat', 0, 2, 15, 44, 76, 388, 402, 416, 640, 654, 669, 725, 739, 753, [38, 0], 0],
  ['Salamander (act2)', 0, 2, 18, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [53, 56, 0], 0],
  ['Salamander (act5)', 0, 5, 0, 61, 77, 397, 411, 425, 649, 664, 678, 734, 748, 762, [118, 119, 0], 0],
  ['Sand Leaper', 0, 2, 14, 43, 75, 388, 402, 416, 640, 654, 669, 725, 739, 753, [37, 0], 0],
  ['Sand Maggot', 0, 2, 16, 45, 76, 388, 402, 416, 640, 654, 669, 725, 739, 753, [39, 57, 58, 59, 0], 0],
  ['Sand Raider', 0, 2, 13, 43, 74, 388, 402, 416, 640, 654, 669, 725, 739, 753, [43, 44, 0], 0],
  ['Serpent Magus (act3)', 0, 3, 24, 54, 85, 392, 406, 420, 644, 658, 673, 729, 743, 757, [91, 92, 93, 0], 0],
  ['Serpent Magus (act5)', 0, 5, 0, 63, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [121, 123, 0], 0],
  ['Sharptooth Slayer', 1, -1, 39, 64, 85, 864, 879, 894, 864, 879, 894, 864, 879, 894, [103, 0], 0],
  ['Shenk the Overseer', 1, -1, 36, 61, 83, 863, 878, 893, 863, 878, 893, 863, 878, 893, [102, 0], 0],
  ['Siege Beast', 0, 5, 37, 59, 81, 398, 412, 424, 650, 665, 677, 735, 749, 761, [103, 0], 0],
  ['Skeleton Archer', 0, 1, 5, 38, 68, 470, 484, 498, 638, 652, 667, 723, 737, 753, [3, 8, 12, 35, 0], 0],
  ['Skeleton', 0, 1, 2, 37, 68, 385, 399, 413, 637, 651, 666, 722, 736, 750, [2, 7, 11, 15, 16, 17, 0], 0],
  ['Slayer', 0, 5, 33, 59, 81, 396, 410, 424, 648, 663, 677, 733, 747, 761, [103, 116, 0], 0],
  ['Slinger (act2)', 0, 2, 14, 43, 75, 472, 486, 500, 640, 654, 669, 725, 739, 753, [37, 0], 0],
  ['Slinger (act5)', 0, 5, 0, 58, 80, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 103, 104, 0], 0],
  ['Snapchip Shatter', 1, -1, 40, 64, 85, 865, 880, 895, 865, 880, 895, 865, 880, 895, [111, 0], 0],
  ['Snow Drifter', 0, 5, 40, 61, 82, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 106, 107, 0], 0],
  ['Soul Killer (act3)', 0, 3, 23, 51, 81, 392, 406, 420, 644, 658, 673, 729, 743, 757, [72, 82, 83, 85, 0], 0],
  ['Soul Killer (act5)', 0, 5, 0, 54, 83, 392, 406, 420, 644, 658, 673, 729, 743, 757, [120, 0], 0],
  ['Soul Killer Shaman (act3)', 0, 3, 24, 51, 82, 434, 448, 462, 644, 658, 673, 729, 743, 757, [72, 82, 83, 85, 0], 0],
  ['Soul Killer Shaman (act5)', 0, 5, 0, 53, 84, 434, 448, 462, 644, 658, 673, 729, 743, 757, [120, 0], 0],
  ['Spear Cat', 0, 2, 15, 44, 76, 472, 486, 500, 640, 654, 669, 725, 739, 753, [38, 51, 52, 55, 0], 0],
  ['Wraith (act1)', 0, 1, 10, 41, 71, 513, 527, 541, 639, 653, 668, 724, 738, 752, [26, 27, 28, 30, 0], 0],
  ['Specter (act2)', 0, 2, 19, 48, 79, 516, 530, 544, 642, 656, 671, 727, 741, 755, [69, 0], 0],
  ['Specter / Wraith (act5)', 0, 5, 0, 61, 82, 512, 526, 540, 638, 652, 667, 723, 737, 751, [107, 122, 0], 0],
  ['Spider Magus', 0, 3, 23, 53, 84, 392, 406, 420, 644, 658, 673, 729, 743, 757, [88, 89, 90, 0], 0],
  ['Spike Fiend (act1)', 0, 1, 5, 38, 68, 765, 770, 775, 637, 651, 666, 722, 736, 750, [3, 0], 0],
  ['Spike Fiend (act5)', 0, 5, 0, 58, 80, 764, 769, 774, 637, 663, 677, 722, 747, 761, [110, 0], 0],
  ['Sszark the Burning', 1, -1, 25, 53, 82, 857, 874, 890, 857, 872, 887, 857, 872, 887, [79, 0], 0],
  ['Steel Scarab (act2)', 0, 2, 19, 48, 79, 390, 404, 418, 642, 656, 671, 727, 741, 755, [42, 61, 0], 0],
  ['Steel Scarab (act5)', 0, 5, 0, 54, 85, 392, 406, 420, 644, 658, 673, 729, 743, 757, [121, 0], 0],
  ['Storm Caster (act4)', 0, 4, 30, 58, 85, 521, 535, 548, 647, 661, 676, 732, 746, 760, [101, 0], 0],
  ['Storm Caster (act5)', 0, 5, 0, 58, 85, 521, 535, 548, 647, 661, 676, 732, 746, 760, [122, 0], 0],
  ['Stormtree', 1, -1, 26, 55, 84, 858, 875, 891, 858, 873, 888, 858, 873, 888, [72, 0], 0],
  ['Stygian Fury', 0, 5, 35, 62, 83, 439, 453, 467, 649, 664, 678, 734, 748, 762, [123, 0], 0],
  ['Stygian Hag', 0, 4, 27, 57, 84, 436, 450, 464, 646, 660, 675, 731, 745, 759, [99, 0], 0],
  ['Succubus', 0, 5, 42, 61, 83, 396, 410, 424, 648, 663, 677, 733, 747, 761, [105, 106, 108, 0], 0],
  ['Sucker', 0, 3, 21, 49, 79, 780, 782, 784, 643, 657, 672, 728, 742, 756, [70, 0], 0],
  ['Swamp Dweller', 0, 3, 21, 49, 79, 391, 405, 419, 643, 657, 672, 728, 742, 756, [71, 0], 0],
  ['Tainted', 0, 1, 11, 42, 72, 429, 443, 457, 639, 653, 668, 724, 738, 752, [27, 30, 31, 32, 0], 0],
  ['Temple Guard (act3)', 0, 3, 24, 53, 81, 392, 406, 420, 644, 658, 673, 729, 743, 757, [76, 0], 0],
  ['Temple Guard (act5)', 0, 5, 0, 53, 81, 392, 406, 420, 644, 658, 673, 729, 743, 757, [115, 0], 0],
  ['The Banished', 0, 1, 12, 43, 73, 427, 441, 455, 637, 651, 666, 722, 736, 750, [33, 0], 0],
  ['The Countess', 1, -1, 11, 42, 72, 958, 959, 960, 958, 959, 960, 958, 959, 960, [22, 0], 0],
  ['The Cow King', 1, -1, 31, 64, 84, 941, 942, 943, 941, 942, 943, 941, 942, 943, [36, 0], 0],
  ['The Smith', 1, -1, 13, 45, 82, 845, 846, 847, 845, 846, 847, 845, 846, 847, [25, 0], 0],
  ['The Summoner', 1, -1, 21, 58, 83, 815, 816, 817, 815, 816, 817, 815, 816, 817, [69, 0], 1],
  ['Thorn Beast', 0, 1, 8, 39, 69, 766, 771, 776, 638, 652, 667, 723, 737, 751, [5, 0], 0],
  ['Thorned Hulk (act3)', 0, 3, 21, 49, 79, 391, 405, 419, 643, 657, 672, 728, 742, 756, [70, 0], 0],
  ['Thorned Hulk (act5)', 0, 5, 0, 58, 80, 396, 410, 424, 648, 663, 677, 733, 747, 761, [102, 0], 0],
  ['Thrasher', 0, 3, 23, 52, 81, 392, 406, 420, 644, 658, 673, 729, 743, 757, [72, 74, 75, 0], 0],
  ['Thresh Socket', 1, -1, 41, 63, 84, 910, 925, 940, 910, 925, 940, 910, 925, 940, [104, 0], 0],
  ['Tomb Creeper (act2)', 0, 2, 17, 46, 77, 389, 403, 417, 641, 655, 670, 726, 740, 754, [40, 0], 0],
  ['Tomb Creeper (act5)', 0, 5, 0, 61, 82, 396, 410, 424, 648, 663, 677, 733, 747, 761, [114, 0], 0],
  ['Toorc Icefist', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [77, 0], 0],
  ['Tree Lurker', 0, 3, 22, 52, 80, 391, 405, 419, 643, 657, 672, 728, 742, 756, [73, 0], 0],
  ['Treehead WoodFist', 1, -1, 8, 41, 72, 852, 867, 886, 852, 867, 882, 852, 867, 882, [3, 0], 0],
  ['Undead Scavenger', 0, 2, 15, 44, 76, 779, 781, 783, 640, 654, 669, 725, 739, 753, [38, 39, 0], 0],
  ['Undead Soul Killer (act3)', 0, 3, 23, 52, 82, 392, 406, 420, 644, 658, 673, 729, 743, 757, [80, 81, 84, 86, 87, 0], 0],
  ['Undead Soul Killer (act5)', 0, 5, 0, 53, 83, 392, 406, 420, 644, 658, 673, 729, 743, 757, [123, 0], 0],
  ['Undead Stygian Doll', 0, 3, 24, 53, 83, 392, 406, 420, 644, 658, 673, 729, 743, 757, [80, 81, 84, 94, 95, 0], 0],
  ['Unholy Corpse', 0, 5, 42, 65, 85, 398, 412, 426, 650, 665, 679, 735, 749, 763, [119, 120, 0], 0],
  ['Unraveler', 0, 2, 20, 49, 80, 432, 446, 460, 642, 656, 671, 727, 741, 755, [61, 0], 0],
  ['Urdar', 0, 4, 32, 57, 85, 394, 408, 422, 646, 660, 675, 731, 745, 759, [100, 0], 0],
  ['Venom Lord (act4)', 0, 4, 30, 57, 84, 395, 409, 423, 647, 661, 676, 732, 746, 760, [97, 98, 100, 101, 0], 0],
  ['Venom Lord (act5)', 0, 5, 0, 68, 93, 397, 411, 424, 649, 663, 678, 734, 748, 762, [123, 0], 0],
  ['Ventar the Unholy', 1, -1, 43, 71, 96, 650, 670, 679, 650, 665, 679, 650, 665, 679, [123, 0], 0],
  ['Vile Hunter', 0, 1, 5, 38, 68, 386, 400, 414, 638, 652, 667, 723, 737, 751, [3, 8, 12, 0], 0],
  ['Vile Temptress', 0, 5, 34, 61, 82, 397, 411, 425, 649, 663, 677, 734, 747, 761, [108, 0], 0],
  ['Vile Witch', 0, 5, 34, 61, 82, 438, 452, 466, 648, 663, 677, 733, 747, 761, [120, 0], 0],
  ['Wailing Beast', 0, 3, 23, 53, 84, 392, 406, 420, 644, 658, 673, 729, 743, 757, [88, 89, 90, 0], 0],
  ['Winged Nightmare', 0, 3, 23, 50, 81, 780, 782, 784, 644, 658, 673, 729, 743, 757, [72, 75, 0], 0],
  ['Witch Doctor Endugu', 1, -1, 27, 54, 85, 858, 875, 891, 858, 873, 888, 858, 873, 888, [85, 0], 0],
  ['Wretched Defiler', 0, 5, 37, 62, 81, 438, 452, 466, 648, 663, 677, 733, 747, 761, [116, 0], 1],
  ['Wyand Voidbringer', 1, -1, 28, 59, 91, 818, 819, 820, 818, 819, 820, 818, 819, 820, [77, 0], 0],
  ['Yeti', 0, 1, 9, 40, 70, 387, 401, 415, 639, 653, 668, 724, 738, 752, [24, 0], 0],
  ['Zealot (act3)', 0, 3, 24, 53, 82, 392, 406, 420, 644, 658, 673, 729, 743, 757, [75, 76, 77, 0], 0],
  ['Zealot (act5)', 0, 5, 0, 53, 82, 392, 406, 420, 644, 658, 673, 729, 743, 757, [115, 120, 0], 0],
  ['Zombie', 0, 1, 1, 36, 67, 385, 399, 413, 637, 651, 666, 722, 736, 750, [0, 6, 0], 0],
];

import { IAreaLevel } from './areas_114d';

export const AREA_LEVEL_DATA_24: IAreaLevel[] = [
  {
    name: 'Blood Moor',
    act: 1,
    normal_alvl: 1,
    nightmare_alvl: 36,
    hell_alvl: 67,
  },
  {
    name: 'Cold Plains',
    act: 1,
    normal_alvl: 2,
    nightmare_alvl: 36,
    hell_alvl: 68,
  },
  {
    name: 'Stony Field',
    act: 1,
    normal_alvl: 4,
    nightmare_alvl: 37,
    hell_alvl: 68,
  },
  {
    name: 'Dark Wood',
    act: 1,
    normal_alvl: 5,
    nightmare_alvl: 38,
    hell_alvl: 68,
  },
  {
    name: 'Black Marsh',
    act: 1,
    normal_alvl: 6,
    nightmare_alvl: 38,
    hell_alvl: 69,
  },
  {
    name: 'Tamoe Highland',
    act: 1,
    normal_alvl: 8,
    nightmare_alvl: 39,
    hell_alvl: 69,
  },
  {
    name: 'Den of Evil',
    act: 1,
    normal_alvl: 1,
    nightmare_alvl: 36,
    hell_alvl: 79,
  },
  {
    name: 'The Cave Level 1',
    act: 1,
    normal_alvl: 2,
    nightmare_alvl: 36,
    hell_alvl: 77,
  },
  {
    name: 'Underground Passage Level 1',
    act: 1,
    normal_alvl: 4,
    nightmare_alvl: 37,
    hell_alvl: 69,
  },
  {
    name: 'The Hole Level 1',
    act: 1,
    normal_alvl: 5,
    nightmare_alvl: 38,
    hell_alvl: 80,
  },
  {
    name: 'The Pit Level 1',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 39,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'F'],
  },
  {
    name: 'The Cave Level 2',
    act: 1,
    normal_alvl: 2,
    nightmare_alvl: 37,
    hell_alvl: 78,
  },
  {
    name: 'Underground Passage Level 2',
    act: 1,
    normal_alvl: 4,
    nightmare_alvl: 38,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'F'],
  },
  {
    name: 'The Hole Level 2',
    act: 1,
    normal_alvl: 5,
    nightmare_alvl: 39,
    hell_alvl: 81,
  },
  {
    name: 'The Pit Level 2',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 40,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'F'],
  },
  {
    name: 'Burial Grounds',
    act: 1,
    normal_alvl: 3,
    nightmare_alvl: 36,
    hell_alvl: 80,
  },
  {
    name: 'Crypt',
    act: 1,
    normal_alvl: 3,
    nightmare_alvl: 37,
    hell_alvl: 83,
  },
  {
    name: 'Mausoleum',
    act: 1,
    normal_alvl: 3,
    nightmare_alvl: 37,
    hell_alvl: 85,
    immunities: ['L', 'P', 'F'],
  },
  {
    name: 'Tower Cellar Level 1',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 38,
    hell_alvl: 75,
  },
  {
    name: 'Tower Cellar Level 2',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 39,
    hell_alvl: 76,
  },
  {
    name: 'Tower Cellar Level 3',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 40,
    hell_alvl: 77,
  },
  {
    name: 'Tower Cellar Level 4',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 41,
    hell_alvl: 78,
  },
  {
    name: 'Tower Cellar Level 5',
    act: 1,
    normal_alvl: 7,
    nightmare_alvl: 42,
    hell_alvl: 79,
  },
  {
    name: 'Monastery Gate',
    act: 1,
    normal_alvl: 8,
    nightmare_alvl: 40,
    hell_alvl: 70,
  },
  {
    name: 'Outer Cloister',
    act: 1,
    normal_alvl: 10,
    nightmare_alvl: 41,
    hell_alvl: 72,
  },
  {
    name: 'Barracks',
    act: 1,
    normal_alvl: 9,
    nightmare_alvl: 40,
    hell_alvl: 70,
  },
  {
    name: 'Jail Levels 1, 2, 3',
    act: 1,
    normal_alvl: 10,
    nightmare_alvl: 41,
    hell_alvl: 71,
  },
  {
    name: 'Inner Cloister',
    act: 1,
    normal_alvl: 10,
    nightmare_alvl: 41,
    hell_alvl: 72,
  },
  {
    name: 'Cathedral',
    act: 1,
    normal_alvl: 11,
    nightmare_alvl: 42,
    hell_alvl: 72,
  },
  {
    name: 'Catacombes Level 1',
    act: 1,
    normal_alvl: 11,
    nightmare_alvl: 42,
    hell_alvl: 72,
  },
  {
    name: 'Catacombes Level 2',
    act: 1,
    normal_alvl: 11,
    nightmare_alvl: 42,
    hell_alvl: 73,
  },
  {
    name: 'Catacombs Levels 3, 4',
    act: 1,
    normal_alvl: 12,
    nightmare_alvl: 43,
    hell_alvl: 74,
  },
  {
    name: 'Tristram',
    act: 1,
    normal_alvl: 6,
    nightmare_alvl: 39,
    hell_alvl: 76,
  },
  {
    name: 'Secret Cow Level',
    act: 1,
    normal_alvl: 28,
    nightmare_alvl: 64,
    hell_alvl: 81,
  },
  {
    name: 'Rocky Waste',
    act: 2,
    normal_alvl: 14,
    nightmare_alvl: 43,
    hell_alvl: 75,
  },
  {
    name: 'Dry Hills',
    act: 2,
    normal_alvl: 15,
    nightmare_alvl: 44,
    hell_alvl: 76,
  },
  {
    name: 'Far Oasis',
    act: 2,
    normal_alvl: 16,
    nightmare_alvl: 45,
    hell_alvl: 76,
  },
  {
    name: 'Lost City',
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 46,
    hell_alvl: 77,
  },
  {
    name: 'Valley of Snakes',
    act: 2,
    normal_alvl: 18,
    nightmare_alvl: 46,
    hell_alvl: 77,
  },
  {
    name: 'Canyon of the Magi',
    act: 2,
    normal_alvl: 16,
    nightmare_alvl: 48,
    hell_alvl: 79,
  },
  {
    name: 'Sewers (Act 2) Levels 1, 2',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 43,
    hell_alvl: 74,
  },
  {
    name: 'Sewers (Act 2) Level 3',
    act: 2,
    normal_alvl: 14,
    nightmare_alvl: 44,
    hell_alvl: 75,
  },
  {
    name: 'Harem Level 2',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 47,
    hell_alvl: 78,
  },
  {
    name: 'Palace Cellars Levels 1, 2',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 47,
    hell_alvl: 78,
  },
  {
    name: 'Palace Cellars Level 3',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 48,
    hell_alvl: 78,
  },
  {
    name: 'Stony Tomb Level 1',
    act: 2,
    normal_alvl: 12,
    nightmare_alvl: 44,
    hell_alvl: 85,
    immunities: ['L', 'P'],
  },
  {
    name: 'Stony Tomb Level 2',
    act: 2,
    normal_alvl: 12,
    nightmare_alvl: 44,
    hell_alvl: 85,
    immunities: ['L', 'P'],
  },
  {
    name: 'Halls of the Dead Level 1',
    act: 2,
    normal_alvl: 12,
    nightmare_alvl: 44,
    hell_alvl: 79,
  },
  {
    name: 'Halls of the Dead Level 2',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 45,
    hell_alvl: 81,
  },
  {
    name: 'Halls of the Dead Level 3',
    act: 2,
    normal_alvl: 13,
    nightmare_alvl: 45,
    hell_alvl: 82,
  },
  {
    name: 'Claw Viper Temple Level 1',
    act: 2,
    normal_alvl: 14,
    nightmare_alvl: 47,
    hell_alvl: 82,
  },
  {
    name: 'Claw Viper Temple Level 2',
    act: 2,
    normal_alvl: 14,
    nightmare_alvl: 47,
    hell_alvl: 83,
  },
  {
    name: 'Maggot Lair Levels 1, 2',
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 45,
    hell_alvl: 84,
  },
  {
    name: 'Maggot Lair Level 3',
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 46,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'Ph'],
  },
  {
    name: 'Ancient Tunnels',
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 46,
    hell_alvl: 85,
    immunities: ['L', 'P', 'F', 'M'],
  },
  {
    name: "Tal Rasha's Tomb",
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 49,
    hell_alvl: 80,
  },
  {
    name: "Tal Rasha's Chamber",
    act: 2,
    normal_alvl: 17,
    nightmare_alvl: 49,
    hell_alvl: 80,
  },
  {
    name: 'Arcane Sanctuary',
    act: 2,
    normal_alvl: 14,
    nightmare_alvl: 48,
    hell_alvl: 79,
  },
  {
    name: 'Spider Forest',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 49,
    hell_alvl: 79,
  },
  {
    name: 'Great Marsh',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 50,
    hell_alvl: 80,
  },
  {
    name: 'Flayer Jungle',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 50,
    hell_alvl: 80,
  },
  {
    name: 'Lower Kurast',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 52,
    hell_alvl: 80,
  },
  {
    name: 'Kurast Bazzar',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 52,
    hell_alvl: 81,
  },
  {
    name: 'Upper Kurast',
    act: 3,
    normal_alvl: 23,
    nightmare_alvl: 52,
    hell_alvl: 81,
  },
  {
    name: 'Kurast Causeway',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 53,
    hell_alvl: 81,
  },
  {
    name: 'Travincal',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 54,
    hell_alvl: 82,
  },
  {
    name: 'Arachnid Lair',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 50,
    hell_alvl: 85,
    immunities: ['P', 'F'],
  },
  {
    name: 'Spider Cavern',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 50,
    hell_alvl: 79,
  },
  {
    name: 'Swampy Pit Level 1',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 51,
    hell_alvl: 85,
    immunities: ['L', 'P', 'F'],
  },
  {
    name: 'Swampy Pit Level 2',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 51,
    hell_alvl: 85,
    immunities: ['L', 'P', 'F'],
  },
  {
    name: 'Swampy Pit Level 3',
    act: 3,
    normal_alvl: 21,
    nightmare_alvl: 51,
    hell_alvl: 85,
    immunities: ['L', 'P', 'F'],
  },
  {
    name: 'Flayer Dungeon Level 1',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 51,
    hell_alvl: 81,
  },
  {
    name: 'Flayer Dungeon Level 2',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 51,
    hell_alvl: 82,
  },
  {
    name: 'Flayer Dungeon Level 3',
    act: 3,
    normal_alvl: 22,
    nightmare_alvl: 51,
    hell_alvl: 83,
  },
  {
    name: 'Kurast Sewers Level 1',
    act: 3,
    normal_alvl: 23,
    nightmare_alvl: 52,
    hell_alvl: 85,
    immunities: ['C', 'P', 'F', 'M'],
  },
  {
    name: 'Kurast Sewers Level 2',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 53,
    hell_alvl: 85,
    immunities: ['P', 'M'],
  },
  {
    name: 'Ruined Temple',
    act: 3,
    normal_alvl: 23,
    nightmare_alvl: 53,
    hell_alvl: 85,
    immunities: ['C', 'F'],
  },
  {
    name: 'Disused Fane',
    act: 3,
    normal_alvl: 23,
    nightmare_alvl: 53,
    hell_alvl: 85,
    immunities: ['C', 'F'],
  },
  {
    name: 'Forgotten Reliquary',
    act: 3,
    normal_alvl: 23,
    nightmare_alvl: 53,
    hell_alvl: 85,
    immunities: ['C', 'F'],
  },
  {
    name: 'Forgotten Temple',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 54,
    hell_alvl: 85,
    immunities: ['L', 'C', 'F'],
  },
  {
    name: 'Ruined Fane',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 54,
    hell_alvl: 85,
    immunities: ['L', 'C', 'F'],
  },
  {
    name: 'Disused Reliquary',
    act: 3,
    normal_alvl: 24,
    nightmare_alvl: 54,
    hell_alvl: 85,
    immunities: ['L', 'C', 'F'],
  },
  {
    name: 'Durance of Hate Levels 1, 2, 3',
    act: 3,
    normal_alvl: 25,
    nightmare_alvl: 55,
    hell_alvl: 83,
  },
  {
    name: 'Outer Steppes',
    act: 4,
    normal_alvl: 26,
    nightmare_alvl: 56,
    hell_alvl: 82,
  },
  {
    name: 'Plains of Despair',
    act: 4,
    normal_alvl: 26,
    nightmare_alvl: 56,
    hell_alvl: 83,
  },
  {
    name: 'City of the Damned',
    act: 4,
    normal_alvl: 27,
    nightmare_alvl: 57,
    hell_alvl: 83,
  },
  {
    name: 'River of Flame',
    act: 4,
    normal_alvl: 27,
    nightmare_alvl: 57,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'F'],
  },
  {
    name: 'Chaos Sanctuary',
    act: 4,
    normal_alvl: 28,
    nightmare_alvl: 58,
    hell_alvl: 85,
    immunities: ['L', 'P', 'C', 'F'],
  },
  {
    name: 'Frigid Highlands',
    act: 5,
    normal_alvl: 25,
    nightmare_alvl: 59,
    hell_alvl: 81,
  },
  {
    name: 'Arreat Plateau',
    act: 5,
    normal_alvl: 26,
    nightmare_alvl: 60,
    hell_alvl: 81,
  },
  {
    name: 'Crystalline Passage',
    act: 5,
    normal_alvl: 29,
    nightmare_alvl: 61,
    hell_alvl: 82,
  },
  {
    name: 'Frozen River',
    act: 5,
    normal_alvl: 29,
    nightmare_alvl: 61,
    hell_alvl: 83,
  },
  {
    name: 'Glacial Trail',
    act: 5,
    normal_alvl: 29,
    nightmare_alvl: 61,
    hell_alvl: 83,
  },
  {
    name: 'Drifter Cavern',
    act: 5,
    normal_alvl: 29,
    nightmare_alvl: 61,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F'],
  },
  {
    name: 'Frozen Tundra',
    act: 5,
    normal_alvl: 27,
    nightmare_alvl: 60,
    hell_alvl: 81,
  },
  {
    name: "Ancient's Way",
    act: 5,
    normal_alvl: 29,
    nightmare_alvl: 62,
    hell_alvl: 81,
  },
  {
    name: 'Arreat Summit',
    act: 5,
    normal_alvl: 26,
    nightmare_alvl: 60,
    hell_alvl: 81,
  },
  {
    name: 'Icy Cellar',
    act: 5,
    normal_alvl: 26,
    nightmare_alvl: 60,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F'],
  },
  {
    name: 'Nihlathaks Temple',
    act: 5,
    normal_alvl: 32,
    nightmare_alvl: 63,
    hell_alvl: 83,
  },
  {
    name: 'Halls of Anguish',
    act: 5,
    normal_alvl: 33,
    nightmare_alvl: 63,
    hell_alvl: 83,
  },
  {
    name: 'Halls of Pain',
    act: 5,
    normal_alvl: 34,
    nightmare_alvl: 64,
    hell_alvl: 84,
  },
  {
    name: 'Halls of Vaught',
    act: 5,
    normal_alvl: 36,
    nightmare_alvl: 64,
    hell_alvl: 84,
  },
  {
    name: 'Abaddon',
    act: 5,
    normal_alvl: 39,
    nightmare_alvl: 60,
    hell_alvl: 85,
    immunities: ['F', 'C', 'L', 'P'],
  },
  {
    name: 'Pit of Acheron',
    act: 5,
    normal_alvl: 39,
    nightmare_alvl: 61,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F', 'M'],
  },
  {
    name: 'Infernal Pit',
    act: 5,
    normal_alvl: 39,
    nightmare_alvl: 62,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F'],
  },
  {
    name: 'Worldstone Keep Level 1',
    act: 5,
    normal_alvl: 39,
    nightmare_alvl: 65,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F', 'Ph'],
  },
  {
    name: 'Worldstone Keep Level 2',
    act: 5,
    normal_alvl: 40,
    nightmare_alvl: 65,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F', 'M'],
  },
  {
    name: 'Worldstone Keep Level 3',
    act: 5,
    normal_alvl: 42,
    nightmare_alvl: 66,
    hell_alvl: 85,
    immunities: ['L', 'C', 'P', 'F', 'Ph'],
  },
  {
    name: 'Throne of Destruction',
    act: 5,
    normal_alvl: 43,
    nightmare_alvl: 66,
    hell_alvl: 85,
    immunities: ['P', 'F', 'Ph', 'M'],
  },
  {
    name: 'Worldstone Chamber',
    act: 5,
    normal_alvl: 43,
    nightmare_alvl: 66,
    hell_alvl: 85,
    immunities: [],
  },
];

import React, { useRef } from 'react';
import { IonModal, useIonRouter } from '@ionic/react';
import { SectionWrapper } from '../StyledComponents';
import { QuickSettings } from '../../../pages/home/components/QuickSettings';
import styled from 'styled-components/macro';
import { appRoutes } from '../../../AppRoutes';

export const SettingsModal: React.FC = () => {
  const self = useRef<HTMLIonModalElement>(null);
  const router = useIonRouter();
  const handleClick = () => {
    if (self.current) {
      self.current.dismiss();
      router.push(appRoutes.settings.path());
    }
  };

  return (
    <IonModal ref={self} trigger={'settings-modal'} initialBreakpoint={1} breakpoints={[0, 1]} className={'auto-height'}>
      <Container>
        <SectionWrapper label={'Quick Settings'} style={{ borderColor: '#9d9fa6' }}>
          <QuickSettings />
        </SectionWrapper>
        <More onClick={handleClick}>View all settings</More>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
  margin-left: auto;
  width: auto;

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/chevron.svg');
    background-size: cover;
    transform: rotate(180deg);
    filter: brightness(100%) invert(1);
  }
`;

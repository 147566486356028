import { StorageRune } from '../../common/providers/RuneProvider/RuneProvider';
import { Runeword } from '../../common/types';

export const findRunewords = (runewords: Runeword[], runes: StorageRune[]) => {
  const canMake = [];
  const canAlmostMake = [];
  const runeCount = new Array(30);

  runes.forEach(r => (runeCount[r.rune_id] = r.count));

  for (const rw of runewords) {
    let matches = 0;

    // Manually check runewords that use duplicate runes
    if (rw.id === 38) {
      // infinity (ber x2)
      if (runeCount[29] === 1) {
        matches++;
      }
      if (runeCount[29] > 1) {
        matches += 2;
      }
      if (runeCount[22] > 0) {
        matches++;
      }
      if (runeCount[23] > 0) {
        matches++;
      }
    } else if (rw.id === 42) {
      // Last wish (Jah x3)
      if (runeCount[30] === 1) {
        matches++;
      }
      if (runeCount[30] === 2) {
        matches += 2;
      }
      if (runeCount[30] > 2) {
        matches += 3;
      }
      if (runeCount[22] > 0) {
        matches++;
      }
      if (runeCount[29] > 0) {
        matches++;
      }
      if (runeCount[29] > 0) {
        matches++;
      }
    } else if (rw.id === 56 || rw.id === 57) {
      // Pheonix (Vex x2)
      if (runeCount[25] === 1) {
        matches++;
      }
      if (runeCount[25] > 1) {
        matches += 2;
      }
      if (runeCount[27] > 0) {
        matches++;
      }
      if (runeCount[30] > 0) {
        matches++;
      }
    } else if (rw.id === 65) {
      // Sanctuary (Ko x2)
      if (runeCount[17] === 1) {
        matches++;
      }
      if (runeCount[17] > 1) {
        matches += 2;
      }
      if (runeCount[22] > 0) {
        matches++;
      }
    } else if (rw.id === 3) {
      // Bone (Um x2)
      if (runeCount[11] > 0) {
        matches++;
      }
      if (runeCount[21] === 1) {
        matches++;
      }
      if (runeCount[21] > 1) {
        matches++;
      }

      // Search for matches for remaining runewords
    } else {
      for (const req of rw.runeOrderInt) {
        if (runeCount[req] > 0) {
          matches++;
          if (rw.runeOrderInt.length === matches) {
            break;
          }
        }
      }
    }

    if (rw.runeOrderInt.length === matches) {
      canMake.push(rw);
    } else if (rw.runeOrderInt.length - 1 === matches) {
      canAlmostMake.push(rw);
    }
  }

  canMake.sort((a, b) => a.cLvlReq - b.cLvlReq);
  canAlmostMake.sort((a, b) => a.cLvlReq - b.cLvlReq);
  return [canMake, canAlmostMake];
};

import React, { useMemo, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonItem, IonLabel, IonList, IonListHeader, IonSearchbar } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorArmorBases, selectorWeaponBases } from '../../store/activeData/selector';
import { ViewBase } from '../../common/components/ViewBase/ViewBase';

export const BaseCatalog: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const weaponBases = useAppSelector(selectorWeaponBases);
  const armorBases = useAppSelector(selectorArmorBases);

  const searchResults = useMemo(() => {
    if (!searchTerm) return undefined;

    const weaponResultIds: number[] = [];
    const armorResultIds: number[] = [];

    for (const item of weaponBases) {
      if (item.name.toLowerCase().includes(searchTerm)) {
        weaponResultIds.push(item.id);
      }
    }

    for (const item of armorBases) {
      if (item.name.toLowerCase().includes(searchTerm)) {
        armorResultIds.push(item.id);
      }
    }
    return { weaponResultIds, armorResultIds };
  }, [searchTerm]);

  return (
    <AppPage
      padding={true}
      navBar={true}
      title={'Base Items'}
      headerContent={
        <IonSearchbar
          animated={true}
          placeholder={'Search name...'}
          mode={'ios'}
          color={'medium'}
          debounce={200}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSearchTerm(e.detail?.value.toLowerCase() ?? ('' as string));
          }}
        />
      }
    >
      {!searchTerm && (
        <>
          <IonList lines={'none'} inset>
            <IonListHeader className={'base no-inset'}>Class Specific</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Amazon Bows')}>
              <IonLabel>Amazon Bows</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Amazon Javelins')}>
              <IonLabel>Amazon Javelins</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Amazon Spears')}>
              <IonLabel>Amazon Spears</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Assassin Katars')}>
              <IonLabel>Assassin Katars</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Barbarian Helms')}>
              <IonLabel>Barbarian Helms</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Druid Pelts')}>
              <IonLabel>Druid Pelts</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Paladin Shields')}>
              <IonLabel>Paladin Shields</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Necromancer Shrunken Heads')}>
              <IonLabel>Necromancer Shrunken Heads</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Sorceress Orbs')}>
              <IonLabel>Sorceress Orbs</IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'base no-inset'}>Weapons</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Axes')}>
              <IonLabel>Axes</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Bows')}>
              <IonLabel>Bows</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Crossbows')}>
              <IonLabel>Crossbows</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Daggers')}>
              <IonLabel>Daggers</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Javelins')}>
              <IonLabel>Javelins</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Maces')}>
              <IonLabel>Maces</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Hammers')}>
              <IonLabel>Hammers</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Polearms')}>
              <IonLabel>Polearms</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Scepters')}>
              <IonLabel>Scepters</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Spears')}>
              <IonLabel>Spears</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Staves')}>
              <IonLabel>Staves</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Swords')}>
              <IonLabel>Swords</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Throwing')}>
              <IonLabel>Throwing</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Weapon', 'Wands')}>
              <IonLabel>Wands</IonLabel>
            </IonItem>
          </IonList>

          <IonList lines={'none'} inset>
            <IonListHeader className={'base no-inset'}>Armors</IonListHeader>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Helms')}>
              <IonLabel>Helms</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Armors')}>
              <IonLabel>Armors</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Shields')}>
              <IonLabel>Shields</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Gloves')}>
              <IonLabel>Gloves</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Boots')}>
              <IonLabel>Boots</IonLabel>
            </IonItem>
            <IonItem routerLink={appRoutes.catalog.children.view.path(-1, 'Base Armor', 'Belts')}>
              <IonLabel>Belts</IonLabel>
            </IonItem>
          </IonList>
        </>
      )}

      {searchTerm && searchResults && (
        <>
          {searchResults.weaponResultIds.map(w => (
            <ViewBase key={`weapon-${w}`} weaponId={w} />
          ))}
          {searchResults.armorResultIds.map(a => (
            <ViewBase key={`armor-base-${a}`} armorId={a} />
          ))}
        </>
      )}
    </AppPage>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonFab, IonFabButton, IonFabList, IonIcon, IonItem, IonSearchbar, useIonRouter } from '@ionic/react';
import { add } from 'ionicons/icons';
import { MuleContext } from '../../common/providers/MuleProvider/MuleProvider';
import styled from 'styled-components/macro';
import { ItemContext, StorageItem } from '../../common/providers/ItemProvider/ItemProvider';
import { ViewStorageItem } from '../../common/components/ViewStorageItem';
import { appRoutes } from '../../AppRoutes';
import { MuleOverview } from './components/MuleOverview';

export const Mules: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<StorageItem[]>([]);
  const { mules, addMule } = useContext(MuleContext);
  const { openAddItem, searchItems } = useContext(ItemContext);
  const router = useIonRouter();

  useEffect(() => {
    search();
  }, [searchTerm]);

  const search = () => {
    if (searchTerm.trim()) searchItems(searchTerm.trim()).then(setSearchResults);
    else setSearchResults([]);
  };

  return (
    <AppPage
      title={'Mules'}
      padding={false}
      headerContent={
        <IonSearchbar
          animated={true}
          placeholder={'Search items...'}
          mode={'ios'}
          color={'medium'}
          debounce={200}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSearchTerm(e.detail?.value.toLowerCase() ?? ('' as string));
          }}
        />
      }
    >
      <Container>
        {!searchTerm && (
          <>
            {mules.map(m => (
              <IonItem
                key={`mule-${m.mule_id}`}
                onClick={() => router.push(appRoutes.mules.children.detail.path(m.mule_id))}
                lines={'none'}
              >
                <MuleOverview mule={m} />
              </IonItem>
            ))}
          </>
        )}

        {!!searchTerm && (
          <>
            {searchResults.map(item => (
              <ViewStorageItem key={`search-result-${item.id}`} item={item} onDelete={search} />
            ))}
          </>
        )}
        <IonFab slot={'fixed'} horizontal={'end'} vertical={'bottom'}>
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side={'top'}>
            <IonFabButton>
              <AddMuleIcon onClick={() => addMule()} />
            </IonFabButton>
            <IonFabButton>
              <AddItemIcon onClick={() => openAddItem()} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 6px;
`;

const AddIcon = styled.div`
  width: 100%;
  height: 100%;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--ion-color-primary);
  position: relative;

  &:after {
    content: '+';
    position: absolute;
    right: 5%;
    bottom: 5%;
    font-size: 20px;
    font-weight: 800;
    color: white;
  }
`;

const AddMuleIcon = styled(AddIcon)`
  background-image: url('/images/icons/mule.svg');
  transform: scaleX(-1);

  &:after {
    left: 5%;
    right: unset;
  }
`;

export const AddItemIcon = styled(AddIcon)`
  background-image: url('/images/icons/item.svg');
  background-position: center 70%;
`;

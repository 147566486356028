import React, { useEffect, useRef, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { IonLabel, IonSearchbar, IonSegment, IonSegmentButton, useIonViewWillLeave } from '@ionic/react';
import { DropCalcSettings } from './components/DropCalcSettings';
import { dropCalcBaseItems, dropCalcSetItems, dropCalcUniques } from '../../common/data/drop-calc/Items';
import { useAppSelector } from '../../store/store';
import { selectorDropCalcZones } from '../../store/activeData/selector';
import { calculateAllItemChancesByForm, calculateMonsterDrops, initMonsters } from './calculator';
import { Item } from '../../common/types';
import { selectorHomeSettings } from '../../store/settings/selectors';
import { DropCalcResultsTable } from './components/DropCalcResultsTable';
import { LocalStorage } from '../../common/Storage';

export const monsterTypes = [
  { name: 'Normal', value: 0 },
  { name: 'Champion', value: 1 },
  { name: 'Minion', value: 3 }, // INTENTIONAL !
  { name: 'Unique', value: 2 },
  { name: 'Super Unique', value: 4 },
  // { name: 'Chest', value: 5 },
  // { name: 'TreasureClass', value: 6 },
];

export interface DataItem {
  name: string;
  value: number;
}

export type Result = [string, number, string, string | undefined];

const allActs: DataItem[] = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  {
    name: '4',
    value: 4,
  },
  { name: '5', value: 5 },
];

export interface DropCalcData {
  mf: number;
  playersAll: number;
  playersParty: number;
  itemType: number; // 0 = base, 1 = unique, 2 = set
  selectedQuality: number;
  itemListIndex: number; // index of item from dropcalc-specific data
  allItemsIndex: number;
  monstertype: number;
  act: number;
  difficulty: number;
  selectedMonster: number;
  selectedMonsterIndex: number;
  selectedLevel: number;
  showJunk: boolean;
  sort: 'alpha' | 'proba';
}

const initialData: DropCalcData = {
  mf: 0,
  playersAll: 1,
  playersParty: 1,
  itemType: 1,
  selectedQuality: 3,
  itemListIndex: 0,
  allItemsIndex: 0,
  monstertype: 2,
  act: 1,
  difficulty: 2,
  selectedMonster: 7,
  selectedMonsterIndex: 7,
  selectedLevel: 61,
  showJunk: false,
  sort: 'proba',
};

// interface DropCalcStorageResults {
//   item:
// }

export const DropCalc: React.FC = () => {
  const [data, setData] = useState(LocalStorage.getSavedDropCalcData()?.data ?? initialData);
  const [segment, setSegment] = useState('item');
  const [itemList, setItemList] = useState<Item[]>([]);

  // -- Monster vars -- //
  const [acts, setActs] = useState(allActs);
  const [monsters, setMonsters] = useState<DataItem[]>([]);
  const [levelList, setLevelList] = useState<DataItem[]>([]);
  const lastdifficulty = useRef(999);
  const lastact = useRef(999);
  const lastmonstertype = useRef(999);

  const [results, setResults] = useState<Result[]>([]);
  const [resultsSearch, setResultsSearch] = useState('');

  const zones = useAppSelector(selectorDropCalcZones);

  const homeSettings = useAppSelector(selectorHomeSettings).dropCalc;

  useEffect(() => {
    if (segment === 'item' && itemList.length) {
      calcItemChances();
    }
    if (segment === 'monster') {
      console.log('calc monster');
      const res = calculateMonsterDrops(0, 0, data, zones);
      setResults((res ?? []) as Result[]);
    }
    console.log(data);
  }, [data]);

  useIonViewWillLeave(() => {
    console.log('view will leave');
    const filteredResults = results
      ?.filter(r => r[0].toLowerCase().includes(resultsSearch))
      .sort((a, b) => {
        if (data.sort === 'alpha') return a[0].localeCompare(b[0]);
        else return b[1] - a[1];
      });

    const saveResults: Result[] = [];
    for (let i = 0; i < homeSettings.results; i++) {
      if (filteredResults[i]) {
        saveResults.push(filteredResults[i]);
      }
    }
    LocalStorage.setSavedDropCalcData({
      data,
      segment,
      results: saveResults,
      monsterName: monsters.at(data.selectedMonsterIndex)?.name,
    });
  }, [results, data, segment, homeSettings, resultsSearch]);

  function calcItemChances() {
    let allItemsIndex = 0;
    switch (data.itemType) {
      case 0:
        for (let i = 0; i < dropCalcBaseItems.length; i++) {
          if (dropCalcBaseItems[i][0] === itemList[data.itemListIndex].name) {
            allItemsIndex = i;
            break;
          }
        }
        break;
      case 1:
        for (let i = 0; i < dropCalcUniques.length; i++) {
          if (dropCalcUniques[i][0] === itemList[data.itemListIndex].name) {
            allItemsIndex = i;
            break;
          }
        }
        break;
      case 2:
        for (let i = 0; i < dropCalcSetItems.length; i++) {
          if (dropCalcSetItems[i][0] === itemList[data.itemListIndex].name) {
            allItemsIndex = i;
            break;
          }
        }
    }
    if (itemList.length) {
      const results = calculateAllItemChancesByForm({ ...data, allItemsIndex }, itemList, zones);
      setResults(results as Result[]);
    }
  }

  useEffect(() => {
    if (segment === 'monster') {
      doInitMonsters();
    }
  }, [segment, data.monstertype, data.act, data.difficulty, data.selectedMonster]);

  const doInitMonsters = () => {
    const res = initMonsters(data, lastdifficulty.current, lastact.current, lastmonstertype.current, zones, setData, setActs);
    lastmonstertype.current = res.lastmonstertype;
    lastact.current = res.lastact;
    lastdifficulty.current = res.lastdifficulty;
    if (res.monsterlist.length) {
      setMonsters(res.monsterlist);
    }
    setLevelList(res.levellist);
    console.log(res.levellist);
  };

  return (
    <AppPage title={'Drop Calculator'} padding={true}>
      <Container>
        <IonSegment value={segment} onIonChange={e => setSegment(e.detail.value as string)} mode={'md'}>
          <IonSegmentButton value={'monster'}>
            <IonLabel color={'primary'}>Monster</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={'item'}>
            <IonLabel color={'primary'}>Item</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <DropCalcSettings
          data={data}
          segment={segment}
          onChange={setData}
          onItemListChange={setItemList}
          acts={acts}
          monsters={monsters}
          levelList={levelList}
        />
      </Container>
      <div>
        <IonSearchbar
          animated={true}
          placeholder={'Search name...'}
          mode={'ios'}
          color={'medium'}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setResultsSearch(e.target?.value.toLowerCase() as string);
          }}
        />
      </div>

      <DropCalcResultsTable results={results} resultsSearch={resultsSearch} data={data} />
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  //margin-top: 30px;
`;

import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './App.scss'; /* Theme variables */
import './theme/variables.scss';
import { AppRoutes } from './AppRoutes';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { SQLiteHook, useSQLite } from 'react-sqlite-hook';
import { ProvidersWrapper } from './common/providers/ProvidersWrapper';
import { ModalContainer } from './common/components/modals/ModalContainer';
import { useAppDispatch, useAppSelector } from './store/store';
import { selectorPatch } from './store/settings/selectors';
import { changePatch } from './store/settings/settingsSlice';
import { UpdateProvider } from './common/providers/UpdateProvider';
import { Device } from '@capacitor/device';
import { setPlatform } from './store/app/appSlice';
import { selectorAdHeight, selectorPlatform } from './store/app/selectors';
import { AlertService } from './common/providers/AlertService';

interface JsonListenerInterface {
  jsonListeners: boolean;
  setJsonListeners: React.Dispatch<React.SetStateAction<boolean>>;
}

interface existingConnInterface {
  existConn: boolean;
  setExistConn: React.Dispatch<React.SetStateAction<boolean>>;
}

// Singleton SQLite Hook
export let sqlite: SQLiteHook;
// Existing Connections Store
export let existingConn: existingConnInterface;
// Is Json Listeners used
export let isJsonListeners: JsonListenerInterface;

setupIonicReact({ innerHTMLTemplatesEnabled: true });

const AdService = lazy(() => import('./common/providers/AdService'));

const App: React.FC = () => {
  const [existConn, setExistConn] = useState(false);
  existingConn = { existConn: existConn, setExistConn: setExistConn };
  sqlite = useSQLite();
  console.log(`$$$ in App sqlite.isAvailable  ${sqlite.isAvailable} $$$`);

  const dispatch = useAppDispatch();
  const patch = useAppSelector(selectorPatch);
  const platform = useAppSelector(selectorPlatform);
  const adHeight = useAppSelector(selectorAdHeight);
  const flagInnit = useRef(false);

  useEffect(() => {
    if (patch && !flagInnit.current) {
      dispatch(changePatch(patch));
    }
  }, [patch]);

  useEffect(() => {
    Device.getInfo().then(info => dispatch(setPlatform(info.platform)));
  }, []);

  return (
    <ProvidersWrapper>
      <IonApp style={{ marginBottom: `${adHeight}px` }}>
        <IonReactRouter>
          <UpdateProvider>
            <AppRoutes />
            <ModalContainer />
            <Suspense>{platform === 'android' && <AdService />}</Suspense>
            <AlertService />
          </UpdateProvider>
        </IonReactRouter>
      </IonApp>
    </ProvidersWrapper>
  );
};

export default App;

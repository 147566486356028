interface Scaling {
  standard: string;
  champion: string;
  unique: string;
}

interface TerrorZoneScaling {
  normal: Scaling;
  nightmare: Scaling;
  hell: Scaling;
}

export interface TerrorZones {
  act1: string[];
  act2: string[];
  act3: string[];
  act4: string[];
  act5: string[];
  scaling: TerrorZoneScaling;
}

export const TERROR_ZONES_26: TerrorZones = {
  act1: [
    'Blood Moor and Den of Evil',
    'Cold Plains and The Cave',
    'Burial Grounds, The Crypt, and the Mausoleum',
    'Stony Field',
    'Dark Wood / Underground Passage',
    'Black Marsh / The Hole',
    'The Forgotten Tower',
    'Jail / Barracks',
    'Cathedral and Catacombs',
    'The Pit',
    'Tristram',
    'Moo Moo Farm',
  ],
  act2: [
    'Sewers',
    'Rocky Waste and Stony Tomb',
    'Dry Hills and Halls of the Dead',
    'Far Oasis',
    'Lost City, Valley of Snakes, and Claw Viper Temple',
    'Ancient Tunnels',
    'Arcane Sanctuary',
    "Tal Rasha's Tombs",
  ],
  act3: [
    'Spider Forest and Spider Cavern',
    'Great Marsh',
    'Flayer Jungle and Flayer Dungeon',
    'Kurast Bazaar, Ruined Temple, and Disused Fane',
    'Travincal',
    'Durance of Hate',
  ],
  act4: ['Outer Steppes and Plains of Despair', 'River of Flame / City of the Damned', 'Chaos Sanctuary'],
  act5: [
    'Bloody Foothills / Frigid Highlands / Abbadon',
    'Glacial Trail / Drifter Cavern',
    'Crystalline Passage and Frozen River',
    'Arreat Plateau / Pit of Acheron',
    "Nihlathak's Temple, Halls of Anguish, Halls of Pain, and Halls of Vaught",
    "Ancient's Way and Icy Cellar",
    'Worldstone Keep, Throne of Destruction, and Worldstone Chamber',
  ],
  scaling: {
    normal: {
      standard: '+2 player level up to 45',
      champion: '+4 player level up to 47',
      unique: '+4 player level up to 47',
    },
    nightmare: {
      standard: '+2 player level up to 71',
      champion: '+4 player level up to 73',
      unique: '+5 player level up to 74',
    },
    hell: {
      standard: '+2 player level up to 96',
      champion: '+4 player level up to 98',
      unique: '+5 player level up to 99',
    },
  },
};

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { MuleContext } from '../../../common/providers/MuleProvider/MuleProvider';
import { IonButton, IonCheckbox, IonContent, IonSelect, IonSelectOption } from '@ionic/react';
import { itemTypes, StorageItem } from '../../../common/providers/ItemProvider/ItemProvider';
import { useAppSelector } from '../../../store/store';
import {
  selectorArmorBases,
  selectorRunewords,
  selectorSets,
  selectorUniques,
  selectorWeaponBases,
} from '../../../store/activeData/selector';
import { PickItem } from '../../../common/components/ItemSelectModal/PickItem';
import { ItemImage } from '../../../common/components/ItemImage';
import { ArmorBase, Gem, IRune, Item, MiscItem, SetItem, UniqueItem, WeaponBase } from '../../../common/types';
import { AddMuleItemTextArea } from './AddMuleItemTextArea';
import { MISC_ITEMS } from '../../../common/data/misc-items/misc_items';
import { useRunewordBases } from '../../runeword-bases/runeword_base_hook';
import { ALL_RUNES } from '../../../common/data/runes/all_runes';
import { mapItemTypeToString } from '../../../common/utils';
import { ALL_GEMS } from '../../../common/data/gems/gems';
import { GemImage } from '../../../common/components/GemImage';
import { AddItem } from '../../../store/app/appSlice';
import { useAddItem } from '../../../common/providers/providerHooks';

export const AddMuleItemBottomSheet: React.FC<{ onDismiss: (data: any) => void; inputItem?: AddItem }> = ({ onDismiss, inputItem }) => {
  const [item, setItem] = useState<StorageItem>({
    ethereal: false,
    sockets: null,
    props: null,
    item_type: inputItem?.type,
    item_id: inputItem?.id,
  } as StorageItem);

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [baseItemModalOpen, setBaseItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<object & Item>();
  const [selectedBaseItem, setSelectedBaseItem] = useState<object & Item>();

  const { mules, addMule } = useContext(MuleContext);
  const addItem = useAddItem();

  const uniques = useAppSelector(selectorUniques);
  const sets = useAppSelector(selectorSets);
  const runewords = useAppSelector(selectorRunewords);
  const armorBases = useAppSelector(selectorArmorBases);
  const weaponBases = useAppSelector(selectorWeaponBases);

  const self = useRef<HTMLDivElement>(null);
  const isInit = useRef(true);

  const { armors, weapons } = useRunewordBases(item?.item_type === 'runeword' && item.item_id !== null ? item.item_id : undefined);

  const itemOpts: Item[] = useMemo(() => {
    switch (item.item_type) {
      case 'unique':
        return uniques;
      case 'set':
        return sets;
      case 'runeword':
        return runewords;
      case 'rune':
        return ALL_RUNES;
      case 'base_armor':
        return armorBases;
      case 'base_weapon':
        return weaponBases;
      case 'misc':
        return [
          MISC_ITEMS[6],
          MISC_ITEMS[7],
          MISC_ITEMS[8],
          MISC_ITEMS[9],
          MISC_ITEMS[10],
          MISC_ITEMS[11],
          MISC_ITEMS[12],
          MISC_ITEMS[13],
          MISC_ITEMS[14],
          MISC_ITEMS[15],
          MISC_ITEMS[16],
        ];
      case 'gem':
        return ALL_GEMS;
      default:
        return [];
    }
  }, [item.item_type]);

  const baseItemOpts = useMemo(() => {
    switch (item.item_type) {
      case 'rare':
      case 'magic':
        return [
          ...armorBases,
          ...weaponBases,
          MISC_ITEMS[0],
          MISC_ITEMS[1],
          MISC_ITEMS[2],
          MISC_ITEMS[3],
          MISC_ITEMS[4],
          MISC_ITEMS[5],
        ].sort((a, b) => a.name.localeCompare(b.name));
      case 'runeword':
        return [...armors, ...weapons].sort((a, b) => a.name.localeCompare(b.name));
      default:
        return [];
    }
  }, [item.item_type, armors, weapons]);

  useEffect(() => {
    if (isInit.current && inputItem) {
      const item = itemOpts.find(i => i.id === inputItem.id);
      if (item) setSelectedItem(item);
    }
  }, [inputItem, itemOpts]);

  // Set initial mule
  useEffect(() => {
    if (item && mules.length && !item.mule_id) {
      item.mule_id = mules.at(0)?.mule_id ?? null;
    }
  }, [item, mules]);

  useEffect(() => {
    if (item.mule_id === -1) {
      addMule();
      setItem(prev => ({ ...prev, mule_id: null }));
    }
  }, [item.mule_id]);

  useEffect(() => {
    if (!isInit.current) {
      setSelectedItem(undefined);
      setSelectedBaseItem(undefined);
      setItem(prev => ({
        ...prev,
        props: null,
        sockets: null,
        base_name: null,
        base_id: null,
        item_id: null,
        name: null,
        ethereal: false,
      }));
    } else {
      isInit.current = false;
    }
  }, [item.item_type]);

  useEffect(() => {
    console.log(item);
  }, [item]);

  const handleSaveItem = () => {
    addItem(item).then(onDismiss);
  };

  const disabled = useMemo(() => {
    if (!item.mule_id) return true;
    if (item.item_type !== 'rare' && item.item_type !== 'magic' && item.item_id === undefined) return true;
    if (!item.item_type) return true;
    if (item.item_type === 'runeword' || item.item_type === 'rare' || item.item_type === 'magic') {
      if (item.base_id == undefined) return true; // "==" is intentional! Can be null or undefined
    }
    if (
      (item.item_type === 'unique' || item.item_type === 'set' || item.item_type === 'base_weapon' || item.item_type === 'base_armor') &&
      !item.name
    )
      return true;

    return false;
  }, [item]);

  return (
    <>
      <IonContent className={'ion-padding'}>
        <Container ref={self}>
          <IonSelect
            label={'Mule: '}
            onIonChange={e => {
              setItem(prev => ({ ...prev, mule_id: e.detail.value }));
            }}
            value={item.mule_id ?? ''}
            interface={'popover'}
            style={{ color: 'white' }}
          >
            {mules &&
              mules.map(m => (
                <IonSelectOption key={`ai-mule-${m.mule_id}`} value={m.mule_id}>
                  {m.mule_name}
                </IonSelectOption>
              ))}
            <IonSelectOption value={-1}>Add Mule...</IonSelectOption>
          </IonSelect>

          <IonSelect
            label={'Item type: '}
            onIonChange={e => {
              setSelectedItem(undefined);
              setItem(prev => ({ ...prev, item_type: e.detail.value }));
            }}
            value={item.item_type}
            interface={'popover'}
            style={{ color: 'white' }}
          >
            {itemTypes.map(t => (
              <IonSelectOption key={`ai-it-${t}`} value={t}>
                {mapItemTypeToString(t)}
              </IonSelectOption>
            ))}
          </IonSelect>

          {item.item_type !== 'rare' && item.item_type !== 'magic' && (
            <PickItem
              label={'Item name: '}
              isOpen={itemModalOpen}
              onClick={() => {
                setItemModalOpen(!itemModalOpen);
              }}
              onDismiss={() => setItemModalOpen(false)}
              selected={{
                name: item.name ?? '',
                type: item.item_type,
              }}
              items={itemOpts as Item[]}
              onSelect={selected => {
                setItemModalOpen(false);
                setItem(prev => ({ ...prev, item_id: selected.id, name: selected.name }));
                setSelectedItem(selected);
              }}
              presentingElement={self.current as HTMLDivElement | undefined}
              disabled={!item.item_type}
            />
          )}

          {(item.item_type === 'rare' || item.item_type === 'magic' || item.item_type === 'runeword') && (
            <PickItem
              label={'Base Item: '}
              isOpen={baseItemModalOpen}
              onClick={() => setBaseItemModalOpen(true)}
              onDismiss={() => setBaseItemModalOpen(false)}
              selected={{
                name: (baseItemOpts as Item[]).find(opt => opt.id === item.base_id)?.name ?? '',
                type: `${item.item_type === 'runeword' ? 'base_armor' : item.item_type}`,
              }}
              items={baseItemOpts}
              onSelect={selected => {
                setBaseItemModalOpen(false);
                setItem(prev => ({ ...prev, base_id: selected.id, base_name: selected.name }));
                setSelectedBaseItem(selected);
              }}
              disabled={item.item_type === 'runeword' && item.item_id === undefined}
            />
          )}

          {selectedItem &&
            (selectedItem.item_type === 'base_weapon' || selectedItem.item_type === 'base_armor') &&
            (selectedItem as ArmorBase).sockets && (
              <IonSelect
                label={'Sockets: '}
                value={item.sockets}
                interface={'popover'}
                onIonChange={e => setItem(prev => ({ ...prev, sockets: e.detail.value }))}
              >
                {new Array((selectedItem as ArmorBase).sockets).fill(1).map((_, i) => (
                  <IonSelectOption key={`socket_opt_${i}`} value={i + 1}>
                    {i + 1}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}

          {(item.item_type === 'rare' || item.item_type === 'magic') && (
            <IonSelect
              label={'Sockets: '}
              value={item.sockets}
              interface={'popover'}
              onIonChange={e => setItem(prev => ({ ...prev, sockets: e.detail.value }))}
            >
              {new Array(item.item_type === 'magic' ? 6 : 2).fill(1).map((_, i) => (
                <IonSelectOption key={`socket_opt_${i}`} value={i + 1}>
                  {i + 1}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}

          {item.item_type !== 'rune' && selectedBaseItem?.item_type !== 'misc' && item.item_type !== 'misc' && item.item_type !== 'gem' && (
            <IonCheckbox
              checked={item.ethereal}
              onIonChange={() =>
                setItem(prev => ({
                  ...prev,
                  ethereal: !prev.ethereal,
                }))
              }
            >
              Ethereal
            </IonCheckbox>
          )}

          {selectedItem && (
            <>
              {item.item_type === 'unique' && <ItemImage size={'120px'} uniqueFilename={(selectedItem as UniqueItem).filename} />}
              {item.item_type === 'set' && <ItemImage size={'120px'} setFilename={(selectedItem as SetItem).filename} />}
              {item.item_type === 'runeword' && selectedBaseItem && (
                <ItemImage
                  size={'120px'}
                  armorBase={selectedBaseItem.item_type === 'base_armor' ? (selectedBaseItem as ArmorBase) : undefined}
                  weaponBase={selectedBaseItem.item_type === 'base_weapon' ? (selectedBaseItem as WeaponBase) : undefined}
                  ethereal={item.ethereal}
                />
              )}
              {item.item_type === 'rune' && <ItemImage size={'120px'} runeName={(selectedItem as IRune).name} />}
              {(item.item_type === 'base_armor' || item.item_type === 'base_weapon') && (
                <ItemImage
                  size={'120px'}
                  armorBase={selectedItem.item_type === 'base_armor' ? (selectedItem as ArmorBase) : undefined}
                  weaponBase={selectedItem.item_type === 'base_weapon' ? (selectedItem as WeaponBase) : undefined}
                  ethereal={item.ethereal}
                />
              )}

              {item.item_type === 'misc' && <ItemImage size={'120px'} miscName={(selectedItem as MiscItem).name} />}
              {item.item_type === 'gem' && (
                <GemImage
                  quality={(selectedItem as Gem).quality}
                  type={(selectedItem as Gem).type}
                  size={'80px'}
                  style={{ margin: 'auto' }}
                />
              )}

              {item.item_type !== 'misc' && item.item_type !== 'gem' && (
                <AddMuleItemTextArea
                  itemType={item.item_type}
                  selectedItem={selectedItem}
                  onChange={props => setItem(item => ({ ...item, props }))}
                  ethereal={item.ethereal}
                  value={item.props}
                />
              )}
            </>
          )}

          {(item.item_type === 'magic' || item.item_type === 'rare') && selectedBaseItem && (
            <>
              <ItemImage
                size={'120px'}
                armorBase={selectedBaseItem?.item_type === 'base_armor' ? (selectedBaseItem as ArmorBase) : undefined}
                weaponBase={selectedBaseItem?.item_type === 'base_weapon' ? (selectedBaseItem as WeaponBase) : undefined}
                miscName={selectedBaseItem?.item_type === 'misc' ? (selectedBaseItem as MiscItem).filename : undefined}
                ethereal={item.ethereal}
              />

              <AddMuleItemTextArea
                itemType={item.item_type}
                selectedItem={selectedBaseItem}
                onChange={props => {
                  console.log('on change');
                  setItem(item => ({ ...item, props }));
                }}
                ethereal={item.ethereal}
                value={item.props}
              />
            </>
          )}
          <IonButton expand={'block'} onClick={handleSaveItem} disabled={disabled}>
            Save
          </IonButton>
        </Container>
      </IonContent>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  background-color: var(--background);
`;

const Textarea = styled.textarea`
  width: 100%;
`;

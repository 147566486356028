import React, { useContext, useEffect, useState } from 'react';
import { ArmorBase, WeaponBase } from '../../types';
import { ItemCard, ItemStats, ItemTitle } from '../StyledComponents';
import { useAppSelector } from '../../../store/store';
import { ItemImage } from '../ItemImage';
import { selectorFindArmorBase, selectorFindWeaponBase } from '../../../store/activeData/selector';
import { ItemContext, StorageItem } from '../../providers/ItemProvider/ItemProvider';
import { useIonViewWillLeave } from '@ionic/react';
import { ItemDBStatus } from '../ItemDBStatus';

interface Props {
  weaponBase?: WeaponBase;
  armorBase?: ArmorBase;
  weaponId?: number;
  armorId?: number;
}

export const ViewBase: React.FC<Props> = ({ armorBase, weaponBase, weaponId, armorId }) => {
  const [mounted, setMounted] = useState(false);
  const [storedItems, setStoredItems] = useState<StorageItem[]>([]);
  weaponBase = weaponBase ?? useAppSelector(state => selectorFindWeaponBase(state, weaponId));
  armorBase = armorBase ?? useAppSelector(state => selectorFindArmorBase(state, armorId));
  const { ready: itemProviderReady, findItem } = useContext(ItemContext);

  useEffect(() => {
    console.log(storedItems); // stop GH actions complaining
  }, []);

  useEffect(() => {
    if (itemProviderReady && armorBase) findItem('base_armor', armorBase.id).then(setStoredItems);
    if (itemProviderReady && weaponBase) findItem('base_weapon', weaponBase.id).then(setStoredItems);
  }, [weaponBase, armorBase, itemProviderReady, mounted]);

  useIonViewWillLeave(() => {
    setMounted(prev => !prev);
  });

  useEffect(() => {
    if (weaponBase?.name === 'Hand Axe') console.log('HA', mounted);
  }, [mounted]);

  if (weaponBase) {
    return (
      <ItemCard>
        <ItemTitle color={'white'}>{weaponBase.name}</ItemTitle>

        <ItemImage weaponBase={weaponBase} armorBase={armorBase} />

        <ItemStats>
          {weaponBase.minDam && (
            <div>
              <span>Damage: </span>
              {weaponBase.minDam} ~ {weaponBase!.maxDam}
            </div>
          )}
          {weaponBase.minThrowDmg && (
            <div>
              <span>Throw Damage: </span>
              {weaponBase.minThrowDmg} ~ {weaponBase!.maxThrowDmg}
            </div>
          )}
          {weaponBase.twoHandMinDmg && (
            <div>
              <span>Two Hand Damage: </span>
              {weaponBase.twoHandMinDmg} ~ {weaponBase!.twoHandMaxDmg}
            </div>
          )}
          {weaponBase.reqStr && (
            <div>
              <span>Required Strength: </span>
              {weaponBase.reqStr}
            </div>
          )}
          {weaponBase.reqDex && (
            <div>
              <span>Required Dexterity: </span>
              {weaponBase.reqDex}
            </div>
          )}
          {weaponBase.reqLvl && (
            <div>
              <span>Required Level: </span>
              {weaponBase.reqLvl}
            </div>
          )}
          {weaponBase.durability && (
            <div>
              <span>Durability: </span>
              {weaponBase.durability}
            </div>
          )}
          {weaponBase.maxStack && (
            <div>
              <span>Max Stack: </span>
              {weaponBase.maxStack}
            </div>
          )}
          {weaponBase.sockets_normal && (
            <div>
              <span>Max sockets (iLvl 1 - 25): </span>
              {weaponBase.sockets_normal}
            </div>
          )}
          {weaponBase.sockets_nm && (
            <div>
              <span>Max sockets (iLvl 26 - 40): </span>
              {weaponBase.sockets_nm}
            </div>
          )}
          {weaponBase.sockets_hell && (
            <div>
              <span>Max sockets (iLvl 41+): </span>
              {weaponBase.sockets_hell}
            </div>
          )}
          {!!weaponBase.range && (
            <div>
              <span>Range: </span>
              {weaponBase.range}
            </div>
          )}
          {weaponBase.speed !== undefined && (
            <div>
              <span>Speed: </span>
              {weaponBase.speed}
            </div>
          )}
          {weaponBase.special && (
            <div>
              <span>Special: </span>
              {weaponBase.special}
            </div>
          )}
        </ItemStats>
        <ItemDBStatus type={'base_weapon'} itemId={weaponBase.id} />
      </ItemCard>
    );
  } else if (armorBase) {
    return (
      <ItemCard>
        <ItemTitle color={'white'}>{armorBase.name}</ItemTitle>

        <ItemImage weaponBase={weaponBase} armorBase={armorBase} />

        <ItemStats>
          {armorBase.minDef && (
            <div>
              <span>Defense: </span>
              {armorBase.minDef} ~ {armorBase!.maxDef}
            </div>
          )}
          {armorBase.smiteMin && (
            <div>
              <span>Smite Damage: </span>
              {armorBase.smiteMin} ~ {armorBase!.smiteMax}
            </div>
          )}
          {armorBase.block && (
            <div>
              <span>Block: </span>
              {armorBase.block}%
            </div>
          )}
          {armorBase.reqStr && (
            <div>
              <span>Required Strength: </span>
              {armorBase.reqStr}
            </div>
          )}
          {armorBase.reqDex && (
            <div>
              <span>Required Dexterity: </span>
              {armorBase.reqDex}
            </div>
          )}
          {armorBase.reqLvl && (
            <div>
              <span>Required Level: </span>
              {armorBase.reqLvl}
            </div>
          )}
          {armorBase.durability && (
            <div>
              <span>Durability: </span>
              {armorBase.durability}
            </div>
          )}
          {armorBase.beltRows && (
            <div>
              <span>Rows: </span>
              {armorBase.beltRows}
            </div>
          )}
          {armorBase.sockets_normal && (
            <div>
              <span>Max sockets (iLvl 1 - 25): </span>
              {armorBase.sockets_normal}
            </div>
          )}
          {armorBase.sockets_nm && (
            <div>
              <span>Max sockets (iLvl 26 - 40): </span>
              {armorBase.sockets_nm}
            </div>
          )}
          {armorBase.sockets_hell && (
            <div>
              <span>Max sockets (iLvl 41+): </span>
              {armorBase.sockets_hell}
            </div>
          )}
          {armorBase.armorType !== undefined && (
            <div>
              <span>Weight: </span>
              {armorBase.armorType === 0 ? 'Light' : armorBase.armorType === 1 ? 'Medium' : 'Heavy'}
            </div>
          )}
        </ItemStats>
        <ItemDBStatus type={'base_armor'} itemId={armorBase.id} />
      </ItemCard>
    );
  } else return null;
};

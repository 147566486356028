import React, { useContext, useEffect, useRef, useState } from 'react';
import { ItemContext, StorageItem } from '../../providers/ItemProvider/ItemProvider';
import { useOpenCloseDialog } from '../../hooks';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { setViewMuledItemsDialog } from '../../../store/app/appSlice';
import { ViewStorageItemSlider } from '../ViewStorageItemSlider';

interface Props {
  ids: number[];
}

export const DialogViewMuledItems: React.FC<Props> = ({ ids }) => {
  const [storedItems, setStoredItems] = useState<StorageItem[]>([]);
  const self = useRef<HTMLDialogElement>(null);
  const { findItemsByIds } = useContext(ItemContext);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setViewMuledItemsDialog(undefined));
  };

  useOpenCloseDialog(self, !!storedItems.length);

  useEffect(() => {
    findItemsByIds(ids).then(setStoredItems);
  }, [ids]);

  const onItemDelete = (id: number) => {
    dispatch(setViewMuledItemsDialog(ids.filter(val => val !== id)));
  };

  return (
    <Dialog ref={self} onCancel={handleClose} onClose={handleClose}>
      <Close onClick={handleClose} />
      <ViewStorageItemSlider items={storedItems} onItemDelete={onItemDelete} />
    </Dialog>
  );
};

const Dialog = styled.dialog`
  width: 90vw;
  position: relative;
  padding: 60px 20px 40px;
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: url('/images/icons/close.svg');
  background-size: contain;
  background-repeat: no-repeat;
`;

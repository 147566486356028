import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorIsD2r, selectorPatch } from '../../../store/settings/selectors';
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import { changePatch, setIsD2r } from '../../../store/settings/settingsSlice';
import { patches } from '../../../common/data/patches/patchData';

export const QuickSettings: React.FC = () => {
  const isD2R = useAppSelector(selectorIsD2r);
  const patch = useAppSelector(selectorPatch);
  const dispatch = useAppDispatch();

  return (
    <>
      <IonItem lines={'none'}>
        <IonLabel slot={'start'} style={{ maxWidth: 'min-content', marginRight: 0 }}>
          Game type:
        </IonLabel>
        <IonToggle slot={'end'} checked={isD2R} onIonChange={() => dispatch(setIsD2r(!isD2R))} mode={'md'}>
          {isD2R ? 'Resurrected' : 'Classic'}
        </IonToggle>
      </IonItem>
      <IonItem lines={'none'}>
        {/*<IonLabel style={{ maxWidth: 'min-content'}}>Patch:</IonLabel>*/}
        <IonSelect
          label={'Patch:'}
          value={patch}
          onIonChange={e => dispatch(changePatch(e.detail.value))}
          interface={'popover'}
          mode={'ios'}
        >
          {[...patches]
            .reverse()
            .filter(p => p.isD2R === isD2R)
            .map(p => (
              <IonSelectOption key={`patch-select-${p.id}`} value={p.id} style={{ color: 'white' }}>
                {p.name} ({p.release})
              </IonSelectOption>
            ))}
        </IonSelect>
      </IonItem>
    </>
  );
};

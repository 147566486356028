import React from 'react';
import { StorageItem } from '../providers/ItemProvider/ItemProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components/macro';
import 'swiper/css';
import { ViewStorageItem } from './ViewStorageItem';
import { Navigation, Pagination } from 'swiper';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';

interface Props {
  items: StorageItem[];
  onItemDelete: (id: number) => void;
  autoHeight?: boolean;
  navigation?: boolean;
}

export const ViewStorageItemSlider: React.FC<Props> = ({ items, onItemDelete, autoHeight, navigation }) => {
  const router = useIonRouter();

  return (
    <SliderWrap arrows={true} dots={true}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={navigation !== undefined ? navigation : items.length > 1}
        pagination={items.length > 1}
        modules={[Navigation, Pagination]}
        // pagination={items.length > 1}
        // navigation={items.length > 1}
        loop={true}
        autoHeight={autoHeight !== undefined ? autoHeight : false}
      >
        {!!items.length &&
          items.map(item => (
            <SwiperSlide
              key={`storage-item-${item.id}`}
              onClick={() => router.push(appRoutes.mules.children.detail.path(item.mule_id!), 'forward')}
            >
              <ViewStorageItem item={item} onDelete={() => onItemDelete(item.id)} />
            </SwiperSlide>
          ))}
      </Swiper>
    </SliderWrap>
  );
};

export const SliderWrap = styled.div<{ arrows: boolean; dots: boolean }>`
  position: relative;

  .swiper {
    width: 90%;
    //margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    //margin-bottom: 30px;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    height: auto !important;

    img {
      max-width: 50%;
      height: 300px;
    }
  }

  ${({ arrows }) =>
    arrows &&
    css`
      .swiper-button {
        &-next,
        &-prev {
          top: 50%;
          z-index: 9;
          width: 40px;
          height: 40px;
          margin: auto;
          line-height: 40px;
          position: absolute;
          visibility: visible;
          text-align: center;
          transform: translateY(-50%);
          color: #000000;
          transition: all 0.4s ease 0s;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: 0 3px 25.5px 4.5px rgba(0, 0, 0, 0.06);
          background-image: url('/images/icons/chevron.svg');
          background-size: 80%;
          background-position: center;
          background-repeat: no-repeat;

          &:hover {
            color: #212121;
            background-color: var(--ion-color-primary);
          }

          &:after {
            font-size: 14px;
          }
        }

        &-prev {
          outline: 0;
          right: auto;
          left: 10px;
          background-image: url('/images/icons/chevron.svg');
          background-size: 80%;
          background-position: center;
          background-repeat: no-repeat;

          &:after {
            margin-right: 3px;
          }
        }

        &-next {
          outline: 0;
          left: auto;
          right: 10px;
          transform: rotate(180deg) translateY(50%);

          &:after {
            margin-left: 3px;
          }
        }

        &-disabled {
          opacity: 0.1;
        }
      }
    `}
  ${({ dots }) =>
    dots &&
    css`
      .swiper-pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &-bullet {
          cursor: pointer;
          width: 14px;
          height: 14px;
          display: inline-block;
          border-radius: 50%;
          background: var(--ion-color-primary);
          opacity: 0.2;
          margin: 8px 8px;
          box-shadow: none;
          transition: all 0.4s ease 0s;
          transform: scale(0.8);

          &:hover,
          &-active {
            background: var(--ion-color-primary);
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    `};
`;

import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonButton, IonIcon, IonSegment, IonSegmentButton } from '@ionic/react';
import { useAppSelector } from '../../store/store';
import { selectorArmorBases, selectorUniques, selectorWeaponBases } from '../../store/activeData/selector';
import { ViewUnique } from '../../common/components/ViewUnique/ViewUnique';
import { ViewSet } from '../../common/components/ViewSet/ViewSet';
import { ViewBase } from '../../common/components/ViewBase/ViewBase';
import {
  AMAZON_AUTO_MODS,
  ASSASSIN_AUTO_MODS,
  AutoModTable,
  BARBARIAN_AUTO_MODS,
  DRUID_AUTO_MODS,
  NECROMANCER_AUTO_MODS,
  PALADIN_AUTO_MODS,
  SORCERESS_AUTO_MODS,
} from '../../common/data/base-items/auto_mods';
import styled from 'styled-components/macro';
import { closeSharp } from 'ionicons/icons';

export const CatalogView: React.FC = () => {
  const { categoryId, category, type } = useParams<{ categoryId: string; category: string; type: string }>();
  console.log(categoryId, category, type);
  const [section, setSection] = useState('2');
  const uniques = useAppSelector(selectorUniques);
  const weaponBases = useAppSelector(selectorWeaponBases);
  const armorBases = useAppSelector(selectorArmorBases);
  const dialog = useRef<HTMLDialogElement>(null);

  const hasAutoMods =
    category.includes('Base') &&
    (type.includes('Amazon') ||
      type.includes('Assassin') ||
      type.includes('Barbarian') ||
      type.includes('Druid') ||
      type.includes('Paladin') ||
      type.includes('Necromancer') ||
      type.includes('Sorceress'));

  const getTitle = () => {
    if (category === 'Unique') {
      return ~~categoryId <= 6 ? `${type} ${category}s` : `${category} ${type}`;
    }
    return type;
  };

  const presentAutoMods = () => {
    if (dialog.current && !dialog.current.open) {
      dialog.current.showModal();
    }
  };

  const closeAutoMods = () => {
    if (dialog.current && dialog.current.open) {
      dialog.current.close();
    }
  };

  return (
    <AppPage
      padding={true}
      navBar={true}
      title={getTitle()}
      headerContent={
        <>
          {((category === 'Unique' && ~~categoryId >= 12) || category.includes('Base')) && (
            <IonSegment value={section} onIonChange={e => setSection(e.detail.value as string)} mode={'md'}>
              <IonSegmentButton value={'0'}>Normal</IonSegmentButton>
              <IonSegmentButton value={'1'}>Exceptional</IonSegmentButton>
              <IonSegmentButton value={'2'}>Elite</IonSegmentButton>
            </IonSegment>
          )}
          {type === 'Amazon' && (
            <IonSegment value={section} onIonChange={e => setSection(e.detail.value as string)} mode={'md'}>
              <IonSegmentButton value={'2'}>Javelins</IonSegmentButton>
              <IonSegmentButton value={'3'}>Bows</IonSegmentButton>
            </IonSegment>
          )}
          {hasAutoMods && (
            <IonButton onClick={presentAutoMods} size={'small'} expand={'block'} style={{ marginTop: '10px' }}>
              Auto Mods
            </IonButton>
          )}
        </>
      }
    >
      <>
        {category === 'Unique' && (
          <>
            {~~categoryId <= 11 &&
              uniques
                .filter(
                  u =>
                    u.category_id === ~~categoryId &&
                    (type === 'Amazon' ? (section === '2' ? u.category.includes('Javelins') : u.category.includes('Bows')) : true),
                )
                .map(u => <ViewUnique style={{ marginTop: '10px' }} key={`item-${u.id}`} id={u.id} />)}

            {~~categoryId > 11 &&
              uniques
                .filter(u => u.category_id === ~~categoryId && u.quality_id === ~~section)
                .map(u => <ViewUnique style={{ marginTop: '10px' }} key={`item-${u.id}`} id={u.id} />)}
          </>
        )}

        {category === 'Set' && <ViewSet setId={~~categoryId} />}

        {category === 'Base Weapon' && (
          <>
            {weaponBases
              .filter(w => w.qualifiedType === type && w.quality_id === ~~section)
              .map(w => (
                <ViewBase key={`w-item-${w.id}`} weaponBase={w} />
              ))}
          </>
        )}

        {category === 'Base Armor' && (
          <>
            {armorBases
              .filter(a => a.qualifiedType === type && a.quality_id === ~~section)
              .map(w => (
                <ViewBase key={`w-item-${w.id}`} armorBase={w} />
              ))}
          </>
        )}
      </>
      <Dialog ref={dialog}>
        <Close>
          <IonIcon icon={closeSharp} style={{ fontSize: '30px' }} onClick={closeAutoMods} color={'light'} />
        </Close>
        {type === 'Amazon Bows' && (
          <>
            <ModTable table={AMAZON_AUTO_MODS.tables[0]} />
            <ModNotes notes={AMAZON_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Amazon Javelins' && (
          <>
            <ModTable table={AMAZON_AUTO_MODS.tables[1]} /> <ModNotes notes={AMAZON_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Amazon Spears' && (
          <>
            <ModTable table={AMAZON_AUTO_MODS.tables[1]} /> <ModNotes notes={AMAZON_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Assassin Katars' && (
          <>
            <ModTable table={ASSASSIN_AUTO_MODS.tables[0]} /> <ModNotes notes={ASSASSIN_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Barbarian Helms' && (
          <>
            <ModTable table={BARBARIAN_AUTO_MODS.tables[0]} /> <ModNotes notes={BARBARIAN_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Druid Pelts' && (
          <>
            <ModTable table={DRUID_AUTO_MODS.tables[0]} /> <ModNotes notes={DRUID_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Paladin Shields' && (
          <>
            <ModTable table={PALADIN_AUTO_MODS.tables[0]} /> <ModNotes notes={PALADIN_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Necromancer Shrunken Heads' && (
          <>
            <ModTable table={NECROMANCER_AUTO_MODS.tables[0]} /> <ModNotes notes={NECROMANCER_AUTO_MODS.notes} />
          </>
        )}
        {type === 'Sorceress Orbs' && (
          <>
            <ModTable table={SORCERESS_AUTO_MODS.tables[0]} /> <ModNotes notes={SORCERESS_AUTO_MODS.notes} />
          </>
        )}
      </Dialog>
    </AppPage>
  );
};

const ModTable: React.FC<{ table?: AutoModTable }> = ({ table }) => {
  return (
    <>
      {table && (
        <Table>
          <tbody>
            <tr>
              {table.tableHeaders.map((h, i) => (
                <th key={`table-h-${i}`} dangerouslySetInnerHTML={{ __html: h }}></th>
              ))}
            </tr>
            {table.tableRows.map((r, i) => (
              <tr key={`table-${i}-r-${i}`}>
                {r.map((v, j) => (
                  <td key={`table-${i}-r-${i}-d-${j}`} dangerouslySetInnerHTML={{ __html: v }}></td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

const ModNotes: React.FC<{ notes: string[] }> = ({ notes }) => {
  return (
    <Ul>
      {notes.map((n, i) => (
        <li key={`note-${i}`}>{n}</li>
      ))}
    </Ul>
  );
};

const Dialog = styled.dialog`
  position: relative;
`;

const Close = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Table = styled.table`
  max-width: 100%;
  margin: 50px auto 20px;
  color: white;

  th,
  td {
    max-width: 130px;
    word-break: keep-all;
    white-space: pre-wrap;
    padding: 0 10px;
    border: 1px solid var(--border-color);
    font-size: 12px;
  }
`;

const Ul = styled.ul`
  font-size: 14px;
  color: white;

  > li {
    padding: 8px 0;
    list-style-type: ' *   ';
    list-style-position: outside;
    word-break: keep-all;
  }
`;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ItemContext, StorageItem } from '../../common/providers/ItemProvider/ItemProvider';
import { useParams } from 'react-router';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { MuleContext } from '../../common/providers/MuleProvider/MuleProvider';
import styled from 'styled-components/macro';
import { IonAccordion, IonAccordionGroup, IonFab, IonFabButton, IonItem, IonLabel, IonListHeader } from '@ionic/react';
import { ViewStorageItem } from '../../common/components/ViewStorageItem';
import { GemImage } from '../../common/components/GemImage';
import { ALL_GEMS } from '../../common/data/gems/gems';
import { Rune } from '../../common/components/Rune';
import { MuleDetailViewHeader } from './components/MuleDetailViewHeader';
import { AddItemIcon } from './Mules';

interface SortedItems {
  unique: StorageItem[];
  set: StorageItem[];
  runeword: StorageItem[];
  rune: StorageItem[];
  rare: StorageItem[];
  magic: StorageItem[];
  base_armor: StorageItem[];
  base_weapon: StorageItem[];
  misc: StorageItem[];
  gem: StorageItem[];
}

const initialState: SortedItems = {
  gem: [],
  rune: [],
  base_armor: [],
  base_weapon: [],
  magic: [],
  rare: [],
  runeword: [],
  unique: [],
  misc: [],
  set: [],
};
export const MuleDetailView: React.FC = () => {
  const { muleId } = useParams<{ muleId: string }>();
  const [sorted, setSorted] = useState<SortedItems>(JSON.parse(JSON.stringify(initialState)));
  const { getItemsByMuleId, ready } = useContext(ItemContext);
  const { mules } = useContext(MuleContext);
  const { openAddItem } = useContext(ItemContext);

  useEffect(() => {
    if (ready) {
      getItems();
    }
  }, [ready, muleId]);

  const getItems = () => {
    getItemsByMuleId(~~muleId).then(sortItems);
  };

  const sortItems = (items: StorageItem[]) => {
    const temp: SortedItems = JSON.parse(JSON.stringify(initialState));
    for (const item of items) {
      temp[item.item_type].push(item);
    }

    temp.rune.sort((a, b) => b.id - a.id);
    temp.gem.sort((a, b) => (a.item_id ?? 0) - (b.item_id ?? 0));
    temp.unique.sort(compareName);
    temp.set.sort(compareName);
    temp.base_armor.sort(compareName);
    temp.base_weapon.sort(compareName);
    temp.magic.sort(compareBaseName);
    temp.rare.sort(compareBaseName);
    temp.misc.sort(compareBaseName);

    setSorted(temp);
  };

  const mule = useMemo(() => mules.find(m => m.mule_id == ~~muleId), [mules, muleId]);

  return (
    <AppPage title={<MuleDetailViewHeader mule={mule} />} padding={true} navBar={true}>
      <Container>
        {!!sorted.unique.length && (
          <>
            <IonListHeader className={'no-inset white'}>Uniques</IonListHeader>
            <IonAccordionGroup>
              {sorted.unique.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.set.length && (
          <>
            <IonListHeader className={'no-inset white'}>Sets</IonListHeader>
            <IonAccordionGroup>
              {sorted.set.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.runeword.length && (
          <>
            <IonListHeader className={'no-inset white'}>Runewords</IonListHeader>
            <IonAccordionGroup>
              {sorted.runeword.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.rare.length && (
          <>
            <IonListHeader className={'no-inset white'}>Rares</IonListHeader>
            <IonAccordionGroup>
              {sorted.rare.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.magic.length && (
          <>
            <IonListHeader className={'no-inset white'}>Magics</IonListHeader>
            <IonAccordionGroup>
              {sorted.magic.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.base_weapon.length && (
          <>
            <IonListHeader className={'no-inset white'}>Weapon Bases</IonListHeader>
            <IonAccordionGroup>
              {sorted.base_weapon.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.base_armor.length && (
          <>
            <IonListHeader className={'no-inset white'}>Armor Bases</IonListHeader>
            <IonAccordionGroup>
              {sorted.base_armor.map(item => (
                <ItemAccordion key={`item-${item.id}`} item={item} onDelete={getItems} />
              ))}
            </IonAccordionGroup>
          </>
        )}

        {!!sorted.gem.length && (
          <>
            <IonListHeader className={'no-inset white'}>Gems</IonListHeader>
            <Grid>
              {sorted.gem.map(item => {
                const gem = ALL_GEMS.find(g => g.id === item.item_id);
                return gem ? <GemImage key={`item-${item.id}`} quality={gem.quality} type={gem.type} size={'10vw'} /> : null;
              })}
            </Grid>
          </>
        )}

        {!!sorted.rune.length && (
          <>
            <IonListHeader className={'no-inset white'}>Runes</IonListHeader>
            <Grid>
              {sorted.rune.map(item => (
                <Rune key={`item-${item.id}`} id={item.item_id!} size={'10vw'} forceInStock={true} />
              ))}
            </Grid>
          </>
        )}
      </Container>

      <IonFab slot={'fixed'} horizontal={'end'} vertical={'bottom'}>
        <IonFabButton>
          <AddItemIcon onClick={() => openAddItem()} />
        </IonFabButton>
      </IonFab>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

export const ItemAccordion: React.FC<{ item: StorageItem; onDelete: () => void }> = ({ item, onDelete }) => {
  return (
    <IonAccordion value={item.id.toString(10)}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel className={getClassName(item)}>
          <div>
            {item.name && (
              <>
                <span className={item.item_type}>{item.name} </span>
                {item.base_name && (
                  <>
                    <span className={'base'}>({item.base_name})</span>
                  </>
                )}
              </>
            )}
            {!item.name && item.base_name && <span className={item.item_type}>{item.base_name}</span>}

            {item.sockets && <span className={'base'}>({item.sockets})</span>}
          </div>
        </IonLabel>
      </IonItem>
      <div slot={'content'}>
        <ViewStorageItem item={item} noMuleName={true} onDelete={onDelete} />
      </div>
    </IonAccordion>
  );
};

const getClassName = (item: StorageItem) => {
  if (!item.item_type.includes('base')) return item.item_type;
  if (item.sockets && item.sockets > 0) return 'base';
  return 'white';
};

const compareName = (a: StorageItem, b: StorageItem) => compare(a, b, 'name');
const compareBaseName = (a: StorageItem, b: StorageItem) => compare(a, b, 'base_name');

const compare = (a: StorageItem, b: StorageItem, key: 'name' | 'base_name') => (a[key] ?? '').localeCompare(b[key] ?? '');

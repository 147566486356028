import { WeaponBase } from '../../types';

export const WEAPON_BASES: WeaponBase[] = [
  {
    name: 'Hand Axe',
    minDam: 3,
    maxDam: 6,

    isTwoHanded: 0,
    type: 'axe',
    code: 'hax',
    wClass: '1hs',
    sockets: 2,
    durability: 28,
    range: 0,
    speed: 0,

    quality: 3,
    nightmareUpgrade: 'axe',
    hellUpgrade: '2ax',
    filename: 'handaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 201,
    item_type: 'base_weapon',
  },
  {
    name: 'Axe',
    minDam: 4,
    maxDam: 11,

    isTwoHanded: 0,
    type: 'axe',
    code: 'axe',
    wClass: '1hs',
    sockets: 4,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 32,

    quality: 7,
    nightmareUpgrade: '2ax',
    hellUpgrade: 'mpi',
    filename: 'axed2.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 202,
    item_type: 'base_weapon',
  },
  {
    name: 'Double Axe',
    minDam: 5,
    maxDam: 13,

    isTwoHanded: 0,
    type: 'axe',
    code: '2ax',
    wClass: '1hs',
    sockets: 5,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 43,

    quality: 13,
    nightmareUpgrade: 'mpi',
    hellUpgrade: 'wax',
    filename: 'doubleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 203,
    item_type: 'base_weapon',
  },
  {
    name: 'Military Pick',
    minDam: 7,
    maxDam: 11,

    isTwoHanded: 0,
    type: 'axe',
    code: 'mpi',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 1,
    speed: -10,
    reqStr: 49,
    reqDex: 33,

    quality: 19,
    nightmareUpgrade: 'wax',
    hellUpgrade: 'xxx',
    filename: 'militarypick.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 204,
    item_type: 'base_weapon',
  },
  {
    name: 'War Axe',
    minDam: 10,
    maxDam: 18,

    isTwoHanded: 0,
    type: 'axe',
    code: 'wax',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 2,
    speed: 0,
    reqStr: 67,

    quality: 25,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'waraxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 205,
    item_type: 'base_weapon',
  },
  {
    name: 'Large Axe',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 13,
    isTwoHanded: 1,
    type: 'axe',
    code: 'lax',
    wClass: 'stf',
    sockets: 4,
    durability: 30,
    range: 1,
    speed: -10,
    reqStr: 35,

    quality: 6,
    nightmareUpgrade: 'btx',
    hellUpgrade: 'gax',
    filename: 'largeaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 206,
    item_type: 'base_weapon',
  },
  {
    name: 'Broad Axe ',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 18,
    isTwoHanded: 1,
    type: 'axe',
    code: 'bax',
    wClass: 'stf',
    sockets: 5,
    durability: 35,
    range: 1,
    speed: 0,
    reqStr: 48,

    quality: 12,
    nightmareUpgrade: 'gax',
    hellUpgrade: 'gix',
    filename: 'broadaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 207,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Axe',

    twoHandMinDmg: 12,
    twoHandMaxDmg: 32,
    isTwoHanded: 1,
    type: 'axe',
    code: 'btx',
    wClass: 'stf',
    sockets: 5,
    durability: 40,
    range: 1,
    speed: 10,
    reqStr: 54,

    quality: 17,
    nightmareUpgrade: 'gix',
    hellUpgrade: 'xxx',
    filename: 'battleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 208,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Axe',

    twoHandMinDmg: 9,
    twoHandMaxDmg: 30,
    isTwoHanded: 1,
    type: 'axe',
    code: 'gax',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: -10,
    reqStr: 63,
    reqDex: 39,

    quality: 23,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greataxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 209,
    item_type: 'base_weapon',
  },
  {
    name: 'Giant Axe',

    twoHandMinDmg: 22,
    twoHandMaxDmg: 45,
    isTwoHanded: 1,
    type: 'axe',
    code: 'gix',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 3,
    speed: 10,
    reqStr: 70,

    quality: 27,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'giantaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 210,
    item_type: 'base_weapon',
  },
  {
    name: 'Wand',
    minDam: 2,
    maxDam: 4,

    isTwoHanded: 0,
    type: 'wand',
    code: 'wnd',
    wClass: '1hs',
    sockets: 1,
    durability: 15,
    range: 0,
    speed: 0,

    quality: 2,
    nightmareUpgrade: 'ywn',
    hellUpgrade: 'bwn',
    special: 'magically charged',
    filename: 'wand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 211,
    item_type: 'base_weapon',
  },
  {
    name: 'Yew Wand',
    minDam: 2,
    maxDam: 8,

    isTwoHanded: 0,
    type: 'wand',
    code: 'ywn',
    wClass: '1hs',
    sockets: 1,
    durability: 15,
    range: 0,
    speed: 10,

    quality: 12,
    nightmareUpgrade: 'bwn',
    hellUpgrade: 'gwn',
    special: 'magically charged',
    filename: 'yewwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 212,
    item_type: 'base_weapon',
  },
  {
    name: 'Bone Wand',
    minDam: 3,
    maxDam: 7,

    isTwoHanded: 0,
    type: 'wand',
    code: 'bwn',
    wClass: '1hs',
    sockets: 2,
    durability: 15,
    range: 0,
    speed: -20,

    quality: 18,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'bonewand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 213,
    item_type: 'base_weapon',
  },
  {
    name: 'Grim Wand',
    minDam: 5,
    maxDam: 11,

    isTwoHanded: 0,
    type: 'wand',
    code: 'gwn',
    wClass: '1hs',
    sockets: 2,
    durability: 15,
    range: 0,
    speed: 0,

    quality: 26,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'grimwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 214,
    item_type: 'base_weapon',
  },
  {
    name: 'Club',
    minDam: 1,
    maxDam: 6,

    isTwoHanded: 0,
    type: 'club',
    code: 'clb',
    wClass: '1hs',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: -10,

    quality: 1,
    nightmareUpgrade: 'mac',
    hellUpgrade: 'mst',
    filename: 'd2-club.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 215,
    item_type: 'base_weapon',
  },
  {
    name: 'Scepter',
    minDam: 6,
    maxDam: 11,

    isTwoHanded: 0,
    type: 'scep',
    code: 'scp',
    wClass: '1hs',
    sockets: 2,
    durability: 50,
    range: 0,
    speed: 0,
    reqStr: 25,

    quality: 3,
    nightmareUpgrade: 'gsc',
    hellUpgrade: 'wsp',
    special: 'magically charged',
    filename: 'scepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 216,
    item_type: 'base_weapon',
  },
  {
    name: 'Grand Scepter',
    minDam: 8,
    maxDam: 18,

    isTwoHanded: 0,
    type: 'scep',
    code: 'gsc',
    wClass: '1hs',
    sockets: 3,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 37,

    quality: 15,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'wsp',
    special: 'magically charged',
    filename: 'grandscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 217,
    item_type: 'base_weapon',
  },
  {
    name: 'War Scepter',
    minDam: 10,
    maxDam: 17,

    isTwoHanded: 0,
    type: 'scep',
    code: 'wsp',
    wClass: '1hs',
    sockets: 5,
    durability: 70,
    range: 1,
    speed: -10,
    reqStr: 55,

    quality: 21,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 218,
    item_type: 'base_weapon',
  },
  {
    name: 'Spiked Club',
    minDam: 5,
    maxDam: 8,

    isTwoHanded: 0,
    type: 'club',
    code: 'spc',
    wClass: '1hs',
    sockets: 2,
    durability: 36,
    range: 1,
    speed: 0,

    quality: 4,
    nightmareUpgrade: 'mac',
    hellUpgrade: 'mst',
    filename: 'spikedclub.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 219,
    item_type: 'base_weapon',
  },
  {
    name: 'Mace',
    minDam: 3,
    maxDam: 10,

    isTwoHanded: 0,
    type: 'mace',
    code: 'mac',
    wClass: '1hs',
    sockets: 2,
    durability: 60,
    range: 0,
    speed: 0,
    reqStr: 27,

    quality: 8,
    nightmareUpgrade: 'mst',
    hellUpgrade: 'mau',
    filename: 'd2-mace.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 220,
    item_type: 'base_weapon',
  },
  {
    name: 'Morning Star',
    minDam: 7,
    maxDam: 16,

    isTwoHanded: 0,
    type: 'mace',
    code: 'mst',
    wClass: '1hs',
    sockets: 3,
    durability: 72,
    range: 1,
    speed: 10,
    reqStr: 36,

    quality: 13,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'fla',
    filename: 'd2-morningstar.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 221,
    item_type: 'base_weapon',
  },
  {
    name: 'Flail',
    minDam: 1,
    maxDam: 24,

    isTwoHanded: 0,
    type: 'mace',
    code: 'fla',
    wClass: '1hs',
    sockets: 5,
    durability: 30,
    range: 2,
    speed: -10,
    reqStr: 41,
    reqDex: 35,

    quality: 19,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'gma',
    filename: 'd2-flail.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 222,
    item_type: 'base_weapon',
  },
  {
    name: 'War Hammer',
    minDam: 19,
    maxDam: 29,

    isTwoHanded: 0,
    type: 'hamm',
    code: 'whm',
    wClass: '1hs',
    sockets: 4,
    durability: 55,
    range: 0,
    speed: 20,
    reqStr: 53,

    quality: 25,
    nightmareUpgrade: 'mau',
    hellUpgrade: 'gma',
    filename: 'warhammer.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 223,
    item_type: 'base_weapon',
  },
  {
    name: 'Maul',

    twoHandMinDmg: 30,
    twoHandMaxDmg: 43,
    isTwoHanded: 1,
    type: 'hamm',
    code: 'mau',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 69,

    quality: 21,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'mau',
    filename: 'maul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 224,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Maul',

    twoHandMinDmg: 38,
    twoHandMaxDmg: 58,
    isTwoHanded: 1,
    type: 'hamm',
    code: 'gma',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 2,
    speed: 20,
    reqStr: 99,

    quality: 32,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greatmaul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 225,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Sword',
    minDam: 2,
    maxDam: 7,

    isTwoHanded: 0,
    type: 'swor',
    code: 'ssd',
    wClass: '1hs',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: 0,

    quality: 1,
    nightmareUpgrade: 'scm',
    hellUpgrade: 'wsd',
    filename: 'shortswordd2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 226,
    item_type: 'base_weapon',
  },
  {
    name: 'Scimitar',
    minDam: 2,
    maxDam: 6,

    isTwoHanded: 0,
    type: 'swor',
    code: 'scm',
    wClass: '1hs',
    sockets: 2,
    durability: 22,
    range: 0,
    speed: -20,

    reqDex: 21,

    quality: 5,
    nightmareUpgrade: 'sbr',
    hellUpgrade: 'lsd',
    filename: 'scimitard2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 227,
    item_type: 'base_weapon',
  },
  {
    name: 'Sabre',
    minDam: 3,
    maxDam: 8,

    isTwoHanded: 0,
    type: 'swor',
    code: 'sbr',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: -10,
    reqStr: 25,
    reqDex: 25,

    quality: 8,
    nightmareUpgrade: 'flc',
    hellUpgrade: 'bsd',
    filename: 'saber.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,

    id: 228,
    item_type: 'base_weapon',
  },
  {
    name: 'Falchion',
    minDam: 9,
    maxDam: 17,

    isTwoHanded: 0,
    type: 'swor',
    code: 'flc',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: 20,
    reqStr: 33,

    quality: 11,
    nightmareUpgrade: 'bsd',
    hellUpgrade: 'wsd',
    filename: 'd2falchion.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 229,
    item_type: 'base_weapon',
  },
  {
    name: 'Crystal Sword',
    minDam: 5,
    maxDam: 15,

    isTwoHanded: 0,
    type: 'swor',
    code: 'crs',
    wClass: '1hs',
    sockets: 6,
    durability: 20,
    range: 1,
    speed: 0,
    reqStr: 43,

    quality: 11,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'crystalsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 230,
    item_type: 'base_weapon',
  },
  {
    name: 'Broad Sword',
    minDam: 7,
    maxDam: 14,

    isTwoHanded: 0,
    type: 'swor',
    code: 'bsd',
    wClass: '1hs',
    sockets: 4,
    durability: 32,
    range: 0,
    speed: 0,
    reqStr: 48,

    quality: 15,
    nightmareUpgrade: 'lsd',
    hellUpgrade: 'wsd',
    filename: 'd2broadsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 231,
    item_type: 'base_weapon',
  },
  {
    name: 'Long Sword ',
    minDam: 3,
    maxDam: 19,

    isTwoHanded: 0,
    type: 'swor',
    code: 'lsd',
    wClass: '1hs',
    sockets: 4,
    durability: 44,
    range: 1,
    speed: -10,
    reqStr: 55,
    reqDex: 39,

    quality: 20,
    nightmareUpgrade: 'wsd',
    hellUpgrade: 'wsd',
    filename: 'd2longsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,

    id: 232,
    item_type: 'base_weapon',
  },
  {
    name: 'War Sword',
    minDam: 8,
    maxDam: 20,

    isTwoHanded: 0,
    type: 'swor',
    code: 'wsd',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 1,
    speed: 0,
    reqStr: 71,
    reqDex: 45,

    quality: 27,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'warsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 233,
    item_type: 'base_weapon',
  },
  {
    name: 'Two-Handed Sword',
    minDam: 2,
    maxDam: 9,

    twoHandMinDmg: 8,
    twoHandMaxDmg: 17,
    isTwoHanded: 1,
    type: 'swor',
    code: '2hs',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 2,
    speed: 0,
    reqStr: 35,
    reqDex: 27,

    quality: 10,
    nightmareUpgrade: 'clm',
    hellUpgrade: 'gis',
    filename: '2hsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 234,
    item_type: 'base_weapon',
  },
  {
    name: 'Claymore',
    minDam: 5,
    maxDam: 12,

    twoHandMinDmg: 13,
    twoHandMaxDmg: 30,
    isTwoHanded: 1,
    type: 'swor',
    code: 'clm',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 47,

    quality: 17,
    nightmareUpgrade: 'gis',
    hellUpgrade: 'bsw',
    filename: 'd2claymore.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 235,
    item_type: 'base_weapon',
  },
  {
    name: 'Giant Sword',
    minDam: 3,
    maxDam: 16,

    twoHandMinDmg: 9,
    twoHandMaxDmg: 28,
    isTwoHanded: 1,
    type: 'swor',
    code: 'gis',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 56,
    reqDex: 34,

    quality: 21,
    nightmareUpgrade: 'bsw',
    hellUpgrade: 'flb',
    filename: 'giantsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 236,
    item_type: 'base_weapon',
  },
  {
    name: 'Bastard Sword',
    minDam: 7,
    maxDam: 19,

    twoHandMinDmg: 20,
    twoHandMaxDmg: 28,
    isTwoHanded: 1,
    type: 'swor',
    code: 'bsw',
    wClass: '1hs',
    sockets: 4,
    durability: 40,
    range: 1,
    speed: 10,
    reqStr: 62,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2bastardsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 237,
    item_type: 'base_weapon',
  },
  {
    name: 'Flamberge',
    minDam: 9,
    maxDam: 15,

    twoHandMinDmg: 13,
    twoHandMaxDmg: 26,
    isTwoHanded: 1,
    type: 'swor',
    code: 'flb',
    wClass: '1hs',
    sockets: 5,
    durability: 50,
    range: 2,
    speed: -10,
    reqStr: 70,
    reqDex: 49,

    quality: 27,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'flamberge.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 238,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Sword',
    minDam: 12,
    maxDam: 20,

    twoHandMinDmg: 25,
    twoHandMaxDmg: 42,
    isTwoHanded: 1,
    type: 'swor',
    code: 'gsd',
    wClass: '1hs',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 100,
    reqDex: 60,

    quality: 33,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2greatsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 239,
    item_type: 'base_weapon',
  },
  {
    name: 'Dagger',
    minDam: 1,
    maxDam: 4,

    isTwoHanded: 0,
    type: 'knif',
    code: 'dgr',
    wClass: '1ht',
    sockets: 1,
    durability: 16,
    range: 0,
    speed: -20,

    quality: 3,
    nightmareUpgrade: 'kri',
    hellUpgrade: 'bld',
    filename: 'd2-dagger.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 240,
    item_type: 'base_weapon',
  },
  {
    name: 'Dirk',
    minDam: 3,
    maxDam: 9,

    isTwoHanded: 0,
    type: 'knif',
    code: 'dir',
    wClass: '1ht',
    sockets: 1,
    durability: 20,
    range: 0,
    speed: 0,

    reqDex: 25,

    quality: 9,
    nightmareUpgrade: 'kri',
    hellUpgrade: 'bld',
    filename: 'dirk.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 241,
    item_type: 'base_weapon',
  },
  {
    name: 'Kris',
    minDam: 2,
    maxDam: 11,

    isTwoHanded: 0,
    type: 'knif',
    code: 'kri',
    wClass: '1ht',
    sockets: 3,
    durability: 24,
    range: 0,
    speed: -20,

    reqDex: 45,

    quality: 17,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'kris.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 0,

    id: 242,
    item_type: 'base_weapon',
  },
  {
    name: 'Blade',
    minDam: 4,
    maxDam: 15,

    isTwoHanded: 0,
    type: 'knif',
    code: 'bld',
    wClass: '1ht',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: -10,
    reqStr: 35,
    reqDex: 51,

    quality: 23,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-blade.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 243,
    item_type: 'base_weapon',
  },
  {
    name: 'Throwing Knife',
    minDam: 2,
    maxDam: 3,
    minThrowDmg: 4,
    maxThrowDmg: 9,

    isTwoHanded: 0,
    type: 'tkni',
    code: 'tkf',
    wClass: '1ht',

    durability: 4,
    range: 0,
    speed: 0,

    reqDex: 21,

    maxStack: 160,
    quality: 2,
    nightmareUpgrade: 'bkf',
    hellUpgrade: 'bkf',
    special: 'primarily thrown',
    filename: 'throwingknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 0,

    id: 244,
    item_type: 'base_weapon',
  },
  {
    name: 'Throwing Axe',
    minDam: 4,
    maxDam: 7,
    minThrowDmg: 8,
    maxThrowDmg: 12,

    isTwoHanded: 0,
    type: 'taxe',
    code: 'tax',
    wClass: '1hs',

    durability: 6,
    range: 0,
    speed: 10,

    reqDex: 40,

    maxStack: 130,
    quality: 7,
    nightmareUpgrade: 'bal',
    hellUpgrade: 'bal',
    special: 'primarily thrown',
    filename: 'throwingaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 0,

    id: 245,
    item_type: 'base_weapon',
  },
  {
    name: 'Balanced Knife',
    minDam: 1,
    maxDam: 8,
    minThrowDmg: 6,
    maxThrowDmg: 11,

    isTwoHanded: 0,
    type: 'tkni',
    code: 'bkf',
    wClass: '1ht',

    durability: 8,
    range: 0,
    speed: -20,

    reqDex: 51,

    maxStack: 160,
    quality: 13,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 0,

    id: 246,
    item_type: 'base_weapon',
  },
  {
    name: 'Balanced Axe',
    minDam: 5,
    maxDam: 10,
    minThrowDmg: 12,
    maxThrowDmg: 15,

    isTwoHanded: 0,
    type: 'taxe',
    code: 'bal',
    wClass: '1hs',

    durability: 10,
    range: 0,
    speed: -10,

    reqDex: 57,

    maxStack: 130,
    quality: 16,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 0,

    id: 247,
    item_type: 'base_weapon',
  },
  {
    name: 'Javelin',
    minDam: 1,
    maxDam: 5,
    minThrowDmg: 6,
    maxThrowDmg: 14,

    isTwoHanded: 0,
    type: 'jave',
    code: 'jav',
    wClass: '1ht',

    durability: 2,
    range: 2,
    speed: -10,

    maxStack: 60,
    quality: 1,
    nightmareUpgrade: 'glv',
    hellUpgrade: 'glv',
    special: 'primarily thrown',
    filename: 'javelin.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 0,

    id: 248,
    item_type: 'base_weapon',
  },
  {
    name: 'Pilum',
    minDam: 4,
    maxDam: 9,
    minThrowDmg: 7,
    maxThrowDmg: 20,

    isTwoHanded: 0,
    type: 'jave',
    code: 'pil',
    wClass: '1ht',

    durability: 3,
    range: 2,
    speed: 0,

    reqDex: 45,

    maxStack: 50,
    quality: 10,
    nightmareUpgrade: 'tsp',
    hellUpgrade: 'tsp',
    special: 'primarily thrown',
    filename: 'pilum.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 0,

    id: 249,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Spear',
    minDam: 2,
    maxDam: 13,
    minThrowDmg: 10,
    maxThrowDmg: 22,

    isTwoHanded: 0,
    type: 'jave',
    code: 'ssp',
    wClass: '1ht',

    durability: 4,
    range: 2,
    speed: 10,
    reqStr: 40,
    reqDex: 40,

    maxStack: 40,
    quality: 15,
    nightmareUpgrade: 'tsp',
    hellUpgrade: 'tsp',
    special: 'primarily thrown',
    filename: 'shortspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 0,

    id: 250,
    item_type: 'base_weapon',
  },
  {
    name: 'Glaive',
    minDam: 5,
    maxDam: 17,
    minThrowDmg: 16,
    maxThrowDmg: 22,

    isTwoHanded: 0,
    type: 'jave',
    code: 'glv',
    wClass: '1ht',

    durability: 5,
    range: 2,
    speed: 20,
    reqStr: 52,
    reqDex: 35,

    maxStack: 40,
    quality: 23,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'glaive.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 0,

    id: 251,
    item_type: 'base_weapon',
  },
  {
    name: 'Throwing Spear',
    minDam: 5,
    maxDam: 15,
    minThrowDmg: 12,
    maxThrowDmg: 30,

    isTwoHanded: 0,
    type: 'jave',
    code: 'tsp',
    wClass: '1ht',

    durability: 6,
    range: 2,
    speed: -10,

    reqDex: 65,

    maxStack: 80,
    quality: 29,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 0,

    id: 252,
    item_type: 'base_weapon',
  },
  {
    name: 'Spear',

    twoHandMinDmg: 3,
    twoHandMaxDmg: 15,
    isTwoHanded: 1,
    type: 'spea',
    code: 'spr',
    wClass: '2ht',
    sockets: 3,
    durability: 30,
    range: 3,
    speed: -10,

    reqDex: 20,

    quality: 5,
    nightmareUpgrade: 'spt',
    hellUpgrade: 'pik',
    special: '2 square reach',
    filename: 'spear.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 253,
    item_type: 'base_weapon',
  },
  {
    name: 'Trident',

    twoHandMinDmg: 9,
    twoHandMaxDmg: 15,
    isTwoHanded: 1,
    type: 'spea',
    code: 'tri',
    wClass: '2ht',
    sockets: 4,
    durability: 35,
    range: 3,
    speed: 0,
    reqStr: 38,
    reqDex: 24,

    quality: 9,
    nightmareUpgrade: 'spt',
    hellUpgrade: 'pik',
    special: '2 square reach',
    filename: 'trident.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 254,
    item_type: 'base_weapon',
  },
  {
    name: 'Brandistock',

    twoHandMinDmg: 7,
    twoHandMaxDmg: 17,
    isTwoHanded: 1,
    type: 'spea',
    code: 'brn',
    wClass: '2ht',
    sockets: 5,
    durability: 28,
    range: 4,
    speed: -20,
    reqStr: 40,
    reqDex: 50,

    quality: 16,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'pik',
    special: '2 square reach',
    filename: 'brandistock.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 255,
    item_type: 'base_weapon',
  },
  {
    name: 'Spetum',

    twoHandMinDmg: 15,
    twoHandMaxDmg: 23,
    isTwoHanded: 1,
    type: 'spea',
    code: 'spt',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 54,
    reqDex: 35,

    quality: 20,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'spetum.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 256,
    item_type: 'base_weapon',
  },
  {
    name: 'Pike',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 63,
    isTwoHanded: 1,
    type: 'spea',
    code: 'pik',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 20,
    reqStr: 60,
    reqDex: 45,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'pike.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 257,
    item_type: 'base_weapon',
  },
  {
    name: 'Bardiche',

    twoHandMinDmg: 1,
    twoHandMaxDmg: 27,
    isTwoHanded: 1,
    type: 'pole',
    code: 'bar',
    wClass: 'stf',
    sockets: 3,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 40,

    quality: 5,
    nightmareUpgrade: 'hal',
    hellUpgrade: 'wsc',
    special: '2 square reach',
    filename: 'bardiche.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 258,
    item_type: 'base_weapon',
  },
  {
    name: 'Voulge',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 21,
    isTwoHanded: 1,
    type: 'pole',
    code: 'vou',
    wClass: 'stf',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 50,

    quality: 11,
    nightmareUpgrade: 'hal',
    hellUpgrade: 'wsc',
    special: '2 square reach',
    filename: 'voulge.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 259,
    item_type: 'base_weapon',
  },
  {
    name: 'Scythe',

    twoHandMinDmg: 8,
    twoHandMaxDmg: 20,
    isTwoHanded: 1,
    type: 'pole',
    code: 'scy',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 1,
    speed: -10,
    reqStr: 41,
    reqDex: 41,

    quality: 15,
    nightmareUpgrade: 'hal',
    hellUpgrade: 'wsc',
    special: '2 square reach',
    filename: 'scythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 260,
    item_type: 'base_weapon',
  },
  {
    name: 'Poleaxe',

    twoHandMinDmg: 18,
    twoHandMaxDmg: 39,
    isTwoHanded: 1,
    type: 'pole',
    code: 'pax',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 3,
    speed: 10,
    reqStr: 62,

    quality: 21,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'poleaxe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 261,
    item_type: 'base_weapon',
  },
  {
    name: 'Halberd',

    twoHandMinDmg: 12,
    twoHandMaxDmg: 45,
    isTwoHanded: 1,
    type: 'pole',
    code: 'hal',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: 0,
    reqStr: 75,
    reqDex: 47,

    quality: 29,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'halberd.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 262,
    item_type: 'base_weapon',
  },
  {
    name: 'War Scythe',

    twoHandMinDmg: 15,
    twoHandMaxDmg: 36,
    isTwoHanded: 1,
    type: 'pole',
    code: 'wsc',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: -10,
    reqStr: 80,
    reqDex: 80,

    quality: 34,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'warscythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 263,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Staff',

    twoHandMinDmg: 1,
    twoHandMaxDmg: 5,
    isTwoHanded: 1,
    type: 'staf',
    code: 'sst',
    wClass: 'stf',
    sockets: 2,
    durability: 20,
    range: 1,
    speed: -10,

    quality: 1,
    nightmareUpgrade: 'cst',
    hellUpgrade: 'bst',
    special: 'magically charged',
    filename: 'd2-shortstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 264,
    item_type: 'base_weapon',
  },
  {
    name: 'Long Staff',

    twoHandMinDmg: 2,
    twoHandMaxDmg: 8,
    isTwoHanded: 1,
    type: 'staf',
    code: 'lst',
    wClass: 'stf',
    sockets: 3,
    durability: 30,
    range: 1,
    speed: 0,

    quality: 8,
    nightmareUpgrade: 'cst',
    hellUpgrade: 'wst',
    special: 'magically charged',
    filename: 'd2-longstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 265,
    item_type: 'base_weapon',
  },
  {
    name: 'Gnarled Staff',

    twoHandMinDmg: 4,
    twoHandMaxDmg: 12,
    isTwoHanded: 1,
    type: 'staf',
    code: 'cst',
    wClass: 'stf',
    sockets: 4,
    durability: 35,
    range: 1,
    speed: 10,

    quality: 12,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'wst',
    special: 'magically charged',
    filename: 'gnarledstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 266,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Staff',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 13,
    isTwoHanded: 1,
    type: 'staf',
    code: 'bst',
    wClass: 'stf',
    sockets: 4,
    durability: 40,
    range: 1,
    speed: 0,

    quality: 17,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'battlestaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 267,
    item_type: 'base_weapon',
  },
  {
    name: 'War Staff',

    twoHandMinDmg: 12,
    twoHandMaxDmg: 28,
    isTwoHanded: 1,
    type: 'staf',
    code: 'wst',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 1,
    speed: 20,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 5,
    sockets_nm: 6,
    sockets_hell: 6,
    id: 268,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Bow',

    twoHandMinDmg: 1,
    twoHandMaxDmg: 4,
    isTwoHanded: 1,
    type: 'bow',
    code: 'sbw',
    wClass: 'bow',
    sockets: 3,

    range: 0,
    speed: 5,

    reqDex: 15,

    quality: 1,
    nightmareUpgrade: 'hbw',
    hellUpgrade: 'cbw',
    filename: 'shortbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 269,
    item_type: 'base_weapon',
  },
  {
    name: "Hunter's Bow",

    twoHandMinDmg: 2,
    twoHandMaxDmg: 6,
    isTwoHanded: 1,
    type: 'bow',
    code: 'hbw',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,

    reqDex: 28,

    quality: 5,
    nightmareUpgrade: 'lbw',
    hellUpgrade: 'sbb',
    filename: 'huntersbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 270,
    item_type: 'base_weapon',
  },
  {
    name: 'Long Bow',

    twoHandMinDmg: 3,
    twoHandMaxDmg: 10,
    isTwoHanded: 1,
    type: 'bow',
    code: 'lbw',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 22,
    reqDex: 19,

    quality: 8,
    nightmareUpgrade: 'cbw',
    hellUpgrade: 'lbb',
    filename: 'longbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 271,
    item_type: 'base_weapon',
  },
  {
    name: 'Composite Bow',

    twoHandMinDmg: 4,
    twoHandMaxDmg: 8,
    isTwoHanded: 1,
    type: 'bow',
    code: 'cbw',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,
    reqStr: 25,
    reqDex: 35,

    quality: 12,
    nightmareUpgrade: 'sbb',
    hellUpgrade: 'swb',
    filename: 'compositebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 272,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Battle Bow',

    twoHandMinDmg: 5,
    twoHandMaxDmg: 11,
    isTwoHanded: 1,
    type: 'bow',
    code: 'sbb',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 30,
    reqDex: 40,

    quality: 18,
    nightmareUpgrade: 'lbb',
    hellUpgrade: 'lwb',
    filename: 'shortbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 273,
    item_type: 'base_weapon',
  },
  {
    name: 'Long Battle Bow',

    twoHandMinDmg: 3,
    twoHandMaxDmg: 18,
    isTwoHanded: 1,
    type: 'bow',
    code: 'lbb',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 40,
    reqDex: 50,

    quality: 23,
    nightmareUpgrade: 'swb',
    hellUpgrade: 'lwb',
    filename: 'longbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 274,
    item_type: 'base_weapon',
  },
  {
    name: 'Short War Bow',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 14,
    isTwoHanded: 1,
    type: 'bow',
    code: 'swb',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 35,
    reqDex: 55,

    quality: 27,
    nightmareUpgrade: 'lwb',
    hellUpgrade: 'xxx',
    filename: 'shortwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 275,
    item_type: 'base_weapon',
  },
  {
    name: 'Long War Bow',

    twoHandMinDmg: 3,
    twoHandMaxDmg: 23,
    isTwoHanded: 1,
    type: 'bow',
    code: 'lwb',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 50,
    reqDex: 65,

    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 276,
    item_type: 'base_weapon',
  },
  {
    name: 'Light Crossbow',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 9,
    isTwoHanded: 1,
    type: 'xbow',
    code: 'lxb',
    wClass: 'xbw',
    sockets: 3,

    range: 0,
    speed: -10,
    reqStr: 21,
    reqDex: 27,

    quality: 6,
    nightmareUpgrade: 'mxb',
    hellUpgrade: 'rxb',
    special: 'reload lag between shots',
    filename: 'lightcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 277,
    item_type: 'base_weapon',
  },
  {
    name: 'Crossbow',

    twoHandMinDmg: 9,
    twoHandMaxDmg: 16,
    isTwoHanded: 1,
    type: 'xbow',
    code: 'mxb',
    wClass: 'xbw',
    sockets: 4,

    range: 0,
    speed: 0,
    reqStr: 40,
    reqDex: 33,

    quality: 15,
    nightmareUpgrade: 'hxb',
    hellUpgrade: 'hxb',
    special: 'reload lag between shots',
    filename: 'crossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 278,
    item_type: 'base_weapon',
  },
  {
    name: 'Heavy Crossbow',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 26,
    isTwoHanded: 1,
    type: 'xbow',
    code: 'hxb',
    wClass: 'xbw',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 60,
    reqDex: 40,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'heavycrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 279,
    item_type: 'base_weapon',
  },
  {
    name: 'Repeating Crossbow',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 12,
    isTwoHanded: 1,
    type: 'xbow',
    code: 'rxb',
    wClass: 'xbw',
    sockets: 5,

    range: 0,
    speed: -40,
    reqStr: 40,
    reqDex: 50,

    quality: 33,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'fires 5 shots before reload',
    filename: 'repeatingcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 280,
    item_type: 'base_weapon',
  },
  {
    name: 'Hatchet',
    minDam: 10,
    maxDam: 21,

    isTwoHanded: 0,
    type: 'axe',
    code: '9ha',
    wClass: '1hs',
    sockets: 2,
    durability: 28,
    range: 0,
    speed: 0,
    reqStr: 25,
    reqDex: 25,
    reqLvl: 19,

    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'handaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 281,
    item_type: 'base_weapon',
  },
  {
    name: 'Cleaver',
    minDam: 10,
    maxDam: 33,

    isTwoHanded: 0,
    type: 'axe',
    code: '9ax',
    wClass: '1hs',
    sockets: 4,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 68,

    reqLvl: 22,

    quality: 34,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'axed2.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 282,
    item_type: 'base_weapon',
  },
  {
    name: 'Twin Axe',
    minDam: 13,
    maxDam: 38,

    isTwoHanded: 0,
    type: 'axe',
    code: '92a',
    wClass: '1hs',
    sockets: 5,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 85,

    reqLvl: 25,

    quality: 39,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'doubleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 283,
    item_type: 'base_weapon',
  },
  {
    name: 'Crowbill',
    minDam: 14,
    maxDam: 34,

    isTwoHanded: 0,
    type: 'axe',
    code: '9mp',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 1,
    speed: -10,
    reqStr: 94,
    reqDex: 70,
    reqLvl: 25,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'militarypick.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 284,
    item_type: 'base_weapon',
  },
  {
    name: 'Naga',
    minDam: 16,
    maxDam: 45,

    isTwoHanded: 0,
    type: 'axe',
    code: '9wa',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 2,
    speed: 0,
    reqStr: 121,

    reqLvl: 25,

    quality: 48,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'waraxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 285,
    item_type: 'base_weapon',
  },
  {
    name: 'Military Axe',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 34,
    isTwoHanded: 1,
    type: 'axe',
    code: '9la',
    wClass: 'stf',
    sockets: 4,
    durability: 30,
    range: 1,
    speed: -10,
    reqStr: 73,

    reqLvl: 22,

    quality: 34,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'largeaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 286,
    item_type: 'base_weapon',
  },
  {
    name: 'Bearded Axe ',

    twoHandMinDmg: 21,
    twoHandMaxDmg: 49,
    isTwoHanded: 1,
    type: 'axe',
    code: '9ba',
    wClass: 'stf',
    sockets: 5,
    durability: 35,
    range: 1,
    speed: 0,
    reqStr: 92,

    reqLvl: 25,

    quality: 38,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'broadaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 287,
    item_type: 'base_weapon',
  },
  {
    name: 'Tabar',

    twoHandMinDmg: 24,
    twoHandMaxDmg: 77,
    isTwoHanded: 1,
    type: 'axe',
    code: '9bt',
    wClass: 'stf',
    sockets: 5,
    durability: 40,
    range: 1,
    speed: 10,
    reqStr: 101,

    reqLvl: 25,

    quality: 42,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'battleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 288,
    item_type: 'base_weapon',
  },
  {
    name: 'Gothic Axe',

    twoHandMinDmg: 18,
    twoHandMaxDmg: 70,
    isTwoHanded: 1,
    type: 'axe',
    code: '9ga',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: -10,
    reqStr: 115,
    reqDex: 79,
    reqLvl: 25,

    quality: 46,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greataxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 289,
    item_type: 'base_weapon',
  },
  {
    name: 'Ancient Axe',

    twoHandMinDmg: 43,
    twoHandMaxDmg: 85,
    isTwoHanded: 1,
    type: 'axe',
    code: '9gi',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 3,
    speed: 10,
    reqStr: 125,

    reqLvl: 25,

    quality: 51,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'giantaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 290,
    item_type: 'base_weapon',
  },
  {
    name: 'Burnt Wand',
    minDam: 8,
    maxDam: 18,

    isTwoHanded: 0,
    type: 'wand',
    code: '9wn',
    wClass: '1hs',
    sockets: 1,
    durability: 15,
    range: 0,
    speed: 0,
    reqStr: 25,

    reqLvl: 19,

    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'wand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 291,
    item_type: 'base_weapon',
  },
  {
    name: 'Petrified Wand',
    minDam: 8,
    maxDam: 24,

    isTwoHanded: 0,
    type: 'wand',
    code: '9yw',
    wClass: '1hs',
    sockets: 2,
    durability: 15,
    range: 0,
    speed: 10,
    reqStr: 25,

    reqLvl: 25,

    quality: 38,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'yewwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 292,
    item_type: 'base_weapon',
  },
  {
    name: 'Tomb Wand',
    minDam: 10,
    maxDam: 22,

    isTwoHanded: 0,
    type: 'wand',
    code: '9bw',
    wClass: '1hs',
    sockets: 2,
    durability: 15,
    range: 0,
    speed: -20,
    reqStr: 25,

    reqLvl: 25,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'bonewand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 293,
    item_type: 'base_weapon',
  },
  {
    name: 'Grave Wand',
    minDam: 13,
    maxDam: 29,

    isTwoHanded: 0,
    type: 'wand',
    code: '9gw',
    wClass: '1hs',
    sockets: 2,
    durability: 15,
    range: 0,
    speed: 0,
    reqStr: 25,

    reqLvl: 25,

    quality: 49,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'grimwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 294,
    item_type: 'base_weapon',
  },
  {
    name: 'Cudgel',
    minDam: 6,
    maxDam: 21,

    isTwoHanded: 0,
    type: 'club',
    code: '9cl',
    wClass: '1hs',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: -10,
    reqStr: 25,

    reqLvl: 18,

    quality: 30,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-club.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 295,
    item_type: 'base_weapon',
  },
  {
    name: 'Rune Scepter',
    minDam: 13,
    maxDam: 24,

    isTwoHanded: 0,
    type: 'scep',
    code: '9sc',
    wClass: '1hs',
    sockets: 2,
    durability: 50,
    range: 0,
    speed: 0,
    reqStr: 58,

    reqLvl: 19,

    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'scepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 296,
    item_type: 'base_weapon',
  },
  {
    name: 'Holy Water Sprinkler',
    minDam: 14,
    maxDam: 36,

    isTwoHanded: 0,
    type: 'scep',
    code: '9qs',
    wClass: '1hs',
    sockets: 3,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 76,

    reqLvl: 25,

    quality: 40,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'grandscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 297,
    item_type: 'base_weapon',
  },
  {
    name: 'Divine Scepter',
    minDam: 16,
    maxDam: 38,

    isTwoHanded: 0,
    type: 'scep',
    code: '9ws',
    wClass: '1hs',
    sockets: 5,
    durability: 70,
    range: 1,
    speed: -10,
    reqStr: 103,

    reqLvl: 25,

    quality: 45,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 298,
    item_type: 'base_weapon',
  },
  {
    name: 'Barbed Club',
    minDam: 13,
    maxDam: 25,

    isTwoHanded: 0,
    type: 'club',
    code: '9sp',
    wClass: '1hs',
    sockets: 3,
    durability: 36,
    range: 1,
    speed: 0,
    reqStr: 30,

    reqLvl: 20,

    quality: 32,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'spikedclub.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 299,
    item_type: 'base_weapon',
  },
  {
    name: 'Flanged Mace',
    minDam: 15,
    maxDam: 23,

    isTwoHanded: 0,
    type: 'mace',
    code: '9ma',
    wClass: '1hs',
    sockets: 2,
    durability: 60,
    range: 0,
    speed: 0,
    reqStr: 61,

    reqLvl: 23,

    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-mace.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 300,
    item_type: 'base_weapon',
  },
  {
    name: 'Jagged Star',
    minDam: 20,
    maxDam: 31,

    isTwoHanded: 0,
    type: 'mace',
    code: '9mt',
    wClass: '1hs',
    sockets: 3,
    durability: 72,
    range: 1,
    speed: 10,
    reqStr: 74,

    reqLvl: 25,

    quality: 39,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-morningstar.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 301,
    item_type: 'base_weapon',
  },
  {
    name: 'Knout',
    minDam: 13,
    maxDam: 35,

    isTwoHanded: 0,
    type: 'mace',
    code: '9fl',
    wClass: '1hs',
    sockets: 5,
    durability: 30,
    range: 2,
    speed: -10,
    reqStr: 82,
    reqDex: 73,
    reqLvl: 25,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-flail.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 302,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Hammer',
    minDam: 35,
    maxDam: 58,

    isTwoHanded: 0,
    type: 'hamm',
    code: '9wh',
    wClass: '1hs',
    sockets: 4,
    durability: 55,
    range: 0,
    speed: 20,
    reqStr: 100,

    reqLvl: 25,

    quality: 48,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'warhammer.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 303,
    item_type: 'base_weapon',
  },
  {
    name: 'War Club',

    twoHandMinDmg: 53,
    twoHandMaxDmg: 78,
    isTwoHanded: 1,
    type: 'hamm',
    code: '9m9',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 124,

    reqLvl: 25,

    quality: 45,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'maul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 304,
    item_type: 'base_weapon',
  },
  {
    name: 'Martel de Fer',

    twoHandMinDmg: 61,
    twoHandMaxDmg: 99,
    isTwoHanded: 1,
    type: 'hamm',
    code: '9gm',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 2,
    speed: 20,
    reqStr: 169,

    reqLvl: 25,

    quality: 53,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greatmaul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 305,
    item_type: 'base_weapon',
  },
  {
    name: 'Gladius',
    minDam: 8,
    maxDam: 22,

    isTwoHanded: 0,
    type: 'swor',
    code: '9ss',
    wClass: '1hs',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: 0,
    reqStr: 25,

    reqLvl: 18,

    quality: 30,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortswordd2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 306,
    item_type: 'base_weapon',
  },
  {
    name: 'Cutlass',
    minDam: 8,
    maxDam: 21,

    isTwoHanded: 0,
    type: 'swor',
    code: '9sm',
    wClass: '1hs',
    sockets: 2,
    durability: 22,
    range: 0,
    speed: -30,
    reqStr: 25,
    reqDex: 52,
    reqLvl: 25,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'scimitard2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 307,
    item_type: 'base_weapon',
  },
  {
    name: 'Shamshir',
    minDam: 10,
    maxDam: 24,

    isTwoHanded: 0,
    type: 'swor',
    code: '9sb',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: -10,
    reqStr: 58,
    reqDex: 58,
    reqLvl: 23,

    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'saber.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 308,
    item_type: 'base_weapon',
  },
  {
    name: 'Tulwar',
    minDam: 16,
    maxDam: 35,

    isTwoHanded: 0,
    type: 'swor',
    code: '9fc',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: 20,
    reqStr: 70,
    reqDex: 42,
    reqLvl: 25,

    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2falchion.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 309,
    item_type: 'base_weapon',
  },
  {
    name: 'Dimensional Blade',
    minDam: 13,
    maxDam: 35,

    isTwoHanded: 0,
    type: 'swor',
    code: '9cr',
    wClass: '1hs',
    sockets: 6,
    durability: 20,
    range: 1,
    speed: 0,
    reqStr: 85,
    reqDex: 60,
    reqLvl: 25,

    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'crystalsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 310,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Sword',
    minDam: 16,
    maxDam: 34,

    isTwoHanded: 0,
    type: 'swor',
    code: '9bs',
    wClass: '1hs',
    sockets: 4,
    durability: 32,
    range: 0,
    speed: 0,
    reqStr: 92,
    reqDex: 43,
    reqLvl: 25,

    quality: 40,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2broadsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 311,
    item_type: 'base_weapon',
  },
  {
    name: 'Rune Sword',
    minDam: 10,
    maxDam: 42,

    isTwoHanded: 0,
    type: 'swor',
    code: '9ls',
    wClass: '1hs',
    sockets: 4,
    durability: 44,
    range: 1,
    speed: -10,
    reqStr: 103,
    reqDex: 79,
    reqLvl: 25,

    quality: 44,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2longsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 312,
    item_type: 'base_weapon',
  },
  {
    name: 'Ancient Sword',
    minDam: 18,
    maxDam: 43,

    isTwoHanded: 0,
    type: 'swor',
    code: '9wd',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 1,
    speed: 0,
    reqStr: 127,
    reqDex: 88,
    reqLvl: 25,

    quality: 49,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'warsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 313,
    item_type: 'base_weapon',
  },
  {
    name: 'Espandon',
    minDam: 8,
    maxDam: 26,

    twoHandMinDmg: 18,
    twoHandMaxDmg: 40,
    isTwoHanded: 1,
    type: 'swor',
    code: '92h',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 2,
    speed: 0,
    reqStr: 73,
    reqDex: 61,
    reqLvl: 25,

    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: '2hsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,

    id: 314,
    item_type: 'base_weapon',
  },
  {
    name: 'Dacian Falx',
    minDam: 13,
    maxDam: 30,

    twoHandMinDmg: 26,
    twoHandMaxDmg: 61,
    isTwoHanded: 1,
    type: 'swor',
    code: '9cm',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 91,
    reqDex: 20,
    reqLvl: 25,

    quality: 42,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2claymore.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 315,
    item_type: 'base_weapon',
  },
  {
    name: 'Tusk Sword',
    minDam: 10,
    maxDam: 37,

    twoHandMinDmg: 19,
    twoHandMaxDmg: 58,
    isTwoHanded: 1,
    type: 'swor',
    code: '9gs',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 104,
    reqDex: 71,
    reqLvl: 25,

    quality: 45,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'giantsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 316,
    item_type: 'base_weapon',
  },
  {
    name: 'Gothic Sword',
    minDam: 14,
    maxDam: 40,

    twoHandMinDmg: 39,
    twoHandMaxDmg: 60,
    isTwoHanded: 1,
    type: 'swor',
    code: '9b9',
    wClass: '1hs',
    sockets: 4,
    durability: 40,
    range: 1,
    speed: 10,
    reqStr: 113,
    reqDex: 20,
    reqLvl: 25,

    quality: 48,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2bastardsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 317,
    item_type: 'base_weapon',
  },
  {
    name: 'Zweihander',
    minDam: 19,
    maxDam: 35,

    twoHandMinDmg: 29,
    twoHandMaxDmg: 54,
    isTwoHanded: 1,
    type: 'swor',
    code: '9fb',
    wClass: '1hs',
    sockets: 5,
    durability: 50,
    range: 2,
    speed: -10,
    reqStr: 125,
    reqDex: 94,
    reqLvl: 25,

    quality: 49,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'flamberge.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 318,
    item_type: 'base_weapon',
  },
  {
    name: 'Executioner Sword',
    minDam: 24,
    maxDam: 40,

    twoHandMinDmg: 47,
    twoHandMaxDmg: 80,
    isTwoHanded: 1,
    type: 'swor',
    code: '9gd',
    wClass: '1hs',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 170,
    reqDex: 110,
    reqLvl: 25,

    quality: 54,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2greatsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 319,
    item_type: 'base_weapon',
  },
  {
    name: 'Poignard',
    minDam: 6,
    maxDam: 18,

    isTwoHanded: 0,
    type: 'knif',
    code: '9dg',
    wClass: '1ht',
    sockets: 1,
    durability: 16,
    range: 0,
    speed: -20,
    reqStr: 25,

    reqLvl: 19,

    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-dagger.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 320,
    item_type: 'base_weapon',
  },
  {
    name: 'Rondel',
    minDam: 10,
    maxDam: 26,

    isTwoHanded: 0,
    type: 'knif',
    code: '9di',
    wClass: '1ht',
    sockets: 1,
    durability: 20,
    range: 0,
    speed: 0,
    reqStr: 25,
    reqDex: 58,
    reqLvl: 24,

    quality: 36,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'dirk.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 321,
    item_type: 'base_weapon',
  },
  {
    name: 'Cinquedeas',
    minDam: 15,
    maxDam: 31,

    isTwoHanded: 0,
    type: 'knif',
    code: '9kr',
    wClass: '1ht',
    sockets: 3,
    durability: 24,
    range: 0,
    speed: -20,
    reqStr: 25,
    reqDex: 88,
    reqLvl: 25,

    quality: 42,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'kris.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 322,
    item_type: 'base_weapon',
  },
  {
    name: 'Stiletto',
    minDam: 19,
    maxDam: 36,

    isTwoHanded: 0,
    type: 'knif',
    code: '9bl',
    wClass: '1ht',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: -10,
    reqStr: 47,
    reqDex: 97,
    reqLvl: 25,

    quality: 46,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-blade.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 1,

    id: 323,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Dart',
    minDam: 8,
    maxDam: 16,
    minThrowDmg: 11,
    maxThrowDmg: 24,

    isTwoHanded: 0,
    type: 'tkni',
    code: '9tk',
    wClass: '1ht',

    durability: 6,
    range: 0,
    speed: 0,
    reqStr: 25,
    reqDex: 52,
    reqLvl: 19,
    maxStack: 160,
    quality: 31,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 1,

    id: 324,
    item_type: 'base_weapon',
  },
  {
    name: 'Francisca',
    minDam: 11,
    maxDam: 22,
    minThrowDmg: 18,
    maxThrowDmg: 33,

    isTwoHanded: 0,
    type: 'taxe',
    code: '9ta',
    wClass: '1hs',

    durability: 15,
    range: 0,
    speed: 10,
    reqStr: 25,
    reqDex: 80,
    reqLvl: 22,
    maxStack: 130,
    quality: 34,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 1,

    id: 325,
    item_type: 'base_weapon',
  },
  {
    name: 'War Dart',
    minDam: 6,
    maxDam: 24,
    minThrowDmg: 14,
    maxThrowDmg: 27,

    isTwoHanded: 0,
    type: 'tkni',
    code: '9bk',
    wClass: '1ht',

    durability: 20,
    range: 0,
    speed: -20,
    reqStr: 25,
    reqDex: 97,
    reqLvl: 25,
    maxStack: 160,
    quality: 39,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 1,

    id: 326,
    item_type: 'base_weapon',
  },
  {
    name: 'Hurlbat',
    minDam: 13,
    maxDam: 27,
    minThrowDmg: 24,
    maxThrowDmg: 34,

    isTwoHanded: 0,
    type: 'taxe',
    code: '9b8',
    wClass: '1hs',

    durability: 16,
    range: 0,
    speed: -10,
    reqStr: 25,
    reqDex: 106,
    reqLvl: 25,
    maxStack: 130,
    quality: 41,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 1,

    id: 327,
    item_type: 'base_weapon',
  },
  {
    name: 'War Javelin',
    minDam: 6,
    maxDam: 19,
    minThrowDmg: 14,
    maxThrowDmg: 32,

    isTwoHanded: 0,
    type: 'jave',
    code: '9ja',
    wClass: '1ht',

    durability: 10,
    range: 2,
    speed: -10,
    reqStr: 25,
    reqDex: 25,
    reqLvl: 18,
    maxStack: 60,
    quality: 30,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'javelin.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 1,

    id: 328,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Pilum',
    minDam: 11,
    maxDam: 26,
    minThrowDmg: 16,
    maxThrowDmg: 42,

    isTwoHanded: 0,
    type: 'jave',
    code: '9pi',
    wClass: '1ht',

    durability: 12,
    range: 2,
    speed: 0,
    reqStr: 25,
    reqDex: 88,
    reqLvl: 25,
    maxStack: 50,
    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'pilum.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 1,

    id: 329,
    item_type: 'base_weapon',
  },
  {
    name: 'Simbilan',
    minDam: 8,
    maxDam: 32,
    minThrowDmg: 27,
    maxThrowDmg: 50,

    isTwoHanded: 0,
    type: 'jave',
    code: '9s9',
    wClass: '1ht',

    durability: 14,
    range: 2,
    speed: 10,
    reqStr: 80,
    reqDex: 80,
    reqLvl: 25,
    maxStack: 40,
    quality: 40,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'shortspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 1,

    id: 330,
    item_type: 'base_weapon',
  },
  {
    name: 'Spiculum',
    minDam: 13,
    maxDam: 38,
    minThrowDmg: 32,
    maxThrowDmg: 60,

    isTwoHanded: 0,
    type: 'jave',
    code: '9gl',
    wClass: '1ht',

    durability: 16,
    range: 2,
    speed: 20,
    reqStr: 98,
    reqDex: 73,
    reqLvl: 25,
    maxStack: 20,
    quality: 46,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'glaive.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 1,

    id: 331,
    item_type: 'base_weapon',
  },
  {
    name: 'Harpoon',
    minDam: 13,
    maxDam: 35,
    minThrowDmg: 18,
    maxThrowDmg: 54,

    isTwoHanded: 0,
    type: 'jave',
    code: '9ts',
    wClass: '1ht',

    durability: 18,
    range: 2,
    speed: -10,
    reqStr: 25,
    reqDex: 118,
    reqLvl: 25,
    maxStack: 80,
    quality: 51,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 1,

    id: 332,
    item_type: 'base_weapon',
  },
  {
    name: 'War Spear',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 37,
    isTwoHanded: 1,
    type: 'spea',
    code: '9sr',
    wClass: '2ht',
    sockets: 3,
    durability: 30,
    range: 3,
    speed: -10,
    reqStr: 25,
    reqDex: 25,
    reqLvl: 21,

    quality: 33,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'spear.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 333,
    item_type: 'base_weapon',
  },
  {
    name: 'Fuscina',

    twoHandMinDmg: 19,
    twoHandMaxDmg: 37,
    isTwoHanded: 1,
    type: 'spea',
    code: '9tr',
    wClass: '2ht',
    sockets: 4,
    durability: 35,
    range: 3,
    speed: 0,
    reqStr: 77,
    reqDex: 25,
    reqLvl: 24,

    quality: 36,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'trident.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 334,
    item_type: 'base_weapon',
  },
  {
    name: 'War Fork',

    twoHandMinDmg: 16,
    twoHandMaxDmg: 40,
    isTwoHanded: 1,
    type: 'spea',
    code: '9br',
    wClass: '2ht',
    sockets: 5,
    durability: 28,
    range: 4,
    speed: -20,
    reqStr: 80,
    reqDex: 95,
    reqLvl: 25,

    quality: 41,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'brandistock.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 335,
    item_type: 'base_weapon',
  },
  {
    name: 'Yari',

    twoHandMinDmg: 29,
    twoHandMaxDmg: 59,
    isTwoHanded: 1,
    type: 'spea',
    code: '9st',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 101,

    reqLvl: 25,

    quality: 44,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'spetum.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 336,
    item_type: 'base_weapon',
  },
  {
    name: 'Lance',

    twoHandMinDmg: 27,
    twoHandMaxDmg: 114,
    isTwoHanded: 1,
    type: 'spea',
    code: '9p9',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 20,
    reqStr: 110,
    reqDex: 88,
    reqLvl: 25,

    quality: 47,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'pike.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 337,
    item_type: 'base_weapon',
  },
  {
    name: 'Lochaber Axe',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 58,
    isTwoHanded: 1,
    type: 'pole',
    code: '9b7',
    wClass: 'stf',
    sockets: 3,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 80,

    reqLvl: 21,

    quality: 33,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'bardiche.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 338,
    item_type: 'base_weapon',
  },
  {
    name: 'Bill',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 53,
    isTwoHanded: 1,
    type: 'pole',
    code: '9vo',
    wClass: 'stf',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 95,

    reqLvl: 25,

    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'voulge.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 339,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Scythe',

    twoHandMinDmg: 18,
    twoHandMaxDmg: 45,
    isTwoHanded: 1,
    type: 'pole',
    code: '9s8',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 1,
    speed: -10,
    reqStr: 82,
    reqDex: 82,
    reqLvl: 25,

    quality: 40,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'scythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 340,
    item_type: 'base_weapon',
  },
  {
    name: 'Partizan',

    twoHandMinDmg: 34,
    twoHandMaxDmg: 75,
    isTwoHanded: 1,
    type: 'pole',
    code: '9pa',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 3,
    speed: 10,
    reqStr: 113,
    reqDex: 67,
    reqLvl: 23,

    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'poleaxe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 341,
    item_type: 'base_weapon',
  },
  {
    name: 'Bec-De-Corbin',

    twoHandMinDmg: 13,
    twoHandMaxDmg: 85,
    isTwoHanded: 1,
    type: 'pole',
    code: '9h9',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: 0,
    reqStr: 133,
    reqDex: 91,
    reqLvl: 25,

    quality: 51,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'halberd.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,

    id: 342,
    item_type: 'base_weapon',
  },
  {
    name: 'Grim Scythe',

    twoHandMinDmg: 30,
    twoHandMaxDmg: 70,
    isTwoHanded: 1,
    type: 'pole',
    code: '9wc',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: -10,
    reqStr: 140,
    reqDex: 140,
    reqLvl: 25,

    quality: 55,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'warscythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 343,
    item_type: 'base_weapon',
  },
  {
    name: 'Jo Staff',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 21,
    isTwoHanded: 1,
    type: 'staf',
    code: '8ss',
    wClass: 'stf',
    sockets: 2,
    durability: 20,
    range: 1,
    speed: -10,
    reqStr: 25,

    reqLvl: 18,

    quality: 30,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'd2-shortstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 344,
    item_type: 'base_weapon',
  },
  {
    name: 'Quarterstaff',

    twoHandMinDmg: 8,
    twoHandMaxDmg: 26,
    isTwoHanded: 1,
    type: 'staf',
    code: '8ls',
    wClass: 'stf',
    sockets: 3,
    durability: 30,
    range: 1,
    speed: 0,
    reqStr: 25,

    reqLvl: 23,

    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'd2-longstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 345,
    item_type: 'base_weapon',
  },
  {
    name: 'Cedar Staff',

    twoHandMinDmg: 11,
    twoHandMaxDmg: 32,
    isTwoHanded: 1,
    type: 'staf',
    code: '8cs',
    wClass: 'stf',
    sockets: 4,
    durability: 35,
    range: 1,
    speed: 10,
    reqStr: 25,

    reqLvl: 25,

    quality: 38,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'gnarledstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 346,
    item_type: 'base_weapon',
  },
  {
    name: 'Gothic Staff',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 34,
    isTwoHanded: 1,
    type: 'staf',
    code: '8bs',
    wClass: 'stf',
    sockets: 4,
    durability: 40,
    range: 1,
    speed: 0,
    reqStr: 25,

    reqLvl: 25,

    quality: 42,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'battlestaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 347,
    item_type: 'base_weapon',
  },
  {
    name: 'Rune Staff',

    twoHandMinDmg: 24,
    twoHandMaxDmg: 58,
    isTwoHanded: 1,
    type: 'staf',
    code: '8ws',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 1,
    speed: 20,
    reqStr: 25,

    reqLvl: 25,

    quality: 47,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 5,
    sockets_nm: 6,
    sockets_hell: 6,
    id: 348,
    item_type: 'base_weapon',
  },
  {
    name: 'Edge Bow',

    twoHandMinDmg: 6,
    twoHandMaxDmg: 19,
    isTwoHanded: 1,
    type: 'bow',
    code: '8sb',
    wClass: 'bow',
    sockets: 3,

    range: 0,
    speed: 5,
    reqStr: 25,
    reqDex: 43,
    reqLvl: 18,

    quality: 30,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 349,
    item_type: 'base_weapon',
  },
  {
    name: 'Razor Bow',

    twoHandMinDmg: 8,
    twoHandMaxDmg: 22,
    isTwoHanded: 1,
    type: 'bow',
    code: '8hb',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,
    reqStr: 25,
    reqDex: 62,
    reqLvl: 21,

    quality: 33,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'huntersbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 350,
    item_type: 'base_weapon',
  },
  {
    name: 'Cedar Bow',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 29,
    isTwoHanded: 1,
    type: 'bow',
    code: '8lb',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 53,
    reqDex: 49,
    reqLvl: 23,

    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 351,
    item_type: 'base_weapon',
  },
  {
    name: 'Double Bow',

    twoHandMinDmg: 11,
    twoHandMaxDmg: 26,
    isTwoHanded: 1,
    type: 'bow',
    code: '8cb',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,
    reqStr: 58,
    reqDex: 73,
    reqLvl: 25,

    quality: 39,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'compositebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 352,
    item_type: 'base_weapon',
  },
  {
    name: 'Short Siege Bow',

    twoHandMinDmg: 13,
    twoHandMaxDmg: 30,
    isTwoHanded: 1,
    type: 'bow',
    code: '8s8',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 65,
    reqDex: 80,
    reqLvl: 25,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 353,
    item_type: 'base_weapon',
  },
  {
    name: 'Long Siege Bow',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 42,
    isTwoHanded: 1,
    type: 'bow',
    code: '8l8',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 80,
    reqDex: 95,
    reqLvl: 25,

    quality: 46,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 354,
    item_type: 'base_weapon',
  },
  {
    name: 'Rune Bow',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 35,
    isTwoHanded: 1,
    type: 'bow',
    code: '8sw',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 73,
    reqDex: 103,
    reqLvl: 25,

    quality: 49,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 355,
    item_type: 'base_weapon',
  },
  {
    name: 'Gothic Bow',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 50,
    isTwoHanded: 1,
    type: 'bow',
    code: '8lw',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 95,
    reqDex: 118,
    reqLvl: 25,

    quality: 52,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 356,
    item_type: 'base_weapon',
  },
  {
    name: 'Arbalest',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 27,
    isTwoHanded: 1,
    type: 'xbow',
    code: '8lx',
    wClass: 'xbw',
    sockets: 3,

    range: 0,
    speed: -10,
    reqStr: 52,
    reqDex: 61,
    reqLvl: 22,

    quality: 34,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'lightcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 357,
    item_type: 'base_weapon',
  },
  {
    name: 'Siege Crossbow',

    twoHandMinDmg: 20,
    twoHandMaxDmg: 42,
    isTwoHanded: 1,
    type: 'xbow',
    code: '8mx',
    wClass: 'xbw',
    sockets: 4,

    range: 0,
    speed: 0,
    reqStr: 80,
    reqDex: 70,
    reqLvl: 25,

    quality: 40,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'crossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 358,
    item_type: 'base_weapon',
  },
  {
    name: 'Ballista',

    twoHandMinDmg: 33,
    twoHandMaxDmg: 55,
    isTwoHanded: 1,
    type: 'xbow',
    code: '8hx',
    wClass: 'xbw',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 110,
    reqDex: 80,
    reqLvl: 25,

    quality: 47,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'heavycrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 1,

    id: 359,
    item_type: 'base_weapon',
  },
  {
    name: 'Chu-Ko-Nu',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 32,
    isTwoHanded: 1,
    type: 'xbow',
    code: '8rx',
    wClass: 'xbw',
    sockets: 5,

    range: 0,
    speed: -60,
    reqStr: 80,
    reqDex: 95,
    reqLvl: 25,

    quality: 54,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'fires 5 shots before reload',
    filename: 'repeatingcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 360,
    item_type: 'base_weapon',
  },
  {
    name: 'Katar',
    minDam: 4,
    maxDam: 7,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'ktr',
    wClass: 'ht1',
    sockets: 2,
    durability: 48,
    range: 1,
    speed: -10,
    reqStr: 20,
    reqDex: 20,

    quality: 1,
    nightmareUpgrade: 'wrb',
    hellUpgrade: 'btl',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 361,
    item_type: 'base_weapon',
  },
  {
    name: 'Wrist Blade',
    minDam: 5,
    maxDam: 9,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'wrb',
    wClass: 'ht1',
    sockets: 2,
    durability: 52,
    range: 1,
    speed: 0,
    reqStr: 33,
    reqDex: 33,

    quality: 9,
    nightmareUpgrade: 'axf',
    hellUpgrade: 'skr',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 362,
    item_type: 'base_weapon',
  },
  {
    name: 'Hatchet Hands',
    minDam: 2,
    maxDam: 15,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'axf',
    wClass: 'ht1',
    sockets: 2,
    durability: 56,
    range: 1,
    speed: 10,
    reqStr: 37,
    reqDex: 37,

    quality: 12,
    nightmareUpgrade: 'ces',
    hellUpgrade: 'clw',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 363,
    item_type: 'base_weapon',
  },
  {
    name: 'Cestus',
    minDam: 7,
    maxDam: 15,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'ces',
    wClass: 'ht1',
    sockets: 2,
    durability: 72,
    range: 1,
    speed: 0,
    reqStr: 42,
    reqDex: 42,

    quality: 15,
    nightmareUpgrade: 'clw',
    hellUpgrade: 'btl',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 364,
    item_type: 'base_weapon',
  },
  {
    name: 'Claws',
    minDam: 8,
    maxDam: 15,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'clw',
    wClass: 'ht1',
    sockets: 3,
    durability: 64,
    range: 1,
    speed: -10,
    reqStr: 46,
    reqDex: 46,

    quality: 18,
    nightmareUpgrade: 'btl',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 365,
    item_type: 'base_weapon',
  },
  {
    name: 'Blade Talons',
    minDam: 10,
    maxDam: 14,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'btl',
    wClass: 'ht1',
    sockets: 3,
    durability: 69,
    range: 1,
    speed: -20,
    reqStr: 50,
    reqDex: 50,

    quality: 21,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 366,
    item_type: 'base_weapon',
  },
  {
    name: 'Scissors Katar',
    minDam: 9,
    maxDam: 17,

    isTwoHanded: 0,
    type: 'h2h',
    code: 'skr',
    wClass: 'ht1',
    sockets: 3,
    durability: 68,
    range: 1,
    speed: -10,
    reqStr: 55,
    reqDex: 55,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'scissorskatar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 367,
    item_type: 'base_weapon',
  },
  {
    name: 'Quhab',
    minDam: 11,
    maxDam: 24,

    isTwoHanded: 0,
    type: 'h2h',
    code: '9ar',
    wClass: 'ht1',
    sockets: 3,
    durability: 48,
    range: 1,
    speed: 0,
    reqStr: 57,
    reqDex: 57,
    reqLvl: 21,

    quality: 28,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 368,
    item_type: 'base_weapon',
  },
  {
    name: 'Wrist Spike',
    minDam: 13,
    maxDam: 27,

    isTwoHanded: 0,
    type: 'h2h',
    code: '9wb',
    wClass: 'ht1',
    sockets: 2,
    durability: 56,
    range: 1,
    speed: -10,
    reqStr: 66,
    reqDex: 66,
    reqLvl: 24,

    quality: 32,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 369,
    item_type: 'base_weapon',
  },
  {
    name: 'Fascia',
    minDam: 8,
    maxDam: 37,

    isTwoHanded: 0,
    type: 'h2h',
    code: '9xf',
    wClass: 'ht1',
    sockets: 2,
    durability: 64,
    range: 1,
    speed: 10,
    reqStr: 69,
    reqDex: 69,
    reqLvl: 27,

    quality: 36,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 370,
    item_type: 'base_weapon',
  },
  {
    name: 'Hand Scythe',
    minDam: 16,
    maxDam: 37,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '9cs',
    wClass: 'ht1',
    sockets: 2,
    durability: 72,
    range: 1,
    speed: -10,
    reqStr: 73,
    reqDex: 73,
    reqLvl: 30,

    quality: 41,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 371,
    item_type: 'base_weapon',
  },
  {
    name: 'Greater Claws',
    minDam: 18,
    maxDam: 37,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '9lw',
    wClass: 'ht1',
    sockets: 3,
    durability: 52,
    range: 1,
    speed: -20,
    reqStr: 76,
    reqDex: 76,
    reqLvl: 33,

    quality: 45,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 372,
    item_type: 'base_weapon',
  },
  {
    name: 'Greater Talons',
    minDam: 21,
    maxDam: 35,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '9tw',
    wClass: 'ht1',
    sockets: 3,
    durability: 69,
    range: 1,
    speed: -30,
    reqStr: 79,
    reqDex: 79,
    reqLvl: 37,

    quality: 50,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 373,
    item_type: 'base_weapon',
  },
  {
    name: 'Scissors Quhab',
    minDam: 19,
    maxDam: 40,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '9qr',
    wClass: 'ht1',
    sockets: 3,
    durability: 68,
    range: 1,
    speed: 0,
    reqStr: 82,
    reqDex: 82,
    reqLvl: 40,

    quality: 54,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'scissorskatar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 374,
    item_type: 'base_weapon',
  },
  {
    name: 'Suwayyah',
    minDam: 39,
    maxDam: 52,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7ar',
    wClass: 'ht1',
    sockets: 3,
    durability: 48,
    range: 1,
    speed: 0,
    reqStr: 99,
    reqDex: 99,
    reqLvl: 44,

    quality: 59,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 375,
    item_type: 'base_weapon',
  },
  {
    name: 'Wrist Sword',
    minDam: 34,
    maxDam: 45,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7wb',
    wClass: 'ht1',
    sockets: 3,
    durability: 56,
    range: 1,
    speed: -10,
    reqStr: 105,
    reqDex: 105,
    reqLvl: 46,

    quality: 62,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'katar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 376,
    item_type: 'base_weapon',
  },
  {
    name: 'War Fist',
    minDam: 44,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7xf',
    wClass: 'ht1',
    sockets: 2,
    durability: 64,
    range: 1,
    speed: 10,
    reqStr: 108,
    reqDex: 108,
    reqLvl: 51,

    quality: 68,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 377,
    item_type: 'base_weapon',
  },
  {
    name: 'Battle Cestus',
    minDam: 36,
    maxDam: 42,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7cs',
    wClass: 'ht1',
    sockets: 2,
    durability: 72,
    range: 1,
    speed: -10,
    reqStr: 110,
    reqDex: 110,
    reqLvl: 54,

    quality: 73,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'hatchethands.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 378,
    item_type: 'base_weapon',
  },
  {
    name: 'Feral Claws',
    minDam: 22,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7lw',
    wClass: 'ht1',
    sockets: 3,
    durability: 52,
    range: 1,
    speed: -20,
    reqStr: 113,
    reqDex: 113,
    reqLvl: 58,

    quality: 78,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 379,
    item_type: 'base_weapon',
  },
  {
    name: 'Runic Talons',
    minDam: 24,
    maxDam: 44,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7tw',
    wClass: 'ht1',
    sockets: 3,
    durability: 69,
    range: 1,
    speed: -30,
    reqStr: 115,
    reqDex: 115,
    reqLvl: 60,

    quality: 81,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claws.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 380,
    item_type: 'base_weapon',
  },
  {
    name: 'Scissors Suwayyah',
    minDam: 40,
    maxDam: 51,

    isTwoHanded: 0,
    type: 'h2h2',
    code: '7qr',
    wClass: 'ht1',
    sockets: 3,
    durability: 68,
    range: 1,
    speed: 0,
    reqStr: 118,
    reqDex: 118,
    reqLvl: 64,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'scissorskatar.gif',
    qualifiedType: 'Assassin Katars',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 381,
    item_type: 'base_weapon',
  },
  {
    name: 'Tomahawk',
    minDam: 33,
    maxDam: 58,

    isTwoHanded: 0,
    type: 'axe',
    code: '7ha',
    wClass: '1hs',
    sockets: 2,
    durability: 28,
    range: 0,
    speed: 0,
    reqStr: 125,
    reqDex: 67,
    reqLvl: 40,

    quality: 54,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'handaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 382,
    item_type: 'base_weapon',
  },
  {
    name: 'Small Crescent',
    minDam: 38,
    maxDam: 60,

    isTwoHanded: 0,
    type: 'axe',
    code: '7ax',
    wClass: '1hs',
    sockets: 4,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 115,
    reqDex: 83,
    reqLvl: 45,

    quality: 61,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'axed2.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 383,
    item_type: 'base_weapon',
  },
  {
    name: 'Ettin Axe',
    minDam: 33,
    maxDam: 66,

    isTwoHanded: 0,
    type: 'axe',
    code: '72a',
    wClass: '1hs',
    sockets: 5,
    durability: 24,
    range: 1,
    speed: 10,
    reqStr: 145,
    reqDex: 45,
    reqLvl: 52,

    quality: 70,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'doubleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 384,
    item_type: 'base_weapon',
  },
  {
    name: 'War Spike',
    minDam: 30,
    maxDam: 48,

    isTwoHanded: 0,
    type: 'axe',
    code: '7mp',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 1,
    speed: -10,
    reqStr: 133,
    reqDex: 54,
    reqLvl: 59,

    quality: 79,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'militarypick.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 385,
    item_type: 'base_weapon',
  },
  {
    name: 'Berserker Axe',
    minDam: 24,
    maxDam: 71,

    isTwoHanded: 0,
    type: 'axe',
    code: '7wa',
    wClass: '1hs',
    sockets: 6,
    durability: 26,
    range: 2,
    speed: 0,
    reqStr: 138,
    reqDex: 59,
    reqLvl: 64,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'waraxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 386,
    item_type: 'base_weapon',
  },
  {
    name: 'Feral Axe',

    twoHandMinDmg: 25,
    twoHandMaxDmg: 123,
    isTwoHanded: 1,
    type: 'axe',
    code: '7la',
    wClass: 'stf',
    sockets: 4,
    durability: 30,
    range: 2,
    speed: -15,
    reqStr: 196,

    reqLvl: 42,

    quality: 57,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'largeaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 387,
    item_type: 'base_weapon',
  },
  {
    name: 'Silver-Edged Axe',

    twoHandMinDmg: 62,
    twoHandMaxDmg: 110,
    isTwoHanded: 1,
    type: 'axe',
    code: '7ba',
    wClass: 'stf',
    sockets: 5,
    durability: 35,
    range: 2,
    speed: 0,
    reqStr: 166,
    reqDex: 65,
    reqLvl: 48,

    quality: 65,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'broadaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 388,
    item_type: 'base_weapon',
  },
  {
    name: 'Decapitator',

    twoHandMinDmg: 49,
    twoHandMaxDmg: 137,
    isTwoHanded: 1,
    type: 'axe',
    code: '7bt',
    wClass: 'stf',
    sockets: 5,
    durability: 40,
    range: 2,
    speed: 10,
    reqStr: 189,
    reqDex: 33,
    reqLvl: 54,

    quality: 73,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'battleaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 389,
    item_type: 'base_weapon',
  },
  {
    name: 'Champion Axe',

    twoHandMinDmg: 59,
    twoHandMaxDmg: 94,
    isTwoHanded: 1,
    type: 'axe',
    code: '7ga',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: -10,
    reqStr: 167,
    reqDex: 59,
    reqLvl: 61,

    quality: 82,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greataxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 390,
    item_type: 'base_weapon',
  },
  {
    name: 'Glorious Axe',

    twoHandMinDmg: 60,
    twoHandMaxDmg: 124,
    isTwoHanded: 1,
    type: 'axe',
    code: '7gi',
    wClass: 'stf',
    sockets: 6,
    durability: 50,
    range: 3,
    speed: 10,
    reqStr: 164,
    reqDex: 55,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'giantaxe.gif',
    qualifiedType: 'Axes',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 5,
    sockets_hell: 6,
    id: 391,
    item_type: 'base_weapon',
  },
  {
    name: 'Polished Wand',
    minDam: 18,
    maxDam: 33,

    isTwoHanded: 0,
    type: 'wand',
    code: '7wn',
    wClass: '1hs',
    sockets: 2,
    durability: 22,
    range: 0,
    speed: 0,
    reqStr: 25,

    reqLvl: 41,

    quality: 55,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'wand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 392,
    item_type: 'base_weapon',
  },
  {
    name: 'Ghost Wand',
    minDam: 20,
    maxDam: 40,

    isTwoHanded: 0,
    type: 'wand',
    code: '7yw',
    wClass: '1hs',
    sockets: 2,
    durability: 14,
    range: 0,
    speed: 10,
    reqStr: 25,

    reqLvl: 48,

    quality: 65,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'yewwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 393,
    item_type: 'base_weapon',
  },
  {
    name: 'Lich Wand',
    minDam: 10,
    maxDam: 31,

    isTwoHanded: 0,
    type: 'wand',
    code: '7bw',
    wClass: '1hs',
    sockets: 2,
    durability: 17,
    range: 0,
    speed: -20,
    reqStr: 25,

    reqLvl: 56,

    quality: 75,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'bonewand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 394,
    item_type: 'base_weapon',
  },
  {
    name: 'Unearthed Wand',
    minDam: 22,
    maxDam: 28,

    isTwoHanded: 0,
    type: 'wand',
    code: '7gw',
    wClass: '1hs',
    sockets: 2,
    durability: 18,
    range: 0,
    speed: 0,
    reqStr: 25,

    reqLvl: 64,

    quality: 86,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'grimwand.gif',
    qualifiedType: 'Wands',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 395,
    item_type: 'base_weapon',
  },
  {
    name: 'Truncheon',
    minDam: 35,
    maxDam: 43,

    isTwoHanded: 0,
    type: 'club',
    code: '7cl',
    wClass: '1hs',
    sockets: 2,
    durability: 55,
    range: 0,
    speed: -10,
    reqStr: 88,
    reqDex: 43,
    reqLvl: 39,

    quality: 52,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-club.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 396,
    item_type: 'base_weapon',
  },
  {
    name: 'Mighty Scepter',
    minDam: 40,
    maxDam: 52,

    isTwoHanded: 0,
    type: 'scep',
    code: '7sc',
    wClass: '1hs',
    sockets: 2,
    durability: 50,
    range: 0,
    speed: 0,
    reqStr: 125,
    reqDex: 65,
    reqLvl: 46,

    quality: 62,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'scepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 397,
    item_type: 'base_weapon',
  },
  {
    name: 'Seraph Rod',
    minDam: 45,
    maxDam: 54,

    isTwoHanded: 0,
    type: 'scep',
    code: '7qs',
    wClass: '1hs',
    sockets: 3,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 108,
    reqDex: 69,
    reqLvl: 57,

    quality: 76,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'grandscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 398,
    item_type: 'base_weapon',
  },
  {
    name: 'Caduceus',
    minDam: 37,
    maxDam: 43,

    isTwoHanded: 0,
    type: 'scep',
    code: '7ws',
    wClass: '1hs',
    sockets: 5,
    durability: 70,
    range: 1,
    speed: -10,
    reqStr: 97,
    reqDex: 70,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warscepter.gif',
    qualifiedType: 'Scepters',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 5,
    sockets_hell: 5,
    id: 399,
    item_type: 'base_weapon',
  },
  {
    name: 'Tyrant Club',
    minDam: 32,
    maxDam: 58,

    isTwoHanded: 0,
    type: 'club',
    code: '7sp',
    wClass: '1hs',
    sockets: 3,
    durability: 65,
    range: 1,
    speed: 0,
    reqStr: 133,

    reqLvl: 42,

    quality: 57,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'spikedclub.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 400,
    item_type: 'base_weapon',
  },
  {
    name: 'Reinforced Mace',
    minDam: 41,
    maxDam: 49,

    isTwoHanded: 0,
    type: 'mace',
    code: '7ma',
    wClass: '1hs',
    sockets: 2,
    durability: 60,
    range: 0,
    speed: 0,
    reqStr: 145,
    reqDex: 46,
    reqLvl: 47,

    quality: 63,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-mace.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 401,
    item_type: 'base_weapon',
  },
  {
    name: 'Devil Star',
    minDam: 43,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'mace',
    code: '7mt',
    wClass: '1hs',
    sockets: 3,
    durability: 72,
    range: 1,
    speed: 10,
    reqStr: 153,
    reqDex: 44,
    reqLvl: 52,

    quality: 70,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-morningstar.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 402,
    item_type: 'base_weapon',
  },
  {
    name: 'Scourge',
    minDam: 3,
    maxDam: 80,

    isTwoHanded: 0,
    type: 'mace',
    code: '7fl',
    wClass: '1hs',
    sockets: 5,
    durability: 65,
    range: 2,
    speed: -10,
    reqStr: 125,
    reqDex: 77,
    reqLvl: 57,

    quality: 76,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-flail.gif',
    qualifiedType: 'Maces',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 403,
    item_type: 'base_weapon',
  },
  {
    name: 'Legendary Mallet',
    minDam: 50,
    maxDam: 61,

    isTwoHanded: 0,
    type: 'hamm',
    code: '7wh',
    wClass: '1hs',
    sockets: 4,
    durability: 65,
    range: 1,
    speed: 20,
    reqStr: 189,

    reqLvl: 61,

    quality: 82,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'warhammer.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 404,
    item_type: 'base_weapon',
  },
  {
    name: 'Ogre Maul',

    twoHandMinDmg: 77,
    twoHandMaxDmg: 106,
    isTwoHanded: 1,
    type: 'hamm',
    code: '7m7',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 1,
    speed: 10,
    reqStr: 225,

    reqLvl: 51,

    quality: 69,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'maul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 405,
    item_type: 'base_weapon',
  },
  {
    name: 'Thunder Maul',

    twoHandMinDmg: 33,
    twoHandMaxDmg: 180,
    isTwoHanded: 1,
    type: 'hamm',
    code: '7gm',
    wClass: 'stf',
    sockets: 6,
    durability: 60,
    range: 2,
    speed: 20,
    reqStr: 253,

    reqLvl: 65,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'greatmaul.gif',
    qualifiedType: 'Hammers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 406,
    item_type: 'base_weapon',
  },
  {
    name: 'Falcata',
    minDam: 31,
    maxDam: 59,

    isTwoHanded: 0,
    type: 'swor',
    code: '7ss',
    wClass: '1hs',
    sockets: 2,
    durability: 24,
    range: 0,
    speed: 0,
    reqStr: 150,
    reqDex: 88,
    reqLvl: 42,

    quality: 56,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortswordd2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 407,
    item_type: 'base_weapon',
  },
  {
    name: 'Ataghan',
    minDam: 26,
    maxDam: 46,

    isTwoHanded: 0,
    type: 'swor',
    code: '7sm',
    wClass: '1hs',
    sockets: 2,
    durability: 22,
    range: 0,
    speed: -20,
    reqStr: 138,
    reqDex: 95,
    reqLvl: 45,

    quality: 61,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'scimitard2.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 408,
    item_type: 'base_weapon',
  },
  {
    name: 'Elegant Blade',
    minDam: 33,
    maxDam: 45,

    isTwoHanded: 0,
    type: 'swor',
    code: '7sb',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: -10,
    reqStr: 109,
    reqDex: 122,
    reqLvl: 47,

    quality: 63,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'saber.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 409,
    item_type: 'base_weapon',
  },
  {
    name: 'Hydra Edge',
    minDam: 28,
    maxDam: 68,

    isTwoHanded: 0,
    type: 'swor',
    code: '7fc',
    wClass: '1hs',
    sockets: 2,
    durability: 32,
    range: 0,
    speed: 10,
    reqStr: 142,
    reqDex: 105,
    reqLvl: 51,

    quality: 69,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2falchion.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 410,
    item_type: 'base_weapon',
  },
  {
    name: 'Phase Blade',
    minDam: 31,
    maxDam: 35,

    isTwoHanded: 0,
    type: 'swor',
    code: '7cr',
    wClass: '1hs',
    sockets: 6,

    range: 1,
    speed: -30,
    reqStr: 25,
    reqDex: 136,
    reqLvl: 54,

    quality: 73,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'crystalsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 411,
    item_type: 'base_weapon',
  },
  {
    name: 'Conquest Sword',
    minDam: 37,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'swor',
    code: '7bs',
    wClass: '1hs',
    sockets: 4,
    durability: 32,
    range: 0,
    speed: 0,
    reqStr: 142,
    reqDex: 112,
    reqLvl: 58,

    quality: 78,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2broadsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 412,
    item_type: 'base_weapon',
  },
  {
    name: 'Cryptic Sword',
    minDam: 5,
    maxDam: 77,

    isTwoHanded: 0,
    type: 'swor',
    code: '7ls',
    wClass: '1hs',
    sockets: 4,
    durability: 44,
    range: 1,
    speed: -10,
    reqStr: 99,
    reqDex: 109,
    reqLvl: 61,

    quality: 82,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2longsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 413,
    item_type: 'base_weapon',
  },
  {
    name: 'Mythical Sword',
    minDam: 40,
    maxDam: 50,

    isTwoHanded: 0,
    type: 'swor',
    code: '7wd',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 1,
    speed: 0,
    reqStr: 147,
    reqDex: 124,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'warsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 414,
    item_type: 'base_weapon',
  },
  {
    name: 'Legend Sword',
    minDam: 22,
    maxDam: 56,

    twoHandMinDmg: 50,
    twoHandMaxDmg: 94,
    isTwoHanded: 1,
    type: 'swor',
    code: '72h',
    wClass: '1hs',
    sockets: 3,
    durability: 44,
    range: 2,
    speed: -15,
    reqStr: 175,
    reqDex: 100,
    reqLvl: 44,

    quality: 59,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: '2hsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 415,
    item_type: 'base_weapon',
  },
  {
    name: 'Highland Blade',
    minDam: 22,
    maxDam: 62,

    twoHandMinDmg: 67,
    twoHandMaxDmg: 96,
    isTwoHanded: 1,
    type: 'swor',
    code: '7cm',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: -5,
    reqStr: 171,
    reqDex: 104,
    reqLvl: 49,

    quality: 66,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2claymore.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 416,
    item_type: 'base_weapon',
  },
  {
    name: 'Balrog Blade',
    minDam: 15,
    maxDam: 75,

    twoHandMinDmg: 55,
    twoHandMaxDmg: 118,
    isTwoHanded: 1,
    type: 'swor',
    code: '7gs',
    wClass: '1hs',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 185,
    reqDex: 87,
    reqLvl: 53,

    quality: 71,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'giantsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 417,
    item_type: 'base_weapon',
  },
  {
    name: 'Champion Sword',
    minDam: 24,
    maxDam: 54,

    twoHandMinDmg: 71,
    twoHandMaxDmg: 83,
    isTwoHanded: 1,
    type: 'swor',
    code: '7b7',
    wClass: '1hs',
    sockets: 4,
    durability: 40,
    range: 2,
    speed: -10,
    reqStr: 163,
    reqDex: 103,
    reqLvl: 57,

    quality: 77,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2bastardsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 418,
    item_type: 'base_weapon',
  },
  {
    name: 'Colossus Sword',
    minDam: 26,
    maxDam: 70,

    twoHandMinDmg: 61,
    twoHandMaxDmg: 121,
    isTwoHanded: 1,
    type: 'swor',
    code: '7fb',
    wClass: '1hs',
    sockets: 5,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 182,
    reqDex: 95,
    reqLvl: 60,

    quality: 80,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'flamberge.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,

    id: 419,
    item_type: 'base_weapon',
  },
  {
    name: 'Colossus Blade',
    minDam: 25,
    maxDam: 65,

    twoHandMinDmg: 58,
    twoHandMaxDmg: 115,
    isTwoHanded: 1,
    type: 'swor',
    code: '7gd',
    wClass: '1hs',
    sockets: 6,
    durability: 50,
    range: 2,
    speed: 5,
    reqStr: 189,
    reqDex: 110,
    reqLvl: 63,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2greatsword.gif',
    qualifiedType: 'Swords',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 420,
    item_type: 'base_weapon',
  },
  {
    name: 'Bone Knife',
    minDam: 23,
    maxDam: 49,

    isTwoHanded: 0,
    type: 'knif',
    code: '7dg',
    wClass: '1ht',
    sockets: 1,
    durability: 26,
    range: 0,
    speed: -20,
    reqStr: 38,
    reqDex: 75,
    reqLvl: 43,

    quality: 58,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-dagger.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 421,
    item_type: 'base_weapon',
  },
  {
    name: 'Mithril Point',
    minDam: 37,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'knif',
    code: '7di',
    wClass: '1ht',
    sockets: 1,
    durability: 55,
    range: 0,
    speed: 0,
    reqStr: 55,
    reqDex: 98,
    reqLvl: 52,

    quality: 70,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'dirk.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 1,
    sockets_nm: 1,
    sockets_hell: 1,
    id: 422,
    item_type: 'base_weapon',
  },
  {
    name: 'Fanged Knife',
    minDam: 15,
    maxDam: 57,

    isTwoHanded: 0,
    type: 'knif',
    code: '7kr',
    wClass: '1ht',
    sockets: 3,
    durability: 36,
    range: 0,
    speed: -20,
    reqStr: 42,
    reqDex: 86,
    reqLvl: 62,

    quality: 83,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'kris.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 423,
    item_type: 'base_weapon',
  },
  {
    name: 'Legend Spike',
    minDam: 31,
    maxDam: 47,

    isTwoHanded: 0,
    type: 'knif',
    code: '7bl',
    wClass: '1ht',
    sockets: 2,
    durability: 47,
    range: 0,
    speed: -10,
    reqStr: 65,
    reqDex: 67,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'd2-blade.gif',
    qualifiedType: 'Daggers',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 424,
    item_type: 'base_weapon',
  },
  {
    name: 'Flying Knife',
    minDam: 23,
    maxDam: 54,
    minThrowDmg: 23,
    maxThrowDmg: 54,

    isTwoHanded: 0,
    type: 'tkni',
    code: '7tk',
    wClass: '1ht',

    durability: 6,
    range: 0,
    speed: 0,
    reqStr: 48,
    reqDex: 141,
    reqLvl: 48,
    maxStack: 200,
    quality: 64,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 2,

    id: 425,
    item_type: 'base_weapon',
  },
  {
    name: 'Flying Axe',
    minDam: 17,
    maxDam: 65,
    minThrowDmg: 15,
    maxThrowDmg: 66,

    isTwoHanded: 0,
    type: 'taxe',
    code: '7ta',
    wClass: '1hs',

    durability: 15,
    range: 0,
    speed: 10,
    reqStr: 88,
    reqDex: 108,
    reqLvl: 42,
    maxStack: 180,
    quality: 56,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 2,

    id: 426,
    item_type: 'base_weapon',
  },
  {
    name: 'Winged Knife',
    minDam: 27,
    maxDam: 35,
    minThrowDmg: 23,
    maxThrowDmg: 39,

    isTwoHanded: 0,
    type: 'tkni',
    code: '7bk',
    wClass: '1ht',

    durability: 20,
    range: 0,
    speed: -20,
    reqStr: 45,
    reqDex: 142,
    reqLvl: 57,
    maxStack: 200,
    quality: 77,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedknife.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 2,

    id: 427,
    item_type: 'base_weapon',
  },
  {
    name: 'Winged Axe',
    minDam: 11,
    maxDam: 56,
    minThrowDmg: 7,
    maxThrowDmg: 60,

    isTwoHanded: 0,
    type: 'taxe',
    code: '7b8',
    wClass: '1hs',

    durability: 16,
    range: 0,
    speed: -10,
    reqStr: 96,
    reqDex: 122,
    reqLvl: 60,
    maxStack: 180,
    quality: 80,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'balancedaxe.gif',
    qualifiedType: 'Throwing',
    slotId: 3,
    quality_id: 2,

    id: 428,
    item_type: 'base_weapon',
  },
  {
    name: 'Hyperion Javelin',
    minDam: 21,
    maxDam: 57,
    minThrowDmg: 28,
    maxThrowDmg: 55,

    isTwoHanded: 0,
    type: 'jave',
    code: '7ja',
    wClass: '1ht',

    durability: 10,
    range: 2,
    speed: -10,
    reqStr: 98,
    reqDex: 123,
    reqLvl: 40,
    maxStack: 100,
    quality: 54,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'javelin.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 2,

    id: 429,
    item_type: 'base_weapon',
  },
  {
    name: 'Stygian Pilum',
    minDam: 14,
    maxDam: 64,
    minThrowDmg: 21,
    maxThrowDmg: 75,

    isTwoHanded: 0,
    type: 'jave',
    code: '7pi',
    wClass: '1ht',

    durability: 12,
    range: 2,
    speed: 0,
    reqStr: 118,
    reqDex: 112,
    reqLvl: 46,
    maxStack: 90,
    quality: 62,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'pilum.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 2,

    id: 430,
    item_type: 'base_weapon',
  },
  {
    name: 'Balrog Spear',
    minDam: 33,
    maxDam: 63,
    minThrowDmg: 40,
    maxThrowDmg: 62,

    isTwoHanded: 0,
    type: 'jave',
    code: '7s7',
    wClass: '1ht',

    durability: 14,
    range: 2,
    speed: 10,
    reqStr: 127,
    reqDex: 95,
    reqLvl: 53,
    maxStack: 80,
    quality: 71,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'shortspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 2,

    id: 431,
    item_type: 'base_weapon',
  },
  {
    name: 'Ghost Glaive',
    minDam: 19,
    maxDam: 60,
    minThrowDmg: 30,
    maxThrowDmg: 85,

    isTwoHanded: 0,
    type: 'jave',
    code: '7gl',
    wClass: '1ht',

    durability: 16,
    range: 2,
    speed: 20,
    reqStr: 89,
    reqDex: 137,
    reqLvl: 59,
    maxStack: 75,
    quality: 79,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'glaive.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 2,

    id: 432,
    item_type: 'base_weapon',
  },
  {
    name: 'Winged Harpoon',
    minDam: 27,
    maxDam: 35,
    minThrowDmg: 11,
    maxThrowDmg: 77,

    isTwoHanded: 0,
    type: 'jave',
    code: '7ts',
    wClass: '1ht',

    durability: 18,
    range: 2,
    speed: -10,
    reqStr: 76,
    reqDex: 145,
    reqLvl: 65,
    maxStack: 80,
    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'throwingspear.gif',
    qualifiedType: 'Javelins',
    slotId: 3,
    quality_id: 2,

    id: 433,
    item_type: 'base_weapon',
  },
  {
    name: 'Hyperion Spear',

    twoHandMinDmg: 35,
    twoHandMaxDmg: 119,
    isTwoHanded: 1,
    type: 'spea',
    code: '7sr',
    wClass: '2ht',
    sockets: 3,
    durability: 30,
    range: 3,
    speed: -10,
    reqStr: 155,
    reqDex: 120,
    reqLvl: 43,

    quality: 58,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'spear.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 434,
    item_type: 'base_weapon',
  },
  {
    name: 'Stygian Pike',

    twoHandMinDmg: 29,
    twoHandMaxDmg: 144,
    isTwoHanded: 1,
    type: 'spea',
    code: '7tr',
    wClass: '2ht',
    sockets: 4,
    durability: 35,
    range: 3,
    speed: 0,
    reqStr: 168,
    reqDex: 97,
    reqLvl: 49,

    quality: 66,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'trident.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 435,
    item_type: 'base_weapon',
  },
  {
    name: 'Mancatcher',

    twoHandMinDmg: 42,
    twoHandMaxDmg: 92,
    isTwoHanded: 1,
    type: 'spea',
    code: '7br',
    wClass: '2ht',
    sockets: 5,
    durability: 28,
    range: 4,
    speed: -20,
    reqStr: 132,
    reqDex: 134,
    reqLvl: 55,

    quality: 74,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'brandistock.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 436,
    item_type: 'base_weapon',
  },
  {
    name: 'Ghost Spear',

    twoHandMinDmg: 18,
    twoHandMaxDmg: 155,
    isTwoHanded: 1,
    type: 'spea',
    code: '7st',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 122,
    reqDex: 163,
    reqLvl: 62,

    quality: 83,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'spetum.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 437,
    item_type: 'base_weapon',
  },
  {
    name: 'War Pike',

    twoHandMinDmg: 33,
    twoHandMaxDmg: 178,
    isTwoHanded: 1,
    type: 'spea',
    code: '7p7',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 20,
    reqStr: 165,
    reqDex: 106,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'pike.gif',
    qualifiedType: 'Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 438,
    item_type: 'base_weapon',
  },
  {
    name: 'Ogre Axe',

    twoHandMinDmg: 28,
    twoHandMaxDmg: 145,
    isTwoHanded: 1,
    type: 'pole',
    code: '7o7',
    wClass: 'stf',
    sockets: 3,
    durability: 50,
    range: 2,
    speed: 0,
    reqStr: 195,
    reqDex: 75,
    reqLvl: 45,

    quality: 60,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'bardiche.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 439,
    item_type: 'base_weapon',
  },
  {
    name: 'Colossus Voulge',

    twoHandMinDmg: 17,
    twoHandMaxDmg: 165,
    isTwoHanded: 1,
    type: 'pole',
    code: '7vo',
    wClass: 'stf',
    sockets: 4,
    durability: 50,
    range: 2,
    speed: 10,
    reqStr: 210,
    reqDex: 55,
    reqLvl: 48,

    quality: 64,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'voulge.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 440,
    item_type: 'base_weapon',
  },
  {
    name: 'Thresher',

    twoHandMinDmg: 12,
    twoHandMaxDmg: 141,
    isTwoHanded: 1,
    type: 'pole',
    code: '7s8',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 1,
    speed: -10,
    reqStr: 152,
    reqDex: 118,
    reqLvl: 53,

    quality: 71,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'scythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 441,
    item_type: 'base_weapon',
  },
  {
    name: 'Cryptic Axe',

    twoHandMinDmg: 33,
    twoHandMaxDmg: 150,
    isTwoHanded: 1,
    type: 'pole',
    code: '7pa',
    wClass: 'stf',
    sockets: 5,
    durability: 65,
    range: 3,
    speed: 10,
    reqStr: 165,
    reqDex: 103,
    reqLvl: 59,

    quality: 79,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'poleaxe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 442,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Poleaxe',

    twoHandMinDmg: 46,
    twoHandMaxDmg: 127,
    isTwoHanded: 1,
    type: 'pole',
    code: '7h7',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: 0,
    reqStr: 179,
    reqDex: 99,
    reqLvl: 63,

    quality: 84,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'halberd.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 443,
    item_type: 'base_weapon',
  },
  {
    name: 'Giant Thresher',

    twoHandMinDmg: 40,
    twoHandMaxDmg: 114,
    isTwoHanded: 1,
    type: 'pole',
    code: '7wc',
    wClass: 'stf',
    sockets: 6,
    durability: 55,
    range: 4,
    speed: -10,
    reqStr: 188,
    reqDex: 140,
    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'warscythe.gif',
    qualifiedType: 'Polearms',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 444,
    item_type: 'base_weapon',
  },
  {
    name: 'Walking Stick',

    twoHandMinDmg: 69,
    twoHandMaxDmg: 85,
    isTwoHanded: 1,
    type: 'staf',
    code: '6ss',
    wClass: 'stf',
    sockets: 2,
    durability: 20,
    range: 1,
    speed: -10,
    reqStr: 25,

    reqLvl: 43,

    quality: 58,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'd2-shortstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 2,
    sockets_hell: 2,
    id: 445,
    item_type: 'base_weapon',
  },
  {
    name: 'Stalagmite',

    twoHandMinDmg: 75,
    twoHandMaxDmg: 107,
    isTwoHanded: 1,
    type: 'staf',
    code: '6ls',
    wClass: 'stf',
    sockets: 3,
    durability: 30,
    range: 1,
    speed: 10,
    reqStr: 63,
    reqDex: 35,
    reqLvl: 49,

    quality: 66,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'd2-longstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 446,
    item_type: 'base_weapon',
  },
  {
    name: 'Elder Staff',

    twoHandMinDmg: 80,
    twoHandMaxDmg: 93,
    isTwoHanded: 1,
    type: 'staf',
    code: '6cs',
    wClass: 'stf',
    sockets: 4,
    durability: 35,
    range: 1,
    speed: 0,
    reqStr: 44,
    reqDex: 37,
    reqLvl: 55,

    quality: 74,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'gnarledstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 4,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 447,
    item_type: 'base_weapon',
  },
  {
    name: 'Shillelagh',

    twoHandMinDmg: 65,
    twoHandMaxDmg: 108,
    isTwoHanded: 1,
    type: 'staf',
    code: '6bs',
    wClass: 'stf',
    sockets: 4,
    durability: 40,
    range: 1,
    speed: 0,
    reqStr: 52,
    reqDex: 27,
    reqLvl: 62,

    quality: 83,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'battlestaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 2,

    id: 448,
    item_type: 'base_weapon',
  },
  {
    name: 'Archon Staff',

    twoHandMinDmg: 83,
    twoHandMaxDmg: 99,
    isTwoHanded: 1,
    type: 'staf',
    code: '6ws',
    wClass: 'stf',
    sockets: 6,
    durability: 26,
    range: 1,
    speed: 10,
    reqStr: 34,

    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'magically charged',
    filename: 'warstaff.gif',
    qualifiedType: 'Staves',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 5,
    sockets_nm: 6,
    sockets_hell: 6,
    id: 449,
    item_type: 'base_weapon',
  },
  {
    name: 'Spider Bow',

    twoHandMinDmg: 23,
    twoHandMaxDmg: 50,
    isTwoHanded: 1,
    type: 'bow',
    code: '6sb',
    wClass: 'bow',
    sockets: 3,

    range: 0,
    speed: 5,
    reqStr: 64,
    reqDex: 143,
    reqLvl: 41,

    quality: 55,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 450,
    item_type: 'base_weapon',
  },
  {
    name: 'Blade Bow',

    twoHandMinDmg: 21,
    twoHandMaxDmg: 41,
    isTwoHanded: 1,
    type: 'bow',
    code: '6hb',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,
    reqStr: 76,
    reqDex: 119,
    reqLvl: 45,

    quality: 60,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'huntersbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 451,
    item_type: 'base_weapon',
  },
  {
    name: 'Shadow Bow',

    twoHandMinDmg: 15,
    twoHandMaxDmg: 59,
    isTwoHanded: 1,
    type: 'bow',
    code: '6lb',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 52,
    reqDex: 188,
    reqLvl: 47,

    quality: 63,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 452,
    item_type: 'base_weapon',
  },
  {
    name: 'Great Bow',

    twoHandMinDmg: 12,
    twoHandMaxDmg: 52,
    isTwoHanded: 1,
    type: 'bow',
    code: '6cb',
    wClass: 'bow',
    sockets: 4,

    range: 0,
    speed: -10,
    reqStr: 121,
    reqDex: 107,
    reqLvl: 51,

    quality: 68,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'compositebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 453,
    item_type: 'base_weapon',
  },
  {
    name: 'Diamond Bow',

    twoHandMinDmg: 33,
    twoHandMaxDmg: 40,
    isTwoHanded: 1,
    type: 'bow',
    code: '6s7',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 89,
    reqDex: 132,
    reqLvl: 54,

    quality: 72,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 454,
    item_type: 'base_weapon',
  },
  {
    name: 'Crusader Bow',

    twoHandMinDmg: 15,
    twoHandMaxDmg: 63,
    isTwoHanded: 1,
    type: 'bow',
    code: '6l7',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 97,
    reqDex: 121,
    reqLvl: 57,

    quality: 77,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longbattlebow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 455,
    item_type: 'base_weapon',
  },
  {
    name: 'Ward Bow',

    twoHandMinDmg: 20,
    twoHandMaxDmg: 53,
    isTwoHanded: 1,
    type: 'bow',
    code: '6sw',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 72,
    reqDex: 146,
    reqLvl: 60,

    quality: 80,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'shortwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 456,
    item_type: 'base_weapon',
  },
  {
    name: 'Hydra Bow',

    twoHandMinDmg: 10,
    twoHandMaxDmg: 68,
    isTwoHanded: 1,
    type: 'bow',
    code: '6lw',
    wClass: 'bow',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 134,
    reqDex: 167,
    reqLvl: 63,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'longwarbow.gif',
    qualifiedType: 'Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 457,
    item_type: 'base_weapon',
  },
  {
    name: 'Pellet Bow',

    twoHandMinDmg: 28,
    twoHandMaxDmg: 73,
    isTwoHanded: 1,
    type: 'xbow',
    code: '6lx',
    wClass: 'xbw',
    sockets: 3,

    range: 0,
    speed: -10,
    reqStr: 83,
    reqDex: 155,
    reqLvl: 42,

    quality: 57,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'lightcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 458,
    item_type: 'base_weapon',
  },
  {
    name: 'Gorgon Crossbow',

    twoHandMinDmg: 25,
    twoHandMaxDmg: 87,
    isTwoHanded: 1,
    type: 'xbow',
    code: '6mx',
    wClass: 'xbw',
    sockets: 4,

    range: 0,
    speed: 0,
    reqStr: 117,
    reqDex: 105,
    reqLvl: 50,

    quality: 67,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'crossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 4,
    id: 459,
    item_type: 'base_weapon',
  },
  {
    name: 'Colossus Crossbow',

    twoHandMinDmg: 32,
    twoHandMaxDmg: 91,
    isTwoHanded: 1,
    type: 'xbow',
    code: '6hx',
    wClass: 'xbw',
    sockets: 6,

    range: 0,
    speed: 10,
    reqStr: 163,
    reqDex: 77,
    reqLvl: 56,

    quality: 75,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'reload lag between shots',
    filename: 'heavycrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 460,
    item_type: 'base_weapon',
  },
  {
    name: 'Demon Crossbow',

    twoHandMinDmg: 26,
    twoHandMaxDmg: 40,
    isTwoHanded: 1,
    type: 'xbow',
    code: '6rx',
    wClass: 'xbw',
    sockets: 5,

    range: 0,
    speed: -60,
    reqStr: 141,
    reqDex: 98,
    reqLvl: 63,

    quality: 84,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'fires 5 shots before reload',
    filename: 'repeatingcrossbow.gif',
    qualifiedType: 'Crossbows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 461,
    item_type: 'base_weapon',
  },
  {
    name: 'Eagle Orb',
    minDam: 2,
    maxDam: 5,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob1',
    wClass: '1hs',
    sockets: 2,
    durability: 20,
    range: 0,
    speed: -10,

    quality: 1,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'eagleorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 462,
    item_type: 'base_weapon',
  },
  {
    name: 'Sacred Globe',
    minDam: 3,
    maxDam: 8,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob2',
    wClass: '1hs',
    sockets: 2,
    durability: 30,
    range: 0,
    speed: -10,

    quality: 8,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'sacredglobe.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 463,
    item_type: 'base_weapon',
  },
  {
    name: 'Smoked Sphere',
    minDam: 4,
    maxDam: 10,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob3',
    wClass: '1hs',
    sockets: 2,
    durability: 35,
    range: 0,
    speed: 0,

    reqLvl: 8,

    quality: 12,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'smokedsphere.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 464,
    item_type: 'base_weapon',
  },
  {
    name: 'Clasped Orb',
    minDam: 5,
    maxDam: 12,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob4',
    wClass: '1hs',
    sockets: 2,
    durability: 40,
    range: 0,
    speed: 0,

    reqLvl: 13,

    quality: 17,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claspedorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 465,
    item_type: 'base_weapon',
  },
  {
    name: "Jared's Stone",
    minDam: 8,
    maxDam: 18,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob5',
    wClass: '1hs',
    sockets: 3,
    durability: 50,
    range: 0,
    speed: 10,

    reqLvl: 18,

    quality: 24,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'jaredsstone.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 0,

    id: 466,
    item_type: 'base_weapon',
  },
  {
    name: 'Stag Bow',

    twoHandMinDmg: 7,
    twoHandMaxDmg: 12,
    isTwoHanded: 1,
    type: 'abow',
    code: 'am1',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 30,
    reqDex: 45,
    reqLvl: 14,

    quality: 18,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'stagbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 467,
    item_type: 'base_weapon',
  },
  {
    name: 'Reflex Bow',

    twoHandMinDmg: 9,
    twoHandMaxDmg: 19,
    isTwoHanded: 1,
    type: 'abow',
    code: 'am2',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 10,
    reqStr: 35,
    reqDex: 60,
    reqLvl: 20,

    quality: 27,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'reflexbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 468,
    item_type: 'base_weapon',
  },
  {
    name: 'Maiden Spear',

    twoHandMinDmg: 18,
    twoHandMaxDmg: 24,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'am3',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 54,
    reqDex: 40,
    reqLvl: 14,

    quality: 18,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'maidenspear.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 469,
    item_type: 'base_weapon',
  },
  {
    name: 'Maiden Pike',

    twoHandMinDmg: 23,
    twoHandMaxDmg: 55,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'am4',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 10,
    reqStr: 63,
    reqDex: 52,
    reqLvl: 20,

    quality: 27,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'maidenpike.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 0,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 470,
    item_type: 'base_weapon',
  },
  {
    name: 'Maiden Javelin',
    minDam: 8,
    maxDam: 14,
    minThrowDmg: 6,
    maxThrowDmg: 22,

    isTwoHanded: 0,
    type: 'ajav',
    code: 'am5',
    wClass: '1ht',

    durability: 6,
    range: 2,
    speed: -10,
    reqStr: 33,
    reqDex: 47,
    reqLvl: 17,
    maxStack: 80,
    quality: 23,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'maidenjavelin.gif',
    qualifiedType: 'Amazon Javelins',
    slotId: 3,
    quality_id: 0,

    id: 471,
    item_type: 'base_weapon',
  },
  {
    name: 'Glowing Orb',
    minDam: 8,
    maxDam: 21,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob6',
    wClass: '1hs',
    sockets: 2,
    durability: 20,
    range: 0,
    speed: -10,

    reqLvl: 24,

    quality: 32,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'eagleorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 472,
    item_type: 'base_weapon',
  },
  {
    name: 'Crystalline Globe',
    minDam: 10,
    maxDam: 26,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob7',
    wClass: '1hs',
    sockets: 2,
    durability: 30,
    range: 0,
    speed: -10,

    reqLvl: 27,

    quality: 37,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'sacredglobe.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 473,
    item_type: 'base_weapon',
  },
  {
    name: 'Cloudy Sphere',
    minDam: 11,
    maxDam: 29,

    isTwoHanded: 0,
    type: 'orb',
    code: 'ob8',
    wClass: '1hs',
    sockets: 2,
    durability: 35,
    range: 0,
    speed: 0,

    reqLvl: 30,

    quality: 41,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'smokedsphere.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 474,
    item_type: 'base_weapon',
  },
  {
    name: 'Sparkling Ball',
    minDam: 13,
    maxDam: 32,
    isTwoHanded: 0,
    type: 'orb',
    code: 'ob9',
    wClass: '1hs',
    sockets: 2,
    durability: 40,
    range: 0,
    speed: 0,

    reqLvl: 34,

    quality: 46,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claspedorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 475,
    item_type: 'base_weapon',
  },
  {
    name: 'Swirling Crystal',
    minDam: 18,
    maxDam: 42,

    isTwoHanded: 0,
    type: 'orb',
    code: 'oba',
    wClass: '1hs',
    sockets: 3,
    durability: 50,
    range: 0,
    speed: 10,

    reqLvl: 37,

    quality: 50,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'jaredsstone.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 476,
    item_type: 'base_weapon',
  },
  {
    name: 'Ashwood Bow',

    twoHandMinDmg: 16,
    twoHandMaxDmg: 29,
    isTwoHanded: 1,
    type: 'abow',
    code: 'am6',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 0,
    reqStr: 56,
    reqDex: 77,
    reqLvl: 29,

    quality: 39,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'stagbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 477,
    item_type: 'base_weapon',
  },
  {
    name: 'Ceremonial Bow',

    twoHandMinDmg: 19,
    twoHandMaxDmg: 41,
    isTwoHanded: 1,
    type: 'abow',
    code: 'am7',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 10,
    reqStr: 73,
    reqDex: 110,
    reqLvl: 35,

    quality: 47,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'reflexbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 478,
    item_type: 'base_weapon',
  },
  {
    name: 'Ceremonial Spear',

    twoHandMinDmg: 34,
    twoHandMaxDmg: 51,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'am8',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 101,
    reqDex: 80,
    reqLvl: 32,

    quality: 43,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'maidenspear.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 479,
    item_type: 'base_weapon',
  },
  {
    name: 'Ceremonial Pike',

    twoHandMinDmg: 42,
    twoHandMaxDmg: 101,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'am9',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 20,
    reqStr: 115,
    reqDex: 98,
    reqLvl: 38,

    quality: 51,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'maidenpike.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 1,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 480,
    item_type: 'base_weapon',
  },
  {
    name: 'Ceremonial Javelin',
    minDam: 18,
    maxDam: 35,
    minThrowDmg: 18,
    maxThrowDmg: 54,

    isTwoHanded: 0,
    type: 'ajav',
    code: 'ama',
    wClass: '1ht',

    durability: 6,
    range: 2,
    speed: -10,
    reqStr: 25,
    reqDex: 109,
    reqLvl: 26,
    maxStack: 80,
    quality: 35,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'maidenjavelin.gif',
    qualifiedType: 'Amazon Javelins',
    slotId: 3,
    quality_id: 1,

    id: 481,
    item_type: 'base_weapon',
  },
  {
    name: 'Heavenly Stone',
    minDam: 21,
    maxDam: 46,

    isTwoHanded: 0,
    type: 'orb',
    code: 'obb',
    wClass: '1hs',
    sockets: 2,
    durability: 20,
    range: 0,
    speed: -10,

    reqLvl: 44,

    quality: 59,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'eagleorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 482,
    item_type: 'base_weapon',
  },
  {
    name: 'Eldritch Orb',
    minDam: 18,
    maxDam: 50,

    isTwoHanded: 0,
    type: 'orb',
    code: 'obc',
    wClass: '1hs',
    sockets: 2,
    durability: 30,
    range: 0,
    speed: -10,

    reqLvl: 50,

    quality: 67,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'sacredglobe.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 483,
    item_type: 'base_weapon',
  },
  {
    name: 'Demon Heart',
    minDam: 23,
    maxDam: 55,

    isTwoHanded: 0,
    type: 'orb',
    code: 'obd',
    wClass: '1hs',
    sockets: 2,
    durability: 35,
    range: 0,
    speed: 0,

    reqLvl: 56,

    quality: 75,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'smokedsphere.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 484,
    item_type: 'base_weapon',
  },
  {
    name: 'Vortex Orb',
    minDam: 12,
    maxDam: 66,

    isTwoHanded: 0,
    type: 'orb',
    code: 'obe',
    wClass: '1hs',
    sockets: 2,
    durability: 40,
    range: 0,
    speed: 0,

    reqLvl: 63,

    quality: 84,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'claspedorb.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 485,
    item_type: 'base_weapon',
  },
  {
    name: 'Dimensional Shard',
    minDam: 30,
    maxDam: 53,

    isTwoHanded: 0,
    type: 'orb',
    code: 'obf',
    wClass: '1hs',
    sockets: 2,
    durability: 50,
    range: 0,
    speed: 10,

    reqLvl: 66,

    quality: 85,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'jaredsstone.gif',
    qualifiedType: 'Sorceress Orbs',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 2,
    sockets_nm: 3,
    sockets_hell: 3,
    id: 486,
    item_type: 'base_weapon',
  },
  {
    name: 'Matriarchal Bow',

    twoHandMinDmg: 20,
    twoHandMaxDmg: 47,
    isTwoHanded: 1,
    type: 'abow',
    code: 'amb',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: -10,
    reqStr: 87,
    reqDex: 187,
    reqLvl: 39,

    quality: 53,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'stagbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 487,
    item_type: 'base_weapon',
  },
  {
    name: 'Grand Matron Bow',

    twoHandMinDmg: 14,
    twoHandMaxDmg: 72,
    isTwoHanded: 1,
    type: 'abow',
    code: 'amc',
    wClass: 'bow',
    sockets: 5,

    range: 0,
    speed: 10,
    reqStr: 108,
    reqDex: 152,
    reqLvl: 58,

    quality: 78,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    filename: 'reflexbow.gif',
    qualifiedType: 'Amazon Bows',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 5,
    id: 488,
    item_type: 'base_weapon',
  },
  {
    name: 'Matriarchal Spear',

    twoHandMinDmg: 65,
    twoHandMaxDmg: 95,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'amd',
    wClass: '2ht',
    sockets: 6,
    durability: 28,
    range: 4,
    speed: 0,
    reqStr: 114,
    reqDex: 142,
    reqLvl: 45,

    quality: 61,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '2 square reach',
    filename: 'maidenspear.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 489,
    item_type: 'base_weapon',
  },
  {
    name: 'Matriarchal Pike',

    twoHandMinDmg: 37,
    twoHandMaxDmg: 153,
    isTwoHanded: 1,
    type: 'aspe',
    code: 'ame',
    wClass: '2ht',
    sockets: 6,
    durability: 25,
    range: 4,
    speed: 20,
    reqStr: 132,
    reqDex: 149,
    reqLvl: 60,

    quality: 81,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: '3 square reach',
    filename: 'maidenpike.gif',
    qualifiedType: 'Amazon Spears',
    slotId: 3,
    quality_id: 2,
    sockets_normal: 3,
    sockets_nm: 4,
    sockets_hell: 6,
    id: 490,
    item_type: 'base_weapon',
  },
  {
    name: 'Matriarchal Javelin',
    minDam: 30,
    maxDam: 54,
    minThrowDmg: 35,
    maxThrowDmg: 66,

    isTwoHanded: 0,
    type: 'ajav',
    code: 'amf',
    wClass: '1ht',

    durability: 6,
    range: 2,
    speed: -10,
    reqStr: 107,
    reqDex: 151,
    reqLvl: 48,
    maxStack: 80,
    quality: 65,
    nightmareUpgrade: 'xxx',
    hellUpgrade: 'xxx',
    special: 'primarily thrown',
    filename: 'maidenjavelin.gif',
    qualifiedType: 'Amazon Javelins',
    slotId: 3,
    quality_id: 2,

    id: 491,
    item_type: 'base_weapon',
  },
];

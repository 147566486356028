import React, { useMemo } from 'react';
import { StorageItem } from '../providers/ItemProvider/ItemProvider';
import { ItemCard, ItemFooter, ItemMuleName, ItemStats, ItemTitle } from './StyledComponents';
import { ItemImage } from './ItemImage';
import { IonIcon } from '@ionic/react';
import { swapHorizontal, trash } from 'ionicons/icons';
import { useDeleteMuleItem, useTransferMule } from '../providers/providerHooks';
import { ALL_GEMS } from '../data/gems/gems';

interface Props {
  item: StorageItem;
  noMuleName?: boolean;
  onDelete: () => void;
}

export const ViewStorageItem: React.FC<Props> = ({ item, noMuleName, onDelete }) => {
  const deleteItem = useDeleteMuleItem();
  const transfer = useTransferMule(item);

  const gem = useMemo(() => {
    if (item.item_type === 'gem') return ALL_GEMS.find(g => g.id == item.item_id);
  }, [item]);

  return (
    <ItemCard>
      {!noMuleName && <ItemMuleName name={item.mule_name} style={{ marginBottom: '10px' }} />}
      <ItemTitle color={''} className={item.item_type === 'gem' ? item.name ?? '' : item.item_type}>
        {item.name}
      </ItemTitle>

      <ItemImage storageItem={item} ethereal={item.ethereal} size={item.item_type === 'rune' ? '50px' : undefined} />
      <ItemStats>
        {item.sockets && (
          <div>
            <span>Sockets: </span> {item.sockets}
          </div>
        )}
        {item.item_type !== 'gem' && <div>{item.props}</div>}
        {item.item_type === 'gem' && (
          <div>
            <div>Armors/Helms: {gem?.props.armor}</div>
            <div>Shields: {gem?.props.shield}</div>
            <div>Weapons: {gem?.props.weapon}</div>
          </div>
        )}
      </ItemStats>
      <ItemFooter>
        <IonIcon icon={swapHorizontal} onClick={transfer} />
        <IonIcon
          icon={trash}
          color={'danger'}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            deleteItem(item).then(onDelete);
          }}
        />
      </ItemFooter>
    </ItemCard>
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { dropCalcBaseItems, dropCalcSetItems, dropCalcUniques } from '../../../common/data/drop-calc/Items';
import { DropCalcResultsTable } from '../../drop-calc/components/DropCalcResultsTable';
import { monsterClassName } from '../../drop-calc/components/DropCalcSettings';
import { useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { LocalStorage, SavedDropCalcData } from '../../../common/Storage';
import { appRoutes } from '../../../AppRoutes';

export const DropCalcHistory: React.FC = () => {
  const [data, setData] = useState<SavedDropCalcData | null>(null);
  const [dropCalcItemName, setDropCalcItemName] = useState<string>();
  const router = useIonRouter();

  useIonViewWillEnter(() => {
    console.log('view will enter');
    setData(LocalStorage.getSavedDropCalcData());
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (data) {
      switch (data.data.itemType) {
        case 0:
          setDropCalcItemName(
            dropCalcBaseItems
              .filter(it => it[1] === data.data.selectedQuality)
              .at(data.data.itemListIndex)
              ?.at(0) as string | undefined,
          );
          break;
        case 1:
          setDropCalcItemName(
            dropCalcUniques
              .filter(it => it[1] === data.data.selectedQuality)
              .at(data.data.itemListIndex)
              ?.at(0) as string | undefined,
          );
          break;
        case 2:
          setDropCalcItemName(
            dropCalcSetItems
              .filter(it => it[1] === data.data.selectedQuality)
              .at(data.data.itemListIndex)
              ?.at(0) as string | undefined,
          );
          break;
      }
    }
  }, [data]);

  return (
    <>
      {data && (
        <Container onClick={() => router.push(appRoutes.dropCalc.path())}>
          <Settings>
            <div>MF: {data.data.mf}</div>
            {data.segment === 'item' && (
              <div className={data.data.itemType === 0 ? 'base' : data.data.itemType === 1 ? 'unique' : 'set'}>{dropCalcItemName}</div>
            )}
            {data.segment === 'monster' && data.monsterName && (
              <div className={monsterClassName(data.data.monstertype)}>{data.monsterName}</div>
            )}
          </Settings>

          {data.results && <DropCalcResultsTable results={data.results} resultsSearch={''} data={data.data} />}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 6px;
`;

const Settings = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
`;

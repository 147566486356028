import React, { useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { useParams } from 'react-router';
import { BREAKPOINT_DATA, CLASS_TITLES } from '../../common/data/breakpoints/data';
import styled from 'styled-components/macro';
import { SectionWrapper } from '../../common/components/StyledComponents';
import { IonSegment, IonSegmentButton } from '@ionic/react';

export const Breakpoints: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const [segment, setSegment] = useState(type);

  return (
    <AppPage
      padding={true}
      title={`${CLASS_TITLES.at(~~segment) ?? ''}`}
      headerContent={
        ~~type >= 7 ? (
          <IonSegment value={segment} onIonChange={e => setSegment(e.target.value as string)} mode={'md'}>
            <IonSegmentButton value={'7'}>I</IonSegmentButton>
            <IonSegmentButton value={'8'}>II</IonSegmentButton>
            <IonSegmentButton value={'9'}>III</IonSegmentButton>
            <IonSegmentButton value={'10'}>V</IonSegmentButton>
          </IonSegment>
        ) : undefined
      }
    >
      <Container>
        {BREAKPOINT_DATA.at(~~segment)?.map(bpt => (
          <SectionWrapper key={`bp-1`} label={bpt.at(0) ?? ''}>
            {bpt.map((bp, i) => i > 0 && <Value key={`bp-1${bp}`}>{bp}</Value>)}
          </SectionWrapper>
        ))}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Value = styled.div`
  padding: 6px 20px;
  color: white;
`;

import React, { useContext, useRef } from 'react';
import styled from 'styled-components/macro';
import { UpdateContext } from '../../../common/providers/UpdateProvider';
import { IonButton, IonProgressBar, useIonRouter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorIsPro } from '../../../store/app/selectors';
import { appRoutes } from '../../../AppRoutes';

export const About: React.FC = () => {
  const { version, webUpdate, nativeUpdate, checkForUpdate, takeUserToNativeStore } = useContext(UpdateContext);
  const isPro = useAppSelector(selectorIsPro);
  const dispatch = useAppDispatch();
  const clicks = useRef(0);
  const clickTimeout = useRef<NodeJS.Timeout>();
  const router = useIonRouter();
  const handleClick = () => {
    console.log(clicks.current);
    if (clicks.current === 5) {
      router.push(appRoutes.debug.path());
    } else {
      clicks.current = clicks.current + 1;
    }
    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      clicks.current = 0;
    }, 600);
  };

  return (
    <Container>
      <div>
        <Header>Tome of D2</Header>
        <SubHeader onClick={handleClick}>Version: {version}</SubHeader>
        <Update>
          {webUpdate.available && (
            <>
              <div>An update is downloading ({webUpdate.progress}%)</div>
              <IonProgressBar value={webUpdate.progress / 100} />
            </>
          )}

          {!webUpdate.available && nativeUpdate && (
            <IonButton onClick={takeUserToNativeStore}>An update is available. Click here to update now.</IonButton>
          )}

          {!webUpdate.available && !nativeUpdate && <IonButton onClick={checkForUpdate}>Check for updates</IonButton>}
        </Update>
      </div>

      <Disclaimer>
        <div>All images and item text are copyright of Blizzard Entertainment</div>
        <br />
        <div>
          To report a copyright violation, report a bug or suggest a new feature please contact at{' '}
          <a href='mailto:dev.ajsoftware@gmail.com'>dev.ajsoftware@gmail.com</a>
        </div>
        <br/>
        <div>View the privacy policy <a rel={'noreferrer'} href={'http://alexanderjs.co.uk/Privacy-Policy-new.html'} target={'_blank'}>here</a></div>
      </Disclaimer>

      {/*<IonButton color={'success'} onClick={() => dispatch(purchasePro())}>*/}
      {/*  Remove Ads*/}
      {/*</IonButton>*/}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: center;
`;

const SubHeader = styled.div`
  color: #e8e8e8;
  font-size: 14px;
  text-align: center;
`;

const Update = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  margin-top: 30px;
`;

const Disclaimer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #9d9fa6;
`;

const RemoveAds = styled.div`
  width: 100%;
`;

export const CREATE_MULE_TABLE = `
    CREATE TABLE IF NOT EXISTS mules (
        mule_id INTEGER PRIMARY KEY NOT NULL,
        mule_name VARCHAR(255)
    );
`;

export const QUERY_ALL_MULES = `
  SELECT *,
    (SELECT COUNT(*) FROM items WHERE items.mule_id = mule_id)
        AS item_count
  FROM
    mules;
`;

export const INSERT_MULE = `
    INSERT INTO mules (mule_name) VALUES (?);
`;

export const UPDATE_MULE_NAME = (id: number, name: string) => `
    UPDATE mules SET mule_name = ${name} WHERE mule_id = ${id};
`;

export const DELETE_MULE = (id: number) => `
    DELETE FROM mules WHERE mule_id = ${id};
    DELETE FROM items WHERE mule_id = ${id};
`;

export const DROP_MULES_TABLE = `DROP TABLE mules`;

import React, { useEffect, useState } from 'react';
import { IonActionSheet, IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonRouter } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { Runewords } from './pages/runewords/Runewords';
import { RunewordBases } from './pages/runeword-bases/RunewordBases';
import { DropCalc } from './pages/drop-calc/DropCalc';
import { AttackCalc } from './pages/attack-calc/AttackCalc';
import { RunewordCalc } from './pages/runeword-calc/RunewordCalc';
import { RunewordCalcResults } from './pages/runeword-calc/RunewordCalcResults';
import { RunWalkCalc } from './pages/run-walk-calc/RunWalkCalc';
import { TabPages } from './pages/tab-pages/TabPages';
import { CubeRuneCombining } from './pages/horadric-cube/CubeRuneCombining';
import { CubeSocketRolling } from './pages/horadric-cube/CubeSocketRolling';
import { CubeBaseUpgrading } from './pages/horadric-cube/CubeBaseUpgrading';
import { CubeUtility } from './pages/horadric-cube/CubeUtility';
import { CubeCrafting } from './pages/horadric-cube/CubeCrafting';
import { CubeItemRolling } from './pages/horadric-cube/CubeItemRolling';
import {
  appsOutline,
  calculator,
  cubeSharp,
  folderOpen,
  gitMerge,
  handLeft,
  helpOutline,
  homeOutline,
  listOutline,
  settingsOutline,
  walkSharp,
} from 'ionicons/icons';
import { Breakpoints } from './pages/breakpoints/Breakpoints';
import { Areas } from './pages/areas/Areas';
import { Mules } from './pages/mules/Mules';
import { MuleDetailView } from './pages/mules/MuleDetailView';
import { UniqueCatalog } from './pages/item-catalog/UniqueCatalog';
import { CatalogView } from './pages/item-catalog/CatalogView';
import { SetCatalog } from './pages/item-catalog/SetCatalog';
import { BaseCatalog } from './pages/item-catalog/BaseCatalog';
import { Home } from './pages/home/Home';
import { HolyGrail } from './pages/holy-grail/HolyGrail';
import { SettingsModal } from './common/components/modals/SettingsModal';
import { Settings } from './pages/settings/Settings';
import { RuneCatalog } from './pages/item-catalog/RuneCatalog';
import { useLocation } from 'react-router';
import { AppInfo } from './pages/app-info/AppInfo';
import { LocalStorage } from './common/Storage';
import { HomeSection } from './store/settings/settingsSlice';
import { TerrorZonesPage } from './pages/terror-zones/TerrorZones';
import { useAppSelector } from './store/store';
import { selectorAppBarAction, selectorAppBarAction2, selectorPatch } from './store/settings/selectors';
import { Debug } from './pages/debug/Debug';
import { useRepairItems } from './common/providers/storageHooks';
import { AndroidBackButtonHandler } from './common/providers/AndroidBackButtonHandler';

export type SettingsHash = HomeSection | 'item-catalog' | 'crafting';

export const appRoutes = {
  home: {
    route: '/home',
    path: () => `/home`,
  },
  tabPages: {
    route: '/tab-pages',
    path: () => '/tab-pages',
  },
  runewords: {
    route: '/runewords',
    path: () => `runewords`,
  },
  runewordBases: {
    route: '/runeword-bases/:runewordId',
    path: (runewordId: number) => `/runeword-bases/${runewordId}`,
  },
  dropCalc: {
    route: '/drop-calc',
    path: () => `/drop-calc`,
  },
  attackCalc: {
    route: '/attack-calc',
    path: () => `/attack-calc`,
  },
  runewordCalc: {
    route: '/runeword-calc',
    path: () => `/runeword-calc`,
    children: {
      results: {
        route: '/runeword-calc/results/:section',
        path: (section: 'can' | 'almost') => `/runeword-calc/results/${section}`,
      },
    },
  },
  runWalkCalc: {
    route: '/run-walk-calc',
    path: () => '/run-walk-calc',
  },
  cube: {
    route: '/cube',
    children: {
      runeCombining: {
        route: '/cube/rune-combining',
        path: () => '/cube/rune-combining',
      },
      socketRolling: {
        route: '/cube/socket-rolling',
        path: () => '/cube/socket-rolling',
      },
      baseUpgrading: {
        route: '/cube/base-upgrading',
        path: () => '/cube/base-upgrading',
      },
      crafting: {
        route: '/cube/crafting',
        path: () => '/cube/crafting',
      },
      itemRolling: {
        route: '/cube/item-rolling',
        path: () => '/cube/item-rolling',
      },
      utility: {
        route: '/cube/utility',
        path: () => '/cube/utility',
      },
    },
  },
  breakpoints: {
    route: '/breakpoints/:type',
    path: (type: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) => `/breakpoints/${type}`,
  },
  area: {
    route: '/area/:act',
    path: (act: 1 | 2 | 3 | 4 | 5 | 'all') => `/area/${act}`,
  },
  mules: {
    route: '/mules',
    path: () => `/mules`,
    children: {
      detail: {
        route: '/mules/detail/:muleId',
        path: (muleId: number) => `/mules/detail/${muleId}`,
      },
    },
  },
  catalog: {
    route: '/catalog',
    children: {
      uniqueTypes: {
        route: '/catalog/uniqueTypes',
        path: () => `/catalog/uniqueTypes`,
      },
      setTypes: {
        route: '/catalog/setTypes',
        path: () => `/catalog/setTypes`,
      },
      baseTypes: {
        route: '/catalog/baseTypes',
        path: () => `/catalog/baseTypes`,
      },
      runes: {
        route: '/catalog/runes',
        path: () => '/catalog/runes',
      },
      view: {
        route: '/catalog/:categoryId/:category/:type',
        path: (categoryId: number, category: 'Unique' | 'Set' | 'Base Armor' | 'Base Weapon' | 'Rune', type: string) =>
          `/catalog/${categoryId}/${category}/${type}`,
      },
    },
  },
  holyGrail: {
    route: '/holy-grail',
    path: () => `/holy-grail`,
  },
  settings: {
    route: '/settings',
    path: (hash?: SettingsHash) => `/settings#${hash}`,
  },
  appInfo: {
    route: '/app-info',
    path: (hash?: 'about' | 'back-up' | 'change-log') => `/app-info${hash ? `#${hash}` : ''}`,
  },
  terrorZones: {
    route: '/terror-zones',
    path: () => '/terror-zones',
  },
  debug: {
    route: '/debug',
    path: () => `/debug`,
  },
};

export const AppRoutes: React.FC = () => {
  const [openAs, setOpenAs] = useState<string>();
  const router = useIonRouter();
  const location = useLocation();
  const actionButton = useAppSelector(selectorAppBarAction);
  const actionButton2 = useAppSelector(selectorAppBarAction2);
  console.log(actionButton, actionButton2, (actionButton || actionButton2) === 'Breakpoints');
  const patch = useAppSelector(selectorPatch);

  useRepairItems();

  useEffect(() => {
    const lastRoute = LocalStorage.getLastRoute();
    if (lastRoute) router.push(lastRoute);
  }, []);

  useEffect(() => {
    LocalStorage.setLastRoute(location.pathname);
  }, [location.pathname]);

  return (
    <IonTabs>
      <IonRouterOutlet id='main'>
        <Route exact path={appRoutes.home.route} component={Home} />
        <Route exact path={appRoutes.runewords.route} component={Runewords} />
        <Route exact path={appRoutes.runewordBases.route} component={RunewordBases} />
        <Route exact path={appRoutes.dropCalc.route} component={DropCalc} />
        <Route exact path={appRoutes.attackCalc.route} component={AttackCalc} />
        <Route exact path={appRoutes.runewordCalc.route} component={RunewordCalc} />
        <Route path={appRoutes.runewordCalc.children.results.route} component={RunewordCalcResults} />
        <Route exact path={appRoutes.runWalkCalc.route} component={RunWalkCalc} />
        <Route exact path={appRoutes.tabPages.route} component={TabPages} />
        <Route exact path={appRoutes.cube.children.runeCombining.route} component={CubeRuneCombining} />
        <Route exact path={appRoutes.cube.children.socketRolling.route} component={CubeSocketRolling} />
        <Route exact path={appRoutes.cube.children.baseUpgrading.route} component={CubeBaseUpgrading} />
        <Route exact path={appRoutes.cube.children.utility.route} component={CubeUtility} />
        <Route exact path={appRoutes.cube.children.crafting.route} component={CubeCrafting} />
        <Route exact path={appRoutes.cube.children.itemRolling.route} component={CubeItemRolling} />
        <Route exact path={appRoutes.breakpoints.route} component={Breakpoints} />
        <Route exact path={appRoutes.area.route} component={Areas} />
        <Route exact path={appRoutes.mules.route} component={Mules} />
        <Route exact path={appRoutes.mules.children.detail.route} component={MuleDetailView} />

        <Route exact path={appRoutes.catalog.children.uniqueTypes.route} component={UniqueCatalog} />
        <Route exact path={appRoutes.catalog.children.setTypes.route} component={SetCatalog} />
        <Route exact path={appRoutes.catalog.children.baseTypes.route} component={BaseCatalog} />
        <Route exact path={appRoutes.catalog.children.runes.route} component={RuneCatalog} />
        <Route path={appRoutes.catalog.children.view.route} component={CatalogView} />

        <Route exact path={appRoutes.holyGrail.route} component={HolyGrail} />

        <Route exact path={appRoutes.settings.route} component={Settings} />

        <Route exact path={appRoutes.appInfo.route} component={AppInfo} />

        <Route exact path={appRoutes.terrorZones.route} component={TerrorZonesPage} />

        <Route exact path={appRoutes.debug.route} component={Debug} />

        <Redirect exact from={'/'} to={appRoutes.home.route} />

        {/*<Route path={'/'} component={Home} />*/}
      </IonRouterOutlet>
      <IonTabBar slot={'bottom'} style={{ backgroundColor: 'black' }}>
        {(actionButton === 'Home Page' || actionButton2 === 'Home Page') && (
          <IonTabButton
            tab={actionButton === 'Home Page' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.home.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.home.route ? 'primary' : 'light'} icon={homeOutline} />
          </IonTabButton>
        )}
        {(actionButton === 'Runewords' || actionButton2 === 'Runewords') && (
          <IonTabButton
            tab={actionButton === 'Runewords' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.runewords.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.runewords.route ? 'primary' : 'light'} icon={'/images/icons/jah.svg'} />
          </IonTabButton>
        )}
        {(actionButton === 'Runeword Calculator' || actionButton2 === 'Runeword Calculator') && (
          <IonTabButton
            tab={actionButton === 'Runeword Calculator' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.runewordCalc.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.runewordCalc.route ? 'primary' : 'light'} icon={appsOutline} />
          </IonTabButton>
        )}
        {(actionButton === 'Drop Calculator' || actionButton2 === 'Drop Calculator') && (
          <IonTabButton
            tab={actionButton === 'Drop Calculator' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.dropCalc.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.dropCalc.route ? 'primary' : 'light'} icon={calculator} />
          </IonTabButton>
        )}
        {(actionButton === 'Mules' || actionButton2 === 'Mules') && (
          <IonTabButton
            tab={actionButton === 'Mules' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.mules.path(), 'root', 'replace')}
          >
            <IonIcon
              color={location.pathname === appRoutes.mules.route ? 'primary' : 'light'}
              icon={'/images/icons/mule.svg'}
              style={{ transform: 'scaleX(-1) rotate(-10deg)' }}
            />
          </IonTabButton>
        )}
        {(actionButton === 'Run/Walk Calculator' || actionButton2 === 'Run/Walk Calculator') && (
          <IonTabButton
            tab={actionButton === 'Run/Walk Calculator' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.runWalkCalc.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.runWalkCalc.route ? 'primary' : 'light'} icon={walkSharp} />
          </IonTabButton>
        )}
        {(actionButton === 'Attack Calculator' || actionButton2 === 'Attack Calculator') && (
          <IonTabButton
            tab={actionButton === 'Attack Calculator' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.attackCalc.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.attackCalc.route ? 'primary' : 'light'} icon={'/images/icons/attack.svg'} />
          </IonTabButton>
        )}
        {(actionButton === 'App Info' || actionButton2 === 'App Info') && (
          <IonTabButton
            tab={actionButton === 'App Info' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.appInfo.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.appInfo.route ? 'primary' : 'light'} icon={helpOutline} />
          </IonTabButton>
        )}
        {(actionButton === 'Terror Zones' || actionButton2 === 'Terror Zones') && patch >= 2 && (
          <IonTabButton
            tab={actionButton === 'Terror Zones' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.terrorZones.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.terrorZones.route ? 'primary' : 'light'} src={'/images/icons/terrorzone.svg'} />
          </IonTabButton>
        )}
        {(actionButton === 'Holy Grail' || actionButton2 === 'Holy Grail') && (
          <IonTabButton
            tab={actionButton === 'Holy Grail' ? 'tab1' : 'tab2'}
            onClick={() => router.push(appRoutes.holyGrail.path(), 'root', 'replace')}
          >
            <IonIcon color={location.pathname === appRoutes.holyGrail.route ? 'primary' : 'light'} icon={'/images/icons/grail.svg'} />
          </IonTabButton>
        )}
        {(actionButton === 'Item Catalog' || actionButton2 === 'Item Catalog') && (
          <IonTabButton tab={actionButton === 'Item Catalog' ? 'tab1' : 'tab2'} onClick={() => setOpenAs('item-catalog')}>
            <IonIcon color={location.pathname.includes(appRoutes.catalog.route) ? 'primary' : 'light'} icon={folderOpen} />
            <IonActionSheet
              isOpen={openAs === 'item-catalog'}
              onDidDismiss={() => setOpenAs(undefined)}
              mode={'md'}
              buttons={[
                {
                  text: 'Uniques',
                  handler: () => router.push(appRoutes.catalog.children.uniqueTypes.path(), 'forward', 'replace'),
                },
                {
                  text: 'Sets',
                  handler: () => router.push(appRoutes.catalog.children.setTypes.path(), 'forward', 'replace'),
                },
                {
                  text: 'Bases',
                  handler: () => router.push(appRoutes.catalog.children.baseTypes.path(), 'forward', 'replace'),
                },
                {
                  text: 'Runes',
                  handler: () => router.push(appRoutes.catalog.children.runes.path(), 'forward', 'replace'),
                },
              ]}
            />
          </IonTabButton>
        )}
        {(actionButton === 'Horadric Cube' || actionButton2 === 'Horadric Cube') && (
          <IonTabButton tab={actionButton === 'Horadric Cube' ? 'tab1' : 'tab2'} onClick={() => setOpenAs('cube')}>
            <IonIcon color={location.pathname.includes(appRoutes.cube.route) ? 'primary' : 'light'} icon={cubeSharp} />
            <IonActionSheet
              isOpen={openAs === 'cube'}
              onDidDismiss={() => setOpenAs(undefined)}
              mode={'md'}
              buttons={[
                {
                  text: 'Rune Combining',
                  handler: () => router.push(appRoutes.cube.children.runeCombining.path(), 'forward', 'replace'),
                },
                {
                  text: 'Socket Rolling',
                  handler: () => router.push(appRoutes.cube.children.socketRolling.path(), 'forward', 'replace'),
                },
                {
                  text: 'Base Upgrading',
                  handler: () => router.push(appRoutes.cube.children.baseUpgrading.path(), 'forward', 'replace'),
                },
                {
                  text: 'Utility Recipes',
                  handler: () => router.push(appRoutes.cube.children.utility.path(), 'forward', 'replace'),
                },
                {
                  text: 'Crafting',
                  handler: () => router.push(appRoutes.cube.children.crafting.path(), 'forward', 'replace'),
                },
                {
                  text: 'Item Rolling',
                  handler: () => router.push(appRoutes.cube.children.itemRolling.path(), 'forward', 'replace'),
                },
              ]}
            />
          </IonTabButton>
        )}
        {(actionButton === 'Breakpoints' || actionButton2 === 'Breakpoints') && (
          <IonTabButton tab={actionButton === 'Breakpoints' ? 'tab1' : 'tab2'} onClick={() => setOpenAs('breakpoints')}>
            <IonIcon color={location.pathname.includes(appRoutes.breakpoints.route) ? 'primary' : 'light'} icon={handLeft} />
            <IonActionSheet
              isOpen={openAs === 'breakpoints'}
              onDidDismiss={() => setOpenAs(undefined)}
              mode={'md'}
              buttons={[
                {
                  text: 'Amazon',
                  handler: () => router.push(appRoutes.breakpoints.path(0), 'forward', 'replace'),
                },
                {
                  text: 'Assassin',
                  handler: () => router.push(appRoutes.breakpoints.path(1), 'forward', 'replace'),
                },
                {
                  text: 'Barbarian',
                  handler: () => router.push(appRoutes.breakpoints.path(2), 'forward', 'replace'),
                },
                {
                  text: 'Druid',
                  handler: () => router.push(appRoutes.breakpoints.path(3), 'forward', 'replace'),
                },
                {
                  text: 'Necromancer',
                  handler: () => router.push(appRoutes.breakpoints.path(4), 'forward', 'replace'),
                },
                {
                  text: 'Paladin',
                  handler: () => router.push(appRoutes.breakpoints.path(5), 'forward', 'replace'),
                },
                {
                  text: 'Sorceress',
                  handler: () => router.push(appRoutes.breakpoints.path(6), 'forward', 'replace'),
                },
                {
                  text: 'Mercenaries',
                  handler: () => router.push(appRoutes.breakpoints.path(7), 'forward', 'replace'),
                },
              ]}
            />
          </IonTabButton>
        )}
        {(actionButton === 'Areas' || actionButton2 === 'Areas') && (
          <IonTabButton tab={actionButton === 'Areas' ? 'tab1' : 'tab2'} onClick={() => setOpenAs('areas')}>
            <IonIcon color={location.pathname.includes(appRoutes.area.route) ? 'primary' : 'light'} icon={gitMerge} />
            <IonActionSheet
              isOpen={openAs === 'areas'}
              onDidDismiss={() => setOpenAs(undefined)}
              mode={'md'}
              buttons={[
                {
                  text: 'Act 1',
                  handler: () => router.push(appRoutes.area.path(1), 'forward', 'replace'),
                },
                {
                  text: 'Act 2',
                  handler: () => router.push(appRoutes.area.path(2), 'forward', 'replace'),
                },
                {
                  text: 'Act 3',
                  handler: () => router.push(appRoutes.area.path(3), 'forward', 'replace'),
                },
                {
                  text: 'Act 4',
                  handler: () => router.push(appRoutes.area.path(4), 'forward', 'replace'),
                },
                {
                  text: 'Act 5',
                  handler: () => router.push(appRoutes.area.path(5), 'forward', 'replace'),
                },
              ]}
            />
          </IonTabButton>
        )}
        <IonTabButton tab={'tab3'} onClick={() => router.push(appRoutes.tabPages.path(), 'root')}>
          <IonIcon color={location.pathname === appRoutes.tabPages.route ? 'primary' : 'light'} icon={listOutline} />
        </IonTabButton>
        <IonTabButton tab={'tab4'} href={'#'}>
          <IonIcon
            color={location.pathname === appRoutes.settings.route ? 'primary' : 'light'}
            id={'settings-modal'}
            icon={settingsOutline}
          />
          <SettingsModal />
          <AndroidBackButtonHandler />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { AreasSection } from '../../pages/areas/Areas';
import { StorageItem } from '../../common/providers/ItemProvider/ItemProvider';

export const selectorRunewords = (state: RootState) => state.activeData.runewords;

export const selectorFindRuneword = createSelector(
  [(state: RootState) => state.activeData.runewords, (state: RootState, id?: number) => id],
  (runewords, id) => runewords.find(rw => rw.id === id),
);

export const selectorFindRunewords = createSelector(
  [(state: RootState, ids: number[]) => state.activeData.runewords, (state: RootState, ids: number[]) => ids],
  (runewords, ids) => runewords.filter(r => ids.includes(r.id)),
);

export const selectorArmorBases = createSelector([(state: RootState) => state.activeData.armorBases], a => a);
export const selectorWeaponBases = createSelector([(state: RootState) => state.activeData.weaponBases], w => w);

export const selectorDropCalcZones = (state: RootState) => state.activeData.dropCalcZones;
export const selectorArea = createSelector(
  [
    (state: RootState) => state.activeData.areas,
    (state: RootState, sort: 0 | 1) => sort,
    (state: RootState, sort: 0 | 1, difficulty: AreasSection) => difficulty,
    (state: RootState, sort: 0 | 1, difficulty: AreasSection, act: string) => act,
  ],
  (areas, sort, difficulty, act) => {
    if (difficulty === 'map') return undefined;
    if (act === 'all') {
      return [...areas].sort((a, b) =>
        sort === 0
          ? a.name.localeCompare(b.name)
          : difficulty === 'norm'
          ? b.normal_alvl - a.normal_alvl
          : difficulty === 'nm'
          ? b.nightmare_alvl - a.nightmare_alvl
          : b.hell_alvl - a.hell_alvl,
      );
    }
    return areas
      .filter(a => a.act === parseInt(act, 10))
      .sort((a, b) =>
        sort === 0
          ? a.name.localeCompare(b.name)
          : difficulty === 'norm'
          ? b.normal_alvl - a.normal_alvl
          : difficulty === 'nm'
          ? b.nightmare_alvl - a.nightmare_alvl
          : b.hell_alvl - a.hell_alvl,
      );
  },
);

export const selectorUniques = createSelector([(state: RootState) => state.activeData.uniques], uniques => uniques);
export const selectorSets = createSelector([(state: RootState) => state.activeData.sets], sets => sets);
export const selectorSetBonuses = (state: RootState) => state.activeData.setBonuses;

export const selectorFindUnique = createSelector(
  [(state: RootState) => state.activeData.uniques, (state: RootState, id: number | null) => id],
  (uniques, id) => uniques.find(u => u.id === id),
);

export const selectorFindSet = createSelector(
  [
    (state: RootState) => state.activeData,
    (state: RootState, itemId?: number) => itemId,
    (state: RootState, itemId?: number, setId?: number) => setId,
  ],
  ({ sets, setBonuses }, itemId, setId) => {
    if (itemId) {
      const item = sets.find(s => s.id === itemId);
      if (item !== undefined) {
        const items = sets.filter(s => s.set_id === item.set_id);
        const bonuses = setBonuses.find(b => b.set_id === item.set_id);
        return { items, bonuses };
      }
    }
    if (setId !== undefined) {
      const items = sets.filter(s => s.set_id === setId);
      const bonuses = setBonuses.find(b => b.set_id === setId);
      return { items, bonuses };
    }
    return { items: [], bonuses: undefined };
  },
);

export const selectorFindWeaponBase = createSelector(
  [(state: RootState, id?: number) => state.activeData.weaponBases, (state: RootState, id?: number) => id],
  (bases, id) => bases.find(b => b.id === id),
);
export const selectorFindArmorBase = createSelector(
  [(state: RootState, id?: number) => state.activeData.armorBases, (state: RootState, id?: number) => id],
  (bases, id) => bases.find(b => b.id === id),
);

export const selectorFindStorageItemBase = createSelector(
  [(state: RootState, item?: StorageItem) => state.activeData, (state: RootState, item?: StorageItem) => item],
  (activeData, item) => {
    if (!item) return undefined;
    switch (item.item_type) {
      case 'unique':
        return [activeData.uniques.find(u => u.id === item.item_id)];
      case 'set':
        return [activeData.sets.find(s => s.id === item.item_id)];
      case 'runeword': {
        const runeword = activeData.runewords.find(rw => rw.id === item.item_id);
        const base = activeData.weaponBases.find(wb => wb.id === item.base_id) ?? activeData.armorBases.find(ab => ab.id === item.base_id);
        return [runeword, base];
      }
      case 'rare':
      case 'magic':
        return [activeData.weaponBases.find(wb => wb.id === item.base_id) ?? activeData.armorBases.find(ab => ab.id === item.base_id)];
      case 'base_armor':
        return [activeData.armorBases.find(ab => ab.id === item.item_id)];
      case 'base_weapon':
        return [activeData.weaponBases.find(wb => wb.id === item.item_id)];
    }
  },
);

export const selectorTerrorZones = (state: RootState) => state.activeData.terrorZones;

import React, { useEffect, useState } from 'react';
import { capSQLiteJson, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { RuneProvider } from './RuneProvider/RuneProvider';
import { MuleProvider } from './MuleProvider/MuleProvider';
import { ItemProvider } from './ItemProvider/ItemProvider';
import { HolyGrailProvider } from './HolyGrailProvider/HolyGrailProvider';
import { useAppSelector } from '../../store/store';
import { selectorPlatform } from '../../store/app/selectors';
import { sqlite } from '../../App';

interface DbProvider {
  doBackUp: () => Promise<capSQLiteJson>;
}

export const DbContext = React.createContext({} as DbProvider);

export const ProvidersWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [db, setDb] = useState<SQLiteDBConnection>();
  const isWeb = useAppSelector(selectorPlatform) === 'web';

  useEffect(() => {
    if (!db) {
      initializeConnection();
    }
  }, [db]);

  async function initializeConnection() {
    await sqlite.checkConnectionsConsistency();
    const isConnected = await sqlite.isConnection('td2', false);
    if (isConnected.result) {
      await sqlite.closeConnection('td2', false);
    }

    const connection = await sqlite.createConnection('td2', false, 'no-encryption', 1, false);
    setDb(connection);
  }

  const handleBackUp = () => {
    if (db) {
      return db.exportToJson('full');
    }
    return Promise.reject();
  };

  return (
    <DbContext.Provider value={{ doBackUp: handleBackUp }}>
      <MuleProvider db={db} isWeb={isWeb}>
        <HolyGrailProvider db={db} isWeb={isWeb}>
          <ItemProvider db={db} isWeb={isWeb}>
            <RuneProvider db={db} isWeb={isWeb}>
              {children}
            </RuneProvider>
          </ItemProvider>
        </HolyGrailProvider>
      </MuleProvider>
    </DbContext.Provider>
  );
};

import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { useAppSelector } from '../../store/store';
import { selectorHomeSettings } from '../../store/settings/selectors';
import { Section } from './components/Section';

export const Home: React.FC = () => {
  const homeSettings = useAppSelector(selectorHomeSettings);

  return (
    <AppPage noHeader={true} padding={true}>
      <Container>
        {homeSettings.order.order.map(o => (
          <Section key={`home-section-${o}`} section={o} />
        ))}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

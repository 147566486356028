import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { IonAccordion, IonIcon, IonItem, IonLabel, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { eye, eyeOff } from 'ionicons/icons';

interface Props {
  value: 'order';
}

export const HomeOrder: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    dispatch(
      setHomeSettings({
        ...homeSettings,
        order: {
          ...homeSettings.order,
          order: arrayMove([...homeSettings.order.order], event.detail.from, event.detail.to),
        },
      }),
    );

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  }

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Re-order Home</IonLabel>
      </IonItem>
      <IonReorderGroup slot={'content'} disabled={false} onIonItemReorder={handleReorder}>
        {homeSettings.order.order.map(o =>
          o === 'order' ? null : (
            <IonItem key={`reorder-${o}`}>
              <IonIcon slot={'start'} icon={homeSettings[o].show ? eye : eyeOff} color={homeSettings[o].show ? 'light' : 'medium'} />
              <IonLabel className={homeSettings[o].show ? 'white' : 'muted'}>{mapOrderLabel(o)}</IonLabel>
              <IonReorder slot={'end'} />
            </IonItem>
          ),
        )}
      </IonReorderGroup>
    </IonAccordion>
  );
};

function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

const mapOrderLabel = (order: HomeSection) => {
  switch (order) {
    case 'game':
      return 'Game Settings';
    case 'grail':
      return 'Holy Grail';
    case 'dropCalc':
      return 'Drop Calculator';
    case 'mule':
      return 'Mules';
    case 'runes':
      return 'Runes';
    case 'faveRunewords':
      return 'Favorite Runewords';
  }
};

import React, { useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { IonIcon, IonSegment, IonSegmentButton, useIonRouter } from '@ionic/react';
import styled from 'styled-components/macro';
import { Rune } from '../../common/components/Rune';
import { ItemImage } from '../../common/components/ItemImage';
import { GemImage } from '../../common/components/GemImage';
import { blood_recipes, caster_recipes, Craft, hitpower_recipes, safety_recipes } from '../../common/data/cube/CraftingRecipes';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings } from '../../store/settings/selectors';

export const CubeCrafting: React.FC = () => {
  const [section, setSection] = useState('blood');
  const router = useIonRouter();

  useEffect(() => {
    console.log(section);
  }, [section]);

  return (
    <AppPage
      padding={true}
      title={'Crafting'}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
      headerContent={
        <IonSegment value={section} onIonChange={e => setSection(e.detail.value as string)} mode={'md'}>
          <IonSegmentButton value={'blood'}>Blood</IonSegmentButton>
          <IonSegmentButton value={'caster'}>Caster</IonSegmentButton>
          <IonSegmentButton value={'hitpower'}>Hit Power</IonSegmentButton>
          <IonSegmentButton value={'safety'}>Safety</IonSegmentButton>
        </IonSegment>
      }
    >
      <Container>
        {section === 'blood' && (
          <>
            {blood_recipes.map(r => (
              <CraftItem key={`blood-${r.name}`} item={r} />
            ))}
          </>
        )}

        {section === 'caster' && (
          <>
            {caster_recipes.map(r => (
              <CraftItem key={`caster-${r.name}`} item={r} />
            ))}
          </>
        )}

        {section === 'hitpower' && (
          <>
            {hitpower_recipes.map(r => (
              <CraftItem key={`hit-${r.name}`} item={r} />
            ))}
          </>
        )}

        {section === 'safety' && (
          <>
            {safety_recipes.map(r => (
              <CraftItem key={`safety-${r.name}`} item={r} />
            ))}
          </>
        )}
      </Container>
    </AppPage>
  );
};

const Container = styled.div``;

const CraftItem: React.FC<{ item: Craft }> = ({ item }) => {
  const settings = useAppSelector(selectorCraftingSettings);
  return (
    <Item>
      <h1 className={'magic'} dangerouslySetInnerHTML={{ __html: item.base_item }} />
      <div>
        <Rune
          id={item.rune}
          size={'45px'}
          withName={settings.showRuneNames}
          withCount={settings.showRuneCount}
          forceInStock={!settings.showRunesInStock}
        />
        <FlexCol>
          <ItemImage miscName={'Jewel'} size={'50px'} />
          <span className={'magic'}>Magic Jewel</span>
        </FlexCol>
        <GemImage {...item.gem} withName={settings.showGemNames} size={'50px'} />
      </div>
      <span dangerouslySetInnerHTML={{ __html: item.result }} />
      {item.notes && (
        <div style={{ fontSize: '12px' }} className={'muted'}>
          {item.notes}
        </div>
      )}
    </Item>
  );
};

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  gap: 10px;
  font-size: 14px;
  color: white;
  padding: 20px 0;

  > h1 {
    margin: -6px 0 0;
    font-size: 18px;
    text-align: center;
  }

  > span {
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    font-size: 20px;
  }

  & + & {
    border-top: 1px solid var(--border-color);
  }
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
`;

export const dropCalcZones = [
  ['Act 1 Blood Moor', 1, 36, 67, 340, 355, 370], // 0
  ['Act 1 Cold Plains', 2, 36, 68, 340, 355, 370], // 1
  ['Act 1 Stony Field', 4, 37, 68, 340, 355, 370], // 2
  ['Act 1 Dark Wood', 5, 38, 68, 341, 356, 370], // 3
  ['Act 1 Black Marsh', 6, 38, 69, 341, 356, 371], // 4
  ['Act 1 Tamoe Highland', 8, 39, 69, 341, 356, 371], // 5
  ['Act 1 Den of Evil', 1, 36, 79, 340, 355, 372], // 6
  ['Act 1 Cave Level 1', 2, 36, 77, 340, 355, 372], // 7
  ['Act 1 Underground Passage Level 1', 4, 37, 69, 340, 355, 371], // 8
  ['Act 1 Hole Level 1', 5, 38, 80, 341, 356, 372], // 9
  ['Act 1 Pit Level 1', 7, 39, 85, 341, 356, 372], // 10
  ['Act 1 Cave Level 2', 2, 37, 78, 340, 355, 372], // 11
  ['Act 1 Underground Passage Level 2', 4, 38, 83, 340, 356, 372], // 12
  ['Act 1 Hole Level 2', 5, 39, 81, 341, 356, 372], // 13
  ['Act 1 Pit Level 2', 7, 40, 85, 341, 357, 372], // 14
  ['Act 1 Burial Grounds', 3, 36, 80, 340, 355, 372], // 15
  ['Act 1 Crypt', 3, 37, 83, 340, 355, 372], // 16
  ['Act 1 Mausoleum', 3, 37, 85, 340, 355, 372], // 17
  ['Act 1 Tower Cellar Level 1', 7, 38, 75, 341, 356, 372], // 18
  ['Act 1 Tower Cellar Level 2', 7, 39, 76, 341, 356, 372], // 19
  ['Act 1 Tower Cellar Level 3', 7, 40, 77, 341, 356, 372], // 20
  ['Act 1 Tower Cellar Level 4', 7, 41, 78, 341, 356, 372], // 21
  ['Act 1 Tower Cellar Level 5', 7, 42, 79, 341, 356, 372], // 22
  ['Act 1 Monastery Gate', 8, 40, 70, 341, 357, 371], // 23
  ['Act 1 Outer Cloister', 9, 40, 70, 342, 357, 371], // 24
  ['Act 1 Barracks', 9, 40, 70, 342, 357, 371], // 25
  ['Act 1 Jail Level 1', 10, 41, 71, 342, 357, 372], // 26
  ['Act 1 Jail Level 2', 10, 41, 71, 342, 357, 372], // 27
  ['Act 1 Jail Level 3', 10, 41, 71, 342, 357, 372], // 28
  ['Act 1 Inner Cloister', 10, 41, 72, 342, 357, 372], // 29
  ['Act 1 Cathedral', 11, 42, 72, 342, 357, 372], // 30
  ['Act 1 Catacombs Level 1', 11, 42, 72, 342, 357, 372], // 31
  ['Act 1 Catacombs Level 2', 11, 42, 73, 342, 357, 372], // 32
  ['Act 1 Catacombs Level 3', 12, 43, 73, 342, 357, 372], // 33
  ['Act 1 Catacombs Level 4', 12, 43, 73, 342, 357, 372], // 34
  ['Act 1 Tristram', 6, 39, 76, 341, 356, 372], // 35
  ['Act 1 The Secret Cow Level', 28, 64, 81, 342, 357, 372], // 36
  ['Act 2 Rocky Waste', 14, 43, 75, 343, 358, 373], // 37
  ['Act 2 Dry Hills', 15, 44, 76, 344, 358, 373], // 38
  ['Act 2 Far Oasis', 16, 45, 76, 345, 359, 373], // 39
  ['Act 2 Lost City', 17, 46, 77, 345, 359, 374], // 40
  ['Act 2 Valley of Snakes', 18, 46, 77, 345, 359, 374], // 41
  ['Act 2 Canyon of the Magi', 16, 48, 79, 345, 360, 375], // 42
  ['Act 2 Sewers Level 1', 13, 43, 74, 343, 358, 373], // 43
  ['Act 2 Sewers Level 2', 13, 43, 74, 343, 358, 373], // 44
  ['Act 2 Sewers Level 3', 14, 44, 75, 343, 358, 373], // 45
  ['Act 2 Harem Level 2', 13, 47, 78, 343, 358, 373], // 46
  ['Act 2 Palace Cellar Level 1', 13, 47, 78, 343, 360, 374], // 47
  ['Act 2 Palace Cellar Level 2', 13, 47, 78, 343, 360, 374], // 48
  ['Act 2 Palace Cellar Level 3', 13, 48, 78, 343, 360, 374], // 49
  ['Act 2 Stony Tomb Level 1', 12, 44, 78, 343, 358, 374], // 50
  ['Act 2 Halls of the Dead Level 1', 12, 44, 79, 343, 358, 375], // 51
  ['Act 2 Halls of the Dead Level 2', 13, 45, 81, 343, 359, 375], // 52
  ['Act 2 Claw Viper Temple Level 1', 14, 47, 82, 343, 360, 375], // 53
  ['Act 2 Stony Tomb Level 2', 12, 44, 79, 343, 358, 375], // 54
  ['Act 2 Halls of the Dead Level 3', 13, 45, 82, 343, 359, 375], // 55
  ['Act 2 Claw Viper Temple Level 2', 14, 47, 83, 343, 360, 375], // 56
  ['Act 2 Maggot Lair Level 1', 17, 45, 84, 345, 359, 375], // 57
  ['Act 2 Maggot Lair Level 2', 17, 45, 84, 345, 359, 375], // 58
  ['Act 2 Maggot Lair Level 3', 17, 46, 85, 345, 359, 375], // 59
  ['Act 2 Ancient Tunnels', 17, 46, 85, 345, 359, 375], // 60
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 61
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 62
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 63
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 64
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 65
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 66
  ["Act 2 Tal Rasha's Tomb", 17, 49, 80, 345, 360, 375], // 67
  ["Act 2 Tal Rasha's Chamber", 17, 49, 80, 345, 360, 375], // 68
  ['Act 2 Arcane Sanctuary', 14, 48, 79, 343, 360, 375], // 69
  ['Act 3 Spider Forest', 21, 49, 79, 346, 361, 376], // 70
  ['Act 3 Great Marsh', 21, 50, 80, 346, 361, 377], // 71
  ['Act 3 Flayer Jungle', 22, 50, 80, 347, 361, 377], // 72
  ['Act 3 Lower Kurast', 22, 52, 80, 347, 362, 377], // 73
  ['Act 3 Kurast Bazaar', 22, 52, 81, 347, 362, 378], // 74
  ['Act 3 Upper Kurast', 23, 52, 81, 348, 362, 378], // 75
  ['Act 3 Kurast Causeway', 24, 53, 81, 348, 363, 378], // 76
  ['Act 3 Travincal', 24, 54, 82, 348, 363, 378], // 77
  ['Act 3 Arachnid Lair', 21, 50, 79, 346, 361, 376], // 78
  ['Act 3 Spider Cavern', 21, 50, 79, 346, 361, 376], // 79
  ['Act 3 Swampy Pit Level 1', 21, 51, 80, 346, 362, 377], // 80
  ['Act 3 Swampy Pit Level 2', 21, 51, 81, 346, 362, 378], // 81
  ['Act 3 Flayer Dungeon Level 1', 22, 51, 81, 347, 362, 378], // 82
  ['Act 3 Flayer Dungeon Level 2', 22, 51, 82, 347, 362, 378], // 83
  ['Act 3 Swampy Pit Level 3', 21, 51, 82, 346, 362, 378], // 84
  ['Act 3 Flayer Dungeon Level 3', 22, 51, 83, 347, 362, 378], // 85
  ['Act 3 Sewers Level 1', 23, 52, 84, 348, 362, 378], // 86
  ['Act 3 Sewers Level 2', 24, 53, 85, 348, 363, 378], // 87
  ['Act 3 Ruined Temple', 23, 53, 84, 348, 363, 378], // 88
  ['Act 3 Disused Fane', 23, 53, 84, 348, 363, 378], // 89
  ['Act 3 Forgotten Reliquary', 23, 53, 84, 348, 363, 378], // 90
  ['Act 3 Forgotten Temple', 24, 54, 85, 348, 363, 378], // 91
  ['Act 3 Ruined Fane', 24, 54, 85, 348, 363, 378], // 92
  ['Act 3 Disused Reliquary', 24, 54, 85, 348, 363, 378], // 93
  ['Act 3 Durance of Hate Level 1', 25, 55, 83, 348, 363, 378], // 94
  ['Act 3 Durance of Hate Level 2', 25, 55, 83, 348, 363, 378], // 95
  ['Act 3 Durance of Hate Level 3', 25, 55, 83, 348, 363, 378], // 96
  ['Act 4 Outer Steppes', 26, 56, 82, 349, 364, 379], // 97
  ['Act 4 Plains of Despair', 26, 56, 83, 349, 364, 380], // 98
  ['Act 4 City of the Damned', 27, 57, 84, 350, 365, 381], // 99
  ['Act 4 River of Flame', 27, 57, 85, 350, 365, 381], // 100
  ['Act 4 The Chaos Sanctuary', 28, 58, 85, 351, 366, 381], // 101
  ['Act 5 Bloody Foothills', 24, 58, 80, 353, 369, 384], // 102
  ['Act 5 Frigid Highlands', 25, 59, 81, 353, 369, 384], // 103
  ['Act 5 Arreat Plateau', 26, 60, 81, 353, 369, 384], // 104
  ['Act 5 Crystalline Passage', 29, 61, 82, 354, 369, 384], // 105
  ['Act 5 Frozen River', 29, 61, 83, 354, 369, 384], // 106
  ['Act 5 Glacial Trail', 29, 61, 83, 354, 369, 384], // 107
  ['Act 5 Drifter Cavern', 29, 61, 84, 354, 369, 384], // 108
  ['Act 5 Frozen Tundra', 27, 60, 81, 353, 369, 384], // 109
  ["Act 5 The Ancients' Way", 29, 62, 82, 354, 369, 384], // 110
  ['Act 5 Icy Cellar', 29, 62, 83, 354, 369, 384], // 111
  ['Act 5 Arreat Summit', 37, 68, 87, 354, 369, 384], // 112
  ["Act 5 Nihlathak's Temple", 32, 63, 83, 354, 369, 384], // 113
  ['Act 5 Halls of Anguish', 33, 63, 83, 354, 369, 384], // 114
  ['Act 5 Halls of Pain', 34, 64, 84, 354, 369, 384], // 115
  ['Act 5 Halls of Vaught', 36, 64, 84, 354, 369, 384], // 116
  ['Act 5 Abaddon', 39, 60, 81, 354, 369, 384], // 117
  ['Act 5 Pit of Acheron', 39, 61, 82, 354, 369, 384], // 118
  ['Act 5 Infernal Pit', 39, 62, 83, 354, 369, 384], // 119
  ['Act 5 Worldstone Keep Level 1', 39, 65, 85, 354, 369, 384], // 120
  ['Act 5 Worldstone Keep Level 2', 40, 65, 85, 354, 369, 384], // 121
  ['Act 5 Worldstone Keep Level 3', 42, 66, 85, 354, 369, 384], // 122
  ['Act 5 Throne of Destruction', 43, 66, 85, 354, 369, 384], // 123
  ['Act 5 The Worldstone Chamber', 43, 66, 85, 354, 369, 384], // 124
];

// slotId =
// 1) Head
// 2) Chest
// 3) Weapons
// 4) Shields
// 5) boots
// 6) gloves
// 7) belt

import { Runeword } from '../../types';

export const All_runewords_24: Runeword[] = [
  {
    name: "Ancient's Pledge",
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Ral + Ort + Tal',
    runeOrderInt: [7, 8, 6],
    cLvlReq: 21,
    id: 0,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '+50% Enhanced Defense\n' +
      'Cold Resist +43%\n' +
      'Lightning Resist +48%\n' +
      'Fire Resist +48%\n' +
      'Poison Resist +48%\n' +
      '10% Damage Taken Goes to Mana',
  },
  {
    name: 'Beast',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Ber + Tir + Um + Mal + Lum',
    runeOrderInt: [29, 2, 21, 22, 16],
    cLvlReq: 63,
    id: 1,
    slotId: 3,
    basetype: ['Axes', 'Hammers', 'Scepters'],
    isLadderOnly: false,
    stats:
      'Level 9 Fanaticism Aura When Equipped\n' +
      '+40% Increased Attack Speed\n' +
      '+240-270% Enhanced Damage (varies)\n' +
      '20% Chance of Crushing Blow\n' +
      '25% Chance of Open Wounds\n' +
      '+3 To Werebear\n' +
      '+3 To Lycanthropy\n' +
      'Prevent Monster Heal\n' +
      '+25-40 To Strength (varies)\n' +
      '+10 To Energy\n' +
      '+2 To Mana After Each Kill\n' +
      'Level 13 Summon Grizzly (5 Charges)',
  },
  {
    name: 'Black',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Thul + Io + Nef',
    runeOrderInt: [9, 15, 3],
    cLvlReq: 35,
    id: 2,
    slotId: 3,
    basetype: ['Clubs', 'Hammers', 'Maces'],
    isLadderOnly: false,
    stats:
      '+15% Increased Attack Speed\n' +
      '+120% Enhanced Damage\n' +
      '+200 to Attack Rating\n' +
      'Adds 3-14 Cold Damage (3 sec)\n' +
      '40% Chance of Crushing Blow\n' +
      'Knockback\n' +
      '+10 to Vitality\n' +
      'Magic Damage Reduced By 2\n' +
      'Level 4 Corpse Explosion (12 Charges)',
  },
  {
    name: 'Bone',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Sol + Um + Um',
    runeOrderInt: [11, 21, 21],
    cLvlReq: 47,
    id: 3,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '15% Chance To Cast level 10 Bone Armor When Struck\n' +
      '15% Chance To Cast level 10 Bone Spear On Striking\n' +
      '+2 To Necromancer Skill Levels\n' +
      '+100-150 To Mana (varies) \n' +
      'All Resistances +30\n' +
      'Damage Reduced By 7',
  },
  {
    name: 'Bramble',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Ral + Ohm + Sur + Eth',
    runeOrderInt: [7, 26, 28, 4],
    cLvlReq: 61,
    id: 4,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      'Level 15-21 Thorns Aura When Equipped (varies)\n' +
      '+50% Faster Hit Recovery\n' +
      '+25-50% To Poison Skill Damage (varies)\n' +
      '+300 Defense\n' +
      'Increase Maximum Mana 5%\n' +
      'Regenerate Mana 15%\n' +
      '+5% To Maximum Cold Resist\n' +
      'Fire Resist +30%\n' +
      'Poison Resist +100%\n' +
      '+13 Life After Each Kill\n' +
      'Level 13 Spirit of Barbs (33 Charges)',
  },
  {
    name: 'Brand',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Jah + Lo + Mal + Gul',
    runeOrderInt: [30, 27, 22, 24],
    cLvlReq: 65,
    id: 5,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      '35% Chance To Cast Level 14 Amplify Damage When Struck\n' +
      '100% Chance To Cast Level 18 Bone Spear On Striking\n' +
      'Fires Explosive Arrows or Bolts (15)\n' +
      '+260-340% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      '20% Bonus to Attack Rating\n' +
      '+280-330% Damage To Demons (varies)\n' +
      '20% Deadly Strike \n' +
      'Prevent Monster Heal\n' +
      'Knockback',
  },
  {
    name: 'Breath of the Dying',
    item_type: 'runeword',
    sockets: 6,
    runeOrder: 'Vex + Hel + El + Eld + Zod + Eth',
    runeOrderInt: [25, 14, 0, 1, 32, 4],
    cLvlReq: 69,
    id: 6,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '50% Chance To Cast Level 20 Poison Nova When You Kill An Enemy\n' +
      'Indestructible\n' +
      '+60% Increased Attack Speed\n' +
      '+350-400% Enhanced Damage (varies)\n' +
      '-25% Target Defense\n' +
      '+50 To Attack Rating\n' +
      '+200% Damage To Undead\n' +
      '+50 To Attack Rating Against Undead\n' +
      '7% Mana Stolen Per Hit\n' +
      '12-15% Life Stolen Per Hit (varies)\n' +
      'Prevent Monster Heal\n' +
      '+30 To All Attributes\n' +
      '+1 To Light Radius\n' +
      'Requirements -20% ',
  },
  {
    name: 'Call to Arms',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Amn + Ral + Mal + Ist + Ohm',
    runeOrderInt: [10, 7, 22, 23, 26],
    cLvlReq: 57,
    id: 7,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '+1 To All Skills\n' +
      '+40% Increased Attack Speed\n' +
      '+240-290% Enhanced Damage (varies)\n' +
      'Adds 5-30 Fire Damage\n' +
      '7% Life Stolen Per Hit\n' +
      '+2-6 To Battle Command (varies)\n' +
      '+1-6 To Battle Orders (varies)\n' +
      '+1-4 To Battle Cry (varies)\n' +
      'Prevent Monster Heal\n' +
      'Replenish Life +12\n' +
      '30% Better Chance of Getting Magic Items ',
  },
  {
    name: 'Chains of Honor',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Dol + Um + Ber + Ist',
    runeOrderInt: [13, 21, 29, 23],
    cLvlReq: 63,
    id: 8,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+2 To All Skills\n' +
      '+200% Damage To Demons\n' +
      '+100% Damage To Undead\n' +
      '8% Life Stolen Per Hit\n' +
      '+70% Enhanced Defense\n' +
      '+20 To Strength\n' +
      'Replenish Life +7\n' +
      'All Resistances +65\n' +
      'Damage Reduced By 8%\n' +
      '25% Better Chance of Getting Magic Items',
  },
  {
    name: 'Chaos',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Fal + Ohm + Um',
    runeOrderInt: [18, 26, 21],
    cLvlReq: 57,
    id: 9,
    slotId: 3,
    basetype: ['Katars'],
    isLadderOnly: false,
    stats:
      '9% Chance To Cast Level 11 Frozen Orb On Striking\n' +
      '11% Chance To Cast Level 9 Charged Bolt On Striking\n' +
      '+35% Increased Attacked Speed\n' +
      '+290-340% Enhanced Damage (varies)\n' +
      'Adds 216-471 Magic Damage\n' +
      '25% Chance of Open Wounds\n' +
      '+1 To Whirlwind\n' +
      '+10 To Strength\n' +
      '+15 Life After Each Demon Kill',
  },
  {
    name: 'Crescent Moon',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Shael + Um + Tir',
    runeOrderInt: [12, 21, 2],
    cLvlReq: 47,
    id: 10,
    slotId: 3,
    basetype: ['Axes', 'Polearms', 'Swords'],
    isLadderOnly: false,
    stats:
      '10% Chance To Cast Level 17 Chain Lightning On Striking\n' +
      '7% Chance To Cast Level 13 Static Field On Striking\n' +
      '+20% Increased Attack Speed\n' +
      '+180-220% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      '-35% To Enemy Lightning Resistance\n' +
      '25% Chance of Open Wounds\n' +
      '+9-11 Magic Absorb (varies)\n' +
      '+2 To Mana After Each Kill\n' +
      'Level 18 Summon Spirit Wolf (30 Charges)',
  },
  {
    name: 'Death',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Hel + El + Vex + Ort + Gul',
    runeOrderInt: [14, 0, 25, 8, 24],
    cLvlReq: 55,
    id: 11,
    slotId: 3,
    basetype: ['Swords', 'Axes'],
    isLadderOnly: false,
    stats:
      'Indestructible\n' +
      '100% Chance To Cast Level 44 Chain Lightning When You Die\n' +
      '25% Chance To Cast Level 18 Glacial Spike On Attack\n' +
      '+300-385% Enhanced Damage (varies)\n' +
      '20% Bonus To Attack Rating\n' +
      '+50 To Attack Rating\n' +
      'Adds 1-50 Lightning Damage\n' +
      '7% Mana Stolen Per Hit\n' +
      '50% Chance of Crushing Blow\n' +
      '(0.5*Clvl)% Deadly Strike (Based on Character Level)\n' +
      '+1 To Light Radius\n' +
      'Level 22 Blood Golem (15 Charges)\n' +
      'Requirements -20% ',
  },
  {
    name: 'Delirium',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Lem + Ist + Io',
    runeOrderInt: [19, 23, 15],
    cLvlReq: 51,
    id: 12,
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: false,
    stats:
      '1% Chance To Cast lvl 50 Delirium When Struck\n' +
      '6% Chance To Cast lvl 14 Mind Blast When Struck\n' +
      '14% Chance To Cast lvl 13 Terror When Struck\n' +
      '11% Chance To Cast lvl 18 Confuse On Striking\n' +
      '+2 To All Skills\n' +
      '+261 Defense\n' +
      '+10 To Vitality\n' +
      '50% Extra Gold From Monsters\n' +
      '25% Better Chance of Getting Magic Items\n' +
      'Level 17 Attract (60 Charges) ',
  },
  {
    name: 'Destruction',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Vex + Lo + Ber + Jah + Ko',
    runeOrderInt: [25, 27, 29, 30, 17],
    cLvlReq: 65,
    id: 13,
    slotId: 3,
    basetype: ['Polearms', 'Swords'],
    isLadderOnly: false,
    stats:
      '23% Chance To Cast Level 12 Volcano On Striking\n' +
      '5% Chance To Cast Level 23 Molten Boulder On Striking\n' +
      '100% Chance To Cast level 45 Meteor When You Die\n' +
      '15% Chance To Cast Level 22 Nova On Attack\n' +
      '+350% Enhanced Damage\n' +
      "Ignore Target's Defense\n" +
      'Adds 100-180 Magic Damage\n' +
      '7% Mana Stolen Per Hit\n' +
      '20% Chance Of Crushing Blow\n' +
      '20% Deadly Strike\n' +
      'Prevent Monster Heal\n' +
      '+10 To Dexterity ',
  },
  {
    name: 'Doom',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Hel + Ohm + Um + Lo + Cham',
    runeOrderInt: [14, 26, 21, 27, 31],
    cLvlReq: 67,
    id: 14,
    slotId: 3,
    basetype: ['Axes', 'Hammers', 'Polearms'],
    isLadderOnly: false,
    stats:
      '5% Chance To Cast Level 18 Volcano On Striking\n' +
      'Level 12 Holy Freeze Aura When Equipped\n' +
      '+2 To All Skills\n' +
      '+45% Increased Attack Speed\n' +
      '+330-370% Enhanced Damage (varies)\n' +
      '-40-60% To Enemy Cold Resistance (varies)\n' +
      '20% Deadly Strike\n' +
      '25% Chance of Open Wounds\n' +
      'Prevent Monster Heal\n' +
      'Freezes Target +3\n' +
      'Requirements -20% ',
  },
  {
    name: 'Dragon',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Sur + Lo + Sol',
    runeOrderInt: [28, 27, 11],
    cLvlReq: 61,
    id: 15,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '20% Chance to Cast Level 18 Venom When Struck\n' +
      '12% Chance To Cast Level 15 Hydra On Striking\n' +
      'Level 14 Holy Fire Aura When Equipped\n' +
      '+360 Defense\n' +
      '+230 Defense Vs. Missile\n' +
      '+3-5 To All Attributes (varies)\n' +
      '+(0.375 * Clvl) To Strength (Based on Character Level)\n' +
      '+5% To Maximum Lightning Resist\n' +
      'Damage Reduced by 7\n' +
      'Increase Maximum Mana 5%',
  },
  {
    name: 'Dragon',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Sur + Lo + Sol',
    runeOrderInt: [28, 27, 11],
    cLvlReq: 61,
    id: 16,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '20% Chance to Cast Level 18 Venom When Struck\n' +
      '12% Chance To Cast Level 15 Hydra On Striking\n' +
      'Level 14 Holy Fire Aura When Equipped\n' +
      '+360 Defense\n' +
      '+230 Defense Vs. Missile\n' +
      '+3-5 To All Attributes (varies)\n' +
      '+(0.375*Clvl) To Strength (Based on Character Level)\n' +
      '+5% To Maximum Lightning Resist\n' +
      'Damage Reduced by 7\n' +
      '+50 To Mana ',
  },
  {
    name: 'Dream',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Io + Jah + Pul',
    runeOrderInt: [15, 30, 20],
    cLvlReq: 65,
    id: 17,
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: false,
    stats:
      '10% Chance To Cast Level 15 Confuse When Struck\n' +
      'Level 15 Holy Shock Aura When Equipped\n' +
      '+20-30% Faster Hit Recovery (varies)\n' +
      '+30% Enhanced Defense\n' +
      '+150-220 Defense (varies)\n' +
      '+10 To Vitality\n' +
      '+(0.625*Clvl) To Mana (Based On Character Level)\n' +
      'All Resistances +5-20 (varies)\n' +
      '12-25% Better Chance of Getting Magic Items (varies)\n' +
      'Increase Maximum Life 5%',
  },
  {
    name: 'Dream',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Io + Jah + Pul',
    runeOrderInt: [15, 30, 20],
    cLvlReq: 65,
    id: 18,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '10% Chance To Cast Level 15 Confuse When Struck\n' +
      'Level 15 Holy Shock Aura When Equipped\n' +
      '+20-30% Faster Hit Recovery (varies)\n' +
      '+30% Enhanced Defense\n' +
      '+150-220 Defense (varies)\n' +
      '+10 To Vitality\n' +
      '+(0.625*Clvl) To Mana (Based On Character Level)\n' +
      'All Resistances +5-20 (varies)\n' +
      '12-25% Better Chance of Getting Magic Items (varies)\n' +
      'Headgear\n' +
      'Increase Maximum Life 5%\n' +
      'Shields\n' +
      '+50 To Life ',
  },
  {
    name: 'Duress',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Shael + Um + Thul',
    runeOrderInt: [12, 21, 9],
    cLvlReq: 47,
    id: 19,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '40% faster hit Recovery\n' +
      '+10-20% Enhanced Damage (varies)\n' +
      'Adds 37-133 Cold Damage\n' +
      '15% Crushing Blow\n' +
      '33% Open Wounds\n' +
      '+150-200% Enhanced Defense (varies)\n' +
      '-20% Slower Stamina Drain\n' +
      'Cold Resist +45%\n' +
      'Lightning Resist +15%\n' +
      'Fire Resist +15%\n' +
      'Poison Resist +15% ',
  },
  {
    name: 'Edge',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Tir + Tal + Amn',
    runeOrderInt: [2, 6, 10],
    cLvlReq: 25,
    id: 20,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      'Level 15 Thorns Aura When Equipped\n' +
      '+35% Increased Attack Speed\n' +
      '+320-380% Damage To Demons (varies)\n' +
      '+280% Damage To Undead\n' +
      '+75 Poison Damage Over 5 Seconds\n' +
      '7% Life Stolen Per Hit\n' +
      'Prevent Monster Heal\n' +
      '+5-10 To All Attributes (varies)\n' +
      '+2 To Mana After Each Kill\n' +
      'Reduces All Vendor Prices 15% ',
  },
  {
    name: 'Enigma',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Jah + Ith + Ber',
    runeOrderInt: [30, 5, 29],
    cLvlReq: 65,
    id: 21,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+2 To All Skills\n' +
      '+45% Faster Run/Walk\n' +
      '+1 To Teleport\n' +
      '+750-775 Defense (Varies)\n' +
      '+(0.75*Clvl) To Strength (Based On Character Level)\n' +
      'Increase Maximum Life 5%\n' +
      'Damage Reduced By 8%\n' +
      '+14 Life After Each Kill\n' +
      '15% Damage Taken Goes To Mana\n' +
      '(1*Clvl)% Better Chance of Getting Magic Items (Based On Character Level) ',
  },
  {
    name: 'Enlightenment',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Pul + Ral + Sol',
    runeOrderInt: [20, 7, 11],
    cLvlReq: 45,
    id: 22,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '5% Chance To Cast Level 15 Blaze When Struck\n' +
      '5% Chance To Cast level 15 Fire Ball On Striking\n' +
      '+2 To Sorceress Skill Levels\n' +
      '+1 To Warmth\n' +
      '+30% Enhanced Defense\n' +
      'Fire Resist +30%\n' +
      'Damage Reduced By 7 ',
  },
  {
    name: 'Eternity',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Amn + Ber + Ist + Sol + Sur',
    runeOrderInt: [10, 29, 23, 11, 28],
    cLvlReq: 63,
    id: 23,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      'Indestructible\n' +
      '+260-310% Enhanced Damage (varies)\n' +
      '+9 To Minimum Damage\n' +
      '7% Life Stolen Per Hit\n' +
      '20% Chance of Crushing Blow\n' +
      'Hit Blinds Target\n' +
      'Slows Target By 33%\n' +
      'Replenish Mana 16%\n' +
      'Cannot Be Frozen\n' +
      '30% Better Chance Of Getting Magic Items\n' +
      'Level 8 Revive (88 Charges) ',
  },
  {
    name: 'Exile',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Vex + Ohm + Ist + Dol',
    runeOrderInt: [25, 26, 23, 13],
    cLvlReq: 57,
    id: 24,
    slotId: 4,
    basetype: ['Paladin Shields'],
    isLadderOnly: false,
    stats:
      '15% Chance To Cast Level 5 Life Tap On Striking\n' +
      'Level 13-16 Defiance Aura When Equipped (varies)\n' +
      '+2 To Offensive Auras (Paladin Only)\n' +
      '+30% Faster Block Rate\n' +
      'Freezes Target\n' +
      '+220-260% Enhanced Defense (varies)\n' +
      'Replenish Life +7\n' +
      '+5% To Maximum Cold Resist\n' +
      '+5% To Maximum Fire Resist\n' +
      '25% Better Chance Of Getting Magic Items\n' +
      'Repairs 1 Durability every 4 seconds ',
  },
  {
    name: 'Faith',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Ohm + Jah + Lem + Eld',
    runeOrderInt: [26, 30, 19, 1],
    cLvlReq: 65,
    id: 25,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      'Level 12-15 Fanaticism Aura When Equipped (varies)\n' +
      '+1-2 To All Skills (varies)\n' +
      '+330% Enhanced Damage\n' +
      "Ignore Target's Defense\n" +
      '300% Bonus To Attack Rating\n' +
      '+75% Damage To Undead\n' +
      '+50 To Attack Rating Against Undead\n' +
      '+120 Fire Damage\n' +
      'All Resistances +15\n' +
      '10% Reanimate As: Returned\n' +
      '75% Extra Gold From Monsters ',
  },
  {
    name: 'Famine',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Fal + Ohm + Ort + Jah',
    runeOrderInt: [18, 26, 8, 30],
    cLvlReq: 65,
    id: 26,
    slotId: 3,
    basetype: ['Axes', 'Hammers'],
    isLadderOnly: false,
    stats:
      '+30% Increased Attack Speed\n' +
      '+320-370% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      'Adds 180-200 Magic Damage\n' +
      'Adds 50-200 Fire Damage\n' +
      'Adds 51-250 Lightning Damage\n' +
      'Adds 50-200 Cold Damage\n' +
      '12% Life Stolen Per Hit\n' +
      'Prevent Monster Heal\n' +
      '+10 To Strength ',
  },
  {
    name: 'Fortitude',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'El + Sol + Dol + Lo',
    runeOrderInt: [0, 11, 13, 27],
    cLvlReq: 59,
    id: 27,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '20% Chance To Cast Level 15 Chilling Armor when Struck\n' +
      '+25% Faster Cast Rate\n' +
      '+300% Enhanced Damage\n' +
      '+200% Enhanced Defense\n' +
      '+((8-12)*0.125*Clvl) To Life (Based on Character Level) (varies)\n' +
      'All Resistances +25-30 (varies)\n' +
      '12% Damage Taken Goes To Mana\n' +
      '+1 To Light Radius\n' +
      'Weapons\n' +
      '+9 To Minimum Damage\n' +
      '+50 To Attack Rating\n' +
      '20% Deadly Strike\n' +
      'Hit Causes Monster To Flee 25%\n' +
      '',
  },
  {
    name: 'Fortitude',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'El + Sol + Dol + Lo',
    runeOrderInt: [0, 11, 13, 27],
    cLvlReq: 59,
    id: 28,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '20% Chance To Cast Level 15 Chilling Armor when Struck\n' +
      '+25% Faster Cast Rate\n' +
      '+300% Enhanced Damage\n' +
      '+200% Enhanced Defense\n' +
      '+((8-12)*0.125*Clvl) To Life (Based on Character Level) (varies)\n' +
      'All Resistances +25-30 (varies)\n' +
      '12% Damage Taken Goes To Mana\n' +
      '+1 To Light Radius\n' +
      '+15 Defense\n' +
      'Replenish Life +7\n' +
      '+5% To Maximum Lightning Resist\n' +
      'Damage Reduced By 7 ',
  },
  {
    name: 'Fury',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Jah + Gul + Eth',
    runeOrderInt: [30, 24, 4],
    cLvlReq: 65,
    id: 29,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      '40% Increased Attack Speed\n' +
      '+209% Enhanced Damage\n' +
      'Ignores Target Defense\n' +
      '-25% Target Defense\n' +
      '20% Bonus to Attack Rating\n' +
      '6% Life Stolen Per Hit\n' +
      '33% Chance Of Deadly Strike\n' +
      '66% Chance Of Open Wounds\n' +
      '+5 To Frenzy (Barbarian Only)\n' +
      'Prevent Monster Heal ',
  },
  {
    name: 'Gloom',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Fal + Um + Pul',
    runeOrderInt: [18, 21, 20],
    cLvlReq: 47,
    id: 30,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '15% Chance To Cast Level 3 Dim Vision When Struck\n' +
      '+10% Faster Hit Recovery\n' +
      '+200-260% Enhanced Defense (varies)\n' +
      '+10 To Strength\n' +
      'All Resistances +45\n' +
      'Half Freeze Duration\n' +
      '5% Damage Taken Goes To Mana\n' +
      '-3 To Light Radius ',
  },
  {
    name: 'Grief',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Eth + Tir + Lo + Mal + Ral',
    runeOrderInt: [4, 2, 27, 22, 7],
    cLvlReq: 59,
    id: 31,
    slotId: 3,
    basetype: ['Swords', 'Axes'],
    isLadderOnly: false,
    stats:
      '35% Chance To Cast Level 15 Venom On Striking\n' +
      '+30-40% Increased Attack Speed (varies)\n' +
      'Damage +340-400 (varies)\n' +
      "Ignore Target's Defense\n" +
      '-25% Target Defense\n' +
      '+(1.875*Clvl)% Damage To Demons (Based on Character Level)\n' +
      'Adds 5-30 Fire Damage\n' +
      '-20-25% To Enemy Poison Resistance (varies)\n' +
      '20% Deadly Strike\n' +
      'Prevent Monster Heal\n' +
      '+2 To Mana After Each Kill\n' +
      '+10-15 Life After Each Kill (varies) ',
  },
  {
    name: 'Hand of Justice',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Sur + Cham + Amn + Lo',
    runeOrderInt: [28, 31, 10, 27],
    cLvlReq: 67,
    id: 32,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '100% Chance To Cast Level 36 Blaze When You Level-Up\n' +
      '100% Chance To Cast Level 48 Meteor When You Die\n' +
      'Level 16 Holy Fire Aura When Equipped\n' +
      '+33% Increased Attack Speed\n' +
      '+280-330% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      '-20% To Enemy Fire Resistance\n' +
      '7% Life Stolen Per Hit\n' +
      '20% Deadly Strike\n' +
      'Hit Blinds Target\n' +
      'Freezes Target +3 ',
  },
  {
    name: 'Harmony',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Tir + Ith + Sol + Ko',
    runeOrderInt: [2, 5, 11, 17],
    cLvlReq: 39,
    id: 33,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      'Level 10 Vigor Aura When Equipped\n' +
      '+200-275% Enhanced Damage (varies)\n' +
      '+9 To Minimum Damage\n' +
      '+9 To Maximum Damage\n' +
      'Adds 55-160 Fire Damage\n' +
      'Adds 55-160 Lightning Damage\n' +
      'Adds 55-160 Cold Damage\n' +
      '+2-6 To Valkyrie (varies)\n' +
      '+10 To Dexterity\n' +
      'Regenerate Mana 20%\n' +
      '+2 To Mana After Each Kill\n' +
      '+2 To Light Radius\n' +
      'Level 20 Revive (25 Charges) ',
  },
  {
    name: 'Heart of the Oak',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Ko + Vex + Pul + Thul',
    runeOrderInt: [17, 25, 20, 9],
    cLvlReq: 55,
    id: 34,
    slotId: 3,
    basetype: ['Staves', 'Maces'],
    isLadderOnly: false,
    stats:
      '+3 To All Skills\n' +
      '+40% Faster Cast Rate\n' +
      '+75% Damage To Demons\n' +
      '+100 To Attack Rating Against Demons\n' +
      'Adds 3-14 Cold Damage\n' +
      '7% Mana Stolen Per Hit\n' +
      '+10 To Dexterity\n' +
      'Replenish Life +20\n' +
      'Increase Maximum Mana 15%\n' +
      'All Resistances +30-40 (varies)\n' +
      'Level 4 Oak Sage (25 Charges)\n' +
      'Level 14 Raven (60 Charges) ',
  },
  {
    name: 'Holy Thunder',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Eth + Ral + Ort + Tal',
    runeOrderInt: [4, 7, 8, 6],
    cLvlReq: 23,
    id: 35,
    slotId: 3,
    basetype: ['Scepters'],
    isLadderOnly: false,
    stats:
      '+60% Enhanced Damage\n' +
      '+10 to Maximum Damage\n' +
      '-25% Target Defense\n' +
      'Adds 5-30 Fire Damage\n' +
      'Adds 21-110 Lightning Damage\n' +
      '+75 Poison Damage over 5 secs\n' +
      '+3 to Holy Shock (Paladin Only)\n' +
      '+5% to Maximum Lightning Resist\n' +
      'Lightning Resist +60%\n' +
      'Level 7 Chain Lightning (60 charges)',
  },
  {
    name: 'Honor',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Amn + El + Ith + Tir + Sol',
    runeOrderInt: [10, 0, 5, 2, 11],
    cLvlReq: 27,
    id: 36,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      '+1 to all skills\n' +
      '+160% Enhanced Damage\n' +
      '+9 to Minimum Damage\n' +
      '+9 to Maximum Damage\n' +
      '+250 Attack Rating\n' +
      '7% Life Stolen per Hit\n' +
      '25% Deadly Strike\n' +
      '+10 to Strength\n' +
      'Replenish life +10\n' +
      '+2 to Mana after each Kill\n' +
      '+1 to Light Radius ',
  },
  {
    name: 'Ice',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Amn + Shael + Jah + Lo',
    runeOrderInt: [10, 12, 30, 27],
    cLvlReq: 65,
    id: 37,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      '100% Chance To Cast Level 40 Blizzard When You Level-up\n' +
      '25% Chance To Cast Level 22 Frost Nova On Striking\n' +
      'Level 18 Holy Freeze Aura When Equipped\n' +
      '+20% Increased Attack Speed\n' +
      '+140-210% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      '+25-30% To Cold Skill Damage (varies)\n' +
      '7% Life Stolen Per Hit\n' +
      '-20% To Enemy Cold Resistance\n' +
      '20% Deadly Strike\n' +
      '(3.125*Clvl)% Extra Gold From Monsters (Based on Character Level) ',
  },
  {
    name: 'Infinity',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Ber + Mal + Ber + Ist',
    runeOrderInt: [29, 22, 29, 23],
    cLvlReq: 63,
    id: 38,
    slotId: 3,
    basetype: ['Polearms', 'Spears'],
    isLadderOnly: false,
    stats:
      '50% Chance To Cast Level 20 Chain Lightning When You Kill An Enemy\n' +
      'Level 12 Conviction Aura When Equipped\n' +
      '+35% Faster Run/Walk\n' +
      '+255-325% Enhanced Damage (varies)\n' +
      '-(45-55)% To Enemy Lightning Resistance (varies)\n' +
      '40% Chance of Crushing Blow\n' +
      'Prevent Monster Heal\n' +
      '+(0.5*Clvl) To Vitality (Based on Character Level)\n' +
      '30% Better Chance of Getting Magic Items\n' +
      'Level 21 Cyclone Armor (30 Charges) ',
  },
  {
    name: 'Insight',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Ral + Tir + Tal + Sol',
    runeOrderInt: [7, 2, 6, 11],
    cLvlReq: 27,
    id: 39,
    slotId: 3,
    basetype: ['Polearms', 'Staves', 'Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      'Level 12-17 Meditation Aura When Equipped (varies)\n' +
      '+35% Faster Cast Rate\n' +
      '+200-260% Enhanced Damage (varies)\n' +
      '+9 To Minimum Damage\n' +
      '180-250% Bonus to Attack Rating (varies)\n' +
      'Adds 5-30 Fire Damage\n' +
      '+75 Poison Damage Over 5 Seconds\n' +
      '+1-6 To Critical Strike (varies)\n' +
      '+5 To All Attributes\n' +
      '+2 To Mana After Each Kill\n' +
      '23% Better Chance of Getting Magic Items ',
  },
  {
    name: "King's Grace",
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Amn + Ral + Thul',
    runeOrderInt: [10, 7, 9],
    cLvlReq: 25,
    id: 40,
    slotId: 3,
    basetype: ['Swords', 'Scepters'],
    isLadderOnly: false,
    stats:
      '+100% Enhanced Damage\n' +
      '+150 to Attack Rating\n' +
      '+100% Damage to Demons\n' +
      '+100 to Attack Rating against Demons\n' +
      '+50% Damage to Undead\n' +
      '+100 to Attack Rating against Undead\n' +
      'Adds 5-30 Fire Damage\n' +
      'Adds 3-14 Cold damage\n' +
      '7% Life stolen per hit ',
  },
  {
    name: 'Kingslayer',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Mal + Um + Gul + Fal',
    runeOrderInt: [22, 21, 24, 18],
    cLvlReq: 53,
    id: 41,
    slotId: 3,
    basetype: ['Swords', 'Axes'],
    isLadderOnly: false,
    stats:
      '+30% Increased Attack Speed\n' +
      '+230-270% Enhanced Damage (varies)\n' +
      '-25% Target Defense\n' +
      '20% Bonus To Attack Rating\n' +
      '33% Chance of Crushing Blow\n' +
      '50% Chance of Open Wounds\n' +
      '+1 To Vengeance\n' +
      'Prevent Monster Heal\n' +
      '+10 To Strength\n' +
      '40% Extra Gold From Monsters ',
  },
  {
    name: 'Last Wish',
    item_type: 'runeword',
    sockets: 6,
    runeOrder: 'Jah + Mal + Jah + Sur + Jah + Ber',
    runeOrderInt: [30, 22, 30, 28, 30, 29],
    cLvlReq: 65,
    id: 42,
    slotId: 3,
    basetype: ['Axes', 'Hammers', 'Swords'],
    isLadderOnly: false,
    stats:
      '6% Chance To Cast Level 11 Fade When Struck\n' +
      '10% Chance To Cast Level 18 Life Tap On Striking\n' +
      '20% Chance To Cast Level 20 Charged Bolt On Attack\n' +
      'Level 17 Might Aura When Equipped\n' +
      '+330-375% Enhanced Damage (varies)\n' +
      "Ignore Target's Defense\n" +
      '60-70% Chance of Crushing Blow (varies)\n' +
      'Prevent Monster Heal\n' +
      'Hit Blinds Target\n' +
      '(0.5*Clvl)% Chance of Getting Magic Items (Based on Character Level)',
  },
  {
    name: 'Lawbringer',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Amn + Lem + Ko',
    runeOrderInt: [10, 19, 17],
    cLvlReq: 43,
    id: 43,
    slotId: 3,
    basetype: ['Hammers', 'Scepters', 'Swords'],
    isLadderOnly: false,
    stats:
      '20% Chance To Cast Level 15 Decrepify On Striking\n' +
      'Level 16-18 Sanctuary Aura When Equipped (varies)\n' +
      '-50% Target Defense\n' +
      'Adds 150-210 Fire Damage\n' +
      'Adds 130-180 Cold Damage\n' +
      '7% Life Stolen Per Hit\n' +
      'Slain Monsters Rest In Peace\n' +
      '+200-250 Defense Vs. Missile (varies)\n' +
      '+10 To Dexterity\n' +
      '75% Extra Gold From Monsters',
  },
  {
    name: 'Leaf',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Tir + Ral',
    runeOrderInt: [2, 7],
    cLvlReq: 19,
    id: 44,
    slotId: 3,
    basetype: ['Staves'],
    isLadderOnly: false,
    stats:
      '+3 to Fire Skills\n' +
      'Adds 5-30 Fire Damage\n' +
      '+3 to Inferno (Sorceress Only)\n' +
      '+3 to Warmth (Sorceress Only)\n' +
      '+3 to Fire Bolt (Sorceress Only)\n' +
      '+(2*Clvl) Defence (Based on Character Level)\n' +
      'Cold Resist +33%\n' +
      '+2 to Mana after each Kill',
  },
  {
    name: 'Lionheart',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Hel + Lum + Fal',
    runeOrderInt: [14, 16, 18],
    cLvlReq: 41,
    id: 45,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+20% Enhanced Damage\n' +
      '+25 To Strength\n' +
      '+15 To Dexterity\n' +
      '+20 To Vitality\n' +
      '+10 To Energy\n' +
      '+50 To Life\n' +
      'All Resistances +30\n' +
      'Requirements -15%',
  },
  {
    name: 'Lore',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Ort + Sol',
    runeOrderInt: [8, 11],
    cLvlReq: 27,
    id: 46,
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: false,
    stats:
      '+1 to All Skills\n' +
      '+10 to Energy\n' +
      'Lightning Resist +30%\n' +
      'Damage Reduced by 7\n' +
      '+2 to Mana after each Kill\n' +
      '+2 to Light Radius',
  },
  {
    name: 'Malice',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Ith + El + Eth',
    runeOrderInt: [5, 0, 4],
    cLvlReq: 15,
    id: 47,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      '+33% Enhanced Damage\n' +
      '+9 to Maximum Damage\n' +
      '-25% Target Defense\n' +
      '+50 to Attack Rating\n' +
      '100% Chance of Open wounds\n' +
      'Prevent Monster Heal\n' +
      '-100 to Monster Defense Per Hit\n' +
      'Drain Life -5',
  },
  {
    name: 'Melody',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Shael + Ko + Nef',
    runeOrderInt: [12, 17, 3],
    cLvlReq: 39,
    id: 48,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      '+3 To Bow and Crossbow Skills (Amazon Only)\n' +
      '+20% Increased Attack Speed\n' +
      '+50% Enhanced Damage\n' +
      '+300% Damage To Undead\n' +
      '+3 To Slow Missiles (Amazon Only)\n' +
      '+3 To Dodge (Amazon Only)\n' +
      '+3 To Critical Strike (Amazon Only)\n' +
      'Knockback\n' +
      '+10 To Dexterity',
  },
  {
    name: 'Memory',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Lum + Io + Sol + Eth',
    runeOrderInt: [16, 15, 11, 4],
    cLvlReq: 37,
    id: 49,
    slotId: 3,
    basetype: ['Staves'],
    isLadderOnly: false,
    stats:
      '+3 To Sorceress Skill Levels\n' +
      '+33% Faster Cast Rate\n' +
      '+9 To Minimum Damage\n' +
      '-25% Target Defence\n' +
      '+3 To Energy Shield (Sorceress Only)\n' +
      '+2 To Static Field (Sorceress Only)\n' +
      '+50% Enhanced Defense\n' +
      '+10 Vitality\n' +
      '+10 Energy\n' +
      'Increase Maximum Mana 20%\n' +
      'Magic Damage Reduced By 7',
  },
  {
    name: 'Myth',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Hel + Amn + Nef',
    runeOrderInt: [14, 10, 3],
    cLvlReq: 25,
    id: 50,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '3% Chance To Cast Level 1 Howl When Struck\n' +
      '10% Chance To Cast Level 1 Taunt On Striking\n' +
      '+2 To Barbarian Skill Levels\n' +
      '+30 Defense Vs. Missile\n' +
      'Replenish Life +10\n' +
      'Attacker Takes Damage of 14\n' +
      'Requirements -15%',
  },
  {
    name: 'Nadir',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Nef + Tir',
    runeOrderInt: [3, 2],
    cLvlReq: 13,
    id: 51,
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: false,
    stats:
      '+50% Enhanced Defense\n' +
      '+10 Defense\n' +
      '+30 Defense vs. Missile\n' +
      '+5 to Strength\n' +
      '+2 to Mana after each Kill\n' +
      '-33% Extra Gold from Monsters\n' +
      '-3 to Light Radius\n' +
      'Level 13 Cloak of Shadows (9 charges)',
  },
  {
    name: 'Oath',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Shael + Pul + Mal + Lum',
    runeOrderInt: [12, 20, 22, 16],
    cLvlReq: 49,
    id: 52,
    slotId: 3,
    basetype: ['Axes', 'Maces', 'Swords'],
    isLadderOnly: false,
    stats:
      'Indestructible\n' +
      '30% Chance To Cast Level 20 Bone Spirit On Striking\n' +
      '+50% Increased Attack Speed\n' +
      '+210-340% Enhanced Damage (varies)\n' +
      '+75% Damage To Demons\n' +
      '+100 To Attack Rating Against Demons\n' +
      'Prevent Monster Heal\n' +
      '+10 To Energy\n' +
      '+10-15 Magic Absorb (varies)\n' +
      'Level 16 Heart of Wolverine (20 Charges)\n' +
      'Level 17 Iron Golem (14 Charges)',
  },
  {
    name: 'Obedience',
    item_type: 'runeword',
    sockets: 5,
    runeOrder: 'Hel + Ko + Thul + Eth + Fal',
    runeOrderInt: [14, 17, 9, 4, 18],
    cLvlReq: 41,
    id: 53,
    slotId: 3,
    basetype: ['Polearms', 'Spears'],
    isLadderOnly: false,
    stats:
      '30% Chance To Cast Level 21 Enchant When You Kill An Enemy\n' +
      '+40% Faster Hit Recovery\n' +
      '+370% Enhanced Damage\n' +
      '-25% Target Defense\n' +
      'Adds 3-14 Cold Damage (3 Seconds Duration,Normal)\n' +
      '-25% To Enemy Fire Resistance\n' +
      '40% Chance of Crushing Blow\n' +
      '+200-300 Defense (varies)\n' +
      '+10 To Strength\n' +
      '+10 To Dexterity\n' +
      'All Resistances +20-30 (varies)\n' +
      'Requirements -20% ',
  },
  {
    name: 'Passion',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Dol + Ort + Eld + Lem',
    runeOrderInt: [13, 8, 1, 19],
    cLvlReq: 43,
    id: 54,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '+25% Increased Attack Speed\n' +
      '+160-210% Enhanced Damage (varies)\n' +
      '50-80% Bonus To Attack Rating (varies)\n' +
      '+75% Damage To Undead\n' +
      '+50 To Attack Rating Against Undead\n' +
      'Adds 1-50 Lightning Damage\n' +
      '+1 To Berserk\n' +
      '+1 To Zeal\n' +
      'Hit Blinds Target +10\n' +
      'Hit Causes Monster To Flee 25%\n' +
      '75% Extra Gold From Monsters\n' +
      'Level 3 Heart of Wolverine (12 Charges)',
  },
  {
    name: 'Peace',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Shael + Thul + Amn',
    runeOrderInt: [12, 9, 10],
    cLvlReq: 29,
    id: 55,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '4% Chance To Cast Level 5 Slow Missiles When Struck\n' +
      '2% Chance To Cast level 15 Valkyrie On Striking\n' +
      '+2 To Amazon Skill Levels\n' +
      '+20% Faster Hit Recovery\n' +
      '+2 To Critical Strike\n' +
      'Cold Resist +30%\n' +
      'Attacker Takes Damage of 14',
  },
  {
    name: 'Phoenix',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Vex + Vex + Lo + Jah',
    runeOrderInt: [25, 25, 27, 30],
    cLvlReq: 65,
    id: 56,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '100% Chance To Cast level 40 Blaze When You Level-up\n' +
      '40% Chance To Cast Level 22 Firestorm On Striking\n' +
      'Level 10-15 Redemption Aura When Equipped (varies)\n' +
      '+350-400% Enhanced Damage (varies)\n' +
      '-28% To Enemy Fire Resistance\n' +
      '+350-400 Defense Vs. Missile (varies)\n' +
      '+15-21 Fire Absorb (varies)\n' +
      '+50 To Life\n' +
      '+5% To Maximum Lightning Resist\n' +
      '+10% To Maximum Fire Resist ',
  },
  {
    name: 'Phoenix',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Vex + Vex + Lo + Jah',
    runeOrderInt: [25, 25, 27, 30],
    cLvlReq: 65,
    id: 57,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '100% Chance To Cast level 40 Blaze When You Level-up\n' +
      '40% Chance To Cast Level 22 Firestorm On Striking\n' +
      'Level 10-15 Redemption Aura When Equipped (varies)\n' +
      '+350-400% Enhanced Damage (varies)\n' +
      '-28% To Enemy Fire Resistance\n' +
      '+350-400 Defense Vs. Missile (varies)\n' +
      '+15-21 Fire Absorb (varies)\n' +
      "Ignores Target's Defense\n" +
      '14% Mana Stolen Per Hit\n' +
      '20% Deadly Strike\n' +
      '',
  },
  {
    name: 'Pride',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Cham + Sur + Io + Lo',
    runeOrderInt: [31, 28, 15, 27],
    cLvlReq: 67,
    id: 58,
    slotId: 3,
    basetype: ['Polearms', 'Spears'],
    isLadderOnly: false,
    stats:
      '25% Chance To Cast Level 17 Fire Wall When Struck\n' +
      'Level 16-20 Concentration Aura When Equipped (varies)\n' +
      '260-300% Bonus To Attack Rating (varies)\n' +
      '+(1*Clvl)% Damage To Demons (Based on Character Level)\n' +
      'Adds 50-280 Lightning Damage\n' +
      '20% Deadly Strike\n' +
      'Hit Blinds Target\n' +
      'Freezes Target +3\n' +
      '+10 To Vitality\n' +
      'Replenish Life +8\n' +
      '(1.875*Clvl)% Extra Gold From Monsters (Based on Character Level)',
  },
  {
    name: 'Principle',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Ral + Gul + Eld',
    runeOrderInt: [7, 24, 1],
    cLvlReq: 55,
    id: 59,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '100% Chance To Cast Level 5 Holy Bolt On Striking\n' +
      '+2 To Paladin Skill Levels\n' +
      '+50% Damage to Undead\n' +
      '+100-150 to Life (varies)\n' +
      '15% Slower Stamina Drain\n' +
      '+5% To Maximum Poison Resist\n' +
      'Fire Resist +30%',
  },
  {
    name: 'Prudence',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Mal + Tir',
    runeOrderInt: [22, 2],
    cLvlReq: 49,
    id: 60,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+25% Faster Hit Recovery\n' +
      '+140-170% Enhanced Defense (varies)\n' +
      'All Resistances +25-35 (varies)\n' +
      'Damage Reduced by 3\n' +
      'Magic Damage Reduced by 17\n' +
      '+2 To Mana After Each Kill\n' +
      '+1 To Light Radius\n' +
      'Repairs Durability 1 In 4 Seconds',
  },
  {
    name: 'Radiance',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Nef + Sol + Ith',
    runeOrderInt: [3, 11, 5],
    cLvlReq: 27,
    id: 61,
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: false,
    stats:
      '+75% Enhanced Defense\n' +
      '+30 Defense vs. Missiles\n' +
      '+10 to Vitality\n' +
      '+10 to Energy\n' +
      '+33 to Mana\n' +
      'Damage Reduced by 7\n' +
      'Magic Damage Reduced by 3\n' +
      '15% Damage Taken Goes to Mana\n' +
      '+5 to Light Radius',
  },
  {
    name: 'Rain',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Ort + Mal + Ith',
    runeOrderInt: [8, 22, 5],
    cLvlReq: 49,
    id: 62,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '5% Chance To Cast Level 15 Cyclone Armor When Struck\n' +
      '5% Chance To Cast Level 15 Twister On Striking\n' +
      '+2 To Druid Skills\n' +
      '+100-150 To Mana (varies)\n' +
      'Lightning Resist +30%\n' +
      'Magic Damage Reduced By 7\n' +
      '15% Damage Taken Goes to Mana',
  },
  {
    name: 'Rhyme',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Shael + Eth',
    runeOrderInt: [12, 4],
    cLvlReq: 29,
    id: 63,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '+40% Faster Block Rate\n' +
      '20% Increased Chance of Blocking\n' +
      'Regenerate Mana 15%\n' +
      'All Resistances +25\n' +
      'Cannot be Frozen\n' +
      '50% Extra Gold from Monsters\n' +
      '25% Better Chance of Getting Magic Items ',
  },
  {
    name: 'Rift',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Hel + Ko + Lem + Gul',
    runeOrderInt: [14, 17, 19, 24],
    cLvlReq: 53,
    id: 64,
    slotId: 3,
    basetype: ['Polearms', 'Scepters'],
    isLadderOnly: false,
    stats:
      '20% Chance To Cast Level 16 Tornado On Striking\n' +
      '16% Chance To Cast Level 21 Frozen Orb On Attack\n' +
      '20% Bonus To Attack Rating\n' +
      'Adds 160-250 Magic Damage\n' +
      'Adds 60-180 Fire Damage\n' +
      '+5-10 To All Attributes (varies)\n' +
      '+10 To Dexterity\n' +
      '38% Damage Taken Goes To Mana\n' +
      '75% Extra Gold From Monsters\n' +
      'Level 15 Iron Maiden (40 Charges)\n' +
      'Requirements -20%',
  },
  {
    name: 'Sanctuary',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Ko + Ko + Mal',
    runeOrderInt: [17, 17, 22],
    cLvlReq: 49,
    id: 65,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '+20% Faster Hit Recovery\n' +
      '+20% Faster Block Rate\n' +
      '20% Increased Chance of Blocking\n' +
      '+130-160% Enhanced Defense (varies)\n' +
      '+250 Defense vs. Missile\n' +
      '+20 To Dexterity\n' +
      'All Resistances +50-70 (varies)\n' +
      'Magic Damage Reduced By 7\n' +
      'Level 12 Slow Missiles (60 Charges)',
  },
  {
    name: 'Silence',
    item_type: 'runeword',
    sockets: 6,
    runeOrder: 'Dol + Eld + Hel + Ist + Tir + Vex',
    runeOrderInt: [13, 1, 14, 23, 2, 25],
    cLvlReq: 55,
    id: 66,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      '+2 to All Skills\n' +
      '+20% Increased Attack Speed\n' +
      '+20% Faster Hit Recovery\n' +
      '+200% Enhanced Damage\n' +
      '+75% Damage To Undead\n' +
      '+50 to Attack Rating Against Undead\n' +
      '11% Mana Stolen Per Hit\n' +
      'Hit Blinds Target +33\n' +
      'Hit Causes Monster to Flee 25%\n' +
      'All Resistances +75\n' +
      '+2 to Mana After Each Kill\n' +
      '30% Better Chance of Getting Magic Items\n' +
      'Requirements -20%',
  },
  {
    name: 'Smoke',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Nef + Lum',
    runeOrderInt: [3, 16],
    cLvlReq: 37,
    id: 67,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+20% Faster Hit Recovery\n' +
      '+75% Enhanced Defense\n' +
      '+280 Defense vs. Missiles\n' +
      '+10 to Energy\n' +
      'All Resistances +50\n' +
      '-1 to Light Radius\n' +
      'Level 6 Weaken (18 charges)',
  },
  {
    name: 'Spirit',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Tal + Thul + Ort + Amn',
    runeOrderInt: [6, 9, 8, 10],
    cLvlReq: 25,
    id: 68,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '+2 To All Skills\n' +
      '+25-35% Faster Cast Rate (varies)\n' +
      '+55% Faster Hit Recovery\n' +
      '+250 Defense Vs. Missile\n' +
      '+22 To Vitality\n' +
      '+89-112 To Mana (varies)\n' +
      '+3-8 Magic Absorb (varies)\n' +
      'Cold Resist +35%\n' +
      'Lightning Resist +35%\n' +
      'Poison Resist +35%\n' +
      'Attacker Takes Damage of 14\n' +
      '',
  },
  {
    name: 'Spirit',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Tal + Thul + Ort + Amn',
    runeOrderInt: [6, 9, 8, 10],
    cLvlReq: 25,
    id: 69,
    slotId: 3,
    basetype: ['Swords'],
    isLadderOnly: false,
    stats:
      '+2 To All Skills\n' +
      '+25-35% Faster Cast Rate (varies)\n' +
      '+55% Faster Hit Recovery\n' +
      '+250 Defense Vs. Missile\n' +
      '+22 To Vitality\n' +
      '+89-112 To Mana (varies)\n' +
      '+3-8 Magic Absorb (varies)\n' +
      'Adds 1-50 Lightning Damage\n' +
      'Adds 3-14 Cold Damage (3 Sec,Normal)\n' +
      '+75 Poison Damage Over 5 Seconds\n' +
      '7% Life Stolen Per Hit ',
  },
  {
    name: 'Splendor',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Eth + Lum',
    runeOrderInt: [4, 16],
    cLvlReq: 37,
    id: 70,
    slotId: 4,
    basetype: ['Shields'],
    isLadderOnly: false,
    stats:
      '+1 To All Skills\n' +
      '+10% Faster Cast Rate\n' +
      '+20% Faster Block Rate\n' +
      '+60-100% Enhanced Defense (varies)\n' +
      '+10 To Energy\n' +
      'Regenerate Mana 15%\n' +
      '50% Extra Gold From Monsters\n' +
      '20% Better Chance of Getting Magic Items\n' +
      '+3 To Light Radius',
  },
  {
    name: 'Stealth',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Tal + Eth',
    runeOrderInt: [6, 4],
    cLvlReq: 17,
    id: 71,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+25% Faster Run/Walk\n' +
      '+25% Faster Casting Rate\n' +
      '+25% Faster Hit Recovery\n' +
      '+6 to Dexterity\n' +
      'Regenerate Mana 15%\n' +
      '+15 Maximum Stamina\n' +
      'Poison Resist +30%\n' +
      'Magic Damage Reduced by 3 ',
  },
  {
    name: 'Steel',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Tir + El',
    runeOrderInt: [2, 0],
    cLvlReq: 13,
    id: 72,
    slotId: 3,
    basetype: ['Swords', 'Axes', 'Maces'],
    isLadderOnly: false,
    stats:
      '+25% Increased Attack Speed\n' +
      '+20% Enhanced Damage\n' +
      '+3 to Minimum Damage\n' +
      '+3 to Maximum Damage\n' +
      '+50 to Attack Rating\n' +
      '50% Chance of Open Wounds\n' +
      '+2 to Mana after each Kill\n' +
      '+1 to Light Radius',
  },
  {
    name: 'Stone',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Shael + Um + Pul + Lum',
    runeOrderInt: [12, 21, 20, 16],
    cLvlReq: 47,
    id: 73,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+60% Faster Hit Recovery\n' +
      '+250-290% Enhanced Defense (varies)\n' +
      '+300 Defense Vs. Missile\n' +
      '+16 To Strength\n' +
      '+16 To Vitality\n' +
      '+10 To Energy\n' +
      'All Resistances +15\n' +
      'Level 16 Molten Boulder (80 Charges)\n' +
      'Level 16 Clay Golem (16 Charges)',
  },
  {
    name: 'Strength',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Amn + Tir',
    runeOrderInt: [10, 2],
    cLvlReq: 25,
    id: 74,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      '+35% Enhanced Damage\n' +
      '7% Life stolen per hit\n' +
      '25% Chance of Crushing Blow\n' +
      '+20 to Strength\n' +
      '+10 to Vitality\n' +
      '+2 to Mana after each Kill',
  },
  {
    name: 'Treachery',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Shael + Thul + Lem',
    runeOrderInt: [12, 9, 19],
    cLvlReq: 43,
    id: 75,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '5% Chance To Cast Level 15 Fade When Struck\n' +
      '25% Chance To Cast level 15 Venom On Striking\n' +
      '+2 To Assassin Skills\n' +
      '+45% Increased Attack Speed\n' +
      '+20% Faster Hit Recovery\n' +
      'Cold Resist +30%\n' +
      '50% Extra Gold From Monsters',
  },
  {
    name: 'Venom',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Tal + Dol + Mal',
    runeOrderInt: [6, 13, 22],
    cLvlReq: 49,
    id: 76,
    slotId: 3,
    basetype: ['All Weapons'],
    isLadderOnly: false,
    stats:
      "Ignore Target's Defense\n" +
      '+273 Poison Damage Over 6 Seconds\n' +
      '7% Mana Stolen Per Hit\n' +
      'Prevent Monster Heal\n' +
      'Hit Causes Monster To Flee 25%\n' +
      'Level 13 Poison Nova (11 Charges)\n' +
      'Level 15 Poison Explosion (27 Charges) ',
  },
  {
    name: 'Voice of Reason',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Lem + Ko + El + Eld',
    runeOrderInt: [19, 17, 0, 1],
    cLvlReq: 43,
    id: 77,
    slotId: 3,
    basetype: ['Maces', 'Swords'],
    isLadderOnly: false,
    stats:
      '15% Chance To Cast Level 13 Frozen Orb On Striking\n' +
      '18% Chance To Cast Level 20 Ice Blast On Striking\n' +
      '+50 To Attack Rating\n' +
      '+220-350% Damage To Demons (varies)\n' +
      '+355-375% Damage To Undead (varies)\n' +
      '+50 To Attack Rating Against Undead\n' +
      'Adds 100-220 Cold Damage\n' +
      '-24% To Enemy Cold Resistance\n' +
      '+10 To Dexterity\n' +
      'Cannot Be Frozen\n' +
      '75% Extra Gold From Monsters\n' +
      '+1 To Light Radius',
  },
  {
    name: 'Wealth',
    item_type: 'runeword',
    sockets: 3,
    runeOrder: 'Lem + Ko + Tir',
    runeOrderInt: [19, 17, 2],
    cLvlReq: 43,
    id: 78,
    slotId: 2,
    basetype: ['Armors'],
    isLadderOnly: false,
    stats:
      '+10 to Dexterity\n' +
      '+2 to Mana After Each Kill\n' +
      '300% Extra Gold From Monsters\n' +
      '100% Better Chance of Getting Magic Items ',
  },
  {
    name: 'White',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Dol + Io',
    runeOrderInt: [13, 15],
    cLvlReq: 35,
    id: 79,
    slotId: 3,
    basetype: ['Wands'],
    isLadderOnly: false,
    stats:
      '+3 to Poison and Bone Skills (Necromancer Only)\n' +
      '+20% Faster Cast Rate\n' +
      '+2 to Bone Spear (Necromancer Only)\n' +
      '+4 to Skeleton Mastery (Necromancer Only)\n' +
      '+3 to Bone Armor (Necromancer Only)\n' +
      'Hit causes monster to flee 25%\n' +
      '+10 to vitality\n' +
      '+13 to mana\n' +
      'Magic Damage Reduced by 4',
  },
  {
    name: 'Wind',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Sur + El',
    runeOrderInt: [28, 0],
    cLvlReq: 61,
    id: 80,
    slotId: 3,
    basetype: ['Melee Weapons'],
    isLadderOnly: false,
    stats:
      '10% Chance To Cast Level 9 Tornado On Striking\n' +
      '+20% Faster Run/Walk\n' +
      '+40% Increased Attack Speed\n' +
      '+15% Faster Hit Recovery\n' +
      '+120-160% Enhanced Damage (varies)\n' +
      '-50% Target Defense\n' +
      '+50 To Attack Rating\n' +
      'Hit Blinds Target\n' +
      '+1 To Light Radius\n' +
      'Level 13 Twister (127 Charges)',
  },
  {
    name: 'Wrath',
    item_type: 'runeword',
    sockets: 4,
    runeOrder: 'Pul + Lum + Ber + Mal',
    runeOrderInt: [20, 16, 29, 22],
    cLvlReq: 63,
    id: 81,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      '30% Chance To Cast Level 1 Decrepify On Striking\n' +
      '5% Chance To Cast Level 10 Life Tap On Striking\n' +
      '+375% Damage To Demons\n' +
      '+100 To Attack Rating Against Demons\n' +
      '+250-300% Damage To Undead (varies)\n' +
      'Adds 85-120 Magic Damage\n' +
      'Adds 41-240 Lightning Damage\n' +
      '20% Chance of Crushing Blow\n' +
      'Prevent Monster Heal\n' +
      '+10 To Energy\n' +
      'Cannot Be Frozen',
  },
  {
    name: 'Zephyr',
    item_type: 'runeword',
    sockets: 2,
    runeOrder: 'Ort + Eth',
    runeOrderInt: [8, 4],
    cLvlReq: 21,
    id: 82,
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: false,
    stats:
      '7% Chance to Cast Level 1 Twister When Struck\n' +
      '+25% Faster Run/Walk\n' +
      '+25% Increased Attack Speed\n' +
      '+33% Enhanced Damage\n' +
      '-25% Target Defense\n' +
      '+66 to Attack Rating\n' +
      'Adds 1-50 lightning damage\n' +
      '+25 Defense ',
  },
  {
    name: 'Plague',
    item_type: 'runeword',
    sockets: 3,
    cLvlReq: 67,
    id: 83,
    runeOrder: 'Cham + Shael + Um',
    runeOrderInt: [31, 12, 21],
    slotId: 3,
    basetype: ['Swords', 'Katars', 'Daggers'],
    isLadderOnly: true,
    stats:
      '20% Chance to cast level 12 Lower Resist when struck\n' +
      '25% Chance to cast level 15 Poison Nova on striking\n' +
      'Level 13-17 Cleansing Aura When Equipped (varies)\n' +
      '+1-2 All Skills\n' +
      '+20% Increased Attack Speed\n' +
      '+220-320% Enhanced Damage (varies)\n' +
      '-23% To Enemy Poison Resistance\n' +
      '0.3% (0-29.7) Deadly Strike (Based on Character Level)\n' +
      '+25% Chance of Open Wounds\n' +
      'Freezes Target +3',
  },
  {
    name: 'Pattern',
    item_type: 'runeword',
    sockets: 3,
    cLvlReq: 23,
    id: 84,
    runeOrder: 'Tal + Ort + Thul',
    runeOrderInt: [6, 8, 9],
    slotId: 3,
    basetype: ['Katars'],
    isLadderOnly: true,
    stats:
      '+30% Faster Block Rate\n' +
      '+40-80% Enhanced Damage (varies)\n' +
      '10% Bonus to Attack Rating\n' +
      'Adds 17-62 Fire Damage\n' +
      'Adds 1-50 Lightning Damage\n' +
      'Adds 3-14 Cold Damage\n' +
      '+75 Poison Damage Over 5 Seconds\n' +
      '+6 to Strength\n' +
      '+6 to Dexterity\n' +
      'All Resistances +15',
  },
  {
    name: 'Unbending Will',
    item_type: 'runeword',
    sockets: 6,
    cLvlReq: 41,
    id: 85,
    runeOrder: 'Fal + Io + Ith + Eld + El + Hel',
    runeOrderInt: [18, 15, 5, 1, 0, 14],
    slotId: 3,
    basetype: ['Swords'],
    isLadderOnly: true,
    stats:
      '18% Chance to cast Level 18 Taunt on striking\n' +
      '+3 To Combat Skills (Barbarian Only)\n' +
      '+20-30% Increased Attack Speed (varies)\n' +
      '+300-350% Enhanced Damage (varies)\n' +
      '+9 To Maximum Damage\n' +
      '+50 To Attack Rating\n' +
      '+75% Damage to Undead\n' +
      '+50 Attack Rating Against Undead\n' +
      '8-10% Life Stolen Per Hit (varies)\n' +
      'Prevent Monster Heal\n' +
      '+10 To Strength\n' +
      '+10 To Vitality\n' +
      'Damage Reduced By 8\n' +
      '+1 Light Radius\n' +
      'Requirements -20%',
  },
  {
    name: 'Wisdom',
    item_type: 'runeword',
    sockets: 3,
    cLvlReq: 44,
    id: 86,
    runeOrder: 'Pul + Ith + Eld',
    runeOrderInt: [20, 5, 1],
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: true,
    stats:
      '+33% Piercing Attack\n' +
      '+15-25% Bonus to Attack Rating (varies)\n' +
      '4-8% Mana Stolen Per Hit (varies)\n' +
      '+30% Enhanced Defense\n' +
      '+10 Energy\n' +
      '15% Slower Stamina Drain\n' +
      'Cannot Be Frozen\n' +
      '+5 Mana After Each Kill\n' +
      '15% Damage Taken Goes to Mana',
  },
  {
    name: 'Obsession',
    item_type: 'runeword',
    sockets: 6,
    cLvlReq: 69,
    id: 87,
    runeOrder: 'Zod + Ist + Lem + Lum + Io + Nef',
    runeOrderInt: [32, 23, 19, 16, 15, 3],
    slotId: 3,
    basetype: ['Staves'],
    isLadderOnly: true,
    stats:
      'Indestructible\n' +
      '24% Chance to cast level 10 Weaken when struck\n' +
      '+4 To All Skills\n' +
      '+65% Faster Cast Rate\n' +
      '+60% Faster Hit Recovery\n' +
      'Knockback\n' +
      '+10 To Vitality\n' +
      '+10 To Energy\n' +
      'Increase Maximum Life 15-25% (varies)\n' +
      'Regenerate Mana 15-30% (varies)\n' +
      'All Resistances +60-70 (varies)\n' +
      '75% Extra Gold from Monsters\n' +
      '30% Better Chance of Getting Magic Items',
  },
  {
    name: 'Flickering Flame',
    item_type: 'runeword',
    sockets: 3,
    cLvlReq: 53,
    id: 88,
    runeOrder: 'Nef + Pul + Vex',
    runeOrderInt: [3, 20, 25],
    slotId: 1,
    basetype: ['Helms'],
    isLadderOnly: true,
    stats:
      'Level 4-8 Resist Fire Aura When Equipped (varies)\n' +
      '+3 To Fire Skills\n' +
      '-10-15% to Enemy Fire Resistance (varies)\n' +
      '+30% Enhanced Defense\n' +
      '+30 Defense Vs. Missile\n' +
      '+50-75 To Mana (varies)\n' +
      'Half Freeze Duration\n' +
      '+5% To Maximum Fire Resist\n' +
      'Poison Length Reduced by 50%',
  },
  {
    name: 'Mist',
    item_type: 'runeword',
    sockets: 5,
    cLvlReq: 67,
    id: 89,
    runeOrder: 'Cham + Shael + Gul + Thul + Ith',
    runeOrderInt: [31, 12, 24, 9, 5],
    slotId: 3,
    basetype: ['Bows', 'Crossbows'],
    isLadderOnly: true,
    stats:
      'Level 8-12 Concentration Aura When Equipped (varies)\n' +
      '+3 To All Skills\n' +
      '20% Increased Attack Speed\n' +
      '+100% Piercing Attack\n' +
      '+325-375% Enhanced Damage (varies)\n' +
      '+9 To Maximum Damage\n' +
      '20% Bonus to Attack Rating\n' +
      'Adds 3-14 Cold Damage\n' +
      'Freeze Target +3\n' +
      '+24 Vitality\n' +
      'All Resistances +40',
  },
];

import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import styled from 'styled-components/macro';
import { Rune } from '../../common/components/Rune';
import { ItemImage } from '../../common/components/ItemImage';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings, selectorIsD2r } from '../../store/settings/selectors';
import { GemImage } from '../../common/components/GemImage';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';

export const CubeUtility: React.FC = () => {
  const isD2r = useAppSelector(selectorIsD2r);
  const settings = useAppSelector(selectorCraftingSettings);
  const router = useIonRouter();
  return (
    <AppPage
      padding={true}
      title={'Utility Recipes'}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
    >
      <Item>
        <div>Fully Repaired Weapon</div>
        <div>
          <Rune
            forceInStock={!settings.showRunesInStock}
            withCount={settings.showRuneCount}
            withName={settings.showRuneNames}
            id={8}
            size={'30px'}
          />
        </div>
      </Item>

      <Item>
        <div>Fully Repaired Armor</div>
        <div>
          <Rune
            forceInStock={!settings.showRunesInStock}
            withCount={settings.showRuneCount}
            withName={settings.showRuneNames}
            id={7}
            size={'30px'}
          />
        </div>
      </Item>

      <Item>
        <div>Unsocket Item</div>
        <div>
          <Rune
            forceInStock={!settings.showRunesInStock}
            withCount={settings.showRuneCount}
            withName={settings.showRuneNames}
            id={14}
            size={'30px'}
          />
          <span>+</span>
          <ItemImage size={'30px'} miscName={'Scroll of Town Portal'} />
        </div>
      </Item>

      <Item>
        <div>Fully Repaired & Recharged Weapon</div>
        <div>
          <Rune
            forceInStock={!settings.showRunesInStock}
            withCount={settings.showRuneCount}
            withName={settings.showRuneNames}
            id={8}
            size={'30px'}
          />
          <span>+</span>
          <GemImage withName={settings.showGemNames} quality={'chipped'} type={'all'} size={'30px'} />
        </div>
      </Item>

      <Item>
        <div>Fully Repaired & Recharged Armor</div>
        <div>
          <Rune
            forceInStock={!settings.showRunesInStock}
            withCount={settings.showRuneCount}
            withName={settings.showRuneNames}
            id={7}
            size={'30px'}
          />
          <span>+</span>
          <GemImage withName={settings.showGemNames} quality={'flawed'} type={'all'} size={'30px'} />
        </div>
      </Item>

      <Item>
        <div>Full Rejuvenation Potion</div>
        <div>
          <ItemImage miscName={'Rejuvenation Potion'} size={'30px'} />
          <ItemImage miscName={'Rejuvenation Potion'} size={'30px'} />
          <ItemImage miscName={'Rejuvenation Potion'} size={'30px'} />
        </div>
      </Item>
    </AppPage>
  );
};

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 6px;
  align-items: center;
  padding: 20px 0;
  font-size: 20px;

  > div:last-of-type {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }

  & + & {
    border-top: 1px solid var(--border-color);
  }
`;

/* eslint-disable */
/* tslint:disable */

export const werform = ['Unchanged', 'Werebear', 'Werewolf'];

export const waffen: [string, number, number, number, number, number][] = [
  ['Unarmed', 0, 0, -1, 0, 0],
  ['Ancient Axe', 10, 6, -1, 0, 1],
  ['Ancient Sword', 0, 2, -1, 9, 0],
  ['Arbalest', -10, 8, -1, 1, 0],
  ['Archon Staff', 10, 6, -1, 0, 1],
  ['Ashwood Bow', 0, 7, 0, 1, 0],
  ['Ataghan', -20, 2, -1, 9, 0],
  ['Axe', 10, 2, -1, 0, 1],
  ['Balanced Axe', -10, 2, -1, 3, 0],
  ['Balanced Knife', -20, 4, -1, 3, 0],
  ['Ballista', 10, 8, -1, 1, 0],
  ['Balrog Blade', 0, 3, -1, 9, 1],
  ['Balrog Spear', 10, 4, -1, 2, 0],
  ['Barbed Club', 0, 2, -1, 0, 0],
  ['Bardiche', 10, 6, -1, 8, 0],
  ['Bastard Sword', 10, 3, -1, 9, 1],
  ['Battle Axe', 10, 6, -1, 0, 1],
  ['Battle Cestus', -10, 1, 1, 0, 0],
  ['Battle Dart', 0, 4, -1, 3, 0],
  ['Battle Hammer', 20, 2, -1, 0, 1],
  ['Battle Scythe', -10, 6, -1, 8, 1],
  ['Battle Staff', 0, 6, -1, 0, 1],
  ['Battle Sword', 0, 2, -1, 9, 1],
  ['Bearded Axe', 0, 6, -1, 0, 1],
  ['Bec-de-Corbin', 0, 6, -1, 8, 1],
  ['Berserker Axe', 0, 2, -1, 0, 1],
  ['Bill', 0, 6, -1, 8, 1],
  ['Blade Bow', -10, 7, -1, 1, 0],
  ['Blade Talons', -20, 1, 1, 0, 0],
  ['Blade', -10, 4, -1, 0, 0],
  ['Bone Knife', -20, 4, -1, 0, 0],
  ['Bone Wand', -20, 2, -1, 0, 0],
  ['Brandistock', -20, 5, -1, 8, 1],
  ['Broad Axe', 0, 6, -1, 0, 1],
  ['Broad Sword', 0, 2, -1, 9, 1],
  ['Burnt Wand', 0, 2, -1, 0, 0],
  ['Caduceus', -10, 2, -1, 0, 1],
  ['Cedar Bow', 0, 7, -1, 1, 0],
  ['Cedar Staff', 10, 6, -1, 0, 1],
  ['Ceremonial Bow', 10, 7, 0, 1, 0],
  ['Ceremonial Javelin', -10, 4, 0, 2, 0],
  ['Ceremonial Pike', 20, 5, 0, 8, 1],
  ['Ceremonial Spear', 0, 5, 0, 8, 1],
  ['Cestus', 0, 1, 1, 0, 0],
  ['Champion Axe', -10, 6, -1, 0, 1],
  ['Champion Sword', -10, 3, -1, 9, 1],
  ['Chu-Ko-Nu', -60, 8, -1, 1, 0],
  ['Cinquedeas', -20, 4, -1, 0, 0],
  ['Clasped Orb', 0, 2, 6, 0, 0],
  ['Claws', -10, 1, 1, 0, 0],
  ['Claymore', 10, 3, -1, 9, 1],
  ['Cleaver', 10, 2, -1, 0, 1],
  ['Cloudy Sphere', 0, 2, 6, 0, 0],
  ['Club', -10, 2, -1, 0, 0],
  ['Colossus Blade', 5, 3, -1, 9, 1],
  ['Colossus Crossbow', 10, 8, -1, 1, 0],
  ['Colossus Sword', 10, 3, -1, 9, 1],
  ['Colossus Voulge', 10, 6, -1, 8, 1],
  ['Composite Bow', -10, 7, -1, 1, 0],
  ['Conquest Sword', 0, 2, -1, 9, 1],
  ['Crossbow', 0, 8, -1, 1, 0],
  ['Crowbill', -10, 2, -1, 0, 1],
  ['Crusader Bow', 10, 7, -1, 1, 0],
  ['Cryptic Axe', 10, 6, -1, 8, 1],
  ['Cryptic Sword', -10, 2, -1, 9, 1],
  ['Crystal Sword', 0, 2, -1, 9, 1],
  ['Crystalline Globe', -10, 2, 6, 0, 0],
  ['Cudgel', -10, 2, -1, 0, 0],
  ['Cutlass', -30, 2, -1, 9, 0],
  ['Dacian Falx', 10, 3, -1, 9, 1],
  ['Dagger', -20, 4, -1, 0, 0],
  ['Decapitator', 10, 6, -1, 0, 1],
  ['Demon Crossbow', -60, 8, -1, 1, 0],
  ['Demon Heart', 0, 2, 6, 0, 0],
  ['Devil Star', 10, 2, -1, 0, 0],
  ['Diamond Bow', 0, 7, -1, 1, 0],
  ['Dimensional Blade', 0, 2, -1, 9, 1],
  ['Dimensional Shard', 10, 2, 6, 0, 0],
  ['Dirk', 0, 4, -1, 0, 0],
  ['Divine Scepter', -10, 2, -1, 0, 1],
  ['Double Axe', 10, 2, -1, 0, 1],
  ['Double Bow', -10, 7, -1, 1, 0],
  ['Eagle Orb', -10, 2, 6, 0, 0],
  ['Edge Bow', 5, 7, -1, 1, 0],
  ['Elder Staff', 0, 6, -1, 0, 1],
  ['Eldritch Orb', -10, 2, 6, 0, 0],
  ['Elegant Blade', -10, 2, -1, 9, 0],
  ['Espandon', 0, 3, -1, 9, 0],
  ['Ettin Axe', 10, 2, -1, 0, 1],
  ['Executioner Sword', 10, 3, -1, 9, 1],
  ['Falcata', 0, 2, -1, 9, 0],
  ['Falchion', 20, 2, -1, 9, 0],
  ['Fanged Knife', -20, 4, -1, 0, 0],
  ['Fascia', 10, 1, 1, 0, 0],
  ['Feral Axe', -15, 6, -1, 0, 1],
  ['Feral Claws', -20, 1, 1, 0, 0],
  ['Flail', -10, 2, -1, 0, 1],
  ['Flamberge', -10, 3, -1, 9, 1],
  ['Flanged Mace', 0, 2, -1, 0, 0],
  ['Flying Axe', 10, 2, -1, 3, 0],
  ['Francisca', 10, 2, -1, 3, 0],
  ['Fuscina', 0, 5, -1, 8, 1],
  ['Ghost Glaive', 20, 4, -1, 2, 0],
  ['Ghost Spear', 0, 5, -1, 8, 1],
  ['Ghost Wand', 10, 2, -1, 0, 0],
  ['Giant Axe', 10, 6, -1, 0, 1],
  ['Giant Sword', 0, 3, -1, 9, 1],
  ['Giant Thresher', -10, 6, -1, 8, 1],
  ['Gladius', 0, 2, -1, 9, 0],
  ['Glaive', 20, 4, -1, 2, 0],
  ['Glorious Axe', 10, 6, -1, 0, 1],
  ['Glowing Orb', -10, 2, 6, 0, 0],
  ['Gnarled Staff', 10, 6, -1, 0, 1],
  ['Gorgon Crossbow', 0, 8, -1, 1, 0],
  ['Gothic Axe', -10, 6, -1, 0, 1],
  ['Gothic Bow', 10, 7, -1, 1, 0],
  ['Gothic Staff', 0, 6, -1, 0, 1],
  ['Gothic Sword', 10, 3, -1, 9, 1],
  ['Grand Matron Bow', 10, 7, 0, 1, 0],
  ['Grand Scepter', 10, 2, -1, 0, 0],
  ['Grave Wand', 0, 2, -1, 0, 0],
  ['Great Axe', -10, 6, -1, 0, 1],
  ['Great Bow', -10, 7, -1, 1, 0],
  ['Great Maul', 20, 6, -1, 0, 1],
  ['Great Pilum', 0, 4, -1, 2, 0],
  ['Great Poleaxe', 0, 6, -1, 8, 1],
  ['Great Sword', 10, 3, -1, 9, 1],
  ['Greater Claws', -20, 1, 1, 0, 0],
  ['Greater Talons', -30, 1, 1, 0, 0],
  ['Grim Scythe', -10, 6, -1, 8, 1],
  ['Grim Wand', 0, 2, -1, 0, 0],
  ['Halberd', 0, 6, -1, 8, 1],
  ['Hand Axe', 0, 2, -1, 0, 0],
  ['Hand Scythe', -10, 1, 1, 0, 0],
  ['Harpoon', -10, 4, -1, 2, 0],
  ['Hatchet Hands', 10, 1, 1, 0, 0],
  ['Hatchet', 0, 2, -1, 0, 0],
  ['Heavenly Stone', -10, 2, 6, 0, 0],
  ['Heavy Crossbow', 10, 8, -1, 1, 0],
  ['Highland Blade', -5, 3, -1, 9, 1],
  ['Holy Water Sprinkler', 10, 2, -1, 0, 0],
  ["Hunter's Bow", -10, 7, -1, 1, 0],
  ['Hurlbat', -10, 2, -1, 3, 0],
  ['Hydra Bow', 10, 7, -1, 1, 0],
  ['Hydra Edge', 10, 2, -1, 9, 0],
  ['Hyperion Javelin', -10, 4, -1, 2, 0],
  ['Hyperion Spear', -10, 5, -1, 8, 0],
  ['Jagged Star', 10, 2, -1, 0, 0],
  ["Jared's Stone", 10, 2, 6, 0, 0],
  ['Javelin', -10, 4, -1, 2, 0],
  ['Jo Staff', -10, 6, -1, 0, 0],
  ['Katar', -10, 1, 1, 0, 0],
  ['Knout', -10, 2, -1, 0, 1],
  ['Kris', -20, 4, -1, 0, 0],
  ['Lance', 20, 5, -1, 8, 1],
  ['Large Axe', -10, 6, -1, 0, 1],
  ['Large Siege Bow', 10, 7, -1, 1, 0],
  ['Legend Spike', -10, 4, -1, 0, 0],
  ['Legend Sword', -15, 3, -1, 9, 0],
  ['Legendary Mallet', 20, 2, -1, 0, 1],
  ['Lich Wand', -20, 2, -1, 0, 0],
  ['Light Crossbow', -10, 8, -1, 1, 0],
  ['Lochaber Axe', 10, 6, -1, 8, 0],
  ['Long Battle Bow', 10, 7, -1, 1, 0],
  ['Long Bow', 0, 7, -1, 1, 0],
  ['Long Staff', 0, 6, -1, 0, 0],
  ['Long Sword', -10, 2, -1, 9, 1],
  ['Long War Bow', 10, 7, -1, 1, 0],
  ['Mace', 0, 2, -1, 0, 0],
  ['Maiden Javelin', -10, 4, 0, 2, 0],
  ['Maiden Pike', 10, 5, 0, 8, 1],
  ['Maiden Spear', 0, 5, 0, 8, 1],
  ['Mancatcher', -20, 5, -1, 8, 1],
  ['Martel de Fer', 20, 6, -1, 0, 1],
  ['Matriarchal Bow', -10, 7, 0, 1, 0],
  ['Matriarchal Javelin', -10, 4, 0, 2, 0],
  ['Matriarchal Pike', 20, 5, 0, 8, 1],
  ['Matriarchal Spear', 0, 5, 0, 8, 1],
  ['Maul', 10, 6, -1, 0, 1],
  ['Mighty Scepter', 0, 2, -1, 0, 0],
  ['Military Axe', -10, 6, -1, 0, 1],
  ['Military Pick', -10, 2, -1, 0, 1],
  ['Mithril Point', 0, 4, -1, 0, 0],
  ['Morning Star', 10, 2, -1, 0, 0],
  ['Mythical Sword', 0, 2, -1, 9, 0],
  ['Naga', 0, 2, -1, 0, 1],
  ['Ogre Axe', 0, 6, -1, 8, 0],
  ['Ogre Maul', 10, 6, -1, 0, 1],
  ['Partizan', 10, 6, -1, 8, 1],
  ['Pellet Bow', -10, 8, -1, 1, 0],
  ['Petrified Wand', 10, 2, -1, 0, 0],
  ['Phaseblade', -30, 2, -1, 9, 1],
  ['Pike', 20, 5, -1, 8, 1],
  ['Pilum', 0, 4, -1, 2, 0],
  ['Poignard', -20, 4, -1, 0, 0],
  ['Poleaxe', 10, 6, -1, 8, 1],
  ['Polished Wand', 0, 2, -1, 0, 0],
  ['Quarterstaff', 0, 6, -1, 0, 0],
  ['Quhab', 0, 1, 1, 0, 0],
  ['Razor Bow', -10, 7, -1, 1, 0],
  ['Reflex Bow', 10, 7, 0, 1, 0],
  ['Reinforced Mace', 0, 2, -1, 0, 0],
  ['Repeating Crossbow', -40, 8, -1, 1, 0],
  ['Rondel', 0, 4, -1, 0, 0],
  ['Rune Bow', 0, 7, -1, 1, 0],
  ['Rune Scepter', 0, 2, -1, 0, 0],
  ['Rune Staff', 20, 6, -1, 0, 1],
  ['Rune Sword', -10, 2, -1, 9, 1],
  ['Runic Talons', -30, 1, 1, 0, 0],
  ['Sabre', -10, 2, -1, 9, 0],
  ['Sacred Globe', -10, 2, 6, 0, 0],
  ['Scepter', 0, 2, -1, 0, 0],
  ['Scimitar', -20, 2, -1, 9, 0],
  ['Scissors Katar', -10, 1, 1, 0, 0],
  ['Scissors Quhab', 0, 1, 1, 0, 0],
  ['Scissors Suwayyah', 0, 1, 1, 0, 0],
  ['Scourge', -10, 2, -1, 0, 1],
  ['Scythe', -10, 6, -1, 8, 1],
  ['Seraph Rod', 10, 2, -1, 0, 0],
  ['Shadow Bow', 0, 7, -1, 1, 0],
  ['Shamshir', -10, 2, -1, 9, 0],
  ['Shillelagh', 0, 6, -1, 0, 1],
  ['Short Battle Bow', 0, 7, -1, 1, 0],
  ['Short Bow', 5, 7, -1, 1, 0],
  ['Short Siege Bow', 0, 7, -1, 1, 0],
  ['Short Spear', 10, 4, -1, 2, 0],
  ['Short Staff', -10, 6, -1, 0, 0],
  ['Short Sword', 0, 2, -1, 9, 0],
  ['Short War Bow', 0, 7, -1, 1, 0],
  ['Siege Crossbow', 0, 8, -1, 1, 0],
  ['Silver-edged Axe', 0, 6, -1, 0, 1],
  ['Simbilan', 10, 4, -1, 2, 0],
  ['Small Crescent', 10, 2, -1, 0, 1],
  ['Smoked Sphere', 0, 2, 6, 0, 0],
  ['Sparkling Ball', 0, 2, 6, 0, 0],
  ['Spear', -10, 5, -1, 8, 0],
  ['Spetum', 0, 5, -1, 8, 1],
  ['Spiculum', 20, 4, -1, 2, 0],
  ['Spider Bow', 5, 7, -1, 1, 0],
  ['Spiked Club', 0, 2, -1, 0, 0],
  ['Stag Bow', 0, 7, 0, 1, 0],
  ['Stalagmite', 10, 6, -1, 0, 0],
  ['Stiletto', -10, 4, -1, 0, 0],
  ['Stygian Pike', 0, 5, -1, 8, 1],
  ['Stygian Pilum', 0, 4, -1, 2, 0],
  ['Suwayyah', 0, 1, 1, 0, 0],
  ['Swirling Crystal', 10, 2, 6, 0, 0],
  ['Tabar', 10, 6, -1, 0, 1],
  ['Thresher', -10, 6, -1, 8, 1],
  ['Throwing Axe', 10, 2, -1, 3, 0],
  ['Throwing Knife', 0, 4, -1, 3, 0],
  ['Throwing Spear', -10, 4, -1, 2, 0],
  ['Thunder Maul', 20, 6, -1, 0, 1],
  ['Tomahawk', 0, 2, -1, 0, 0],
  ['Tomb Wand', -20, 2, -1, 0, 0],
  ['Trident', 0, 5, -1, 8, 1],
  ['Truncheon', -10, 2, -1, 0, 0],
  ['Tulwar', 20, 2, -1, 9, 0],
  ['Tusk Sword', 0, 3, -1, 9, 1],
  ['Twin Axe', 10, 2, -1, 0, 1],
  ['Two-Handed Sword', 0, 3, -1, 9, 0],
  ['Tyrant Club', 0, 2, -1, 0, 0],
  ['Unearthed Wand', 0, 2, -1, 0, 0],
  ['Vortex Orb', 0, 2, 6, 0, 0],
  ['Voulge', 0, 6, -1, 8, 1],
  ['Walking Stick', -10, 6, -1, 0, 0],
  ['Wand', 0, 2, -1, 0, 0],
  ['War Axe', 0, 2, -1, 0, 1],
  ['War Club', 10, 6, -1, 0, 0],
  ['War Dart', -20, 4, -1, 3, 0],
  ['War Fist', 10, 1, 1, 0, 0],
  ['War Fork', -20, 5, -1, 8, 1],
  ['War Hammer', 20, 2, -1, 0, 1],
  ['War Javelin', -10, 4, -1, 2, 0],
  ['War Pike', 20, 5, -1, 8, 1],
  ['War Scepter', -10, 2, -1, 0, 1],
  ['War Scythe', -10, 6, -1, 8, 1],
  ['War Spear', -10, 5, -1, 8, 0],
  ['War Spike', -10, 2, -1, 0, 1],
  ['War Staff', 20, 6, -1, 0, 1],
  ['War Sword', 0, 2, -1, 9, 0],
  ['Ward Bow', 0, 7, -1, 1, 0],
  ['Winged Axe', -10, 2, -1, 3, 0],
  ['Winged Harpoon', -10, 4, -1, 2, 0],
  ['Winged Knife', -20, 4, -1, 3, 0],
  ['Wrist Blade', 0, 1, 1, 0, 0],
  ['Wrist Spike', -10, 1, 1, 0, 0],
  ['Wrist Sword', -10, 1, 1, 0, 0],
  ['Yari', 0, 5, -1, 8, 1],
  ['Yew Wand', 10, 2, -1, 0, 0],
  ['Zweihander', -10, 3, -1, 9, 1],
];

export const waffengattung: [number[], ...Array<any>][] = [
  [[13, 13], [11, 12], [12, 12], [16, 16], [15, 15], [14, 14], [16, 16], 0, 0, 0, 'Unarmed'],
  [[0, 0], [11, 12], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], 0, 0, 0, 'claw'],
  [[16, 16], [15, 15], [16, 16], [19, 19], [19, 19], [15, 15], [20, 20], 0, 0, [16, 16], 'one-handed swinging weapon'],
  [[20, 20], [23, 23], [18, 18], [21, 21], [23, 23], [18, 19], [24, 24], 0, 0, [16, 16], 'two-handed sword'],
  [[15, 15], [15, 15], [16, 16], [19, 19], [19, 19], [17, 17], [19, 19], 0, [16, 16], 0, 'one-handed thrusting weapon'],
  [[18, 18], [23, 23], [19, 19], [23, 23], [24, 24], [20, 20], [23, 23], 0, [16, 16], 0, 'spear'],
  [[20, 20], [19, 19], [19, 19], [17, 17], [20, 20], [18, 18], [18, 18], 0, [16, 16], 0, 'two-handed weapon'],
  [[14, 14], [16, 16], [15, 15], [16, 16], [18, 18], [16, 16], [17, 17], [15, 15], 0, 0, 'bow'],
  [[20, 20], [21, 21], [20, 20], [20, 20], [20, 20], [20, 20], [20, 20], 0, 0, 0, 'crossbow'],
  //@ts-ignore
  [16, 16, 16, 18, 20, 16, 20],
];
export const aktionsframe = [
  [8, 6, 6, 8, 8, 7, 9],
  [0, 0, 0, 0, 0, 0, 0],
  [10, 7, 7, 9, 9, 7, 12],
  [12, 11, 8, 10, 11, 8, 14],
  [9, 7, 7, 8, 9, 8, 11],
  [11, 10, 9, 9, 10, 8, 13],
  [12, 9, 9, 9, 11, 9, 11],
  [6, 7, 7, 8, 9, 8, 9],
  [9, 10, 10, 10, 11, 10, 11],
];
export const fertigkeiten: [string, number, number, number, number][] = [
  ['Standard', 0, 1, 0, 100],
  ['Throw', 1, 2, 0, 100],
  ['Impale', 2, 7, 0, 100],
  ['Jab', 3, 7, 1, 100],
  ['Strafe', 4, 0, 0, 50],
  ['Fend', 5, 0, 0, 40],
  ['Tiger Strike', 6, 0, 0, 100],
  ['Cobra Strike', 7, 0, 0, 100],
  ['Phoenix Strike', 8, 0, 0, 100],
  ['Fists of Fire', 9, 7, 2, 100],
  ['Claws of Thunder', 10, 7, 2, 100],
  ['Blades of Ice', 11, 7, 2, 100],
  ['Dragon Claw', 12, 7, 2, 100],
  ['Dragon Tail', 13, 3, 0, 100],
  ['Dragon Talon', 14, 3, 0, 0],
  ['Laying Traps', 15, 5, 0, 100],
  ['Double Swing', 16, 7, 3, 100],
  ['Frenzy', 17, 7, 3, 100],
  ['Double Throw', 18, 7, 4, 100],
  ['Whirlwind', 19, 7, 0, 100],
  ['Concentrate', 20, 0, 0, 100],
  ['Berserk', 21, 0, 0, 100],
  ['Bash', 22, 0, 0, 100],
  ['Stun', 23, 0, 0, 100],
  ['Zeal', 24, 0, 0, 0],
  ['Smite', 25, 4, 0, 100],
  ['Feral Rage', 26, 0, 0, 100],
  ['Hunger', 27, 6, 0, 100],
  ['Rabies', 28, 6, 0, 100],
  ['Fury', 29, 0, 0, 0],
  ['Sacrifice', 30, 0, 0, 100],
  ['Vengeance', 31, 0, 0, 100],
  ['Conversion', 32, 0, 0, 100],
];
export const sequenzen = [
  [0, 0, 0, 0, 21, 24, 0, 0, 0],
  [0, 0, 0, 0, 18, 21, 0, 0, 0],
  [12, 12, 16, 0, 0, 0, 0, 0, 0],
  [0, 0, 17, 17, 17, 0, 0, 0, 0],
  [0, 0, 12, 0, 12, 0, 0, 0, 0],
];
export const startframe = [1, 0, 2, 2, 2, 2, 2, 0, 0];

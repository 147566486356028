import React, { useEffect, useState } from 'react';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import semver from 'semver/preload';
import { IonicSafeString, useIonAlert, useIonRouter, useIonToast } from '@ionic/react';
import { SplashScreen } from '@capacitor/splash-screen';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorPlatform } from '../../store/app/selectors';
import { CHANGE_LOG } from '../../pages/app-info/components/change_log';

const APP_STORE_ID = 'id1446555252';
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=uk.co.alexanderjs.runewordhelper';

interface UpdateData {
  web: {
    version: string;
    updateUrl: string;
    date: string;
    notes: string[];
  };
  android: {
    version: string;
    date: string;
    notes: string[];
  };
  apple: {
    version: string;
    date: string;
    notes: string[];
  };
}

interface Context {
  version: string;
  webUpdate: {
    available: boolean;
    progress: number;
  };
  nativeUpdate: boolean;
  checkForUpdate: () => void;
  takeUserToNativeStore: () => void;
}

export const UpdateContext = React.createContext({} as Context);

const CURRENT_VERSION = CHANGE_LOG.at(0)!.version;
// const CURRENT_VERSION = '5.0.11';
export const UpdateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [updateData, setUpdateData] = useState<UpdateData>();
  const [appActive, setAppActive] = useState(true);
  const [webUpdate, setWebUpdate] = useState<{ available: boolean; progress: number }>({ available: false, progress: 0 });
  const [nativeUpdate, setNativeUpdate] = useState(false);

  const platform = useAppSelector(selectorPlatform);

  const [alert] = useIonAlert();
  const [toast] = useIonToast();
  const router = useIonRouter();

  useEffect(() => {
    CapacitorUpdater.notifyAppReady();
    App.addListener('appStateChange', state => setAppActive(state.isActive));
    CapacitorUpdater.addListener('download', e => setWebUpdate({ available: true, progress: e.percent }));
  }, []);

  useEffect(() => {
    if (platform) {
      fetchUpdateData().then(setUpdateData);
    }
  }, [platform]);

  useEffect(() => {
    if (platform && platform !== 'web' && appActive && updateData) {
      checkAndDoWebAppUpdate();
      checkAndDoNativeUpdate();
    }
  }, [platform, appActive, updateData]);

  async function checkAndDoWebAppUpdate(): Promise<boolean> {
    return new Promise(resolve => {
      if (semver.gt(updateData!.web.version, CURRENT_VERSION)) {
        setWebUpdate({ available: true, progress: 0 });
        toast({
          message: 'An update is downloading',
          duration: 2500,
          buttons: [{ text: 'Check Progress', handler: () => router.push(appRoutes.appInfo.path('about')) }],
        });
        CapacitorUpdater.download({
          url: updateData!.web.updateUrl,
          version: updateData!.web.version,
        }).then(bundle => {
          if (bundle) {
            alert({
              header: 'An update has been downloaded',
              buttons: [
                {
                  text: 'Install now',
                  handler: () => installWebUpdate(bundle),
                },
              ],
            });
            resolve(true);
          }
        });
      }
      resolve(false);
    });
  }

  async function checkAndDoNativeUpdate() {
    const info = await App.getInfo();
    return new Promise(resolve => {
      let message;

      if (platform === 'android' && semver.gt(updateData!.android.version, info.version)) {
        let notes = '';
        if (updateData?.android.notes.length) {
          notes = '<ul>';
          for (const line of updateData.android.notes) {
            notes += '<li>' + line + '</li>';
          }
          notes += '</ul>';
        }
        message = new IonicSafeString(notes);
      }

      if (platform === 'ios' && semver.gt(updateData!.apple.version, info.version)) {
        let notes = '';
        if (updateData?.apple.notes.length) {
          notes = '<ul>';
          for (const line of updateData.apple.notes) {
            notes += '<li>' + line + '</li>';
          }
          notes += '</ul>';
        }
        message = new IonicSafeString(notes);
      }

      if (message !== undefined) {
        setNativeUpdate(true);
        alert({
          header: 'An update is available',
          message: message,
          buttons: [
            {
              text: 'Update Now',
              handler: () => takeUserToNativeStore(),
            },
            {
              text: 'Later',
              role: 'destructive',
            },
          ],
        });
        resolve(true);
      }
      resolve(false);
    });
  }

  async function installWebUpdate(bundle: BundleInfo) {
    const current = await CapacitorUpdater.current();
    try {
      await SplashScreen.show();
      await CapacitorUpdater.set(bundle);
      await CapacitorUpdater.delete({ id: current.bundle.id });
      router.push(appRoutes.appInfo.path('change-log'));
    } catch (e) {
      console.error(e);
    } finally {
      await SplashScreen.hide();
    }
  }

  const checkForUpdate = async () => {
    const web = await checkAndDoWebAppUpdate();
    const native = await checkAndDoNativeUpdate();
    if (!web && !native) {
      await toast({ message: 'No new updates', duration: 2000 });
    }
  };

  const takeUserToNativeStore = async () => {
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === 'ios') {
      window.open(`itms-apps://itunes.ios.com/app/${APP_STORE_ID}`);
    } else {
      window.open(PLAY_STORE_URL);
    }
  };

  return (
    <UpdateContext.Provider value={{ webUpdate, nativeUpdate, version: CURRENT_VERSION, checkForUpdate, takeUserToNativeStore }}>
      {children}
    </UpdateContext.Provider>
  );
};

const fetchUpdateData = (): Promise<UpdateData> => {
  return new Promise(resolve => {
    fetch('https://alexanderjs.co.uk/versioning/tomeofd2-update.json', { cache: 'no-store' })
      .then(res => {
        if (res.ok && res.status === 200) {
          res.json().then(data => {
            resolve(data);
          });
        }
      })
      .catch(err => console.error(err));
  });
};

import React, { useMemo, useRef, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { ViewRuneword } from '../../common/components/ViewRuneword/ViewRuneword';
import styled, { css } from 'styled-components/macro';
import { useAppSelector } from '../../store/store';
import { selectorRunewords } from '../../store/activeData/selector';
import { IonSearchbar } from '@ionic/react';
import { Runeword } from '../../common/types';
import { useOnClickOutside, useOpenCloseDialog } from '../../common/hooks';
import { FilterModal } from './components/FilterModal';

export type SocketOperator = 'Any' | 'Less than' | 'Equal to' | 'Greater than';

export interface RunewordsFilterData {
  minLevel: number;
  maxLevel: number;
  includeLadder: boolean;
  socketOperator: SocketOperator;
  socketNumber: number;
  slot: number;
  highlightInStock: boolean;
}

export const initialFilterData: RunewordsFilterData = {
  minLevel: 11,
  maxLevel: 71,
  includeLadder: true,
  socketOperator: 'Any',
  socketNumber: 6,
  slot: 0,
  highlightInStock: true,
};

export const Runewords: React.FC = () => {
  const [filterDialog, setFilterDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState({ ...initialFilterData });
  const runewords = useAppSelector(selectorRunewords);
  const container = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();
  const fab = useRef<HTMLButtonElement>(null);
  const dialog = useRef<HTMLDialogElement>(null);
  const dialogContainer = useRef<HTMLDivElement>(null);
  useOpenCloseDialog(dialog, filterDialog, undefined);
  useOnClickOutside(dialogContainer, () => setFilterDialog(false));

  const filtered = useMemo(() => {
    const temp: Runeword[] = [];

    for (const rw of runewords) {
      if (
        rw.name.toLowerCase().includes(searchTerm) ||
        rw.basetype.some(bt => bt.toLowerCase().includes(searchTerm)) ||
        rw.stats.toLowerCase().includes(searchTerm) ||
        rw.runeOrder.toLowerCase().includes(searchTerm)
      ) {
        if (rw.cLvlReq >= filterData.minLevel && rw.cLvlReq <= filterData.maxLevel) {
          if (filterData.includeLadder || (!filterData.includeLadder && !rw.isLadderOnly)) {
            if (filterData.slot === 0 || rw.slotId === filterData.slot)
              switch (filterData.socketOperator) {
                case 'Any':
                  temp.push(rw);
                  break;
                case 'Less than':
                  if (rw.sockets <= filterData.socketNumber) {
                    temp.push(rw);
                  }
                  break;
                case 'Equal to':
                  if (rw.sockets === filterData.socketNumber) {
                    temp.push(rw);
                  }
                  break;
                case 'Greater than':
                  if (rw.sockets >= filterData.socketNumber) {
                    temp.push(rw);
                  }
              }
          }
        }
      }
    }
    return temp;
  }, [runewords, searchTerm, filterData]);

  const onScroll = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (fab.current) {
      fab.current.style.opacity = '0';
      fab.current.style.transform = 'scale3d(.1,.1,.1)';
    }

    timeout.current = setTimeout(() => {
      if (fab.current) {
        fab.current.style.opacity = '1';
        fab.current.style.transform = 'scale3d(1,1,1)';
      }
    }, 100);
  };

  return (
    <AppPage
      padding={true}
      title={'Runewords'}
      onScroll={onScroll}
      headerContent={
        <IonSearchbar
          animated={true}
          placeholder={'Search anything...'}
          mode={'ios'}
          color={'medium'}
          onIonInput={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSearchTerm(e.detail?.value.toLowerCase() as string);
          }}
        />
      }
    >
      <Container ref={container}>
        {filtered.map(r => (
          <ViewRuneword key={`rw_${r.id}`} runeword={r} forceRuneInStock={!filterData.highlightInStock} />
        ))}
      </Container>

      <FAB ref={fab} applied={JSON.stringify(filterData) !== JSON.stringify(initialFilterData)} onClick={() => setFilterDialog(true)} />
      <FilterModal filterData={filterData} setFilterData={setFilterData} isOpen={filterDialog} onClose={() => setFilterDialog(false)} />
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const FAB = styled.button<{ applied: boolean }>`
  position: absolute;
  width: 60px;
  height: 60px;
  right: 30px;
  bottom: 60px;
  background: var(--ion-color-${props => (props.applied ? 'success' : 'primary')});
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  transition: all 500ms;
  box-shadow: black 1px 1px;
  border: none;
  outline: none;

  &:before {
    content: 'FILTERS';
  }

  ${({ applied }) =>
    applied &&
    css`
      :after {
        content: '✓';
      }
    `}
`;

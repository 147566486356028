import React, { useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { useAppSelector } from '../../store/store';
import { selectorTerrorZones } from '../../store/activeData/selector';
import { selectorPatch } from '../../store/settings/selectors';
import { IonSegment, IonSegmentButton, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { TerrorZones } from '../../common/data/terror-zones/terror_zones_2.6';
import styled from 'styled-components/macro';

type TZK = keyof TerrorZones;
export const TerrorZonesPage: React.FC = () => {
  const [section, setSection] = useState<TZK>('scaling');
  const terrorZones = useAppSelector(selectorTerrorZones);
  const patch = useAppSelector(selectorPatch);
  const router = useIonRouter();

  useEffect(() => {
    console.log('terror zone UE');
    if (patch < 2) router.push(appRoutes.home.path());
  }, [patch]);

  return (
    <AppPage
      title={'Terror Zones'}
      padding={false}
      headerContent={
        <IonSegment mode={'md'} value={section} onIonChange={e => setSection(e.target.value as TZK)} scrollable>
          <IonSegmentButton value={'scaling'}>Info</IonSegmentButton>
          <IonSegmentButton value={'act1'}>Act 1</IonSegmentButton>
          <IonSegmentButton value={'act2'}>Act 2</IonSegmentButton>
          <IonSegmentButton value={'act3'}>Act 3</IonSegmentButton>
          <IonSegmentButton value={'act4'}>Act 4</IonSegmentButton>
          <IonSegmentButton value={'act5'}>Act 5</IonSegmentButton>
        </IonSegment>
      }
    >
      <Container>
        {section === 'scaling' && terrorZones && (
          <Info>
            <div style={{ padding: '16px' }}>
              <div>
                The level of monsters in Terror Zones will be at least two levels higher than your current level, up to a maximum per
                difficulty (see below), but never lower. This means that if a Level 45 player creates a game in Nightmare difficulty, in
                which a Level 55 zone becomes a Terror Zone, Standard Monsters will still be Level 55, not Level 47. Terror Zones will never
                scale lower than the base level of the original zone.
              </div>
              <br />
              <div>
                <b>
                  To unlock Terror Zones for the first time, you will have to defeat Baal on the character and difficulty you want to play
                  in Terror Zones
                </b>
                <br />
                <b>Afterwards, you can activate Terror Zones by enabling them for your game.</b>
                <br />
                <b>
                  You will then see a <span style={{ color: '#9370DB' }}>purple message</span> in your chat window that tells you which zone
                  is affected.
                </b>
              </div>
            </div>

            <Table>
              <tbody>
                <tr>
                  <th>Monster</th>
                  <th>Normal</th>
                  <th>Nightare</th>
                  <th>Hell</th>
                </tr>
                <tr>
                  <th>Standard</th>
                  <td>{terrorZones.scaling.normal.standard}</td>
                  <td>{terrorZones.scaling.nightmare.standard}</td>
                  <td>{terrorZones.scaling.hell.standard}</td>
                </tr>
                <tr>
                  <th className={'magic'}>Champion</th>
                  <td>{terrorZones.scaling.normal.champion}</td>
                  <td>{terrorZones.scaling.nightmare.champion}</td>
                  <td>{terrorZones.scaling.hell.champion}</td>
                </tr>
                <tr>
                  <th className={'unique'}>Unique</th>
                  <td>{terrorZones.scaling.normal.unique}</td>
                  <td>{terrorZones.scaling.nightmare.unique}</td>
                  <td>{terrorZones.scaling.hell.unique}</td>
                </tr>
              </tbody>
            </Table>
          </Info>
        )}
        {section !== 'scaling' && terrorZones && (
          <Ul>
            {terrorZones[section].map(z => (
              <li key={`tz-${z}`}>{z}</li>
            ))}
          </Ul>
        )}
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 2px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: white;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid var(--border-color);
  border-collapse: collapse;

  td,
  th {
    border: 1px solid var(--border-color);
    border-collapse: collapse;
  }

  td {
    text-align: center;
    font-size: 14px;
  }
`;

const Ul = styled.ul`
  > li {
    font-size: 16px;
    line-height: 200%;
  }
`;

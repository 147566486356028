import React from 'react';
import { SettingsContainer } from '../Settings';
import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorAppBarAction, selectorAppBarAction2 } from '../../../store/settings/selectors';
import { setTabBarAction, SettingsPageOrder, settingsPageOrder } from '../../../store/settings/settingsSlice';
import AppLink from '../../../common/components/AppLink';
import styled from 'styled-components/macro';

export const TabBarSettings: React.FC = () => {
  const action = useAppSelector(selectorAppBarAction);
  const action2 = useAppSelector(selectorAppBarAction2);
  const dispatch = useAppDispatch();

  const onChange = (tab: 1 | 2, page: SettingsPageOrder) => {
    dispatch(setTabBarAction({ tab, action: page }));
  };

  return (
    <SettingsContainer>
      {action !== 'none' && (
        <LinkWrapper>
          <AppLink page={action} asTab />
        </LinkWrapper>
      )}
      <IonItem lines={'none'}>
        <IonSelect
          label={'Tab Bar Action Button:'}
          value={action}
          interface={'action-sheet'}
          mode={'md'}
          onIonChange={e => onChange(1, e.detail.value as SettingsPageOrder)}
        >
          <>
            {settingsPageOrder
              .filter(o => o !== action2)
              .map(o => (
                <IonSelectOption key={`action_${o}`} value={o} className={'white'}>
                  {o}
                </IonSelectOption>
              ))}
            <IonSelectOption value={'none'} className={'white'}>
              None
            </IonSelectOption>
          </>
        </IonSelect>
      </IonItem>
      {action2 !== 'none' && (
        <LinkWrapper>
          <AppLink page={action2} asTab />
        </LinkWrapper>
      )}
      <IonItem lines={'none'}>
        <IonSelect
          label={'Tab Bar Action Button 2:'}
          value={action2}
          interface={'action-sheet'}
          mode={'md'}
          onIonChange={e => onChange(2, e.detail.value as SettingsPageOrder)}
        >
          <>
            {settingsPageOrder
              .filter(o => o !== action)
              .map(o => (
                <IonSelectOption key={`action2_${o}`} value={o} className={'white'}>
                  {o}
                </IonSelectOption>
              ))}
            <IonSelectOption value={'none'} className={'white'}>
              None
            </IonSelectOption>
          </>
        </IonSelect>
      </IonItem>
    </SettingsContainer>
  );
};

const LinkWrapper = styled.div`
  margin-top: 20px;

  * > {
    width: 80px !important;
  }
`;

import React, { useEffect, useState } from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { useParams } from 'react-router';
import { useAppSelector } from '../../store/store';
import { selectorArea } from '../../store/activeData/selector';
import { IonIcon, IonSegment, IonSegmentButton, IonSelect, IonSelectOption } from '@ionic/react';
import styled from 'styled-components/macro';
import { ACT_FIVE, ACT_FOUR, ACT_ONE, ACT_THREE, ACT_TWO, IAct } from '../../common/data/areas/act_maps';
import { selectorIsD2r } from '../../store/settings/selectors';
import { informationCircleOutline } from 'ionicons/icons';
import { ImmunitiesDialog } from './ImmunitiesDialog';

export type AreasSection = 'map' | 'norm' | 'nm' | 'hell';
export const Areas: React.FC = () => {
  const { act } = useParams<{ act: string }>();
  const [dialog, setDialog] = useState(false);
  const [sort, setSort] = useState<0 | 1>(0);
  const [section, setSection] = useState<AreasSection>('map');
  const area = useAppSelector(state => selectorArea(state, sort, section, act));
  const [map, setMap] = useState<IAct[]>();
  const isD2r = useAppSelector(selectorIsD2r);

  useEffect(() => {
    if (!area) {
      switch (act) {
        case '1':
          setMap(ACT_ONE);
          break;
        case '2':
          setMap(ACT_TWO);
          break;
        case '3':
          setMap(ACT_THREE);
          break;
        case '4':
          setMap(ACT_FOUR);
          break;
        case '5':
          setMap(ACT_FIVE);
          break;
        case 'all':
          setSection('hell');
      }
    }
  }, [area]);

  return (
    <AppPage
      padding={true}
      title={act == 'all' ? 'All Acts' : `Act ${act}`}
      headerContent={
        <IonSegment value={section} onIonChange={e => setSection(e.detail.value as AreasSection)} mode={'md'}>
          {act !== 'all' && <IonSegmentButton value={'map'}>Map</IonSegmentButton>}
          <IonSegmentButton value={'norm'}>Normal</IonSegmentButton>
          <IonSegmentButton value={'nm'}>Nightmare</IonSegmentButton>
          <IonSegmentButton value={'hell'}>Hell</IonSegmentButton>
        </IonSegment>
      }
      buttons={
        section === 'map' ? undefined : (
          <IonIcon icon={informationCircleOutline} style={{ fontSize: '25px' }} color={'primary'} onClick={() => setDialog(true)} />
        )
      }
    >
      <Container>
        {area && (
          <>
            <IonSelect
              style={{ color: 'white' }}
              value={sort}
              onIonChange={e => setSort(parseInt(e.detail.value, 10) as 0 | 1)}
              label={'Sort: '}
              labelPlacement={'floating'}
              interface={'popover'}
            >
              <IonSelectOption value={0}>Alphabetically</IonSelectOption>
              <IonSelectOption value={1}>Area Level</IonSelectOption>
            </IonSelect>
            <AreaLevels>
              {area.map(a => (
                <div key={a.name}>
                  <span>{a.name}</span>
                  {a.immunities && (
                    <span>
                      {a.immunities.map(i => (
                        <span key={`${a.name}-immune-${i}`} className={i.toLowerCase()}>
                          {i}
                        </span>
                      ))}
                    </span>
                  )}
                  <span>{section === 'norm' ? a.normal_alvl : section === 'nm' ? a.nightmare_alvl : a.hell_alvl}</span>
                </div>
              ))}
            </AreaLevels>
          </>
        )}

        {!area &&
          map &&
          map.map(
            (m, i) =>
              !m.isSideZone && (
                <React.Fragment key={m.zone_name}>
                  <MapRow isD2r={isD2r}>
                    <img className={'zone'} src={`/images/act_maps/${isD2r ? 'd2r/' + m.filename2 : m.filename}`} alt='' />
                    <div>
                      <h1>{m.zone_name}</h1>
                      <div>
                        <span className={'muted'}>Has Waypoint: </span>
                        {typeof m.waypoint === 'string' ? m.waypoint : m.waypoint ? 'Yes' : 'No'}
                      </div>
                      {m.additional && (
                        <div>
                          <span className={'muted'}>Side Zones: </span>
                          {m.additional}
                        </div>
                      )}
                      {m.levels && (
                        <div>
                          <span className={'muted'}>Levels: </span>
                          {m.levels}
                        </div>
                      )}
                      {m.related && (
                        <div>
                          <span className={'muted'}>Quest Related: </span>
                          {m.related}
                        </div>
                      )}
                    </div>
                  </MapRow>
                  {map.at(i + 1) && !map.at(i + 1)!.isSideZone && (
                    <MapRow isD2r={isD2r}>
                      <img src={`/images/act_maps/arrow.png`} alt='' />
                    </MapRow>
                  )}
                  {map.at(i + 1) && map.at(i + 1)!.isSideZone && <SideZone map={map} index={i + 1} />}
                  {map.at(i + 2) && map.at(i + 2)!.isSideZone && <SideZone map={map} index={i + 2} />}
                </React.Fragment>
              ),
          )}
      </Container>
      <ImmunitiesDialog open={dialog} close={() => setDialog(false)} />
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MapRow = styled.div<{ isD2r?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80px;
  color: white;
  gap: 10px;

  > img {
    height: 100%;
    max-width: 30%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    border-radius: ${props => (props.isD2r ? '6px' : '0')};

    &.zone {
      box-shadow: ${props => (props.isD2r ? '0px 0 3px 0px #c5c5c56e' : 'none')};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    color: white;

    > h1 {
      font-size: 14px;
      margin: 4px 0 6px;
    }
  }
`;

const AreaLevels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  color: white;
  padding: 10px 6px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    > span:first-of-type {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  > div:nth-child(even) {
    background-color: rgb(47, 46, 46);
  }
`;

const SideZone: React.FC<{ map: IAct[]; index: number }> = ({ map, index }) => {
  const isD2r = useAppSelector(selectorIsD2r);
  return (
    <MapRow isD2r={isD2r}>
      <img src={'/images/act_maps/arrow1.png'} alt='' />
      <img className={'zone'} src={`/images/act_maps/${isD2r ? 'd2r/' + map.at(index)!.filename2 : map.at(index)!.filename}`} alt='' />
      <div>
        <h1>{map.at(index)!.zone_name}</h1>
        <div>
          <span className={'muted'}>Has Waypoint: </span>
          {map.at(index)!.waypoint ? 'Yes' : 'No'}
        </div>
        {map.at(index)!.additional && (
          <div>
            <span className={'muted'}>Side Zones: </span>
            {map.at(index)!.additional}
          </div>
        )}
        {map.at(index)!.levels && (
          <div>
            <span className={'muted'}>Levels: </span>
            {map.at(index)!.levels}
          </div>
        )}
        {map.at(index)!.related && (
          <div>
            <span className={'muted'}>Quest Related: </span>
            {map.at(index)!.related}
          </div>
        )}
      </div>
    </MapRow>
  );
};

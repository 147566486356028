import React, { useEffect, useState } from 'react';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import {
  CREATE_GRAIL_TABLE,
  DELETE_GRAIL_ITEM,
  DELETE_GRAIL_ITEMS,
  DROP_GRAIL_TABLE,
  INSERT_GRAIL_ITEM,
  INSERT_GRAIL_ITEMS,
  QUERY_ALL_GRAIL,
  QUERY_GRAIL_ITEM,
  UPDATE_GRAIL_TABLE_RUN_ONLY_ONCE,
} from './sql_utils';
import { Filter } from '../../types';
import { ItemType } from '../ItemProvider/ItemProvider';
import { sqlite } from '../../../App';
import { LocalStorage } from '../../Storage';

export interface HolyGrail {
  id: number;
  item_type: Filter<ItemType, 'unique' | 'set'>;
  item_id: number;
}

interface Context {
  grailUniques: HolyGrail[];
  grailSets: HolyGrail[];
  toggleGrailItem: (item: HolyGrail) => void;
  addGrailItems: (items: HolyGrail[]) => Promise<void>;
  removeGrailItems: <T extends { item_type: string; item_id: number }>(items: T[]) => Promise<void>;
  addGrailItem: (item: HolyGrail) => void;
  isGrailItem: (itemId: number) => Promise<boolean>;
  resetGrailTable: () => Promise<void>;
}

export const HolyGraiLContext = React.createContext<Context>({} as Context);

export const HolyGrailProvider: React.FC<{
  db: SQLiteDBConnection | undefined;
  isWeb: boolean;
  children: React.ReactNode;
}> = ({ db, isWeb, children }) => {
  const [ready, setReady] = useState(false);
  const [uniques, setUniques] = useState<HolyGrail[]>([]);
  const [sets, setSets] = useState<HolyGrail[]>([]);

  useEffect(() => {
    if (db) {
      initializeTables();
    }
  }, [db]);

  const initializeTables = async () => {
    if (db) {
      await db.open();
      await db.execute(CREATE_GRAIL_TABLE);
      setReady(true);
    }
  };

  useEffect(() => {
    if (ready) {
      updateTable().then(() => {
        queryAllGrail();
      });
    }
  }, [ready]);

  const updateTable = async (): Promise<void> => {
    return new Promise(resolve => {
      if (LocalStorage.getGrailTableVersion() !== '1') {
        db
          ?.execute(UPDATE_GRAIL_TABLE_RUN_ONLY_ONCE)
          .then(res => {
            console.log('grail table update', res);
            LocalStorage.setGrailTableVersion('1');
          })
          .finally(() => resolve());
      } else {
        resolve();
      }
    });
  };

  const queryAllGrail = async () => {
    const res = await db?.query(QUERY_ALL_GRAIL);
    const values = (res?.values ?? []) as HolyGrail[];
    setUniques(values.filter(item => item.item_type === 'unique'));
    setSets(values.filter(item => item.item_type === 'set'));
  };

  const toggleGrailItem = async (grailItem: HolyGrail) => {
    console.log(grailItem);
    if (grailItem.id >= 0) {
      await db?.execute(DELETE_GRAIL_ITEM(grailItem.id));
      if (grailItem.item_type === 'unique') setUniques(prev => prev.filter(item => item.id !== grailItem.id));
      if (grailItem.item_type === 'set') setSets(prev => prev.filter(item => item.id !== grailItem.id));
    } else {
      const res = await db?.run(INSERT_GRAIL_ITEM, [grailItem.item_type, grailItem.item_id]);
      if (res?.changes?.lastId) {
        const item: HolyGrail = { id: res.changes.lastId, item_type: grailItem.item_type, item_id: grailItem.item_id };
        if (grailItem.item_type === 'unique') setUniques(prev => [...prev, item]);
        if (grailItem.item_type === 'set') setSets(prev => [...prev, item]);
      }
    }
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
  };

  const addGrailItems = async (items: HolyGrail[]) => {
    if (items.length) {
      try {
        await db?.execute(INSERT_GRAIL_ITEMS(items));
        await queryAllGrail();
        if (isWeb) {
          await sqlite.saveToStore('td2');
        }
      } catch (e) {
        console.error(e);
      }
    }
    return;
  };
  const removeGrailItems = async <T extends { item_type: string; item_id: number }>(items: T[]) => {
    if (items.length) {
      console.log(items);
      const uniques = items.filter(i => i.item_type === 'unique');
      const sets = items.filter(i => i.item_type === 'set');

      if (uniques.length) {
        await db?.execute(
          DELETE_GRAIL_ITEMS(
            'unique',
            uniques.map(item => item.item_id),
          ),
        );
      }

      if (sets.length) {
        const q = DELETE_GRAIL_ITEMS(
          'set',
          sets.map(item => item.item_id),
        );
        console.log(q);
        await db?.execute(
          DELETE_GRAIL_ITEMS(
            'set',
            sets.map(item => item.item_id),
          ),
        );
      }

      await queryAllGrail();
      if (isWeb) {
        await sqlite.saveToStore('td2');
      }
    }
  };

  const addGrailItem = async (item: HolyGrail) => {
    if (item.item_type === 'unique' && !uniques.find(u => u.item_id === item.item_id)) {
      await db?.run(INSERT_GRAIL_ITEM, [item.item_type, item.item_id]);
    }
    if (item.item_type === 'set' && !sets.find(s => s.item_id === item.item_id)) {
      await db?.run(INSERT_GRAIL_ITEM, [item.item_type, item.item_id]);
    }
    await queryAllGrail();
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
  };

  const isGrailItem = async (itemId: number): Promise<boolean> => {
    const res = await db?.query(QUERY_GRAIL_ITEM(itemId));
    return !!(res?.values && res.values.length > 0);
  };

  const resetGrailTable = async () => {
    await db?.execute(DROP_GRAIL_TABLE);
    await initializeTables();
    await queryAllGrail();
    // Need to wait for react state to update before continuing
    return new Promise<void>(resolve => {
      setTimeout(resolve, 200);
    });
  };

  return (
    <HolyGraiLContext.Provider
      value={{
        grailUniques: uniques,
        grailSets: sets,
        toggleGrailItem,
        addGrailItem,
        addGrailItems,
        removeGrailItems,
        isGrailItem,
        resetGrailTable,
      }}
    >
      {children}
    </HolyGraiLContext.Provider>
  );
};

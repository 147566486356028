import React from 'react';
import { IonAccordion, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { Filter } from '../../../common/types';

interface Props {
  value: Filter<HomeSection, 'game'>;
}

export const HomeGameSettings: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();
  const handleToggleShow = () => {
    dispatch(setHomeSettings({ ...homeSettings, game: { ...homeSettings.game, show: !homeSettings.game.show } }));
  };

  const handleToggleUseLatest = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        game: { ...homeSettings.game, useLatestPatch: !homeSettings.game.useLatestPatch },
      }),
    );
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Game Settings</IonLabel>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.game.show} onIonChange={handleToggleShow}>
          Show
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.game.useLatestPatch} onIonChange={handleToggleUseLatest}>
          Always use latest patch
        </IonToggle>
      </IonItem>
    </IonAccordion>
  );
};

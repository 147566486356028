import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderWrap } from '../../../common/components/ViewStorageItemSlider';
import { LocalStorage } from '../../../common/Storage';
import { useAppSelector } from '../../../store/store';
import { selectorFindRunewords } from '../../../store/activeData/selector';
import { ViewRuneword } from '../../../common/components/ViewRuneword/ViewRuneword';
import { selectorHomeSettings } from '../../../store/settings/selectors';

export const FaveRunewords: React.FC = () => {
  const items = useAppSelector(state => selectorFindRunewords(state, LocalStorage.getAllFaveRunewords()));
  const homeSettings = useAppSelector(selectorHomeSettings);
  return (
    <SliderWrap arrows={true} dots={true}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={homeSettings.general.sliderArrows}
        pagination={items.length > 1}
        modules={[Navigation, Pagination]}
        // pagination={items.length > 1}
        // navigation={items.length > 1}
        loop={true}
        autoHeight={homeSettings.general.autoHeight}
      >
        {items.map(rw => (
          <SwiperSlide key={`fave-rw-home-${rw.id}`}>
            <ViewRuneword runeword={rw} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderWrap>
  );
};

import React from 'react';
import { IonAccordion, IonIcon, IonItem, IonLabel, IonRange, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { add, remove } from 'ionicons/icons';
import { RangeWrapper } from '../Settings';
import { Filter } from '../../../common/types';

interface Props {
  value: Filter<HomeSection, 'dropCalc'>;
}

export const HomeDropCalcSettings: React.FC<Props> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();
  const handleToggleShow = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        dropCalc: { ...homeSettings.dropCalc, show: !homeSettings.dropCalc.show },
      }),
    );
  };

  const handleChangeResultsLength = (results: number) => {
    dispatch(setHomeSettings({ ...homeSettings, dropCalc: { ...homeSettings.dropCalc, results } }));
    alert('Changes will be reflected after next Drop Calculator calculation');
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Drop Calculator Settings</IonLabel>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.dropCalc.show} onIonChange={handleToggleShow}>
          Show
        </IonToggle>
      </IonItem>
      <RangeWrapper slot={'content'} label={'Results to show'}>
        <IonRange
          slot={'content'}
          value={homeSettings.dropCalc.results}
          min={1}
          max={12}
          snaps={true}
          pin={true}
          // pinFormatter={p => `${p}px`}
          mode={'md'}
          onIonChange={e => handleChangeResultsLength(e.detail.value as number)}
        >
          <IonIcon slot={'start'} icon={remove} color={'light'} />
          <IonIcon slot={'end'} icon={add} color={'light'} />
        </IonRange>
      </RangeWrapper>
    </IonAccordion>
  );
};

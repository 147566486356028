import { useContext, useEffect } from 'react';
import { LocalStorage } from '../Storage';
import { useAppSelector } from '../../store/store';
import { selectorArmorBases, selectorSets, selectorUniques, selectorWeaponBases } from '../../store/activeData/selector';
import { ItemContext, StorageItem } from './ItemProvider/ItemProvider';
import { ALL_RUNES } from '../data/runes/all_runes';
import { ALL_GEMS } from '../data/gems/gems';
import { MISC_ITEMS } from '../data/misc-items/misc_items';

export const useRepairItems = () => {
  const sets = useAppSelector(selectorSets);
  const uniques = useAppSelector(selectorUniques);
  const baseArmors = useAppSelector(selectorArmorBases);
  const baseWeapons = useAppSelector(selectorWeaponBases);

  const { ready, getAllItems, updateItemName } = useContext(ItemContext);

  useEffect(() => {
    if (!ready || !sets.length || !uniques.length || !baseArmors.length || !baseArmors.length || !getAllItems) return;

    const version = LocalStorage.getDbRepair();
    if (version === null) {
      getAllItems().then(items => {
        if (items.length) void doNameRepair(items);
      });
      LocalStorage.setDbRepair('1');
    }
  }, [ready, sets, uniques, baseWeapons, baseArmors, getAllItems]);

  const doNameRepair = async (items: StorageItem[]) => {
    const brokenItems = items.filter(s => s.item_type !== 'magic' && s.item_type !== 'rare' && !s.name);
    const repairedItems: StorageItem[] = [];

    if (brokenItems.length) {
      for (const broken of brokenItems) {
        if (broken.item_type === 'set') {
          const item = sets.find(s => s.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'unique') {
          const item = uniques.find(u => u.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'rune') {
          const item = ALL_RUNES.find(r => r.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'gem') {
          const item = ALL_GEMS.find(g => g.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'base_armor') {
          const item = baseArmors.find(a => a.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'base_weapon') {
          const item = baseWeapons.find(w => w.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
        if (broken.item_type === 'misc') {
          const item = MISC_ITEMS.find(m => m.id === broken.item_id);
          if (item) repairedItems.push({ ...broken, name: item.name });
        }
      }
    }

    if (repairedItems.length) {
      for (const repaired of repairedItems) {
        await updateItemName(repaired);
      }
    }
  };
};

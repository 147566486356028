import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { HomeSettings } from './components/HomeSettings';
import styled, { css, keyframes } from 'styled-components/macro';
import { CraftingSettings } from './components/CraftingSettings';
import { TabBarSettings } from './components/TabBarSettings';
import { ItemCatalogSettings } from './components/ItemCatalogSettings';

export const Settings: React.FC = () => {
  return (
    <AppPage title={'Settings'} padding={false}>
      <Container>
        <div>
          <Header>Home Settings</Header>
          <HomeSettings />
        </div>

        <div>
          <Header>Crafting Settings</Header>
          <CraftingSettings />
        </div>

        <div>
          <Header>Tab Bar Settings</Header>
          <TabBarSettings />
        </div>

        <div>
          <Header>Item Catalog Settings</Header>
          <ItemCatalogSettings />
        </div>
      </Container>
    </AppPage>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding-left: 8px;
`;

export const RangeWrapper = styled.div<{ label: string }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #222;

  &:before {
    content: '${props => props.label}';
    color: white;
    font-size: 16px;
  }
`;

const flash = keyframes`
    0% {
        width: 10px;
    }
    75% {
        width: 110%;
    }
    100% {
        opacity: 0;
        display: none;
    }
`;

export const SettingsContainer = styled.div<{ highlight?: boolean }>`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 16px;
  overflow: hidden;
  gap: 8px;
  margin: 16px;
  position: relative;

  ${({ highlight }) =>
    highlight &&
    css`
      &:after {
        content: '';
        height: 100%;
        flex-shrink: 0;
        position: absolute;
        background: linear-gradient(90deg, rgba(93, 136, 155, 0.3), rgba(61, 194, 255, 0.56) 100%);
        //box-shadow: 0 0 3px 3px #3dc2ff;
        animation: ${flash} 0.6s linear forwards;
        animation-delay: 0.4s;
        animation-iteration-count: 1;
        z-index: 10;
        transform-origin: center;
      }
    `}
`;

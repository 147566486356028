import React, { useEffect, useRef } from 'react';
import { HomeRuneSettings } from './HomeRuneSettings';
import { IonAccordionGroup } from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';
import { useLocation } from 'react-router';
import { HomeGrailSettings } from './HomeGrailSettings';
import { HomeGameSettings } from './HomeGameSettings';
import { HomeMulesSettings } from './HomeMulesSettings';
import { HomeDropCalcSettings } from './HomeDropCalcSettings';
import { HomeOrder } from './HomeOrder';
import { HomeGeneral } from './HomeGeneral';

export const HomeSettings: React.FC = () => {
  const location = useLocation();
  const accordions = useRef<HTMLIonAccordionGroupElement>(null);

  useEffect(() => {
    if (location.pathname.includes(appRoutes.settings.route) && location.hash) {
      setTimeout(() => {
        const hash = location.hash.substring(1);
        toggleAccordion(hash);
      }, 100);
    }
  }, [location]);

  const toggleAccordion = (value: string) => {
    if (!accordions.current) {
      return;
    }
    const nativeEl = accordions.current;

    if (nativeEl.value === value) {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = value;
    }
  };

  return (
    <>
      <IonAccordionGroup expand={'inset'} ref={accordions}>
        <HomeGameSettings value={'game'} />
        <HomeMulesSettings value={'mule'} />
        <HomeRuneSettings value={'runes'} />
        <HomeGrailSettings value={'grail'} />
        <HomeDropCalcSettings value={'dropCalc'} />
        <HomeOrder value={'order'} />
        <HomeGeneral value={'general'} />
      </IonAccordionGroup>
    </>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import styled from 'styled-components/macro';
import { useOpenCloseDialog } from '../../../common/hooks';

export interface DialogProps {
  open?: boolean;
  data?: any;
  aidel?: any;
  type?: any;
  aps?: any;
  onClose: () => void;
}

export const BreakPointDialog: React.FC<DialogProps> = props => {
  const [items, setItems] = useState<any[]>([]);
  const self = useRef<HTMLDialogElement>(null);
  useOpenCloseDialog(self, !!props?.open);
  console.log(props);

  const rawTable = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.type === 0) {
      const temp = [...props.data];
      temp.forEach(d => (d[2] = Math.round((2500 / (props.aidel + d[1]) / 100) * 100) / 100));
      setItems(temp);
    }

    if (props.type === 1 && rawTable.current) {
      rawTable.current.innerHTML = props.data;
    }

    if (props.type === 2) {
      const temp = [...props.data];
      for (let i = 0; i < props.aps.length; i++) {
        temp[i][2] = Math.round(props.aps[i] * 100) / 100;
      }
      setItems(temp);
    }
  }, [props]);

  useEffect(() => console.log(items), [items]);

  return (
    <Dialog ref={self}>
      <Close onClick={props.onClose} />
      {props.type !== 1 && (
        <IonGrid>
          <>
            <IonRow className={'ion-align-items-center ion-justify-content-center'}>
              <IonCol size={'4'} className='th ion-text-center'>
                IAS
              </IonCol>
              <IonCol size={'4'} className='th ion-text-center'>
                Frames per Attack
              </IonCol>
              <IonCol size={'4'} className='th ion-text-center'>
                Attacks per Second
              </IonCol>
            </IonRow>
            {items.map(item => (
              <IonRow key={item[0]} className='ion-align-items-center ion-justify-content-center'>
                <IonCol size='4' className='td ion-text-center ion-justify-content-center'>
                  {item[0]}
                </IonCol>
                <IonCol size='4' className='td ion-text-center'>
                  {item[1]}
                </IonCol>
                <IonCol size='4' className='td ion-text-center'>
                  {item[2]}
                </IonCol>
              </IonRow>
            ))}
          </>
        </IonGrid>
      )}
      <div ref={rawTable}></div>
    </Dialog>
  );
};

const Dialog = styled.dialog`
  width: 90vw;
  color: white;
  padding: 60px 10px 40px;
  position: relative;
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: url('/images/icons/close.svg');
  background-size: contain;
  background-repeat: no-repeat;
`;

import React, { useContext } from 'react';
import { IonCheckbox } from '@ionic/react';
import { useGrailItem, useStorageItems } from '../providers/providerHooks';
import { ItemContext, ItemType } from '../providers/ItemProvider/ItemProvider';
import styled from 'styled-components/macro';
import { ItemMuleCount } from './StyledComponents';
import { setViewMuledItemsDialog } from '../../store/app/appSlice';
import { useAppDispatch } from '../../store/store';

interface Props {
  type: ItemType;
  itemId?: number;
}

export const ItemDBStatus: React.FC<Props> = ({ type, itemId }) => {
  const { grailItem, toggle } = useGrailItem(type, itemId);
  const { storageItems } = useStorageItems(type, itemId);
  const { openAddItem } = useContext(ItemContext);

  const dispatch = useAppDispatch();

  return (
    <Container>
      {(type === 'unique' || type === 'set') && (
        <IonCheckbox checked={!!grailItem} onIonChange={toggle} style={{ color: 'white' }}>
          Found:
        </IonCheckbox>
      )}
      {type !== 'unique' && type !== 'set' && <div />}
      <Mules>
        <AddItemIcon onClick={() => openAddItem(type, itemId)} />
        {!!storageItems.length && (
          <ItemMuleCount count={storageItems.length} onClick={() => dispatch(setViewMuledItemsDialog(storageItems.map(item => item.id)))} />
        )}
      </Mules>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Mules = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const AddIcon = styled.div`
  width: 40px;
  height: 40px;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--ion-color-primary);
  position: relative;
  border-radius: 50%;

  &:after {
    content: '+';
    position: absolute;
    right: 5%;
    bottom: 5%;
    font-size: 20px;
    font-weight: 800;
    color: white;
  }
`;

const AddItemIcon = styled(AddIcon)`
  background-image: url('/images/icons/item.svg');
  background-position: center 70%;
`;

import React from 'react';
import { ArmorBase, SetItem, UniqueItem, WeaponBase } from '../types';
import styled from 'styled-components/macro';
import { useAppSelector } from '../../store/store';
import { selectorIsD2r } from '../../store/settings/selectors';
import { StorageItem } from '../providers/ItemProvider/ItemProvider';
import { selectorFindStorageItemBase } from '../../store/activeData/selector';
import { MISC_ITEMS } from '../data/misc-items/misc_items';
import { ALL_GEMS } from '../data/gems/gems';

interface Props {
  weaponBase?: WeaponBase;
  armorBase?: ArmorBase;
  size?: string;
  src?: string;
  miscName?: string;
  uniqueFilename?: string;
  setFilename?: string;
  runeName?: string;
  ethereal?: boolean;
  storageItem?: StorageItem;
}

export const ItemImage: React.FC<Props> = ({
  weaponBase,
  armorBase,
  size,
  miscName,
  src,
  uniqueFilename,
  setFilename,
  runeName,
  ethereal,
  storageItem,
}) => {
  const isD2r = useAppSelector(selectorIsD2r);
  const storageItemBase = useAppSelector(state => selectorFindStorageItemBase(state, storageItem));

  const replaceFileExtension = (fileName: string, extension?: string) => {
    fileName = fileName.includes('.') ? fileName : `${fileName}.`;
    return `${fileName.substr(0, fileName.lastIndexOf('.'))}.${extension ?? 'png'}`;
  };

  const weaponBaseSrc = (filename: string) =>
    isD2r ? `/images/item-images/d2r/bases/weapons/${replaceFileExtension(filename)}` : `/images/item-images/bases/weapons/${filename}`;
  const armorBaseSrc = (filename: string) =>
    isD2r ? `/images/item-images/d2r/bases/armors/${replaceFileExtension(filename)}` : `/images/item-images/bases/armors/${filename}`;
  const uniqueSrc = (filename: string) =>
    isD2r ? `/images/item-images/d2r/uniques/${replaceFileExtension(filename)}` : `/images/item-images/uniques/${filename}`;
  const setSrc = (filename: string) =>
    isD2r ? `/images/item-images/d2r/sets/${replaceFileExtension(filename)}` : `/images/item-images/sets/${filename}`;
  const runeSrc = (name: string) =>
    isD2r ? `/images/item-images/d2r/runes/${name.toLowerCase()}.png` : `/images/item-images/runes/${name.toLowerCase()}.gif`;
  const miscSrc = (name: string) => {
    const item = MISC_ITEMS.find(m => m.name === name);
    if (item) {
      return isD2r
        ? `/images/item-images/d2r/misc/${item.filename}`
        : `/images/item-images/misc/${replaceFileExtension(item.filename, 'gif')}`;
    }
    return '';
  };

  const gemSrc = (id: number) => {
    const gem = ALL_GEMS.find(g => g.id === id);
    console.log(id);
    if (gem) {
      return isD2r
        ? `/images/item-images/d2r/gems/${gem.quality}_${gem.type}.png`
        : `/images/item-images/gems/${gem.quality}_${gem.type}.gif`;
    }
  };

  const getFileSrc = () => {
    if (weaponBase) return weaponBaseSrc(weaponBase.filename);

    if (armorBase) return armorBaseSrc(armorBase.filename);

    if (miscName) return miscSrc(miscName);

    if (uniqueFilename) return uniqueSrc(uniqueFilename);

    if (setFilename) return setSrc(setFilename);

    if (runeName) return runeSrc(runeName);

    if (storageItem) return getStorageItemSrc();
  };

  function getStorageItemSrc() {
    if (storageItem) {
      switch (storageItem.item_type) {
        case 'unique': {
          if (storageItemBase) {
            return uniqueSrc((storageItemBase.at(0) as UniqueItem).filename);
          }
          break;
        }
        case 'set': {
          if (storageItemBase) {
            return setSrc((storageItemBase.at(0) as SetItem).filename);
          }
          break;
        }
        case 'base_armor': {
          if (storageItemBase) {
            return armorBaseSrc((storageItemBase.at(0) as ArmorBase).filename);
          }
          break;
        }
        case 'base_weapon': {
          if (storageItemBase) {
            return weaponBaseSrc((storageItemBase.at(0) as WeaponBase).filename);
          }
          break;
        }
        case 'magic':
        case 'rare': {
          if ((storageItemBase?.at(0) as WeaponBase | ArmorBase)?.id <= 200) {
            return armorBaseSrc((storageItemBase!.at(0) as ArmorBase).filename);
          } else if (storageItemBase?.at(0)) {
            return weaponBaseSrc((storageItemBase.at(0) as WeaponBase).filename);
          }
          break;
        }
        case 'runeword': {
          if ((storageItemBase?.at(1) as WeaponBase | ArmorBase)?.id <= 200) {
            return armorBaseSrc((storageItemBase!.at(1) as ArmorBase).filename);
          } else if (storageItemBase?.at(1)) {
            return weaponBaseSrc((storageItemBase.at(1) as WeaponBase).filename);
          }
          break;
        }
        case 'rune': {
          const src = runeSrc(storageItem.name ?? '');
          return src;
        }
        case 'misc': {
          return miscSrc(storageItem.name ?? '');
        }
        case 'gem': {
          return gemSrc(storageItem.item_id!);
        }
      }
    }
  }

  return <Image src={src ?? getFileSrc()} size={size} ethereal={ethereal} />;
};

export const Image = styled.img<{ size?: string; ethereal?: boolean }>`
  max-width: ${props => props.size ?? '90px'};
  max-height: ${props => props.size ?? '120px'};
  object-fit: contain;
  margin: auto;
  opacity: ${props => (props.ethereal ? 0.4 : 1)};
  filter: brightness(140%);
`;

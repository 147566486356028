import React, { useEffect, useRef, useState } from 'react';
import { DataItem, DropCalcData, monsterTypes } from '../DropCalc';
import { IonCheckbox, IonInput, IonList, IonModal, IonSearchbar, IonSelect, IonSelectOption } from '@ionic/react';
import { dropCalcBaseItems, dropCalcSetItems, dropCalcUniques } from '../../../common/data/drop-calc/Items';
import { PickItem } from '../../../common/components/ItemSelectModal/PickItem';
import styled from 'styled-components/macro';
import { Item } from '../../../common/types';

interface Props {
  data: DropCalcData;
  segment: string;
  onChange: (data: DropCalcData) => void;
  onItemListChange: (items: Item[]) => void;
  acts: DataItem[];
  monsters: DataItem[];
  levelList: DataItem[];
}

const itemTypes = [
  { name: 'Bases', value: 0 },
  { name: 'Uniques', value: 1 },
  { name: 'Sets', value: 2 },
];

const qualities = [
  { name: 'Others', value: 0 },
  { name: 'Normal', value: 1 },
  {
    name: 'Exceptional',
    value: 2,
  },
  { name: 'Elite', value: 3 },
];

export const DropCalcSettings: React.FC<Props> = ({ data, segment, onChange, onItemListChange, acts, monsters, levelList }) => {
  const [itemList, setItemList] = useState<Item[]>([]);
  const [itemListModal, setItemListModal] = useState(false);
  const [monsterSearchTerm, setMonsterSearchTerm] = useState('');
  const monsterModal = useRef<HTMLIonModalElement>(null);

  console.log(levelList);

  useEffect(() => {
    let temp: Item[] = [];
    switch (data.itemType) {
      case 0:
        temp = dropCalcBaseItems
          .filter(item => item[1] === data.selectedQuality)
          .map((item, i) => ({
            name: item[0],
            id: i,
            item_type: 'base_armor',
          }));
        break;
      case 1:
        temp = dropCalcUniques
          .filter(item => item[1] === data.selectedQuality)
          .map((item, i) => ({
            name: item[0],
            id: i,
            item_type: 'unique',
          }));
        break;
      case 2:
        temp = dropCalcSetItems
          .filter(item => item[5] === data.selectedQuality)
          .map((item, i) => ({
            name: item[0],
            id: i,
            item_type: 'set',
          }));
    }
    onChange({ ...data, itemListIndex: 0 });
    setItemList(temp);
    onItemListChange(temp);
  }, [data.itemType, data.selectedQuality]);

  const handleMonsterClick = (monster: DataItem) => {
    onChange({
      ...data,
      selectedMonster: monster.value,
      selectedMonsterIndex: monsters.findIndex(m => m.value === monster.value),
    });
    if (monsterModal.current) {
      monsterModal.current.dismiss();
    }
  };

  return (
    <IonList style={{ background: '#222', color: 'white' }}>
      <IonInput
        label={'Total Magic Find: '}
        type={'tel'}
        onIonInput={e => onChange({ ...data, mf: ~~(e.detail.value as string) })}
        value={data.mf}
      />
      <IonSelect
        mode={'md'}
        label={'Total Players in Game: '}
        onIonChange={e => onChange({ ...data, playersAll: ~~(e.detail.value as string) })}
        value={data.playersAll}
        interface={'popover'}
      >
        <IonSelectOption value={1}>1</IonSelectOption>
        <IonSelectOption value={2}>2</IonSelectOption>
        <IonSelectOption value={3}>3</IonSelectOption>
        <IonSelectOption value={4}>4</IonSelectOption>
        <IonSelectOption value={5}>5</IonSelectOption>
        <IonSelectOption value={6}>6</IonSelectOption>
        <IonSelectOption value={7}>7</IonSelectOption>
        <IonSelectOption value={8}>8</IonSelectOption>
      </IonSelect>
      <IonSelect
        mode={'md'}
        label={'Players in Party: '}
        onIonChange={e => onChange({ ...data, playersParty: ~~(e.detail.value as string) })}
        value={data.playersParty}
        interface={'popover'}
      >
        <IonSelectOption value={1}>1</IonSelectOption>
        <IonSelectOption value={2}>2</IonSelectOption>
        <IonSelectOption value={3}>3</IonSelectOption>
        <IonSelectOption value={4}>4</IonSelectOption>
        <IonSelectOption value={5}>5</IonSelectOption>
        <IonSelectOption value={6}>6</IonSelectOption>
        <IonSelectOption value={7}>7</IonSelectOption>
        <IonSelectOption value={8}>8</IonSelectOption>
      </IonSelect>

      {segment === 'item' && (
        <>
          <Row>
            <IonSelect
              mode={'md'}
              label={'Item Type: '}
              value={data.itemType}
              onIonChange={e => onChange({ ...data, itemType: ~~(e.detail.value as string) })}
              interface={'popover'}
            >
              {itemTypes.map(it => (
                <IonSelectOption key={`item_type_${it.value}`} value={it.value}>
                  {it.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonSelect
              mode={'md'}
              value={data.selectedQuality}
              onIonChange={e => onChange({ ...data, selectedQuality: ~~(e.detail.value as string) })}
              interface={'popover'}
            >
              {qualities.map(it => (
                <IonSelectOption key={`item_quality_${it.value}`} value={it.value}>
                  {it.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </Row>

          {itemList.length > 0 && (
            <PickItem
              isOpen={itemListModal}
              onClick={() => setItemListModal(prev => !prev)}
              selected={{
                name: itemList[data.itemListIndex].name,
                type: data.itemType === 0 ? 'base_armor' : data.itemType === 1 ? 'unique' : data.itemType === 2 ? 'set' : 'misc',
              }}
              onDismiss={() => setItemListModal(false)}
              items={itemList}
              onSelect={item => onChange({ ...data, itemListIndex: item.id })}
            />
          )}
        </>
      )}

      {segment === 'monster' && (
        <>
          <Row>
            <IonSelect
              mode={'md'}
              label={'Type:'}
              value={data.monstertype}
              onIonChange={e => onChange({ ...data, monstertype: ~~e.detail.value })}
              interface={'popover'}
              style={{ maxWidth: `${acts.at(0)?.name !== '---' ? '150px' : '300px'}` }}
              interfaceOptions={{ cssClass: 'monster-popover' }}
              className={monsterClassName(data.monstertype)}
            >
              {monsterTypes.map(m => (
                <IonSelectOption key={`monstertypes-${m.value}`} value={m.value}>
                  {m.name}
                </IonSelectOption>
              ))}
            </IonSelect>

            {acts.at(0)?.name !== '---' && (
              <IonSelect
                mode={'md'}
                label={'Act:'}
                value={data.act}
                onIonChange={e => onChange({ ...data, act: ~~e.detail.value })}
                interface={'popover'}
              >
                {acts.map(a => (
                  <IonSelectOption key={`act-${a.value}`} value={a.value}>
                    {a.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}

            <IonSelect
              mode={'md'}
              value={data.difficulty}
              onIonChange={e => onChange({ ...data, difficulty: ~~e.detail.value })}
              interface={'popover'}
            >
              <IonSelectOption value={0}>Normal</IonSelectOption>
              <IonSelectOption value={1}>Nightmare</IonSelectOption>
              <IonSelectOption value={2}>Hell</IonSelectOption>
            </IonSelect>
          </Row>

          <Row>
            <FakeSelect className={'ion-activatable'} id={'monster-select'}>
              <div style={{ marginLeft: 'auto' }}>{monsters.at(data.selectedMonsterIndex)?.name ?? ''}</div>
            </FakeSelect>
          </Row>

          {levelList.at(0)?.name !== '---' && (
            <Row>
              <IonSelect
                label={'Monster Location:'}
                mode={'md'}
                value={data.selectedLevel}
                onIonChange={e => {
                  console.log(e.detail.value);
                  onChange({ ...data, selectedLevel: ~~e.detail.value });
                }}
                interface={'popover'}
              >
                {levelList.map(l => (
                  <IonSelectOption key={`level-${l.name}`} value={l.value}>
                    {l.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </Row>
          )}

          <IonModal
            ref={monsterModal}
            trigger={'monster-select'}
            initialBreakpoint={0.7}
            breakpoints={[0, 0.7]}
            onDidDismiss={() => setMonsterSearchTerm('')}
          >
            <ModalContainer>
              <IonSearchbar
                animated={true}
                placeholder={'Search name...'}
                mode={'ios'}
                color={'medium'}
                onIonInput={e => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setMonsterSearchTerm(e.target?.value.toLowerCase() as string);
                }}
              />
              <div>
                {monsters
                  .filter(m => m.name.toLowerCase().includes(monsterSearchTerm))
                  .map((m, i) => (
                    <div key={`monster-${m.name}-${m.value}`} onClick={() => handleMonsterClick(m)}>
                      {m.name}
                    </div>
                  ))}
              </div>
            </ModalContainer>
          </IonModal>
        </>
      )}
      <Row>
        {segment === 'monster' && (
          <div>
            <IonCheckbox checked={data.showJunk} onIonChange={() => onChange({ ...data, showJunk: !data.showJunk })} mode={'md'}>
              Show Junk:
            </IonCheckbox>
          </div>
        )}
        <IonSelect
          mode={'md'}
          label={'Sort:'}
          value={data.sort}
          onIonChange={e => onChange({ ...data, sort: e.detail.value })}
          interface={'popover'}
        >
          <IonSelectOption value={'alpha'}>Alphabetically</IonSelectOption>
          <IonSelectOption value={'proba'}>Probability</IonSelectOption>
        </IonSelect>
      </Row>
    </IonList>
  );
};

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  > * {
    flex: 1;
  }
`;

const FakeSelect = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;

  &:before {
    content: 'Monster:';
  }

  &:after {
    content: '';
    width: 18px;
    height: 18px;
    background-image: url('/images/icons/chevron.svg');
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(270deg);
    flex-shrink: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  color: white;
  padding: 20px 0;

  > div {
    max-height: 63vh;
    overflow: auto;

    > div {
      width: 100%;
      text-align: center;
      padding: 6px;
    }
  }
`;

export const monsterClassName = (type: number) => {
  switch (type) {
    case 0:
      return 'white';
    case 1:
      return 'magic';
    case 3:
      return 'magic';
    default:
      return 'unique';
  }
};

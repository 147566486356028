import { RootState } from '../store';

export const selectorOpenAddItemModal = (state: RootState) => state.app.openAddItemModal;
export const selectorPickItemModal = (state: RootState) => state.app.pickItemModal;
export const selectorViewMuleDialog = (state: RootState) => state.app.viewMuleDialog;

export const selectorViewMuledItemsDialog = (state: RootState) => state.app.viewMuledItemsDialog;

export const selectorSyncGrailItemStore = (state: RootState) => state.app.syncGrailItemStore;

export const selectorIsPro = (state: RootState) => state.app.isPro;
export const selectorPlatform = (state: RootState) => state.app.platform;
export const selectorAdHeight = (state: RootState) => state.app.bannerSize;

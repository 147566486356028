import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickItemProps } from '../../common/components/ItemSelectModal/PickItem';
import { ItemType } from '../../common/providers/ItemProvider/ItemProvider';
import { LocalStorage } from '../../common/Storage';

export interface AddItem {
  type: ItemType;
  id: number;
}

interface AppState {
  openAddItemModal: boolean | AddItem;
  pickItemModal?: PickItemProps;
  viewMuleDialog?: number;
  viewMuledItemsDialog?: number[];
  syncGrailItemStore: boolean | null;
  platform?: string;
  isPro: boolean;
  purchasePro: number;
  bannerSize: number;
}

const initialState: AppState = {
  openAddItemModal: false,
  syncGrailItemStore: LocalStorage.getSyncGrailItemStore(),
  isPro: true,
  purchasePro: 0,
  bannerSize: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOpenAddMuleModal: (state, action: PayloadAction<boolean | AddItem>) => {
      state.openAddItemModal = action.payload;
    },
    setPickItemModal: (state, action: PayloadAction<PickItemProps | undefined>) => {
      state.pickItemModal = action.payload;
    },
    setViewMuledItems: (state, action: PayloadAction<number | undefined>) => {
      state.viewMuleDialog = action.payload;
    },
    setViewMuledItemsDialog: (state, action: PayloadAction<number[] | undefined>) => {
      state.viewMuledItemsDialog = action.payload;
    },
    setSyncGrailItems: (state, action: PayloadAction<boolean>) => {
      state.syncGrailItemStore = action.payload;
    },
    setPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload;
      if (action.payload === 'android') {
        state.isPro = LocalStorage.getIsPro();
      } else {
        state.isPro = true;
      }
    },
    setIsPro: (state, action: PayloadAction<boolean>) => {
      state.isPro = action.payload;
      if (action.payload) {
        LocalStorage.setIsPro();
      }
    },
    purchasePro: state => {
      state.purchasePro = state.purchasePro + 1;
    },
    setBannerSize: (state, action: PayloadAction<number>) => {
      state.bannerSize = action.payload;
    },
  },
});

export default appSlice.reducer;
export const {
  setOpenAddMuleModal,
  setPickItemModal,
  setViewMuledItems,
  setViewMuledItemsDialog,
  setSyncGrailItems,
  setPlatform,
  setIsPro,
  purchasePro,
  setBannerSize,
} = appSlice.actions;

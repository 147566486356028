export const CREATE_RUNES_TABLE = `
    CREATE TABLE IF NOT EXISTS runes (id INTEGER PRIMARY KEY NOT NULL, rune_id INTEGER UNIQUE NOT NULL, count INTEGER NOT NULL); 

`;

export const QUERY_ALL_RUNES = `
    SELECT * FROM runes;
`;

export const INSERT_RUNE = `
  INSERT INTO runes (rune_id, count) VALUES (?, ?);
`;

export const UPDATE_RUNE = (rune_id: number, value: number) => `
    UPDATE runes SET count = ${value} WHERE rune_id = ${rune_id};
`;

export const QUERY_RUNE_IN_STOCK = (id: number) => `SELECT count FROM runes WHERE rune_id = ${id}`;

export const DROP_TABLE_RUNES = `DROP TABLE runes`;

//     INSERT INTO runes (rune_id, count) VALUES (0, 0);
//     INSERT INTO runes (rune_id, count) VALUES (1, 0);
//     INSERT INTO runes (rune_id, count) VALUES (2, 0);
//     INSERT INTO runes (rune_id, count) VALUES (3, 0);
//     INSERT INTO runes (rune_id, count) VALUES (4, 0);
//     INSERT INTO runes (rune_id, count) VALUES (5, 0);
//     INSERT INTO runes (rune_id, count) VALUES (6, 0);
//     INSERT INTO runes (rune_id, count) VALUES (7, 0);
//     INSERT INTO runes (rune_id, count) VALUES (8, 0);
//     INSERT INTO runes (rune_id, count) VALUES (9, 0);
//     INSERT INTO runes (rune_id, count) VALUES (10, 0);
//     INSERT INTO runes (rune_id, count) VALUES (11, 0);
//     INSERT INTO runes (rune_id, count) VALUES (12, 0);
//     INSERT INTO runes (rune_id, count) VALUES (13, 0);
//     INSERT INTO runes (rune_id, count) VALUES (14, 0);
//     INSERT INTO runes (rune_id, count) VALUES (15, 0);
//     INSERT INTO runes (rune_id, count) VALUES (16, 0);
//     INSERT INTO runes (rune_id, count) VALUES (17, 0);
//     INSERT INTO runes (rune_id, count) VALUES (18, 0);
//     INSERT INTO runes (rune_id, count) VALUES (19, 0);
//     INSERT INTO runes (rune_id, count) VALUES (20, 0);
//     INSERT INTO runes (rune_id, count) VALUES (21, 0);
//     INSERT INTO runes (rune_id, count) VALUES (22, 0);
//     INSERT INTO runes (rune_id, count) VALUES (23, 0);
//     INSERT INTO runes (rune_id, count) VALUES (24, 0);
//     INSERT INTO runes (rune_id, count) VALUES (25, 0);
//     INSERT INTO runes (rune_id, count) VALUES (26, 0);
//     INSERT INTO runes (rune_id, count) VALUES (27, 0);
//     INSERT INTO runes (rune_id, count) VALUES (28, 0);
//     INSERT INTO runes (rune_id, count) VALUES (29, 0);
//     INSERT INTO runes (rune_id, count) VALUES (30, 0);
//     INSERT INTO runes (rune_id, count) VALUES (31, 0);
//     INSERT INTO runes (rune_id, count) VALUES (32, 0);

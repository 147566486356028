import React from 'react';
import { IonAccordion, IonIcon, IonItem, IonLabel, IonRange, IonToggle } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorHomeSettings } from '../../../store/settings/selectors';
import { HomeSection, setHomeSettings } from '../../../store/settings/settingsSlice';
import { Rune } from '../../../common/components/Rune';
import { add, remove } from 'ionicons/icons';
import { RangeWrapper } from '../Settings';
import { Filter } from '../../../common/types';

export const HomeRuneSettings: React.FC<{ value: Filter<HomeSection, 'runes'> }> = ({ value }) => {
  const homeSettings = useAppSelector(selectorHomeSettings);
  const dispatch = useAppDispatch();

  const handleToggleShowRunes = () => {
    dispatch(setHomeSettings({ ...homeSettings, runes: { ...homeSettings.runes, show: !homeSettings.runes.show } }));
  };

  const handleChangeRuneSize = (size: number) => {
    dispatch(setHomeSettings({ ...homeSettings, runes: { ...homeSettings.runes, size } }));
  };

  const handleToggleShowName = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        runes: { ...homeSettings.runes, showName: !homeSettings.runes.showName },
      }),
    );
  };

  const handleToggleShowCount = () => {
    dispatch(
      setHomeSettings({
        ...homeSettings,
        runes: { ...homeSettings.runes, showCount: !homeSettings.runes.showCount },
      }),
    );
  };

  return (
    <IonAccordion value={value} className={'settings-accordion'}>
      <IonItem slot={'header'} lines={'none'}>
        <IonLabel>Runes</IonLabel>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.runes.show} onIonChange={handleToggleShowRunes}>
          Show Runes
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.runes.showName} onIonChange={handleToggleShowName}>
          Show Name
        </IonToggle>
      </IonItem>
      <IonItem slot={'content'} lines={'none'}>
        <IonToggle checked={homeSettings.runes.showCount} onIonChange={handleToggleShowCount}>
          Show Count
        </IonToggle>
      </IonItem>
      <RangeWrapper slot={'content'} label={'Rune Size'}>
        <IonRange
          slot={'content'}
          value={homeSettings.runes.size}
          min={25}
          max={65}
          snaps={true}
          pin={true}
          pinFormatter={p => `${p}px`}
          mode={'md'}
          onIonChange={e => handleChangeRuneSize(e.detail.value as number)}
        >
          <IonIcon slot={'start'} icon={remove} color={'light'} />
          <IonIcon slot={'end'} icon={add} color={'light'} />
        </IonRange>
      </RangeWrapper>
      <IonItem slot={'content'}>
        <div style={{ margin: 'auto' }}>
          <Rune
            id={30}
            size={`${homeSettings.runes.size}px`}
            withName={homeSettings.runes.showName}
            withCount={homeSettings.runes.showCount}
            forceInStock={true}
          />
        </div>
      </IonItem>
    </IonAccordion>
  );
};

import React, { useContext } from 'react';
import { type Mule, MuleContext } from '../../../common/providers/MuleProvider/MuleProvider';
import { IonIcon, useIonRouter } from '@ionic/react';
import { create, trashOutline } from 'ionicons/icons';
import { ItemMuleName } from '../../../common/components/StyledComponents';
import styled from 'styled-components/macro';
import { ItemContext } from '../../../common/providers/ItemProvider/ItemProvider';
import { HolyGraiLContext } from '../../../common/providers/HolyGrailProvider/HolyGrailProvider';

interface Props {
  mule?: Mule;
}

export const MuleDetailViewHeader: React.FC<Props> = ({ mule }) => {
  const { deleteMule, updateMule } = useContext(MuleContext);
  const { getItemsByMuleId, deleteItemsByItemIds } = useContext(ItemContext);
  const { removeGrailItems } = useContext(HolyGraiLContext);
  const router = useIonRouter();

  const handleDelete = async () => {
    if (!mule) return;
    if (confirm(`Delete ${mule.mule_name} (${mule.item_count} items)?`)) {
      const deleteGrail = confirm('Delete from Holy Grail?');
      const items = await getItemsByMuleId(mule.mule_id);

      if (deleteGrail) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await removeGrailItems(items.filter(item => item.item_id !== null));
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await deleteItemsByItemIds(items.filter(item => item.item_id !== null).map(item => item.item_id));
      await deleteMule(mule.mule_id);

      router.goBack();
    }
  };

  const handleEdit = () => {
    if (!mule) return;
    updateMule(mule.mule_id, mule.mule_name);
  };

  return (
    <Container>
      <ItemMuleName name={mule?.mule_name ?? ''} />
      <IconWrap>
        <IonIcon icon={create} onClick={handleEdit} />
        <IonIcon icon={trashOutline} onClick={handleDelete} />
      </IconWrap>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.5rem;
`;

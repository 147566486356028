import React, { useEffect, useState } from 'react';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { CREATE_RUNES_TABLE, DROP_TABLE_RUNES, INSERT_RUNE, QUERY_ALL_RUNES, UPDATE_RUNE } from './sql_utils';
import { sqlite } from '../../../App';

export interface StorageRune {
  id: number;
  rune_id: number;
  count: number;
}

interface RuneStorage {
  runes: StorageRune[];
  incRune: (rune_id: number) => Promise<void>;
  decRune: (rune_id: number) => Promise<void>;
  setCount: (rune_id: number, count: number) => Promise<void>;
  resetRunesTable: () => Promise<void>;
}

export const RuneContext = React.createContext<RuneStorage>({} as RuneStorage);
export const RuneProvider: React.FC<{
  db: SQLiteDBConnection | undefined;
  isWeb: boolean;
  children: React.ReactNode;
}> = ({ db, isWeb, children }) => {
  const [ready, setReady] = useState(false);
  const [runes, setRunes] = useState<StorageRune[]>([]);

  useEffect(() => {
    if (db && !ready) {
      initializeTables();
    }
  }, [db, ready]);

  useEffect(() => {
    if (ready) {
      queryGetRunes();
    }
  }, [ready]);

  async function initializeTables() {
    if (db) {
      await db.open();
      await db?.execute(CREATE_RUNES_TABLE);
      setReady(true);
    }
  }

  const queryGetRunes = async () => {
    const res = await db?.query(QUERY_ALL_RUNES);
    setRunes(res?.values ?? []);
  };

  const incRune = async (rune_id: number) => {
    const rune = runes.find(r => r.rune_id === rune_id);
    if (rune) {
      await db?.execute(UPDATE_RUNE(rune_id, rune.count + 1));
      setRunes(prev =>
        prev.map(r => {
          if (r.rune_id === rune_id) return { ...r, count: r.count + 1 };
          else return r;
        }),
      );
    } else {
      await db?.run(INSERT_RUNE, [rune_id, 1]);
      await queryGetRunes();
    }
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
  };

  const decRune = async (rune_id: number) => {
    const rune = runes.find(r => r.rune_id === rune_id);
    if (rune && rune.count > 0) {
      await db?.execute(UPDATE_RUNE(rune_id, rune.count - 1));
      setRunes(prev =>
        prev.map(r => {
          if (r.rune_id === rune_id) return { ...r, count: r.count - 1 };
          else return r;
        }),
      );
    }
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
  };

  const setCount = async (rune_id: number, count: number) => {
    const rune = runes.find(r => r.rune_id === rune_id);
    if (rune) {
      await db?.execute(UPDATE_RUNE(rune_id, rune.count + count));
      setRunes(prev =>
        prev.map(r => {
          if (r.rune_id === rune_id) return { ...r, count: r.count + count };
          else return r;
        }),
      );
    } else {
      await db?.run(INSERT_RUNE, [rune_id, count]);
      await queryGetRunes();
    }
    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
  };

  const resetRunesTable = async () => {
    await db?.execute(DROP_TABLE_RUNES);
    await initializeTables();
    await queryGetRunes();
    // Need to wait for react state to update before continuing
    return new Promise<void>(resolve => {
      setTimeout(resolve, 200);
    });
  };

  return <RuneContext.Provider value={{ runes, incRune, decRune, setCount, resetRunesTable }}>{children}</RuneContext.Provider>;
};

RuneProvider.displayName = 'RuneProvider';

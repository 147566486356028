import { UniqueItem } from '../../types';

export const UNIQUES_26: UniqueItem[] = [
  {
    name: 'Greyform',
    type: 'Quilted Armor',
    image: 'http://www.d2tomb.com/images/uniques/greyform.gif',
    props: [
      'Defense:',
      '28-31',
      'Durability:',
      '20',
      'Req Strength:',
      '12',
      'Req Level:',
      '7',
      '5% Life Stolen per Hit',
      'Magic damage reduced by 3',
      'Cold resist 20%',
      'Fire resist 20%',
      '+20 to Defense',
      '+10 to Dexterity',
    ],
    category: 'Unique Armor',
    id: 0,
    filename: 'greyform.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Blinkbat's Form",
    type: 'Leather Armor',
    image: 'http://www.d2tomb.com/images/uniques/blinkbatsform.gif',
    props: [
      'Defense:',
      '39-42',
      'Durability:',
      '24',
      'Req Strength:',
      '15',
      'Req Level:',
      '12',
      '10% Faster Run/Walk',
      'Add 3-6 Fire Damage',
      '40% Faster Hit Recovery',
      '+50 Defense vs. missles',
      '+25 Defense',
    ],
    category: 'Unique Armor',
    id: 1,
    filename: 'blinkbatsform.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Centurion',
    type: 'Hard Leather Armor',
    image: 'http://www.d2tomb.com/images/uniques/thecenturion.gif',
    props: [
      'Defense:',
      '51-54',
      'Durability:',
      '28',
      'Req Strength:',
      '20',
      'Req Level:',
      '14',
      'Damage reduced by 2',
      '+30 Defense',
      'Replenish Life +5',
      '+50 to Attack Rating',
      '25% Slower Stamina Drain',
      '+15 to Mana',
      '+15 to max Stamina',
      '+15 to Life',
    ],
    category: 'Unique Armor',
    id: 2,
    filename: 'thecenturion.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Twitchthroe',
    type: 'Studded Leather',
    image: 'http://www.d2tomb.com/images/uniques/twitchthroe.gif',
    props: [
      'Defense:',
      '57-60',
      'Durability:',
      '32',
      'Req Strength:',
      '27',
      'Req Level:',
      '16',
      '25% increased chance of blocking',
      '20% Faster Hit Recovery',
      '20% Increased Attack Speed',
      '+25 to Defense',
      '+10 to Dexterity',
      '+10 to Strength',
    ],
    category: 'Unique Armor',
    id: 3,
    filename: 'twitchthroe.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Darkglow',
    type: 'Ring Mail',
    image: 'http://www.d2tomb.com/images/uniques/darkglow.gif',
    props: [
      'Defense:',
      '83-98',
      'Durability:',
      '26',
      'Req Strength:',
      '36',
      'Req Level:',
      '14',
      '+3 to Light Radius',
      '+70-100% Enhanced Defense',
      '5% to max Poison resist',
      '5% to max Cold resist',
      '5% to max Lightning resist',
      '5% to max Fire resist',
      '+50 Defense vs. Melee',
      '+20 to Attack Rating',
      'All Resistances +10',
    ],
    category: 'Unique Armor',
    id: 4,
    filename: 'darkglow.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Hawkmail',
    type: 'Scale Mail',
    image: 'http://www.d2tomb.com/images/uniques/hawkmail.gif',
    props: [
      'Defense:',
      '109-122',
      'Durability:',
      '36',
      'Req Strength:',
      '44',
      'Req Level:',
      '15',
      'Cannot be Frozen',
      '15% to max Cold Resist',
      '+15% Cold Resist',
      '+80-100% Enhanced Defense',
      '10% Faster Run/Walk',
    ],
    category: 'Unique Armor',
    id: 5,
    filename: 'hawkmail.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Venom Ward',
    type: 'Breast Plate',
    image: 'http://www.d2tomb.com/images/uniques/venomsward.gif',
    props: [
      'Defense:',
      '110-138',
      'Durability:',
      '50',
      'Req Strength:',
      '30',
      'Req Level:',
      '20',
      '+2 to Light Radius',
      '+60-100% Enhanced Defense',
      '15% to max Poison Resist',
      '+90% Poison Resist',
      'Poison Length reduced by 50%',
    ],
    category: 'Unique Armor',
    id: 6,
    filename: 'venomsward.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Sparking Mail',
    type: 'Chain Mail',
    image: 'http://www.d2tomb.com/images/uniques/sparklingmail.gif',
    props: [
      'Defense:',
      '133-140',
      'Durability:',
      '45',
      'Req Strength:',
      '48',
      'Req Level:',
      '17',
      'Attacker takes 10-14 Lightning Damage',
      'Adds 1-20 Lightning Damage',
      '+75-85% Enhanced Defense',
      '+30% Lightning Resist',
    ],
    category: 'Unique Armor',
    id: 7,
    filename: 'sparklingmail.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Iceblink',
    type: 'Splint Mail',
    image: 'http://www.d2tomb.com/images/uniques/iceblink.gif',
    props: [
      'Defense:',
      '163-172',
      'Durability:',
      '30',
      'Req Strength:',
      '51',
      'Req Level:',
      '22',
      'Freezes Target',
      '+4 to Light Radius',
      '+70-80% Enhanced Defense',
      'Magic damage reduced by 1',
      '+30% Cold Resist',
    ],
    category: 'Unique Armor',
    id: 8,
    filename: 'iceblink.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Heavenly Garb',
    type: 'Light Plate',
    image: 'http://www.d2tomb.com/images/uniques/heavenlygarb.gif',
    props: [
      'Defense:',
      '216',
      'Durability:',
      '60',
      'Req Strength:',
      '41',
      'Req Level:',
      '29',
      '+100% Enhanced Defense',
      '+15 to Energy',
      '+50% Damage to Undead',
      '+100 to Attack Rating against Undead',
      'Regenerate Mana 25%',
      'All Resistances +10',
    ],
    category: 'Unique Armor',
    id: 9,
    filename: 'heavenlygarb.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Boneflesh',
    type: 'Plate Mail',
    image: 'http://www.d2tomb.com/images/uniques/boneflesh.gif',
    props: [
      'Defense:',
      '234-257',
      'Durability:',
      '60',
      'Req Strength:',
      '65',
      'Req Level:',
      '26',
      '5% Life stolen per hit',
      '+100-120% Enhanced Defense',
      '25% Chance of Open Wounds',
      '+35 to Attack Rating',
    ],
    category: 'Unique Armor',
    id: 10,
    filename: 'boneflesh.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rockfleece',
    type: 'Field Plate',
    image: 'http://www.d2tomb.com/images/uniques/rockfleece.gif',
    props: [
      'Defense:',
      '212-243',
      'Durability:',
      '48',
      'Req Strength:',
      '50',
      'Req Level:',
      '28',
      'Requirements -10%',
      'Damage reduced by 10%',
      '+100-130% Enhanced Defense',
      '+5 to Strength',
      'Damage reduced by 5',
    ],
    category: 'Unique Armor',
    id: 11,
    filename: 'rockfleece.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rattlecage',
    type: 'Gothic Plate',
    image: 'http://www.d2tomb.com/images/uniques/rattlecage.gif',
    props: [
      'Defense:',
      '328-335',
      'Durability:',
      '55',
      'Req Strength:',
      '70',
      'Req Level:',
      '29',
      '+200 to Defense',
      '25% Chance of Crushing Blow',
      '40% Hit causes monsters to flee',
      '+45 to Attack Rating',
    ],
    category: 'Unique Armor',
    id: 12,
    filename: 'rattlecage.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Goldskin',
    type: 'Full Plate Mail',
    image: 'http://www.d2tomb.com/images/uniques/goldskin.gif',
    props: [
      'Defense:',
      '356-405',
      'Durability:',
      '70',
      'Req Strength:',
      '80',
      'Req Level:',
      '28',
      '+2 to Light Radius',
      '+35 to All Resistances',
      '+120-150% Enhanced Defense',
      '100% Extra Gold from Monsters',
      'Attacker takes damage of 10',
    ],
    category: 'Unique Armor',
    id: 13,
    filename: 'goldskin.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Silks of the Victor',
    type: 'Ancient Armor',
    image: 'http://www.d2tomb.com/images/uniques/victorssilk.gif',
    props: [
      'Defense:',
      '468-514',
      'Durability:',
      '60',
      'Req Strength:',
      '100',
      'Req Level:',
      '28',
      '+2 to Light Radius',
      '+100-120% Enhanced Defense',
      '+1 to all Skills',
      '5% Mana stolen per hit',
    ],
    category: 'Unique Armor',
    id: 14,
    filename: 'victorssilk.gif',
    category_id: 13,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Spirit Shroud',
    type: 'Ghost Armor',
    image: 'http://www.d2tomb.com/images/items/quilted.gif',
    props: [
      'Defense:',
      '295',
      'Durability:',
      '20',
      'Req Strength:',
      '38',
      'Req Level:',
      '28',
      '+150% Enhanced Defense',
      'Cannot be Frozen',
      '+1 to All Skills',
      '+10 Replenish Life',
      'Magic Damage Reduced by 7-11',
    ],
    category: 'Exceptional Unique Armor',
    id: 15,
    filename: 'quilted.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Skin of the Vipermagi',
    type: 'Serpentskin Armor',
    image: 'http://www.d2tomb.com/images/items/leather.gif',
    props: [
      'Defense:',
      '279',
      'Durability:',
      '24',
      'Req Strength:',
      '43',
      'Req Level:',
      '29',
      '+120% Enhanced Defense',
      '+1 to All skills',
      '30% Faster Cast Rate',
      'Magic Damage Reduced by 9-13',
      '+20-35 to All Resistances',
    ],
    category: 'Exceptional Unique Armor',
    id: 16,
    filename: 'vipermagicskin.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Skin of the Flayed One',
    type: 'Demonhide Armor',
    image: 'http://www.d2tomb.com/images/items/hardleather.gif',
    props: [
      'Defense:',
      '342-397',
      'Durability:',
      '58',
      'Req Strength:',
      '50',
      'Req Level:',
      '31',
      '+150-190% Enhanced Defense',
      'Repairs 1 Durability in 10 sec',
      '5-7% Life Stolen per Hit',
      '+15-25 Replenish Life',
      'Attacker takes Damage of 15',
    ],
    category: 'Exceptional Unique Armor',
    id: 17,
    filename: 'flayedoneskin.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Iron Pelt',
    type: 'Trellised Armor',
    image: 'http://www.d2tomb.com/images/items/studdedleather.gif',
    props: [
      'Defense:',
      '234-605',
      'Durability:',
      '157',
      'Req Strength:',
      '61',
      'Req Level:',
      '33',
      '+50-100% Enhanced Defense',
      '+3 per character level to Defense',
      'Damage reduced by 15-20',
      'Magic Damage reduced by 10-16',
      '+25 to Life',
    ],
    category: 'Exceptional Unique Armor',
    id: 18,
    filename: 'ironpelt.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Spirit Forge',
    type: 'Linked Mail',
    image: 'http://www.d2tomb.com/images/items/ringmail.gif',
    props: [
      'Defense:',
      '380-449',
      'Durability:',
      '26',
      'Req Strength:',
      '74',
      'Req Level:',
      '35',
      '+120-160% Enhanced Defense',
      '+1.25 per character level to Life',
      '+5% Fire Resist',
      'Adds 20-65 Fire Damage',
      '+15 to Strength',
      '+4 to Light Radius',
      '2 sockets',
    ],
    category: 'Exceptional Unique Armor',
    id: 19,
    filename: 'ringmail.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Crow Caw',
    type: 'Tigulated Mail',
    image: 'http://www.d2tomb.com/images/items/scalemail.gif',
    props: [
      'Defense:',
      '477-534',
      'Durability:',
      '36',
      'Req Strength:',
      '86',
      'Req Level:',
      '37',
      '+150-180% Enhanced Defense',
      '15% Increased Attack Speed',
      '15% Faster Hit recovery',
      '35% Chance of Open Wounds',
      '+15 to Dexterity',
    ],
    category: 'Exceptional Unique Armor',
    id: 20,
    filename: 'scalemail.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Duriel's Shell",
    type: 'Cuirass',
    image: 'http://www.d2tomb.com/images/items/breastplate.gif',
    props: [
      'Defense:',
      '528-732',
      'Durability:',
      '150',
      'Req Strength:',
      '65',
      'Req Level:',
      '41',
      '+160-200% Enhanced Defense',
      '+1 per character level to Life',
      '+1.25 per character level to Defense',
      '+20% to Fire, Lightning, Poison Resist',
      '+50% to Cold Resist',
      'Cannot be Frozen',
      '+15 to Strength',
    ],
    category: 'Exceptional Unique Armor',
    id: 21,
    filename: 'durielsshell.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Shaftstop',
    type: 'Mesh Armor',
    image: 'http://www.d2tomb.com/images/items/chainmail.gif',
    props: [
      'Defense:',
      '599-684',
      'Durability:',
      '45',
      'Req Strength:',
      '92',
      'Req Level:',
      '38',
      '+180-220% Enhanced Defense',
      'Damage Reduced by 30%',
      '+250 Defense against missles',
      '+60 to Life',
    ],
    category: 'Exceptional Unique Armor',
    id: 22,
    filename: 'chainmail.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Skullder's Ire",
    type: 'Russet Armor',
    image: 'http://www.d2tomb.com/images/items/splintmail.gif',
    props: [
      'Defense:',
      '634-732',
      'Durability:',
      '90',
      'Req Strength:',
      '97',
      'Req Level:',
      '42',
      '+160-200% Enhanced Defense',
      'Repairs 1 durability in 5 seconds',
      '+1.25 per character level % better chance of getting Magic Items',
      '+1 to all Skill Levels',
      'Magic Damage reduced by 10',
    ],
    category: 'Exceptional Unique Armor',
    id: 23,
    filename: 'splintmail.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Que-Hegan's Wisdom",
    type: 'Mage Plate',
    image: 'http://www.d2tomb.com/images/items/lightplate.gif',
    props: [
      'Defense:',
      '628-681',
      'Durability:',
      '60',
      'Req Strength:',
      '55',
      'Req Level:',
      '51',
      '+140-160% Enhanced Defense',
      '+1 to all Skill Levels',
      '+3 to Mana after each kill',
      '20% Faster Cast Rate',
      '20% Faster Hit Recovery',
      'Magic Damage reduced by 6-10',
      '+15 to Energy',
    ],
    category: 'Exceptional Unique Armor',
    id: 24,
    filename: 'lightplate.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Guardian Angel',
    type: 'Templar Coat',
    image: 'http://www.d2tomb.com/images/items/platemail.gif',
    props: [
      'Defense:',
      '770-825',
      'Durability:',
      '60',
      'Req Strength:',
      '118',
      'Req Level:',
      '45',
      '+180-200% Enhanced Defense',
      '+20% increased chance of blocking',
      '+30% faster Block Rate',
      '+2.5 per character level to Attack Rating against demons',
      '+1 to Paladin Skill Levels',
      '+4 to Light Radius',
      '15% to all maximum Resistances',
    ],
    category: 'Exceptional Unique Armor',
    id: 25,
    filename: 'platemail.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Toothrow',
    type: 'Sharktooth Armor',
    image: 'http://www.d2tomb.com/images/items/fieldplate.gif',
    props: [
      'Defense:',
      '713-888',
      'Durability:',
      '63',
      'Req Strength:',
      '103',
      'Req Level:',
      '48',
      '+160-220% Enhanced Defense',
      '+40-60 Defense',
      '40% chance of Open Wounds',
      '+15% Fire Resist',
      '+10 Strength',
      'Attacker takes damage of 20-40',
    ],
    category: 'Exceptional Unique Armor',
    id: 26,
    filename: 'toothrow.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Atma's Wail",
    type: 'Embossed Plate',
    image: 'http://www.d2tomb.com/images/items/gothicplate.gif',
    props: [
      'Defense:',
      '670-988',
      'Durability:',
      '105',
      'Req Strength:',
      '115',
      'Req Level:',
      '51',
      '+120-160% Enhanced Defense',
      '+2 per character level to Defense',
      '30% Faster Hit Recovery',
      '+10 Replenish Life',
      '15% increased maximum Mana',
      '+15 Dexterity',
      '20% better chance of getting Magic Items',
    ],
    category: 'Exceptional Unique Armor',
    id: 27,
    filename: 'gothicplate.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Black Hades',
    type: 'Chaos Armor',
    image: 'http://www.d2tomb.com/images/items/fullplate.gif',
    props: [
      'Defense:',
      '823-1029',
      'Durability:',
      '70',
      'Req Strength:',
      '140',
      'Req Level:',
      '53',
      '140-200% Enhanced Defense',
      '+30-60% Damage to Demons',
      '+200-250 to Attack Rating against Demons',
      'Half Freeze Duration',
      '-2 to Light Radius',
      '3 sockets',
    ],
    category: 'Exceptional Unique Armor',
    id: 28,
    filename: 'fullplate.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Corpsemourn',
    type: 'Ornate Armor',
    image: 'http://www.d2tomb.com/images/items/ancientarmor.gif',
    props: [
      'Defense:',
      '1127-1262',
      'Durability:',
      '60',
      'Req Strength:',
      '170',
      'Req Level:',
      '55',
      '+150-180% Enhanced Defense',
      '40 charges level 5 Corpse Explosion',
      '+12-36 Fire Damage',
      '6% chance to cast level 2 Iron Maiden',
      '+35% Cold Resist',
      '+10 to Vitality',
      '+8 to Strength',
    ],
    category: 'Exceptional Unique Armor',
    id: 29,
    filename: 'corpsemourn.gif',
    category_id: 13,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Ormus' Robes",
    type: 'Dusk Shroud',
    image: 'http://www.d2tomb.com/images/items/quilted.gif',
    props: [
      'Defense:',
      '371-487',
      'Durability:',
      '20',
      'Req Strength:',
      '77',
      'Type: Light',
      'Req Level:',
      '75',
      '+10-20 Defense',
      '20% Faster Cast Rate',
      '+10-15% to Cold Skill Damage',
      '+10-15% to Fire Skill Damage',
      '+10-15% to Lightning Skill Damage',
      '+3 to a random Sorceress Skill',
      '10-15% Regenerate Mana',
    ],
    category: 'Elite Unique Armor',
    id: 30,
    filename: 'ormusrobes.jpg',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "The Gladiator's Bane",
    type: 'Wire Fleece',
    image: 'http://www.d2tomb.com/images/items/studdedleather.gif',
    props: [
      'Defense:',
      '1255-1496',
      'Req Strength:',
      '111',
      'Req Level:',
      '85',
      '+150-200% Enhanced Defense',
      '+50 Defense',
      'Durability:',
      '135',
      'Cannot be Frozen',
      'Poison Length reduced by 50%',
      '30% Faster Hit Recovery',
      'Damage reduced by 15-20',
      'Magic damage reduced by 15-20',
      'Attacker takes damage of 20',
    ],
    category: 'Elite Unique Armor',
    id: 31,
    filename: 'gladiatorsbane.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Arkaine's Valor",
    type: 'Balrog Skin',
    image: 'http://www.d2tomb.com/images/items/splintmail.gif',
    props: [
      'Defense:',
      '1295-1450',
      'Durability:',
      '30',
      'Req Strength:',
      '165',
      'Req Level:',
      '85',
      '+150-180% Enhanced Defense',
      '+1-2 to all Skill Levels',
      '+(0.5 per character level) to Vitality',
      '30% Faster Hit Recovery',
      'Damage Reduced by 10-15',
    ],
    category: 'Elite Unique Armor',
    id: 32,
    filename: 'balrogskin.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Leviathan',
    type: 'Kraken Shell',
    image: 'http://www.d2tomb.com/images/items/fieldplate.gif',
    props: [
      'Defense:',
      '1514-1722',
      'Indestructible',
      'Req Strength:',
      '174',
      'Type: Medium',
      'Req Level:',
      '65',
      '+170-200% Enhanced Defense',
      '+100-150 Defense',
      '+40-50 to Strength',
      'Damage Reduced by 15-25%',
    ],
    category: 'Elite Unique Armor',
    id: 33,
    filename: 'leviathan.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Steel Carapace',
    type: 'Shadow Plate',
    image: 'http://www.d2tomb.com/images/items/fullplate.gif',
    props: [
      'Defense:',
      '1618-1785',
      'Durability:',
      '70',
      'Req Strength:',
      '230',
      'Type: Heavy',
      'Req Level:',
      '66',
      '+190-220% Enhanced Defense',
      '10-15% Regenerate Mana',
      '20% Faster Hit Recovery',
      '+40-60% Cold Resist',
      'Damage Reduced by 9-14',
      'Repairs 1 Durability in 20 seconds',
      '8% Chance to cast Level 6 Iron Maiden when struck',
    ],
    category: 'Elite Unique Armor',
    id: 34,
    filename: 'fullplate.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Templar's Might",
    type: 'Sacred Armor',
    image: 'http://www.d2tomb.com/images/items/ancientarmor.gif',
    props: [
      'Defense:',
      '1622-1923',
      'Durability:',
      '60',
      'Req Strength:',
      '232',
      'Type: Medium',
      'Req Level:',
      '74',
      '+170-220% Enhanced Defense',
      '+250-300 Defense vs. Missile',
      '+10-15 to Strength',
      '+10-15 to Vitality',
      '20% Faster Hit Recovery',
      '+40-50 Maximum Stamina',
      '+1-2 to Offensive Auras (Paladin only)',
    ],
    category: 'Elite Unique Armor',
    id: 35,
    filename: 'templarsmight.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Tyrael's Might",
    type: 'Sacred Armor',
    image: 'http://www.d2tomb.com/images/items/ancientarmor.gif',
    props: [
      'Defense:',
      '1322-1502',
      'Indestructible',
      'Req Strength:',
      'none',
      'Type: Medium',
      'Req Level:',
      '84',
      '+120-150% Enhanced Defense',
      '+50-100% Damage to Demons',
      '20% Faster Run/Walk',
      '+20-30 to Strength',
      '+20-30 to all Resistances',
      'Cannot be Frozen',
      '-100% Requirements',
      'Slain Monsters Rest in Peace',
    ],
    category: 'Elite Unique Armor',
    id: 36,
    filename: 'tyraelsmight.gif',
    category_id: 13,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Gnasher',
    type: 'Hand Axe',
    image: 'http://www.d2tomb.com/images/uniques/thegnasher.gif',
    props: [
      'Damage:',
      '4-10',
      'Durability:',
      '28',
      'Req Level:',
      '5',
      '20% Chance of Crushing Blow',
      '50% Chance of Open Wounds',
      '+60-70% Enhanced Damage',
      '+8 to Strength',
    ],
    category: 'Unique Axes',
    id: 37,
    filename: 'thegnasher.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Deathspade',
    type: 'Axe',
    image: 'http://www.d2tomb.com/images/uniques/deathspade.gif',
    props: [
      'Req Strength:',
      '32',
      'Damage:',
      '14-18',
      'Durability:',
      '24',
      'Req Level:',
      '9',
      '+60-70% Enhanced Damage',
      '+4 Mana after each kill',
      '15% Bonus to Attack Rating',
      'Hit Blinds Target',
      '+8 to minimum Damage',
    ],
    category: 'Unique Axes',
    id: 38,
    filename: 'deathspade.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bladebone',
    type: 'Double Axe',
    image: 'http://www.d2tomb.com/images/uniques/bladebone.gif',
    props: [
      'Req Strength:',
      '43',
      'Damage:',
      '6-19',
      'Durability:',
      '24',
      'Req Level:',
      '15',
      '+30-50% Enhanced Damage',
      '20% Increased Attack Speed',
      '+40 to Attack Rating vs. Undead',
      '100% Damage to Undead',
      'Adds 8-12 Fire Damage',
      '+20 Defense',
    ],
    category: 'Unique Axes',
    id: 39,
    filename: 'bladebone.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Skull Splitter',
    type: 'Military Pick',
    image: 'http://www.d2tomb.com/images/uniques/skullsplitter.gif',
    props: [
      'Req Strength:',
      '49',
      'Req Dexterity:',
      '33',
      'Damage:',
      '11-22',
      'Durability:',
      '26',
      'Req Level:',
      '21',
      'Hit Blinds Target +2',
      'Regenerate Mana 20%',
      'Adds 1-15 Lightning Damage',
      '+60-100% Enhanced Damage',
      '+50-100 to Attack Rating',
      '15% Chance of Open Wounds',
    ],
    category: 'Unique Axes',
    id: 40,
    filename: 'skullsplitter.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rakescar',
    type: 'War Axe',
    image: 'http://www.d2tomb.com/images/uniques/rakescar.gif',
    props: [
      'Req Strength:',
      '67',
      'Damage:',
      '17-45',
      'Durability:',
      '26',
      'Req Level:',
      '27',
      'Poison Resist + 50%',
      '+75-150% Enhanced Damage',
      '+38 Poison Damage for 3 sec',
      '30% Increased Attack Speed',
      '+50 to Attack Rating',
    ],
    category: 'Unique Axes',
    id: 41,
    filename: 'rakescar.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Axe of Fechmar',
    type: 'Large Axe',
    image: 'http://www.d2tomb.com/images/uniques/axeoffechmar.gif',
    props: [
      'Req Strength:',
      '35',
      'Damage:',
      '10-24',
      'Durability:',
      '30',
      'Req Level:',
      '8',
      '+3 Freezes Target',
      '+2 to Light Radius',
      '+70-90% Enhanced Damage',
      '+50% Cold Resist',
    ],
    category: 'Unique Axes',
    id: 42,
    filename: 'axeoffechmar.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Goreshovel',
    type: 'Broad Axe',
    image: 'http://www.d2tomb.com/images/uniques/goreshovel.gif',
    props: [
      'Req Strength:',
      '48',
      'Damage:',
      '14-36',
      'Durability:',
      '35',
      'Req Level:',
      '14',
      '60% Chance of Open Wounds',
      '+25 to Strength',
      '30% Increased Attack Speed',
      '+40-50% Enhanced Damage',
      '+9 to maximum Damage',
    ],
    category: 'Unique Axes',
    id: 43,
    filename: 'goreshovel.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Chieftain',
    type: 'Battle Axe',
    image: 'http://www.d2tomb.com/images/uniques/thechieftan.gif',
    props: [
      'Req Strength:',
      '54',
      'Damage:',
      '24-64',
      'Durability:',
      '40',
      'Req Level:',
      '19',
      '+100% Enhanced Damage',
      '+10-20 to Resist All',
      '20% Increased Attack Speed',
      'Adds 1-40 Lightning Damage',
      '+6 Mana after each Kill',
    ],
    category: 'Unique Axes',
    id: 44,
    filename: 'thechieftan.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Brainhew',
    type: 'Great Axe',
    image: 'http://www.d2tomb.com/images/uniques/brainhew.gif',
    props: [
      'Req Strength:',
      '63',
      'Req Dexterity:',
      '39',
      'Damage:',
      '27-54',
      'Durability:',
      '50',
      'Req Level:',
      '25',
      'Adds 15-35 Fire Damage',
      '+50-80% Enhanced Damage',
      '+4 to Light Radius',
      '10-13% Mana stolen per hit',
      '+25 to Mana',
      '+14 to minimum Damage',
    ],
    category: 'Unique Axes',
    id: 45,
    filename: 'brainhew.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Humongous',
    type: 'Giant Axe',
    image: 'http://www.d2tomb.com/images/uniques/thehumongous.gif',
    props: [
      'Req Strength:',
      '84',
      'Damage:',
      '47-124',
      'Durability:',
      '50',
      'Req Level:',
      '29',
      '+80-120% Enhanced Damage',
      '33% Chance of Crushing Blow',
      '+20% to Requirements',
      'Adds 8-25 Damage',
      '+20-30 to Strength',
    ],
    category: 'Unique Axes',
    id: 46,
    filename: 'thehumongous.gif',
    category_id: 18,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Coldkill',
    type: 'Hatchet',
    image: 'http://www.d2tomb.com/images/items/handaxe.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '25',
      'Damage:',
      '25-60',
      'Durability:',
      '28',
      'Req Level:',
      '36',
      '+150-190% Enhanced Damage',
      '30% Increased Attack Speed',
      '10% Chance to cast level 10 Ice Blast on striking',
      '10% Chance to cast level 5 Frost Nova when struck',
      '+40 Cold Damage for 2 sec',
      '15% to maximum Cold Resist',
      '+15% Cold Resist',
    ],
    category: 'Exceptional Unique Axes',
    id: 47,
    filename: 'coldkill.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Butcher's Pupil",
    type: 'Cleaver',
    image: 'http://www.d2tomb.com/images/items/axe.gif',
    props: [
      'Req Strength:',
      '68',
      'Damage:',
      '55-149',
      'Durability:',
      'Indestructible',
      'Req Level:',
      '39',
      '+150-200% Enhanced Damage',
      'Adds 30-50 Damage',
      '35% Deadly Strike',
      '25% Chance of Open Wounds',
      '30% Increased Attack Speed',
    ],
    category: 'Exceptional Unique Axes',
    id: 48,
    filename: 'butcherspupil.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Islestrike',
    type: 'Twin Axe',
    image: 'http://www.d2tomb.com/images/items/doubleaxe.gif',
    props: [
      'Req Strength:',
      '85',
      'Damage:',
      '35-110',
      'Durability:',
      '24',
      'Req Level:',
      '43',
      '+170-190% Enhanced Damage',
      '25% Chance of Crushing Blow',
      '+2 to Druid Skills',
      '+50 Defense vs. Missles',
      '+10 to Energy, Vitality, Dexterity and Strength',
      '+1 to Fury (Druid only)',
      '+1 to Maul (Druid only)',
    ],
    category: 'Exceptional Unique Axes',
    id: 49,
    filename: 'doubleaxe.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Pompeii's Wrath",
    type: 'Crowbill',
    image: 'http://www.d2tomb.com/images/items/militarypick.gif',
    props: [
      'Req Strength:',
      '94',
      'Req Dexterity:',
      '70',
      'Damage:',
      '33-91',
      'Durability:',
      '26',
      'Req Level:',
      '45',
      '+140-170% Enhanced Damage',
      'Adds 35-150 Fire Damage',
      'Slows Target by 50%',
      '4% Chance to cast level 8 Volcano on striking',
      'Knockback',
    ],
    category: 'Exceptional Unique Axes',
    id: 50,
    filename: 'militarypick.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Guardian Naga',
    type: 'Naga',
    image: 'http://www.d2tomb.com/images/items/waraxe.gif',
    props: [
      'Req Strength:',
      '121',
      'Damage:',
      '40-145',
      'Durability:',
      '26',
      'Req Level:',
      '48',
      '+150-180% Enhanced Damage',
      '+20 to maximum Damage',
      '+250 Poison Damage over 10 sec',
      '5% Chance to cast level 8 Poison Nova on striking',
      '+30% Poison resist',
      'Attacker takes damage of 15',
    ],
    category: 'Exceptional Unique Axes',
    id: 51,
    filename: 'waraxe.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Warlord's Trust",
    type: 'Military Axe',
    image: 'http://www.d2tomb.com/images/items/largeaxe.gif',
    props: [
      'Req Strength:',
      '73',
      'Damage:',
      '38-93',
      'Durabiltiy: 30',
      'Req Level:',
      '35',
      '+175% Enhanced Damage',
      'Repairs 1 durability in 4 sec',
      '+0.5 per character level to Vitality',
      '+20 Replenish Life',
      '+10 to All Resistances',
      '+75 Defense',
    ],
    category: 'Exceptional Unique Axes',
    id: 52,
    filename: 'largeaxe.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Spellsteel',
    type: 'Beared Axe',
    image: 'http://www.d2tomb.com/images/items/broadaxe.gif',
    props: [
      'Req Strength:',
      '37',
      'Damage:',
      '55-129',
      'Durability:',
      '35',
      'Req Level:',
      '39',
      '+165% Enhanced Damage',
      '10% Faster Cast Rate',
      '-60% Requirements',
      '25% Regenerate Mana',
      '+100 to Mana',
      '60 charges level 12 Firestorm',
      '100 charges level 10 Holy Bolt',
      '30 charges level 3 Decrepify',
      '20 charges level 1 Teleport',
      'Magic Damage reduced by 12-15',
    ],
    category: 'Exceptional Unique Axes',
    id: 53,
    filename: 'broadaxe.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Stormrider',
    type: 'Tabar',
    image: 'http://www.d2tomb.com/images/items/battleaxe.gif',
    props: [
      'Req Strength:',
      '101',
      'Damage:',
      '83-229',
      'Durability:',
      '90',
      'Req Level:',
      '41',
      '+100% Enhanced Damage',
      'Adds 35-75 Damage',
      'Adds 1-200 Lightning Damage',
      '15% Chance to cast level 5 Charged Bolt when struck',
      '10% Chance to cast level 13-20 Charged Bolt on striking',
      '5% Chance to cast level 10 Chain Lightning on striking',
      'Attacker takes lightning Damage of 15',
    ],
    category: 'Exceptional Unique Axes',
    id: 54,
    filename: 'stormrider.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Boneslayer Blade',
    type: 'Gothic Axe',
    image: 'http://www.d2tomb.com/images/items/greataxe.gif',
    props: [
      'Req Strength:',
      '115',
      'Req Dexterity:',
      '79',
      'Damage:',
      '50-224',
      'Durability:',
      '50',
      'Req Level:',
      '42',
      '+180-220% Enhanced Damage',
      '+5 per character level to Attack Rating vs. undead',
      '+2.5 per character level to Damage to Undead',
      '50% Chance to cast level 12-20 Holy Bolt when struck',
      '20% Increased Attack Speed',
      '35% Bonus to Attack Rating',
      '200 charges Level 20 Holy Bolt',
      '+8 to Strength',
    ],
    category: 'Exceptional Unique Axes',
    id: 55,
    filename: 'greataxe.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Minotaur',
    type: 'Ancient Axe',
    image: 'http://www.d2tomb.com/images/items/giantaxe.gif',
    props: [
      'Req Strength:',
      '125',
      'Damage:',
      '123-285',
      'Durability:',
      '50',
      'Req Level:',
      '45',
      '+140-200% Enhanced Damage',
      'Adds 20-30 Damage',
      'Slows Target by 50%',
      '30% chance of Crushing Blow',
      '+2 Hit Blinds Target',
      'Half Freeze Duration',
      '+15-20 to Strength',
    ],
    category: 'Exceptional Unique Axes',
    id: 56,
    filename: 'minotuar.gif',
    category_id: 18,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Razor's Edge",
    type: 'Tomahawk',
    image: 'http://www.d2tomb.com/images/items/handaxe.gif',
    props: [
      'Req Strength:',
      '125',
      'Req Dexterity:',
      '67',
      'Damage:',
      '90-188',
      'Durability:',
      '28',
      'Req Level:',
      '67',
      '2-Handed',
      '+175-225% Enhanced Damage',
      '+40% Increased Attack Speed',
      '-33% Target Defense',
      '50% Deadly Strike',
      '50% Chance of Open Wounds',
    ],
    category: 'Elite Unique Axes',
    id: 57,
    filename: 'thegnasher.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Rune Master',
    type: 'Ettin Axe',
    image: 'http://www.d2tomb.com/images/items/doubleaxe.gif',
    props: [
      'Req Strength:',
      '145',
      'Req Dexterity:',
      '45',
      'Damage:',
      '105-244 Avg: 149',
      'Durability:',
      '24',
      'Req Level:',
      '72',
      '2-Handed',
      '+220-270% Enhanced Damage',
      '+5% Maximum Cold Resist',
      'Cannot be Frozen',
      '3-5 Sockets',
    ],
    category: 'Elite Unique Axes',
    id: 58,
    filename: 'doubleaxe.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Cranebeak',
    type: 'War Spike',
    image: 'http://www.d2tomb.com/images/items/militarypick.gif',
    props: [
      'Req Strength:',
      '133',
      'Req Dexterity:',
      '54',
      'Damage:',
      '102-192',
      'Durability:',
      '26',
      'Req Level:',
      '63',
      '1-Handed',
      '+240-300% Enhanced Damage',
      '+40% Increased Attack Speed',
      '-25% Target Defense',
      'Adds 1-305 Lightning Damage',
      '20-50% Better Chance of Magic Items',
      '15 Charges of Level 8 Raven',
    ],
    category: 'Elite Unique Axes',
    id: 59,
    filename: 'militarypick.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Death Cleaver',
    type: 'Berserker Axe',
    image: 'http://www.d2tomb.com/images/items/waraxe.gif',
    props: [
      'Req Strength:',
      '138',
      'Req Dexterity:',
      '59',
      'Damage:',
      '79-269',
      'Durability:',
      '26',
      'Req Level:',
      '70',
      '2-Handed',
      '+230-280% Enhanced Damage',
      '+40% Increased Attack Speed',
      '-33% Target Defense',
      '66% Deadly Strike',
      '+6-9 Life after each Kill',
    ],
    category: 'Elite Unique Axes',
    id: 60,
    filename: 'waraxe.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Ethereal Edge',
    type: 'Silver Edged Axe',
    image: 'http://www.d2tomb.com/images/items/broadaxe.gif',
    props: [
      'Req Strength:',
      '156',
      'Req Dexterity:',
      '55',
      'Damage:',
      '232-462',
      'Indestructible',
      'Req Level:',
      '74',
      '2-Handed',
      '+150-180% Enhanced Damage',
      '+150-200% Damage to Demons',
      '+270-350 to Attack Rating',
      '+25% Increased Attack Speed',
      '+10-12 Fire Absorb',
      '+5-10 Life after each Demon Kill',
      'Ethereal',
    ],
    category: 'Elite Unique Axes',
    id: 61,
    filename: 'broadaxe.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Hellslayer',
    type: 'Decapitator',
    image: 'http://www.d2tomb.com/images/uniques/chieftain.gif',
    props: [
      'Req Strength:',
      '189',
      'Req Dexterity:',
      '33',
      'Durability:',
      '40',
      'Damage:',
      '98-680',
      '2-Handed',
      'Req Level:',
      ' 66',
      '+100% Enhanced Damage',
      '+(3 per character level) to maximum Damage',
      'Adds 150-250 Fire Damage',
      '+0.5 per character level to Strength and Vitality',
      '10% chance to cast level 16-20 Fire Ball on attack',
      '+25 to Life',
    ],
    category: 'Elite Unique Axes',
    id: 62,
    filename: 'chieftain.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Messerschmidt's Reaver",
    type: 'Champion Axe',
    image: 'http://www.d2tomb.com/images/uniques/brainhew.gif',
    props: [
      'Req Strength:',
      '167',
      'Req Dexterity:',
      '59',
      'Damage:',
      '177-514',
      'Durability:',
      '75',
      '2-Handed',
      'Req Level:',
      '70',
      '+200% Enhanced Damage',
      'Adds 20-240 Fire Damage',
      '+15 to Energy',
      '+15 to Vitality',
      '+15 to Dexterity',
      '+15 to Strength',
      '+2.5 per character level to Maximum Damage',
      '100% Bonus to Attack Rating',
    ],
    category: 'Elite Unique Axes',
    id: 63,
    filename: 'brainhew.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Executioner's Justice",
    type: 'Glorious Axe',
    image: 'http://www.d2tomb.com/images/items/giantaxe.gif',
    props: [
      'Req Strength:',
      '164',
      'Req Dexterity:',
      '55',
      'Damage:',
      '204-483',
      'Durability:',
      ' 50',
      'Req Level:',
      '75',
      '2-Handed',
      '+240-290% Enhanced Damage',
      '25% Chance of Crushing Blow',
      '30% Increased Attack Speed',
      "-33% Target's Defense",
      '50% Chance to cast Level 6 Decrepify when you kill an enemy',
    ],
    category: 'Elite Unique Axes',
    id: 64,
    filename: 'giantaxe.gif',
    category_id: 18,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Lenymo',
    type: 'Sash',
    image: 'http://www.d2tomb.com/images/uniques/lenymscord.gif',
    props: [
      'Capacity:',
      '8',
      'Defense:',
      '2',
      'Durability:',
      '12',
      'Req Strength:',
      'none',
      'Req Level:',
      '7',
      '+1 to Light Radius',
      'Regenerate Mana 30%',
      'All Resistances +5',
      '+15 to Mana',
    ],
    category: 'Unique Belts',
    id: 65,
    filename: 'lenymo.gif',
    category_id: 17,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Snakecord',
    type: 'Light Belt',
    image: 'http://www.d2tomb.com/images/uniques/snakecord.gif',
    props: [
      'Capacity:',
      '8',
      'Defense:',
      '14-15',
      'Durability:',
      '14',
      'Req Strength:',
      'none',
      'Req Level:',
      '12',
      'Replenish Life +5',
      'Poison Resist +25%',
      '+20-30% Enhanced Defense',
      'Poison length reduced by 50%',
      '+12 Poison Damage for 3 sec',
      '+10 Defense',
    ],
    category: 'Unique Belts',
    id: 66,
    filename: 'snakecord.gif',
    category_id: 17,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Nightsmoke',
    type: 'Belt',
    image: 'http://www.d2tomb.com/images/uniques/nightsmoke.gif',
    props: [
      'Capacity:',
      '12',
      'Defense:',
      '22-24',
      'Durability:',
      '16',
      'Req Strength:',
      '25',
      'Req Level:',
      '20',
      '50% Damage taken goes to Mana',
      'Damage reduced by 2',
      'All Resistances +10',
      '+15 Defense',
      '+30-50% Enhanced Defense',
      '+20 to Mana',
    ],
    category: 'Unique Belts',
    id: 67,
    filename: 'nightsmoke.gif',
    category_id: 17,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Goldwrap',
    type: 'Heavy Belt',
    image: 'http://www.d2tomb.com/images/uniques/goldwrap.gif',
    props: [
      'Capacity:',
      '12',
      'Defense:',
      '34-36',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '27',
      '+2 to Light Radius',
      '+25 Defense',
      '10% Increased Attack Speed',
      '+40-60% Enhanced Defense',
      '50-80% Extra Gold from Monsters',
      '30% better chance of getting magic item',
    ],
    category: 'Unique Belts',
    id: 68,
    filename: 'goldwrap.gif',
    category_id: 17,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bladebuckle',
    type: 'Plated Belt',
    image: 'http://www.d2tomb.com/images/uniques/bladebuckle.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '51-54',
      'Durability:',
      '24',
      'Req Strength:',
      '60',
      'Req Level:',
      '29',
      'Damage reduced by 3',
      '+80-100% Enhanced Defense',
      '+30% Faster Hit Recovery',
      '+30 Defense',
      '+10 to Dexterity',
      '+5 to Strength',
      'Attacker takes damage of 8',
    ],
    category: 'Unique Belts',
    id: 69,
    filename: 'bladebuckle.gif',
    category_id: 17,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'String of Ears',
    type: 'Demonhide Sash',
    image: 'http://www.d2tomb.com/images/items/sash.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '102-113',
      'Durability:',
      '22',
      'Req Stength: 20',
      'Req Level:',
      '29',
      '+150-180% Enhanced Defense',
      '+15 Defense',
      '6-8% Life Stolen per Hit',
      'Damage Reduced by 10-15%',
      'Magic Damage reduced by 10-15',
    ],
    category: 'Exceptional Unique Belts',
    id: 70,
    filename: 'sash.gif',
    category_id: 17,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Razortail',
    type: 'Sharkskin Belt',
    image: 'http://www.d2tomb.com/images/items/lightbelt.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '96-107',
      'Durability:',
      '14',
      'Req Strength:',
      '20',
      'Req Level:',
      '32',
      '+120-150% Enhanced Defense',
      '+15 Defense',
      '+10 to maximum Damage',
      'Piercing Attack',
      '+15 to Dexterity',
      'Attacker takes Damage of 1 per character level',
    ],
    category: 'Exceptional Unique Belts',
    id: 71,
    filename: 'lightbelt.gif',
    category_id: 17,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Gloom's Trap",
    type: 'Mesh Belt',
    image: 'http://www.d2tomb.com/images/items/belt.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '90-102',
      'Durability:',
      '16',
      'Req Strength:',
      '58',
      'Req Level:',
      '36',
      '+120-150% Enhanced Defense',
      '5% Mana Stolen per hit',
      '15% increased Maximum Mana',
      '15% Regenerate Mana',
      '+15 to Vitality',
      '-3 to Light Radius',
    ],
    category: 'Exceptional Unique Belts',
    id: 72,
    filename: 'belt.gif',
    category_id: 17,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Snowclash',
    type: 'Battle Belt',
    image: 'http://www.d2tomb.com/images/items/heavybelt.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '98-116',
      'Durability:',
      '18',
      'Req Strength:',
      '88',
      'Req Level:',
      '42',
      '+130-170% Enhanced Defense',
      '5% chance to cast level 7-20 Blizzard when struck',
      '+15 Cold Absorb',
      '15% to maximum Cold Resist',
      'Adds 13-21 Cold Damage for 3 sec',
      '+2 to Chilling Armor (Sorceress only)',
      '+2 to Blizzard (Sorceress only)',
      '+3 to Glacial Spike (Sorceress only)',
    ],
    category: 'Exceptional Unique Belts',
    id: 73,
    filename: 'heavybelt.gif',
    category_id: 17,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Thundergod's Vigor",
    type: 'War Belt',
    image: 'http://www.d2tomb.com/images/items/girdle.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '137-159',
      'Durability:',
      '24',
      'Req Strength:',
      '110',
      'Req Level:',
      '47',
      '+160-200% Enhanced Defense',
      'Adds 1-50 Lightning Damage',
      '5% chance to cast level 7 Fist of Heavens when struck',
      '+20 Lightning Absorb',
      '10% to maximum Lightning Resist',
      '+20 to Strength and Vitality',
      '+3 to Lightning Fury (Amazon only)',
      '+3 to Lightning Strike (Amazon only)',
    ],
    category: 'Exceptional Unique Belts',
    id: 74,
    filename: 'girdle.gif',
    category_id: 17,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Arachnid Mesh',
    type: 'Spiderweb Sash',
    image: 'http://www.d2tomb.com/images/items/arachnid-mesh.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '119-138',
      'Durability:',
      '12',
      'Req Strength:',
      '50',
      'Req Level:',
      '80',
      '+90-120% Enhanced Defense',
      'Slows Target by 10%',
      '+1 to all Skills',
      '+20% Faster Cast Rate',
      '5% Increased Maximum Mana',
      '11 Charges Level 3 Venom',
    ],
    category: 'Elite Unique Belts',
    id: 75,
    filename: 'arachnid-mesh.gif',
    category_id: 17,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Nosferatu's Coil",
    type: 'Vampirefang Belt',
    image: 'http://www.d2tomb.com/images/items/lightbelt.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '56-63',
      'Durability:',
      '14',
      'Req Strength:',
      '50',
      'Req Level:',
      '51',
      'Slows Target by 10%',
      '+2 to Mana after each kill',
      '-3 to Light Radius',
      '5-7% Life Stolen per Hit',
      '+15 to Strength',
      '10% increased Attack Speed',
    ],
    category: 'Elite Unique Belts',
    id: 76,
    filename: 'lightbelt.gif',
    category_id: 17,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Verdungo's Hearty Cord",
    type: 'Mithril Coil',
    image: 'http://www.d2tomb.com/images/items/belt.gif',
    props: [
      'Capacity:',
      '16',
      'Defense:',
      '125-158',
      'Durability:',
      '16',
      'Req Strength:',
      '106',
      'Req Level:',
      '63',
      '+90-140% Enhanced Defense',
      '10% Faster Hit Recovery',
      '+30-40 to Vitality',
      '+10-13 Replenish Life',
      '+100-120 Maximum Stamina',
      'Damage Reduced by 10-15%',
    ],
    category: 'Elite Unique Belts',
    id: 77,
    filename: 'verdungosheartycord.gif',
    category_id: 17,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Hotspur',
    type: 'Boots',
    image: 'http://www.d2tomb.com/images/uniques/hotspur.gif',
    props: [
      'Assassin Kick Damage:',
      '3-8',
      'Defense:',
      '10',
      'Durability:',
      '12',
      'Req Strength:',
      'none',
      'Req Level:',
      '5',
      '+10-20% Enhanced Defense',
      '+6 Defense',
      '+45% Fire Resist',
      'Adds 3-6 Fire Damage',
      '+15 to Life',
      '15% to maximum Fire Resist',
    ],
    category: 'Unique Boots',
    id: 78,
    filename: 'hotspur.gif',
    category_id: 16,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Gorefoot',
    type: 'Heavy Boots',
    image: 'http://www.d2tomb.com/images/uniques/gorefoot.gif',
    props: [
      'Assassin Kick Damage:',
      '4-10',
      'Defense:',
      '20-21',
      'Durability:',
      '14',
      'Req Strength:',
      '18',
      'Req Level:',
      '9',
      '+20-30% Enhanced Defense',
      '+2 to Leap (Barbarian only)',
      '20% Faster Run/Walk',
      '2% Mana stolen per hit',
      '+12 to Defense',
      'Attacker takes damage of 2',
    ],
    category: 'Unique Boots',
    id: 79,
    filename: 'gorefoot.gif',
    category_id: 16,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Treads of Cthon',
    type: 'Chain Boots',
    image: 'http://www.d2tomb.com/images/uniques/treadsofcthon.gif',
    props: [
      'Assassin Kick Damage:',
      '6-12',
      'Defense:',
      '25-26',
      'Durability:',
      '16',
      'Req Strength:',
      '30',
      'Req Level:',
      '15',
      '+30-40% Enhanced Defense',
      '+12 Defense',
      '50% slower Stamina Drain',
      '30% Faster Run/Walk',
      '+50 Defense vs. missle',
      '+10 to Life',
    ],
    category: 'Unique Boots',
    id: 80,
    filename: 'treadsofcthon.gif',
    category_id: 16,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Goblin Toe',
    type: 'Light Plated Boots',
    image: 'http://www.d2tomb.com/images/uniques/goblintoe.gif',
    props: [
      'Assassin Kick Damage:',
      '8-16',
      'Defense:',
      '33-34',
      'Durability:',
      '18',
      'Req Strength:',
      '50',
      'Req Level:',
      '22',
      '+50-60% Enhanced Defense',
      '25% chance of Crushing Blow',
      '-1 to Light Radius',
      'Damage Reduced by 1',
      'Magic damage reduced by 1',
      '+15 to Defense',
    ],
    category: 'Unique Boots',
    id: 81,
    filename: 'goblintoe.gif',
    category_id: 16,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Tearhaunch',
    type: 'Greaves',
    image: 'http://www.d2tomb.com/images/uniques/tearhaunch.gif',
    props: [
      'Assassin Kick Damage:',
      '10-20',
      'Defense:',
      '60-63',
      'Durability:',
      '24',
      'Req Strength:',
      '70',
      'Req Level:',
      '29',
      '+60-80% Enhanced Defense',
      '20% Faster Run/Walk',
      '+10 to All Resistances',
      '+35 Defense',
      '+5 to Dexterity',
      '+5 to Strength',
      '+2 to Vigor (Paladin only)',
    ],
    category: 'Unique Boots',
    id: 82,
    filename: 'tearhaunch.gif',
    category_id: 16,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Infernostride',
    type: 'Demonhide Boots',
    image: 'http://www.d2tomb.com/images/items/leatherboots.gif',
    props: [
      'Assassin Kick Damage:',
      '26-46',
      'Defense:',
      '94-105',
      'Durability:',
      '12',
      'Req Strength:',
      '20',
      'Req Level:',
      '29',
      '+120-150% Enhanced Defense',
      'Adds 12-33 Fire Damage',
      '+15 Defense',
      '20% Faster Run/Walk',
      '5% chance to cast level 8 Blaze when struck',
      '+30% Fire Resist',
      '10% to maximum Fire Resist',
      '40-70% extra Gold from monsters',
      '+2 to Light Radius',
    ],
    category: 'Exceptional Unique Boots',
    id: 83,
    filename: 'leatherboots.gif',
    category_id: 16,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Waterwalk',
    type: 'Sharkskin Boots',
    image: 'http://www.d2tomb.com/images/items/heavyboots.gif',
    props: [
      'Assassin Kick Damage:',
      '28-50',
      'Defense:',
      '112-124',
      'Durability:',
      '14',
      'Req Strength:',
      '47',
      'Req Level:',
      '32',
      '+180-210% Enhanced Defense',
      '20% Faster Run/Walk',
      '+100 Defense vs. missles',
      '+15 to Dexterity',
      '+5% to maximum Fire Resist',
      '+50% Heal Stamina',
      '+40 to maximum Stamina',
      '+45-65 to Life',
    ],
    category: 'Exceptional Unique Boots',
    id: 84,
    filename: 'heavyboots.gif',
    category_id: 16,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Silkweave',
    type: 'Mesh Boots',
    image: 'http://www.d2tomb.com/images/items/chainboots.gif',
    props: [
      'Assassin Kick Damage:',
      '23-52',
      'Defense:',
      '112-130',
      'Durability:',
      '16',
      'Req Strength:',
      '65',
      'Req Level:',
      '36',
      '+150-190% Enhanced Defense',
      '30% Faster',
      'Run/Walk',
      '+5 to Mana after each kill',
      '10% increased maximum Mana',
      '+200 Defense vs. missles',
    ],
    category: 'Exceptional Unique Boots',
    id: 85,
    filename: 'chainboots.gif',
    category_id: 16,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'War Traveler',
    type: 'Battle Boots',
    image: 'http://www.d2tomb.com/images/items/lightplateboots.gif',
    props: [
      'Assassin Kick Damage:',
      '37-64',
      'Defense:',
      '120-139',
      'Durability:',
      '48',
      'Req Strength:',
      '95',
      'Req Level:',
      '42',
      '+150-190% Enhanced Defense',
      '25% Faster Run/Walk',
      '+10 to Vitality and Strength',
      'Adds 15-25 Damage',
      '40% Slower Stamina Drain',
      'Attacker takes Damage of 5-10',
      '30-50% better chance of getting Magic Items',
    ],
    category: 'Exceptional Unique Boots',
    id: 86,
    filename: 'lightplateboots.gif',
    category_id: 16,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Gore Rider',
    type: 'War Boots',
    image: 'http://www.d2tomb.com/images/items/plateboots.gif',
    props: [
      'Assassin Kick Damage:',
      '39-80',
      'Defense:',
      '140-162',
      'Durability:',
      '34',
      'Req Strength:',
      '94',
      'Req Level:',
      '47',
      '+160-200% Enhanced Defense',
      '30% Faster Run/Walk',
      '10% chance of Open Wounds',
      '15% chance of Crushing Blow',
      '15% chance of Deadly Strike',
      '-25% Requirements',
      '+20 to maximum Stamina',
    ],
    category: 'Exceptional Unique Boots',
    id: 87,
    filename: 'plateboots.gif',
    category_id: 16,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Sandstorm Trek',
    type: 'Scarabshell Boots',
    image: 'http://www.d2tomb.com/images/items/heavyboots.gif',
    props: [
      'Assassin Kick Damage:',
      '60-110',
      'Defense:',
      '158-178',
      'Durability:',
      '14',
      'Req Strength:',
      '91',
      'Req Level:',
      '64',
      '+140-170% Enhanced Defense',
      '+20% Faster Hit Recovery',
      '+20% Faster Run/Walk',
      '+1 per character level to maximum Stamina',
      '+10-15 to Strength',
      '+10-15 to Vitality',
      '50% Slower Stamina Drain',
      '+40-70% Poison Resist',
      'Repairs 1 Durability in 20 seconds',
    ],
    category: 'Elite Unique Boots',
    id: 88,
    filename: 'heavyboots.gif',
    category_id: 16,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Marrowwalk',
    type: 'Boneweave Boots',
    image: 'http://www.d2tomb.com/images/items/chainboots.gif',
    props: [
      'Assassin Kick Damage:',
      '69-118',
      'Defense:',
      '183-204',
      'Durability:',
      '16',
      'Req Strength:',
      '118',
      'Req Level:',
      '66',
      '+170-200% Enhanced Defense',
      '+20% Faster Run/Walk',
      '+1-2 Skeleton Mastery (Necro only)',
      '+10-20 to Strength',
      '+17 to Dexterity',
      '10% Regenerate Mana',
      '+10% Heal Stamina',
      'Half Freeze Duration',
      '13 Charges Level 33 Bone Prison',
      '10 Charges Level 12 Life Tap',
    ],
    category: 'Elite Unique Boots',
    id: 89,
    filename: 'chainboots.gif',
    category_id: 16,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Shadow Dancer',
    type: 'Myrmidon Greaves',
    image: 'http://www.d2tomb.com/images/items/plateboots.gif',
    props: [
      'Assassin Kick Damage:',
      '83-149',
      'Defense:',
      '122-144',
      'Durability:',
      '24',
      'Req Strength:',
      '167',
      'Req Level:',
      '71',
      '+70-100% Enhanced Defense',
      '+1-2 to Shadow Disciplines (Assassin only)',
      '+30% Faster Run/Walk',
      '+30% Faster Hit Recovery',
      '+15-25 to Dexterity',
      '-20% Requirements',
    ],
    category: 'Elite Unique Boots',
    id: 90,
    filename: 'shadowdancer.gif',
    category_id: 16,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Pluckeye',
    type: 'Short Bow',
    image: 'http://www.d2tomb.com/images/uniques/pluckeye.gif',
    props: [
      'Damage:',
      '2-8',
      'Req Dexterity:',
      '15',
      'Req Level:',
      '7',
      '+2 to Light Radius',
      '3% Mana stolen per hit',
      '+100% Enhanced Damage',
      '+28 to Attack Rating',
      '+2 to Mana after each kill',
      '+10 to Life',
    ],
    category: 'Unique Bows',
    id: 91,
    filename: 'pluckeye.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Witherstring',
    type: 'Hunter Bow',
    image: 'http://www.d2tomb.com/images/uniques/witherstring.gif',
    props: [
      'Damage:',
      '3-12',
      'Req Dexterity:',
      '28',
      'Req Level:',
      '13',
      '+40-50% Enhanced Damage',
      'Fires level 3 Magic Arrows',
      '+50 to Attack Rating',
      'Adds 1-3 Damage',
      '30% increased attack speed',
    ],
    category: 'Unique Bows',
    id: 92,
    filename: 'witherstring.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Raven Claw',
    type: 'Long Bow',
    image: 'http://www.d2tomb.com/images/uniques/ravensclaw.gif',
    props: [
      'Damage:',
      '4-17',
      'Req Dexterity:',
      '19',
      'Req Strength:',
      '22',
      'Req Level:',
      '15',
      '+60-70% Enhanced Damage',
      'Fires level 3 Explosive Arrows',
      '50% bonus to Attack Rating',
      '+3 to Dexterity',
      '+3 to Strength',
    ],
    category: 'Unique Bows',
    id: 93,
    filename: 'ravensclaw.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Rogue's Bow",
    type: 'Composite Bow',
    image: 'http://www.d2tomb.com/images/uniques/roguesbow.gif',
    props: [
      'Damage:',
      '5-12',
      'Req Dexterity:',
      '35',
      'Req Strength:',
      '25',
      'Req Level:',
      '20',
      '+40-60% Enhanced Damage',
      '30% Deadly Strike',
      '+100% Damage to Undead',
      '+10 to all Resistances',
      '+60 to Attack Rating',
      '50% Increased Attack Speed',
    ],
    category: 'Unique Bows',
    id: 94,
    filename: 'roguesbow.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Stormstrike',
    type: 'Short Battle Bow',
    image: 'http://www.d2tomb.com/images/uniques/stormstrike.gif',
    props: [
      'Damage:',
      '8-20',
      'Req Dexterity:',
      '40',
      'Req Strength:',
      '30',
      'Req Level:',
      '25',
      '+70-90% Enhanced Damage',
      'Piercing attack',
      '+25% Lightning Resist',
      '+28 to Attack Rating',
      'Adds 1-30 Lightning Damage',
      '+8 to Strength',
    ],
    category: 'Unique Bows',
    id: 95,
    filename: 'stormstrike.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Wizendraw',
    type: 'Long Battle Bow',
    image: 'http://www.d2tomb.com/images/uniques/wizendraw.gif',
    props: [
      'Damage:',
      '5-32',
      'Req Dexterity:',
      '50',
      'Req Strength:',
      '40',
      'Req Level:',
      '26',
      '+70-80% Enhanced Damage',
      'Fires level 5 Magic Arrows',
      'Cold Resist 26%',
      '+50-100 to Attack Rating',
      '+15 to Energy',
      '+30 to Mana',
      '20% Increased Attack Speed',
      '-20-35% to enemy Cold Resist',
    ],
    category: 'Unique Bows',
    id: 96,
    filename: 'wizendraw.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Hellclap',
    type: 'Short War Bow',
    image: 'http://www.d2tomb.com/images/uniques/hellclap.gif',
    props: [
      'Damage:',
      '10-26',
      'Req Dexterity:',
      '55',
      'Req Strength:',
      '35',
      'Req Level:',
      '27',
      '+70-90% Enhanced Damage',
      '+40% Fire Resist',
      'Adds 15-50 Fire Damage',
      '+50-75 to Attack Rating',
      '+12 to Dexterity',
      '10% increased attack speed',
      '+1 to Fire Skills',
    ],
    category: 'Unique Bows',
    id: 97,
    filename: 'hellclap.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Blastbark',
    type: 'Long War Bow',
    image: 'http://www.d2tomb.com/images/uniques/blastbark.gif',
    props: [
      'Damage:',
      '5-52',
      'Req Dexterity:',
      '65',
      'Req Strength:',
      '50',
      'Req Level:',
      '28',
      '+70-130% Enhanced Damage',
      '+1 to Amazon Skill levels',
      '3% Mana stolen per hit',
      '+5 to Strength',
      '+2 to Exploding Arrow (Amazon only)',
    ],
    category: 'Unique Bows',
    id: 98,
    filename: 'blastbark.gif',
    category_id: 19,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Skystrike',
    type: 'Edge Bow',
    image: 'http://www.d2tomb.com/images/items/shortbow.gif',
    props: [
      'Req Dexterity:',
      '43',
      'Req Strength:',
      '25',
      'Damage:',
      '17-60',
      'Req Level:',
      '28',
      '+150-200% Enhanced Damage',
      'Adds 1-250 Lightning Damage',
      '2% chance to cast level 6 Meteor on striking',
      '30% Increased Attack Rate',
      '+100 to Attack Rating',
      '+1 to Amazon Skill Levels (Amazon only)',
      '+10 to Energy',
    ],
    category: 'Exceptional Unique Bows',
    id: 99,
    filename: 'shortbow.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Riphook',
    type: 'Razor Bow',
    image: 'http://www.d2tomb.com/images/items/huntersbow.gif',
    props: [
      'Req Dexterity:',
      '62',
      'Req Strength:',
      '25',
      'Damage:',
      '22-70',
      'Req Level:',
      '31',
      '+180-220% Enhanced Damage',
      'Slows Target by 30%',
      '30% chance of Open Wounds',
      '30% Increased Attack Speed',
      '7-10% Life stolen per hit',
      '+35 to Mana',
    ],
    category: 'Exceptional Unique Bows',
    id: 100,
    filename: 'huntersbow.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Kuko Shakaku',
    type: 'Cedar Bow',
    image: 'http://www.d2tomb.com/images/items/longbow.gif',
    props: [
      'Req Dexterity:',
      '49',
      'Req Strength:',
      '53',
      'Damage:',
      '25-81',
      'Req Level:',
      '33',
      '+150-180% Enhanced Damage',
      'Fires level 7 Explosive Arrows',
      'Piercing Attack',
      'Adds 40-180 Fire Damage',
      '+3 to Immolation Arrow (Amazon only)',
      '+3 to Bow and Crossbow Skills (Amazon only)',
    ],
    category: 'Exceptional Unique Bows',
    id: 101,
    filename: 'kukoshakaku.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Endlesshail',
    type: 'Double Bow',
    image: 'http://www.d2tomb.com/images/items/compositebow.gif',
    props: [
      'Req Dexterity:',
      '73',
      'Req Strength:',
      '58',
      'Damage:',
      '30-83',
      'Req Level:',
      '36',
      '+180-220% Enhanced Damage',
      'Adds 15-30 Cold Damage for 3 sec',
      '+35% Cold Resist',
      '+50 Defense vs. missles',
      '+40 to Mana',
      '+3-5 to Strafe (Amazon only)',
    ],
    category: 'Exceptional Unique Bows',
    id: 102,
    filename: 'piercerib.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Witchwild String',
    type: 'Short Siege Bow',
    image: 'http://www.d2tomb.com/images/items/shortbattlebow.gif',
    props: [
      'Req Dexterity:',
      '80',
      'Req Strength:',
      '65',
      'Damage:',
      '32-81',
      'Req Level:',
      '39',
      '+150-170% Enhanced Damage',
      'Fires level 20 Magic Arrows',
      '2% chance to cast level 5 Amplify Damage on striking',
      '+1 per character level % Deadly strike',
      '+40 to all Resistances',
      '2 sockets',
    ],
    category: 'Exceptional Unique Bows',
    id: 103,
    filename: 'whichwildstring.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Cliffkiller',
    type: 'Large Siege Bow',
    image: 'http://www.d2tomb.com/images/items/longbattlebow.gif',
    props: [
      'Req Dexterity:',
      '95',
      'Req Strength:',
      '80',
      'Damage:',
      '34-168',
      'Req Level:',
      '41',
      '+190-230% Enhanced Damage',
      'Adds 5-30 Damage',
      '+80 Defense vs. missles',
      '+50 to Life',
      'Knockback',
      '+2 to Amazon Skill Levels',
    ],
    category: 'Exceptional Unique Bows',
    id: 104,
    filename: 'longbattlebow.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Magewrath',
    type: 'Rune Bow',
    image: 'http://www.d2tomb.com/images/items/shortwarbow.gif',
    props: [
      'Req Dexterity:',
      '103',
      'Req Strength:',
      '73',
      'Damage:',
      '55-137',
      'Req Level:',
      '43',
      '+120-150% Enhanced Damage',
      'Adds 20-50 Damage',
      '+200-250 to Attack Rating',
      'Hit Blinds Target',
      '15% Mana stolen per hit',
      'Magic Damage reduced by 9-13',
      '+10 to Dexterity',
      '+3 to Guided Arrow (Amazon only)',
      '+1 to Amazon Skill Levels',
    ],
    category: 'Exceptional Unique Bows',
    id: 105,
    filename: 'hellclap.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Goldstrike Arch',
    type: 'Gothic Bow',
    image: 'http://www.d2tomb.com/images/items/longwarbow.gif',
    props: [
      'Req Dexterity:',
      '118',
      'Req Strength:',
      '95',
      'Damage:',
      '30-175',
      'Req Level:',
      '46',
      '+200-250% Enhanced Damage',
      '+100-200% Damage to Demons',
      '+100-200% Damage to Undead',
      '50% Increased Attack Speed',
      '5% chance to cast level 7 Fist of Heavens on striking',
      '+12 Replenish Life',
      '+100-150% Bonus to Attack Rating',
    ],
    category: 'Exceptional Unique Bows',
    id: 106,
    filename: 'goldstrikearch.gif',
    category_id: 19,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Eaglehorn',
    type: 'Crusader Bow',
    image: 'http://www.d2tomb.com/images/uniques/blastbark.gif',
    props: [
      'Damage:',
      '45-313',
      'Req Dexterity:',
      '121',
      'Req Strength:',
      '97',
      'Req Level:',
      '69',
      '+200% Enhanced Damage',
      '+(2 per character level) to maximum Damage',
      '+(6 per character level) to Attack Rating',
      "Ignore's Target Defense",
      '+25 to Dexterity',
      '+1 to Amazon Skills',
    ],
    category: 'Elite Unique Bows',
    id: 107,
    filename: 'blastbark.gif',
    category_id: 19,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Widowmaker',
    type: 'Ward Bow',
    image: 'http://www.d2tomb.com/images/items/shortwarbow.gif',
    props: [
      'Req Strength:',
      '146',
      'Req Dexterity:',
      '72',
      'Damage:',
      '50-159',
      'Req Level:',
      '65',
      '+150-200% Enhanced Damage',
      "Ignore Target's Defense",
      '33% Deadly Strike',
      '+3-5 to Guided Arrow',
      'Fires level 11 Magic Arrows',
    ],
    category: 'Elite Unique Bows',
    id: 108,
    filename: 'widowmaker.gif',
    category_id: 19,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Windforce',
    type: 'Hydra Bow',
    image: 'http://www.d2tomb.com/images/uniques/blastbark.gif',
    props: [
      'Damage:',
      '35-547',
      'Req Dexterity:',
      '167',
      'Req Strength:',
      '134',
      'Req Level:',
      '73',
      '+250% Enhanced Damage',
      '+10 to Strength',
      '+(3.125 per character level) to maximum Damage',
      '20% increased Attack Speed',
      '6-8% Mana stolen per hit',
      '+30% Heal Stamina',
      '+5 to Dexterity',
      'Knockback',
    ],
    category: 'Elite Unique Bows',
    id: 109,
    filename: 'windforce.gif',
    category_id: 19,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Leadcrow',
    type: 'Light Crossbow',
    image: 'http://www.d2tomb.com/images/uniques/leadcrow.gif',
    props: [
      'Damage:',
      '10-15',
      'Req Dexterity:',
      '27',
      'Req Strength:',
      '21',
      'Req Level:',
      '9',
      '25% Deadly strike',
      '70% Enhanced Damage',
      '+30% Poison Resist',
      '+40 to Attack Rating',
      '+10 to Life',
      '+10 to Dexterity',
    ],
    category: 'Unique Crossbows',
    id: 110,
    filename: 'leadcrow.gif',
    category_id: 20,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Ichorsting',
    type: 'Crossbow',
    image: 'http://www.d2tomb.com/images/uniques/ichorsting.gif',
    props: [
      'Damage:',
      '13-24',
      'Req Dexterity:',
      '33',
      'Req Strength:',
      '40',
      'Req Level:',
      '18',
      '+50% Enhanced Damage',
      '20% Increased Attack Speed',
      'Piercing Attack',
      'Adds 30 Poison Damage for 3 seconds',
      '+50 to Attack Rating',
      '+20 to Dexterity',
    ],
    category: 'Unique Crossbows',
    id: 111,
    filename: 'ichorsting.gif',
    category_id: 20,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Hellcast',
    type: 'Heavy Crossbow',
    image: 'http://www.d2tomb.com/images/uniques/hellcast.gif',
    props: [
      'Damage:',
      '23-46',
      'Req Dexterity:',
      '40',
      'Req Strength:',
      '60',
      'Req Level:',
      '27',
      '+70-80% Enhanced Damage',
      'Adds 15-35 Fire Damage',
      'Fires level 5 Explosive Arrows',
      '15% to Max Fire resist',
      '15% Fire Resist',
      '+70 to Attack Rating',
      '20% Increased Attack Speed',
    ],
    category: 'Unique Crossbows',
    id: 112,
    filename: 'hellcast.gif',
    category_id: 20,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Doomslinger',
    type: 'Repeating Crossbow',
    image: 'http://www.d2tomb.com/images/uniques/doomslinger.gif',
    props: [
      'Damage:',
      '9-24',
      'Req Dexterity:',
      '50',
      'Req Strength:',
      '40',
      'Req Level:',
      '28',
      '+60-100% Enhanced Damage',
      'Piercing Attack',
      '+1 to Amazon Skill levels',
      '+15 to Life',
      '+30% increased Attack Speed',
    ],
    category: 'Unique Crossbows',
    id: 113,
    filename: 'doomslinger.gif',
    category_id: 20,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Langer Briser',
    type: 'Arbalest',
    image: 'http://www.d2tomb.com/images/items/lightcrossbow.gif',
    props: [
      'Req Dexterity:',
      ' 61',
      'Req Strength:',
      '52',
      'Damage:',
      '37-111',
      'Req Level:',
      '32',
      '+170-200% Enhanced Damage',
      '+10-30 to maximum Damage',
      '33% chance of Open Wounds',
      'Adds 1-212 Lightning Damage',
      '+30 to Life',
      'Knockback',
      '30-60% better chance of getting Magic item',
    ],
    category: 'Exceptional Unique Crossbows',
    id: 114,
    filename: 'langerbriser.gif',
    category_id: 20,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Pus Spitter',
    type: 'Siege Crossbow',
    image: 'http://www.d2tomb.com/images/items/crossbow.gif',
    props: [
      'Req Dexterity:',
      '28',
      'Req Strength:',
      '32',
      'Damage:',
      '50-134',
      'Req Level:',
      '36',
      '+150-220% Enhanced Damage',
      '+150 Poison Damage for 8 sec',
      '-60% Requirements',
      '9% chance to cast level 6 Poison Nova when struck',
      '4% chance to cast level 1 Lower Resist',
      '+5 per character level to Attack Rating',
      '+2 to Necromancer Skill Levels',
    ],
    category: 'Exceptional Unique Crossbows',
    id: 115,
    filename: 'pusspitter.gif',
    category_id: 20,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Buriza-Do Kyanon',
    type: 'Ballista',
    image: 'http://www.d2tomb.com/images/items/heavycrossbow.gif',
    props: [
      'Req Dexterity:',
      '80',
      'Req Strength:',
      '110',
      'Damage:',
      '82-412',
      'Req Level:',
      '41',
      '+150-200% Enhanced Damage',
      '+2.5 per character level to maximum Damage',
      'Adds 32-196 Cold Damage for 8 sec',
      'Piercing Attack',
      '+3 Freezes Target',
      '+75-150 Defense',
      '+35 to Dexterity',
      '80% Increased Attack Speed',
    ],
    category: 'Exceptional Unique Crossbows',
    id: 116,
    filename: 'hellcast.gif',
    category_id: 20,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Demon Machine',
    type: 'Chu-Ko-Nu',
    image: 'http://www.d2tomb.com/images/items/repeatingcrossbow.gif',
    props: [
      'Req Dexterity:',
      '95',
      'Req Strength:',
      '80',
      'Damage:',
      '31-137',
      'Req Level:',
      '49',
      '+123% Enhanced Damage',
      '+66 to maximum Damage',
      'Fires level 6 Explosive Bolts',
      '+632 to Attack Rating',
      'Piercing Attack',
      '+321 Defense',
      '+36 to Mana',
    ],
    category: 'Exceptional Unique Crossbows',
    id: 117,
    filename: 'demonmachine.gif',
    category_id: 20,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Hellrack',
    type: 'Colossus Crossbow',
    image: 'http://www.d2tomb.com/images/items/heavycrossbow.gif',
    props: [
      'Req Dexterity:',
      '77',
      'Req Strength:',
      '163',
      'Damage:',
      '89-300',
      'Sockets:',
      '2',
      'Req Level:',
      '76',
      '+180-230% Enhanced Damage',
      '100-150% Bonus to Attack Rating',
      'Adds 63-324 Fire Damage',
      'Adds 63-324 Cold Damage',
      'Adds 63-324 Lightning Damage',
      '+20% Increased Attack Speed',
      '150 Charges Level 18 Immolation Arrow',
    ],
    category: 'Elite Unique Crossbows',
    id: 118,
    filename: 'hellcast.gif',
    category_id: 20,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Gut Siphon',
    type: 'Demon Crossbow',
    image: 'http://www.d2tomb.com/images/items/repeatingcrossbow.gif',
    props: [
      'Req Dexterity:',
      '98',
      'Req Strength:',
      '141',
      'Damage:',
      '67-128',
      'Req Level:',
      '71',
      '+160-220% Enhanced Damage',
      'Piercing Attack',
      '12-18% Life Stolen per Hit',
      '33% Chance of Open Wounds',
      'Slows Target by 25%',
    ],
    category: 'Elite Unique Crossbows',
    id: 119,
    filename: 'gutsiphon.gif',
    category_id: 20,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Gull',
    type: 'Dagger',
    image: 'http://www.d2tomb.com/images/uniques/gull.gif',
    props: [
      'Damage:',
      '2-19',
      'Durability:',
      '16',
      'Req Level:',
      '4',
      '-5 to Mana',
      'Adds 1-15 to Damage',
      '100% better chance of getting magic items',
    ],
    category: 'Unique Daggers',
    id: 120,
    filename: 'gull.gif',
    category_id: 21,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Diggler',
    type: 'Dirk',
    image: 'http://www.d2tomb.com/images/uniques/thediggler.gif',
    props: [
      'Damage:',
      '4-13',
      'Durability:',
      '20',
      'Req Dexterity:',
      '25',
      'Req Level:',
      '11',
      '+50% Enhanced Damage',
      '+25% Cold Resist',
      '+25% Fire Resist',
      '+10 to Dexterity',
      '30% increased attack speed',
      'Ignores Target Defense',
    ],
    category: 'Unique Daggers',
    id: 121,
    filename: 'thediggler.gif',
    category_id: 21,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Jade Tan Do',
    type: 'Kris',
    image: 'http://www.d2tomb.com/images/uniques/thejadetando.gif',
    props: [
      'Damage:',
      '2-11',
      'Durability:',
      '24',
      'Req Dexterity:',
      '45',
      'Req Level:',
      '19',
      '+100-150 to Attack Rating',
      'Cannot be Frozen',
      '+180 Poison Damage for 4 sec',
      '+95% Poison Resist',
      '+20% to maximum Poison Resist',
    ],
    category: 'Unique Daggers',
    id: 122,
    filename: 'thejadetando.gif',
    category_id: 21,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Spectral Shard',
    type: 'Blade',
    image: 'http://www.d2tomb.com/images/uniques/spectralshard.gif',
    props: [
      'Damage:',
      '4-15',
      'Durability:',
      '24',
      'Req Strength:',
      '35',
      'Req Dexterity:',
      '51',
      'Req Level:',
      '25',
      '50% Faster Cast Rate',
      '+10 to All Resistances',
      '+55 to Attack Rating',
      '+50 to Mana',
    ],
    category: 'Unique Daggers',
    id: 123,
    filename: 'spectralshard.gif',
    category_id: 21,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Spineripper',
    type: 'Poignard',
    image: 'http://www.d2tomb.com/images/items/dagger.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '33-88',
      'Durability:',
      '16',
      'Req Level:',
      '32',
      '+200-240% Enhanced Damage',
      'Adds 15-27 Damage',
      '15% Increased Attack Speed',
      '+1 to Necromancer Skill Levels',
      'Prevent Monster Heal',
      'Ignore Targets Defense',
      '8% Life stolen per hit',
      '+10 to Dexterity',
    ],
    category: 'Exceptional Unique Daggers',
    id: 124,
    filename: 'dagger.gif',
    category_id: 21,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Heart Carver',
    type: 'Rondel',
    image: 'http://www.d2tomb.com/images/items/dirk.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '58',
      'Damage:',
      '44-123',
      'Durability:',
      '20',
      'Req Level:',
      '36',
      '+190-240% Enhanced Damage',
      'Adds 15-35 Damage',
      '35% Deadly Strike',
      'Ignores targets defense',
      '+4 to Grim Ward (Barbarian only)',
      '+4 to Find Item (Barbarian only)',
      '+4 to Find Potion (Barbarian only)',
    ],
    category: 'Exceptional Unique Daggers',
    id: 125,
    filename: 'dirk.gif',
    category_id: 21,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Blackbog's Sharp",
    type: 'Cinquedeas',
    image: 'http://www.d2tomb.com/images/items/kriss.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '88',
      'Damage:',
      '30-76',
      'Durability:',
      '24',
      'Req Level:',
      '38',
      '+488 Poison Damage for 10 sec',
      'Adds 15-45 Damage',
      '30% increased Attack Speed',
      'Slows Target by 50%',
      '+50 Defense',
      '+4 to Poison Nova (Necromancer only)',
      '+4 to Poison Explosion (Necromancer only)',
      '+5 to Poison Dagger (Necromancer only)',
    ],
    category: 'Exceptional Unique Daggers',
    id: 126,
    filename: 'kriss.gif',
    category_id: 21,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Stormspike',
    type: 'Stilleto',
    image: 'http://www.d2tomb.com/images/items/blade.gif',
    props: [
      'Req Strength:',
      '47',
      'Req Dexterity:',
      '97',
      'Damage:',
      '47-90',
      'Durability:',
      '24',
      'Req Level:',
      '41',
      '+150% Enhanced Damage',
      'Adds 1-120 Lightning Damage',
      '25% chance to cast level 3 Charged Bolt when struck',
      '+1 per character level to Lightning Resist',
      'Attacker takes Lightning Damage of 20',
    ],
    category: 'Exceptional Unique Daggers',
    id: 127,
    filename: 'stormspike.gif',
    category_id: 21,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Wizardspike',
    type: 'Bone Knife',
    image: 'http://www.d2tomb.com/images/items/dagger.gif',
    props: [
      'Damage:',
      '23-49',
      'Req Strength:',
      '38',
      'Req Dexterity:',
      '75',
      'Req Level:',
      '61',
      '+(2 per character level) to Mana',
      '50% faster Cast Rate',
      '15% Regenerate Mana',
      '15% increased maximum Mana',
      '+75 to all Resistances',
      'Indestructible',
    ],
    category: 'Elite Unique Daggers',
    id: 128,
    filename: 'dagger.gif',
    category_id: 21,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Fleshripper',
    type: 'Fanged Knife',
    image: 'http://www.d2tomb.com/images/items/kriss.gif',
    props: [
      'Req Strength:',
      '42',
      'Req Dexterity:',
      '86',
      'Damage:',
      '45-228',
      'Durability:',
      ' 36',
      'Req Level:',
      '68',
      '+200-300% Enhanced Damage',
      "-50% Target's Defense",
      '25% Chance of Crushing Blow',
      '33% Deadly Strike',
      '50% Chance of Open Wounds',
      'Prevent Monster Heal',
      'Slows Target by 20%',
    ],
    category: 'Elite Unique Daggers',
    id: 129,
    filename: 'kriss.gif',
    category_id: 21,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Ghostflame',
    type: 'Legend Spike',
    image: 'http://www.d2tomb.com/images/items/blade.gif',
    props: [
      'Req Strength:',
      '55',
      'Req Dexterity:',
      '57',
      'Damage:',
      '133-238',
      'Indestructible',
      'Req Level:',
      '66',
      '+190-240% Enhanced Damage',
      "Ignore Target's Defense",
      '+108 Magic Damage',
      '10-15% Mana Stolen per Hit',
      '+2 to Light Radius',
      'Ethereal',
    ],
    category: 'Elite Unique Daggers',
    id: 130,
    filename: 'ghostflame.gif',
    category_id: 21,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Hand of Broc',
    type: 'Leather Gloves',
    image: 'http://www.d2tomb.com/images/uniques/thehandofbroc.gif',
    props: [
      'Defense:',
      '14',
      'Durability:',
      '12',
      'Req Strength:',
      'none',
      'Req Level:',
      '5',
      '+10-20% Enhanced Defense',
      '+10 Defense',
      '3% Life Stolen per hit',
      '3% Mana Stolen per hit',
      '+10% Poison Resist',
      '+20 to Mana',
    ],
    category: 'Unique Gloves',
    id: 131,
    filename: 'thehandofbroc.gif',
    category_id: 15,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bloodfist',
    type: 'Heavy Gloves',
    image: 'http://www.d2tomb.com/images/uniques/bloodfist.gif',
    props: [
      'Defense:',
      '17-18',
      'Durability:',
      '14',
      'Req Strength:',
      'none',
      'Req Level:',
      '9',
      '+10-20% Enhanced Defense',
      '+10 to Defense',
      '10% Increased Attack Speed',
      '30% Faster Hit Recovery',
      '+40 to Life',
      '+5 to min Damage',
    ],
    category: 'Unique Gloves',
    id: 132,
    filename: 'bloodfist.gif',
    category_id: 15,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Chance Guards',
    type: 'Chain Gloves',
    image: 'http://www.d2tomb.com/images/uniques/chanceguards.gif',
    props: [
      'Defense:',
      '27-28',
      'Durability:',
      '16',
      'Req Strength:',
      '25',
      'Req Level:',
      '15',
      '+20-30% Enhanced Defense',
      '+2 to Light Radius',
      '+15 to Defense',
      '+25 to Attack Rating',
      '25-40% better chance of getting Magic Item',
      '200% extra Gold from monsters',
    ],
    category: 'Unique Gloves',
    id: 133,
    filename: 'chanceguards.gif',
    category_id: 15,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Magefist',
    type: 'Light Gauntlets',
    image: 'http://www.d2tomb.com/images/uniques/magefist.gif',
    props: [
      'Defense:',
      '24-25',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '23',
      '+20-30% Enhanced Defense',
      '+10 to Defense',
      '+1 to Fire Skills',
      '20% Faster Cast Rate',
      'Regenerate Mana 25%',
      'Add 1-6 Fire Damage',
    ],
    category: 'Unique Gloves',
    id: 134,
    filename: 'magefist.gif',
    category_id: 15,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Frostburn',
    type: 'Gauntlets',
    image: 'http://www.d2tomb.com/images/uniques/frostburn.gif',
    props: [
      'Defense:',
      '47-49',
      'Durability:',
      '24',
      'Req Strength:',
      '60',
      'Req Level:',
      '29',
      '+10-20% Enhnaced Defense',
      '5% Enhanced Damage',
      'Increased maximum Mana 40%',
      'Adds 1-6 Cold Damage for 2 seconds',
      '+30 Defense',
    ],
    category: 'Unique Gloves',
    id: 135,
    filename: 'frostburn.gif',
    category_id: 15,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Venom Grip',
    type: 'Demonhide Gloves',
    image: 'http://www.d2tomb.com/images/items/leathergloves.gif',
    props: [
      'Defense:',
      '97-118',
      'Durability:',
      '12',
      'Req Strength:',
      '20',
      'Req Level:',
      '29',
      '+130-160% Enhanced Defense',
      '+15-25 Defense',
      '5% chance of Crushing Blow',
      '+60 Poison Damage for 4 sec',
      '5% Life Stolen per hit',
      '5% to maximum Poison Resist',
      '+30% Poison Resist',
    ],
    category: 'Exceptional Unique Gloves',
    id: 136,
    filename: 'leathergloves.gif',
    category_id: 15,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Gravepalm',
    type: 'Sharkskin Gloves',
    image: 'http://www.d2tomb.com/images/items/heavygloves.gif',
    props: [
      'Defense:',
      '96-112',
      'Durability:',
      '14',
      'Req Strength:',
      '20',
      'Req Level:',
      '32',
      '+140-180% Enhanced Defense',
      '+100-200% Damage to Undead',
      '+100-200 to Attack Rating vs. Undead',
      '+10 to Energy and Strength',
    ],
    category: 'Exceptional Unique Gloves',
    id: 137,
    filename: 'heavygloves.gif',
    category_id: 15,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Ghoulhide',
    type: 'Heavy Bracers',
    image: 'http://www.d2tomb.com/images/items/chaingloves.gif',
    props: [
      'Defense:',
      '112-130',
      'Durability:',
      '16',
      'Req Strength:',
      '58',
      'Req Level:',
      '36',
      '+150-190% Enhanced Defense',
      '+8 per character level to Attack Rating vs. Undead',
      '+2 per character level to % Damage vs. Undead',
      '4-5% Mana stolen per hit',
      '+20 to Life',
    ],
    category: 'Exceptional Unique Gloves',
    id: 138,
    filename: 'chaingloves.gif',
    category_id: 15,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Lava Gout',
    type: 'Battle Gauntlets',
    image: 'http://www.d2tomb.com/images/items/lightgauntlets.gif',
    props: [
      'Defense:',
      '120-144',
      'Durability:',
      '38',
      'Req Strength:',
      '88',
      'Req Level:',
      '42',
      '+150-200% Enhanced Defense',
      '2% chance to cast level 10 Enchant on striking',
      'Half Freeze Duration',
      'Adds 13-46 Fire Damage',
      '20% Increased Attack Speed',
      '+24% Fire Resist',
    ],
    category: 'Exceptional Unique Gloves',
    id: 139,
    filename: 'lightgauntlets.gif',
    category_id: 15,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Hellmouth',
    type: 'War Gauntlets',
    image: 'http://www.d2tomb.com/images/items/gauntlets.gif',
    props: [
      'Defense:',
      '135-162',
      'Durability:',
      '39',
      'Req Strength:',
      '110',
      'Req Level:',
      '47',
      '+150-200% Enhanced Defense',
      '4% chance to cast level 12 Firestorm on striking',
      '2% chance to cast level 4 Meteor on striking',
      '+15 Fire Absorb',
      'Adds 15-72 Fire Damage',
    ],
    category: 'Exceptional Unique Gloves',
    id: 140,
    filename: 'gauntlets.gif',
    category_id: 15,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Dracul's Grasp",
    type: 'Vampirebone Gloves',
    image: 'http://www.d2tomb.com/images/items/heavygloves.gif',
    props: [
      'Defense:',
      '125-145',
      'Durability:',
      '14',
      'Req Strength:',
      '50',
      'Req Level:',
      '76',
      '+90-120% Enhanced Defense',
      '+10-15 to Strength',
      '+5-10 Life after each Kill',
      '25% Chance of Open Wounds',
      '7-10% Life Stolen per Hit',
      '5% Chance to cast Level 10 Life Tap on striking',
    ],
    category: 'Elite Unique Gloves',
    id: 141,
    filename: 'draculsgrasp.gif',
    category_id: 15,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Soul Drainer',
    type: 'Vambraces',
    image: 'http://www.d2tomb.com/images/items/chaingloves.gif',
    props: [
      'Defense:',
      '129-149',
      'Durability:',
      '16',
      'Req Strength:',
      '106',
      'Req Level:',
      '74',
      '+90-120% Enhanced Defense',
      '4-7% Mana Stolen per Hit',
      '4-7% Life Stolen per Hit',
      '-50 to Monster Defense',
      '8% Chance to cast Level 3 Weaken on striking',
    ],
    category: 'Elite Unique Gloves',
    id: 142,
    filename: 'souldrainer.gif',
    category_id: 15,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Steelrend',
    type: 'Ogre Gauntlets',
    image: 'http://www.d2tomb.com/images/items/gauntlets.gif',
    props: [
      'Defense:',
      '232-281',
      'Durability:',
      '24',
      'Req Strength:',
      '185',
      'Req Level:',
      '70',
      '+170-210 Defense',
      '+30-60% Enhanced Damage',
      '10% Chance of Crushing Blow',
      '+15-20 to Strength',
    ],
    category: 'Elite Unique Gloves',
    id: 143,
    filename: 'gauntlets.gif',
    category_id: 15,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Biggin's Bonnet",
    type: 'Cap',
    image: 'http://www.d2tomb.com/images/uniques/warbonet.gif',
    props: [
      'Defense:',
      '17-19',
      'Durability:',
      '12',
      'Req Level:',
      '3',
      '+30% Enhanced Damage',
      '+30 to Attack Rating',
      '+15 to Mana',
      '+14 to Defense',
      '+15 to Life',
    ],
    category: 'Unique Helms',
    id: 144,
    filename: 'warbonet.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Tarnhelm',
    type: 'Skull Cap',
    image: 'http://www.d2tomb.com/images/uniques/tarnhelm.gif',
    props: [
      'Defense:',
      '8-11',
      'Durability:',
      '18',
      'Req Strength:',
      '15',
      'Req Level:',
      '15',
      '+1 to all Skill Levels',
      '25-50% chance of getting Magic item',
      '75% extra gold from Monsters',
    ],
    category: 'Unique Helms',
    id: 145,
    filename: 'tarnhelm.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Coif of Glory',
    type: 'Helm',
    image: 'http://www.d2tomb.com/images/uniques/coifofglory.gif',
    props: [
      'Defense:',
      '25-28',
      'Durability:',
      '24',
      'Req Strength:',
      '26',
      'Req Level:',
      '14',
      'Attacker takes lightning damage of 7',
      '+10 to Defense',
      'Hit blinds target',
      'Lightning Resist +15%',
      '+100% Defense against missles',
    ],
    category: 'Unique Helms',
    id: 146,
    filename: 'coifofglory.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Duskdeep',
    type: 'Full Helm',
    image: 'http://www.d2tomb.com/images/uniques/duskdeep.gif',
    props: [
      'Defense:',
      '45-60',
      'Durability:',
      '30',
      'Req Strength:',
      '41',
      'Req Level:',
      '17',
      '+30-50% Enhanced Defense',
      '-2 to Light Radius',
      'Damage reduced by 7',
      '+8 to maximum Damage',
      '+10-20 to Defense',
      '+15 to All Resistances',
    ],
    category: 'Unique Helms',
    id: 147,
    filename: 'duskdeep.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Howltusk',
    type: 'Great Helm',
    image: 'http://www.d2tomb.com/images/uniques/howltusk.gif',
    props: [
      'Defense:',
      '64',
      'Durability:',
      '40',
      'Req Strength:',
      '63',
      'Req Level:',
      '25',
      '35% Damage taken goes to Mana',
      '+80% Enhanced Defense',
      'Knockback',
      '25% Hit causes monster to flee',
      'Magic Damage Reduced by 2',
      'Attacker takes damage of 3',
    ],
    category: 'Unique Helms',
    id: 148,
    filename: 'howltusk.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Face of Horror',
    type: 'Mask',
    image: 'http://www.d2tomb.com/images/uniques/mask.gif',
    props: [
      'Defense:',
      '34-52',
      'Durability:',
      '20',
      'Req Strength:',
      '23',
      'Req Level:',
      '20',
      '+50% damage to Undead',
      '50% Hit causes monsters to flee',
      '+10 to All Resistances',
      '+25 to Defense',
      '+20 to Strength',
    ],
    category: 'Unique Helms',
    id: 149,
    filename: 'mask.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Undead Crown',
    type: 'Crown',
    image: 'http://www.d2tomb.com/images/uniques/undeadcrown.gif',
    props: [
      'Defense:',
      '99-113',
      'Durability:',
      '50',
      'Req Strength:',
      '55',
      'Req Level:',
      '29',
      '+30-60% Enhanced Defense',
      '+50% Damage vs. Undead',
      '+50-100 to Attack Rating vs. Undead',
      '+3 to Skeleton Mastery (Necro only)',
      'Half Freeze Duration',
      '5% Life Stolen per hit',
      '+50% Poison Resist',
      '+40 to Defense',
    ],
    category: 'Unique Helms',
    id: 150,
    filename: 'undeadcrown.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Wormskull',
    type: 'Bone Helm',
    image: 'http://www.d2tomb.com/images/uniques/wormskull.gif',
    props: [
      'Defense:',
      '33-36',
      'Durability:',
      '40',
      'Req Strength:',
      '25',
      'Req Level:',
      '21',
      '+1 to Necromancer skill levels',
      '5% Life stolen per hit',
      '+80 Poison Damage over 8 sec',
      '+25% Poison Resist',
      '+10 to Mana',
    ],
    category: 'Unique Helms',
    id: 151,
    filename: 'wormskull.gif',
    category_id: 12,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Peasant Crown',
    type: 'War Hat',
    image: 'http://www.d2tomb.com/images/items/cap.gif',
    props: [
      'Defense:',
      '108',
      'Durability:',
      '12',
      'Req Strength:',
      '20',
      'Req Level:',
      '28',
      '+100% Enhanced Defense',
      '+1 to all Skills',
      '15% Faster Run/Walk',
      '+6-12 Replenish Life',
      '+20 to Energy and Vitality',
    ],
    category: 'Unique Exceptional Helms',
    id: 152,
    filename: 'cap.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Rockstopper',
    type: 'Sallet',
    image: 'http://www.d2tomb.com/images/items/skullcap.gif',
    props: [
      'Defense:',
      '163-201',
      'Durability:',
      '18',
      'Req Strength:',
      '43',
      'Req Level:',
      '31',
      '+160-220% Enhanced Defense',
      'Damage Reduced by 10%',
      '30% Faster Hit Recovery',
      '+20-40% Cold Resist',
      '+20-50% Fire Resist',
      '+20-40% Lightning Resist',
      '+15 to Vitality',
    ],
    category: 'Unique Exceptional Helms',
    id: 153,
    filename: 'rockstopper.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Stealskull',
    type: 'Casque',
    image: 'http://www.d2tomb.com/images/items/helm.gif',
    props: [
      'Defense:',
      '219-248',
      'Durability:',
      '24',
      'Req Strength:',
      '59',
      'Req Level:',
      '35',
      '+200-240% Enhanced Defense',
      '10% Increased Attack Speed',
      '10% Faster Hit Recovery',
      '5% Life Stolen per hit',
      '5% Mana stolen per hit',
      '30-50% better chance of getting Magic Items',
    ],
    category: 'Unique Exceptional Helms',
    id: 154,
    filename: 'coifofglory.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Darksight Helm',
    type: 'Basinet',
    image: 'http://www.d2tomb.com/images/items/fullhelm.gif',
    props: [
      'Defense:',
      '77-282',
      'Durability:',
      '30',
      'Req Strength:',
      '82',
      'Req Level:',
      '38',
      '+2 per character level to Defense',
      '6% chance to cast level 3 Dim Vision when struck',
      '30 charges Level 5 Cloak of Shadows',
      'Cannot be Frozen',
      '+20-40% Fire Resist',
      '5% Mana Stolen per hit',
      '-4 to Light Radius',
    ],
    category: 'Unique Exceptional Helms',
    id: 155,
    filename: 'darksighthelm.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Valkyrie Wing',
    type: 'Winged Helm',
    image: 'http://www.d2tomb.com/images/items/greathelm.gif',
    props: [
      'Defense:',
      '247-297',
      'Durability:',
      '40',
      'Req Strength:',
      '115',
      'Req Level:',
      '44',
      '+150-200% Enhanced Defense',
      '+1-2 to Amazon Skill Levels',
      '20% Faster Run/Walk',
      '20% Faster Hit Recovery',
      '+2-4 to Mana after each kill',
    ],
    category: 'Unique Exceptional Helms',
    id: 156,
    filename: 'greathelm.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Blackhorn's Face",
    type: 'Death Mask',
    image: 'http://www.d2tomb.com/images/items/mask.gif',
    props: [
      'Defense:',
      '243-278',
      'Durability:',
      '20',
      'Req Strength:',
      '55',
      'Req Level:',
      '41',
      '+180-220% Enhanced Defense',
      'Slows target by 20%',
      'Attacker takes Lightning Damage of 25',
      'Prevent Monster Heal',
      '+20 Lightning Absorb',
      '+15% Lightning Resist',
    ],
    category: 'Unique Exceptional Helms',
    id: 157,
    filename: 'mask.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Crown of Thieves',
    type: 'Grand Crown',
    image: 'http://www.d2tomb.com/images/items/crown.gif',
    props: [
      'Defense:',
      '296-342',
      'Durability:',
      '50',
      'Req Strength:',
      '103',
      'Req Level:',
      '49',
      '+160-200% Enhanced Defense',
      '9-12% Life stolen per Hit',
      '+33% Fire Resist',
      '+35 to Mana',
      '+50 to Life',
      '+25 to Dexterity',
      '80-100% Extra Gold from Monsters',
    ],
    category: 'Unique Exceptional Helms',
    id: 158,
    filename: 'crownofthieves.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Vampire Gaze',
    type: 'Grim Helm',
    image: 'http://www.d2tomb.com/images/items/bonehelm.gif',
    props: [
      'Defense:',
      '252',
      'Durability:',
      '40',
      'Req Strength:',
      '58',
      'Req Level:',
      '41',
      '+100% Enhanced Defense',
      'Adds 6-22 Cold Damage for 4 sec',
      '15% slower Stamina Drain',
      '6-8% Life stolen per hit',
      '6-8% Mana stolen per hit',
      'Damage reduced by 15-20%',
      'Magic Damage reduced by 10-15',
    ],
    category: 'Unique Exceptional Helms',
    id: 159,
    filename: 'vampiregaze.gif',
    category_id: 12,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Harlequin Crest',
    type: 'Shako',
    image: 'http://www.d2tomb.com/images/items/cap.gif',
    props: [
      'Defense:',
      '98-141',
      'Req Strength:',
      '50',
      'Durability:',
      '12',
      'Req Level:',
      '62',
      '+2 to all Skills',
      '+1.5 per character level to Life',
      '+1.5 per character level to Mana',
      'Damage reduced by 10%',
      '+2 to Strength',
      '+2 to Dexterity',
      '+2 to Vitality',
      '+2 to Energy',
      '50% better chance to get Magic Items',
    ],
    category: 'Unique Elite Helms',
    id: 160,
    filename: 'harlequincrest.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Steel Shade',
    type: 'Armet',
    image: 'http://www.d2tomb.com/images/items/helm.gif',
    props: [
      'Defense:',
      '300-345',
      'Durability:',
      '24',
      'Req Strength:',
      '109',
      'Req Level:',
      '62',
      '+100-130% Enhanced Defense',
      '+10-18 Replenish Life',
      '+4-8% Mana Stolen per Hit',
      '+5-11 Fire Absorb',
    ],
    category: 'Unique Elite Helms',
    id: 161,
    filename: 'steelshade.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Veil of Steel',
    type: 'Spired Helm',
    image: 'http://www.d2tomb.com/images/items/greathelm.gif',
    props: [
      'Defense:',
      '396',
      'Req Strength:',
      '192',
      'Durability:',
      '60',
      'Req Level:',
      '73',
      '+60% Enhanced Defense',
      '-4 to Light Radius',
      '+50 to all Resistances',
      '+140 to Defense',
      '+15 to Strength and Vitality',
    ],
    category: 'Unique Elite Helms',
    id: 162,
    filename: 'veilofsteel.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Nightwing's Veil",
    type: 'Spired Helm',
    image: 'http://www.d2tomb.com/images/items/greathelm.gif',
    props: [
      'Defense:',
      '304-352',
      'Req Strength:',
      '96',
      'Durability:',
      '40',
      'Req Level:',
      '67',
      '+90-120% Enhanced Defense',
      '+2 to all Skills',
      '+8-15% to Cold Skill Damage',
      '+10-20 to Dexterity',
      '+5-9 Cold Absorb',
      'Half Freeze Duration',
      '-50% Requirements',
    ],
    category: 'Unique Elite Helms',
    id: 163,
    filename: 'nightwingsveil.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Andariel's Visage",
    type: 'Demonhead Mask',
    image: 'http://www.d2tomb.com/images/items/mask.gif',
    props: [
      'Defense:',
      '310-387',
      'Durability:',
      '20',
      'Req Strength:',
      '102',
      'Req Level:',
      '83',
      '+100-150% Enhanced Defense',
      '+2 to all Skills',
      '20% Increased Attack Speed',
      '8-10% Life Stolen per Hit',
      '+25-30 to Strength',
      '+10% to Maximum Poison Resist',
      '-30% Fire Resist',
      '+70% Poison Resist',
      '15% Chance to cast Level 15 Poison Nova when struck',
      '20 Charges Level 3 Venom',
    ],
    category: 'Unique Elite Helms',
    id: 164,
    filename: 'mask.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Crown of Ages',
    type: 'Corona',
    image: 'http://www.d2tomb.com/images/items/crown.gif',
    props: [
      'Defense:',
      '349-399',
      'Indestructible',
      'Req Strength:',
      '174',
      'Req Level:',
      '82',
      '+50% Enhanced Defense',
      '+100-150 Defense',
      '+1 to all Skills',
      'Damage Reduced by 10-15%',
      '+20-30 to all Resistances',
      '+30% Faster Hit Recovery',
      '1-2 Sockets',
    ],
    category: 'Unique Elite Helms',
    id: 165,
    filename: 'crownofages.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Giant Skull',
    type: 'Bone Visage',
    image: 'http://www.d2tomb.com/images/items/bonehelm.gif',
    props: [
      'Defense:',
      '350-477',
      'Durability:',
      '40',
      'Req Strength:',
      '106',
      'Sockets:',
      '1-2',
      'Req Level:',
      '65',
      '+250-320 Defense',
      '10% Chance of Crushing Blow',
      'Knockback',
      '+25-35 to Strength',
    ],
    category: 'Unique Elite Helms',
    id: 166,
    filename: 'bonehelm.gif',
    category_id: 12,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Kira's Guardian",
    type: 'Tiara',
    image: 'http://www.d2tomb.com/images/items/tiara.gif',
    props: [
      'Defense:',
      '90-170',
      'Durability:',
      '25',
      'Req Level:',
      '77',
      '+50-120 Defense',
      '+20% Faster Hit Recovery',
      'Cannot be Frozen',
      '+50-70 to all Resistances',
    ],
    category: 'Elite Unique Circlets',
    id: 167,
    filename: 'tiara.gif',
    category_id: 11,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Griffon's Eye",
    type: 'Diadem',
    image: 'http://www.d2tomb.com/images/items/diadem.gif',
    props: [
      'Defense:',
      '150-260',
      'Durability:',
      '20',
      'Req Level:',
      '76',
      '+100-200 Defense',
      '+1 to all Skills',
      '+25% Faster Cast Rate',
      '-15-20% to Enemy Lightning Resistance',
      '+10-15% to Lightning Skill Damage',
    ],
    category: 'Elite Unique Circlets',
    id: 168,
    filename: 'diadem.gif',
    category_id: 11,
    quality_id: 2,
    item_type: 'unique',
  },
  // {
  //     name: 'War Treachery',
  //     type: 'Javelin',
  //     image: 'http://www.d2tomb.com/images/items/javelin.gif',
  //     props: [
  //         'Throw Damage:',
  //         '16-38',
  //         'OneHand Damage:',
  //         '2-13',
  //         'Quantity: 57',
  //         'Req Level:',
  //         '2',
  //         '+175% Enhanced Damage',
  //         '-50% Target Defense',
  //         '20% Bonus to Attack Rating',
  //         '+81 to Attack Rating',
  //         '+97 to Attack Rating (based on Character Level)',
  //         'Prevent Monster Heal',
  //         'Increased Stack Size'
  //     ],
  //     category: 'Unique Javelins',
  //     id: 169,
  //     filename: 'javelin.gif',
  //     category_id: 22,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  // {
  //     name: 'Terror Sting',
  //     type: 'Pilum',
  //     image: 'http://www.d2tomb.com/images/items/pilum.gif',
  //     props: [
  //         'Throw Damage:',
  //         '16-46',
  //         'OneHand Damage:',
  //         '9-20',
  //         'Quantity: 50',
  //         'Required Dexterity: 45',
  //         'Required Level:',
  //         '7',
  //         '50% chance to cast level 3 Terror on Striking',
  //         '+40% Increased Attack Speed',
  //         '+130% Enhanced Damage',
  //         'Ignore Target\'s Defense',
  //         'Prevent Monster Heal',
  //         '+8 to Light Radius'
  //     ],
  //     category: 'Unique Javelins',
  //     id: 170,
  //     filename: 'pilum.gif',
  //     category_id: 22,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  // {
  //     name: 'Truth',
  //     type: 'Short Spear',
  //     image: 'http://www.d2tomb.com/images/items/shortspear.gif',
  //     props: [
  //         'Throw Damage:',
  //         '12-27',
  //         'OnHand Damage:',
  //         '2-16',
  //         'Quantity: 40',
  //         'Required Dexterity: 40',
  //         'Required Strength:',
  //         '40',
  //         'Required Level:',
  //         '13',
  //         '+25% Enhanced Damage',
  //         '+12 Damage',
  //         '25% chance of Crushing Blow',
  //         '25% Deadly Strike',
  //         '20% chance of Open Wounds'
  //     ],
  //     category: 'Unique Javelins',
  //     id: 171,
  //     filename: 'shortspear.gif',
  //     category_id: 22,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  {
    name: "Demon's Arch",
    type: 'Balrog Spear',
    image: 'http://www.d2tomb.com/images/items/shortspear.gif',
    props: [
      'Req Strength:',
      '127',
      'Req Dexterity:',
      '95',
      'Throw Damage:',
      '104-192',
      'Damage:',
      '85-195',
      'Req Level:',
      '68',
      'Max Stack:',
      '80',
      '+160-210% Enhanced Damage',
      'Adds 232-323 Fire Damage',
      'Adds 23-333 Lightning Damage',
      '+30% Increased Attack Speed',
      '6-12% Life Stolen per hit',
      'Replenishes Quantity',
    ],
    category: 'Elite Unique Javelins',
    id: 172,
    filename: 'shortspear.gif',
    category_id: 22,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Wraith Flight',
    type: 'Ghost Glaive',
    image: 'http://www.d2tomb.com/images/items/glaive.gif',
    props: [
      'Req Strength:',
      '79',
      'Req Dexterity:',
      '127',
      'Throw Damage:',
      '112-368',
      'Damage:',
      '70-261',
      'Req Level:',
      '76',
      'Max Stack:',
      '75',
      '+150-190% Enhanced Damage',
      '9-13% Life Stolen per Hit',
      '+15 to Mana after each Kill',
      'Replenishes Quantity',
      'Ethereal',
    ],
    category: 'Elite Unique Javelins',
    id: 173,
    filename: 'wraithflight.gif',
    category_id: 22,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Gargoyle's Bite",
    type: 'Winged Harpoon',
    image: 'http://www.d2tomb.com/images/items/throwingspear.gif',
    props: [
      'Req Strength:',
      '76',
      'Req Dexterity:',
      '145',
      'Throw Damage:',
      '30-254',
      'Damage:',
      '75-115',
      'Req Level:',
      '70',
      'Max Stack:',
      '80',
      '+180-230% Enhanced Damage',
      '+293 Poison Damage over 10 seconds',
      '60 Charges Level 11 Plague Javelin',
      '9-15% Life stolen per Hit',
      'Replenishes Quantity',
    ],
    category: 'Elite Unique Javelins',
    id: 174,
    filename: 'throwingspear.gif',
    category_id: 22,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Nokozan Relic',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu2.gif',
    props: [
      'Req Level:',
      '10',
      '+3 to Light Radius',
      '+10% to max Fire Resist',
      '+20% Faster Hit Recovery',
      '+50% Fire Resist',
      'Adds 3-6 Fire Damage<',
    ],
    category: 'Unique Amulets',
    id: 175,
    filename: 'amulet.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Eye of Etlich',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu2.gif',
    props: [
      'Req Level:',
      '15',
      '+1-5 to Light Radius',
      '+1 to Skill Levels',
      '3-7% Life stolen per hit',
      'Adds 1-5 Cold Damage for 2-10 sec',
      '+10-40 Defense vs. Missles',
    ],
    category: 'Unique Amulets',
    id: 176,
    filename: 'amulet.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Mahim-Oak Curio',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu3.gif',
    props: [
      'Req Level:',
      '25',
      '+10 Defense',
      '+10 to Vitality, Dexterity, Energy, and Strength',
      '+10% Enhanced Defense',
      '+10% bonus to Attack Rating',
      '+10 to all Resistances',
    ],
    category: 'Unique Amulets',
    id: 177,
    filename: 'amu3.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Saracen's Chance",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu1.gif',
    props: [
      'Req Level:',
      '47',
      '10% chance to cast level 2 Iron Maiden when struck',
      '+15-25 to All Resistances',
      '+12 to Dexterity, Strength, Vitality, and Energy',
    ],
    category: 'Unique Amulets',
    id: 178,
    filename: 'amu1.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "The Cat's Eye",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu2.gif',
    props: [
      'Req Level:',
      '50',
      '+30% Faster Run/Walk',
      '+100 Defense against missles',
      '+100 Defense',
      '+25 to Dexterity',
      '+20% Increased Attack Speed',
    ],
    category: 'Unique Amulets',
    id: 179,
    filename: 'amulet.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Crescent Moon',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu3.gif',
    props: [
      'Req Level:',
      '50',
      '10% Damage taken goes to Mana',
      '3-6% Life stolen per hit',
      '-2 to Light Radius',
      '11-15% Mana stolen per hit',
      'Magic Damage is reduced by 10',
      '+45 to Mana',
    ],
    category: 'Unique Amulets',
    id: 180,
    filename: 'amu3.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Atma's Scarab",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu2.gif',
    props: [
      'Req Level:',
      '60',
      '5% chance to cast level 2 Amplify Damage on striking',
      '20% bonus to Attack Rating',
      '+3 to Light Radius',
      '+75% Poison Resist',
      'Attacker takes damage of 5',
      '+40 Poison Damage over 4 sec',
    ],
    category: 'Unique Amulets',
    id: 181,
    filename: 'amulet.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Rising Sun',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu3.gif',
    props: [
      'Req Level:',
      '65',
      '2% chance to cast level 13-19 Meteor when struck',
      '+2 to Fire Skills',
      '+10 Replenish Life',
      '+4 to Light Radius',
      'Adds 24-48 Fire Damage',
      'Adds 0.75 per character level to Fire Absorb',
    ],
    category: 'Unique Amulets',
    id: 182,
    filename: 'amu3.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Highlord's Wrath",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu3.gif',
    props: [
      'Req Level:',
      '65',
      '+20% Increased Attack Speed',
      '+1 to all Skill Levels',
      '+35% Lightning Resist',
      'Adds 1-30 Lightning Damage',
      '+0.375 per character level to Deadly Strike',
      'Attacker takes Lightning damage of 15',
    ],
    category: 'Unique Amulets',
    id: 183,
    filename: 'amu3.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Mara's Kaleidoscope",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu1.gif',
    props: [
      'Req Level:',
      '67',
      '+2 to all Skill Levels',
      '+20-30 to all Resistances',
      '+5 to Energy',
      '+5 to Vitality',
      '+5 to Dexterity',
      '+5 to Strength',
    ],
    category: 'Unique Amulets',
    id: 184,
    filename: 'amu1.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Seraph's Hymn",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu1.gif',
    props: [
      'Req Level:',
      '65',
      '+2 to all Skill',
      '+1-2 to Defense Auras (Paladin only)',
      '+20-50% Damage to Demons',
      '+150-250 to Attack Rating vs. Demons',
      '+20-50% Damage to Undead',
      '+150-250 to Attack Rating vs. Undead',
      '+2 to Light Radius',
    ],
    category: 'Unique Amulets',
    id: 185,
    filename: 'amu1.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Metalgrid',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/amu1.gif',
    props: [
      'Req Level:',
      '81',
      '+400-450 to Attack Rating',
      '11 Charges Level 22 Iron Golem',
      '20 Charges Level 12 Iron Maiden',
      '+25-35 to all Resistances',
      '+300-350 Defense',
    ],
    category: 'Unique Amulets',
    id: 186,
    filename: 'amu1.gif',
    category_id: 8,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Nagelring',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring4.gif',
    props: [
      'Req Level:',
      '7',
      'Magic Damage reduced by 3',
      '+50-75 to Attack Rating',
      'Attacker takes 3 Damage',
      '15-30% better chance of getting magic item',
    ],
    category: 'Unique Rings',
    id: 187,
    filename: 'ring4.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Manald Heal',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring4.gif',
    props: ['Req Level:', '15', '4-7% Mana stolen per hit', '+5-8 Replenish Life', '20% Regenerate Mana', '+20 to Life'],
    category: 'Unique Rings',
    id: 188,
    filename: 'ring4.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Stone of Jordan',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring4.gif',
    props: ['Req Level:', '29', '+1 to all Skill levels', 'Increase max Mana 25%', 'Adds 1-12 Lightning damage', '+20 to Mana'],
    category: 'Unique Rings',
    id: 189,
    filename: 'ring4.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Dwarf Star',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring2.gif',
    props: [
      'Req Level:',
      '45',
      'Magic Damage reduced by 12-15',
      '+15% heal Stamina',
      '+40 to maximum Stamina',
      '+40 to Life',
      '15% Fire Absorb',
      '100% extra gold from Monsters',
    ],
    category: 'Unique Rings',
    id: 190,
    filename: 'ring2.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Raven Frost',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring3.gif',
    props: [
      'Req Level:',
      '45',
      '+150-250 to Attack Rating',
      '+15-20 to Dexterity',
      '+40 to Mana',
      '20% Cold Absorb',
      'Cannot be frozen',
      'Adds 15-45 Cold Damage for 4 sec',
    ],
    category: 'Unique Rings',
    id: 191,
    filename: 'ring3.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Bul-Kathos' Wedding Band",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring1.gif',
    props: [
      'Req Level:',
      '58',
      '+1 to all Skill Levels',
      '3-5% Life stolen per hit',
      '+50 to maximum Stamina',
      '+0.5 per character level to Life',
    ],
    category: 'Unique Rings',
    id: 192,
    filename: 'ring1.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Carrion Wind',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring3.gif',
    props: [
      'Req Level:',
      '60',
      '10% Chance to Cast Level 10 Poison Nova when struck',
      '8% Chance to Cast Level 13 Twister on striking',
      '6-9% Life Stolen per Hit',
      '+100-160 Defense vs. Missile',
      '+55% Poison Resist',
      '10% Damage taken goes to Mana',
      '15 Charges Level 21 Poison Creeper',
    ],
    category: 'Unique Rings',
    id: 193,
    filename: 'ring3.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Nature's Peace",
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring3.gif',
    props: [
      'Req Level:',
      '69',
      'Slain Monsters Rest in Peace',
      'Prevent Monster Heal',
      '+20-30% Poison Resist',
      'Damage Reduced by 7-11',
      '27 Charges Level 5 Oak Sage',
    ],
    category: 'Unique Rings',
    id: 194,
    filename: 'ring3.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Wisp Projector',
    type: '',
    image: 'http://www.d2tomb.com/images/uniques/ring3.gif',
    props: [
      'Req Level:',
      '76',
      '10% Chance to Cast Level 16 Lightning Bolt on striking',
      '10-20% Lightning Absorb',
      '10-20% better chance for getting Magic Items',
      '11 Charges Level 7 Spirit of Barbs',
      '13 Charges Level 5 Heart of Wolverine',
      '15 Charges Level 2 Oak Sage',
    ],
    category: 'Unique Rings',
    id: 195,
    filename: 'ring3.gif',
    category_id: 7,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Felloak',
    type: 'Club',
    image: 'http://www.d2tomb.com/images/uniques/felloak.gif',
    props: [
      'Damage:',
      '1-10',
      'Durability:',
      '24',
      'Req Level:',
      '3',
      '+70-80% Enhanced Damage',
      '+60% Lightning Resist',
      '+20% Fire Resist',
      'Adds 6-8 Fire Damage',
      'Knockback',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Maces',
    id: 196,
    filename: 'felloak.gif',
    category_id: 23,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Stoutnail',
    type: 'Spiked Club',
    image: 'http://www.d2tomb.com/images/uniques/stoutnail.gif',
    props: [
      'Damage:',
      '10-16',
      'Durability:',
      '36',
      'Req Level:',
      '5',
      '+100% Enhanced Damage',
      '+7 to Vitality',
      'Magic Damage reduced by 2',
      'Attacker takes Damage of 3-10',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Maces',
    id: 197,
    filename: 'stoutnail.gif',
    category_id: 23,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Crushflange',
    type: 'Mace',
    image: 'http://www.d2tomb.com/images/uniques/crushflange.gif',
    props: [
      'Damage:',
      '4-16',
      'Durability:',
      '60',
      'Req Strength:',
      '27',
      'Req Level:',
      '9',
      '+50-60% Enhanced Damage',
      '33% chance of Crushing Blow',
      '+2 to Light radius',
      '+50% Fire Resist',
      '+15 to Strength',
      'Knockback',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Maces',
    id: 198,
    filename: 'crushflange.gif',
    category_id: 23,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bloodrise',
    type: 'Morning Star',
    image: 'http://www.d2tomb.com/images/uniques/bloodrise.gif',
    props: [
      'Damage:',
      '15-35',
      'Durability:',
      '72',
      'Req Strength:',
      '36',
      'Req Level:',
      '15',
      '+120% Enhanced Damage',
      '25% chance of Open Wounds',
      '10% Increased Attack Speed',
      '5% Life Stolen per hit',
      '50% bonus to Attack Rating',
      '+2 to Light Radius',
      '+3 to Sacrifice (Paladin only)',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Maces',
    id: 199,
    filename: 'bloodrise.gif',
    category_id: 23,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "The General's Tan Do Li Ga",
    type: 'Flail',
    image: 'http://www.d2tomb.com/images/uniques/thegeneralstandoliga.gif',
    props: [
      'Damage:',
      '2-58',
      'Durability:',
      '30',
      'Req Strength:',
      '41',
      'Req Dexterity:',
      '35',
      'Req Level:',
      '21',
      '+50-60% Enhanced Damage',
      '20% Increased Attack Speed',
      'Slows Target by 50%',
      '5% Mana stolen per hit',
      '+25 Defense',
      'Adds 1-20 Damage',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Maces',
    id: 200,
    filename: 'thegeneralstandoliga.gif',
    category_id: 23,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Ironstone',
    type: 'War Hammer',
    image: 'http://www.d2tomb.com/images/uniques/ironstone.gif',
    props: [
      'Damage:',
      '38-72',
      'Durability:',
      '55',
      'Req Strength:',
      '53',
      'Req Level:',
      '27',
      '+100-150% Enhanced Damage',
      '+100-150 to Attack Rating',
      '+10 to Strength',
      'Adds 1-10 Lightning Damage',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Hammers',
    id: 201,
    filename: 'ironstone.gif',
    category_id: 31,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bonesnap',
    type: 'Maul',
    image: 'http://www.d2tomb.com/images/uniques/bonesnap.gif',
    props: [
      'Damage:',
      '90-172',
      'Durabilty: 60',
      'Req Strength:',
      '69',
      'Req Level:',
      '24',
      '+200-300% Enhanced Damage',
      '+100% Damage to Undead',
      '40% chance of Crushing Blow',
      '30% Cold Resist',
      '30% Fire Resist',
    ],
    category: 'Unique Hammers',
    id: 202,
    filename: 'bonesnap.gif',
    category_id: 31,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Steeldriver',
    type: 'Great Maul',
    image: 'http://www.d2tomb.com/images/uniques/steeldriver.gif',
    props: [
      'Damage:',
      '95-203',
      'Durability:',
      '60',
      'Req Strength:',
      '50',
      'Req Level:',
      '29',
      '+150-250% Enhanced Damage',
      '40% increased Attack Speed',
      'Requirements -50%',
      'Heal Stamina +25%',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Hammers',
    id: 203,
    filename: 'steeldriver.gif',
    category_id: 31,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Dark Clan Crusher',
    type: 'Cudgel',
    image: 'http://www.d2tomb.com/images/items/club.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '17-61',
      'Durability:',
      '24',
      'Req Level:',
      '34',
      '+195% Enhanced Damage',
      '+200% Damage to Demons',
      '+20-25% bonus to Attack Rating',
      '+15 to Life after each demon kill',
      '+2 to Druid Skill Levels',
      '+50% Damage to Undead',
      '+200 to Attack Rating against Demons',
    ],
    category: 'Exceptional Unique Maces',
    id: 204,
    filename: 'club.gif',
    category_id: 23,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Fleshrender',
    type: 'Barbed Club',
    image: 'http://www.d2tomb.com/images/items/spikedclub.gif',
    props: [
      'Req Strength:',
      '30',
      'Damage:',
      '64-125',
      'Durability:',
      '56',
      'Req Level:',
      '38',
      '+130-200% Enhanced Damage',
      'Adds 35-50 Damage',
      '+50% Damage to Undead',
      '20% Deadly Strike',
      '20% chance of Crushing Blow',
      '25% chance of Open Wounds',
      '+1 to Druid Skills',
      '+2 to Shape Shifting Skills (Druid only)',
      'Prevent Monster Heal',
    ],
    category: 'Exceptional Unique Maces',
    id: 205,
    filename: 'stoutnail.gif',
    category_id: 23,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Sureshrill Frost',
    type: 'Flanged Mace',
    image: 'http://www.d2tomb.com/images/items/mace.gif',
    props: [
      'Req Strength:',
      '61',
      'Damage:',
      '42-74',
      'Durability:',
      '60',
      'Req Level:',
      '39',
      '+150-180% Enhanced Damage',
      'Adds 5-10 Damage',
      '+50% Damage to Undead',
      'Adds 63-112 Cold Damage for 5 sec',
      '+3 Freezes Target',
      '50 charges Level 9 Frozen Orb',
      'Cannot be frozen',
    ],
    category: 'Exceptional Unique Maces',
    id: 206,
    filename: 'crushflange.gif',
    category_id: 23,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Moonfall',
    type: 'Jagged Star',
    image: 'http://www.d2tomb.com/images/items/morningstar.gif',
    props: [
      'Req Strength:',
      '74',
      'Damage:',
      '54-92',
      'Durability:',
      '72',
      'Req Level:',
      '42',
      '+120-150% Enhanced Damage',
      'Adds 10-15 Damage',
      '+50% Damage to undead',
      'Adds 55-115 Fire Damage',
      '+2 to Light Radius',
      '5% chance to cast level 6 Meteor on striking',
      '60 charges Level 11 Meteor',
      'Magic damage reduced by 9-12',
    ],
    category: 'Exceptional Unique Maces',
    id: 207,
    filename: 'morningstar.gif',
    category_id: 23,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Baezil's Vortex",
    type: 'Knout',
    image: 'http://www.d2tomb.com/images/items/flail.gif',
    props: [
      'Req Strength:',
      '82',
      'Req Dexterity:',
      '73',
      'Damage:',
      '33-105',
      'Durability:',
      '30',
      'Req Level:',
      '45',
      '+160-200% Enhanced Damage',
      '+50% Damage to Undead',
      'Adds 1-150 Lightning Damage',
      '20% Increased Attack Speed',
      '5% chance to cast level 8 Nova on striking',
      '80 charges level 15 Nova',
      '25% Lightning Resist',
      '+100 to Mana',
    ],
    category: 'Exceptional Unique Maces',
    id: 208,
    filename: 'flail.gif',
    category_id: 23,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Earthshaker',
    type: 'Battle Hammer',
    image: 'http://www.d2tomb.com/images/items/warhammer.gif',
    props: [
      'Req Strength:',
      '100',
      'Damage:',
      '98-162',
      'Durability:',
      '105',
      'Req Level:',
      '43',
      '+180% Enhanced Damage',
      '+50% Damage to Undead',
      '5% chance to cast level 7 Fissure on striking',
      '30% Increase Attack Speed',
      'Hit Blinds Target',
      'Knockback',
      '+3 to Elemental Skills (Druid only)',
    ],
    category: 'Exceptional Unique Hammers',
    id: 209,
    filename: 'warhammer.gif',
    category_id: 31,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Bloodtree Stump',
    type: 'War Club',
    image: 'http://www.d2tomb.com/images/items/maul.gif',
    props: [
      'Req Strength:',
      '124',
      'Damage:',
      '148-249',
      'Durability:',
      '100',
      'Req Level:',
      '48',
      '+180-220% Enhanced Damage',
      '+50% Damage to Undead',
      '50% chance of Crushing Blow',
      '+20 to all Resistances',
      '+25 to Strength',
      '+2 to Masteries (Barbarian only)',
      '+3 to Mace Masteries (Barbarian only)',
    ],
    category: 'Exceptional Unique Hammers',
    id: 210,
    filename: 'maul.gif',
    category_id: 31,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Gavel of Pain',
    type: 'Martel de Fer',
    image: 'http://www.d2tomb.com/images/items/greatmaul.gif',
    props: [
      'Req Strength:',
      '169',
      'Damage:',
      '152-286',
      'Indestructible',
      'Req Level:',
      '45',
      '+130-160% Enhanced Damage',
      'Adds 12-30 Damage',
      '+50% Damage to Undead',
      '5% chance to cast level 1 Iron Maiden when struck',
      '5% chance to cast level 1 Amplify Damage on striking',
      '3 charges level 8 Amplify Damage',
      'Attacker takes Damage of 26',
    ],
    category: 'Exceptional Unique Hammers',
    id: 211,
    filename: 'gavelofpain.gif',
    category_id: 31,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Nord's Tenderizer",
    type: 'Truncheon',
    image: 'http://www.d2tomb.com/images/items/club.gif',
    props: [
      'Req Strength:',
      '88',
      'Req Dexterity:',
      '43',
      'Damage:',
      '129-184',
      'Durability:',
      '55',
      'Req Level:',
      '68',
      '1-Handed',
      '+270-330% Enhanced Damage',
      '+50% Damage to Undead',
      'Adds 205-455 Cold Damage for 5 sec',
      '+2-4 Freezes Target',
      '5-15% Cold Absorb',
      '+25% Increased Attack Speed',
      '12 Charges Level 16 Blizzard',
      '+150-180% Bonus to Attack Rating',
    ],
    category: 'Elite Unique Maces',
    id: 212,
    filename: 'club.gif',
    category_id: 23,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Demon Limb',
    type: 'Tyrant Club',
    image: 'http://www.d2tomb.com/images/items/spikedclub.gif',
    props: [
      'Req Strength:',
      '133',
      'Damage:',
      '89-191',
      'Durability:',
      '65',
      'Req Level:',
      '63',
      '1-Handed',
      '+180-230% Enhanced Damage',
      '+50% Damage to Undead',
      '+123% Damage to Demons',
      'Adds 222-333 Fire Damage',
      '7-13% Life Stolen per Hit',
      '+15-20% Fire Resist',
      '20 Charges Level 23 Enchant',
      'Repairs 1 Durability in 20 seconds',
    ],
    category: 'Elite Unique Maces',
    id: 213,
    filename: 'stoutnail.gif',
    category_id: 23,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Baranar's Star",
    type: 'Devil Star',
    image: 'http://www.d2tomb.com/images/uniques/bloodrise.gif',
    props: [
      'Damage:',
      '129-159',
      'Durability:',
      '172',
      'Req Strength:',
      '153',
      'Req Dexterity:',
      '44',
      'Req Level:',
      '65',
      '+200% Enhanced Damage',
      '+50% Damage to Undead',
      '50% increased Attack Speed',
      '200% bonus to Attack Rating',
      'Adds 1-200 Fire Damage',
      'Adds 1-200 Lightning Damage',
      'Adds 1-200 Cold Damage',
      '+15 to Dexterity and Strength',
    ],
    category: 'Elite Unique Maces',
    id: 214,
    filename: 'bloodrise.gif',
    category_id: 23,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Horizon's Tornado",
    type: 'Scourge',
    image: 'http://www.d2tomb.com/images/items/flail.gif',
    props: [
      'Req Strength:',
      '100',
      'Req Dexterity:',
      '62',
      'Damage:',
      '9-304',
      'Durability:',
      '65',
      'Req Level:',
      '64',
      '1-Handed',
      '+230-280% Enhanced Damage',
      '+50% Damage to Undead',
      '+50% Increased Attack Speed',
      'Slows Target by 20%',
      '20% Chance to cast Level 15 Tornado on striking',
      '-20% Requirements',
    ],
    category: 'Elite Unique Maces',
    id: 215,
    filename: 'flail.gif',
    category_id: 23,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Stormlash',
    type: 'Scourge',
    image: 'http://www.d2tomb.com/images/items/flail.gif',
    props: [
      'Req Strength:',
      '125',
      'Req Dexterity:',
      '77',
      'Damage:',
      '10-320',
      'Durability:',
      '65',
      'Req Level:',
      '82',
      '1-Handed',
      '+240-300% Enhanced Damage',
      '+50% Damage to Undead',
      'Adds 1-473 Lightning Damage',
      '+30% Increased Attack Speed',
      '+33% Chance of Crushing Blow',
      '15% chance to cast level 10 Static Field on striking',
      '+3-9 Lightning Absorb',
      'Attacker takes Lightning Damage of 30',
      '20% chance to cast level 18 Tornado on striking',
    ],
    category: 'Elite Unique Maces',
    id: 216,
    filename: 'flail.gif',
    category_id: 23,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Schaefer's Hammer",
    type: 'Legendary Mallet',
    image: 'http://www.d2tomb.com/images/uniques/ironstone.gif',
    props: [
      'Damage:',
      '100-338',
      'Req Strength:',
      '189',
      'Indestructible',
      'Req Level:',
      '79',
      '+100-130% Enhanced Damage',
      '+2 per character level to maximum Damage',
      '+50% Damage to Undead',
      '20% chance to cast level 10 Static Field on striking',
      '20% increased Attack Speed',
      '+(8 per character level) to Attack Rating',
      '+75% Lightning Resist',
      'Adds 50-200 Lightning Damage',
      '+1 to Light Radius',
      '+50 to Life',
    ],
    category: 'Elite Unique Hammers',
    id: 217,
    filename: 'ironstone.gif',
    category_id: 31,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Stone Crusher',
    type: 'Legendary Mallet',
    image: 'http://www.d2tomb.com/images/uniques/ironstone.gif',
    props: [
      'Req Strength:',
      '189',
      'Damage:',
      '190-256',
      'Durability:',
      '65',
      'Req Level:',
      '68',
      '1-Handed',
      '+280-320% Enhanced Damage',
      '+10-30 Damage',
      '+50% Damage to Undead',
      '-25% Targets Defense',
      '40% Chance of Crushing Blow',
      '-100 to Monster Defense per Hit',
      '+20-30 to Strength',
    ],
    category: 'Elite Unique Hammers',
    id: 218,
    filename: 'ironstone.gif',
    category_id: 31,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Windhammer',
    type: 'Ogre Maul',
    image: 'http://www.d2tomb.com/images/items/maul.gif',
    props: [
      'Req Strength:',
      '225',
      'Damage:',
      '215-349',
      'Durability:',
      '60',
      'Req Level:',
      '68',
      '2-Handed',
      '+180-230% Enhanced Damage',
      '50% Chance of Crushing Blow',
      '60% Increased Attack Speed',
      '33% Chance to cast Level 22 Twister on striking',
      '+50% Damage to Undead',
    ],
    category: 'Elite Unique Hammers',
    id: 219,
    filename: 'maul.gif',
    category_id: 31,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Cranium Basher',
    type: 'Thunder Maul',
    image: 'http://www.d2tomb.com/images/uniques/steeldriver.gif',
    props: [
      'Damage:',
      '119-632',
      'Indestructible',
      'Req Strength:',
      '253',
      'Req Level:',
      '87',
      '+200-240% Enhanced Damage',
      '+20 to min and max Damage',
      '+50% Damage to Undead',
      '75% Chance of Crushing Blow',
      '4% chance to cast level 4 Amplify Damage on striking',
      '20% increased Attack Speed',
      '+25 to all Resistances',
      '+25 to Strength',
    ],
    category: 'Elite Unique Hammers',
    id: 220,
    filename: 'steeldriver.gif',
    category_id: 31,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Earth Shifter',
    type: 'Thunder Maul',
    image: 'http://www.d2tomb.com/images/uniques/steeldriver.gif',
    props: [
      'Req Strength:',
      '253',
      'Damage:',
      '115-720',
      'Durability:',
      '60',
      'Req Level:',
      '69',
      '2-Handed',
      '+250-300% Enhanced Damage',
      '+50% Damage to Undead',
      '+7 to Elemental Skills (Druid only)',
      '25% Chance to cast Level 14 Fissure on striking',
      '10% Increased Attack Speed',
      '33% Chance of Crushing Blow',
      '30 Charges Level 14 Volcano',
      '10% Faster Cast Rate',
    ],
    category: 'Elite Unique Hammers',
    id: 221,
    filename: 'steeldriver.gif',
    category_id: 31,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Dimoak's Hew",
    type: 'Bardiche',
    image: 'http://www.d2tomb.com/images/uniques/dimoakshew.gif',
    props: [
      'Damage:',
      '2-54',
      'Durability:',
      ' 50',
      'Req Dexterity:',
      '0',
      'Req Strength:',
      '40',
      'Req Level:',
      '8',
      '+100% Enhanced Damage',
      '-8 Defense',
      '+15 Dexterity',
      '+20% Increased Attack Speed',
    ],
    category: 'Unique Polearms',
    id: 222,
    filename: 'dimoakshew.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Steelgoad',
    type: 'Voulge',
    image: 'http://www.d2tomb.com/images/uniques/steelgoad.gif',
    props: [
      'Damage:',
      '9-37',
      'Durability:',
      '70-90',
      'Req Dexterity:',
      '0',
      'Req Strength:',
      '50',
      'Req Level:',
      '14',
      '+60-80% Enhanced Damage',
      '30% Deadly Strike',
      '75% Hit causes monster to flee',
      '+5 to All Resistances',
      '+30 to Attack Rating',
    ],
    category: 'Unique Polearms',
    id: 223,
    filename: 'steelgoad.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Soul Harvest',
    type: 'Scythe',
    image: 'http://www.d2tomb.com/images/uniques/soulharvest.gif',
    props: [
      'Damage:',
      '12-38',
      'Durability:',
      '65',
      'Req Dexterity:',
      '41',
      'Req Strength:',
      '41',
      'Req Level:',
      '19',
      '+50-90% Enhanced Damage',
      '30% Chance of open wounds',
      '10% Mana Stolen per hit',
      '+20 to All Resistances',
      '+5 to Energy',
      '+45 to Attack Rating',
    ],
    category: 'Unique Polearms',
    id: 224,
    filename: 'soulharvest.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Battlebranch',
    type: 'Poleaxe',
    image: 'http://www.d2tomb.com/images/uniques/battlebranch.gif',
    props: [
      'Damage:',
      '27-66',
      'Durability:',
      '65',
      'Req Dexterity:',
      '0',
      'Req Strength:',
      '62',
      'Req Level:',
      '25',
      '+50-70% Enhanced Damage',
      '7% Life Stolen per hit',
      '+50-100 to Attack Rating',
      '+10 to Dexterity',
      '+30% increased Attack Speed',
    ],
    category: 'Unique Polearms',
    id: 225,
    filename: 'battlebranch.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Woestave',
    type: 'Halberd',
    image: 'http://www.d2tomb.com/images/uniques/woestave.gif',
    props: [
      'Damage:',
      '14-62',
      'Durability:',
      '55',
      'Req Dexterity:',
      '47',
      'Req Strength:',
      '75',
      'Req Level:',
      '28',
      'Slows Target by 50%',
      '+20-40% Enhanced Damage',
      '50% chance of Open Wounds',
      '+3 Hit Blinds Target',
      '-50 to Monster defense',
      'Hit Freezes target',
      'Prevent Monster Heal',
      '-3 to Light Radius',
    ],
    category: 'Unique Polearms',
    id: 226,
    filename: 'woestave.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Grim Reaper',
    type: 'War Scythe',
    image: 'http://www.d2tomb.com/images/uniques/thegrimreaper.gif',
    props: [
      'Damage:',
      '33-43',
      'Durability:',
      '55',
      'Req Dexterity:',
      '80',
      'Req Strength:',
      '80',
      'Req Level:',
      '29',
      '100% Deadly Strike',
      'Prevent Monster Heal',
      '5% Mana stolen per hit',
      '+20% Enhanced Damage',
      '+15 to minimum Damage',
    ],
    category: 'Unique Polearms',
    id: 227,
    filename: 'thegrimreaper.gif',
    category_id: 24,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Meat Scraper',
    type: 'Lochaber Axe',
    image: 'http://www.d2tomb.com/images/items/bardiche.gif',
    props: [
      'Req Strength:',
      '80',
      'Req Dexterity:',
      '0',
      'Damage:',
      '15-174',
      'Durability:',
      '50',
      'Req Level:',
      '41',
      '+150-200% Enhanced Damage',
      '50% chance of Open Wounds',
      '30% increased Attack Speed',
      '10% Life Stolen per hit',
      '25% better chance of getting Magic items',
      '+3 to Masteries (Barbarian only)',
    ],
    category: 'Exceptional Unique Polearms',
    id: 228,
    filename: 'themeatscraper.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Blackleach Blade',
    type: 'Bill',
    image: 'http://www.d2tomb.com/images/items/voulge.gif',
    props: [
      'Req Strength:',
      '72',
      'Req Dexterity:',
      '0',
      'Damage:',
      '28-250',
      'Durability:',
      '50',
      'Req Level:',
      '42',
      '+100-140% Enhanced Damage',
      '+1.25 per character level to maximum Damage',
      '5% chance to cast level 5 Weaken on striking',
      '-25% Requirements',
      '-2 to Light Radius',
      '8% Life Stolen per hit',
    ],
    category: 'Exceptional Unique Polearms',
    id: 229,
    filename: 'voulge.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Athena's Wrath",
    type: 'Battle Scythe',
    image: 'http://www.d2tomb.com/images/items/scythe.gif',
    props: [
      'Req Strength:',
      '82',
      'Req Dexterity:',
      '82',
      'Damage:',
      '45-224',
      'Durability:',
      '65',
      'Req Level:',
      '42',
      '+150-180% Enhanced Damage',
      '+1 per character level to maximum Damage',
      '+1 per character level to Life',
      '30% Increased Attack Speed',
      '+1-3 to Druid Skill levels',
      '+15 to Dexterity',
    ],
    category: 'Exceptional Unique Polearms',
    id: 230,
    filename: 'athenaswrath.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Pierre Tombale Couant',
    type: 'Partizan',
    image: 'http://www.d2tomb.com/images/items/poleaxe.gif',
    props: [
      'Req Strength:',
      '113',
      'Req Dexterity:',
      '67',
      'Damage:',
      '100-260',
      'Durability:',
      '65',
      'Req Level:',
      '43',
      '+160-220% Enhanced Damage',
      'Adds 12-20 Damage',
      '55% Deadly Strike',
      '+100-200 to Attack Rating',
      '+3 to Barbarian Skill Levels',
      '6% Mana stolen per hit',
      '30% Faster Hit Recovery',
    ],
    category: 'Exceptional Unique Polearms',
    id: 231,
    filename: 'poleaxe.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Husoldal Evo',
    type: 'Bec-De-Corbin',
    image: 'http://www.d2tomb.com/images/items/halberd.gif',
    props: [
      'Req Strength:',
      '133',
      'Req Dexterity:',
      '91',
      'Damage:',
      '53-287',
      'Durability:',
      '55',
      'Req Level:',
      '44',
      '+160-200% Enhanced Damage',
      'Adds 20-32 Damage',
      '20% Increased Attack Speed',
      '+200-250 to Attack Rating',
      'Prevent Monster Heal',
      '+20 Replenish Life',
    ],
    category: 'Exceptional Unique Polearms',
    id: 232,
    filename: 'halberd.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Grim's Burning Dead",
    type: 'Grim Scythe',
    image: 'http://www.d2tomb.com/images/items/warscythe.gif',
    props: [
      'Req Strength:',
      '70',
      'Req Dexterity:',
      '70',
      'Damage:',
      '72-196',
      'Durability:',
      '55',
      'Req Level:',
      '45',
      '+140-180% Enhanced Damage',
      'Adds 131-232 Fire Damage',
      '+3 to Necromancer Skill Levels',
      '-50% Target Defense',
      '+20% Enhanced Defense',
      '+200-250 to Attack Rating',
      '+45% Fire Resist',
      '-50% Requirements',
      'Attacker takes Damage of 8',
    ],
    category: 'Exceptional Unique Polearms',
    id: 233,
    filename: 'warscythe.gif',
    category_id: 24,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Bonehew',
    type: 'Ogre Axe',
    image: 'http://www.d2tomb.com/images/items/bardiche.gif',
    props: [
      'Req Strength:',
      '195',
      'Req Dexterity:',
      '75',
      'Damage:',
      '103-609',
      'Durability:',
      '50',
      'Sockets:',
      '2',
      'Req Level:',
      '64',
      '+270-320% Enhanced Damage',
      '30 Charges Level 14 Corpse Explosion',
      'Prevent Monster Heal',
      '30% Increased Attack Speed',
      '50% Chance to Cast Level 16 Bone Spear on striking',
    ],
    category: 'Elite Unique Polearms',
    id: 234,
    filename: 'bonehew.gif',
    category_id: 24,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "The Reaper's Toll",
    type: 'Thresher',
    image: 'http://www.d2tomb.com/images/items/scythe.gif',
    props: [
      'Req Strength:',
      '114',
      'Req Dexterity:',
      '89',
      'Damage:',
      '34-479',
      'Durability:',
      ' 65',
      'Req Level:',
      '75',
      '+190-240% Enhanced Damage',
      "Ignores Target's Defense",
      'Adds 4-44 Cold Damage',
      '11-15% Life Stolen per Hit',
      '33% Deadly Strike',
      '33% Chance to cast Level 1 Decrepify on striking',
      '-25% Requirements',
    ],
    category: 'Elite Unique Polearms',
    id: 235,
    filename: 'scythe.gif',
    category_id: 24,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Tomb Reaver',
    type: 'Cryptic Axe',
    image: 'http://www.d2tomb.com/images/items/poleaxe.gif',
    props: [
      'Req Strength:',
      '165',
      'Req Dexterity:',
      '103',
      'Damage:',
      '99-570',
      'Durability:',
      '65',
      'Sockets:',
      '1-3',
      'Req Level:',
      '84',
      '+200-280% Enhanced Damage',
      '+150-230% Damage to Undead',
      '+60% Increased Attack Speed',
      '+250-350% to Attack Rating Against Undead',
      '+30-50 to all Resistances',
      '10% Chance to reanimate a corpse as Undead',
      '+10-14 Life after each Kill',
      '+50-80% Better Chance to getting Magic Items',
      '+4 to Light Radius',
    ],
    category: 'Elite Unique Polearms',
    id: 236,
    filename: 'poleaxe.gif',
    category_id: 24,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Stormspire',
    type: 'Giant Thresher',
    image: 'http://www.d2tomb.com/images/items/warscythe.gif',
    props: [
      'Req Strength:',
      '188',
      'Req Dexterity:',
      '140',
      'Damage:',
      '100-399',
      'Indestructible',
      'Req Level:',
      '70',
      '+150-250% Enhanced Damage',
      'Adds 1-237 Lightning Damage',
      '30% Increased Attack Speed',
      '+50% Lightning Resist',
      '+10 to Strength',
      'Attacker takes Lightning Damage of 27',
      '5% Chance to cast Level 5 Chain Lightning when struck',
      '2% Chance to cast Level 20 Charged Bolt when struck',
    ],
    category: 'Elite Unique Polearms',
    id: 237,
    filename: 'warscythe.gif',
    category_id: 24,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Knell Striker',
    type: 'Scepter',
    image: 'http://www.d2tomb.com/images/items/scepter.gif',
    props: [
      'Damage:',
      '10-19',
      'Durability:',
      '50',
      'Req Strength:',
      '25',
      'Req Level:',
      '5',
      '+70-80% Enhanced Damage',
      '25% Chance of Crushing Blow',
      '+20% Poison resist',
      '+20% Fire Resist',
      '+35 to Attack Rating',
      '+15 to Mana',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Scepters',
    id: 238,
    filename: 'scepter.gif',
    category_id: 25,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rusthandle',
    type: 'Grand Scepter',
    image: 'http://www.d2tomb.com/images/items/grandscepter.gif',
    props: [
      'Damage:',
      '15-35',
      'Durability:',
      '60',
      'Req Strength:',
      '37',
      'Req Level:',
      '18',
      '+50-60% Enhanced Damage',
      '+100-110% Damage to Undead',
      '+3 to Thorns (Paladin only)',
      '+1-3 to Vengeance (Paladin only)',
      '+1 to Paladin Skill levels',
      '8% Life stolen per hit',
      'Magic damage reduced by 1',
      'Adds 3-7 Damage',
    ],
    category: 'Unique Scepters',
    id: 239,
    filename: 'grandscepter.gif',
    category_id: 25,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Stormeye',
    type: 'War Scepter',
    image: 'http://www.d2tomb.com/images/items/warscepter.gif',
    props: [
      'Damage:',
      '18-37',
      'Durability:',
      '70',
      'Req Strength:',
      '55',
      'Req Level:',
      '30',
      '+80-120% Enhanced Damage',
      '+1 to Fist of Heavens (Paladin only)',
      '+3 to Holy Shock (Paladin only)',
      '+3-5 to Resist Lightning (Paladin only)',
      'Replenish Life +10',
      'Adds 3-5 Cold Damage for 3 sec',
      'Adds 1-6 Lightning Damage',
      '+50% Damage vs. Undead',
    ],
    category: 'Unique Scepters',
    id: 240,
    filename: 'warscepter.gif',
    category_id: 25,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Zakarum's Hand",
    type: 'Rune Scepter',
    image: 'http://www.d2tomb.com/images/items/scepter.gif',
    props: [
      'Req Strength:',
      '58',
      'Damage:',
      '36-76',
      'Durability:',
      '50',
      'Req Level:',
      '37',
      '+180-220% Enhanced Damage',
      '+50% Damage to Undead',
      '30% Increased Attack Speed',
      '6% chance to cast level 5 Blizzard on striking',
      '8% Mana stolen per hit',
      'Ignore target defense',
      '10% Regenerate Mana',
      '15% Heal Stamina',
      '+2 to Holy Shock (Paladin only)',
      '+2 to Holy Freeze (Paladin only)',
    ],
    category: 'Exceptional Unique Scepters',
    id: 241,
    filename: 'scepter.gif',
    category_id: 25,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Fetid Sprinkler',
    type: 'Holy Water Sprinkler',
    image: 'http://www.d2tomb.com/images/items/grandscepter.gif',
    props: [
      'Req Strength:',
      '76',
      'Damage:',
      '51-129',
      'Durability:',
      '60',
      'Req Level:',
      '38',
      '+160-190% Enhanced Damage',
      'Adds 15-25 Damage',
      '+50% Damage to Undead',
      '+2 to Paladin skill levels',
      '10% chance to cast level 1 Confuse on striking',
      '5% chance to cast level 1 Decrepify on striking',
      '+160 Poison Damage for 4 sec',
      '+150-200 to Attack Rating',
    ],
    category: 'Exceptional Unique Scepters',
    id: 242,
    filename: 'grandscepter.gif',
    category_id: 25,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Hand of Blessed Light',
    type: 'Divine Scepter',
    image: 'http://www.d2tomb.com/images/items/warscepter.gif',
    props: [
      'Req Strength:',
      '103',
      'Damage:',
      '56-143',
      'Durability:',
      '70',
      'Req Level:',
      '42',
      '+130-160% Enhanced Damage',
      'Adds 20-45 Damage',
      '+50% Damage to Undead',
      '+2 to Paladin Skill levels',
      '100% bonus to Attack Rating',
      '15% Regenerate Mana',
      '+50 Defense',
      '5% chance to cast level 4 Fist of Heavens on striking',
      '+2 to Fist of Heavens (Paladin only)',
      '+4 Holy Bolt (Paladin only)',
      '+4 to Light Radius',
    ],
    category: 'Exceptional Unique Scepters',
    id: 243,
    filename: 'warscepter.gif',
    category_id: 25,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Heaven's Light",
    type: 'Mighty Scepter',
    image: 'http://www.d2tomb.com/images/items/scepter.gif',
    props: [
      'Req Strength:',
      '125',
      'Req Dexterity:',
      '65',
      'Damage:',
      '140-208',
      'Durability:',
      ' 50',
      'Sockets:',
      '1-2',
      'Req Level:',
      '61',
      '+250-300% Enhanced Damage',
      '+50% Damage to Undead',
      "-33% Target's Defense",
      '+20% Increased Attack Speed',
      '33% Chance of Crushing Blow',
      '+15-20 Life after Each Demon Kill',
      '+2-3 to Paladin Skill Levels',
      '+3 to Light Radius',
    ],
    category: 'Elite Unique Scepters',
    id: 244,
    filename: 'scepter.gif',
    category_id: 25,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Redeemer',
    type: 'Mighty Scepter',
    image: 'http://www.d2tomb.com/images/items/scepter.gif',
    props: [
      'Req Strength:',
      '50',
      'Req Dexterity:',
      '26',
      'Damage:',
      '140-208',
      'Durability:',
      ' 50',
      'Req Level:',
      '72',
      '+250-300% Enhanced Damage',
      '+60-120 Damage',
      '+50% Damage to Undead',
      '+200-250% Damage to Demons',
      '+2 to Paladin Skill Levels',
      "-33% Target's Defense",
      '+2-4 to Redemption (Paladin only)',
      '+2-4 to Holy Bolt (Paladin only)',
      '+3 to Light Radius',
      '-60% Requirements',
    ],
    category: 'Elite Unique Scepters',
    id: 245,
    filename: 'scepter.gif',
    category_id: 25,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Astreon's Iron Ward",
    type: 'Caduceus',
    image: 'http://www.d2tomb.com/images/items/warscepter.gif',
    props: [
      'Req Strength:',
      '97',
      'Req Dexterity:',
      '70',
      'Damage:',
      '125-167',
      'Durability:',
      '70',
      'Req Level:',
      '66',
      '+240-290% Enhanced Damage',
      '+40-85 Damage',
      '+50% Damage to Undead',
      'Adds 80-240 Magic Damage',
      '33% Chance of Crushing Blow',
      'Slows Target by 25%',
      'Damage Reduced by 4-7',
      '+2-4 to Combat Skills (Paladin only)',
      '+10% Increased Attack Speed',
      '150-200% Bonus to Attack Rating',
    ],
    category: 'Elite Unique Scepters',
    id: 246,
    filename: 'warscepter.gif',
    category_id: 25,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Pelta Lunata',
    type: 'Buckler',
    image: 'http://www.d2tomb.com/images/uniques/peltalunata.gif',
    props: [
      'Defense:',
      '39',
      'Durability:',
      '20-24',
      'Req Strength:',
      '12',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress):',
      '40%',
      'Req Level:',
      '2',
      '+30-40% Enhanced Defense',
      '+30 to Defense',
      '20% increased chance of Blocking',
      '40% Faster Block Rate',
      '+10 to Energy and Vitality',
      '+2 to Strength',
    ],
    category: 'Unique Shields',
    id: 247,
    filename: 'peltalunata.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Umbral Disk',
    type: 'Small Shield',
    image: 'http://www.d2tomb.com/images/uniques/umbraldisk.gif',
    props: [
      'Defense:',
      '45-46',
      'Durability:',
      '26-31',
      'Req Strength:',
      '22',
      'Block (Paladin):',
      '65%',
      'Block (Amazon, Assassin, Barbarian):',
      '60%',
      'Block (Druid, Necromancer, Sorceress):',
      '55%',
      'Req Level:',
      '9',
      '+40-50% Enhanced Defense',
      '30% increased chance of Blocking',
      'Hit blinds target',
      '-2 to Light Radius',
      '+30 to Defense',
      '+20 to Life',
      '+10 to Dexterity',
      '+10-15 to Durability',
    ],
    category: 'Unique Shields',
    id: 248,
    filename: 'umbraldisk.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Stormguild',
    type: 'Large Shield',
    image: 'http://www.d2tomb.com/images/uniques/stormguild.gif',
    props: [
      'Defense:',
      '52-54',
      'Durability:',
      '34-39',
      'Req Strength:',
      '34',
      'Block (Paladin):',
      '72%',
      'Block (Amazon, Assassin, Barbarian):',
      '67%',
      'Block (Druid, Necromancer, Sorceress):',
      '62%',
      'Req Level:',
      '13',
      '+50-60% Enhanced Defense',
      '+30 to Defense',
      '+30% increased chance of Blocking',
      '+25% Lightning Resist',
      'Attacker takes Lightning damage of 3',
      'Magic damage reduced by 1',
      'Adds 1-6 Lightning damage',
    ],
    category: 'Unique Shields',
    id: 249,
    filename: 'stormguild.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Steelclash',
    type: 'Kite Shield',
    image: 'http://www.d2tomb.com/images/uniques/steelclash.gif',
    props: [
      'Defense:',
      '50-58',
      'Durability:',
      '45-50',
      'Req Strength:',
      '47',
      'Block (Paladin):',
      '63%',
      'Block (Amazon, Assassin, Barbarian):',
      '58%',
      'Block (Druid, Necromancer, Sorceress):',
      '53%',
      'Req Level:',
      '17',
      '+60-100% Enhanced Defense',
      '+20 to Defense',
      '25% faster Block Rate',
      '+15 to all Resistances',
      '25% Increased chance of blocking',
      '+3 to Light radius',
      '+1 to Paladin skills',
      'Damage reduced by 3',
    ],
    category: 'Unique Shields',
    id: 250,
    filename: 'steelclash.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Swordback Hold',
    type: 'Spiked Shield',
    image: 'http://www.d2tomb.com/images/uniques/swordbackhold.gif',
    props: [
      'Defense:',
      '43-51',
      'Durability:',
      '40',
      'Req Strength:',
      '30',
      'Block (Paladin):',
      '60%',
      'Block (Amazon, Assassin, Barbarian):',
      '55%',
      'Block (Druid, Necromancer, Sorceress):',
      '50%',
      'Req Level:',
      '15',
      '+30-60% enhanced Defense',
      '+10 to Defense',
      '20% increased chance of blocking',
      '50% chance of open wounds',
      'Attacker takes Damage of 10',
    ],
    category: 'Unique Shields',
    id: 251,
    filename: 'swordbackhold.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bverrit Keep',
    type: 'Tower Shield',
    image: 'http://www.d2tomb.com/images/uniques/bverritkeep.gif',
    props: [
      'Defense:',
      '76-87',
      'Durability:',
      '140-160',
      'Req Strength:',
      '75',
      'Block (Paladin):',
      '64%',
      'Block (Amazon, Assassin, Barbarian):',
      '59%',
      'Block (Druid, Necromancer, Sorceress):',
      '54%',
      'Req Level:',
      '19',
      '+80-120% Enhanced Defense',
      '10% increased chance of Blocking',
      'Magic damage reduced by 5',
      '+75% Fire resist',
      '+30 Defense',
      '+5 to Strength',
    ],
    category: 'Unique Shields',
    id: 252,
    filename: 'bverritkeep.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Wall of the Eyeless',
    type: 'Bone Shield',
    image: 'http://www.d2tomb.com/images/uniques/walloftheeyeless.gif',
    props: [
      'Defense:',
      '50-53',
      'Durability:',
      '40',
      'Req Strength:',
      '25',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress): 40%',
      'Req Level:',
      '20',
      '+30-40% Enhanced Defense',
      '+10 to Defense',
      '+5 to Mana after each kill',
      '20% Faster Cast Rate',
      '3% Mana stolen per hit',
      '+20% Poison resist',
    ],
    category: 'Unique Shields',
    id: 253,
    filename: 'walloftheeyeless.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Ward',
    type: 'Gothic Shield',
    image: 'http://www.d2tomb.com/images/uniques/theward.gif',
    props: [
      'Defense:',
      '112',
      'Durability:',
      '40',
      'Req Strength:',
      '60',
      'Block (Paladin):',
      '56%',
      'Block (Amazon, Assassin, Barbarian):',
      '51%',
      'Block (Druid, Necromancer, Sorceress):',
      '46%',
      'Req Level:',
      '26',
      '+100% Enhanced Defense',
      '10% increased chance of blocking',
      '+30-50 to all Resistances',
      'Magic damage reduced by 2',
      '+40 Defense',
      '+10 to Strength',
    ],
    category: 'Unique Shields',
    id: 254,
    filename: 'theward.gif',
    category_id: 14,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Visceratuant',
    type: 'Defender',
    image: 'http://www.d2tomb.com/images/items/buckler.gif',
    props: [
      'Defense:',
      '100-125',
      'Durability:',
      '68',
      'Req Strength:',
      '38',
      'Block (Paladin)',
      '70%',
      'Block (Amazon, Assassin, Barbarian):',
      '65%',
      'Block (Druid, Necromancer, Sorceress):',
      '60%',
      'Req Level:',
      '28',
      '+100-150% Enhanced Defense',
      '30% increased chance of Blocking',
      '30% Faster Block Rate',
      '+1 to Sorceress Skill Levels',
      'Attacker takes Lightning Damage of 10',
    ],
    category: 'Exceptional Unique Shields',
    id: 255,
    filename: 'peltalunata.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Moser's Blessed Circle",
    type: 'Round Shield',
    image: 'http://www.d2tomb.com/images/items/smallshield.gif',
    props: [
      'Defense:',
      '156-179',
      'Durability:',
      '64',
      'Req Strength:',
      '53',
      'Block (Paladin):',
      '67%',
      'Block (Amazon, Assassin, Barbarian):',
      '62%',
      'Block (Druid, Necromancer, Sorceress):',
      '57%',
      'Req Level:',
      '31',
      '+180-220% Enhanced Defense',
      '+25% Increase chance of Blocking',
      '30% Faster Block Rate',
      '+25 to all Resistances',
      '2 sockets',
    ],
    category: 'Exceptional Unique Shields',
    id: 256,
    filename: 'blessedcircle.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Stormchaser',
    type: 'Scutum',
    image: 'http://www.d2tomb.com/images/items/largeshield.gif',
    props: [
      'Defense:',
      '161-198',
      'Durability:',
      '62',
      'Req Strength:',
      '71',
      'Block (Paladin):',
      '64%',
      'Block (Amazon, Assassin, Barbarian0:',
      '59%',
      'Block (Druid, Necromancer, Sorceress):',
      '54%',
      'Req Level:',
      '35',
      '+160-220% Enhanced Defense',
      '+20% increased chance of Blocking',
      '4% chance to cast level 5 Tornado when struck',
      '4% chance to cast level 5 Blizzard when struck',
      '10% Faster Block Rate',
      '+150 to Attack Rating',
      '+50% Lightning Resist',
      'Half Freeze Duration',
      'Adds 1-60 Lightning Damage',
    ],
    category: 'Exceptional Unique Shields',
    id: 257,
    filename: 'stormchaser.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Tiamat's Rebuke",
    type: 'Dragon Shield',
    image: 'http://www.d2tomb.com/images/items/kiteshield.gif',
    props: [
      'Defense:',
      '163-204',
      'Durability:',
      '116',
      'Req Strength:',
      '91',
      'Block (Paladin):',
      '48%',
      'Block (Amazon, Assassin, Barbarian)',
      '43%',
      'Block (Druid, Necromancer, Sorceress):',
      '38%',
      'Req Level:',
      '38',
      '+140-200% Enhanced Defense',
      'Adds 27-53 Cold Damage',
      'Adds 35-95 Fire Damage',
      'Adds 1-120 Lightning Damage',
      '3% chance to cast level 6 Hydra when struck',
      '5% chance to cast level 7 Nova when struck',
      '5% chance to cast level 9 Frost Nova when struck',
      '+25-35% to all Resistances',
    ],
    category: 'Exceptional Unique Shields',
    id: 258,
    filename: 'steelclash.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Lance Guard',
    type: 'Barbed Shield',
    image: 'http://www.d2tomb.com/images/items/spikedshield.gif',
    props: [
      'Defense:',
      '134-173',
      'Durability:',
      '55',
      'Req Strength:',
      '65',
      'Block (Paladin)',
      '47%',
      'Block (Amazon, Assassin, Barbarian):',
      '42%',
      'Block (Druid, Necromancer, Sorceress):',
      '37%',
      'Req Level:',
      '35',
      '+70-120% Enhanced Defense',
      '15% Damage taken goes to Mana',
      '30% Faster Hit recovery',
      '20% Deadly Strike',
      '+50 to Life',
      'Attacker takes damage of 47',
    ],
    category: 'Exceptional Unique Shields',
    id: 259,
    filename: 'lanceguard.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Gerke's Sanctuary",
    type: 'Pavise',
    image: 'http://www.d2tomb.com/images/items/towershield.gif',
    props: [
      'Defense:',
      '221-268',
      'Durability:',
      '172',
      'Req Strength:',
      '133',
      'Block (Paladin):',
      '84%',
      'Block (Amazon, Assassin, Barbarian):',
      '79%',
      'Block (Druid, Necromancer, Sorceress):',
      '74%',
      'Req Level:',
      '44',
      '+180-240% Enhanced Defense',
      '+30% Increased chance of Blocking',
      '+20-30 to all Resistances',
      '+15 Replenish Life',
      'Damage reduced by 11-16',
      'Magic Damage reduced by 14-18',
    ],
    category: 'Exceptional Unique Shields',
    id: 260,
    filename: 'bverritkeep.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Lidless Wall',
    type: 'Grim Shield',
    image: 'http://www.d2tomb.com/images/items/boneshield.gif',
    props: [
      'Defense:',
      '271-347',
      'Durability:',
      '70',
      'Req Strength:',
      '58',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress):',
      '40%',
      'Req Level:',
      '41',
      '+80-130% Enhanced Defense',
      '+1 to all Skill Levels',
      '10% increased maximum Mana',
      '20% faster Cast Rate',
      '+3-5 to Mana after each kill',
      '+10 to Energy',
      '+1 to Light Radius',
    ],
    category: 'Exceptional Unique Shields',
    id: 261,
    filename: 'lidlesswall.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Radament's Sphere",
    type: 'Ancient Shield',
    image: 'http://www.d2tomb.com/images/items/gothicshield.gif',
    props: [
      'Defense:',
      '244-282',
      'Durability:',
      '100',
      'Req Strength:',
      '110',
      'Block (Paladin):',
      '66%',
      'Block (Amazon, Assassin, Barbarian):',
      '61%',
      'Block (Druid, Necromancer, Sorceress):',
      '56%',
      'Req Level:',
      '50',
      '+160-200% Enhanced Defense',
      '+20% increased chance of Blocking',
      '+20% faster Block Rate',
      '5% chance to cast level 5 Poison Nova when struck',
      '40 charges level 6 Poison Explosion',
      '+75% Poison Resist',
      '+80 Poison Damage for 4 sec',
    ],
    category: 'Exceptional Unique Shields',
    id: 262,
    filename: 'theward.gif',
    category_id: 14,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Blackoak Shield',
    type: 'Luna',
    image: 'http://www.d2tomb.com/images/uniques/umbraldisk.gif',
    props: [
      'Defense:',
      '322-372',
      'Durability:',
      '129',
      'Req Strength:',
      '100',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress):',
      '40%',
      'Req Level:',
      '61',
      '+160-200% Enhanced Defense',
      '0.5 per character level to Dexterity',
      '0.625 per character level to Cold Absorb',
      '1.25 per character level to Life',
      '+50% faster Block Rate',
      'Half Freeze Duration',
      '4% chance to cast level 5 Weaken when struck',
    ],
    category: 'Unique Elite Shields',
    id: 263,
    filename: 'umbraldisk.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Stormshield',
    type: 'Monarch',
    image: 'http://www.d2tomb.com/images/uniques/bverritkeep.gif',
    props: [
      'Defense:',
      '136-519',
      'Req Strength:',
      '156',
      'Block (Paladin):',
      '77%',
      'Block (Amazon, Assassin, Barbarian):',
      '72%',
      'Block (Druid, Necromancer, Sorceress):',
      '67%',
      'Req Level:',
      '73',
      'Indestructible',
      '+3.75 per character level to Defense',
      'attacker takes Lightning Damage of 10',
      '+25% increased chance of blocking',
      '35% Faster Block Rate',
      'Damage reduced by 35%',
      '+60% Cold Resist',
      '+25% Lightning Resist',
      '+30 to Strength',
    ],
    category: 'Unique Elite Shields',
    id: 264,
    filename: 'steelclash.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Spike Thorn',
    type: 'Blade Barrier',
    image: 'http://www.d2tomb.com/images/items/spikedshield.gif',
    props: [
      'Defense:',
      '360-410',
      'Durability:',
      '333',
      'Req Strength:',
      '118',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress):',
      '40%',
      'Sockets:',
      '1',
      'Req Level:',
      '70',
      '+120-150% Enhanced Defense',
      '+30% Faster Hit Recovery',
      'Damage Reduced by 15-20%',
      '+1.375 per character level to Damage taken by attacker',
    ],
    category: 'Unique Elite Shields',
    id: 265,
    filename: 'swordbackhold.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Medusa's Gaze",
    type: 'Aegis',
    image: 'http://www.d2tomb.com/images/items/towershield.gif',
    props: [
      'Defense:',
      '405-453',
      'Durability:',
      '92',
      'Req Strength:',
      '219',
      'Block (Paladin):',
      '54%',
      'Block (Amazon, Assassin, Barbarian):',
      '49%',
      'Block (Druid, Necromancer, Sorceress):',
      '44%',
      'Req Level:',
      '76',
      '+150-180% Enhanced Defense',
      '5-9% Life Stolen per Hit',
      'Slows Target by 20%',
      '+40-80% Cold Resist',
      '10% Chance to cast Level 7 Lower Resist when struck',
      '100% Chance to cast Level 44 Nova when you die',
    ],
    category: 'Unique Elite Shields',
    id: 266,
    filename: 'bverritkeep.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Head Hunter's Glory",
    type: 'Troll Nest',
    image: 'http://www.d2tomb.com/images/items/boneshield.gif',
    props: [
      'Defense:',
      '478-593',
      'Durability:',
      '74',
      'Req Strength:',
      '106',
      'Block (Paladin):',
      '50%',
      'Block (Amazon, Assassin, Barbarian):',
      '45%',
      'Block (Druid, Necromancer, Sorceress):',
      '40%',
      'Sockets:',
      '1-3',
      'Req Level:',
      '75',
      '+320-420 Defense',
      '+300-350 Defense vs. Missile',
      '+20-30% Fire Resist',
      '+30-40% Poison Resist',
      '+5-7 Life after each Kill',
    ],
    category: 'Unique Elite Shields',
    id: 267,
    filename: 'walloftheeyeless.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Spirit Ward',
    type: 'Ward',
    image: 'http://www.d2tomb.com/images/items/gothicshield.gif',
    props: [
      'Defense:',
      '393-478',
      'Durability:',
      '100',
      'Req Strength:',
      '185',
      'Block (Paladin):',
      '74-84%',
      'Block (Amazon, Assassin, Barbarian):',
      '69-79%',
      'Block (Druid, Necromancer, Sorceress):',
      '64-74%',
      'Req Level:',
      '68',
      '+130-180% Enhanced Defense',
      '+25% Faster Block Rate',
      '20-30% Increased Chance of Blocking',
      '+30-40 to all Resistances',
      '+6-11 Cold Absorb',
      '5% Chance to cast Level 8 Fade when struck',
    ],
    category: 'Unique Elite Shields',
    id: 268,
    filename: 'theward.gif',
    category_id: 14,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Dragon Chang',
    type: 'Spear',
    image: 'http://www.d2tomb.com/images/uniques/thedragonchang.gif',
    props: [
      'Damage:',
      '13-15',
      'Dexterity: 20',
      'Durability:',
      '30',
      'Req Level:',
      '8',
      'Adds 3-6 Fire Damage',
      '100% Damage vs. Undead',
      '+2 to Light Radius',
      '+35 to Attack Rating',
      '+10 to minimum damage',
    ],
    category: 'Unique Spears',
    id: 269,
    filename: 'thedragonchang.gif',
    category_id: 26,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Razortine',
    type: 'Trident',
    image: 'http://www.d2tomb.com/images/uniques/razortine.gif',
    props: [
      'Req Strength:',
      '38',
      'Req Dexterity:',
      '24',
      'Damage:',
      '11-22',
      'Durability:',
      '35',
      'Req Level:',
      '12',
      '+30-50% Enhanced Damage',
      'Slows target by 25%',
      '50% target defense',
      '+8 to Dexterity',
      '+15 to Strength',
      '30% increased attack speed',
    ],
    category: 'Unique Spears',
    id: 270,
    filename: 'razortine.gif',
    category_id: 26,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bloodthief',
    type: 'Brandistock',
    image: 'http://www.d2tomb.com/images/uniques/bloodthief.gif',
    props: [
      'Req Strength:',
      '40',
      'Req Dexterity:',
      '50',
      'Damage:',
      '10-28',
      'Durability:',
      '28',
      'Req Level:',
      '17',
      '+50-70% Enhanced Damage',
      '35% chance of Open Wounds',
      '8-12% Life stolen per hit',
      '+26 to Life',
      '+10 to Strength',
    ],
    category: 'Unique Spears',
    id: 271,
    filename: 'bloodthief.gif',
    category_id: 26,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Lance of Yaggai',
    type: 'Spetum',
    image: 'http://www.d2tomb.com/images/uniques/lanceofyaggai.gif',
    props: [
      'Req Strength:',
      '54',
      'Req Dexterity:',
      '35',
      'Damage:',
      '15-23',
      'Durability:',
      '28',
      'Req Level:',
      '22',
      'Adds 1-60 Lightning Damage',
      '+15 to All Resistances',
      '+40% increased Attack Speed',
      'Attacker takes 8 Damage',
    ],
    category: 'Unique Spears',
    id: 272,
    filename: 'lanceofyaggai.gif',
    category_id: 26,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Tannr Gorerod',
    type: 'Pike',
    image: 'http://www.d2tomb.com/images/uniques/thetannrgorerod.gif',
    props: [
      'Req Strength:',
      '60',
      'Req Dexterity:',
      '45',
      'Damage:',
      '25-126',
      'Durability:',
      '25',
      'Req Level:',
      '27',
      '+80-100% Enhanced Damage',
      '+3 to Light Radius',
      '15% to max Fire Resist',
      '15% Fire Resist',
      'Adds 23-54 Fire Damage',
      '+60 to Attack Rating',
      '+30 to Life',
    ],
    category: 'Unique Spears',
    id: 273,
    filename: 'thetannrgorerod.gif',
    category_id: 26,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Impaler',
    type: 'War Spear',
    image: 'http://www.d2tomb.com/images/items/spear.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '25',
      'Damage:',
      '24-99',
      'Durability:',
      '30',
      'Req Level:',
      '31',
      '+140-170% Enhanced Damage',
      '40% chance of Open Wounds',
      '+20% increased Attack Speed',
      'Ignore Targets Defense',
      '+150 to Attack Rating',
      'Prevent Monster Heal',
      '+5 to Impale (Amazon only)',
      '+3 to Power Strike (Amazon only)',
    ],
    category: 'Exceptional Unique Spears',
    id: 274,
    filename: 'impaler.gif',
    category_id: 26,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Kelpie Snare',
    type: 'Fuscina',
    image: 'http://www.d2tomb.com/images/items/trident.gif',
    props: [
      'Req Strength:',
      '77',
      'Req Dexterity:',
      '25',
      'Damage:',
      '75-153',
      'Durability:',
      '35',
      'Req Level:',
      '33',
      '+140-180% Enhanced Damage',
      'Adds 30-50 Damage',
      'Slows Target by 75%',
      '+1.25 per character level to Life',
      '+50% Fire Resist',
      '+10 to Strength',
    ],
    category: 'Exceptional Unique Spears',
    id: 275,
    filename: 'razortine.gif',
    category_id: 26,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Soulfeast Tine',
    type: 'War Fork',
    image: 'http://www.d2tomb.com/images/items/brandistock.gif',
    props: [
      'Req Strength:',
      '64',
      'Req Dexterity:',
      '76',
      'Damage:',
      '40-116',
      'Durability:',
      '43',
      'Req Level:',
      '35',
      '+150-250 to Attack Rating',
      '+150-190% Enhanced Damage',
      '-20% Requirements',
      '7% Life stolen per hit',
      '7% Mana stolen per hit',
      '20% Stamina Drain',
    ],
    category: 'Exceptional Unique Spears',
    id: 276,
    filename: 'soulfeastine.gif',
    category_id: 26,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Hone Sundan',
    type: 'Yari',
    image: 'http://www.d2tomb.com/images/items/spetum.gif',
    props: [
      'Req Strength:',
      '101',
      'Damage:',
      '95-217',
      'Durability:',
      '28',
      'Req Level:',
      '37',
      '+160-200% Enhanced Damage',
      'Adds 20-40 Damage',
      '45% chance of Crushing Blow',
      'Repairs 1 durability in 10 seconds',
      '3 sockets',
    ],
    category: 'Exceptional Unique Spears',
    id: 277,
    filename: 'lanceofyaggai.gif',
    category_id: 26,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Spire of Honor',
    type: 'Lance',
    image: 'http://www.d2tomb.com/images/items/pike.gif',
    props: [
      'Req Strength:',
      '110',
      'Req Dexterity:',
      '88',
      'Damage:',
      '87-382',
      'Durabilty: 25',
      'Req Level:',
      '39',
      '+150-200% Enhanced Damage',
      'Adds 20-40 Damage',
      '+1.5 per character level % Damage to demons',
      '20% Faster Hit Recovery',
      '+20 Replenish Life',
      '+25% Bonus to Attack Rating',
      '+25% Enhanced defense',
      '+3 to Combat Skills (Paladin only)',
      '+3 to Light Radius',
    ],
    category: 'Exceptional Unique Spears',
    id: 278,
    filename: 'pike.gif',
    category_id: 26,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Arioc's Needle",
    type: 'Hyperion Spear',
    image: 'http://www.d2tomb.com/images/items/spear.gif',
    props: [
      'Req Strength:',
      '155',
      'Req Dexterity:',
      '120',
      'Damage:',
      '98-392',
      'Durability:',
      ' 30',
      'Req Level:',
      '81',
      '+180-230% Enhanced Damage',
      '50% Deadly Strike',
      '+394 Poison Damage over 10 seconds',
      '+30% Increased Attack Speed',
      '+2-4 to all Skills',
      "Ignores Target's Defense",
    ],
    category: 'Elite Unique Spears',
    id: 279,
    filename: 'spear.gif',
    category_id: 26,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Viperfork',
    type: 'Mancatcher',
    image: 'http://www.d2tomb.com/images/items/brandistock.gif',
    props: [
      'Req Strength:',
      '132',
      'Req Dexterity:',
      '134',
      'Damage:',
      '121-312',
      'Durability:',
      '28',
      'Req Level:',
      '71',
      '+190-240% Enhanced Damage',
      '+200-250 to Attack Rating',
      '+50% Increased Attack Speed',
      '+325 Poison Damage over 10 seconds',
      '+30-50% Poison Resist',
      '15% Chance to cast Level 9 Poison Explosion on striking',
    ],
    category: 'Elite Unique Spears',
    id: 280,
    filename: 'viperfork.gif',
    category_id: 26,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Steel Pillar',
    type: 'War Pike',
    image: 'http://www.d2tomb.com/images/items/pike.gif',
    props: [
      'Req Strength:',
      '165',
      'Req Dexterity:',
      '106',
      'Damage:',
      '102-640',
      'Indestructible',
      'Req Level:',
      '69',
      '+210-260% Enhanced Damage',
      '25% Chance of Crushing Blow',
      '-20% Target Defense',
      '+25% Increased Attack Speed',
      '+50-80% Enhanced Defense',
    ],
    category: 'Elite Unique Spears',
    id: 281,
    filename: 'pike.gif',
    category_id: 26,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Bane Ash',
    type: 'Short Staff',
    image: 'http://www.d2tomb.com/images/uniques/baneash.gif',
    props: [
      'Damage:',
      '1-8',
      'Durability:',
      '20',
      'Req Level:',
      '5',
      '+50-60% Enhanced Damage',
      '+50% Damage to Undead',
      '+50% Fire Resist',
      'Adds 4-6 Fire Damage',
      '+30 to Mana',
      '20% Increased Attack Speed',
      '+5 to Fire Bolt (Sorceress only)',
      '+2 to Warmth (Sorceress only)',
    ],
    category: 'Unique Staves',
    id: 282,
    filename: 'baneash.gif',
    category_id: 27,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Serpent Lord',
    type: 'Long Staff',
    image: 'http://www.d2tomb.com/images/uniques/serpentlord.gif',
    props: [
      'Damage:',
      '2-11',
      'Durability:',
      '30',
      'Req Level:',
      '9',
      '+30-40% Enhanced Damage',
      '+50% Damage to Undead',
      '100% Mana stolen per hit',
      '-50% to Target Defense',
      '-1 to Light Radius',
      '+50% Poison Resist',
      'Adds 12 Poison Damage for 3 seconds',
      '+10 to Mana',
    ],
    category: 'Unique Staves',
    id: 283,
    filename: 'serpentlord.gif',
    category_id: 27,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Spire of Lazarus',
    type: 'Gnarled Staff',
    image: 'http://www.d2tomb.com/images/uniques/spireoflazarus.gif',
    props: [
      'Damage:',
      '4-12',
      'Durability:',
      '35',
      'Req Level:',
      '18',
      '+50% Damage to Undead',
      'Adds 1-28 Lightning Damage',
      '43% Regenerate Mana',
      '+ 1 to Sorceress Skill Levels',
      '+15 to Energy',
      'Damage reduced by 5',
      '+75% Lightning Resistance',
      '+2 to Lightning (Sorceress only)',
      '+1 to Chain Lightning (Sorceress only)',
      '+3 to Static Field (Sorceress only)',
    ],
    category: 'Unique Staves',
    id: 284,
    filename: 'spireoflazarus.gif',
    category_id: 27,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Salamander',
    type: 'Battle Staff',
    image: 'http://www.d2tomb.com/images/uniques/thesalamander.gif',
    props: [
      'Damage:',
      '6-13',
      'Durability:',
      '40',
      'Req Level:',
      '21',
      '+50% Damage to Undead',
      '+2 to Fire Ball (Sorceress only)',
      '+1 to Fire Wall (Sorceress only)',
      '+2 to Fire Skills',
      '+30% Fire Resist',
      'Adds 15-32 Fire Damage',
      '+3 to Warmth (Sorceress only)',
    ],
    category: 'Unique Staves',
    id: 285,
    filename: 'thesalamander.gif',
    category_id: 27,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Iron Jang Bong',
    type: 'War Staff',
    image: 'http://www.d2tomb.com/images/uniques/theironiangbong.gif',
    props: [
      'Damage:',
      '24-56',
      'Durability:',
      '50',
      'Req Level:',
      '28',
      '+100% Enhanced Damage',
      '+50% Damage to Undead',
      '50% bonus to Attack Rating',
      '20% Faster Cast Rate',
      '+2 to Sorceress Skill Level',
      '+30 Defense',
      '+3 to Frost Nova (Sorceress only)',
      '+2 to Blaze (Sorceress only)',
      '+2 to Nova (Sorceress only)',
    ],
    category: 'Unique Staves',
    id: 286,
    filename: 'theironiangbong.gif',
    category_id: 27,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Razorswitch',
    type: 'Jo Staff',
    image: 'http://www.d2tomb.com/images/items/shortstaff.gif',
    props: [
      'Damage:',
      '6-21',
      'Durability:',
      '20',
      'Req Strength:',
      '25',
      'Req Level:',
      '28',
      '+50% Damage to Undead',
      '+1 to All Skill Levels',
      '30% Faster Cast Rate',
      'Magic Damage reduced by 15',
      '+50 to all Resistances',
      '+175 to Mana',
      '+80 to Life',
      'Attacker takes damage of 15',
    ],
    category: 'Exceptional Unique Staves',
    id: 287,
    filename: 'shortstaff.gif',
    category_id: 27,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Ribcracker',
    type: 'Quarter Staff',
    image: 'http://www.d2tomb.com/images/items/longstaff.gif',
    props: [
      'Damage:',
      '54-169',
      'Durability:',
      '130',
      'Req Strength:',
      '25',
      'Req Level:',
      '31',
      '+200-300% Enhanced Damage',
      'Adds 30-65 Damage',
      '+50% Damage to Undead',
      '50% chance of Crushing Blow',
      '50% Increased Attack Speed',
      '50% Faster Hit Recovery',
      '100% Enhanced Defense',
      '+100 Defense',
      '+15 to Dexterity',
    ],
    category: 'Exceptional Unique Staves',
    id: 288,
    filename: 'ribcracker.gif',
    category_id: 27,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Chromatic Ire',
    type: 'Cedar Staff',
    image: 'http://www.d2tomb.com/images/items/gnarledstaff.gif',
    props: [
      'Damage:',
      '11-32',
      'Durability:',
      '35',
      'Req Strength:',
      '25',
      'Req Level:',
      '35',
      '+50% Damage to Undead',
      '20% Faster Cast Rate',
      '+3 to Sorceress Skill Levels',
      '20-25% increased maximum Life',
      '+20-40 to all Resistances',
      'Attacker takes Lightning Damage of 20',
      '+1 to Cold Mastery (Sorceress only)',
      '+1 to Lightning Mastery (Sorceress only)',
      '+1 to Fire Mastery (Sorceress only)',
    ],
    category: 'Exceptional Unique Staves',
    id: 289,
    filename: 'lazarusspire.gif',
    category_id: 27,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Warpspear',
    type: 'Gothic Staff',
    image: 'http://www.d2tomb.com/images/items/battlestaff.gif',
    props: [
      'Damage:',
      '14-34',
      'Durability:',
      '40',
      'Req Strength:',
      '25',
      'Req Level:',
      '39',
      '+50% Damage to Undead',
      'Ignore Targets Defense',
      '+250 Defense vs. Missles',
      '+3 to Sorceress Skill Levels',
      '+3 to Energy Shield (Sorceress only)',
      '+3 to Telekinesis (Sorceress only)',
      '+3 to Teleport (Sorceress only)',
    ],
    category: 'Exceptional Unique Staves',
    id: 290,
    filename: 'warpspear.gif',
    category_id: 27,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Skull Collector',
    type: 'Rune Staff',
    image: 'http://www.d2tomb.com/images/items/warstaff.gif',
    props: [
      'Damage:',
      '24-58',
      'Durability:',
      '50',
      'Req Strength:',
      '25',
      'Req Level:',
      '41',
      '+50% Damage to Undead',
      '+20 to Mana after each kill',
      '20% increased maximum Mana',
      '+1 per character level better chance of getting Magic items',
      '+2 to all Skill Levels',
    ],
    category: 'Exceptional Unique Staves',
    id: 291,
    filename: 'skullcollector.gif',
    category_id: 27,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Ondal's Wisdom",
    type: 'Elder Staff',
    image: 'http://www.d2tomb.com/images/items/gnarledstaff.gif',
    props: [
      'Req Strength:',
      '44',
      'Req Dexterity:',
      '37',
      'Damage:',
      '80-93',
      'Durability:',
      '35',
      'Req Level:',
      '66',
      '+2-4 to all Skills',
      '+45% Faster Cast Rate',
      '+450-550 Defense',
      '+40-50 to Energy',
      'Magic Damage reduced by 5-8',
      '+5% to Experience Gained',
      '+50% Damage to Undead',
    ],
    category: 'Elite Unique Staves',
    id: 292,
    filename: 'lazarusspire.gif',
    category_id: 27,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Mang Song's Lesson",
    type: 'Archon Staff',
    image: 'http://www.d2tomb.com/images/items/warstaff.gif',
    props: [
      'Req Strength:',
      '34',
      'Damage:',
      '83-99',
      'Durability:',
      '26',
      'Req Level:',
      '82',
      '+5 to all Skills',
      '+30% Faster Cast Rate',
      '-7-15% to Enemy Lightning Resistance',
      '-7-15% to Enemy Cold Resistance',
      '-7-15% to Enemy Fire Resistance',
      '10% Regenerate Mana',
      '+50% Damage to Undead',
    ],
    category: 'Elite Unique Staves',
    id: 293,
    filename: 'mangsongslesson.gif',
    category_id: 27,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Rixot's Keen",
    type: 'Short Sword',
    image: 'http://www.d2tomb.com/images/uniques/rixotskeen.gif',
    props: [
      'Damage:',
      '9-14',
      'Durability:',
      '24',
      'Req Level:',
      '2',
      '1-Handed',
      '+100% Enhanced Damage',
      '25% chance of Crushing Blow',
      '20% Bonus to Attack Rating',
      '+2 to Light Radius',
      '+5 to Minimum Damage',
      '+25 Defense',
    ],
    category: 'Unique Swords',
    id: 294,
    filename: 'rixotskeen.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Blood Crescent',
    type: 'Scimitar',
    image: 'http://www.d2tomb.com/images/uniques/bloodcrescent.gif',
    props: [
      'Req Dexterity:',
      '21',
      'Damage:',
      '3-10',
      'Durability:',
      '22',
      'Req Level:',
      '7',
      '1-Handed',
      '+60-80% Enhanced Damage',
      '33% Chance of Open Wounds',
      '15% Increased Attack Speed',
      '15% Life stolen per hit',
      '+4 to Light Radius',
      '+15 to All Resistances',
      '+15 to Life',
    ],
    category: 'Unique Swords',
    id: 295,
    filename: 'bloodcrescent.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Skewer of Krintiz',
    type: 'Sabre',
    image: 'http://www.d2tomb.com/images/uniques/skewerofkrintiz.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '25',
      'Damage:',
      '7-19',
      'Durability:',
      ' 32',
      'Req Level:',
      '10',
      '1-Handed',
      "Ignores Target's Defense",
      '7% Mana stolen per hit',
      '+50% Enhanced Damage',
      'Adds 3-7 Damage',
      '+10 to Dexterity',
      '+10 to Strength',
    ],
    category: 'Unique Swords',
    id: 296,
    filename: 'skewerofkrintiz.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Gleamscythe',
    type: 'Falchion',
    image: 'http://www.d2tomb.com/images/uniques/gleamscythe.gif',
    props: [
      'Req Strength:',
      '33',
      'Damage:',
      '14-34',
      'Durability:',
      '32',
      'Req Level:',
      '13',
      '1-Handed',
      '+60-100% Enhanced Damage',
      'Adds 3-5 Cold Damage for 2 sec',
      '+3 to Light Radius',
      '+20 Defense',
      '+30 to Mana',
      '20% Increased Attack Speed',
    ],
    category: 'Unique Swords',
    id: 297,
    filename: 'gleamscythe.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Griswold's Edge",
    type: 'Broad Sword',
    image: 'http://www.d2tomb.com/images/uniques/griswoldsedge.gif',
    props: [
      'Req Strength:',
      '48',
      'Damage:',
      '12-30',
      'Durability:',
      '32',
      'Req Level:',
      '17',
      '1-Handed',
      '+80-120% Enhanced Damage',
      '+12 to Strength',
      'Adds 10-25 Fire Damage',
      '+100 to Attack Rating',
      '10% Increased Attack Speed',
      'Knockback',
    ],
    category: 'Unique Swords',
    id: 298,
    filename: 'griswoldsedge.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Hellplague',
    type: 'Long Sword',
    image: 'http://www.d2tomb.com/images/uniques/hellplague.gif',
    props: [
      'Req Strength:',
      '55',
      'Req Dexterity:',
      '39',
      'Damage:',
      '5-34',
      'Durability:',
      '44',
      'Req Level:',
      '22',
      '1-Handed',
      '+70-80% Enhanced Damage',
      '+2 to Fire Skills',
      'Adds 25-75 Fire Damage',
      '5% Life stolen per hit',
      '5% Mana stolen per hit',
      'Adds 28-56 Poison damage for 3 seconds',
    ],
    category: 'Unique Swords',
    id: 299,
    filename: 'hellplague.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Culwen's Point",
    type: 'War Sword',
    image: 'http://www.d2tomb.com/images/uniques/culwenspoint.gif',
    props: [
      'Req Strength:',
      '71',
      'Req Dexterity:',
      '45',
      'Damage:',
      '13-36',
      'Durability:',
      '44',
      'Req Level:',
      '29',
      '1-Handed',
      '+70-80% Enhanced Damage',
      '20% Increased Attack Speed',
      'Poison Length reduced by 50%',
      '20% Faster Hit Recovery',
      '+1 to all Skill Levels',
      '+60 to Attack Rating',
    ],
    category: 'Unique Swords',
    id: 300,
    filename: 'culwenspoint.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Shadowfang',
    type: ' 2-Handed Sword',
    image: 'http://www.d2tomb.com/images/uniques/shadowfang.gif',
    props: [
      'Req Strength:',
      '35',
      'Req Dexterity:',
      '27',
      'Damage:',
      '16-34',
      'Durability:',
      '44',
      'Req Level:',
      '12',
      '2-Handed',
      '+100% Enhanced Damage',
      '9% Life stolen per hit',
      '-2 to Light Radius',
      '9% Mana stolen per hit',
      'Cold Resist 20%',
      'Adds 10-30 Cold Damage for 6 sec',
    ],
    category: 'Unique Swords',
    id: 301,
    filename: 'shadowfang.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Soulflay',
    type: 'Claymore',
    image: 'http://www.d2tomb.com/images/uniques/soulflay.gif',
    props: [
      'Req Strength:',
      '47',
      'Damage:',
      '22-60',
      'Durability:',
      '50',
      'Req Level:',
      '19',
      '2-Handed',
      '4% Life stolen per hit',
      '4-10% Mana stolen per hit',
      '+70-100% Enhanced Damage',
      '10% Increased Attack Speed',
      '5 to All Resistances',
    ],
    category: 'Unique Swords',
    id: 302,
    filename: 'soulflay.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Kinemil's Awl",
    type: 'Giant Sword',
    image: 'http://www.d2tomb.com/images/uniques/kinemilsawl.gif',
    props: [
      'Req Strength:',
      '56',
      'Req Dexterity:',
      '34',
      'Damage:',
      '16-56',
      'Durability:',
      '50',
      'Req Level:',
      '23',
      '2-Handed',
      '+80-100% Enhanced Damage',
      '+100-150 to Attack Rating',
      'Adds 6-40 Fire Damage',
      '+6 to Holy Fire (Paladin only)',
      '+20 to Mana',
    ],
    category: 'Unique Swords',
    id: 303,
    filename: 'kinemilsawl.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Blacktongue',
    type: 'Bastard Sword',
    image: 'http://www.d2tomb.com/images/uniques/blacktongue.gif',
    props: [
      'Req Strength:',
      '62',
      'Damage:',
      '30-44',
      'Durability:',
      '40',
      'Req Level:',
      '26',
      '2-Handed',
      '+50-60% Enhanced Damage',
      '+113 Poison Damage for 6 sec',
      '+50 to Attack Rating',
      'Prevent Monster Heal',
      '50% Poison Resist',
    ],
    category: 'Unique Swords',
    id: 304,
    filename: 'blacktongue.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Ripsaw',
    type: 'Flamberge',
    image: 'http://www.d2tomb.com/images/uniques/ripsaw.gif',
    props: [
      'Req Strength:',
      '70',
      'Req Dexterity:',
      '49',
      'Damage:',
      '22-67',
      'Durability:',
      '50',
      'Req Level:',
      '26',
      '2-Handed',
      '+80-100% Enhanced Damage',
      '80% chance of Open Wounds',
      '6% Mana stolen per hit',
      '+15 to max Damage',
    ],
    category: 'Unique Swords',
    id: 305,
    filename: 'ripsaw.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'The Patriarch',
    type: 'Great Sword',
    image: 'http://www.d2tomb.com/images/uniques/thepatriarch.gif',
    props: [
      'Req Strength:',
      '100',
      'Req Dexterity:',
      '60',
      'Damage:',
      '50-92',
      'Durability:',
      '50',
      'Req Level:',
      '29',
      '2-Handed',
      '+100-120% Enhanced Damage',
      'Hit Blinds Target',
      'Damage reduced by 3',
      'Magic Damage reduced by 3',
      '100% extra gold from monsters',
      '+10 to Strength',
    ],
    category: 'Unique Swords',
    id: 306,
    filename: 'thegrandfather.gif',
    category_id: 28,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bloodletter',
    type: 'Gladius',
    image: 'http://www.d2tomb.com/images/items/shortsword.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '31-97',
      'Durability:',
      '54',
      'Req Level:',
      '30',
      '1-Handed',
      '+140% Enhanced Damage',
      'Adds 12-45 Damage',
      '+90 to Attack Rating',
      '20% Increased Attack Speed',
      '10% Slower Stamina Drain',
      '8% Life Stolen per Hit',
      '+1-3 to Whirlwind (Barbarian only)',
      '+2-4 to Sword Mastery (Barbarian only)',
    ],
    category: 'Exceptional Unique Swords',
    id: 307,
    filename: 'shortsword.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Coldsteel Eye',
    type: 'Cutlass',
    image: 'http://www.d2tomb.com/images/items/scimitar.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '52',
      'Damage:',
      '24-73',
      'Durability:',
      '72',
      'Req Level:',
      '31',
      '1-Handed',
      '+200-250% Enhanced Damage',
      '50% Chance of Deadly Strike',
      '20% Increased Attack Speed',
      '6% Mana stolen per hit',
      'Slows Target by 30%',
      'Hit Blinds Target',
    ],
    category: 'Exceptional Unique Swords',
    id: 308,
    filename: 'bloodcrescent.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Hexfire',
    type: 'Shamshir',
    image: 'http://www.d2tomb.com/images/items/saber.gif',
    props: [
      'Req Strength:',
      '58',
      'Req Dexterity:',
      '58',
      'Damage:',
      '59-102',
      'Durability:',
      '32',
      'Req Level:',
      '33',
      '1-Handed',
      '+140-160% Enhanced Damage',
      'Adds 35-40 Damage',
      '+3 to Fire Skills',
      '36 charges level 6 Hydra',
      'Ignore Targets Defense',
      '+25% Fire Resist',
      '10% to maximum Fire Resist',
    ],
    category: 'Exceptional Unique Swords',
    id: 309,
    filename: 'krintizskewer.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Blade of Ali Baba',
    type: 'Tulwar',
    image: 'http://www.d2tomb.com/images/items/falchion.gif',
    props: [
      'Req Strength:',
      '70',
      'Req Dexterity:',
      '42',
      'Damage:',
      '25-77',
      'Durability:',
      '32',
      'Req Level:',
      '35',
      '1-Handed',
      '+60-120% Enhanced Damage',
      '+2.5 per character level extra gold from monsters',
      '+1 per character level % better chance of getting magic items',
      '+15 to Mana',
      '+5-15 to Dexterity',
      '2 Sockets',
    ],
    category: 'Exceptional Unique Swords',
    id: 310,
    filename: 'bladeofalibaba.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: "Ginther's Rift",
    type: 'Dimensional Blade',
    image: 'http://www.d2tomb.com/images/items/crystalsword.gif',
    props: [
      'Req Strength:',
      '85',
      'Req Dexterity:',
      '60',
      'Damage:',
      '26-87',
      'Durability:',
      '60',
      'Req Level:',
      '37',
      '1-Handed',
      '+100-150% Enhanced Damage',
      'Adds 50-120 Magic Damage',
      '30% Increased Attack Rate',
      'Magic Damage reduced by 7-12',
      'Repairs 1 durability in 5 seconds',
    ],
    category: 'Exceptional Unique Swords',
    id: 311,
    filename: 'riftlash.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Headstriker',
    type: 'Battle Sword',
    image: 'http://www.d2tomb.com/images/items/broadsword.gif',
    props: [
      'Req Strength:',
      '92',
      'Req Dexterity:',
      '43',
      'Damage:',
      '40-184',
      'Durability:',
      '32',
      'Req Level:',
      '39',
      '1-Handed',
      '150% Enhanced Damage',
      '+1 per character level to maximum Damage',
      '+1.5 per character level to % Deadly Strike',
      'Prevent Monster Heal',
      '+15 to Strength',
    ],
    category: 'Exceptional Unique Swords',
    id: 312,
    filename: 'broadsword.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Plague Bearer',
    type: 'Rune Sword',
    image: 'http://www.d2tomb.com/images/items/longsword.gif',
    props: [
      'Req Strength:',
      '103',
      'Req Dexterity:',
      '79',
      'Damage:',
      '35-150',
      'Durability:',
      '44',
      'Req Level:',
      '41',
      '1-Handed',
      '150% Enhanced Damage',
      'Adds 10-45 Damage',
      '+300 Poison Damage over 8 seconds',
      '5% chance to cast level 4 Poison Nova on striking',
      '+45% Poison Resist',
      '+5 to Rabies (Druid only)',
    ],
    category: 'Exceptional Unique Swords',
    id: 313,
    filename: 'longsword.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Atlantean',
    type: 'Ancient Sword',
    image: 'http://www.d2tomb.com/images/items/warsword.gif',
    props: [
      'Req Strength:',
      '127',
      'Req Dexterity:',
      '88',
      'Damage:',
      '54-150',
      'Durability:',
      '144',
      'Req Level:',
      '42',
      '1-Handed',
      '+200-250% Enhanced Damage',
      '+2 to Paladin Skill Levels',
      '50% Bonus to Attack Rating',
      '+75 Defense',
      '+8 to Vitality',
      '+12 to Dexterity',
      '+16 to Strength',
    ],
    category: 'Exceptional Unique Swords',
    id: 314,
    filename: 'warsword.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Crainte Vomir',
    type: 'Espandon',
    image: 'http://www.d2tomb.com/images/items/twohandedsword.gif',
    props: [
      'Req Strength:',
      '73',
      'Req Dexterity:',
      '61',
      'Damage:',
      '46-120',
      'Durability:',
      '44',
      'Req Level:',
      '42',
      '2-Handed',
      '+160-200% Enhanced Damage',
      '50% Increased Attack Speed',
      'Slows Target by 35%',
      '-70 to Monster Defense per hit',
      '20% Faster Run/Walk',
      'Damage reduced by 10%',
    ],
    category: 'Exceptional Unique Swords',
    id: 315,
    filename: 'twohandedsword.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Bing Sz Wang',
    type: 'Dacian Falx',
    image: 'http://www.d2tomb.com/images/items/claymore.gif',
    props: [
      'Req Strength:',
      '64',
      'Req Dexterity:',
      '14',
      'Damage:',
      '59-158',
      'Durability:',
      '50',
      'Req Level:',
      '43',
      '2-Handed',
      '+130-160% Enhanced Damage',
      'Adds 50-140 Cold Damage for 3 seconds',
      '5% chance to cast level 3 Frozen Orb on striking',
      '+2 Freezes Target',
      '-30% Requirements',
      '+20 to Strength',
    ],
    category: 'Exceptional Unique Swords',
    id: 316,
    filename: 'claymore.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Vile Husk',
    type: 'Tusk Sword',
    image: 'http://www.d2tomb.com/images/items/giantsword.gif',
    props: [
      'Req Strength:',
      '104',
      'Req Dexterity:',
      '71',
      'Damage:',
      '47-174',
      'Durability:',
      '50',
      'Req Level:',
      '44',
      '2-Handed',
      '+150-200% Enhanced Damage',
      '+7.5 per character level to % Damage to Undead',
      '+10 per character level to Attack Rating',
      '+250 Poison Damage over 6 seconds',
      '6% chance to cast level 1 Amplify Damage on striking',
      '+50% Poison Resist',
    ],
    category: 'Exceptional Unique Swords',
    id: 317,
    filename: 'kinemilsawl.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Cloudcrack',
    type: 'Gothic Sword',
    image: 'http://www.d2tomb.com/images/items/bastardsword.gif',
    props: [
      'Req Strength:',
      '113',
      'Req Dexterity:',
      '20',
      'Damage:',
      '97-180',
      'Durability:',
      '40',
      'Req Level:',
      '45',
      '2-Handed',
      '+150-200% Enhanced Damage',
      '6% chance to cast Level 7 Fist of Heavens on striking',
      'Adds 1-240 Lightning Damage',
      '10% to maximum Lightning Resist',
      '+30 to Defense',
      'Attacker takes Lightning Damage of 15',
      '+2 to Defensive Auras (Paladin only)',
      '+2 to Offensive Auras (Paladin only)',
      '+2 to Light Radius',
    ],
    category: 'Exceptional Unique Swords',
    id: 318,
    filename: 'blacktongue.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Todesfaelle Flamme',
    type: 'Zweihander',
    image: 'http://www.d2tomb.com/images/items/flamberge.gif',
    props: [
      'Req Strength:',
      '125',
      'Req Dexterity:',
      '94',
      'Damage:',
      '63-140',
      'Durability:',
      '50',
      'Req Level:',
      '46',
      '2-Handed',
      '+120-160% Enhanced Damage',
      'Adds 50-200 Fire Damage',
      '10% chance to cast level 6 Fire Ball on attack',
      '20 charges level 10 Fire Wall',
      '45 charges level 10 Enchant',
      '+10 Fire Absorb',
      '+40% Fire Resist',
    ],
    category: 'Exceptional Unique Swords',
    id: 319,
    filename: 'todesfaelleflamme.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Swordguard',
    type: 'Executioner Sword',
    image: 'http://www.d2tomb.com/images/items/greatsword.gif',
    props: [
      'Req Strength:',
      '85',
      'Req Dexterity:',
      '55',
      'Damage:',
      '126-224',
      'Durability:',
      '50',
      'Req Level:',
      '48',
      '2-Handed',
      '+170-180% Enhanced Damage',
      '+5 per character level to Defense',
      '30% Damage taken goes to Mana',
      '-50% Requirements',
      '+10-20 to All Resistances',
      '20% Faster Hit Recovery',
      '20% Increased chance of Blocking',
      '+100 Defense against Missles',
      '+200 Defense against Melee',
    ],
    category: 'Exceptional Unique Swords',
    id: 320,
    filename: 'swordguard.gif',
    category_id: 28,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Djinn Slayer',
    type: 'Ataghan',
    image: 'http://www.d2tomb.com/images/items/scimitar.gif',
    props: [
      'Req Strength:',
      '138',
      'Req Dexterity:',
      '95',
      'Damage:',
      '75-156',
      'Durability:',
      '22',
      'Sockets:',
      '1-2',
      'Req Level:',
      '65',
      '1-Handed',
      '+190-240% Enhanced Damage',
      '+100-150% Damage to Demons',
      'Adds 250-500 Fire Damage',
      '3-6% Mana Stolen per Hit',
      '+3-7 Lightning Absorb',
      '+200-300 to Attack Rating vs. Demons',
    ],
    category: 'Elite Unique Swords',
    id: 321,
    filename: 'scimitar.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Bloodmoon',
    type: 'Elegant Blade',
    image: 'http://www.d2tomb.com/images/items/saber.gif',
    props: [
      'Req Strength:',
      '109',
      'Req Dexterity:',
      '122',
      'Damage:',
      '102-162',
      'Durability:',
      '32',
      'Req Level:',
      '61',
      '1-Handed',
      '+210-260% Enhanced Damage',
      '10-15% Life Stolen per Hit',
      '50% Chance of Open Wounds',
      '+7-13 Life after each Kill',
      '9 Charges Level 15 Blood Golem',
    ],
    category: 'Elite Unique Swords',
    id: 322,
    filename: 'krintizskewer.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Azurewrath',
    type: 'Phase Blade',
    image: 'http://www.d2tomb.com/images/items/crystalsword.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '136',
      'Damage:',
      '102-129',
      'Indestructible',
      'Req Level:',
      '85',
      '1-Handed',
      '+230-270% Enhanced Damage',
      '+30% Increased Attack Speed',
      'Adds 250-500 Magic Damage',
      'Adds 250-500 Cold Damage for 10 sec',
      '+1 to all Skills',
      '+5-10 to all Attributes',
      'Level 10-13 Sanctuary Aura when equipped',
      '+3 to Light Radius',
    ],
    category: 'Elite Unique Swords',
    id: 323,
    filename: 'azurewrath2.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Lightsabre',
    type: 'Phase Blade',
    image: 'http://www.d2tomb.com/images/uniques/azurewrath.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '136',
      'Damage:',
      '87-135',
      'Durability:',
      ' Indestructible',
      'Req Level:',
      '58',
      '1-Handed',
      '+150-200% Enhanced Damage',
      '5% chance to cast level 14-20 Chain Lightning on attack',
      '20% increased Attack Speed',
      "Ignore Target's Defense",
      '+7 to Light Radius',
      '25% Lightning Absorb',
      'Adds 10-30 Damage',
      'Adds 60-120 Magic Damage',
      'Adds 1-200 Lightning Damage',
      '5-7% Mana stolen per hit',
    ],
    category: 'Elite Unique Swords',
    id: 324,
    filename: 'azurewrath.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Frostwind',
    type: 'Cryptic Sword',
    image: 'http://www.d2tomb.com/images/items/longsword.gif',
    props: [
      'Req Strength:',
      '99',
      'Req Dexterity:',
      '109',
      'Damage:',
      '14-254',
      'Durability:',
      '44',
      'Req Level:',
      '70',
      '1-Handed',
      '+180-230% Enhanced Damage',
      'Adds 237-486 Cold Damage for 6 sec',
      '+7-14 to Arctic Blast',
      '+4 to Freezes Target',
      '7-15% Cold Absorb',
      'Half Freeze Duration',
      '+25% Increased Attack Speed',
    ],
    category: 'Elite Unique Swords',
    id: 325,
    filename: 'longsword.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Flamebellow',
    type: 'Balrog Blade',
    image: 'http://www.d2tomb.com/images/items/giantsword.gif',
    props: [
      'Req Strength:',
      '185',
      'Req Dexterity:',
      '87',
      'Damage:',
      '148-401',
      'Durability:',
      '50',
      'Req Level:',
      '71',
      '2-Handed',
      '+170-240% Enhanced Damage',
      'Adds 233-482 Fire Damage',
      '+3 to Fire Skills',
      '+12-18 to Inferno',
      '+10-20 to Strength',
      '+5-10 to Vitality',
      '12% Chance to cast level 16 Firestorm on striking',
      '20-30% Fire Absorb',
    ],
    category: 'Elite Unique Swords',
    id: 326,
    filename: 'flamebellow.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Doombringer',
    type: 'Champion Sword',
    image: 'http://www.d2tomb.com/images/uniques/blacktongue.gif',
    props: [
      'Req Strength:',
      '163',
      'Req Dexterity:',
      '103',
      'Damage:',
      '228-390',
      'Durability:',
      'Indestructible',
      'Req Level:',
      '69',
      '2-Handed',
      '+180-250% Enhanced Damage',
      'Adds 30-100 Damage',
      '8% chance to cast level 3 Weaken on striking',
      '40% bonus to Attack Rating',
      '5-7% Life stolen per hit',
      '20% increased maximum Life',
    ],
    category: 'Elite Unique Swords',
    id: 327,
    filename: 'blacktongue.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'The Grandfather',
    type: 'Colossus Blade',
    image: 'http://www.d2tomb.com/images/uniques/thepatriarch.gif',
    props: [
      'Req Strength:',
      '189',
      'Req Dexterity:',
      '110',
      'Damage:',
      '145-649',
      'Durability:',
      'Indestructible',
      'Req Level:',
      '81',
      '2-Handed',
      '+150-250% Enhanced Damage',
      '+(2.5 per character level) to maximum Damage',
      '50 % bonus to Attack Rating',
      '+80 to Life',
      '+20 to Dexterity, Strength, Vitality, and Energy',
    ],
    category: 'Elite Unique Swords',
    id: 328,
    filename: 'thegrandfather.gif',
    category_id: 28,
    quality_id: 2,
    item_type: 'unique',
  },
  // {
  //     name: 'Accuracy of Heaven',
  //     type: 'Throwing Knife',
  //     image: 'http://www.d2tomb.com/images/items/throwingknife.gif',
  //     props: [
  //         'Throw Damage:',
  //         '6-14',
  //         'OneHand Damage:',
  //         '1-20',
  //         'Quantity: 220',
  //         'Required Dexterity: 51',
  //         'Required Level:',
  //         '21',
  //         '+50% Increased Attack Speed',
  //         '+36% Enhanced Maximum Damage (based on char lvl)',
  //         'Adds 20-36 Cold Damage',
  //         '+1 to Double Throw',
  //         'Increased Stack Size',
  //         'Replenishes Quantity'
  //     ],
  //     category: 'Unique Throwing Weapons',
  //     id: 329,
  //     filename: 'throwingknife.gif',
  //     category_id: 29,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  // {
  //     name: 'Smoke Rift',
  //     type: 'Balanced Axe',
  //     image: 'http://www.d2tomb.com/images/items/balancedaxe.gif',
  //     props: [
  //         'Throw Damage:',
  //         '71-88',
  //         'OneHand Damage:',
  //         '29-59',
  //         'Quantity: 140',
  //         'Required Dexterity: 57',
  //         'Required Level:',
  //         '22',
  //         '+493% Enhanced Damage',
  //         'Adds 1-20 Magic Damage',
  //         'Adds 1-20 Fire Damage',
  //         'Adds 1-20 Lightning Damage',
  //         'Adds 10-20 Cold Damage',
  //         'Increased Stack Size'
  //     ],
  //     category: 'Unique Throwing Weapons',
  //     id: 330,
  //     filename: 'balancedaxe.gif',
  //     category_id: 29,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  // {
  //     name: 'Star Venom',
  //     type: 'Throwing Knife',
  //     image: 'http://www.d2tomb.com/images/items/throwingknife.gif',
  //     props: [
  //         'Throw Damage:',
  //         '18-36',
  //         'OneHand Damage:',
  //         '16-30',
  //         'Quantity: 52',
  //         'Required Dexterity: 21',
  //         'Required Level:',
  //         '3',
  //         '+25% Increased Attack Speed',
  //         'Adds 14-27 Damage',
  //         'Adds 22-33 Cold Damage',
  //         '+19 Poison Damage over 6 seconds'
  //     ],
  //     category: 'Unique Throwing Weapons',
  //     id: 331,
  //     filename: 'throwingknife.gif',
  //     category_id: 29,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  // {
  //     name: 'Stone Splitter',
  //     type: 'Balanced Axe',
  //     image: 'http://www.d2tomb.com/images/items/balancedaxe.gif',
  //     props: [
  //         'Throw Damage:',
  //         '23-34',
  //         'OneHand Damage:',
  //         '11-20',
  //         'Quantity: 160',
  //         'Required Dexterity: 40',
  //         'Required Level:',
  //         '6',
  //         '+1 to all Skills',
  //         '+40% Increased Attack Speed',
  //         '+190% Enhanced Damage',
  //         'Damage +27',
  //         'Adds 7-14 Magic Damage',
  //         'Increased Stack Size',
  //         'Replenishes Quantity'
  //     ],
  //     category: 'Unique Throwing Weapons',
  //     id: 332,
  //     filename: 'balancedaxe.gif',
  //     category_id: 29,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  {
    name: 'Deathbit',
    type: 'Battle Dart',
    image: 'http://www.d2tomb.com/images/items/throwingknife.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '52',
      'Throw Damage:',
      '25-67',
      'Damage:',
      '18-44',
      'Req Level:',
      '44',
      'Max Stack:',
      '160',
      '+130-180% Enhanced Damage',
      '40% Deadly Strike',
      '+200-450 to Attack Rating',
      '7-9% Life stolen per hit',
      '4-6% Mana stolen per hit',
      'Replenishes quantity (1 in 4 sec)',
    ],
    category: 'Exceptional Unique Throwing Weapons',
    id: 333,
    filename: 'throwingknife.gif',
    category_id: 29,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'The Scalper',
    type: 'Francisca',
    image: 'http://www.d2tomb.com/images/items/balancedaxe.gif',
    props: [
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '80',
      'Throw Damage:',
      '45-99',
      'Damage:',
      '27-66',
      'Req Level:',
      '57',
      'Max Stack:',
      '130',
      '+150-200% Enhanced Damage',
      '33% Chance of Open Wounds',
      '+4 Mana after each kill',
      '20% increased Attack Speed',
      '25% bonus to Attack Rating',
      '4-6% Life stolen per hit',
      'Replenishes quantity (1 in 3 sec)',
    ],
    category: 'Exceptional Unique Throwing Weapons',
    id: 334,
    filename: 'throwingaxe.gif',
    category_id: 29,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Warshrike',
    type: 'Winged Knife',
    image: 'http://www.d2tomb.com/images/items/balancedknife.gif',
    props: [
      'Req Strength:',
      '45',
      'Req Dexterity:',
      '142',
      'Throw Damage:',
      '69-136',
      'Damage:',
      '81-122',
      'Req Level:',
      '75',
      'Max Stack:',
      '200',
      '+200-250% Enhanced Damage',
      '50% Deadly Strike',
      '+30% Increased Attack Speed',
      'Piercing Attack',
      '25% Chance to cast Level 9 Nova on striking',
      'Replenishes Quantity (1 in 3 sec)',
    ],
    category: 'Elite Unique Throwing Weapons',
    id: 335,
    filename: 'warshrike.gif',
    category_id: 29,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Gimmershred',
    type: 'Flying Axe',
    image: 'http://www.d2tomb.com/images/items/throwingaxe.gif',
    props: [
      'Req Strength:',
      '88',
      'Req Dexterity:',
      '108',
      'Throw Damage:',
      '39-204',
      'Damage:',
      '44-201',
      'Req Level:',
      '70',
      'Max Stack:',
      '240',
      '+160-210% Enhanced Damage',
      'Adds 218-483 Fire Damage',
      'Adds 29-501 Lightning Damage',
      'Adds 176-397 Cold Damage',
      '+30% Increased Attack Speed',
      'Increased Stack Size',
    ],
    category: 'Elite Unique Throwing Weapons',
    id: 336,
    filename: 'throwingaxe.gif',
    category_id: 29,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Lacerator',
    type: 'Winged Axe',
    image: 'http://www.d2tomb.com/images/items/balancedaxe.gif',
    props: [
      'Req Strength:',
      '96',
      'Req Dexterity:',
      '122',
      'Throw Damage:',
      '17-186',
      'Damage:',
      '27-173',
      'Req Level:',
      '68',
      'Max Stack:',
      '180',
      '+150-210% Enhanced Damage',
      '+30% Increased Attack Speed',
      'Prevent Monster Heal',
      '50% Hit Causes Monster to Flee',
      '33% Chance to cast Level 3 Amplify Damage on striking',
      '33% chance of Open Wounds',
      'Replenishes Quantity (1 in 4 sec)',
    ],
    category: 'Elite Unique Throwing Weapons',
    id: 337,
    filename: 'balancedaxe.gif',
    category_id: 29,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Torch of Iro',
    type: 'Wand',
    image: 'http://www.d2tomb.com/images/uniques/torchofiro.gif',
    props: [
      'Damage:',
      '2-4',
      'Durability:',
      '15',
      'Req Level:',
      '5',
      '+1 to Necromancer Skill Levels',
      '6% Life stolen per hit',
      'Adds 5-9 Fire Damage',
      '+50% Damage vs. Undead',
      '+10 to Energy',
      '5% Regenerate Mana',
      '+3 to Light Radius',
    ],
    category: 'Unique Wands',
    id: 338,
    filename: 'torchofiro.gif',
    category_id: 30,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Maelstrom',
    type: 'Yew Wand',
    image: 'http://www.d2tomb.com/images/uniques/maelstrom.gif',
    props: [
      'Damage:',
      '2-8',
      'Durability:',
      '15',
      'Req Level:',
      '14',
      '30% Faster Cast Rate',
      '+40% Lightning Resist',
      'Adds 1-9 Lightning Damage',
      '+13 to Mana',
      '+50% Damage vs. Undead',
      '+1-3 to Iron Maiden (Necro only)',
      '+1-3 to Amplify Damage (Necro only)',
      '+1-3 to Terror (Necro only)',
      '+1-3 to Corpse Explosion (Necro only)',
    ],
    category: 'Unique Wands',
    id: 339,
    filename: 'maelstrom.gif',
    category_id: 30,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Gravenspine',
    type: 'Bone Wand',
    image: 'http://www.d2tomb.com/images/uniques/gravenspine.gif',
    props: [
      'Damage:',
      '3-7',
      'Durabiilty: 15',
      'Req Level:',
      '20',
      '5% Mana Stolen per hit',
      'Adds 4-8 Cold Damage for 3 sec',
      '+10 to Dexterity',
      '+10 to Strength',
      '+25-50 Mana',
      '+50% Damage vs. Undead',
      '+2 to Necromancer Skill Levels',
    ],
    category: 'Unique Wands',
    id: 340,
    filename: 'gravenspine.gif',
    category_id: 30,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Ume's Lament",
    type: 'Grim Wand',
    image: 'http://www.d2tomb.com/images/uniques/umeslament.gif',
    props: [
      'Damage:',
      '5-11',
      'Durability:',
      '15',
      'Req Level:',
      '28',
      '50% Hit causes monster to flee',
      '20% Faster Cast Rate',
      '+2 to Necromancer Skill Levels',
      '+40 to Mana',
      '+50% Damage vs. Undead',
      '+2 to Decrepify (Necro only)',
      '+3 to Terror (Necro only)',
    ],
    category: 'Unique Wands',
    id: 341,
    filename: 'umeslament.gif',
    category_id: 30,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Suicide Branch',
    type: 'Burnt Wand',
    image: 'http://www.d2tomb.com/images/items/wand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '8-18',
      'Durability:',
      '15',
      'Req Level:',
      '33',
      '+50% Damage to Undead',
      '+1 to All Skills',
      '50% Faster Cast Rate',
      '10% increase maximum Mana',
      '+10 to all Resistances',
      '+40 to Life',
      'Attacker takes Damage of 25',
    ],
    category: 'Exceptional Unique Wands',
    id: 342,
    filename: 'wand.gif',
    category_id: 30,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Carin Shard',
    type: 'Petrified Wand',
    image: 'http://www.d2tomb.com/images/items/yewwand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '8-24',
      'Durability:',
      '15',
      'Req Level:',
      '35',
      '+50% Damage to Undead',
      '+1.25 per character level to Mana',
      '+1.25 per character level to Life',
      '+1 to Necromancer Skill Levels',
      '+2 to Summoning Skills (Necro only)',
      '10% Faster Cast Rate',
      '30% Faster Hit Recovery',
      '+5 Replenish Life',
    ],
    category: 'Exceptional Unique Wands',
    id: 343,
    filename: 'maelstrom.gif',
    category_id: 30,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Arm of King Leoric',
    type: 'Tomb Wand',
    image: 'http://www.d2tomb.com/images/items/bonewand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '10-22',
      'Durability:',
      '15',
      'Req Level:',
      '36',
      '+50% Damage to Undead',
      '10% chance to cast level 2 Bone Prison when struck',
      '5% chance to cast level 10 Bone Spirit when struck',
      '+1.25 per character level to Mana',
      '10% Faster Cast Rate',
      '+2 to Terror (Necro only)',
      '+2 to Raise Skeletal Mage (Necro only)',
      '+3 to Skeleton Mastery (Necro only)',
      '+3 to Raise Skeleton (Necro only)',
      '+2 to Summoning Skills (Necro only)',
      '+2 to Poison and Bone Skills (Necro only)',
    ],
    category: 'Exceptional Unique Wands',
    id: 344,
    filename: 'gravenspine.gif',
    category_id: 30,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Blackhand Key',
    type: 'Grave Wand',
    image: 'http://www.d2tomb.com/images/items/grimwand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '13-29',
      'Durability:',
      '15',
      'Req Level:',
      '41',
      '+50% Damage to Undead',
      '+2 to Necromancer Skill Levels',
      '+1 to Curses (Necro only)',
      '20% Damage take goes to Mana',
      '30% Faster Cast Rate',
      '+37% Fire Resist',
      '+50 to Life',
      '30 charges level 13 Grim Ward',
      '-2 to Light Radius',
    ],
    category: 'Exceptional Unique Wands',
    id: 345,
    filename: 'blackhandkey.gif',
    category_id: 30,
    quality_id: 1,
    item_type: 'unique',
  },
  {
    name: 'Boneshade',
    type: 'Lich Wand',
    image: 'http://www.d2tomb.com/images/items/bonewand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '10-31',
      'Durability:',
      '17',
      'Req Level:',
      '79',
      '+50% Damage to Undead',
      '+2 to Necromancer Skill Levels',
      '+1-2 to Bone Spirit (Necro only)',
      '+2-3 to Bone Spear (Necro only)',
      '+2-3 to Bone Wall (Necro only)',
      '+4-5 to Bone Armor (Necro only)',
      '+4-5 to Teeth (Necro only)',
      '25% Faster Cast Rate',
    ],
    category: 'Elite Unique Wands',
    id: 346,
    filename: 'bonewand.gif',
    category_id: 30,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: "Death's Web",
    type: 'Unearthed Wand',
    image: 'http://www.d2tomb.com/images/items/grimwand.gif',
    props: [
      'Req Strength:',
      '25',
      'Damage:',
      '22-28',
      'Durability:',
      '18',
      'Req Level:',
      '66',
      '50% Damage to Undead',
      '-40-50% to Enemy Poison Resistance',
      '+7-12 to Mana after each Kill',
      '+7-12 Life after each Kill',
      '+2 to all Skills',
      '+1-2 to Poison and Bone Skills (Necro only)',
    ],
    category: 'Elite Unique Wands',
    id: 347,
    filename: 'deathsweb.gif',
    category_id: 30,
    quality_id: 2,
    item_type: 'unique',
  },
  {
    name: 'Stoneraven',
    type: 'Matriarchal Spear',
    image: 'http://www.d2tomb.com/images/items/maidenspear.gif',
    props: [
      'Damage:',
      '214-361',
      'Req Strength:',
      '114',
      'Req Dexterity:',
      '142',
      'Durability:',
      '28',
      'Req Level:',
      '64',
      '+230-280% Enhanced Damage',
      'Adds 101-187 Magic Damage',
      '+400-600 Defense',
      '+30-50 to all Resistances',
      '+1-3 to Javelin and Spear Skills (Amazon only)',
    ],
    category: 'Unique Amazon Javelins',
    id: 348,
    filename: 'maidenspear.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Lycander's Flank",
    type: 'Ceremonial Pike',
    image: 'http://www.d2tomb.com/images/items/lycanders_flank.gif',
    props: [
      'Damage:',
      '130-353',
      'Req Strength:',
      '115',
      'Req Dexterity:',
      '98',
      'Durability:',
      '25',
      'Req Level:',
      '42',
      '+150-200% Enhanced Damage',
      'Adds 25-50 Damage',
      '+2 to Amazon Skills Levels',
      '30% increased Attack Speed',
      '5-9% Life stolen per hit',
      '+20 to Strength and Vitality',
      '+20% Enhanced Defense',
      '+2 to Javelin and Spear Skills',
    ],
    category: 'Unique Amazon Javelins',
    id: 349,
    filename: 'lycanders_flank.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Lycander's Aim",
    type: 'Ceremonial Bow',
    image: 'http://www.d2tomb.com/images/items/lycanders_aim.gif',
    props: [
      'Damage:',
      '72-173',
      'Req Strength:',
      '73',
      'Req Dexterity:',
      '110',
      'Req Level:',
      '42',
      '+150-200% Enhanced damage',
      'Adds 25-50 Damage',
      '+2 to Amazon Skill Levels',
      '20% increased Attack Speed',
      '5-8% Mana stolen per hit',
      '+20 to Energy and Dexterity',
      '+20% Enhanced Defense',
      '+2 to Bow and Crossbow Skills',
    ],
    category: 'Unique Amazon Bows',
    id: 350,
    filename: 'lycanders_aim.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Titan's Revenge",
    type: 'Ceremonial Javelin',
    image: 'http://www.d2tomb.com/images/items/titans_revenge.gif',
    props: [
      'Damage:',
      '70-155',
      'Throw Damage:',
      '70-212',
      'Req Strength:',
      '25',
      'Req Dexterity:',
      '109',
      'Max Stack:',
      '140',
      'Req Level:',
      '42',
      'Replenishes 1 quantity in 3 seconds',
      '+2 to Amazon Skill Levels',
      '+150-200% Enhanced Damage',
      'Adds 25-50 Damage',
      '5-9% Life stolen per hit',
      '30% Faster Run/Walk',
      '+20 to Strength and Dexterity',
      '+2 to Javelin and Spear Skills',
    ],
    category: 'Unique Amazon Javelins',
    id: 351,
    filename: 'titans_revenge.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Thunderstroke',
    type: 'Matriarchal Javelin',
    image: 'http://www.d2tomb.com/images/items/maidenjavelin.gif',
    props: [
      'Damage:',
      '75-162',
      'Throw Damage:',
      '87-198',
      'Req Strength:',
      '107',
      'Req Dexterity:',
      '151',
      'Req Level:',
      '69',
      '+150-200% Enhanced Damage',
      'Adds 1-511 Lightning Damage',
      '15% Increased Attack Speed',
      '-15% to Enemy Lightning Resistance',
      '20% Chance to cast Level 14 Lightning on striking',
      '+2-4 to Javelin and Spear Skills (Amazon only)',
      '+3 to Lightning Bolt (Amazon only)',
    ],
    category: 'Unique Amazon Javelins',
    id: 352,
    filename: 'maidenjavelin.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Blood Raven's Charge",
    type: 'Matriarchal Bow',
    image: 'http://www.d2tomb.com/images/items/stagbow.gif',
    props: [
      'Damage:',
      '56-155',
      'Req Strength:',
      '87',
      'Req Dexterity:',
      '187',
      'Req Level:',
      '71',
      '+180-230% Enhanced Damage',
      '200-300% Bonus to Attack Rating',
      'Fires level 13 Explosive Arrows',
      '30 Charges Level 5 Revive',
      '+2-4 to Bow and Crossbow Skills (Amazon only)',
    ],
    category: 'Unique Amazon Bows',
    id: 353,
    filename: 'stagbow.gif',
    category_id: 0,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Shadow Killer',
    type: 'Battle Cestus',
    image: 'http://www.d2tomb.com/images/items/hatchethands.gif',
    props: [
      'Damage:',
      '145-201',
      'Indestructible',
      'Req Strength:',
      '100',
      'Req Dexterity:',
      '100',
      'Req Level:',
      '78',
      '+170-220% Enhanced Damage',
      "-25% Target's Defense",
      '+2 Freezes Target',
      '+10-15 Mana after each Kill',
      '33% Chance to Cast Level 8 Frost Nova on striking',
      'Ethereal',
    ],
    category: 'Unique Assassin Katars',
    id: 354,
    filename: 'hatchethands.gif',
    category_id: 1,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Bartuc's Cut-Throat",
    type: 'Greater Talons',
    image: 'http://www.d2tomb.com/images/items/bartucs_cutthroat.gif',
    props: [
      'Damage:',
      '77-155',
      'Durability:',
      '69',
      'Req Strength:',
      '79',
      'Req Dexterity:',
      '79',
      'Req Level:',
      '42',
      '+150-200% Enhanced Damage',
      'Adds 25-50 Damage',
      '30% Faster Hit Recovery',
      '20% bonus to Attack Rating',
      '5-9% Life stolen per hit',
      '+20 to Strength and Dexterity',
      '+2 to Assassin Skill Levels',
      '+1 to Martial Arts Skills',
    ],
    category: 'Unique Assassin Katars',
    id: 355,
    filename: 'bartucs_cutthroat.gif',
    category_id: 1,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Jade Talon',
    type: 'Wrist Sword',
    image: 'http://www.d2tomb.com/images/items/katar.gif',
    props: [
      'Damage:',
      '98-153',
      'Durability:',
      '56',
      'Req Strength:',
      '105',
      'Req Dexterity:',
      '105',
      'Req Level:',
      '66',
      '+190-240% Enhanced Damage',
      '+1-2 to Martial Arts (Assassin only)',
      '+1-2 to Shadow Disciplines (Assassin only)',
      '+30% Faster Hit Recovery',
      '10-15% Mana Stolen per Hit',
      '+40-50 to all Resistances',
    ],
    category: 'Unique Assassin Katars',
    id: 356,
    filename: 'katar.gif',
    category_id: 1,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Firelizard's Talons",
    type: 'Feral Claws',
    image: 'http://www.d2tomb.com/images/items/claws.gif',
    props: [
      'Damage:',
      '66-196',
      'Durability:',
      '52',
      'Req Strength:',
      '113',
      'Req Dexterity:',
      '113',
      'Req Level:',
      '67',
      '+200-270% Enhanced Damage',
      'Adds 236-480 Fire Damage',
      '+15% Increased Attack Speed',
      '+1-3 to Martial Arts (Assassin only)',
      '+1-2 to Wake of Inferno (Assassin only)',
      '+1-2 to Wake of Fire (Assassin only)',
      '+40-70% Fire Resist',
    ],
    category: 'Unique Assassin Katars',
    id: 357,
    filename: 'claws.gif',
    category_id: 1,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Arreat's Face",
    type: 'Slayer Guard',
    image: 'http://www.d2tomb.com/images/items/arreats_face.gif',
    props: [
      'Defense:',
      '302-363',
      'Durability:',
      '55',
      'Req Strength:',
      '118',
      'Req Level:',
      '42',
      '+150-200% Enhanced Defense',
      '30% Faster Hit Recovery',
      '20% Bonus to Attack Rating',
      '+2 to Barbarian Skill Levels',
      '3-6% Life stolen per hit',
      '+30 to all Resistances',
      '+20 to Strength and Dexterity',
      '+2 to Combat Skills',
    ],
    category: 'Unique Barbarian Helms',
    id: 358,
    filename: 'arreats_face.gif',
    category_id: 2,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Wolfhowl',
    type: 'Fury Visor',
    image: 'http://www.d2tomb.com/images/items/fangedhelm.gif',
    props: [
      'Defense:',
      '332-377',
      'Durability:',
      '35',
      'Req Strength:',
      '129',
      'Req Level:',
      '79',
      '+120-150% Enhanced Defense',
      '+2-3 to Warcries (Barbarian only)',
      '+3-6 to Feral Rage',
      '+3-6 to Lycanthropy',
      '+3-6 to Werewolf',
      '+8-15 to Strength',
      '+8-15 to Dexterity',
      '+8-15 to Vitality',
      '18 Charges Level 15 Summon Dire Wolf',
    ],
    category: 'Unique Barbarian Helms',
    id: 359,
    filename: 'fangedhelm.gif',
    category_id: 2,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Demonhorn's Edge",
    type: 'Destroyer Helm',
    image: 'http://www.d2tomb.com/images/items/hornedhelm.gif',
    props: [
      'Defense:',
      '345-408',
      'Durability:',
      '45',
      'Req Strength:',
      '151',
      'Req Level:',
      '61',
      '+120-160% Enhanced Defense',
      '10% Increased Attack Speed',
      '3-6% Life Stolen per Hit',
      'Attacker takes Damage of 55-77',
      '+1-3 to Warcries (Barbarian only)',
      '+1-3 to Masteries (Barbarian only)',
      '+1-3 to Combat Skills (Barbarian only)',
    ],
    category: 'Unique Barbarian Helms',
    id: 360,
    filename: 'hornedhelm.gif',
    category_id: 2,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Halaberd's Reign",
    type: 'Conqueror Crown',
    image: 'http://www.d2tomb.com/images/items/assaulthelm.gif',
    props: [
      'Defense:',
      '384-432',
      'Durability:',
      '50',
      'Req Strength:',
      '174',
      'Req Level:',
      '77',
      '+140-170% Enhanced Defense',
      '+15-23 Replenish Life',
      '+20% Faster Hit Recovery',
      '+2 to Barbarian Skill Levels',
      '+1 to Masteries (Barbarian only)',
      '+1-2 to Battle Commands (Barbarian only)',
      '+1-2 to Battle Orders (Barbarian only)',
    ],
    category: 'Unique Barbarian Helms',
    id: 361,
    filename: 'assaulthelm.gif',
    category_id: 2,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Jalal's Mane",
    type: 'Totemic Mask',
    image: 'http://www.d2tomb.com/images/items/ialals_mane.gif',
    props: [
      'Defense:',
      '247-297',
      'Durability:',
      '20',
      'Req Strength:',
      '65',
      'Req Level:',
      '42',
      '+150-200% Enhanced Defense',
      '30% Faster Hit Recovery',
      '20% bonus to Attack Rating',
      '+2 to Druid Skills',
      '+30 to all Resistances',
      '+5 to Mana after each kill',
      '+20 to Strength',
      '+2 to Shapeshifting Skills',
      '+20 to Energy',
    ],
    category: 'Unique Druid Pelts',
    id: 362,
    filename: 'ialals_mane.gif',
    category_id: 3,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Cerebus' Bite",
    type: 'Blood Spirit',
    image: 'http://www.d2tomb.com/images/items/wolfhead.gif',
    props: [
      'Defense:',
      '335-350',
      'Durability:',
      '20',
      'Req Strength:',
      '86',
      'Req Level:',
      '63',
      '+130-140% Enhanced Defense',
      '60-120% Bonus to Attack Rating',
      '7-10% Life Stolen per Hit',
      '33% Chance of Open Wounds',
      '+2-4 to Shape Shifting Skills (Druid only)',
      '+1-2 to Feral Rage (Druid only)',
    ],
    category: 'Unique Druid Pelts',
    id: 363,
    filename: 'wolfhead.gif',
    category_id: 3,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Spirit Keeper',
    type: 'Earth Spirit',
    image: 'http://www.d2tomb.com/images/items/antlers.gif',
    props: [
      'Defense:',
      '413-443',
      'Durability:',
      '20',
      'Req Strength:',
      '104',
      'Req Level:',
      '67',
      '+170-190% Enhanced Defense',
      '+1-2 to Druid Skills',
      '+20% Faster Hit Recovery',
      '+10% to Maximum Poison Resist',
      '+30-40% Fire Resist',
      '+9-14% Lightning Absorb',
      '15-25% Cold Absorb',
    ],
    category: 'Unique Druid Pelts',
    id: 364,
    filename: 'antlers.gif',
    category_id: 3,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Ravenlore',
    type: 'Sky Spirit',
    image: 'http://www.d2tomb.com/images/items/falconmask.gif',
    props: [
      'Defense:',
      '343-390',
      'Durability:',
      '20',
      'Req Strength:',
      '113',
      'Req Level:',
      '74',
      '+120-150% Enhanced Defense',
      '+7 to Raven (Druid only)',
      '+3 to Elemental Skills (Druid only)',
      '+20-30 to Energy',
      '-10-20% to Enemy Fire Resistance',
      '+15-25 to all Resistances',
    ],
    category: 'Unique Druid Pelts',
    id: 365,
    filename: 'falconmask.gif',
    category_id: 3,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Homunculus',
    type: 'Hierophant Trophy',
    image: 'http://www.d2tomb.com/images/items/homunculus.gif',
    props: [
      'Defense:',
      '177-213',
      'Durability:',
      '20',
      'Req Strength:',
      '58',
      'Chance to Block:',
      '72%',
      'Req Level:',
      '42',
      '+150-200% Enhanced Defense',
      '+5 Mana after each kill',
      '40% increased chance of Blocking',
      '30% Faster Block rate',
      '+2 to Necromancer Skill Levels',
      '+20 to Energy',
      '33% Regenerate Mana',
      '+40 to All Resistances',
      '+2 to Curses Skills',
    ],
    category: 'Unique Necromancer Shrunken Heads',
    id: 366,
    filename: 'homunculus.gif',
    category_id: 5,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Boneflame',
    type: 'Succubus Skull',
    image: 'http://www.d2tomb.com/images/items/gargoylehead.gif',
    props: [
      'Defense:',
      '323-367',
      'Durability:',
      '20',
      'Req Strength:',
      '95',
      'Chance to Block:',
      '30%',
      'Req Level:',
      '72',
      '+120-150% Enhanced Defense',
      '+20% Faster Run/Walk',
      '+2-3 to Necro Skill Levels',
      '+20-30 to all Resistances',
      '15% Chance to cast Level 3 Terror when struck',
    ],
    category: 'Unique Necromancer Shrunken Heads',
    id: 367,
    filename: 'gargoylehead.gif',
    category_id: 5,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Darkforce Spawn',
    type: 'Bloodlord Skull',
    image: 'http://www.d2tomb.com/images/items/demonhead.gif',
    props: [
      'Defense:',
      '357-417',
      'Durability:',
      '20',
      'Req Strength:',
      '106',
      'Chance to Block:',
      '32%',
      'Req Level:',
      '65',
      '+140-180% Enhanced Defense',
      '+1-3 to Summoning Skills (Necro only)',
      '+1-3 to Poison and Bone Skills (Necro only)',
      '+1-3 to Curses (Necro only)',
      '+30% Faster Cast Rate',
      '10% Increase Maximum Mana',
    ],
    category: 'Unique Necromancer Shrunken Heads',
    id: 368,
    filename: 'demonhead.gif',
    category_id: 5,
    quality_id: 0,
    item_type: 'unique',
  },
  // {
  //     name: 'Horadrim Skull',
  //     type: 'Unraveller Head',
  //     image: 'http://www.d2tomb.com/images/items/unravellerhead.gif',
  //     props: [
  //         'Defense:',
  //         '6',
  //         'Chance to Block:',
  //         '33%',
  //         'Durability:',
  //         '20',
  //         'Required Strength:',
  //         '18',
  //         'Required Level:',
  //         '29',
  //         '+2 to Necro Skill Levels',
  //         '10% Faster Block Rate',
  //         '+50 Defense vs Melee',
  //         '15% Regenerate Mana',
  //         '+25 to all Resistances',
  //         '2 Sockets'
  //     ],
  //     category: 'Unique Necromancer Shrunken Heads',
  //     id: 369,
  //     filename: 'unravellerhead.gif',
  //     category_id: 5,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  {
    name: 'Herald of Zakarum',
    type: 'Gilded Shield',
    image: 'http://www.d2tomb.com/images/items/herald_of_zakarum.gif',
    props: [
      'Defense:',
      '422-507',
      'Durability:',
      '50',
      'Req Strength:',
      '89',
      'Chance to Block:',
      '82%',
      'Req Level:',
      '42',
      '+150-200% Enhanced Defense',
      '30% Increased Chance of Blocking',
      '30% Faster Block Rate',
      '20% bonus to Attack Rating',
      '+20 to Strength and Vitality',
      '+50 to all Resistances',
      '+2 to Paladin Skill Levels',
      '+2 to Combat Skills',
    ],
    category: 'Unique Paladin Shields',
    id: 370,
    filename: 'herald_of_zakarum.gif',
    category_id: 4,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Alma Negra',
    type: 'Sacred Rondache',
    image: 'http://www.d2tomb.com/images/items/rondache.gif',
    props: [
      'Defense:',
      '461-511',
      'Durability:',
      '68',
      'Req Strength:',
      '109',
      'Chance to Block:',
      '78%',
      'Req Level:',
      '77',
      '+180-210% Enhanced Defense',
      '+1-2 to Paladin Skill Levels',
      '20% Increased Chance of Blocking',
      '+40-75% Enhanced Damage',
      '+40-75% Bonus to Attack Rating',
      'Magic Damage Reduced by 5-9',
      '30% Faster Block Rate',
    ],
    category: 'Unique Paladin Shields',
    id: 371,
    filename: 'rondache.gif',
    category_id: 4,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Dragonscale',
    type: 'Zakarum Shield',
    image: 'http://www.d2tomb.com/images/items/aerinshield.gif',
    props: [
      'Defense:',
      '523-582',
      'Durability:',
      '65',
      'Req Strength:',
      '142',
      'Chance to Block:',
      '52%',
      'Req Level:',
      '80',
      '+170-200% Enhanced Defense',
      'Adds 211-371 Fire Damage',
      '+15% to Fire Skill Damage',
      '+10 to Hydra',
      '+5% to Maximum Fire Resist',
      '+15-25 to Strength',
      '10-20% Fire Absorb',
    ],
    category: 'Unique Paladin Shields',
    id: 372,
    filename: 'aerinshield.gif',
    category_id: 4,
    quality_id: 0,
    item_type: 'unique',
  },
  // {
  //     name: 'Protector\'s Gleam',
  //     type: 'Targe',
  //     image: 'http://www.d2tomb.com/images/items/targe.gif',
  //     props: [
  //         'Defense:',
  //         '23',
  //         'Chance to Block:',
  //         '35%',
  //         'Durability:',
  //         '20',
  //         'Required Strength:',
  //         '16',
  //         'Required Level:',
  //         '3',
  //         '+1 to Paladin Skill Levels',
  //         '+20% Faster Block Rate',
  //         '+36% Enhanced Damage',
  //         '+80% Enhanced Defense',
  //         '+10 to all Resistances'
  //     ],
  //     category: 'Unique Paladin Shields',
  //     id: 373,
  //     filename: 'targe.gif',
  //     category_id: 4,
  //     quality_id: 0
  // item_type: 'unique',
  // },
  {
    name: 'The Oculus',
    type: 'Swirling Crystal',
    image: 'http://www.d2tomb.com/images/items/the_oculus.gif',
    props: [
      'Damage:',
      '18-42',
      'Durability:',
      '50',
      'Req Level:',
      '42',
      '+3 to Sorceress Skill Levels',
      '+5 to Mana after each kill',
      '+20 to all Resistances',
      '25% chance to cast level 1 Teleport when struck',
      '30% Faster Cast Rate',
      '+20 to Vitality and Energy',
      '+20% Enhanced Defense',
      '50% better chance of getting Magic Items',
    ],
    category: 'Unique Sorceress Orbs',
    id: 374,
    filename: 'the_oculus.gif',
    category_id: 6,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Eschuta's Temper",
    type: 'Eldritch Orb',
    image: 'http://www.d2tomb.com/images/items/sacredglobe.gif',
    props: [
      'Damage:',
      '18-50',
      'Durability:',
      '30',
      'Req Level:',
      '72',
      '+1-3 to Sorceress Skill Levels',
      '40% Faster Cast Rate',
      '+10-20% Fire Skill Damage',
      '+10-20% Lightning Skill Damage',
      '+20-30 to Energy',
    ],
    category: 'Unique Sorceress Orbs',
    id: 375,
    filename: 'sacredglobe.gif',
    category_id: 6,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Death's Fathom",
    type: 'Dimensional Shard',
    image: 'http://www.d2tomb.com/images/items/dragonstone.gif',
    props: [
      'Damage:',
      '30-53',
      'Durability:',
      '50',
      'Req Level:',
      '73',
      '+3 to all Sorceress Skill Levels',
      '+20% Faster Cast Rate',
      '+15-30% to Cold Skill Damage',
      '+25-40% Lightning Resist',
      '+25-40% Fire Resist',
    ],
    category: 'Unique Sorceress Orbs',
    id: 376,
    filename: 'dragonstone.gif',
    category_id: 6,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Annihilus',
    type: 'Small Charm',
    image: 'http://classic.battle.net/diablo2exp/images/items/charms/bluecharm.gif',
    props: [
      '+1 To All Skills',
      '+10-20 To All Attributes',
      'All Resistances +10-20',
      '+5-10% To Experience Gained',
      'Required Level:',
      '70',
    ],
    category: 'Unique Charms',
    id: 377,
    filename: 'bluecharm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: "Gheed's Fortune",
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: [
      '80-160% Extra Gold From Monsters',
      'Reduces All Vendor Prices 10-15%',
      '20-40% Better Chance of Getting Magic Items',
      'Required Level:',
      '62',
    ],
    category: 'Unique Charms',
    id: 378,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Hellfire Torch',
    type: 'Large Charm',
    image: 'http://classic.battle.net/diablo2exp/images/items/uniques/hellfiretorch.jpg',
    props: [
      '5% Chance to cast level 10 Firestorm on attack',
      '+3 to (Random Class) Skill Levels',
      '+10-20 to All Attributes',
      'All Resistances +10-20%',
      '+8 to Light Radius',
      'Level 30 Hydra (10 Charges)',
      'Required Level:',
      '75',
    ],
    category: 'Unique Charms',
    id: 379,
    filename: 'hellfiretorch.jpg',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rainbow Facet Cold',
    type: 'Jewel',
    image: 'https://vignette.wikia.nocookie.net/diablo/images/1/17/Jewelblue.gif',
    props: [
      '100% Chance to Cast Level 43 Frost Nova When You Level Up or 100% Chance to Cast Level 37 Blizzard When You Dien',
      'Adds 24-38 Cold Damage - 0.12 Second Duration',
      '+3-5% to Cold Skill Damage (varies)',
      '-3-5% to Enemy Cold Resistance (varies)',
      'Required Level: 49',
    ],
    category: 'Unique Jewels',
    id: 380,
    filename: 'Jewelblue.gif',
    category_id: 10,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rainbow Facet Fire',
    type: 'Jewel',
    image: 'https://vignette.wikia.nocookie.net/diablo/images/e/ef/Jewelred.gif',
    props: [
      '100% Chance to Cast Level 29 Blaze When You Level Up or 100% Chance to Cast Level 31 Meteor When You Die',
      'Adds 17-45 Fire Damage',
      '+3-5% to Fire Skill Damage (varies)',
      '-3-5% to Enemy Fire Resistance (varies)',
      'Required Level: 49',
    ],
    category: 'Unique Jewels',
    id: 381,
    filename: 'Jewelred.gif',
    category_id: 10,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rainbow Facet Lightning',
    type: 'Jewel',
    image: 'https://vignette.wikia.nocookie.net/diablo/images/6/6c/Jewelwhite.gif',
    props: [
      '100% Chance to Cast Level 41 Nova When You Level Up or 100% Chance to Cast Level 47 Chain Lightning When You Die',
      'Adds 1-74 Lightning Damage',
      '+3-5% to Lightning Skill Damage (varies)',
      '-3-5% to Enemy Lightning Resistance (varies)',
      'Required Level: 49',
    ],
    category: 'Unique Jewels',
    id: 382,
    filename: 'Jewelwhite.gif',
    category_id: 10,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rainbow Facet Poison',
    type: 'Jewel',
    image: 'https://vignette.wikia.nocookie.net/diablo/images/2/20/Jewelgreen.gif',
    props: [
      '100% Chance to Cast Level 23 Venom When You Level Up or 100% Chance to Cast Level 51 Poison Nova When You Die',
      '+37 Poison Damage Over 2 Seconds',
      '+3-5% to Poison Skill Damage (varies)',
      '-3-5% to Enemy Poison Resistance (varies)',
      'Required Level: 49',
    ],
    category: 'Unique Jewels',
    id: 383,
    filename: 'Jewelgreen.gif',
    category_id: 10,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Black Cleft',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Magic Immunity is Sundered', ' Magic Resist -45 to 65%'],
    category: 'Unique Charms',
    id: 384,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Bone Break',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Physical Immunity is Sundered', 'Damage Increased by 10 to 20%'],
    category: 'Unique Charms',
    id: 385,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Cold Rupture',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Cold Immunity is Sundered', 'Cold Resist -70 to 90%'],
    category: 'Unique Charms',
    id: 386,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Crack of the Heavens',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Lightning Immunity is Sundered', 'Lightning Resist -70 to 90%'],
    category: 'Unique Charms',
    id: 387,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Flame Rift',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Fire Immunity is Sundered', 'Fire Resist -70 to 90%'],
    category: 'Unique Charms',
    id: 388,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
  {
    name: 'Rotting Fissure',
    type: 'Grand Charm',
    image: 'http://classic.battle.net/images/battle/diablo2exp/images/items/charms/largecharm01.gif',
    props: ['Monster Poison Immunity is Sundered', 'Poison Resist -70 to 90%'],
    category: 'Unique Charms',
    id: 389,
    filename: 'grand_charm.gif',
    category_id: 9,
    quality_id: 0,
    item_type: 'unique',
  },
];

export const TCs: [string, number, number, number, number, number, number, number, number[], number, number?, number?][] = [
  ['abow78', 0, 0, 0, 0, 1, 0, 1, [1, 1, 239, 0], 0], // 0
  ['abow54', 0, 0, 0, 0, 1, 0, 1, [1, 1, 357, 0], 0], // 1
  ['abow48', 0, 0, 0, 0, 1, 0, 1, [1, 1, 80, 0], 0], // 2
  ['abow39', 0, 0, 0, 0, 1, 0, 1, [1, 1, 19, 0], 0], // 3
  ['abow27', 0, 0, 0, 0, 1, 0, 1, [1, 1, 418, 0], 0], // 4
  ['abow18', 0, 0, 0, 0, 1, 0, 1, [1, 1, 501, 0], 0], // 5
  [
    'armo87',
    0,
    0,
    0,
    0,
    22,
    0,
    1,
    [1, 3, 147, 1, 3, 117, 1, 3, 436, 1, 3, 382, 1, 3, 377, 1, 3, 111, 1, 1, 159, 1, 1, 260, 1, 1, 553, 1, 1, 56, 0],
    0,
  ], // 6
  ['armo84', 0, 0, 0, 0, 20, 0, 1, [1, 3, 315, 1, 3, 462, 1, 3, 15, 1, 3, 575, 1, 3, 537, 1, 3, 61, 1, 1, 483, 1, 1, 590, 0], 0], // 7
  ['armo81', 0, 0, 0, 0, 14, 0, 1, [1, 3, 498, 1, 3, 312, 1, 3, 0, 1, 3, 371, 1, 1, 115, 1, 1, 509, 0], 0], // 8
  ['armo78', 0, 0, 0, 0, 13, 0, 1, [1, 3, 28, 1, 3, 279, 1, 3, 124, 1, 3, 538, 1, 1, 162, 0], 0], // 9
  ['armo75', 0, 0, 0, 0, 14, 0, 1, [1, 3, 140, 1, 3, 344, 1, 3, 244, 1, 3, 372, 1, 1, 145, 1, 1, 314, 0], 0], // 10
  ['armo72', 0, 0, 0, 0, 16, 0, 1, [1, 3, 527, 1, 3, 581, 1, 3, 150, 1, 3, 374, 1, 3, 64, 1, 1, 439, 0], 0], // 11
  ['armo69', 0, 0, 0, 0, 20, 0, 1, [1, 3, 17, 1, 3, 586, 1, 3, 446, 1, 3, 548, 1, 3, 550, 1, 3, 52, 1, 1, 510, 1, 1, 280, 0], 0], // 12
  ['armo66', 0, 0, 0, 0, 14, 0, 1, [1, 3, 386, 1, 3, 160, 1, 3, 293, 1, 3, 447, 1, 1, 211, 1, 1, 388, 0], 0], // 13
  ['armo63', 0, 0, 0, 0, 20, 0, 1, [1, 3, 91, 1, 3, 292, 1, 3, 63, 1, 3, 346, 1, 3, 549, 1, 3, 495, 1, 1, 55, 1, 1, 440, 0], 0], // 14
  ['armo60', 0, 0, 0, 0, 17, 0, 1, [1, 3, 169, 1, 3, 348, 1, 3, 464, 1, 3, 271, 1, 3, 587, 1, 1, 76, 1, 1, 368, 0], 0], // 15
  ['armo57', 0, 0, 0, 0, 14, 0, 1, [1, 3, 238, 1, 3, 469, 1, 3, 10, 1, 3, 66, 1, 1, 533, 1, 1, 427, 0], 0], // 16
  ['armo54', 0, 0, 0, 0, 20, 0, 1, [1, 3, 519, 1, 3, 564, 1, 3, 559, 1, 3, 558, 1, 3, 118, 1, 3, 220, 1, 1, 484, 1, 1, 283, 0], 0], // 17
  [
    'armo51',
    0,
    0,
    0,
    0,
    25,
    0,
    1,
    [1, 3, 579, 1, 3, 434, 1, 3, 390, 1, 3, 40, 1, 3, 37, 1, 3, 36, 1, 3, 256, 1, 1, 437, 1, 1, 444, 1, 1, 223, 1, 1, 74, 0],
    0,
  ], // 18
  ['armo48', 0, 0, 0, 0, 11, 0, 1, [1, 3, 134, 1, 3, 130, 1, 3, 258, 1, 1, 288, 1, 1, 409, 0], 0], // 19
  [
    'armo45',
    0,
    0,
    0,
    0,
    23,
    0,
    1,
    [1, 3, 33, 1, 3, 528, 1, 3, 362, 1, 3, 158, 1, 3, 275, 1, 3, 364, 1, 3, 363, 1, 1, 414, 1, 1, 460, 0],
    0,
  ], // 20
  ['armo42', 0, 0, 0, 0, 20, 0, 1, [1, 3, 8, 1, 3, 77, 1, 3, 535, 1, 3, 335, 1, 3, 456, 1, 3, 31, 1, 1, 255, 1, 1, 2, 0], 0], // 21
  [
    'armo39',
    0,
    0,
    0,
    0,
    23,
    0,
    1,
    [1, 3, 208, 1, 3, 441, 1, 3, 141, 1, 3, 426, 1, 3, 468, 1, 3, 467, 1, 3, 466, 1, 1, 336, 1, 1, 185, 0],
    0,
  ], // 22
  ['armo36', 0, 0, 0, 0, 23, 0, 1, [1, 3, 333, 1, 3, 566, 1, 3, 215, 1, 3, 459, 1, 3, 136, 1, 3, 142, 1, 3, 144, 1, 1, 4, 1, 1, 3, 0], 0], // 23
  ['armo33', 0, 0, 0, 0, 8, 0, 1, [1, 3, 233, 1, 3, 143, 1, 1, 304, 1, 1, 376, 0], 0], // 24
  ['armo30', 0, 0, 0, 0, 9, 0, 1, [1, 3, 121, 1, 3, 186, 1, 3, 234, 0], 0], // 25
  ['armo27', 0, 0, 0, 0, 9, 0, 1, [1, 3, 214, 1, 3, 254, 1, 3, 404, 0], 0], // 26
  ['armo24', 0, 0, 0, 0, 19, 0, 1, [1, 3, 245, 1, 3, 403, 1, 3, 534, 1, 3, 58, 1, 1, 499, 1, 1, 22, 1, 1, 122, 1, 1, 138, 1, 3, 102, 0], 0], // 27
  [
    'armo21',
    0,
    0,
    0,
    0,
    22,
    0,
    1,
    [1, 3, 356, 1, 3, 500, 1, 3, 330, 1, 3, 334, 1, 3, 273, 1, 3, 60, 1, 1, 179, 1, 1, 20, 1, 1, 1, 1, 1, 213, 0],
    0,
  ], // 28
  ['armo18', 0, 0, 0, 0, 7, 0, 1, [1, 3, 68, 1, 1, 13, 1, 1, 286, 1, 1, 282, 1, 1, 547, 0], 0], // 29
  ['armo15', 0, 0, 0, 0, 12, 0, 1, [1, 3, 207, 1, 3, 445, 1, 3, 87, 1, 3, 309, 0], 0], // 30
  ['armo12', 0, 0, 0, 0, 21, 0, 1, [1, 3, 281, 1, 3, 423, 1, 3, 319, 1, 3, 86, 1, 3, 85, 1, 3, 47, 1, 3, 497, 0], 0], // 31
  ['armo9', 0, 0, 0, 0, 16, 0, 1, [1, 3, 506, 1, 3, 277, 1, 3, 274, 1, 3, 328, 1, 1, 269, 1, 1, 180, 1, 1, 424, 1, 1, 592, 0], 0], // 32
  ['armo6', 0, 0, 0, 0, 13, 0, 1, [1, 3, 482, 1, 3, 265, 1, 3, 487, 1, 1, 582, 1, 1, 303, 1, 1, 518, 1, 1, 408, 0], 0], // 33
  ['armo3', 0, 0, 0, 0, 21, 0, 1, [1, 3, 75, 1, 3, 413, 1, 3, 321, 1, 3, 71, 1, 3, 322, 1, 3, 65, 1, 3, 443, 0], 0], // 34
  [
    'mele87',
    0,
    0,
    0,
    0,
    32,
    0,
    1,
    [
      1, 2, 452, 1, 3, 49, 1, 3, 226, 1, 1, 546, 1, 1, 73, 1, 3, 526, 1, 3, 378, 1, 3, 109, 1, 3, 323, 1, 3, 578, 1, 3, 568, 1, 3, 222, 1,
      1, 16, 0,
    ],
    0,
  ], // 35
  ['mele84', 0, 0, 0, 0, 19, 0, 1, [1, 3, 89, 1, 3, 325, 1, 3, 126, 1, 3, 181, 1, 3, 217, 1, 3, 248, 1, 1, 470, 0], 0], // 36
  ['mele81', 0, 0, 0, 0, 18, 0, 1, [1, 2, 433, 1, 3, 572, 1, 3, 112, 1, 3, 577, 1, 3, 216, 1, 3, 125, 1, 1, 359, 0], 0], // 37
  ['mele78', 0, 0, 0, 0, 15, 0, 1, [1, 2, 184, 1, 1, 458, 1, 3, 453, 1, 3, 116, 1, 3, 90, 1, 3, 580, 0], 0], // 38
  ['mele75', 0, 0, 0, 0, 13, 0, 1, [1, 2, 38, 1, 3, 135, 1, 1, 327, 1, 3, 400, 1, 3, 354, 1, 1, 166, 0], 0], // 39
  ['mele72', 0, 0, 0, 0, 18, 0, 1, [1, 3, 173, 1, 3, 146, 1, 3, 27, 1, 3, 373, 1, 3, 29, 1, 3, 521, 0], 0], // 40
  ['mele69', 0, 0, 0, 0, 8, 0, 1, [1, 2, 562, 1, 3, 383, 1, 3, 291, 0], 0], // 41
  ['mele66', 0, 0, 0, 0, 18, 0, 1, [1, 3, 479, 1, 1, 218, 1, 3, 284, 1, 3, 205, 1, 3, 507, 1, 3, 113, 1, 1, 502, 1, 1, 358, 0], 0], // 42
  ['mele63', 0, 0, 0, 0, 19, 0, 1, [1, 2, 585, 1, 3, 486, 1, 1, 365, 1, 3, 419, 1, 3, 21, 1, 3, 168, 1, 3, 508, 1, 1, 360, 0], 0], // 43
  ['mele60', 0, 0, 0, 0, 15, 0, 1, [1, 2, 514, 1, 3, 324, 1, 3, 59, 1, 3, 295, 1, 3, 381, 1, 1, 555, 0], 0], // 44
  ['mele57', 0, 0, 0, 0, 16, 0, 1, [1, 3, 257, 1, 3, 183, 1, 1, 407, 1, 3, 544, 1, 3, 177, 1, 3, 204, 0], 0], // 45
  ['mele54', 0, 0, 0, 0, 17, 0, 1, [1, 3, 355, 1, 3, 174, 1, 2, 451, 1, 3, 530, 1, 3, 539, 1, 3, 294, 0], 0], // 46
  ['mele51', 0, 0, 0, 0, 19, 0, 1, [1, 3, 9, 1, 1, 241, 1, 3, 11, 1, 3, 593, 1, 3, 266, 1, 3, 46, 1, 2, 253, 1, 1, 82, 0], 0], // 47
  ['mele48', 0, 0, 0, 0, 22, 0, 1, [1, 3, 379, 1, 3, 231, 1, 3, 41, 1, 3, 236, 1, 3, 504, 1, 3, 493, 1, 3, 316, 1, 1, 431, 0], 0], // 48
  [
    'mele45',
    0,
    0,
    0,
    0,
    26,
    0,
    1,
    [1, 3, 120, 1, 1, 531, 1, 1, 154, 1, 3, 310, 1, 3, 560, 1, 3, 131, 1, 3, 432, 1, 3, 541, 1, 3, 588, 1, 2, 250, 1, 1, 83, 0],
    0,
  ], // 49
  [
    'mele42',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [1, 3, 516, 1, 1, 285, 1, 3, 44, 1, 3, 132, 1, 3, 101, 1, 3, 289, 1, 3, 480, 1, 3, 563, 1, 3, 42, 1, 1, 235, 1, 2, 264, 0],
    0,
  ], // 50
  [
    'mele39',
    0,
    0,
    0,
    0,
    29,
    0,
    1,
    [1, 3, 542, 1, 3, 45, 1, 1, 399, 1, 3, 299, 1, 3, 540, 1, 3, 151, 1, 3, 171, 1, 3, 561, 1, 3, 247, 1, 3, 50, 1, 1, 79, 0],
    0,
  ], // 51
  [
    'mele36',
    0,
    0,
    0,
    0,
    31,
    0,
    1,
    [1, 3, 570, 1, 3, 106, 1, 3, 366, 1, 3, 189, 1, 3, 465, 1, 3, 425, 1, 3, 206, 1, 3, 212, 1, 3, 389, 1, 1, 411, 1, 2, 182, 1, 1, 81, 0],
    0,
  ], // 52
  [
    'mele33',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [1, 3, 246, 1, 3, 249, 1, 3, 267, 1, 1, 72, 1, 1, 430, 1, 3, 30, 1, 3, 405, 1, 3, 39, 1, 3, 571, 1, 3, 338, 1, 2, 584, 0],
    0,
  ], // 53
  ['mele30', 0, 0, 0, 0, 18, 0, 1, [1, 3, 524, 1, 3, 262, 1, 3, 129, 1, 3, 224, 1, 3, 567, 1, 1, 306, 1, 2, 412, 0], 0], // 54
  ['mele27', 0, 0, 0, 0, 17, 0, 1, [1, 3, 557, 1, 3, 219, 1, 1, 259, 1, 3, 565, 1, 3, 574, 1, 3, 188, 1, 1, 350, 0], 0], // 55
  ['mele24', 0, 0, 0, 0, 19, 0, 1, [1, 3, 242, 1, 3, 34, 1, 3, 51, 1, 3, 225, 1, 3, 401, 1, 1, 573, 1, 2, 450, 1, 1, 349, 0], 0], // 56
  [
    'mele21',
    0,
    0,
    0,
    0,
    24,
    0,
    1,
    [1, 3, 367, 1, 1, 569, 1, 3, 187, 1, 3, 361, 1, 3, 342, 1, 3, 221, 1, 3, 492, 1, 3, 406, 1, 2, 54, 0],
    0,
  ], // 57
  ['mele18', 0, 0, 0, 0, 20, 0, 1, [1, 3, 35, 1, 1, 62, 1, 3, 105, 1, 3, 313, 1, 3, 24, 1, 3, 67, 1, 1, 43, 1, 2, 104, 1, 1, 351, 0], 0], // 58
  ['mele15', 0, 0, 0, 0, 21, 0, 1, [1, 3, 156, 1, 1, 240, 1, 3, 375, 1, 3, 70, 1, 3, 25, 1, 3, 474, 1, 3, 457, 1, 2, 84, 0], 0], // 59
  [
    'mele12',
    0,
    0,
    0,
    0,
    22,
    0,
    1,
    [1, 3, 69, 1, 1, 589, 1, 3, 178, 1, 3, 127, 1, 3, 543, 1, 3, 402, 1, 3, 554, 1, 1, 228, 1, 2, 268, 0],
    0,
  ], // 60
  ['mele9', 0, 0, 0, 0, 21, 0, 1, [1, 3, 23, 1, 3, 347, 1, 3, 435, 1, 3, 153, 1, 3, 522, 1, 3, 536, 1, 1, 341, 1, 2, 583, 0], 0], // 61
  ['mele6', 0, 0, 0, 0, 15, 0, 1, [1, 3, 317, 1, 3, 496, 1, 3, 449, 1, 3, 491, 1, 3, 32, 0], 0], // 62
  [
    'mele3',
    0,
    0,
    0,
    0,
    23,
    0,
    1,
    [1, 3, 263, 1, 1, 556, 1, 3, 108, 1, 1, 448, 1, 3, 476, 1, 3, 133, 1, 3, 523, 1, 3, 302, 1, 1, 475, 1, 2, 307, 0],
    0,
  ], // 63
  [
    'weap87',
    0,
    0,
    0,
    0,
    36,
    0,
    1,
    [
      1, 2, 452, 1, 3, 49, 1, 3, 226, 1, 1, 546, 1, 1, 73, 1, 3, 526, 1, 3, 378, 1, 3, 109, 1, 3, 323, 1, 3, 578, 1, 3, 568, 1, 3, 222, 1,
      1, 16, 1, 3, 290, 1, 1, 152, 0,
    ],
    0,
  ], // 64
  [
    'weap84',
    0,
    0,
    0,
    0,
    23,
    0,
    1,
    [1, 3, 89, 1, 3, 325, 1, 3, 126, 1, 3, 181, 1, 3, 217, 1, 3, 248, 1, 1, 470, 1, 3, 137, 1, 1, 552, 0],
    0,
  ], // 65
  ['weap81', 0, 0, 0, 0, 21, 0, 1, [1, 2, 433, 1, 3, 572, 1, 3, 112, 1, 3, 577, 1, 3, 216, 1, 3, 125, 1, 3, 576, 1, 1, 359, 0], 0], // 66
  ['weap78', 0, 0, 0, 0, 19, 0, 1, [1, 2, 184, 1, 1, 458, 1, 3, 453, 1, 3, 116, 1, 3, 90, 1, 3, 580, 1, 3, 123, 1, 1, 239, 0], 0], // 67
  ['weap75', 0, 0, 0, 0, 17, 0, 1, [1, 2, 38, 1, 3, 135, 1, 1, 327, 1, 3, 400, 1, 3, 354, 1, 1, 166, 1, 3, 110, 1, 1, 139, 0], 0], // 68
  ['weap72', 0, 0, 0, 0, 21, 0, 1, [1, 3, 173, 1, 3, 146, 1, 3, 27, 1, 3, 373, 1, 3, 29, 1, 3, 521, 1, 3, 149, 0], 0], // 69
  ['weap69', 0, 0, 0, 0, 15, 0, 1, [1, 2, 562, 1, 3, 383, 1, 3, 291, 1, 3, 243, 1, 3, 230, 1, 1, 167, 0], 0], // 70
  ['weap66', 0, 0, 0, 0, 18, 0, 1, [1, 3, 479, 1, 1, 218, 1, 3, 284, 1, 3, 205, 1, 3, 507, 1, 3, 113, 1, 1, 502, 1, 1, 358, 0], 0], // 71
  [
    'weap63',
    0,
    0,
    0,
    0,
    22,
    0,
    1,
    [1, 2, 585, 1, 3, 486, 1, 1, 365, 1, 3, 419, 1, 3, 21, 1, 3, 168, 1, 3, 508, 1, 3, 461, 1, 1, 360, 0],
    0,
  ], // 72
  ['weap60', 0, 0, 0, 0, 19, 0, 1, [1, 2, 514, 1, 3, 324, 1, 3, 59, 1, 3, 295, 1, 3, 381, 1, 1, 555, 1, 3, 53, 1, 1, 272, 0], 0], // 73
  ['weap57', 0, 0, 0, 0, 22, 0, 1, [1, 3, 257, 1, 3, 183, 1, 1, 407, 1, 3, 544, 1, 3, 177, 1, 3, 204, 1, 3, 494, 1, 3, 391, 0], 0], // 74
  [
    'weap54',
    0,
    0,
    0,
    0,
    24,
    0,
    1,
    [1, 3, 355, 1, 3, 174, 1, 3, 232, 1, 3, 100, 1, 2, 451, 1, 3, 530, 1, 3, 539, 1, 3, 294, 1, 1, 357, 0],
    0,
  ], // 75
  [
    'weap51',
    0,
    0,
    0,
    0,
    23,
    0,
    1,
    [1, 3, 9, 1, 1, 241, 1, 3, 11, 1, 3, 593, 1, 3, 266, 1, 3, 46, 1, 3, 429, 1, 2, 253, 1, 1, 515, 1, 1, 82, 0],
    0,
  ], // 76
  [
    'weap48',
    0,
    0,
    0,
    0,
    30,
    0,
    1,
    [1, 3, 379, 1, 3, 231, 1, 3, 41, 1, 3, 236, 1, 3, 504, 1, 3, 493, 1, 3, 316, 1, 1, 431, 1, 3, 320, 1, 3, 26, 1, 1, 490, 1, 1, 80, 0],
    0,
  ], // 77
  [
    'weap45',
    0,
    0,
    0,
    0,
    29,
    0,
    1,
    [1, 3, 120, 1, 1, 531, 1, 1, 154, 1, 3, 310, 1, 3, 560, 1, 3, 131, 1, 3, 432, 1, 3, 541, 1, 3, 588, 1, 3, 473, 1, 2, 250, 1, 1, 83, 0],
    0,
  ], // 78
  [
    'weap42',
    0,
    0,
    0,
    0,
    32,
    0,
    1,
    [
      1, 3, 516, 1, 1, 285, 1, 3, 44, 1, 3, 132, 1, 3, 101, 1, 3, 289, 1, 3, 480, 1, 3, 563, 1, 3, 42, 1, 1, 235, 1, 3, 478, 1, 2, 264, 1,
      1, 107, 0,
    ],
    0,
  ], // 79
  [
    'weap39',
    0,
    0,
    0,
    0,
    34,
    0,
    1,
    [
      1, 3, 542, 1, 3, 45, 1, 1, 399, 1, 3, 299, 1, 3, 540, 1, 3, 151, 1, 3, 171, 1, 3, 561, 1, 3, 247, 1, 3, 50, 1, 1, 79, 1, 3, 157, 1, 1,
      128, 1, 1, 19, 0,
    ],
    0,
  ], // 80
  [
    'weap36',
    0,
    0,
    0,
    0,
    37,
    0,
    1,
    [
      1, 3, 570, 1, 3, 106, 1, 3, 366, 1, 3, 189, 1, 3, 465, 1, 3, 425, 1, 3, 206, 1, 3, 212, 1, 3, 389, 1, 1, 411, 1, 3, 78, 1, 3, 14, 1,
      2, 182, 1, 1, 81, 0,
    ],
    0,
  ], // 81
  [
    'weap33',
    0,
    0,
    0,
    0,
    41,
    0,
    1,
    [
      1, 3, 246, 1, 3, 249, 1, 3, 343, 1, 3, 421, 1, 3, 416, 1, 3, 267, 1, 1, 72, 1, 1, 430, 1, 3, 30, 1, 3, 405, 1, 3, 39, 1, 3, 571, 1, 3,
      338, 1, 3, 417, 1, 2, 584, 1, 1, 227, 0,
    ],
    0,
  ], // 82
  [
    'weap30',
    0,
    0,
    0,
    0,
    24,
    0,
    1,
    [1, 3, 524, 1, 3, 262, 1, 3, 385, 1, 3, 129, 1, 3, 224, 1, 3, 567, 1, 1, 306, 1, 3, 163, 1, 2, 412, 0],
    0,
  ], // 83
  [
    'weap27',
    0,
    0,
    0,
    0,
    21,
    0,
    1,
    [1, 3, 557, 1, 3, 219, 1, 1, 259, 1, 3, 565, 1, 3, 574, 1, 3, 188, 1, 3, 477, 1, 1, 418, 1, 1, 350, 0],
    0,
  ], // 84
  [
    'weap24',
    0,
    0,
    0,
    0,
    26,
    0,
    1,
    [1, 3, 242, 1, 3, 34, 1, 3, 51, 1, 3, 225, 1, 3, 401, 1, 1, 573, 1, 3, 339, 1, 3, 276, 1, 2, 450, 1, 1, 301, 1, 1, 349, 0],
    0,
  ], // 85
  [
    'weap21',
    0,
    0,
    0,
    0,
    27,
    0,
    1,
    [1, 3, 367, 1, 1, 569, 1, 3, 187, 1, 3, 361, 1, 3, 342, 1, 3, 221, 1, 3, 492, 1, 3, 406, 1, 3, 99, 1, 2, 54, 0],
    0,
  ], // 86
  [
    'weap18',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [
      1, 3, 35, 1, 1, 62, 1, 3, 105, 1, 3, 313, 1, 3, 24, 1, 3, 67, 1, 1, 43, 1, 3, 471, 1, 3, 175, 1, 2, 104, 1, 1, 103, 1, 1, 501, 1, 1,
      351, 0,
    ],
    0,
  ], // 87
  ['weap15', 0, 0, 0, 0, 24, 0, 1, [1, 3, 156, 1, 1, 240, 1, 3, 375, 1, 3, 70, 1, 3, 25, 1, 3, 474, 1, 3, 457, 1, 3, 119, 1, 2, 84, 0], 0], // 88
  [
    'weap12',
    0,
    0,
    0,
    0,
    26,
    0,
    1,
    [1, 3, 69, 1, 1, 589, 1, 3, 178, 1, 3, 127, 1, 3, 543, 1, 3, 402, 1, 3, 554, 1, 1, 228, 1, 3, 114, 1, 2, 268, 1, 1, 488, 0],
    0,
  ], // 89
  [
    'weap9',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [1, 3, 23, 1, 3, 347, 1, 3, 435, 1, 3, 153, 1, 3, 522, 1, 3, 536, 1, 1, 341, 1, 3, 340, 1, 3, 505, 1, 2, 583, 1, 1, 438, 0],
    0,
  ], // 90
  ['weap6', 0, 0, 0, 0, 24, 0, 1, [1, 3, 317, 1, 3, 496, 1, 3, 449, 1, 3, 491, 1, 3, 32, 1, 3, 287, 1, 3, 329, 1, 3, 210, 0], 0], // 91
  [
    'weap3',
    0,
    0,
    0,
    0,
    27,
    0,
    1,
    [1, 3, 263, 1, 1, 556, 1, 3, 108, 1, 1, 448, 1, 3, 476, 1, 3, 133, 1, 3, 523, 1, 3, 302, 1, 1, 475, 1, 3, 472, 1, 2, 307, 1, 1, 161, 0],
    0,
  ], // 92
  ['bow87', 0, 0, 0, 0, 3, 0, 1, [1, 3, 290, 0], 0], // 93
  ['bow81', 0, 0, 0, 0, 3, 0, 1, [1, 3, 576, 0], 0], // 94
  ['bow78', 0, 0, 0, 0, 4, 0, 1, [1, 3, 123, 1, 1, 239, 0], 0], // 95
  ['bow72', 0, 0, 0, 0, 3, 0, 1, [1, 3, 149, 0], 0], // 96
  ['bow69', 0, 0, 0, 0, 3, 0, 1, [1, 3, 243, 0], 0], // 97
  ['bow63', 0, 0, 0, 0, 3, 0, 1, [1, 3, 461, 0], 0], // 98
  ['bow60', 0, 0, 0, 0, 3, 0, 1, [1, 3, 53, 0], 0], // 99
  ['bow57', 0, 0, 0, 0, 3, 0, 1, [1, 3, 494, 0], 0], // 100
  ['bow54', 0, 0, 0, 0, 4, 0, 1, [1, 3, 232, 1, 1, 357, 0], 0], // 101
  ['bow51', 0, 0, 0, 0, 3, 0, 1, [1, 3, 429, 0], 0], // 102
  ['bow48', 0, 0, 0, 0, 4, 0, 1, [1, 3, 320, 1, 1, 80, 0], 0], // 103
  ['bow45', 0, 0, 0, 0, 3, 0, 1, [1, 3, 473, 0], 0], // 104
  ['bow39', 0, 0, 0, 0, 4, 0, 1, [1, 3, 157, 1, 1, 19, 0], 0], // 105
  ['bow36', 0, 0, 0, 0, 3, 0, 1, [1, 3, 78, 0], 0], // 106
  ['bow33', 0, 0, 0, 0, 6, 0, 1, [1, 3, 343, 1, 3, 417, 0], 0], // 107
  ['bow30', 0, 0, 0, 0, 3, 0, 1, [1, 3, 163, 0], 0], // 108
  ['bow27', 0, 0, 0, 0, 4, 0, 1, [1, 3, 477, 1, 1, 418, 0], 0], // 109
  ['bow24', 0, 0, 0, 0, 3, 0, 1, [1, 3, 339, 0], 0], // 110
  ['bow18', 0, 0, 0, 0, 4, 0, 1, [1, 3, 471, 1, 1, 501, 0], 0], // 111
  ['bow12', 0, 0, 0, 0, 3, 0, 1, [1, 3, 114, 0], 0], // 112
  ['bow9', 0, 0, 0, 0, 3, 0, 1, [1, 3, 340, 0], 0], // 113
  ['bow6', 0, 0, 0, 0, 3, 0, 1, [1, 3, 287, 0], 0], // 114
  ['bow3', 0, 0, 0, 0, 3, 0, 1, [1, 3, 472, 0], 0], // 115
  ['Gold', 0, 0, 0, 0, 1, 0, 1, [1, 1, 229, 0], 0], // 116
  ['Jewelry A', 0, 0, 0, 0, 20, 0, 1, [1, 8, 422, 1, 4, 7, 1, 2, 305, 1, 2, 237, 1, 2, 318, 1, 2, 485, 0], 0], // 117
  ['Jewelry B', 0, 0, 0, 0, 20, 0, 1, [1, 8, 422, 1, 4, 7, 1, 2, 305, 1, 2, 237, 1, 2, 318, 1, 2, 485, 0], 0], // 118
  ['Jewelry C', 0, 0, 0, 0, 20, 0, 1, [1, 8, 422, 1, 4, 7, 1, 2, 305, 1, 2, 237, 1, 2, 318, 1, 2, 485, 0], 0], // 119
  ['Chipped Gem', 0, 0, 0, 0, 20, 0, 1, [1, 3, 92, 1, 3, 98, 1, 3, 96, 1, 3, 94, 1, 3, 95, 1, 3, 93, 1, 2, 97, 0], 0], // 120
  ['Flawed Gem', 0, 0, 0, 0, 20, 0, 1, [1, 3, 190, 1, 3, 196, 1, 3, 194, 1, 3, 192, 1, 3, 193, 1, 3, 191, 1, 2, 195, 0], 0], // 121
  ['Normal Gem', 0, 0, 0, 0, 20, 0, 1, [1, 3, 5, 1, 3, 532, 1, 3, 442, 1, 3, 170, 1, 3, 428, 1, 3, 148, 1, 2, 481, 0], 0], // 122
  ['Flawless Gem', 0, 0, 0, 0, 20, 0, 1, [1, 3, 197, 1, 3, 203, 1, 3, 201, 1, 3, 199, 1, 3, 200, 1, 3, 198, 1, 2, 202, 0], 0], // 123
  ['Perfect Gem', 0, 0, 0, 0, 20, 0, 1, [1, 3, 392, 1, 3, 398, 1, 3, 396, 1, 3, 394, 1, 3, 395, 1, 3, 393, 1, 2, 397, 0], 0], // 124
  ['Ammo', 0, 0, 0, 0, 2, 0, 1, [1, 1, 18, 1, 1, 57, 0], 0], // 125
  ['Misc 0', 0, 0, 0, 0, 13, 0, 1, [1, 3, 308, 1, 3, 455, 1, 3, 454, 1, 2, 210, 1, 2, 505, 0], 0], // 126
  ['Misc 1', 0, 0, 0, 0, 13, 0, 1, [1, 3, 308, 1, 3, 455, 1, 3, 454, 1, 2, 175, 1, 2, 99, 0], 0], // 127
  ['Misc 2', 0, 0, 0, 0, 13, 0, 1, [1, 3, 308, 1, 3, 455, 1, 3, 454, 1, 2, 385, 1, 2, 416, 0], 0], // 128
  ['Hpotion 1', 0, 0, 0, 0, 17, 0, 1, [1, 8, 369, 1, 3, 331, 1, 3, 370, 1, 2, 332, 1, 1, 420, 0], 0], // 129
  ['Hpotion 2', 0, 0, 0, 0, 13, 0, 1, [1, 1, 369, 1, 4, 331, 1, 3, 270, 1, 2, 332, 1, 2, 353, 1, 1, 420, 0], 0], // 130
  ['Hpotion 3', 0, 0, 0, 0, 13, 0, 1, [1, 1, 331, 1, 4, 270, 1, 3, 251, 1, 2, 353, 1, 2, 252, 1, 1, 420, 0], 0], // 131
  ['Hpotion 4', 0, 0, 0, 0, 13, 0, 1, [1, 1, 270, 1, 4, 251, 1, 3, 511, 1, 2, 252, 1, 2, 512, 1, 1, 420, 0], 0], // 132
  ['Hpotion 5', 0, 0, 0, 0, 14, 0, 1, [1, 1, 270, 1, 4, 251, 1, 4, 511, 1, 4, 512, 1, 1, 420, 0], 0], // 133
  ['Hpotion 6', 0, 0, 0, 0, 17, 0, 1, [1, 4, 251, 1, 6, 511, 1, 2, 252, 1, 4, 512, 1, 1, 420, 0], 0], // 134
  ['Potion 1', 0, 0, 0, 0, 30, 0, 1, [1, 12, 369, 1, 4, 331, 1, 5, 370, 1, 4, 332, 1, 1, 420, 1, 4, 503, 0], 0], // 135
  [
    'Potion 2',
    0,
    0,
    0,
    0,
    31,
    0,
    1,
    [1, 4, 369, 1, 8, 331, 1, 5, 270, 1, 2, 332, 1, 5, 353, 1, 1, 420, 1, 2, 503, 1, 3, 12, 1, 1, 520, 0],
    0,
  ], // 136
  [
    'Potion 3',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [1, 6, 331, 1, 6, 270, 1, 4, 251, 1, 3, 353, 1, 3, 252, 1, 1, 420, 1, 2, 503, 1, 2, 12, 1, 1, 520, 0],
    0,
  ], // 137
  [
    'Potion 4',
    0,
    0,
    0,
    0,
    28,
    0,
    1,
    [1, 6, 270, 1, 6, 251, 1, 4, 511, 1, 3, 252, 1, 3, 512, 1, 1, 420, 1, 2, 503, 1, 2, 12, 1, 1, 520, 0],
    0,
  ], // 138
  ['Potion 5', 0, 0, 0, 0, 27, 0, 1, [1, 4, 270, 1, 6, 251, 1, 6, 511, 1, 5, 512, 1, 1, 420, 1, 2, 503, 1, 2, 12, 1, 1, 520, 0], 0], // 139
  ['Potion 6', 0, 0, 0, 0, 26, 0, 1, [1, 6, 251, 1, 8, 511, 1, 1, 252, 1, 5, 512, 1, 1, 420, 1, 2, 503, 1, 2, 12, 1, 1, 520, 0], 0], // 140
  ['Runes 1', 0, 0, 0, 0, 5, 0, 1, [1, 3, 164, 1, 2, 165, 0], 0], // 141
  ['Runes 2', 0, 0, 0, 0, 7, 0, 1, [1, 3, 529, 1, 2, 380, 2, 2, 141, 0], 0], // 142
  ['Runes 3', 0, 0, 0, 0, 10, 0, 1, [1, 3, 172, 1, 2, 298, 2, 5, 142, 0], 0], // 143
  ['Runes 4', 0, 0, 0, 0, 12, 0, 1, [1, 3, 517, 1, 2, 415, 2, 7, 143, 0], 0], // 144
  ['Runes 5', 0, 0, 0, 0, 17, 0, 1, [1, 3, 387, 1, 2, 525, 2, 12, 144, 0], 0], // 145
  ['Runes 6', 0, 0, 0, 0, 27, 0, 1, [1, 3, 6, 1, 2, 489, 2, 22, 145, 0], 0], // 146
  ['Runes 7', 0, 0, 0, 0, 50, 0, 1, [1, 3, 463, 1, 2, 155, 2, 45, 146, 0], 0], // 147
  ['Runes 8', 0, 0, 0, 0, 95, 0, 1, [1, 3, 278, 1, 2, 296, 2, 90, 147, 0], 0], // 148
  ['Runes 9', 0, 0, 0, 0, 185, 0, 1, [1, 3, 345, 1, 2, 311, 2, 180, 148, 0], 0], // 149
  ['Runes 10', 0, 0, 0, 0, 365, 0, 1, [1, 3, 176, 1, 2, 326, 2, 360, 149, 0], 0], // 150
  ['Runes 11', 0, 0, 0, 0, 725, 0, 1, [1, 3, 410, 1, 2, 545, 2, 720, 150, 0], 0], // 151
  ['Runes 12', 0, 0, 0, 0, 1071, 0, 1, [1, 3, 352, 1, 2, 297, 2, 1066, 151, 0], 0], // 152
  ['Runes 13', 0, 0, 0, 0, 1524, 0, 1, [1, 3, 261, 1, 2, 551, 2, 1519, 152, 0], 0], // 153
  ['Runes 14', 0, 0, 0, 0, 2175, 0, 1, [1, 3, 384, 1, 2, 337, 2, 2170, 153, 0], 0], // 154
  ['Runes 15', 0, 0, 0, 0, 2946, 0, 1, [1, 3, 513, 1, 2, 48, 2, 2941, 154, 0], 0], // 155
  ['Runes 16', 0, 0, 0, 0, 3962, 0, 1, [1, 3, 300, 1, 2, 88, 2, 3957, 155, 0], 0], // 156
  ['Runes 17', 0, 0, 0, 0, 5171, 0, 1, [1, 1, 591, 2, 5170, 156, 0], 0], // 157
  ['Act 1 Junk', 0, 0, 0, 0, 16, 0, 1, [2, 8, 135, 2, 4, 126, 2, 4, 125, 0], 0], // 158
  ['Act 2 Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 158, 2, 8, 136, 2, 4, 126, 2, 4, 125, 0], 0], // 159
  ['Act 3 Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 159, 2, 8, 137, 2, 4, 127, 2, 4, 125, 0], 0], // 160
  ['Act 4 Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 160, 2, 8, 138, 2, 4, 127, 2, 4, 125, 0], 0], // 161
  ['Act 5 Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 161, 2, 8, 139, 2, 4, 128, 2, 4, 125, 0], 0], // 162
  ['Act 1 (N) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 163
  ['Act 2 (N) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 164
  ['Act 3 (N) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 165
  ['Act 4 (N) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 166
  ['Act 5 (N) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 167
  ['Act 1 (H) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 168
  ['Act 2 (H) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 169
  ['Act 3 (H) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 170
  ['Act 4 (H) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 171
  ['Act 5 (H) Junk', 0, 0, 0, 0, 18, 0, 1, [2, 2, 162, 2, 8, 140, 2, 4, 128, 2, 4, 125, 0], 0], // 172
  ['Act 1 Equip A', 0, 0, 0, 0, 14, 0, 1, [2, 7, 92, 2, 7, 34, 0], 0], // 173
  ['Act 1 Equip B', 0, 0, 0, 0, 20, 0, 1, [2, 3, 92, 2, 3, 34, 2, 7, 91, 2, 7, 33, 0], 0], // 174
  ['Act 1 Equip C', 0, 0, 0, 0, 36, 0, 1, [2, 2, 92, 2, 1, 34, 2, 6, 91, 2, 3, 33, 2, 14, 90, 2, 7, 32, 2, 2, 89, 2, 1, 31, 0], 0], // 175
  [
    'Act 2 Equip A',
    0,
    0,
    0,
    0,
    66,
    0,
    1,
    [2, 2, 91, 2, 1, 33, 2, 6, 90, 2, 3, 32, 2, 14, 89, 2, 7, 31, 2, 2, 88, 2, 1, 30, 2, 30, 175, 0],
    0,
  ], // 176
  [
    'Act 2 Equip B',
    0,
    0,
    0,
    0,
    96,
    0,
    1,
    [2, 2, 90, 2, 1, 32, 2, 6, 89, 2, 3, 31, 2, 14, 88, 2, 7, 30, 2, 2, 87, 2, 1, 29, 2, 60, 176, 0],
    0,
  ], // 177
  [
    'Act 2 Equip C',
    0,
    0,
    0,
    0,
    116,
    0,
    1,
    [2, 2, 89, 2, 1, 31, 2, 6, 88, 2, 3, 30, 2, 14, 87, 2, 7, 29, 2, 2, 86, 2, 1, 28, 2, 80, 177, 0],
    0,
  ], // 178
  [
    'Act 3 Equip A',
    0,
    0,
    0,
    0,
    136,
    0,
    1,
    [2, 2, 88, 2, 1, 30, 2, 6, 87, 2, 3, 29, 2, 14, 86, 2, 7, 28, 2, 2, 85, 2, 1, 27, 2, 100, 178, 0],
    0,
  ], // 179
  [
    'Act 3 Equip B',
    0,
    0,
    0,
    0,
    236,
    0,
    1,
    [2, 2, 87, 2, 1, 29, 2, 6, 86, 2, 3, 28, 2, 14, 85, 2, 7, 27, 2, 2, 84, 2, 1, 26, 2, 200, 179, 0],
    0,
  ], // 180
  [
    'Act 3 Equip C',
    0,
    0,
    0,
    0,
    376,
    0,
    1,
    [2, 2, 86, 2, 1, 28, 2, 6, 85, 2, 3, 27, 2, 14, 84, 2, 7, 26, 2, 2, 83, 2, 1, 25, 2, 340, 180, 0],
    0,
  ], // 181
  [
    'Act 4 Equip A',
    0,
    0,
    0,
    0,
    463,
    0,
    1,
    [2, 2, 85, 2, 1, 27, 2, 6, 84, 2, 3, 26, 2, 14, 83, 2, 4, 25, 2, 2, 82, 2, 1, 24, 2, 430, 181, 0],
    0,
  ], // 182
  [
    'Act 4 Equip B',
    0,
    0,
    0,
    0,
    532,
    0,
    1,
    [2, 2, 84, 2, 1, 26, 2, 6, 83, 2, 3, 25, 2, 14, 82, 2, 3, 24, 2, 2, 81, 2, 1, 23, 2, 500, 182, 0],
    0,
  ], // 183
  [
    'Act 5 Equip A',
    0,
    0,
    0,
    0,
    670,
    0,
    1,
    [2, 2, 84, 2, 1, 26, 2, 6, 83, 2, 3, 25, 2, 14, 82, 2, 3, 24, 2, 7, 81, 2, 5, 23, 2, 629, 183, 0],
    0,
  ], // 184
  [
    'Act 5 Equip B',
    0,
    0,
    0,
    0,
    766,
    0,
    1,
    [2, 2, 83, 2, 1, 25, 2, 6, 82, 2, 2, 24, 2, 14, 81, 2, 7, 23, 2, 2, 80, 2, 1, 22, 2, 731, 184, 0],
    0,
  ], // 185
  [
    'Act 5 Equip C',
    0,
    0,
    0,
    0,
    868,
    0,
    1,
    [2, 2, 83, 2, 1, 25, 2, 6, 82, 2, 2, 24, 2, 14, 81, 2, 7, 23, 2, 2, 80, 2, 1, 22, 2, 833, 185, 0],
    0,
  ], // 186
  [
    'Act 1 (N) Equip A',
    0,
    0,
    0,
    0,
    971,
    0,
    1,
    [2, 2, 82, 2, 1, 24, 2, 6, 81, 2, 3, 23, 2, 14, 80, 2, 7, 22, 2, 2, 79, 2, 1, 21, 2, 935, 186, 0],
    0,
  ], // 187
  [
    'Act 1 (N) Equip B',
    0,
    0,
    0,
    0,
    1147,
    0,
    1,
    [2, 2, 82, 2, 1, 24, 2, 6, 81, 2, 3, 23, 2, 14, 80, 2, 7, 22, 2, 6, 79, 2, 3, 21, 2, 1105, 187, 0],
    0,
  ], // 188
  [
    'Act 1 (N) Equip C',
    0,
    0,
    0,
    0,
    1056,
    0,
    1,
    [2, 2, 81, 2, 1, 23, 2, 6, 80, 2, 3, 22, 2, 14, 79, 2, 7, 21, 2, 2, 78, 2, 1, 20, 2, 1020, 187, 0],
    0,
  ], // 189
  [
    'Act 2 (N) Equip A',
    0,
    0,
    0,
    0,
    1275,
    0,
    1,
    [2, 2, 81, 2, 1, 23, 2, 6, 80, 2, 3, 22, 2, 14, 79, 2, 7, 21, 2, 6, 78, 2, 3, 20, 2, 1233, 188, 0],
    0,
  ], // 190
  [
    'Act 2 (N) Equip B',
    0,
    0,
    0,
    0,
    1141,
    0,
    1,
    [2, 2, 80, 2, 1, 22, 2, 6, 79, 2, 3, 21, 2, 14, 78, 2, 7, 20, 2, 2, 77, 2, 1, 19, 2, 1105, 190, 0],
    0,
  ], // 191
  [
    'Act 2 (N) Equip C',
    0,
    0,
    0,
    0,
    1360,
    0,
    1,
    [2, 2, 80, 2, 1, 22, 2, 6, 79, 2, 3, 21, 2, 14, 78, 2, 7, 20, 2, 6, 77, 2, 3, 19, 2, 1318, 190, 0],
    0,
  ], // 192
  [
    'Act 3 (N) Equip A',
    0,
    0,
    0,
    0,
    1226,
    0,
    1,
    [2, 2, 79, 2, 1, 21, 2, 6, 78, 2, 3, 20, 2, 14, 77, 2, 7, 19, 2, 2, 76, 2, 1, 18, 2, 1190, 191, 0],
    0,
  ], // 193
  [
    'Act 3 (N) Equip B',
    0,
    0,
    0,
    0,
    1487,
    0,
    1,
    [2, 2, 79, 2, 1, 21, 2, 6, 78, 2, 3, 20, 2, 14, 77, 2, 7, 19, 2, 6, 76, 2, 3, 18, 2, 1445, 193, 0],
    0,
  ], // 194
  [
    'Act 3 (N) Equip C',
    0,
    0,
    0,
    0,
    1311,
    0,
    1,
    [2, 2, 78, 2, 1, 20, 2, 6, 77, 2, 3, 19, 2, 14, 76, 2, 7, 18, 2, 2, 75, 2, 1, 17, 2, 1275, 193, 0],
    0,
  ], // 195
  [
    'Act 4 (N) Equip A',
    0,
    0,
    0,
    0,
    1572,
    0,
    1,
    [2, 2, 78, 2, 1, 20, 2, 6, 77, 2, 3, 19, 2, 14, 76, 2, 7, 18, 2, 6, 75, 2, 3, 17, 2, 1530, 194, 0],
    0,
  ], // 196
  [
    'Act 4 (N) Equip B',
    0,
    0,
    0,
    0,
    1396,
    0,
    1,
    [2, 2, 77, 2, 1, 19, 2, 6, 76, 2, 3, 18, 2, 14, 75, 2, 7, 17, 2, 2, 74, 2, 1, 16, 2, 1360, 196, 0],
    0,
  ], // 197
  [
    'Act 4 (N) Equip C',
    0,
    0,
    0,
    0,
    1700,
    0,
    1,
    [2, 2, 77, 2, 1, 19, 2, 6, 76, 2, 3, 18, 2, 14, 75, 2, 7, 17, 2, 6, 74, 2, 3, 16, 2, 1658, 196, 0],
    0,
  ], // 198
  [
    'Act 5 (N) Equip A',
    0,
    0,
    0,
    0,
    1481,
    0,
    1,
    [2, 2, 76, 2, 1, 18, 2, 6, 75, 2, 3, 17, 2, 14, 74, 2, 7, 16, 2, 2, 73, 2, 1, 15, 2, 1445, 197, 0],
    0,
  ], // 199
  [
    'Act 5 (N) Equip B',
    0,
    0,
    0,
    0,
    1785,
    0,
    1,
    [2, 2, 76, 2, 1, 18, 2, 6, 75, 2, 3, 17, 2, 14, 74, 2, 7, 16, 2, 6, 73, 2, 3, 15, 2, 1743, 199, 0],
    0,
  ], // 200
  [
    'Act 5 (N) Equip C',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 75, 2, 1, 17, 2, 6, 74, 2, 3, 16, 2, 14, 73, 2, 7, 15, 2, 2, 72, 2, 1, 14, 2, 1530, 200, 0],
    0,
  ], // 201
  [
    'Act 1 (H) Equip A',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 75, 2, 1, 17, 2, 6, 74, 2, 3, 16, 2, 14, 73, 2, 7, 15, 2, 6, 72, 2, 3, 14, 2, 1785, 201, 0],
    0,
  ], // 202
  [
    'Act 1 (H) Equip B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 74, 2, 1, 16, 2, 6, 73, 2, 3, 15, 2, 14, 72, 2, 7, 14, 2, 2, 71, 2, 1, 13, 2, 1530, 202, 0],
    0,
  ], // 203
  [
    'Act 1 (H) Equip C',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 74, 2, 1, 16, 2, 6, 73, 2, 3, 15, 2, 14, 72, 2, 7, 14, 2, 6, 71, 2, 3, 13, 2, 1785, 202, 0],
    0,
  ], // 204
  [
    'Act 2 (H) Equip A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 73, 2, 1, 15, 2, 6, 72, 2, 3, 14, 2, 14, 71, 2, 7, 13, 2, 2, 70, 2, 1, 12, 2, 1530, 203, 0],
    0,
  ], // 205
  [
    'Act 2 (H) Equip B',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 73, 2, 1, 15, 2, 6, 72, 2, 3, 14, 2, 14, 71, 2, 7, 13, 2, 6, 70, 2, 3, 12, 2, 1785, 205, 0],
    0,
  ], // 206
  [
    'Act 2 (H) Equip C',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 72, 2, 1, 14, 2, 6, 71, 2, 3, 13, 2, 14, 70, 2, 7, 12, 2, 2, 69, 2, 1, 11, 2, 1530, 205, 0],
    0,
  ], // 207
  [
    'Act 3 (H) Equip A',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 72, 2, 1, 14, 2, 6, 71, 2, 3, 13, 2, 14, 70, 2, 7, 12, 2, 6, 69, 2, 3, 11, 2, 1785, 206, 0],
    0,
  ], // 208
  [
    'Act 3 (H) Equip B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 71, 2, 1, 13, 2, 6, 70, 2, 3, 12, 2, 14, 69, 2, 7, 11, 2, 2, 68, 2, 1, 10, 2, 1530, 208, 0],
    0,
  ], // 209
  [
    'Act 3 (H) Equip C',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 71, 2, 1, 13, 2, 6, 70, 2, 3, 12, 2, 14, 69, 2, 7, 11, 2, 6, 68, 2, 3, 10, 2, 1785, 208, 0],
    0,
  ], // 210
  [
    'Act 4 (H) Equip A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 70, 2, 1, 12, 2, 6, 69, 2, 3, 11, 2, 14, 68, 2, 7, 10, 2, 2, 67, 2, 1, 9, 2, 1530, 209, 0],
    0,
  ], // 211
  [
    'Act 4 (H) Equip B',
    0,
    0,
    0,
    0,
    1827,
    0,
    1,
    [2, 2, 70, 2, 1, 12, 2, 6, 69, 2, 3, 11, 2, 14, 68, 2, 7, 10, 2, 6, 67, 2, 3, 9, 2, 1785, 211, 0],
    0,
  ], // 212
  [
    'Act 4 (H) Equip C',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 69, 2, 1, 11, 2, 6, 68, 2, 3, 10, 2, 14, 67, 2, 7, 9, 2, 2, 66, 2, 1, 8, 2, 1530, 211, 0],
    0,
  ], // 213
  [
    'Act 5 (H) Equip A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 68, 2, 1, 10, 2, 6, 67, 2, 3, 9, 2, 14, 66, 2, 7, 8, 2, 2, 65, 2, 1, 7, 2, 1530, 212, 0],
    0,
  ], // 214
  [
    'Act 5 (H) Equip B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 67, 2, 1, 9, 2, 6, 66, 2, 3, 8, 2, 14, 65, 2, 7, 7, 2, 2, 64, 2, 1, 6, 2, 1530, 214, 0],
    0,
  ], // 215
  [
    'Act 5 (H) Equip C',
    0,
    0,
    0,
    0,
    1565,
    0,
    1,
    [2, 2, 66, 2, 1, 8, 2, 6, 65, 2, 3, 7, 2, 14, 64, 2, 7, 6, 2, 1, 64, 2, 1, 6, 2, 1530, 215, 0],
    0,
  ], // 216
  ['Act 1 Melee A', 0, 0, 0, 0, 14, 0, 1, [2, 7, 63, 2, 7, 34, 0], 0, 2, 0], // 217
  ['Act 1 Melee B', 0, 0, 0, 0, 20, 0, 1, [2, 3, 63, 2, 3, 34, 2, 7, 62, 2, 7, 33, 0], 0, 2, 5], // 218
  ['Act 1 Melee C', 0, 0, 0, 0, 36, 0, 1, [2, 2, 63, 2, 1, 34, 2, 6, 62, 2, 3, 33, 2, 14, 61, 2, 7, 32, 2, 2, 60, 2, 1, 31, 0], 0, 2, 9], // 219
  [
    'Act 2 Melee A',
    0,
    0,
    0,
    0,
    66,
    0,
    1,
    [2, 2, 62, 2, 1, 33, 2, 6, 61, 2, 3, 32, 2, 14, 60, 2, 7, 31, 2, 2, 59, 2, 1, 30, 2, 30, 219, 0],
    0,
    2,
    12,
  ], // 220
  [
    'Act 2 Melee B',
    0,
    0,
    0,
    0,
    96,
    0,
    1,
    [2, 2, 61, 2, 1, 32, 2, 6, 60, 2, 3, 31, 2, 14, 59, 2, 7, 30, 2, 2, 58, 2, 1, 29, 2, 60, 220, 0],
    0,
    2,
    15,
  ], // 221
  [
    'Act 2 Melee C',
    0,
    0,
    0,
    0,
    126,
    0,
    1,
    [2, 2, 60, 2, 1, 31, 2, 6, 59, 2, 3, 30, 2, 14, 58, 2, 7, 29, 2, 2, 57, 2, 1, 28, 2, 90, 221, 0],
    0,
    2,
    18,
  ], // 222
  [
    'Act 3 Melee A',
    0,
    0,
    0,
    0,
    156,
    0,
    1,
    [2, 2, 59, 2, 1, 30, 2, 6, 58, 2, 3, 29, 2, 14, 57, 2, 7, 28, 2, 2, 56, 2, 1, 27, 2, 120, 222, 0],
    0,
    2,
    20,
  ], // 223
  [
    'Act 3 Melee B',
    0,
    0,
    0,
    0,
    186,
    0,
    1,
    [2, 2, 58, 2, 1, 29, 2, 6, 57, 2, 3, 28, 2, 14, 56, 2, 7, 27, 2, 2, 55, 2, 1, 26, 2, 150, 223, 0],
    0,
    2,
    22,
  ], // 224
  [
    'Act 3 Melee C',
    0,
    0,
    0,
    0,
    216,
    0,
    1,
    [2, 2, 57, 2, 1, 28, 2, 6, 56, 2, 3, 27, 2, 14, 55, 2, 7, 26, 2, 2, 54, 2, 1, 25, 2, 180, 224, 0],
    0,
    2,
    24,
  ], // 225
  [
    'Act 4 Melee A',
    0,
    0,
    0,
    0,
    243,
    0,
    1,
    [2, 2, 56, 2, 1, 27, 2, 6, 55, 2, 3, 26, 2, 14, 54, 2, 4, 25, 2, 2, 53, 2, 1, 24, 2, 210, 225, 0],
    0,
    2,
    26,
  ], // 226
  [
    'Act 4 Melee B',
    0,
    0,
    0,
    0,
    292,
    0,
    1,
    [2, 2, 55, 2, 1, 26, 2, 6, 54, 2, 3, 25, 2, 14, 53, 2, 3, 24, 2, 2, 52, 2, 1, 23, 2, 260, 226, 0],
    0,
    2,
    29,
  ], // 227
  [
    'Act 5 Melee A',
    0,
    0,
    0,
    0,
    441,
    0,
    1,
    [2, 2, 55, 2, 1, 26, 2, 6, 54, 2, 3, 25, 2, 14, 53, 2, 3, 24, 2, 7, 52, 2, 5, 23, 2, 400, 227, 0],
    0,
    2,
    32,
  ], // 228
  [
    'Act 5 Melee B',
    0,
    0,
    0,
    0,
    524,
    0,
    1,
    [2, 2, 54, 2, 1, 25, 2, 6, 53, 2, 2, 24, 2, 14, 52, 2, 7, 23, 2, 2, 51, 2, 1, 22, 2, 489, 228, 0],
    0,
    2,
    34,
  ], // 229
  [
    'Act 5 Melee C',
    0,
    0,
    0,
    0,
    664,
    0,
    1,
    [2, 2, 54, 2, 1, 25, 2, 6, 53, 2, 2, 24, 2, 14, 52, 2, 7, 23, 2, 2, 51, 2, 1, 22, 2, 629, 229, 0],
    0,
    2,
    36,
  ], // 230
  [
    'Act 1 (N) Melee A',
    0,
    0,
    0,
    0,
    805,
    0,
    1,
    [2, 2, 82, 2, 1, 24, 2, 6, 81, 2, 3, 23, 2, 14, 80, 2, 7, 22, 2, 2, 79, 2, 1, 21, 2, 769, 230, 0],
    0,
    2,
    38,
  ], // 231
  [
    'Act 1 (N) Melee B',
    0,
    0,
    0,
    0,
    971,
    0,
    1,
    [2, 2, 81, 2, 1, 23, 2, 6, 80, 2, 3, 22, 2, 14, 79, 2, 7, 21, 2, 2, 78, 2, 1, 20, 2, 935, 231, 0],
    0,
    2,
    40,
  ], // 232
  [
    'Act 2 (N) Melee A',
    0,
    0,
    0,
    0,
    1190,
    0,
    1,
    [2, 2, 81, 2, 1, 23, 2, 6, 80, 2, 3, 22, 2, 14, 79, 2, 7, 21, 2, 6, 78, 2, 3, 20, 2, 1148, 232, 0],
    0,
    2,
    42,
  ], // 233
  [
    'Act 2 (N) Melee B',
    0,
    0,
    0,
    0,
    1056,
    0,
    1,
    [2, 2, 80, 2, 1, 22, 2, 6, 79, 2, 3, 21, 2, 14, 78, 2, 7, 20, 2, 2, 77, 2, 1, 19, 2, 1020, 233, 0],
    0,
    2,
    44,
  ], // 234
  [
    'Act 3 (N) Melee A',
    0,
    0,
    0,
    0,
    1099,
    0,
    1,
    [2, 2, 79, 2, 1, 21, 2, 6, 78, 2, 3, 20, 2, 14, 77, 2, 7, 19, 2, 2, 76, 2, 1, 18, 2, 1063, 234, 0],
    0,
    2,
    46,
  ], // 235
  [
    'Act 3 (N) Melee B',
    0,
    0,
    0,
    0,
    1141,
    0,
    1,
    [2, 2, 78, 2, 1, 20, 2, 6, 77, 2, 3, 19, 2, 14, 76, 2, 7, 18, 2, 2, 75, 2, 1, 17, 2, 1105, 235, 0],
    0,
    2,
    48,
  ], // 236
  [
    'Act 4 (N) Melee A',
    0,
    0,
    0,
    0,
    1381,
    0,
    1,
    [2, 2, 78, 2, 1, 20, 2, 6, 77, 2, 3, 19, 2, 14, 76, 2, 7, 18, 2, 6, 75, 2, 3, 17, 2, 1339, 236, 0],
    0,
    2,
    51,
  ], // 237
  [
    'Act 4 (N) Melee B',
    0,
    0,
    0,
    0,
    1226,
    0,
    1,
    [2, 2, 77, 2, 1, 19, 2, 6, 76, 2, 3, 18, 2, 14, 75, 2, 7, 17, 2, 2, 74, 2, 1, 16, 2, 1190, 237, 0],
    0,
    2,
    54,
  ], // 238
  [
    'Act 5 (N) Melee A',
    0,
    0,
    0,
    0,
    1311,
    0,
    1,
    [2, 2, 76, 2, 1, 18, 2, 6, 75, 2, 3, 17, 2, 14, 74, 2, 7, 16, 2, 2, 73, 2, 1, 15, 2, 1275, 238, 0],
    0,
    2,
    57,
  ], // 239
  [
    'Act 5 (N) Melee B',
    0,
    0,
    0,
    0,
    1785,
    0,
    1,
    [2, 2, 76, 2, 1, 18, 2, 6, 75, 2, 3, 17, 2, 14, 74, 2, 7, 16, 2, 6, 73, 2, 3, 15, 2, 1743, 239, 0],
    0,
    2,
    60,
  ], // 240
  [
    'Act 5 (N) Melee C',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 75, 2, 1, 17, 2, 6, 74, 2, 3, 16, 2, 14, 73, 2, 7, 15, 2, 2, 72, 2, 1, 14, 2, 1530, 240, 0],
    0,
    2,
    63,
  ], // 241
  [
    'Act 1 (H) Melee A',
    0,
    0,
    0,
    0,
    1823,
    0,
    1,
    [2, 2, 75, 2, 1, 17, 2, 6, 74, 2, 3, 16, 2, 14, 73, 2, 7, 15, 2, 6, 72, 2, 3, 14, 2, 1781, 241, 0],
    0,
    2,
    66,
  ], // 242
  [
    'Act 1 (H) Melee B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 74, 2, 1, 16, 2, 6, 73, 2, 3, 15, 2, 14, 72, 2, 7, 14, 2, 2, 71, 2, 1, 13, 2, 1530, 242, 0],
    0,
    2,
    68,
  ], // 243
  [
    'Act 2 (H) Melee A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 73, 2, 1, 15, 2, 6, 72, 2, 3, 14, 2, 14, 71, 2, 7, 13, 2, 2, 70, 2, 1, 12, 2, 1530, 243, 0],
    0,
    2,
    71,
  ], // 244
  [
    'Act 2 (H) Melee B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 72, 2, 1, 14, 2, 6, 71, 2, 3, 13, 2, 14, 70, 2, 7, 12, 2, 2, 69, 2, 1, 11, 2, 1530, 244, 0],
    0,
    2,
    73,
  ], // 245
  [
    'Act 3 (H) Melee A',
    0,
    0,
    0,
    0,
    1823,
    0,
    1,
    [2, 2, 72, 2, 1, 14, 2, 6, 71, 2, 3, 13, 2, 14, 70, 2, 7, 12, 2, 6, 69, 2, 3, 11, 2, 1781, 245, 0],
    0,
    2,
    75,
  ], // 246
  [
    'Act 3 (H) Melee B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 71, 2, 1, 13, 2, 6, 70, 2, 3, 12, 2, 14, 69, 2, 7, 11, 2, 2, 68, 2, 1, 10, 2, 1530, 246, 0],
    0,
    2,
    77,
  ], // 247
  [
    'Act 4 (H) Melee A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 70, 2, 1, 12, 2, 6, 69, 2, 3, 11, 2, 14, 68, 2, 7, 10, 2, 2, 67, 2, 1, 9, 2, 1530, 247, 0],
    0,
    2,
    79,
  ], // 248
  [
    'Act 4 (H) Melee B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 69, 2, 1, 11, 2, 6, 68, 2, 3, 10, 2, 14, 67, 2, 7, 9, 2, 2, 66, 2, 1, 8, 2, 1530, 248, 0],
    0,
    2,
    80,
  ], // 249
  [
    'Act 5 (H) Melee A',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 68, 2, 1, 10, 2, 6, 67, 2, 3, 9, 2, 14, 66, 2, 7, 8, 2, 2, 65, 2, 1, 7, 2, 1530, 249, 0],
    0,
    2,
    81,
  ], // 250
  [
    'Act 5 (H) Melee B',
    0,
    0,
    0,
    0,
    1566,
    0,
    1,
    [2, 2, 67, 2, 1, 9, 2, 6, 66, 2, 3, 8, 2, 14, 65, 2, 7, 7, 2, 2, 64, 2, 1, 6, 2, 1530, 250, 0],
    0,
    2,
    83,
  ], // 251
  [
    'Act 5 (H) Melee C',
    0,
    0,
    0,
    0,
    1565,
    0,
    1,
    [2, 2, 66, 2, 1, 8, 2, 6, 65, 2, 3, 7, 2, 14, 64, 2, 7, 6, 2, 1, 64, 2, 1, 6, 2, 1530, 251, 0],
    0,
    2,
    85,
  ], // 252
  ['Act 1 Bow A', 0, 0, 0, 0, 36, 0, 1, [2, 36, 115, 0], 0], // 253
  ['Act 1 Bow B', 0, 0, 0, 0, 36, 0, 1, [2, 20, 115, 2, 16, 114, 0], 0], // 254
  ['Act 1 Bow C', 0, 0, 0, 0, 36, 0, 1, [2, 10, 115, 2, 16, 114, 2, 10, 113, 0], 0], // 255
  ['Act 2 Bow A', 0, 0, 0, 0, 36, 0, 1, [2, 3, 115, 2, 15, 114, 2, 15, 113, 2, 3, 112, 0], 0], // 256
  ['Act 2 Bow B', 0, 0, 0, 0, 36, 0, 1, [2, 1, 115, 2, 7, 114, 2, 20, 113, 2, 7, 112, 0], 0], // 257
  ['Act 2 Bow C', 0, 0, 0, 0, 36, 0, 1, [2, 1, 115, 2, 3, 114, 2, 14, 113, 2, 14, 112, 2, 1, 111, 0], 0], // 258
  ['Act 3 Bow A', 0, 0, 0, 0, 32, 0, 1, [2, 1, 115, 2, 3, 114, 2, 6, 113, 2, 12, 112, 2, 3, 111, 0], 0], // 259
  ['Act 3 Bow B', 0, 0, 0, 0, 36, 0, 1, [2, 1, 115, 2, 3, 114, 2, 6, 113, 2, 8, 112, 2, 6, 111, 2, 1, 110, 0], 0], // 260
  ['Act 3 Bow C', 0, 0, 0, 0, 36, 0, 1, [2, 1, 115, 2, 2, 114, 2, 5, 113, 2, 7, 112, 2, 7, 111, 2, 2, 110, 0], 0], // 261
  ['Act 4 Bow A', 0, 0, 0, 0, 146, 0, 1, [2, 1, 114, 2, 3, 113, 2, 6, 112, 2, 8, 111, 2, 3, 110, 2, 1, 109, 2, 110, 261, 0], 0], // 262
  ['Act 4 Bow B', 0, 0, 0, 0, 216, 0, 1, [2, 1, 114, 2, 2, 113, 2, 5, 112, 2, 8, 111, 2, 4, 110, 2, 2, 109, 2, 180, 262, 0], 0], // 263
  ['Act 5 Bow A', 0, 0, 0, 0, 306, 0, 1, [2, 1, 113, 2, 3, 112, 2, 8, 111, 2, 6, 110, 2, 3, 109, 2, 1, 108, 2, 270, 263, 0], 0], // 264
  ['Act 5 Bow B', 0, 0, 0, 0, 416, 0, 1, [2, 1, 113, 2, 2, 112, 2, 7, 111, 2, 7, 110, 2, 4, 109, 2, 2, 108, 2, 380, 264, 0], 0], // 265
  ['Act 5 Bow C', 0, 0, 0, 0, 536, 0, 1, [2, 1, 113, 2, 2, 112, 2, 7, 111, 2, 7, 110, 2, 4, 109, 2, 2, 108, 2, 500, 265, 0], 0], // 266
  ['Act 1 (N) Bow A', 0, 0, 0, 0, 666, 0, 1, [2, 1, 112, 2, 5, 111, 2, 8, 110, 2, 7, 109, 2, 4, 108, 2, 2, 107, 2, 630, 266, 0], 0], // 267
  ['Act 1 (N) Bow B', 0, 0, 0, 0, 806, 0, 1, [2, 2, 111, 2, 7, 110, 2, 8, 109, 2, 7, 108, 2, 4, 107, 2, 2, 106, 2, 770, 267, 0], 0], // 268
  [
    'Act 2 (N) Bow A',
    0,
    0,
    0,
    0,
    956,
    0,
    1,
    [2, 1, 111, 2, 5, 110, 2, 7, 109, 2, 8, 108, 2, 7, 107, 2, 4, 106, 2, 2, 105, 2, 920, 268, 0],
    0,
  ], // 269
  ['Act 2 (N) Bow B', 0, 0, 0, 0, 1106, 0, 1, [2, 2, 110, 2, 5, 109, 2, 7, 108, 2, 8, 107, 2, 7, 106, 2, 4, 105, 2, 1070, 269, 0], 0], // 270
  [
    'Act 3 (N) Bow A',
    0,
    0,
    0,
    0,
    1256,
    0,
    1,
    [2, 1, 110, 2, 2, 109, 2, 5, 108, 2, 7, 107, 2, 8, 106, 2, 7, 105, 2, 2, 104, 2, 1220, 270, 0],
    0,
  ], // 271
  [
    'Act 3 (N) Bow B',
    0,
    0,
    0,
    0,
    1406,
    0,
    1,
    [2, 1, 109, 2, 2, 108, 2, 5, 107, 2, 7, 106, 2, 8, 105, 2, 4, 104, 2, 2, 103, 2, 1370, 271, 0],
    0,
  ], // 272
  [
    'Act 4 (N) Bow A',
    0,
    0,
    0,
    0,
    1556,
    0,
    1,
    [2, 1, 108, 2, 2, 107, 2, 5, 106, 2, 7, 105, 2, 7, 104, 2, 4, 103, 2, 2, 102, 2, 1520, 272, 0],
    0,
  ], // 273
  [
    'Act 4 (N) Bow B',
    0,
    0,
    0,
    0,
    1706,
    0,
    1,
    [2, 1, 107, 2, 2, 106, 2, 5, 105, 2, 8, 104, 2, 7, 103, 2, 4, 102, 2, 2, 101, 2, 1670, 273, 0],
    0,
  ], // 274
  [
    'Act 5 (N) Bow A',
    0,
    0,
    0,
    0,
    1856,
    0,
    1,
    [2, 1, 106, 2, 2, 105, 2, 7, 104, 2, 8, 103, 2, 7, 102, 2, 4, 101, 2, 2, 100, 2, 1820, 274, 0],
    0,
  ], // 275
  [
    'Act 5 (N) Bow B',
    0,
    0,
    0,
    0,
    2006,
    0,
    1,
    [2, 1, 105, 2, 5, 104, 2, 7, 103, 2, 8, 102, 2, 7, 101, 2, 4, 100, 2, 2, 99, 2, 1970, 275, 0],
    0,
  ], // 276
  [
    'Act 5 (N) Bow C',
    0,
    0,
    0,
    0,
    2156,
    0,
    1,
    [2, 2, 104, 2, 5, 103, 2, 7, 102, 2, 8, 101, 2, 7, 100, 2, 4, 99, 2, 2, 98, 2, 2120, 276, 0],
    0,
  ], // 277
  [
    'Act 1 (H) Bow A',
    0,
    0,
    0,
    0,
    2306,
    0,
    1,
    [2, 1, 104, 2, 2, 103, 2, 5, 102, 2, 7, 101, 2, 8, 100, 2, 7, 99, 2, 4, 98, 2, 2270, 277, 0],
    0,
  ], // 278
  [
    'Act 1 (H) Bow B',
    0,
    0,
    0,
    0,
    2306,
    0,
    1,
    [2, 1, 103, 2, 2, 102, 2, 5, 101, 2, 7, 100, 2, 8, 99, 2, 7, 98, 2, 2, 97, 2, 2270, 278, 0],
    0,
  ], // 279
  [
    'Act 2 (H) Bow A',
    0,
    0,
    0,
    0,
    2306,
    0,
    1,
    [2, 1, 102, 2, 2, 101, 2, 5, 100, 2, 7, 99, 2, 8, 98, 2, 4, 97, 2, 2, 96, 2, 2270, 279, 0],
    0,
  ], // 280
  ['Act 2 (H) Bow B', 0, 0, 0, 0, 2306, 0, 1, [2, 1, 101, 2, 2, 100, 2, 5, 99, 2, 7, 98, 2, 7, 97, 2, 4, 96, 2, 2270, 280, 0], 0], // 281
  ['Act 3 (H) Bow A', 0, 0, 0, 0, 2306, 0, 1, [2, 1, 100, 2, 2, 99, 2, 5, 98, 2, 8, 97, 2, 7, 96, 2, 2, 95, 2, 2270, 281, 0], 0], // 282
  ['Act 3 (H) Bow B', 0, 0, 0, 0, 2306, 0, 1, [2, 1, 99, 2, 2, 98, 2, 7, 97, 2, 8, 96, 2, 4, 95, 2, 2, 94, 2, 2270, 282, 0], 0], // 283
  ['Act 4 (H) Bow A', 0, 0, 0, 0, 2306, 0, 1, [2, 1, 98, 2, 5, 97, 2, 7, 96, 2, 7, 95, 2, 4, 94, 2, 2270, 283, 0], 0], // 284
  ['Act 4 (H) Bow B', 0, 0, 0, 0, 2113, 0, 1, [2, 2, 97, 2, 5, 96, 2, 8, 95, 2, 7, 94, 2, 2080, 284, 0], 0], // 285
  ['Act 5 (H) Bow A', 0, 0, 0, 0, 2113, 0, 1, [2, 1, 97, 2, 2, 96, 2, 7, 95, 2, 8, 94, 2, 2, 93, 2, 1, 93, 2, 2080, 285, 0], 0], // 286
  ['Act 5 (H) Bow B', 0, 0, 0, 0, 2113, 0, 1, [2, 1, 96, 2, 5, 95, 2, 7, 94, 2, 7, 93, 2, 2, 93, 2, 1, 93, 2, 2080, 286, 0], 0], // 287
  ['Act 5 (H) Bow C', 0, 0, 0, 0, 1681, 0, 1, [2, 2, 95, 2, 5, 94, 2, 8, 93, 2, 1, 93, 2, 1, 93, 2, 1, 93, 2, 1655, 287, 0], 0], // 288
  ['Act 1 Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 6, 370, 0], 0], // 289
  ['Act 1 Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 4, 370, 1, 2, 332, 0], 0], // 290
  ['Act 1 Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 370, 1, 4, 332, 0], 0], // 291
  ['Act 2 Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 1, 370, 1, 4, 332, 1, 1, 353, 0], 0], // 292
  ['Act 2 Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 1, 370, 1, 3, 332, 1, 2, 353, 0], 0], // 293
  ['Act 2 Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 332, 1, 3, 353, 1, 1, 252, 0], 0], // 294
  ['Act 3 Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 4, 353, 1, 2, 252, 0], 0], // 295
  ['Act 3 Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 3, 353, 1, 3, 252, 0], 0], // 296
  ['Act 3 Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 353, 1, 3, 252, 1, 1, 512, 0], 0], // 297
  ['Act 4 Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 1, 353, 1, 4, 252, 1, 1, 512, 0], 0], // 298
  ['Act 4 Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 4, 252, 1, 2, 512, 0], 0], // 299
  ['Act 5 Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 3, 252, 1, 3, 512, 0], 0], // 300
  ['Act 5 Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 301
  ['Act 5 Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 302
  ['Act 1 (N) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 303
  ['Act 1 (N) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 304
  ['Act 2 (N) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 305
  ['Act 2 (N) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 306
  ['Act 3 (N) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 307
  ['Act 3 (N) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 308
  ['Act 4 (N) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 309
  ['Act 4 (N) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 310
  ['Act 5 (N) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 311
  ['Act 5 (N) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 312
  ['Act 5 (N) Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 313
  ['Act 1 (H) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 314
  ['Act 1 (H) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 315
  ['Act 2 (H) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 316
  ['Act 2 (H) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 317
  ['Act 3 (H) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 318
  ['Act 3 (H) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 319
  ['Act 4 (H) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 320
  ['Act 4 (H) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 321
  ['Act 5 (H) Magic A', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 322
  ['Act 5 (H) Magic B', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 323
  ['Act 5 (H) Magic C', 0, 0, 0, 0, 12, 0, 1, [1, 2, 455, 1, 4, 454, 1, 2, 252, 1, 4, 512, 0], 0], // 324
  ['Act 1 Good', 0, 0, 0, 0, 10, 0, 1, [2, 5, 117, 2, 5, 120, 0], 0], // 325
  ['Act 2 Good', 0, 0, 0, 0, 42, 0, 1, [2, 20, 118, 2, 12, 120, 2, 4, 121, 2, 3, 141, 2, 3, 142, 0], 0], // 326
  ['Act 3 Good', 0, 0, 0, 0, 42, 0, 1, [2, 20, 119, 2, 8, 120, 2, 8, 121, 2, 3, 143, 2, 3, 144, 0], 0], // 327
  ['Act 4 Good', 0, 0, 0, 0, 155, 0, 1, [2, 50, 119, 2, 14, 120, 2, 28, 121, 2, 42, 122, 2, 14, 145, 2, 7, 146, 0], 0], // 328
  ['Act 5 Good', 0, 0, 0, 0, 102, 0, 1, [2, 50, 119, 2, 6, 120, 2, 8, 121, 2, 24, 122, 2, 4, 123, 2, 10, 147, 0], 0], // 329
  ['Act 1 (N) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 6, 120, 2, 8, 121, 2, 28, 122, 2, 14, 123, 2, 14, 148, 0], 0], // 330
  ['Act 2 (N) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 149, 0], 0], // 331
  ['Act 3 (N) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 150, 0], 0], // 332
  ['Act 4 (N) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 151, 0], 0], // 333
  ['Act 5 (N) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 152, 0], 0], // 334
  ['Act 1 (H) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 153, 0], 0], // 335
  ['Act 2 (H) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 154, 0], 0], // 336
  ['Act 3 (H) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 155, 0], 0], // 337
  ['Act 4 (H) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 156, 0], 0], // 338
  ['Act 5 (H) Good', 0, 0, 0, 0, 130, 0, 1, [2, 60, 119, 2, 4, 120, 2, 10, 121, 2, 14, 122, 2, 28, 123, 2, 14, 157, 0], 0], // 339
  ['Act 1 Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 158, 2, 10, 173, 2, 2, 325, 0], 0], // 340
  ['Act 1 Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 158, 2, 10, 174, 2, 2, 325, 0], 0], // 341
  ['Act 1 Chest C', 0, 0, 0, 0, 147, 100, 4, [1, 15, 229, 2, 12, 158, 2, 3, 159, 2, 15, 175, 2, 2, 325, 0], 0], // 342
  ['Act 2 Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 159, 2, 10, 176, 2, 2, 326, 0], 0], // 343
  ['Act 2 Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 159, 2, 10, 177, 2, 2, 326, 0], 0], // 344
  ['Act 2 Chest C', 0, 0, 0, 0, 147, 100, 4, [1, 15, 229, 2, 12, 159, 2, 3, 160, 2, 15, 178, 2, 2, 326, 0], 0], // 345
  ['Act 3 Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 160, 2, 10, 179, 2, 2, 327, 0], 0], // 346
  ['Act 3 Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 160, 2, 10, 180, 2, 2, 327, 0], 0], // 347
  ['Act 3 Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 160, 2, 10, 181, 2, 2, 327, 0], 0], // 348
  ['Act 4 Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 161, 2, 10, 182, 2, 2, 328, 0], 0], // 349
  ['Act 4 Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 161, 2, 10, 183, 2, 2, 328, 0], 0], // 350
  ['Act 4 Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 161, 2, 10, 183, 2, 2, 328, 0], 0], // 351
  ['Act 5 Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 162, 2, 10, 184, 2, 2, 329, 0], 0], // 352
  ['Act 5 Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 162, 2, 10, 185, 2, 2, 329, 0], 0], // 353
  ['Act 5 Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 162, 2, 10, 185, 2, 2, 329, 0], 0], // 354
  ['Act 1 (N) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 163, 2, 10, 187, 2, 2, 330, 0], 0], // 355
  ['Act 1 (N) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 163, 2, 10, 188, 2, 2, 330, 0], 0], // 356
  ['Act 1 (N) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 163, 2, 10, 188, 2, 2, 330, 0], 0], // 357
  ['Act 2 (N) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 164, 2, 10, 190, 2, 2, 331, 0], 0], // 358
  ['Act 2 (N) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 164, 2, 10, 191, 2, 2, 331, 0], 0], // 359
  ['Act 2 (N) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 164, 2, 10, 191, 2, 2, 331, 0], 0], // 360
  ['Act 3 (N) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 165, 2, 10, 193, 2, 2, 332, 0], 0], // 361
  ['Act 3 (N) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 165, 2, 10, 194, 2, 2, 332, 0], 0], // 362
  ['Act 3 (N) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 165, 2, 10, 194, 2, 2, 332, 0], 0], // 363
  ['Act 4 (N) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 166, 2, 10, 196, 2, 2, 333, 0], 0], // 364
  ['Act 4 (N) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 166, 2, 10, 197, 2, 2, 333, 0], 0], // 365
  ['Act 4 (N) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 166, 2, 10, 198, 2, 2, 333, 0], 0], // 366
  ['Act 5 (N) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 167, 2, 10, 199, 2, 2, 334, 0], 0], // 367
  ['Act 5 (N) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 167, 2, 10, 200, 2, 2, 334, 0], 0], // 368
  ['Act 5 (N) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 167, 2, 10, 200, 2, 2, 334, 0], 0], // 369
  ['Act 1 (H) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 168, 2, 10, 202, 2, 2, 335, 0], 0], // 370
  ['Act 1 (H) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 168, 2, 10, 203, 2, 2, 335, 0], 0], // 371
  ['Act 1 (H) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 168, 2, 10, 203, 2, 2, 335, 0], 0], // 372
  ['Act 2 (H) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 169, 2, 10, 205, 2, 2, 336, 0], 0], // 373
  ['Act 2 (H) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 169, 2, 10, 206, 2, 2, 336, 0], 0], // 374
  ['Act 2 (H) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 169, 2, 10, 206, 2, 2, 336, 0], 0], // 375
  ['Act 3 (H) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 170, 2, 10, 208, 2, 2, 337, 0], 0], // 376
  ['Act 3 (H) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 170, 2, 10, 209, 2, 2, 337, 0], 0], // 377
  ['Act 3 (H) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 170, 2, 10, 209, 2, 2, 337, 0], 0], // 378
  ['Act 4 (H) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 171, 2, 10, 211, 2, 2, 338, 0], 0], // 379
  ['Act 4 (H) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 171, 2, 10, 212, 2, 2, 338, 0], 0], // 380
  ['Act 4 (H) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 171, 2, 10, 212, 2, 2, 338, 0], 0], // 381
  ['Act 5 (H) Chest A', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 172, 2, 10, 214, 2, 2, 339, 0], 0], // 382
  ['Act 5 (H) Chest B', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 172, 2, 10, 215, 2, 2, 339, 0], 0], // 383
  ['Act 5 (H) Chest C', 0, 0, 0, 0, 142, 100, 4, [1, 15, 229, 2, 15, 172, 2, 10, 215, 2, 2, 339, 0], 0], // 384
  ['Act 1 H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 173, 2, 21, 158, 2, 2, 325, 0], 0, 7, 0], // 385
  ['Act 1 H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 174, 2, 21, 158, 2, 2, 325, 0], 0, 7, 5], // 386
  ['Act 1 H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 175, 2, 21, 158, 2, 2, 325, 0], 0, 7, 9], // 387
  ['Act 2 H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 176, 2, 21, 159, 2, 2, 326, 0], 0, 7, 12], // 388
  ['Act 2 H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 177, 2, 21, 159, 2, 2, 326, 0], 0, 7, 15], // 389
  ['Act 2 H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 178, 2, 21, 159, 2, 2, 327, 0], 0, 7, 18], // 390
  ['Act 3 H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 179, 2, 21, 160, 2, 2, 327, 0], 0, 7, 20], // 391
  ['Act 3 H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 180, 2, 21, 160, 2, 2, 327, 0], 0, 7, 22], // 392
  ['Act 3 H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 181, 2, 21, 160, 2, 2, 327, 0], 0, 7, 24], // 393
  ['Act 4 H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 182, 2, 21, 161, 2, 2, 328, 0], 0, 7, 26], // 394
  ['Act 4 H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 183, 2, 21, 161, 2, 2, 328, 0], 0, 7, 29], // 395
  ['Act 5 H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 184, 2, 21, 162, 2, 2, 329, 0], 0, 7, 32], // 396
  ['Act 5 H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 185, 2, 21, 162, 2, 2, 329, 0], 0, 7, 34], // 397
  ['Act 5 H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 186, 2, 21, 162, 2, 2, 329, 0], 0, 7, 36], // 398
  ['Act 1 (N) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 187, 2, 21, 163, 2, 2, 330, 0], 0, 7, 38], // 399
  ['Act 1 (N) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 188, 2, 21, 163, 2, 2, 330, 0], 0, 7, 40], // 400
  ['Act 1 (N) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 188, 2, 21, 163, 2, 2, 330, 0], 0, 7, 41], // 401
  ['Act 2 (N) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 190, 2, 21, 164, 2, 2, 331, 0], 0, 7, 42], // 402
  ['Act 2 (N) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 191, 2, 21, 164, 2, 2, 331, 0], 0, 7, 44], // 403
  ['Act 2 (N) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 191, 2, 21, 164, 2, 2, 331, 0], 0, 7, 45], // 404
  ['Act 3 (N) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 193, 2, 21, 165, 2, 2, 332, 0], 0, 7, 46], // 405
  ['Act 3 (N) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 194, 2, 21, 165, 2, 2, 332, 0], 0, 7, 48], // 406
  ['Act 3 (N) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 194, 2, 21, 165, 2, 2, 332, 0], 0, 7, 50], // 407
  ['Act 4 (N) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 196, 2, 21, 166, 2, 2, 333, 0], 0, 7, 51], // 408
  ['Act 4 (N) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 197, 2, 21, 166, 2, 2, 333, 0], 0, 7, 54], // 409
  ['Act 5 (N) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 199, 2, 21, 167, 2, 2, 334, 0], 0, 7, 57], // 410
  ['Act 5 (N) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 200, 2, 21, 167, 2, 2, 334, 0], 0, 7, 60], // 411
  ['Act 5 (N) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 201, 2, 21, 167, 2, 2, 334, 0], 0, 7, 62], // 412
  ['Act 1 (H) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 202, 2, 21, 168, 2, 2, 335, 0], 0, 7, 63], // 413
  ['Act 1 (H) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 203, 2, 21, 168, 2, 2, 335, 0], 0, 7, 66], // 414
  ['Act 1 (H) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 203, 2, 21, 168, 2, 2, 335, 0], 0, 7, 68], // 415
  ['Act 2 (H) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 205, 2, 21, 168, 2, 2, 336, 0], 0, 7, 69], // 416
  ['Act 2 (H) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 206, 2, 21, 168, 2, 2, 336, 0], 0, 7, 71], // 417
  ['Act 2 (H) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 206, 2, 21, 168, 2, 2, 336, 0], 0, 7, 73], // 418
  ['Act 3 (H) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 208, 2, 21, 168, 2, 2, 337, 0], 0, 7, 74], // 419
  ['Act 3 (H) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 209, 2, 21, 168, 2, 2, 337, 0], 0, 7, 76], // 420
  ['Act 3 (H) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 209, 2, 21, 168, 2, 2, 337, 0], 0, 7, 77], // 421
  ['Act 4 (H) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 211, 2, 21, 168, 2, 2, 338, 0], 0, 7, 79], // 422
  ['Act 4 (H) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 212, 2, 21, 168, 2, 2, 338, 0], 0, 7, 80], // 423
  ['Act 5 (H) H2H A', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 214, 2, 21, 168, 2, 2, 339, 0], 0, 7, 82], // 424
  ['Act 5 (H) H2H B', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 215, 2, 21, 168, 2, 2, 339, 0], 0, 7, 83], // 425
  ['Act 5 (H) H2H C', 0, 0, 0, 0, 160, 100, 1, [1, 21, 229, 2, 16, 216, 2, 21, 168, 2, 2, 339, 0], 0, 7, 85], // 426
  ['Act 1 Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 173, 2, 18, 158, 2, 2, 325, 2, 7, 289, 0], 0, 8, 0], // 427
  ['Act 1 Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 174, 2, 18, 158, 2, 2, 325, 2, 7, 290, 0], 0, 8, 5], // 428
  ['Act 1 Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 175, 2, 18, 158, 2, 2, 325, 2, 7, 291, 0], 0, 8, 9], // 429
  ['Act 2 Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 176, 2, 18, 159, 2, 2, 326, 2, 7, 292, 0], 0, 8, 12], // 430
  ['Act 2 Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 177, 2, 18, 159, 2, 2, 326, 2, 7, 293, 0], 0, 8, 15], // 431
  ['Act 2 Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 178, 2, 18, 159, 2, 2, 327, 2, 7, 294, 0], 0, 8, 18], // 432
  ['Act 3 Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 179, 2, 18, 160, 2, 2, 327, 2, 7, 295, 0], 0, 8, 20], // 433
  ['Act 3 Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 180, 2, 18, 160, 2, 2, 327, 2, 7, 296, 0], 0, 8, 22], // 434
  ['Act 3 Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 181, 2, 18, 160, 2, 2, 327, 2, 7, 297, 0], 0, 8, 24], // 435
  ['Act 4 Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 182, 2, 18, 161, 2, 2, 328, 2, 7, 298, 0], 0, 8, 26], // 436
  ['Act 4 Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 183, 2, 18, 161, 2, 2, 328, 2, 7, 299, 0], 0, 8, 29], // 437
  ['Act 5 Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 184, 2, 18, 162, 2, 2, 329, 2, 7, 300, 0], 0, 8, 32], // 438
  ['Act 5 Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 185, 2, 18, 162, 2, 2, 329, 2, 7, 301, 0], 0, 8, 34], // 439
  ['Act 5 Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 186, 2, 18, 162, 2, 2, 329, 2, 7, 302, 0], 0, 8, 36], // 440
  ['Act 1 (N) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 187, 2, 18, 163, 2, 2, 330, 2, 7, 303, 0], 0, 8, 38], // 441
  ['Act 1 (N) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 188, 2, 18, 163, 2, 2, 330, 2, 7, 304, 0], 0, 8, 40], // 442
  ['Act 1 (N) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 188, 2, 18, 163, 2, 2, 330, 2, 7, 304, 0], 0, 8, 41], // 443
  ['Act 2 (N) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 190, 2, 18, 164, 2, 2, 331, 2, 7, 305, 0], 0, 8, 42], // 444
  ['Act 2 (N) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 191, 2, 18, 164, 2, 2, 331, 2, 7, 306, 0], 0, 8, 44], // 445
  ['Act 2 (N) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 191, 2, 18, 164, 2, 2, 331, 2, 7, 306, 0], 0, 8, 45], // 446
  ['Act 3 (N) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 193, 2, 18, 165, 2, 2, 332, 2, 7, 307, 0], 0, 8, 46], // 447
  ['Act 3 (N) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 194, 2, 18, 165, 2, 2, 332, 2, 7, 308, 0], 0, 8, 48], // 448
  ['Act 3 (N) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 194, 2, 18, 165, 2, 2, 332, 2, 7, 308, 0], 0, 8, 50], // 449
  ['Act 4 (N) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 196, 2, 18, 166, 2, 2, 333, 2, 7, 309, 0], 0, 8, 51], // 450
  ['Act 4 (N) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 197, 2, 18, 166, 2, 2, 333, 2, 7, 310, 0], 0, 8, 54], // 451
  ['Act 5 (N) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 199, 2, 18, 167, 2, 2, 334, 2, 7, 311, 0], 0, 8, 57], // 452
  ['Act 5 (N) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 200, 2, 18, 167, 2, 2, 334, 2, 7, 312, 0], 0, 8, 60], // 453
  ['Act 5 (N) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 201, 2, 18, 167, 2, 2, 334, 2, 7, 312, 0], 0, 8, 62], // 454
  ['Act 1 (H) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 202, 2, 18, 168, 2, 2, 335, 2, 7, 314, 0], 0, 8, 63], // 455
  ['Act 1 (H) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 203, 2, 18, 168, 2, 2, 335, 2, 7, 315, 0], 0, 8, 66], // 456
  ['Act 1 (H) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 203, 2, 18, 168, 2, 2, 335, 2, 7, 315, 0], 0, 8, 68], // 457
  ['Act 2 (H) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 205, 2, 18, 168, 2, 2, 336, 2, 7, 316, 0], 0, 8, 69], // 458
  ['Act 2 (H) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 206, 2, 18, 168, 2, 2, 336, 2, 7, 317, 0], 0, 8, 71], // 459
  ['Act 2 (H) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 206, 2, 18, 168, 2, 2, 336, 2, 7, 317, 0], 0, 8, 72], // 460
  ['Act 3 (H) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 208, 2, 18, 168, 2, 2, 337, 2, 7, 318, 0], 0, 8, 74], // 461
  ['Act 3 (H) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 209, 2, 18, 168, 2, 2, 337, 2, 7, 319, 0], 0, 8, 75], // 462
  ['Act 3 (H) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 209, 2, 18, 168, 2, 2, 337, 2, 7, 319, 0], 0, 8, 77], // 463
  ['Act 4 (H) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 211, 2, 18, 168, 2, 2, 338, 2, 7, 320, 0], 0, 8, 78], // 464
  ['Act 4 (H) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 212, 2, 18, 168, 2, 2, 338, 2, 7, 321, 0], 0, 8, 80], // 465
  ['Act 5 (H) Cast A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 214, 2, 18, 168, 2, 2, 339, 2, 7, 322, 0], 0, 8, 81], // 466
  ['Act 5 (H) Cast B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 215, 2, 18, 168, 2, 2, 339, 2, 7, 323, 0], 0, 8, 83], // 467
  ['Act 5 (H) Cast C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 15, 216, 2, 18, 168, 2, 2, 339, 2, 7, 323, 0], 0, 8, 85], // 468
  ['Act 1 Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 173, 2, 18, 158, 2, 2, 325, 2, 6, 253, 2, 3, 125, 0], 0, 9, 0], // 469
  ['Act 1 Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 174, 2, 18, 158, 2, 2, 325, 2, 6, 254, 2, 3, 125, 0], 0, 9, 5], // 470
  ['Act 1 Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 175, 2, 18, 158, 2, 2, 325, 2, 6, 255, 2, 3, 125, 0], 0, 9, 9], // 471
  ['Act 2 Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 176, 2, 18, 159, 2, 2, 326, 2, 6, 256, 2, 3, 125, 0], 0, 9, 12], // 472
  ['Act 2 Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 177, 2, 18, 159, 2, 2, 326, 2, 6, 257, 2, 3, 125, 0], 0, 9, 15], // 473
  ['Act 2 Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 178, 2, 18, 159, 2, 2, 327, 2, 6, 258, 2, 3, 125, 0], 0, 9, 18], // 474
  ['Act 3 Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 179, 2, 18, 160, 2, 2, 327, 2, 6, 259, 2, 3, 125, 0], 0, 9, 20], // 475
  ['Act 3 Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 180, 2, 18, 160, 2, 2, 327, 2, 6, 260, 2, 3, 125, 0], 0, 9, 22], // 476
  ['Act 3 Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 181, 2, 18, 160, 2, 2, 327, 2, 6, 261, 2, 3, 125, 0], 0, 9, 24], // 477
  ['Act 4 Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 182, 2, 18, 161, 2, 2, 328, 2, 6, 262, 2, 3, 125, 0], 0, 9, 26], // 478
  ['Act 4 Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 183, 2, 18, 161, 2, 2, 328, 2, 6, 263, 2, 3, 125, 0], 0, 9, 29], // 479
  ['Act 5 Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 184, 2, 18, 162, 2, 2, 329, 2, 6, 264, 2, 3, 125, 0], 0, 9, 32], // 480
  ['Act 5 Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 185, 2, 18, 162, 2, 2, 329, 2, 6, 265, 2, 3, 125, 0], 0, 9, 34], // 481
  ['Act 5 Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 186, 2, 18, 162, 2, 2, 329, 2, 6, 265, 2, 3, 125, 0], 0, 9, 36], // 482
  ['Act 1 (N) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 187, 2, 18, 163, 2, 2, 330, 2, 6, 267, 2, 3, 125, 0], 0, 9, 38], // 483
  ['Act 1 (N) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 188, 2, 18, 163, 2, 2, 330, 2, 6, 268, 2, 3, 125, 0], 0, 9, 40], // 484
  ['Act 1 (N) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 188, 2, 18, 163, 2, 2, 330, 2, 6, 268, 2, 3, 125, 0], 0, 9, 41], // 485
  ['Act 2 (N) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 190, 2, 18, 164, 2, 2, 331, 2, 6, 269, 2, 3, 125, 0], 0, 9, 42], // 486
  ['Act 2 (N) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 191, 2, 18, 164, 2, 2, 331, 2, 6, 270, 2, 3, 125, 0], 0, 9, 44], // 487
  ['Act 2 (N) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 191, 2, 18, 164, 2, 2, 331, 2, 6, 270, 2, 3, 125, 0], 0, 9, 45], // 488
  ['Act 3 (N) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 193, 2, 18, 165, 2, 2, 332, 2, 6, 271, 2, 3, 125, 0], 0, 9, 46], // 489
  ['Act 3 (N) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 194, 2, 18, 165, 2, 2, 332, 2, 6, 272, 2, 3, 125, 0], 0, 9, 48], // 490
  ['Act 3 (N) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 194, 2, 18, 165, 2, 2, 332, 2, 6, 272, 2, 3, 125, 0], 0, 9, 50], // 491
  ['Act 4 (N) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 196, 2, 18, 166, 2, 2, 333, 2, 6, 273, 2, 3, 125, 0], 0, 9, 51], // 492
  ['Act 4 (N) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 197, 2, 18, 166, 2, 2, 333, 2, 6, 274, 2, 3, 125, 0], 0, 9, 54], // 493
  ['Act 5 (N) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 199, 2, 18, 167, 2, 2, 334, 2, 6, 275, 2, 3, 125, 0], 0, 9, 57], // 494
  ['Act 5 (N) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 200, 2, 18, 167, 2, 2, 334, 2, 6, 276, 2, 3, 125, 0], 0, 9, 60], // 495
  ['Act 5 (N) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 201, 2, 18, 167, 2, 2, 334, 2, 6, 276, 2, 3, 125, 0], 0, 9, 62], // 496
  ['Act 1 (H) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 202, 2, 18, 168, 2, 2, 335, 2, 6, 278, 2, 3, 125, 0], 0, 9, 63], // 497
  ['Act 1 (H) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 203, 2, 18, 168, 2, 2, 335, 2, 6, 279, 2, 3, 125, 0], 0, 9, 66], // 498
  ['Act 1 (H) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 203, 2, 18, 168, 2, 2, 335, 2, 6, 279, 2, 3, 125, 0], 0, 9, 68], // 499
  ['Act 2 (H) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 205, 2, 18, 168, 2, 2, 336, 2, 6, 280, 2, 3, 125, 0], 0, 9, 69], // 500
  ['Act 2 (H) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 206, 2, 18, 168, 2, 2, 336, 2, 6, 281, 2, 3, 125, 0], 0, 9, 71], // 501
  ['Act 2 (H) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 206, 2, 18, 168, 2, 2, 336, 2, 6, 281, 2, 3, 125, 0], 0, 9, 72], // 502
  ['Act 3 (H) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 208, 2, 18, 168, 2, 2, 337, 2, 6, 282, 2, 3, 125, 0], 0, 9, 74], // 503
  ['Act 3 (H) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 209, 2, 18, 168, 2, 2, 337, 2, 6, 283, 2, 3, 125, 0], 0, 9, 75], // 504
  ['Act 3 (H) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 209, 2, 18, 168, 2, 2, 337, 2, 6, 283, 2, 3, 125, 0], 0, 9, 77], // 505
  ['Act 4 (H) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 211, 2, 18, 168, 2, 2, 338, 2, 6, 284, 2, 3, 125, 0], 0, 9, 78], // 506
  ['Act 4 (H) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 212, 2, 18, 168, 2, 2, 338, 2, 6, 285, 2, 3, 125, 0], 0, 9, 80], // 507
  ['Act 5 (H) Miss A', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 214, 2, 18, 168, 2, 2, 339, 2, 6, 286, 2, 3, 125, 0], 0, 9, 81], // 508
  ['Act 5 (H) Miss B', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 215, 2, 18, 168, 2, 2, 339, 2, 6, 287, 2, 3, 125, 0], 0, 9, 83], // 509
  ['Act 5 (H) Miss C', 0, 0, 0, 0, 160, 100, 1, [1, 18, 229, 2, 13, 216, 2, 18, 168, 2, 2, 339, 2, 6, 287, 2, 3, 125, 0], 0, 9, 85], // 510
  ['Act 1 Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 325, 2, 25, 289, 0], 0, 10, 0], // 511
  ['Act 1 Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 325, 2, 25, 290, 0], 0, 10, 5], // 512
  ['Act 1 Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 325, 2, 25, 291, 0], 0, 10, 9], // 513
  ['Act 2 Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 326, 2, 25, 292, 0], 0, 10, 12], // 514
  ['Act 2 Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 326, 2, 25, 293, 0], 0, 10, 15], // 515
  ['Act 2 Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 327, 2, 25, 294, 0], 0, 10, 18], // 516
  ['Act 3 Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 327, 2, 25, 295, 0], 0, 10, 20], // 517
  ['Act 3 Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 327, 2, 25, 296, 0], 0, 10, 22], // 518
  ['Act 3 Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 327, 2, 25, 297, 0], 0, 10, 24], // 519
  ['Act 4 Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 328, 2, 25, 298, 0], 0, 10, 26], // 520
  ['Act 4 Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 328, 2, 25, 299, 0], 0, 10, 29], // 521
  ['Act 5 Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 329, 2, 25, 300, 0], 0, 10, 32], // 522
  ['Act 5 Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 329, 2, 25, 301, 0], 0, 10, 34], // 523
  ['Act 5 Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 329, 2, 25, 302, 0], 0, 10, 36], // 524
  ['Act 1 (N) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 330, 2, 25, 303, 0], 0, 10, 38], // 525
  ['Act 1 (N) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 330, 2, 25, 304, 0], 0, 10, 40], // 526
  ['Act 1 (N) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 330, 2, 25, 304, 0], 0, 10, 41], // 527
  ['Act 2 (N) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 331, 2, 25, 305, 0], 0, 10, 42], // 528
  ['Act 2 (N) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 331, 2, 25, 306, 0], 0, 10, 44], // 529
  ['Act 2 (N) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 331, 2, 25, 306, 0], 0, 10, 45], // 530
  ['Act 3 (N) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 332, 2, 25, 307, 0], 0, 10, 46], // 531
  ['Act 3 (N) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 332, 2, 25, 308, 0], 0, 10, 48], // 532
  ['Act 3 (N) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 332, 2, 25, 308, 0], 0, 10, 50], // 533
  ['Act 4 (N) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 333, 2, 25, 309, 0], 0, 10, 51], // 534
  ['Act 4 (N) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 333, 2, 25, 310, 0], 0, 10, 54], // 535
  ['Act 5 (N) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 334, 2, 25, 311, 0], 0, 10, 57], // 536
  ['Act 5 (N) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 334, 2, 25, 312, 0], 0, 10, 60], // 537
  ['Act 5 (N) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 334, 2, 25, 313, 0], 0, 10, 62], // 538
  ['Act 1 (H) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 335, 2, 25, 314, 0], 0, 10, 63], // 539
  ['Act 1 (H) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 335, 2, 25, 315, 0], 0, 10, 66], // 540
  ['Act 1 (H) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 335, 2, 25, 315, 0], 0, 10, 68], // 541
  ['Act 2 (H) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 336, 2, 25, 316, 0], 0, 10, 69], // 542
  ['Act 2 (H) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 336, 2, 25, 317, 0], 0, 10, 71], // 543
  ['Act 2 (H) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 336, 2, 25, 317, 0], 0, 10, 72], // 544
  ['Act 3 (H) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 337, 2, 25, 318, 0], 0, 10, 74], // 545
  ['Act 3 (H) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 337, 2, 25, 319, 0], 0, 10, 75], // 546
  ['Act 3 (H) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 337, 2, 25, 319, 0], 0, 10, 77], // 547
  ['Act 4 (H) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 338, 2, 25, 320, 0], 0, 10, 78], // 548
  ['Act 4 (H) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 338, 2, 25, 321, 0], 0, 10, 80], // 549
  ['Act 5 (H) Wraith A', 0, 0, 0, 0, 104, 75, 1, [2, 4, 339, 2, 25, 322, 0], 0, 10, 81], // 550
  ['Act 5 (H) Wraith B', 0, 0, 0, 0, 104, 75, 1, [2, 4, 339, 2, 25, 323, 0], 0, 10, 83], // 551
  ['Act 5 (H) Wraith C', 0, 0, 0, 0, 104, 75, 1, [2, 4, 339, 2, 25, 324, 0], 0, 10, 85], // 552
  ['Act 1 Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 173, 2, 3, 325, 0], 0], // 553
  ['Act 1 Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 174, 2, 3, 325, 0], 0], // 554
  ['Act 1 Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 175, 2, 3, 325, 0], 0], // 555
  ['Act 2 Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 176, 2, 3, 326, 0], 0], // 556
  ['Act 2 Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 177, 2, 3, 326, 0], 0], // 557
  ['Act 2 Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 178, 2, 3, 327, 0], 0], // 558
  ['Act 3 Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 179, 2, 3, 327, 0], 0], // 559
  ['Act 3 Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 180, 2, 3, 327, 0], 0], // 560
  ['Act 3 Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 181, 2, 3, 327, 0], 0], // 561
  ['Act 4 Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 182, 2, 3, 328, 0], 0], // 562
  ['Act 4 Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 183, 2, 3, 328, 0], 0], // 563
  ['Act 5 Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 184, 2, 3, 329, 0], 0], // 564
  ['Act 5 Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 185, 2, 3, 329, 0], 0], // 565
  ['Act 5 Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 186, 2, 3, 329, 0], 0], // 566
  ['Act 1 (N) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 187, 2, 3, 330, 0], 0], // 567
  ['Act 1 (N) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 188, 2, 3, 330, 0], 0], // 568
  ['Act 1 (N) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 188, 2, 3, 330, 0], 0], // 569
  ['Act 2 (N) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 190, 2, 3, 331, 0], 0], // 570
  ['Act 2 (N) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 191, 2, 3, 331, 0], 0], // 571
  ['Act 2 (N) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 191, 2, 3, 331, 0], 0], // 572
  ['Act 3 (N) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 193, 2, 3, 332, 0], 0], // 573
  ['Act 3 (N) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 194, 2, 3, 332, 0], 0], // 574
  ['Act 3 (N) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 194, 2, 3, 332, 0], 0], // 575
  ['Act 4 (N) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 196, 2, 3, 333, 0], 0], // 576
  ['Act 4 (N) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 197, 2, 3, 333, 0], 0], // 577
  ['Act 5 (N) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 199, 2, 3, 334, 0], 0], // 578
  ['Act 5 (N) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 200, 2, 3, 334, 0], 0], // 579
  ['Act 5 (N) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 201, 2, 3, 334, 0], 0], // 580
  ['Act 1 (H) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 202, 2, 3, 335, 0], 0], // 581
  ['Act 1 (H) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 203, 2, 3, 335, 0], 0], // 582
  ['Act 1 (H) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 203, 2, 3, 335, 0], 0], // 583
  ['Act 2 (H) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 205, 2, 3, 336, 0], 0], // 584
  ['Act 2 (H) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 206, 2, 3, 336, 0], 0], // 585
  ['Act 2 (H) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 206, 2, 3, 336, 0], 0], // 586
  ['Act 3 (H) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 208, 2, 3, 337, 0], 0], // 587
  ['Act 3 (H) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 209, 2, 3, 337, 0], 0], // 588
  ['Act 3 (H) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 209, 2, 3, 337, 0], 0], // 589
  ['Act 4 (H) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 211, 2, 3, 338, 0], 0], // 590
  ['Act 4 (H) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 212, 2, 3, 338, 0], 0], // 591
  ['Act 5 (H) Citem A', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 214, 2, 3, 339, 0], 0], // 592
  ['Act 5 (H) Citem B', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 215, 2, 3, 339, 0], 0], // 593
  ['Act 5 (H) Citem C', 800, 800, 800, 1024, 100, 0, 1, [1, 60, 229, 2, 37, 216, 2, 3, 339, 0], 0], // 594
  ['Act 1 Cpot A', 0, 0, 0, 0, 1, 0, 2, [2, 1, 129, 0], 0], // 595
  ['Act 1 Cpot B', 0, 0, 0, 0, 1, 0, 2, [2, 1, 129, 0], 0], // 596
  ['Act 1 Cpot C', 0, 0, 0, 0, 1, 0, 2, [2, 1, 129, 0], 0], // 597
  ['Act 2 Cpot A', 0, 0, 0, 0, 1, 0, 2, [2, 1, 130, 0], 0], // 598
  ['Act 2 Cpot B', 0, 0, 0, 0, 1, 0, 2, [2, 1, 130, 0], 0], // 599
  ['Act 2 Cpot C', 0, 0, 0, 0, 1, 0, 2, [2, 1, 130, 0], 0], // 600
  ['Act 3 Cpot A', 0, 0, 0, 0, 1, 0, 2, [2, 1, 131, 0], 0], // 601
  ['Act 3 Cpot B', 0, 0, 0, 0, 1, 0, 2, [2, 1, 131, 0], 0], // 602
  ['Act 3 Cpot C', 0, 0, 0, 0, 1, 0, 2, [2, 1, 131, 0], 0], // 603
  ['Act 4 Cpot A', 0, 0, 0, 0, 3, 0, 2, [2, 1, 131, 2, 2, 132, 0], 0], // 604
  ['Act 4 Cpot B', 0, 0, 0, 0, 3, 0, 2, [2, 1, 131, 2, 2, 132, 0], 0], // 605
  ['Act 5 Cpot A', 0, 0, 0, 0, 3, 0, 2, [2, 1, 132, 2, 2, 133, 0], 0], // 606
  ['Act 5 Cpot B', 0, 0, 0, 0, 3, 0, 2, [2, 1, 132, 2, 2, 133, 0], 0], // 607
  ['Act 5 Cpot C', 0, 0, 0, 0, 3, 0, 2, [2, 1, 132, 2, 2, 133, 0], 0], // 608
  ['Act 1 (N) Cpot A', 0, 0, 0, 0, 7, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 609
  ['Act 1 (N) Cpot B', 0, 0, 0, 0, 14, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 610
  ['Act 1 (N) Cpot C', 0, 0, 0, 0, 14, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 611
  ['Act 2 (N) Cpot A', 0, 0, 0, 0, 14, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 612
  ['Act 2 (N) Cpot B', 0, 0, 0, 0, 14, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 613
  ['Act 2 (N) Cpot C', 0, 0, 0, 0, 14, 0, 2, [2, 1, 132, 2, 2, 133, 2, 4, 134, 0], 0], // 614
  ['Act 3 (N) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 615
  ['Act 3 (N) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 616
  ['Act 3 (N) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 617
  ['Act 4 (N) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 618
  ['Act 4 (N) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 619
  ['Act 5 (N) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 620
  ['Act 5 (N) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 621
  ['Act 5 (N) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 420, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 622
  ['Act 1 (H) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 623
  ['Act 1 (H) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 624
  ['Act 1 (H) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 625
  ['Act 2 (H) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 626
  ['Act 2 (H) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 627
  ['Act 2 (H) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 628
  ['Act 3 (H) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 629
  ['Act 3 (H) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 630
  ['Act 3 (H) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 631
  ['Act 4 (H) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 632
  ['Act 4 (H) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 633
  ['Act 5 (H) Cpot A', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 634
  ['Act 5 (H) Cpot B', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 635
  ['Act 5 (H) Cpot C', 0, 0, 0, 0, 22, 0, 2, [1, 1, 209, 2, 2, 132, 2, 4, 133, 2, 8, 134, 0], 0], // 636
  ['Act 1 Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 553, 2, 2, 595, 0], 0, 13, 0], // 637
  ['Act 1 Champ B', 0, 0, 0, 0, 2, 0, -2, [2, 1, 554, 2, 1, 596, 0], 0, 13, 5], // 638
  ['Act 1 Champ C', 0, 0, 0, 0, 2, 0, -2, [2, 1, 555, 2, 1, 597, 0], 0, 13, 9], // 639
  ['Act 2 Champ A', 0, 0, 0, 0, 2, 0, -2, [2, 1, 556, 2, 1, 598, 0], 0, 13, 12], // 640
  ['Act 2 Champ B', 0, 0, 0, 0, 2, 0, -2, [2, 1, 557, 2, 1, 599, 0], 0, 13, 15], // 641
  ['Act 2 Champ C', 0, 0, 0, 0, 2, 0, -2, [2, 1, 558, 2, 1, 600, 0], 0, 13, 18], // 642
  ['Act 3 Champ A', 0, 0, 0, 0, 2, 0, -2, [2, 1, 559, 2, 1, 601, 0], 0, 13, 20], // 643
  ['Act 3 Champ B', 0, 0, 0, 0, 2, 0, -2, [2, 1, 560, 2, 1, 602, 0], 0, 13, 22], // 644
  ['Act 3 Champ C', 0, 0, 0, 0, 2, 0, -2, [2, 1, 561, 2, 1, 603, 0], 0, 13, 24], // 645
  ['Act 4 Champ A', 0, 0, 0, 0, 2, 0, -2, [2, 1, 562, 2, 1, 604, 0], 0, 13, 26], // 646
  ['Act 4 Champ B', 0, 0, 0, 0, 2, 0, -2, [2, 1, 563, 2, 1, 605, 0], 0, 13, 29], // 647
  ['Act 5 Champ A', 0, 0, 0, 0, 2, 0, -2, [2, 1, 564, 2, 1, 606, 0], 0, 13, 32], // 648
  ['Act 5 Champ B', 0, 0, 0, 0, 2, 0, -2, [2, 1, 565, 2, 1, 607, 0], 0, 13, 34], // 649
  ['Act 5 Champ C', 0, 0, 0, 0, 2, 0, -2, [2, 1, 566, 2, 1, 608, 0], 0, 13, 36], // 650
  ['Act 1 (N) Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 567, 2, 2, 609, 0], 0, 13, 38], // 651
  ['Act 1 (N) Champ B', 0, 0, 0, 0, 3, 0, -2, [2, 1, 568, 2, 2, 610, 0], 0, 13, 40], // 652
  ['Act 1 (N) Champ C', 0, 0, 0, 0, 3, 0, -2, [2, 1, 569, 2, 2, 611, 0], 0, 13, 41], // 653
  ['Act 2 (N) Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 570, 2, 2, 612, 0], 0, 13, 42], // 654
  ['Act 2 (N) Champ B', 0, 0, 0, 0, 3, 0, -2, [2, 1, 571, 2, 2, 613, 0], 0, 13, 44], // 655
  ['Act 2 (N) Champ C', 0, 0, 0, 0, 3, 0, -2, [2, 1, 572, 2, 2, 614, 0], 0, 13, 45], // 656
  ['Act 3 (N) Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 573, 2, 2, 615, 0], 0, 13, 46], // 657
  ['Act 3 (N) Champ B', 0, 0, 0, 0, 3, 0, -2, [2, 1, 574, 2, 2, 616, 0], 0, 13, 48], // 658
  ['Act 3 (N) Champ C', 0, 0, 0, 0, 3, 0, -2, [2, 1, 575, 2, 2, 617, 0], 0, 13, 50], // 659
  ['Act 4 (N) Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 576, 2, 2, 618, 0], 0, 13, 51], // 660
  ['Act 4 (N) Champ B', 0, 0, 0, 0, 3, 0, -2, [2, 1, 577, 2, 2, 619, 0], 0, 13, 54], // 661
  ['Act 4 (N) Champ C', 0, 0, 0, 0, 3, 0, -2, [2, 1, 577, 2, 2, 619, 0], 0, 13, 56], // 662
  ['Act 5 (N) Champ A', 0, 0, 0, 0, 3, 0, -2, [2, 1, 578, 2, 2, 620, 0], 0, 13, 57], // 663
  ['Act 5 (N) Champ B', 0, 0, 0, 0, 3, 0, -2, [2, 1, 579, 2, 2, 621, 0], 0, 13, 60], // 664
  ['Act 5 (N) Champ C', 0, 0, 0, 0, 3, 0, -2, [2, 1, 580, 2, 2, 622, 0], 0, 13, 62], // 665
  ['Act 1 (H) Champ A', 0, 0, 0, 0, 4, 0, -2, [2, 1, 581, 2, 3, 623, 0], 0, 13, 63], // 666
  ['Act 1 (H) Champ B', 0, 0, 0, 0, 4, 0, -2, [2, 1, 582, 2, 3, 624, 0], 0, 13, 66], // 667
  ['Act 1 (H) Champ C', 0, 0, 0, 0, 4, 0, -2, [2, 1, 583, 2, 3, 625, 0], 0, 13, 68], // 668
  ['Act 2 (H) Champ A', 0, 0, 0, 0, 4, 0, -2, [2, 1, 584, 2, 3, 626, 0], 0, 13, 69], // 669
  ['Act 2 (H) Champ B', 0, 0, 0, 0, 4, 0, -2, [2, 1, 585, 2, 3, 627, 0], 0, 13, 71], // 670
  ['Act 2 (H) Champ C', 0, 0, 0, 0, 4, 0, -2, [2, 1, 586, 2, 3, 628, 0], 0, 13, 72], // 671
  ['Act 3 (H) Champ A', 0, 0, 0, 0, 4, 0, -2, [2, 1, 587, 2, 3, 629, 0], 0, 13, 74], // 672
  ['Act 3 (H) Champ B', 0, 0, 0, 0, 4, 0, -2, [2, 1, 588, 2, 3, 630, 0], 0, 13, 75], // 673
  ['Act 3 (H) Champ C', 0, 0, 0, 0, 4, 0, -2, [2, 1, 589, 2, 3, 631, 0], 0, 13, 77], // 674
  ['Act 4 (H) Champ A', 0, 0, 0, 0, 4, 0, -2, [2, 1, 590, 2, 3, 632, 0], 0, 13, 78], // 675
  ['Act 4 (H) Champ B', 0, 0, 0, 0, 4, 0, -2, [2, 1, 591, 2, 3, 633, 0], 0, 13, 80], // 676
  ['Act 5 (H) Champ A', 0, 0, 0, 0, 4, 0, -2, [2, 1, 592, 2, 3, 634, 0], 0, 13, 81], // 677
  ['Act 5 (H) Champ B', 0, 0, 0, 0, 4, 0, -2, [2, 1, 593, 2, 3, 635, 0], 0, 13, 83], // 678
  ['Act 5 (H) Champ C', 0, 0, 0, 0, 4, 0, -2, [2, 1, 594, 2, 3, 636, 0], 0, 13, 85], // 679
  ['Act 1 Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 173, 2, 4, 325, 0], 0], // 680
  ['Act 1 Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 174, 2, 4, 325, 0], 0], // 681
  ['Act 1 Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 175, 2, 4, 325, 0], 0], // 682
  ['Act 2 Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 176, 2, 4, 326, 0], 0], // 683
  ['Act 2 Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 177, 2, 4, 326, 0], 0], // 684
  ['Act 2 Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 178, 2, 4, 327, 0], 0], // 685
  ['Act 3 Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 179, 2, 4, 327, 0], 0], // 686
  ['Act 3 Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 180, 2, 4, 327, 0], 0], // 687
  ['Act 3 Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 181, 2, 4, 327, 0], 0], // 688
  ['Act 4 Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 182, 2, 4, 328, 0], 0], // 689
  ['Act 4 Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 183, 2, 4, 328, 0], 0], // 690
  ['Act 5 Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 184, 2, 4, 329, 0], 0], // 691
  ['Act 5 Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 185, 2, 4, 329, 0], 0], // 692
  ['Act 5 Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 186, 2, 4, 329, 0], 0], // 693
  ['Act 1 (N) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 187, 2, 4, 330, 0], 0], // 694
  ['Act 1 (N) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 188, 2, 4, 330, 0], 0], // 695
  ['Act 1 (N) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 188, 2, 4, 330, 0], 0], // 696
  ['Act 2 (N) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 190, 2, 4, 331, 0], 0], // 697
  ['Act 2 (N) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 191, 2, 4, 331, 0], 0], // 698
  ['Act 2 (N) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 191, 2, 4, 331, 0], 0], // 699
  ['Act 3 (N) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 193, 2, 4, 332, 0], 0], // 700
  ['Act 3 (N) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 194, 2, 4, 332, 0], 0], // 701
  ['Act 3 (N) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 194, 2, 4, 332, 0], 0], // 702
  ['Act 4 (N) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 196, 2, 4, 333, 0], 0], // 703
  ['Act 4 (N) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 197, 2, 4, 333, 0], 0], // 704
  ['Act 5 (N) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 199, 2, 4, 334, 0], 0], // 705
  ['Act 5 (N) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 200, 2, 4, 334, 0], 0], // 706
  ['Act 5 (N) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 201, 2, 4, 334, 0], 0], // 707
  ['Act 1 (H) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 202, 2, 4, 335, 0], 0], // 708
  ['Act 1 (H) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 203, 2, 4, 335, 0], 0], // 709
  ['Act 1 (H) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 203, 2, 4, 335, 0], 0], // 710
  ['Act 2 (H) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 205, 2, 4, 336, 0], 0], // 711
  ['Act 2 (H) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 206, 2, 4, 336, 0], 0], // 712
  ['Act 2 (H) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 206, 2, 4, 336, 0], 0], // 713
  ['Act 3 (H) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 208, 2, 4, 337, 0], 0], // 714
  ['Act 3 (H) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 209, 2, 4, 337, 0], 0], // 715
  ['Act 3 (H) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 209, 2, 4, 337, 0], 0], // 716
  ['Act 4 (H) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 211, 2, 4, 338, 0], 0], // 717
  ['Act 4 (H) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 212, 2, 4, 338, 0], 0], // 718
  ['Act 5 (H) Uitem A', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 214, 2, 4, 339, 0], 0], // 719
  ['Act 5 (H) Uitem B', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 215, 2, 4, 339, 0], 0], // 720
  ['Act 5 (H) Uitem C', 800, 800, 800, 1024, 62, 0, 1, [2, 58, 216, 2, 4, 339, 0], 0], // 721
  ['Act 1 Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 680, 2, 2, 595, 0], 0, 15, 0], // 722
  ['Act 1 Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 681, 2, 2, 596, 0], 0, 15, 5], // 723
  ['Act 1 Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 682, 2, 2, 597, 0], 0, 15, 9], // 724
  ['Act 2 Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 683, 2, 2, 598, 0], 0, 15, 12], // 725
  ['Act 2 Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 684, 2, 2, 599, 0], 0, 15, 15], // 726
  ['Act 2 Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 685, 2, 2, 600, 0], 0, 15, 18], // 727
  ['Act 3 Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 686, 2, 2, 601, 0], 0, 15, 20], // 728
  ['Act 3 Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 687, 2, 2, 602, 0], 0, 15, 22], // 729
  ['Act 3 Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 688, 2, 2, 603, 0], 0, 15, 24], // 730
  ['Act 4 Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 689, 2, 2, 604, 0], 0, 15, 26], // 731
  ['Act 4 Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 690, 2, 2, 605, 0], 0, 15, 29], // 732
  ['Act 5 Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 691, 2, 2, 606, 0], 0, 15, 32], // 733
  ['Act 5 Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 692, 2, 2, 607, 0], 0, 15, 34], // 734
  ['Act 5 Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 693, 2, 2, 608, 0], 0, 15, 36], // 735
  ['Act 1 (N) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 694, 2, 2, 609, 0], 0, 15, 38], // 736
  ['Act 1 (N) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 695, 2, 2, 610, 0], 0, 15, 40], // 737
  ['Act 1 (N) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 696, 2, 2, 611, 0], 0, 15, 41], // 738
  ['Act 2 (N) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 697, 2, 2, 612, 0], 0, 15, 42], // 739
  ['Act 2 (N) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 698, 2, 2, 613, 0], 0, 15, 44], // 740
  ['Act 2 (N) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 699, 2, 2, 614, 0], 0, 15, 45], // 741
  ['Act 3 (N) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 700, 2, 2, 615, 0], 0, 15, 46], // 742
  ['Act 3 (N) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 701, 2, 2, 616, 0], 0, 15, 48], // 743
  ['Act 3 (N) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 702, 2, 2, 617, 0], 0, 15, 50], // 744
  ['Act 4 (N) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 703, 2, 2, 618, 0], 0, 15, 51], // 745
  ['Act 4 (N) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 704, 2, 2, 619, 0], 0, 15, 54], // 746
  ['Act 5 (N) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 705, 2, 2, 620, 0], 0, 15, 57], // 747
  ['Act 5 (N) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 706, 2, 2, 621, 0], 0, 15, 60], // 748
  ['Act 5 (N) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 707, 2, 2, 622, 0], 0, 15, 62], // 749
  ['Act 1 (H) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 708, 2, 2, 623, 0], 0, 15, 63], // 750
  ['Act 1 (H) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 709, 2, 2, 624, 0], 0, 15, 66], // 751
  ['Act 1 (H) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 710, 2, 2, 625, 0], 0, 15, 68], // 752
  ['Act 2 (H) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 711, 2, 2, 626, 0], 0, 15, 69], // 753
  ['Act 2 (H) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 712, 2, 2, 627, 0], 0, 15, 71], // 754
  ['Act 2 (H) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 713, 2, 2, 628, 0], 0, 15, 72], // 755
  ['Act 3 (H) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 714, 2, 2, 629, 0], 0, 15, 74], // 756
  ['Act 3 (H) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 715, 2, 2, 630, 0], 0, 15, 75], // 757
  ['Act 3 (H) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 716, 2, 2, 631, 0], 0, 15, 77], // 758
  ['Act 4 (H) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 717, 2, 2, 632, 0], 0, 15, 78], // 759
  ['Act 4 (H) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 718, 2, 2, 633, 0], 0, 15, 80], // 760
  ['Act 5 (H) Unique A', 0, 0, 0, 0, 3, 0, -3, [2, 1, 719, 2, 2, 634, 0], 0, 15, 81], // 761
  ['Act 5 (H) Unique B', 0, 0, 0, 0, 3, 0, -3, [2, 1, 720, 2, 2, 635, 0], 0, 15, 83], // 762
  ['Act 5 (H) Unique C', 0, 0, 0, 0, 3, 0, -3, [2, 1, 721, 2, 2, 636, 0], 0, 15, 85], // 763
  ['Quill 1', 0, 0, 0, 0, 163, 125, 1, [1, 19, 229, 2, 19, 158, 0], 0, 16, 0], // 764
  ['Quill 2', 0, 0, 0, 0, 163, 125, 1, [1, 19, 229, 2, 19, 158, 0], 0, 16, 4], // 765
  ['Quill 3', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 158, 2, 1, 325, 0], 0, 16, 8], // 766
  ['Quill 4', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 158, 2, 1, 325, 0], 0, 16, 12], // 767
  ['Quill 5', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 160, 2, 1, 327, 0], 0, 16, 20], // 768
  ['Quill 1 (N)', 0, 0, 0, 0, 163, 125, 1, [1, 19, 229, 2, 19, 163, 0], 0, 16, 32], // 769
  ['Quill 2 (N)', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 163, 2, 1, 330, 0], 0, 16, 35], // 770
  ['Quill 3 (N)', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 163, 2, 1, 330, 0], 0, 16, 38], // 771
  ['Quill 4 (N)', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 163, 2, 1, 330, 0], 0, 16, 40], // 772
  ['Quill 5 (N)', 0, 0, 0, 0, 164, 125, 1, [1, 19, 229, 2, 19, 165, 2, 1, 332, 0], 0, 16, 46], // 773
  ['Quill 1 (H)', 0, 0, 0, 0, 163, 125, 1, [1, 19, 229, 2, 19, 168, 0], 0, 16, 60], // 774
  ['Quill 2 (H)', 0, 0, 0, 0, 166, 125, 1, [1, 19, 229, 2, 19, 168, 2, 3, 335, 0], 0, 16, 62], // 775
  ['Quill 3 (H)', 0, 0, 0, 0, 166, 125, 1, [1, 19, 229, 2, 19, 168, 2, 3, 335, 0], 0, 16, 65], // 776
  ['Quill 4 (H)', 0, 0, 0, 0, 166, 125, 1, [1, 19, 229, 2, 19, 168, 2, 3, 335, 0], 0, 16, 68], // 777
  ['Quill 5 (H)', 0, 0, 0, 0, 166, 125, 1, [1, 19, 229, 2, 19, 170, 2, 3, 337, 0], 0, 16, 85], // 778
  ['Swarm 1', 0, 0, 0, 0, 150, 125, 1, [2, 12, 136, 2, 6, 127, 2, 1, 326, 2, 6, 292, 0], 0, 17, 0], // 779
  ['Swarm 2', 0, 0, 0, 0, 150, 125, 1, [2, 12, 137, 2, 6, 128, 2, 1, 327, 2, 6, 295, 0], 0, 17, 20], // 780
  ['Swarm 1 (N)', 0, 0, 0, 0, 150, 125, 1, [2, 12, 140, 2, 6, 128, 2, 1, 331, 2, 6, 305, 0], 0, 17, 40], // 781
  ['Swarm 2 (N)', 0, 0, 0, 0, 150, 125, 1, [2, 12, 140, 2, 6, 128, 2, 1, 332, 2, 6, 307, 0], 0, 17, 46], // 782
  ['Swarm 1 (H)', 0, 0, 0, 0, 150, 125, 1, [2, 12, 140, 2, 6, 128, 2, 1, 336, 2, 6, 316, 0], 0, 17, 68], // 783
  ['Swarm 2 (H)', 0, 0, 0, 0, 150, 125, 1, [2, 12, 140, 2, 6, 128, 2, 1, 337, 2, 6, 318, 0], 0, 17, 74], // 784
  ['Andariel', 983, 983, 983, 1024, 67, 19, 7, [1, 11, 229, 2, 19, 176, 2, 15, 159, 2, 3, 326, 0], 0], // 785
  ['Andariel (N)', 983, 983, 983, 1024, 67, 19, 7, [1, 11, 229, 2, 19, 190, 2, 15, 164, 2, 3, 331, 0], 0], // 786
  ['Andariel (H)', 983, 983, 983, 1024, 67, 19, 7, [1, 11, 229, 2, 19, 205, 2, 15, 169, 2, 3, 336, 1, 1, 597, 0], 0], // 787
  ['Andarielq', 995, 995, 1024, 1024, 41, 19, 7, [2, 19, 176, 2, 3, 326, 0], 0], // 788
  ['Andarielq (N)', 995, 995, 1024, 1024, 41, 19, 7, [2, 19, 190, 2, 3, 331, 0], 0], // 789
  ['Andarielq (H)', 995, 995, 1024, 1024, 41, 19, 7, [2, 19, 205, 2, 3, 336, 1, 1, 597, 0], 0], // 790
  ['Duriel - Base', 983, 983, 983, 1024, 271, 19, 6, [1, 55, 229, 2, 95, 179, 2, 75, 160, 2, 15, 327, 2, 22, 963, 0], 0], // 791
  ['Duriel (N) - Base', 983, 983, 983, 1024, 271, 19, 6, [1, 55, 229, 2, 95, 193, 2, 75, 165, 2, 15, 332, 2, 22, 963, 0], 0], // 792
  ['Duriel (H) - Base', 983, 983, 983, 1024, 283, 19, 6, [1, 55, 229, 2, 95, 208, 2, 75, 170, 2, 15, 337, 1, 10, 597, 2, 22, 963, 0], 0], // 793
  ['Durielq - Base', 993, 993, 1024, 1024, 129, 19, 6, [2, 95, 179, 2, 15, 327, 2, 10, 963, 0], 0], // 794
  ['Durielq (N) - Base', 993, 993, 1024, 1024, 129, 19, 6, [2, 95, 193, 2, 15, 332, 2, 10, 963, 0], 0], // 795
  ['Durielq (H) - Base', 993, 993, 1024, 1024, 140, 19, 6, [2, 95, 208, 2, 15, 337, 1, 10, 597, 2, 11, 963, 0], 0], // 796
  ['Radament', 900, 900, 900, 1024, 67, 19, 5, [1, 11, 229, 2, 19, 180, 2, 15, 160, 2, 3, 327, 0], 0], // 797
  ['Radament (N)', 900, 900, 900, 1024, 67, 19, 5, [1, 11, 229, 2, 19, 194, 2, 15, 165, 2, 3, 332, 0], 0], // 798
  ['Radament (H)', 900, 900, 900, 1024, 67, 19, 5, [1, 11, 229, 2, 19, 210, 2, 15, 170, 2, 3, 337, 0], 0], // 799
  ['Flying Scimitar', 0, 0, 0, 0, 160, 100, 1, [2, 3, 326, 1, 57, 449, 0], 0], // 800
  ['Flying Scimitar (N)', 0, 0, 0, 0, 160, 100, 1, [2, 3, 331, 1, 25, 449, 1, 32, 131, 0], 0], // 801
  ['Flying Scimitar (H)', 0, 0, 0, 0, 160, 100, 1, [2, 3, 336, 1, 7, 449, 1, 18, 131, 1, 32, 21, 0], 0], // 802
  ['Mephisto', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 182, 2, 5, 161, 2, 3, 328, 0], 0], // 803
  ['Mephisto (N)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 196, 2, 5, 166, 2, 3, 333, 0], 0], // 804
  ['Mephisto (H)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 211, 2, 5, 171, 2, 3, 338, 1, 1, 598, 0], 0], // 805
  ['Mephistoq', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 182, 2, 3, 328, 0], 0], // 806
  ['Mephistoq (N)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 196, 2, 3, 333, 0], 0], // 807
  ['Mephistoq (H)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 211, 2, 3, 338, 1, 1, 598, 0], 0], // 808
  ['Diablo', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 184, 2, 5, 162, 2, 3, 329, 0], 0], // 809
  ['Diablo (N)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 199, 2, 5, 167, 2, 3, 334, 0], 0], // 810
  ['Diablo (H)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 214, 2, 5, 172, 2, 3, 339, 1, 1, 599, 0], 0], // 811
  ['Diabloq', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 184, 2, 3, 329, 0], 0], // 812
  ['Diabloq (N)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 199, 2, 3, 334, 0], 0], // 813
  ['Diabloq (H)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 214, 2, 3, 339, 1, 1, 599, 0], 0], // 814
  ['Summoner', 900, 900, 972, 1024, 55, 19, 5, [1, 9, 229, 2, 15, 178, 2, 5, 160, 2, 3, 326, 2, 4, 295, 0], 0], // 815
  ['Summoner (N)', 900, 900, 972, 1024, 55, 19, 5, [1, 9, 229, 2, 15, 192, 2, 5, 165, 2, 3, 331, 2, 4, 307, 0], 0], // 816
  ['Summoner (H)', 900, 900, 972, 1024, 56, 19, 5, [1, 9, 229, 2, 15, 207, 2, 5, 170, 2, 3, 336, 2, 4, 318, 1, 1, 594, 0], 0], // 817
  ['Council', 650, 800, 800, 1024, 55, 19, 3, [1, 9, 229, 2, 15, 182, 2, 5, 161, 2, 3, 328, 2, 4, 298, 0], 0], // 818
  ['Council (N)', 650, 800, 800, 1024, 55, 19, 3, [1, 9, 229, 2, 15, 196, 2, 5, 166, 2, 3, 333, 2, 4, 309, 0], 0], // 819
  ['Council (H)', 650, 800, 800, 1024, 55, 19, 3, [1, 9, 229, 2, 15, 211, 2, 5, 171, 2, 3, 338, 2, 4, 320, 0], 0], // 820
  ['Griswold', 0, 0, 0, 0, 3, 0, -3, [2, 1, 682, 2, 2, 218, 0], 0], // 821
  ['Griswold (N)', 0, 0, 0, 0, 3, 0, -3, [2, 1, 710, 2, 2, 232, 0], 0], // 822
  ['Griswold (H)', 0, 0, 0, 0, 3, 0, -3, [2, 1, 710, 2, 2, 243, 0], 0], // 823
  ['Cow', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 19, 184, 2, 19, 162, 2, 3, 329, 0], 0], // 824
  ['Cow (N)', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 19, 199, 2, 19, 167, 2, 3, 334, 0], 0], // 825
  ['Cow (H)', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 19, 215, 2, 19, 172, 2, 3, 339, 0], 0], // 826
  ['Trapped Soul', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 8, 182, 2, 33, 161, 0], 0], // 827
  ['Trapped Soul (N)', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 8, 196, 2, 33, 166, 0], 0], // 828
  ['Trapped Soul (H)', 0, 0, 0, 0, 160, 100, 1, [1, 19, 229, 2, 8, 211, 2, 33, 171, 0], 0], // 829
  ['Haphesto', 800, 800, 900, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 184, 2, 5, 162, 2, 3, 329, 0], 0], // 830
  ['Haphesto (N)', 800, 800, 900, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 199, 2, 5, 167, 2, 3, 334, 0], 0], // 831
  ['Haphesto (H)', 800, 800, 900, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 214, 2, 5, 172, 2, 3, 339, 0], 0], // 832
  ['Nihlathak', 900, 900, 900, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 185, 2, 5, 162, 2, 3, 329, 0], 0], // 833
  ['Nihlathak (N)', 900, 900, 900, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 200, 2, 5, 167, 2, 3, 334, 0], 0], // 834
  ['Nihlathak (H)', 900, 900, 900, 1024, 61, 19, 5, [1, 14, 229, 2, 19, 215, 2, 5, 172, 2, 3, 339, 1, 1, 596, 0], 0], // 835
  ['Baal', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 187, 2, 5, 163, 2, 3, 330, 0], 0], // 836
  ['Baal (N)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 202, 2, 5, 168, 2, 3, 335, 0], 0], // 837
  ['Baal (H)', 983, 983, 983, 1024, 80, 15, 7, [1, 5, 229, 2, 52, 215, 2, 5, 172, 2, 3, 339, 1, 1, 600, 0], 0], // 838
  ['Baalq', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 187, 2, 3, 330, 0], 0], // 839
  ['Baalq (N)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 202, 2, 3, 335, 0], 0], // 840
  ['Baalq (H)', 993, 993, 1024, 1024, 70, 15, 7, [2, 52, 215, 2, 3, 339, 1, 1, 600, 0], 0], // 841
  ['Blood Raven', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 174, 2, 5, 158, 2, 3, 325, 0], 0], // 842
  ['Blood Raven (N)', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 188, 2, 5, 163, 2, 3, 330, 0], 0], // 843
  ['Blood Raven (H)', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 203, 2, 5, 168, 2, 3, 335, 0], 0], // 844
  ['Smith', 0, 0, 0, 0, 3, 0, -3, [2, 1, 682, 2, 2, 218, 0], 0], // 845
  ['Smith (N)', 0, 0, 0, 0, 3, 0, -3, [2, 1, 710, 2, 2, 232, 0], 0], // 846
  ['Smith (H)', 0, 0, 0, 0, 3, 0, -3, [2, 1, 710, 2, 2, 243, 0], 0], // 847
  ['Izual', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 183, 2, 5, 161, 2, 3, 328, 0], 0], // 848
  ['Izual (N)', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 197, 2, 5, 166, 2, 3, 333, 0], 0], // 849
  ['Izual (H)', 800, 800, 972, 1024, 60, 19, 5, [1, 14, 229, 2, 19, 212, 2, 5, 171, 2, 3, 338, 0], 0], // 850
  ['Act 1 Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 680, 2, 2, 595, 0], 0], // 851
  ['Act 1 Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 681, 2, 2, 596, 0], 0], // 852
  ['Act 1 Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 682, 2, 2, 597, 0], 0], // 853
  ['Act 2 Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 683, 2, 2, 598, 0], 0], // 854
  ['Act 2 Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 684, 2, 2, 599, 0], 0], // 855
  ['Act 2 Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 685, 2, 2, 600, 0], 0], // 856
  ['Act 3 Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 686, 2, 2, 601, 0], 0], // 857
  ['Act 3 Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 687, 2, 2, 602, 0], 0], // 858
  ['Act 3 Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 688, 2, 2, 603, 0], 0], // 859
  ['Act 4 Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 689, 2, 2, 604, 0], 0], // 860
  ['Act 4 Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 690, 2, 2, 605, 0], 0], // 861
  ['Act 4 Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 690, 2, 2, 605, 0], 0], // 862
  ['Act 5 Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 691, 2, 2, 606, 0], 0], // 863
  ['Act 5 Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 692, 2, 2, 607, 0], 0], // 864
  ['Act 5 Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 693, 2, 2, 608, 0], 0], // 865
  ['Act 1 (N) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 694, 2, 2, 609, 0], 0], // 866
  ['Act 1 (N) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 695, 2, 2, 610, 0], 0], // 867
  ['Act 1 (N) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 696, 2, 2, 611, 0], 0], // 868
  ['Act 2 (N) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 697, 2, 2, 612, 0], 0], // 869
  ['Act 2 (N) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 698, 2, 2, 613, 0], 0], // 870
  ['Act 2 (N) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 699, 2, 2, 614, 0], 0], // 871
  ['Act 3 (N) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 700, 2, 2, 615, 0], 0], // 872
  ['Act 3 (N) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 701, 2, 2, 616, 0], 0], // 873
  ['Act 3 (N) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 702, 2, 2, 617, 0], 0], // 874
  ['Act 4 (N) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 703, 2, 2, 618, 0], 0], // 875
  ['Act 4 (N) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 704, 2, 2, 619, 0], 0], // 876
  ['Act 4 (N) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 704, 2, 2, 619, 0], 0], // 877
  ['Act 5 (N) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 705, 2, 2, 620, 0], 0], // 878
  ['Act 5 (N) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 706, 2, 2, 621, 0], 0], // 879
  ['Act 5 (N) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 707, 2, 2, 622, 0], 0], // 880
  ['Act 1 (H) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 708, 2, 2, 623, 0], 0], // 881
  ['Act 1 (H) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 709, 2, 2, 624, 0], 0], // 882
  ['Act 1 (H) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 710, 2, 2, 625, 0], 0], // 883
  ['Act 2 (H) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 711, 2, 2, 626, 0], 0], // 884
  ['Act 2 (H) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 712, 2, 2, 627, 0], 0], // 885
  ['Act 2 (H) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 713, 2, 2, 628, 0], 0], // 886
  ['Act 3 (H) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 714, 2, 2, 629, 0], 0], // 887
  ['Act 3 (H) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 715, 2, 2, 630, 0], 0], // 888
  ['Act 3 (H) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 716, 2, 2, 631, 0], 0], // 889
  ['Act 4 (H) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 717, 2, 2, 632, 0], 0], // 890
  ['Act 4 (H) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 718, 2, 2, 633, 0], 0], // 891
  ['Act 4 (H) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 718, 2, 2, 633, 0], 0], // 892
  ['Act 5 (H) Super A', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 719, 2, 2, 634, 0], 0], // 893
  ['Act 5 (H) Super B', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 720, 2, 2, 635, 0], 0], // 894
  ['Act 5 (H) Super C', 800, 800, 972, 1024, 4, 0, -4, [2, 2, 721, 2, 2, 636, 0], 0], // 895
  ['Act 1 Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 680, 2, 2, 595, 0], 0], // 896
  ['Act 1 Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 681, 2, 2, 596, 0], 0], // 897
  ['Act 1 Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 682, 2, 2, 597, 0], 0], // 898
  ['Act 2 Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 683, 2, 2, 598, 0], 0], // 899
  ['Act 2 Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 684, 2, 2, 599, 0], 0], // 900
  ['Act 2 Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 685, 2, 2, 600, 0], 0], // 901
  ['Act 3 Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 686, 2, 2, 601, 0], 0], // 902
  ['Act 3 Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 687, 2, 2, 602, 0], 0], // 903
  ['Act 3 Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 688, 2, 2, 603, 0], 0], // 904
  ['Act 4 Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 689, 2, 2, 604, 0], 0], // 905
  ['Act 4 Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 690, 2, 2, 605, 0], 0], // 906
  ['Act 4 Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 690, 2, 2, 605, 0], 0], // 907
  ['Act 5 Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 691, 2, 2, 606, 0], 0], // 908
  ['Act 5 Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 692, 2, 2, 607, 0], 0], // 909
  ['Act 5 Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 693, 2, 2, 608, 0], 0], // 910
  ['Act 1 (N) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 694, 2, 2, 609, 0], 0], // 911
  ['Act 1 (N) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 695, 2, 2, 610, 0], 0], // 912
  ['Act 1 (N) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 696, 2, 2, 611, 0], 0], // 913
  ['Act 2 (N) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 697, 2, 2, 612, 0], 0], // 914
  ['Act 2 (N) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 698, 2, 2, 613, 0], 0], // 915
  ['Act 2 (N) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 699, 2, 2, 614, 0], 0], // 916
  ['Act 3 (N) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 700, 2, 2, 615, 0], 0], // 917
  ['Act 3 (N) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 701, 2, 2, 616, 0], 0], // 918
  ['Act 3 (N) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 702, 2, 2, 617, 0], 0], // 919
  ['Act 4 (N) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 703, 2, 2, 618, 0], 0], // 920
  ['Act 4 (N) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 704, 2, 2, 619, 0], 0], // 921
  ['Act 4 (N) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 704, 2, 2, 619, 0], 0], // 922
  ['Act 5 (N) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 705, 2, 2, 620, 0], 0], // 923
  ['Act 5 (N) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 706, 2, 2, 621, 0], 0], // 924
  ['Act 5 (N) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 707, 2, 2, 622, 0], 0], // 925
  ['Act 1 (H) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 708, 2, 2, 623, 0], 0], // 926
  ['Act 1 (H) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 709, 2, 2, 624, 0], 0], // 927
  ['Act 1 (H) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 710, 2, 2, 625, 0], 0], // 928
  ['Act 2 (H) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 711, 2, 2, 626, 0], 0], // 929
  ['Act 2 (H) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 712, 2, 2, 627, 0], 0], // 930
  ['Act 2 (H) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 713, 2, 2, 628, 0], 0], // 931
  ['Act 3 (H) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 714, 2, 2, 629, 0], 0], // 932
  ['Act 3 (H) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 715, 2, 2, 630, 0], 0], // 933
  ['Act 3 (H) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 716, 2, 2, 631, 0], 0], // 934
  ['Act 4 (H) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 717, 2, 2, 632, 0], 0], // 935
  ['Act 4 (H) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 718, 2, 2, 633, 0], 0], // 936
  ['Act 4 (H) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 718, 2, 2, 633, 0], 0], // 937
  ['Act 5 (H) Super Ax', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 719, 2, 2, 634, 0], 0], // 938
  ['Act 5 (H) Super Bx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 720, 2, 2, 635, 0], 0], // 939
  ['Act 5 (H) Super Cx', 512, 654, 972, 1024, 4, 0, -4, [2, 2, 721, 2, 2, 636, 0], 0], // 940
  ['Cow King', 850, 983, 983, 1024, 3, 0, 5, [2, 1, 682, 2, 2, 218, 0], 0], // 941
  ['Cow King (N)', 850, 983, 983, 1024, 3, 0, 5, [2, 1, 710, 2, 2, 232, 0], 0], // 942
  ['Cow King (H)', 850, 983, 983, 1024, 3, 0, 5, [2, 1, 710, 2, 2, 243, 0], 0], // 943
  ['Duriel', 0, 0, 0, 0, 2, 0, -2, [2, 1, 961, 2, 1, 791, 0], 0], // 944  Duriel-TCs
  ['Duriel (N)', 0, 0, 0, 0, 2, 0, -2, [2, 1, 961, 2, 1, 792, 0], 0], // 945 sind
  ['Duriel (H)', 0, 0, 0, 0, 2, 0, -2, [2, 1, 961, 2, 1, 793, 0], 0], // 946 ein
  ['Durielq', 0, 0, 0, 0, 2, 0, -2, [2, 1, 962, 2, 1, 794, 0], 0], // 947 workaround
  ['Durielq (N)', 0, 0, 0, 0, 2, 0, -2, [2, 1, 962, 2, 1, 795, 0], 0], // 948 von
  ['Durielq (H)', 0, 0, 0, 0, 2, 0, -2, [2, 1, 962, 2, 1, 796, 0], 0], // 949 mfb
  ['ROP', 1024, 0, 0, 0, 1, 0, 1, [2, 1, 809, 0], 0], // 950
  ['ROP (N)', 1024, 0, 0, 0, 4, 0, 1, [2, 4, 810, 0], 0], // 951
  ['Countess Rune', 0, 0, 0, 0, 20, 5, 3, [2, 15, 144, 0], 0], // 952
  ['Countess Rune (N)', 0, 0, 0, 0, 20, 5, 3, [2, 15, 148, 0], 0], // 953
  ['Countess Rune (H)', 0, 0, 0, 0, 20, 5, 3, [2, 15, 152, 0], 0], // 954
  ['Countess Item', 0, 0, 0, 0, 67, 19, 5, [1, 11, 229, 2, 19, 175, 2, 15, 159, 2, 3, 326, 0], 0], // 955
  ['Countess Item (N)', 0, 0, 0, 0, 67, 19, 5, [1, 11, 229, 2, 19, 189, 2, 15, 164, 2, 3, 331, 0], 0], // 956
  ['Countess Item (H)', 0, 0, 0, 0, 68, 19, 5, [1, 11, 229, 2, 19, 204, 2, 15, 169, 2, 3, 336, 1, 1, 595, 0], 0], // 957
  ['Countess', 883, 883, 883, 1024, 2, 0, -2, [2, 1, 955, 2, 1, 952, 0], 0], // 958
  ['Countess (N)', 883, 883, 983, 1024, 2, 0, -2, [2, 1, 956, 2, 1, 953, 0], 0], // 959
  ['Countess (H)', 883, 883, 983, 1024, 2, 0, -2, [2, 1, 957, 2, 1, 954, 0], 0], // 960
  ['DurielTPDummy', 0, 0, 0, 0, 17, 0, 1, [1, 8, 455, 2, 9, 963, 0], 0], // 961
  ['DurielTPDummyQ', 0, 0, 0, 0, 9, 0, 1, [1, 5, 455, 2, 4, 963, 0], 0], // 962
  ['Dummy', 0, 0, 0, 0, 1, 0, 1, [1, 0, 455, 0], 0], // 963
  ['Baalrun (experimental)', 0, 0, 0, 0, 45, 0, -45, [2, 5, 679, 2, 6, 468, 2, 34, 426, 0], 0], //964 experimental, sum of all waves except the skeletons in w2
  ['High Council (experimental)', 0, 0, 0, 0, 13, 0, -13, [2, 13, 820, 0], 0], //965 experimental, sum of the bosses and their minions in travincal
];

import React, { useState } from 'react';
import { IonAccordionGroup, IonButton, IonIcon } from '@ionic/react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { lockClosed, lockOpen } from 'ionicons/icons';
import { useAppSelector } from '../../store/store';
import { selectorPageOrder } from '../../store/settings/selectors';
import { TabPagesReorder } from './components/TabPagesReorder';
import AppLink from '../../common/components/AppLink';

export const TabPages: React.FC = () => {
  const [reorder, setReorder] = useState(false);
  const order = useAppSelector(selectorPageOrder);

  return (
    <AppPage
      padding
      buttonsRight
      buttons={
        <IonButton color={reorder ? 'success' : 'primary'} fill={'outline'} size={'small'} onClick={() => setReorder(prev => !prev)}>
          Reorder
          <IonIcon slot={'end'} icon={reorder ? lockOpen : lockClosed} color={reorder ? 'success' : 'primary'} />
        </IonButton>
      }
    >
      {!reorder && (
        <IonAccordionGroup>
          {order.map(o => (
            <AppLink key={`page_${o}`} page={o} />
          ))}
        </IonAccordionGroup>
      )}
      {reorder && <TabPagesReorder />}
    </AppPage>
  );
};

import React, { useContext } from 'react';
import { Cell, Table } from './styled';
import { useGrailSets, useGrailUniques } from '../../../common/providers/providerHooks';
import { setPickItemModal } from '../../../store/app/appSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectorSets, selectorUniques } from '../../../store/activeData/selector';
import { Filter, Item } from '../../../common/types';
import { HolyGraiLContext } from '../../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { ItemType } from '../../../common/providers/ItemProvider/ItemProvider';

const HgAllTable: React.FC = () => {
  const uniqueData = useGrailUniques();
  const setData = useGrailSets();
  const { addGrailItems, removeGrailItems } = useContext(HolyGraiLContext);
  const dispatch = useAppDispatch();
  const uniques = useAppSelector(selectorUniques);
  const sets = useAppSelector(selectorSets);
  const uniqueExistClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: uniques,
        itemType: 'unique',
        multipleSelect: {
          selectedIds: [
            ...uniqueData.foundArmor.map(a => a.id),
            ...uniqueData.foundWeapons.map(a => a.id),
            ...uniqueData.foundOther.map(a => a.id),
          ],
          onSelect: newItems => {
            const deleted = [
              ...uniqueData.foundArmor.filter(a => !newItems.some(n => n.id === a.id)),
              ...uniqueData.foundWeapons.filter(a => !newItems.some(n => n.id === a.id)),
              ...uniqueData.foundOther.filter(a => !newItems.some(n => n.id === a.id)),
            ];
            const added = newItems.filter(
              n =>
                !uniqueData.foundArmor.some(a => a.id === n.id) &&
                !uniqueData.foundWeapons.some(a => a.id === n.id) &&
                !uniqueData.foundOther.some(a => a.id === n.id),
            );
            doDeleteItems(deleted, 'unique').then(() => doAddItems(added, 'unique'));
          },
        },
      }),
    );
  };
  const setsExistClick = () => {
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items: sets,
        itemType: 'set',
        multipleSelect: {
          selectedIds: [...setData.foundArmor.map(a => a.id), ...setData.foundWeapons.map(a => a.id), ...setData.foundOther.map(a => a.id)],
          onSelect: newItems => {
            const deleted = [
              ...setData.foundArmor.filter(a => !newItems.some(n => n.id === a.id)),
              ...setData.foundWeapons.filter(a => !newItems.some(n => n.id === a.id)),
              ...setData.foundOther.filter(a => !newItems.some(n => n.id === a.id)),
            ];
            const added = newItems.filter(
              n =>
                !setData.foundArmor.some(a => a.id === n.id) &&
                !setData.foundWeapons.some(a => a.id === n.id) &&
                !setData.foundOther.some(a => a.id === n.id),
            );
            doDeleteItems(deleted, 'set').then(() => doAddItems(added, 'set'));
          },
        },
      }),
    );
  };

  const existAllClick = () => {
    //
  };

  const foundUniquesClick = () => {
    const items = [...uniqueData.foundArmor, ...uniqueData.foundWeapons, ...uniqueData.foundOther];
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items,
        itemType: 'unique',
        multipleSelect: {
          selectedIds: [
            ...uniqueData.foundArmor.map(a => a.id),
            ...uniqueData.foundWeapons.map(a => a.id),
            ...uniqueData.foundOther.map(a => a.id),
          ],
          onSelect: newItems => {
            const deleted = items.filter(a => !newItems.some(n => n.id === a.id));
            doDeleteItems(deleted, 'unique');
          },
        },
      }),
    );
  };
  const foundSetsClick = () => {
    const items = [...setData.foundArmor, ...setData.foundWeapons, ...setData.foundOther];
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items,
        itemType: 'set',
        multipleSelect: {
          selectedIds: [...setData.foundArmor.map(a => a.id), ...setData.foundWeapons.map(a => a.id), ...setData.foundOther.map(a => a.id)],
          onSelect: newItems => {
            const deleted = items.filter(a => !newItems.some(n => n.id === a.id));
            doDeleteItems(deleted, 'set');
          },
        },
      }),
    );
  };
  const foundAllClick = () => {
    //
  };

  const remainingUniquesClick = () => {
    const items = [...uniqueData.unFoundArmor, ...uniqueData.unFoundWeapons, ...uniqueData.unFoundOther];
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items,
        itemType: 'unique',
        multipleSelect: {
          selectedIds: [],
          onSelect: newItems => {
            doAddItems(newItems, 'unique');
          },
        },
      }),
    );
  };
  const remainingSetsClick = () => {
    const items = [...setData.unFoundArmor, ...setData.unFoundWeapons, ...setData.unFoundOther];
    dispatch(
      setPickItemModal({
        onDismiss: () => dispatch(setPickItemModal(undefined)),
        items,
        itemType: 'set',
        multipleSelect: {
          selectedIds: [],
          onSelect: newItems => {
            doAddItems(newItems, 'set');
          },
        },
      }),
    );
  };
  const remainingOtherClick = () => {
    //
  };

  const doAddItems = async (items: Item[], type: Filter<ItemType, 'unique' | 'set'>) => {
    await addGrailItems(items.map(item => ({ id: -1, item_id: item.id, item_type: type })));
  };
  const doDeleteItems = async (items: Item[], type: Filter<ItemType, 'unique' | 'set'>) => {
    await removeGrailItems(items.map(item => ({ id: item.id, item_id: item.id, item_type: type })));
  };

  const calcPercent = (numerator: number, denominator: number) => {
    const val = Math.round((numerator / denominator) * 100);
    return isNaN(val) ? 0 : val;
  };

  const getTotalBorder = () => {
    const complete =
      ((uniqueData.foundArmor.length +
        uniqueData.foundWeapons.length +
        uniqueData.foundOther.length +
        setData.foundArmor.length +
        setData.foundWeapons.length +
        setData.foundOther.length) /
        (uniques.length + sets.length)) *
      100;

    if (complete < 10) {
      return '1px red solid';
    }
    if (complete < 20) {
      return '1px #f5f242 solid';
    }
    if (complete < 30) {
      return '1px #b0f542 solid';
    }
    if (complete < 40) {
      return '1px #42f557 solid';
    }
    if (complete < 50) {
      return '1px #42f5ef solid';
    }
    if (complete < 60) {
      return '1px #4284f5 solid';
    }
    if (complete < 70) {
      return '1px #7242f5 solid';
    }
    if (complete < 80) {
      return '1px #b642f5 solid';
    }
    if (complete < 90) {
      return '1px #f542e0 solid';
    }
    if (complete < 95) {
      return '1px #f59e42 solid';
    }

    return '2px #FFD700 solid';
  };

  return (
    <Table>
      <Cell area={'h1'} className={'unique'}>
        Uniques
      </Cell>
      <Cell area={'h2'} className={'set'}>
        Sets
      </Cell>
      <Cell area={'h3'}>All</Cell>

      <Cell area={'r1'} className={'end'}>
        Exist
      </Cell>
      <Cell area={'d1-1'} onClick={uniqueExistClick}>
        {uniqueData.totalArmor + uniqueData.totalWeapons + uniqueData.totalOther}
      </Cell>
      <Cell area={'d1-2'} onClick={setsExistClick}>
        {setData.totalArmor + setData.totalWeapons + setData.totalOther}
      </Cell>
      <Cell area={'d1-3'} onClick={existAllClick}>
        {setData.totalArmor +
          setData.totalWeapons +
          setData.totalOther +
          uniqueData.totalArmor +
          uniqueData.totalWeapons +
          uniqueData.totalOther}
      </Cell>

      <Cell area={'r2'} className={'end'}>
        Found
      </Cell>
      <Cell area={'d2-1'} onClick={foundUniquesClick}>
        {uniqueData.foundArmor.length + uniqueData.foundWeapons.length + uniqueData.foundOther.length}
      </Cell>
      <Cell area={'d2-2'} onClick={foundSetsClick}>
        {setData.foundArmor.length + setData.foundWeapons.length + setData.foundOther.length}
      </Cell>
      <Cell area={'d2-3'} onClick={foundAllClick}>
        {uniqueData.foundArmor.length +
          uniqueData.foundWeapons.length +
          uniqueData.foundOther.length +
          setData.foundArmor.length +
          setData.foundWeapons.length +
          setData.foundOther.length}
      </Cell>

      <Cell area={'r3'} className={'end'}>
        Remaining
      </Cell>
      <Cell area={'d3-1'} onClick={remainingUniquesClick}>
        {uniqueData.unFoundArmor.length + uniqueData.unFoundWeapons.length + uniqueData.unFoundOther.length}
      </Cell>
      <Cell area={'d3-2'} onClick={remainingSetsClick}>
        {setData.unFoundArmor.length + setData.unFoundWeapons.length + setData.unFoundOther.length}
      </Cell>
      <Cell area={'d3-3'} onClick={remainingOtherClick}>
        {uniqueData.unFoundArmor.length +
          uniqueData.unFoundWeapons.length +
          uniqueData.unFoundOther.length +
          setData.unFoundArmor.length +
          setData.unFoundWeapons.length +
          setData.unFoundOther.length}
      </Cell>

      <Cell area={'r4'} className={'end'}>
        % Complete
      </Cell>
      <Cell area={'d4-1'}>
        {calcPercent(
          uniqueData.foundArmor.length + uniqueData.foundWeapons.length + uniqueData.foundOther.length,
          uniqueData.totalArmor + uniqueData.totalWeapons + uniqueData.totalOther,
        )}{' '}
        %
      </Cell>
      <Cell area={'d4-2'}>
        {calcPercent(
          setData.foundArmor.length + setData.foundWeapons.length + setData.foundOther.length,
          setData.totalArmor + setData.totalWeapons + setData.totalOther,
        )}{' '}
        %
      </Cell>
      <Cell area={'d4-3'} style={{ border: getTotalBorder() }}>
        {calcPercent(
          uniqueData.foundArmor.length +
            uniqueData.foundWeapons.length +
            uniqueData.foundOther.length +
            setData.foundArmor.length +
            setData.foundWeapons.length +
            setData.foundOther.length,
          uniqueData.totalArmor +
            uniqueData.totalWeapons +
            uniqueData.totalOther +
            setData.totalArmor +
            setData.totalWeapons +
            setData.totalOther,
        )}{' '}
        %
      </Cell>
    </Table>
  );
};

HgAllTable.displayName = 'HgAllTable';
export default React.memo(HgAllTable);

import React, { useEffect, useState } from 'react';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { useIonAlert, useIonToast } from '@ionic/react';
import { CREATE_MULE_TABLE, DELETE_MULE, DROP_MULES_TABLE, INSERT_MULE, QUERY_ALL_MULES, UPDATE_MULE_NAME } from './sql_utils';
import { sqlite } from '../../../App';

export interface Mule {
  mule_id: number;
  mule_name: string;
  item_count?: number;
}

interface MuleStorage {
  mules: Mule[];
  addMule: (backdropDismiss?: boolean) => Promise<number>;
  insertMule: (name: string) => Promise<number | undefined>;
  updateMule: (id: number, name: string) => void;
  deleteMule: (muleId: number) => Promise<void>;
  queryMules: () => void;
  ready: boolean;
  resetMuleTable: () => Promise<void>;
}

export const MuleContext = React.createContext<MuleStorage>({} as MuleStorage);

export const MuleProvider: React.FC<{
  db: SQLiteDBConnection | undefined;
  isWeb: boolean;
  children: React.ReactNode;
}> = ({ db, isWeb, children }) => {
  const [ready, setReady] = useState(false);
  const [mules, setMules] = useState<Mule[]>([]);

  const [toast] = useIonToast();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (db && !ready) {
      void initializeTables();
    }
  }, [db, ready]);

  const initializeTables = async () => {
    if (db) {
      await db.open();
      await db.execute(CREATE_MULE_TABLE);
      setReady(true);
    }
  };

  useEffect(() => {
    if (ready) {
      void queryAllMules();
    }
  }, [ready]);

  const queryAllMules = async () => {
    const res = await db?.query(QUERY_ALL_MULES);
    setMules(res ? (res?.values as Mule[]) : []);
  };

  const addMule = async (backdropDismiss?: boolean) => {
    return new Promise<number>(resolve => {
      presentAlert({
        header: 'Add Mule',
        inputs: [
          {
            name: 'name',
            placeholder: 'Mule name...',
            attributes: {
              maxlength: 30,
            },
            cssClass: 'alert-input',
          },
        ],
        buttons: [
          {
            text: 'Save',
            handler: async data => {
              const id = await runAddMule(data.name);
              if (id) {
                return resolve(id);
              }
            },
          },
        ],
        mode: 'ios',
        backdropDismiss: backdropDismiss,
      });
    });
  };

  const runAddMule = async (name: string): Promise<number | undefined> => {
    if (!name.length) return;
    if (mules.some(m => m.mule_name === name)) {
      await toast(`A mule with the name "${name}" already exists.`, 2000);
      return Promise.reject('Duplicate name');
    }
    // const res = (await db?.run(INSERT_MULE, [name])) as undefined | { lastId: number };
    const res = await db?.run(INSERT_MULE, [name]);
    console.log('--- INSERT-MULE ---', res);
    await queryAllMules();

    if (isWeb) {
      await sqlite.saveToStore('td2');
    }

    return Promise.resolve(res?.changes?.lastId);
  };

  const updateMule = async (id: number, name: string) => {
    await presentAlert({
      header: `Edit ${name}`,
      inputs: [
        {
          name: 'name',
          placeholder: `${name}`,
          attributes: {
            maxlength: 30,
          },
          cssClass: 'alert-input',
        },
      ],
      buttons: [
        {
          text: 'Save',
          handler: async data => runUpdateMule(id, data.name),
        },
      ],
    });
  };

  const runUpdateMule = async (id: number, name: string) => {
    await db?.run(UPDATE_MULE_NAME(id, name));
    void queryAllMules();
  };

  const runDeleteMule = async (id: number) => {
    await db?.run(DELETE_MULE(id));

    if (isWeb) {
      await sqlite.saveToStore('td2');
    }
    void queryAllMules();
  };

  const resetMuleTable = async () => {
    await db?.execute(DROP_MULES_TABLE);
    await initializeTables();
    await queryAllMules();
    // Need to wait for react state to update before continuing
    return new Promise<void>(resolve => {
      setTimeout(resolve, 200);
    });
  };

  return (
    <MuleContext.Provider
      value={{
        mules: mules,
        addMule,
        insertMule: runAddMule,
        updateMule,
        deleteMule: runDeleteMule,
        queryMules: queryAllMules,
        ready,
        resetMuleTable,
      }}
    >
      {children}
    </MuleContext.Provider>
  );
};

/* eslint-disable */
import {
  aktionsframe,
  fertigkeiten,
  sequenzen,
  startframe,
  waffen,
  waffengattung,
  werform
} from '../../common/data/attack-calc/data';
import {AttackCalcState} from './AttackCalc';
import {DialogProps} from './components/BreakPointDialog';

export interface ACItem {
  name: string;
  value: number;
}

export function setzeCharform(state: AttackCalcState): string[] {
  let char_form_list = [];

  if (state.classes_selected < 7) {
    char_form_list = werform;
    if (state.classes_selected != 2 && state.classes_selected != 3) {
      char_form_list = [];
      char_form_list[0] = werform[0];
      char_form_list[1] = werform[1];
    }
  } else {
    // Merci is selected therefore no werewolf/bear form available
    char_form_list = [];
    char_form_list[0] = werform[0];
  }
  return char_form_list;
}

export function setzeWaffe(state: AttackCalcState): ACItem[] {
  const tempWaffe = state.weapon_selected;
  const weapon_list: ACItem[] = [];
  for (let i = 0; i < waffen.length; i++) {
    if (waffen[i][3] < 0 || waffen[i][3] == state.classes_selected) {
      if (
        state.classes_selected < 7 ||
        (state.classes_selected == 7 && waffen[i][2] == 7) ||
        (state.classes_selected == 8 && (waffen[i][4] == 8 || waffen[i][4] == 2)) ||
        (state.classes_selected == 9 && waffen[i][4] == 9)
      ) {
        weapon_list.push({ name: waffen[i][0], value: i } as ACItem);
      }
    }
  }
  for (let i = 0; i < weapon_list.length; i++) {
    if (weapon_list[i].value == tempWaffe) {
      // todo
      // state.weapon_selected = i;
    }
  }
  return weapon_list;
}

export function setzeBarbschwert(state: AttackCalcState): string[] {
  let twoHanded_list = [];
  if (state.classes_selected == 2 && waffen[state.weapon_selected][2] == 3) {
    twoHanded_list = ['Two-handed', 'Single-handed'];
  } else {
    twoHanded_list = ['---'];
  }
  return twoHanded_list;
}

export function setzeZweitwaffe(state: AttackCalcState, swordIsUsedTwoHanded: boolean): ACItem[] {
  let weapon_list_second: ACItem[] = [];
  switch (state.classes_selected) {
    case 1:
      if (waffen[state.weapon_selected][2] == 1) {
        for (let i = 0; i < waffen.length; i++) {
          if (waffen[i][3] == 1 || waffen[i][2] == 0) {
            weapon_list_second.push({ name: waffen[i][0], value: i } as ACItem);
          }
        }
      }
      break;
    case 2:
      if (
        waffen[state.weapon_selected][2] == 2 ||
        waffen[state.weapon_selected][2] == 4 ||
        (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded)
      ) {
        for (let i = 0; i < waffen.length; i++) {
          if (waffen[i][2] == 0 || waffen[i][2] == 3 || ((waffen[i][2] == 2 || waffen[i][2] == 4) && waffen[i][3] == -1)) {
            weapon_list_second.push({ name: waffen[i][0], value: i } as ACItem);
          }
        }
        state.weapon_selected_second = state.weapon_selected; // defaults off-hand weapon to same as first
      }
      break;
    default:
      weapon_list_second = [];
  }
  return weapon_list_second;
}

export function setzeIAS(statischIAS: boolean, mIAS: number) {
  let ias_list: ACItem[] = [];
  let wIAS1_list: ACItem[] = [];
  let wIAS2_list: ACItem[] = [];
  if (statischIAS) {
    statischIAS = false;
    ias_list = [];
    for (let i = 0; i <= 30 * mIAS; i++) {
      if (mIAS == 1) {
        ias_list.push({ name: String(5 * i), value: 5 * i } as ACItem);
      }
      if (mIAS == 5) {
        ias_list.push({ name: String(i), value: i } as ACItem);
      }
    }
    wIAS1_list = [];
    for (let i = 0; i <= 24 * mIAS; i++) {
      if (mIAS == 1) {
        wIAS1_list.push({ name: String(5 * i), value: 5 * i } as ACItem);
      }
      if (mIAS == 5) {
        wIAS1_list.push({ name: String(i), value: i } as ACItem);
      }
    }
    wIAS2_list = [];
    for (let i = 0; i <= 24 * mIAS; i++) {
      if (mIAS == 1) {
        wIAS2_list.push({ name: String(5 * i), value: 5 * i } as ACItem);
      }
      if (mIAS == 5) {
        wIAS2_list.push({ name: String(i), value: i } as ACItem);
      }
    }
  }
  return { statischIAS, ias_list, wIAS1_list: [...wIAS1_list], wIAS2_list };
}

export function setzeSIAS(state: AttackCalcState, statischFana: boolean, statischFrost: boolean) {
  const fanta_list = [];
  if (statischFana) {
    statischFana = false;
    for (let i = 0; i <= 50; i++) {
      if (i === 0) {
        fanta_list.push({ name: String(i), value: i } as ACItem);
        continue;
      }
      fanta_list.push({
        name: String(i),
        value: Math.floor((Math.floor((110 * i) / (6 + i)) * (40 - 10)) / 100) + 10,
      } as ACItem);
    }
  }
  const frenzy_list = [];
  if (state.classes_selected == 2) {
    for (let i = 0; i <= 50; i++) {
      if (i === 0) {
        frenzy_list.push({ name: String(i), value: i } as ACItem);
        continue;
      }
      frenzy_list.push({
        name: String(i),
        value: Math.floor((Math.floor((110 * i) / (6 + i)) * 50) / 100),
      } as ACItem);
    }
  }

  const wolf_list = [];
  if (state.classes_selected == 2 || state.classes_selected == 3) {
    for (let i = 0; i <= 50; i++) {
      if (i === 0) {
        wolf_list.push({ name: String(i), value: i } as ACItem);
        continue;
      }
      wolf_list.push({
        name: String(i),
        value: Math.floor((Math.floor((110 * i) / (6 + i)) * (80 - 10)) / 100) + 10,
      } as ACItem);
    }
  }

  const burst_list = [];
  if (state.classes_selected == 1) {
    for (let i = 0; i <= 50; i++) {
      if (i === 0) {
        burst_list.push({ name: String(i), value: i } as ACItem);
        continue;
      }
      burst_list.push({
        name: String(i),
        value: Math.floor((Math.floor((110 * i) / (6 + i)) * (60 - 15)) / 100) + 15,
      } as ACItem);
    }
  }

  const holy_freeze_list = [];
  if (statischFrost) {
    statischFrost = false;

    for (let i = 0; i <= 30; i++) {
      if (i === 0) {
        holy_freeze_list.push({ name: String(i), value: i } as ACItem);
        continue;
      }
      holy_freeze_list.push({
        name: String(i),
        value: Math.floor((Math.floor((110 * i) / (6 + i)) * (60 - 25)) / 100) + 25,
      } as ACItem);
    }
  }

  return { statischFana, fanta_list, frenzy_list, wolf_list, burst_list, statischFrost, holy_freeze_list };
}

export function setzeSkillOption(state: AttackCalcState) {
  const skill_list = [];
  skill_list.push({ name: fertigkeiten[0][0], value: fertigkeiten[0][1] } as ACItem);
  switch (state.classes_selected) {
    case 0:
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          // if selected weapon is of type w/e index [4] is then set special skill options
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][1] } as ACItem);
        } else if (waffen[state.weapon_selected][4] == 1) {
          skill_list.push({ name: fertigkeiten[4][0], value: fertigkeiten[4][1] } as ACItem);
        } else if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 5) {
          skill_list.push({ name: fertigkeiten[2][0], value: fertigkeiten[2][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[3][0], value: fertigkeiten[3][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[5][0], value: fertigkeiten[5][1] } as ACItem);
        } else if (waffen[state.weapon_selected][5] == 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
        }
      }
      break;
    case 1:
      skill_list.push({ name: fertigkeiten[13][0], value: fertigkeiten[13][1] } as ACItem);
      skill_list.push({ name: fertigkeiten[14][0], value: fertigkeiten[14][1] } as ACItem);
      skill_list.push({ name: fertigkeiten[15][0], value: fertigkeiten[15][1] } as ACItem);
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][4] != 1) {
          skill_list.push({ name: fertigkeiten[6][0], value: fertigkeiten[6][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[7][0], value: fertigkeiten[7][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[8][0], value: fertigkeiten[8][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][2] == 1 || waffen[state.weapon_selected][4] == 0) {
          skill_list.push({ name: fertigkeiten[9][0], value: fertigkeiten[9][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[10][0], value: fertigkeiten[10][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[11][0], value: fertigkeiten[11][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][2] == 1 && waffen[state.weapon_selected][4] == 1) {
          skill_list.push({ name: fertigkeiten[12][0], value: fertigkeiten[12][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][5] == 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][2] == 1) {
          skill_list.push({ name: fertigkeiten[19][0], value: fertigkeiten[19][1] } as ACItem);
        }
      }
      break;
    case 2:
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][1] } as ACItem);
        }
        if (state.weapon_selected_second > 0) {
          skill_list.push({ name: fertigkeiten[16][0], value: fertigkeiten[16][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[17][0], value: fertigkeiten[17][1] } as ACItem);
        }
        if (
          (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) &&
          (waffen[state.weapon_selected_second][4] == 2 || waffen[state.weapon_selected_second][4] == 3)
        ) {
          skill_list.push({ name: fertigkeiten[18][0], value: fertigkeiten[18][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][4] != 1) {
          skill_list.push({ name: fertigkeiten[19][0], value: fertigkeiten[19][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[20][0], value: fertigkeiten[20][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[21][0], value: fertigkeiten[21][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[22][0], value: fertigkeiten[22][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[23][0], value: fertigkeiten[23][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][5] == 1 || waffen[state.weapon_selected_second][5] == 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
        }
      }
      if (state.char_form_selected == 2) {
        skill_list.push({ name: fertigkeiten[26][0], value: fertigkeiten[26][1] } as ACItem);
      }
      break;
    case 3:
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][0] } as unknown as ACItem);
        }
        if (waffen[state.weapon_selected][5] == 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
        }
      }
      if (state.char_form_selected == 1) {
        skill_list.push({ name: fertigkeiten[27][0], value: fertigkeiten[27][1] } as ACItem);
      }
      if (state.char_form_selected == 2) {
        skill_list.push({ name: fertigkeiten[26][0], value: fertigkeiten[26][1] } as ACItem);
        skill_list.push({ name: fertigkeiten[27][0], value: fertigkeiten[27][1] } as ACItem);
        skill_list.push({ name: fertigkeiten[28][0], value: fertigkeiten[28][1] } as ACItem);
        skill_list.push({ name: fertigkeiten[29][0], value: fertigkeiten[29][1] } as ACItem);
      }
      break;
    case 5:
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][4] != 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[30][0], value: fertigkeiten[30][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[31][0], value: fertigkeiten[31][1] } as ACItem);
          skill_list.push({ name: fertigkeiten[32][0], value: fertigkeiten[32][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][2] == 0 || waffen[state.weapon_selected][2] == 2 || waffen[state.weapon_selected][2] == 4) {
          skill_list.push({ name: fertigkeiten[25][0], value: fertigkeiten[25][1] } as ACItem);
        }
      }
      break;
    case 7:
      break;
    //@ts-ignore
    case 8:
      skill_list.push({ name: fertigkeiten[3][0], value: fertigkeiten[3][1] } as ACItem);
    case 9:
      skill_list.push({ name: fertigkeiten[22][0], value: fertigkeiten[22][1] } as ACItem);
      skill_list.push({ name: fertigkeiten[23][0], value: fertigkeiten[23][1] } as ACItem);
      break;
    default:
      if (state.char_form_selected == 0) {
        if (waffen[state.weapon_selected][4] == 2 || waffen[state.weapon_selected][4] == 3) {
          skill_list.push({ name: fertigkeiten[1][0], value: fertigkeiten[1][1] } as ACItem);
        }
        if (waffen[state.weapon_selected][5] == 1) {
          skill_list.push({ name: fertigkeiten[24][0], value: fertigkeiten[24][1] } as ACItem);
        }
      }
      break;
  }
  return skill_list;
}

let SIAS = 0;
let EIASprimaer = 0;
let WSMprimaer = 0;
let EIASsekundaer = 0;
let WSMsekundaer = 0;
let start = 0;
let _frames = 0;
let IASprimaer = 0;
let IASsekundaer = 0;
let FPA = 0;
let FPAmax = 0;
let cap = 1;
let grenze = 1;
let rollback1 = 0;
let rollback2 = 0;
let rollback3 = 0;
let rollback4 = 0;
let rollback5 = 0;
let rollbackframe = 0;

export function berechneWerte(state: AttackCalcState, anzMax: string, isUsedTwoHanded: boolean, result_fpa: string, result_frqu: string) {
  let ergebnis = 0;
  let temp = 0;
  let temp2 = 0;
  berechneSIAS(state);
  berechneEIAS(state);
  berechneWSM(state);
  anzMax = '';
  let acceleration = Math.max(Math.min(100 + SIAS + EIASprimaer - WSMprimaer, 175), 15);
  // const acceleration2 = NaN;
  const acceleration2 = Math.max(Math.min(100 + SIAS + EIASsekundaer - WSMsekundaer, 175), 15);
  start = 0;
  if ((state.classes_selected == 0 || state.classes_selected == 6) && fertigkeiten[state.skill_selected][2] < 2) {
    start = startframe[waffen[state.weapon_selected][2]];
  }
  if (
    (fertigkeiten[state.skill_selected][2] == 0 || fertigkeiten[state.skill_selected][2] == 6) &&
    fertigkeiten[state.skill_selected][4] == 100
  ) {
    _frames = _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
    if (waffen[state.weapon_selected][2] == 3 && !isUsedTwoHanded) {
      _frames = 16;
    }
    ergebnis = berechneFPA(state, _frames, acceleration, start, anzMax);
  }
  if (fertigkeiten[state.skill_selected][2] == 1 && fertigkeiten[state.skill_selected][4] == 100) {
    _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
    if (waffen[state.weapon_selected][2] == 3 && !isUsedTwoHanded) {
      _frames = 16;
    }
    ergebnis = berechneFPA(state, _frames, acceleration, start, anzMax);
    if (ergebnis > berechneFPA(state, _frames, 175, start, anzMax)) {
      grenze = 0;
    }
    if (state.char_form_selected) {
      temp = ergebnis;
      _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][1];
      if (waffen[state.weapon_selected][2] == 3 && !isUsedTwoHanded) {
        _frames = 16;
      }
      ergebnis = berechneFPA(state, _frames, acceleration, start, anzMax);
      if (ergebnis > berechneFPA(state, _frames, 175, start, anzMax)) {
        grenze = 0;
      }
      ergebnis = (ergebnis + temp) / 2;
    }
    if (state.classes_selected == 9) {
      ergebnis = ergebnis / 2;
    }
    if (state.weapon_selected_second > 0) {
      temp = ergebnis;
      ergebnis = berechneFPA(state, 12, acceleration2, 0, anzMax);
      if (ergebnis > berechneFPA(state, 12, 175, 0, anzMax)) {
        grenze = 0;
      }
      ergebnis = (ergebnis + temp) / 2;
    }
    if (grenze == 1) {
      anzMax = '(Further IAS useless)';
    }
    grenze = 1;
  }
  if (
    fertigkeiten[state.skill_selected][2] >= 2 &&
    fertigkeiten[state.skill_selected][2] <= 5 &&
    fertigkeiten[state.skill_selected][4] == 100
  ) {
    if (fertigkeiten[state.skill_selected][2] == 2) {
      _frames = waffengattung[9][state.classes_selected];
    }
    if (fertigkeiten[state.skill_selected][2] == 3) {
      _frames = 13;
    }
    if (fertigkeiten[state.skill_selected][2] == 4) {
      _frames = 12;
    }
    if (fertigkeiten[state.skill_selected][2] == 5) {
      _frames = 8;
    }
    ergebnis = berechneFPA(state, _frames, acceleration, start, anzMax);
  }
  if (fertigkeiten[state.skill_selected][2] == 7 && state.skill_selected != 19 && fertigkeiten[state.skill_selected][4] == 100) {
    _frames = sequenzen[fertigkeiten[state.skill_selected][3]][waffen[state.weapon_selected][2]];
    if (state.skill_selected > 8 && state.skill_selected < 13 && state.weapon_selected_second > 0) {
      _frames = 16;
    }
    if (state.classes_selected == 8) {
      _frames = 14;
    }
    start = 0;
    ergebnis = berechneFPA(state, _frames, acceleration, start, anzMax);
    ergebnis++;
    if (state.skill_selected == 3 && state.classes_selected < 7) {
      ergebnis = (100 * ergebnis) / 3 / 100;
    }
    if (state.classes_selected == 8) {
      ergebnis = ergebnis / 2;
    }
    if (state.skill_selected > 15 && state.skill_selected < 19) {
      ergebnis = ergebnis / 2;
    }
    if (state.skill_selected > 8 && state.skill_selected < 13 && state.weapon_selected_second > 0) {
      ergebnis = ergebnis / 2;
    }
  }
  if (state.skill_selected == 19) {
    _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
    if (waffen[state.weapon_selected][2] == 3) {
      _frames = 16;
    }
    acceleration = 100 + state.wIAS1_selected - waffen[state.weapon_selected][1];
    temp = berechneFPA(state, _frames, acceleration, start, anzMax);
    ergebnis = wirbelwind(temp);
    if (ergebnis > 4) {
      grenze = 0;
    }
    if (state.weapon_selected == 0) {
      ergebnis = 10;
      anzMax = '(Further IAS useless)';
    }
    if (state.weapon_selected_second > 0) {
      temp2 = ergebnis;
      _frames = waffengattung[waffen[state.weapon_selected_second][2]][state.classes_selected][0];
      if (waffen[state.weapon_selected_second][2] == 3) {
        _frames = 16;
      }

      acceleration = 100 + state.wIAS2_selected - waffen[state.weapon_selected_second][1];
      temp = berechneFPA(state, _frames, acceleration, start, anzMax);
      ergebnis = wirbelwind(temp);
      if (ergebnis > 4) {
        grenze = 0;
      }
      ergebnis = (ergebnis + temp2) / 4;
    }
    if (grenze == 1) {
      anzMax = '(Further IAS useless)';
    }
    grenze = 1;
  }
  if (fertigkeiten[state.skill_selected][4] == 0) {
    if (state.skill_selected == 14) {
      rollback1 = berechneFPA(state, 4, acceleration, 0, anzMax);
      rollback1++;
      rollback3 = berechneFPA(state, 13, acceleration, 0, anzMax);
      if (rollback3 == 7) {
        anzMax = '(Further IAS useless)';
      }
      result_fpa = rollback1 + '/' + rollback1 + '/' + rollback1 + '/' + rollback1 + '/' + rollback3 + ' _frames per attack';
      result_frqu = (Math.round(((100 * 25) / ((rollback1 * 4 + rollback3) / 5) / 100) * 100) / 100).toString(10);
    }
    if (state.skill_selected == 29) {
      _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
      rollback1 = berechneFPA(state, _frames, acceleration, 0, anzMax);
      if (rollback1 > berechneFPA(state, _frames, 175, 0, anzMax)) {
        grenze = 0;
      }
      rollback1++;
      rollback3 = berechneFPA(state, _frames, acceleration, 1, anzMax);
      if (rollback3 > berechneFPA(state, _frames, 175, 1, anzMax)) {
        grenze = 0;
      }
      if (grenze == 1) {
        anzMax = '(Further IAS useless)';
      }
      grenze = 1;
      result_fpa = rollback1 + '/' + rollback1 + '/' + rollback1 + '/' + rollback1 + '/' + rollback3 + ' _frames per attack';
      result_frqu = (Math.round(((100 * 25) / ((rollback1 * 4 + rollback3) / 5) / 100) * 100) / 100).toString(10);
    }
    if (state.skill_selected == 24) {
      _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
      if (waffen[state.weapon_selected][2] == 3 && !isUsedTwoHanded) {
        _frames = 7;
      }
      rollback1 = berechneFPA(state, _frames, acceleration, start, anzMax);
      if (rollback1 > berechneFPA(state, _frames, 175, start, anzMax)) {
        grenze = 0;
      }
      rollback1++;
      rollback2 = berechneFPA(state, _frames, acceleration, 0, anzMax);
      if (rollback2 > berechneFPA(state, _frames, 175, 0, anzMax)) {
        grenze = 0;
      }
      rollback2++;
      _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
      if (waffen[state.weapon_selected][2] == 3 && !isUsedTwoHanded) {
        _frames = 16;
      }
      rollback3 = berechneFPA(state, _frames, acceleration, 0, anzMax);
      if (rollback3 > berechneFPA(state, _frames, 175, 0, anzMax)) {
        grenze = 0;
      }
      if (grenze == 1) {
        anzMax = '(Further IAS useless)';
      }
      grenze = 1;
      result_fpa = rollback1 + '/' + rollback2 + '/' + rollback2 + '/' + rollback2 + '/' + rollback3?.toString() ?? '';
      if (state.skill_selected == 24) {
        result_frqu = (Math.round(((100 * 25) / (rollback1 + rollback2 * 3 + rollback3 / 5) / 100) * 100) / 100).toString(10);
      }
    }
  }
  if (fertigkeiten[state.skill_selected][4] == 50) {
    _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
    if (acceleration > 149) {
      acceleration = 149;
    }
    rollback1 = berechneFPA(state, _frames, acceleration, start, anzMax);
    if (rollback1 > berechneFPA(state, _frames, 149, start, anzMax)) {
      grenze = 0;
    }
    rollback1++;

    rollbackframe = Math.floor(
      (Math.floor((256 * start + Math.floor((256 * acceleration) / 100) * rollback1) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
    );
    rollback2 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback2 > berechneFPA(state, _frames, 149, rollbackframe, anzMax)) {
      grenze = 0;
    }
    rollback2++;

    rollbackframe = Math.floor(
      (Math.floor((256 * rollbackframe + Math.floor((256 * acceleration) / 100) * rollback2) / 256) *
        fertigkeiten[state.skill_selected][4]) /
        100,
    );
    rollback3 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback3 > berechneFPA(state, _frames, 149, rollbackframe, anzMax)) {
      grenze = 0;
    }
    rollback3++;

    rollbackframe = Math.floor(
      (Math.floor((256 * rollbackframe + Math.floor((256 * acceleration) / 100) * rollback3) / 256) *
        fertigkeiten[state.skill_selected][4]) /
        100,
    );
    rollback4 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback4 > berechneFPA(state, _frames, 149, rollbackframe, anzMax)) {
      grenze = 0;
    }
    rollback4++;
    _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];

    rollbackframe = Math.floor(
      (Math.floor((256 * rollbackframe + Math.floor((256 * acceleration) / 100) * rollback4) / 256) *
        fertigkeiten[state.skill_selected][4]) /
        100,
    );
    rollback5 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback5 > berechneFPA(state, _frames, 149, rollbackframe, anzMax)) {
      grenze = 0;
    }
    if (rollback2 == 5 && rollback3 == 4) {
      rollback3 = 5;
      rollback5 = 13;
    }
    if (grenze == 1) {
      anzMax = '(Further IAS useless)';
    }
    grenze = 1;
    result_fpa = rollback1 + '/' + rollback2 + '/' + rollback3 + '/' + rollback4 + '/' + rollback5?.toString() ?? '';
    result_frqu = (
      Math.round(((100 * 25) / ((rollback1 + rollback2 + rollback3 * 4 + rollback4 * 3 + rollback5) / 10) / 100) * 100) / 100
    ).toString(10);
  }
  if (fertigkeiten[state.skill_selected][4] == 40) {
    _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
    rollback1 = berechneFPA(state, _frames, acceleration, start, anzMax);
    if (rollback1 > berechneFPA(state, _frames, 175, start, anzMax)) {
      grenze = 0;
    }
    rollback1++;
    rollbackframe = Math.floor(
      (Math.floor((256 * start + Math.floor((256 * acceleration) / 100) * rollback1) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
    );
    rollback2 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback2 > berechneFPA(state, _frames, 175, rollbackframe, anzMax)) {
      grenze = 0;
    }
    rollback2++;
    _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
    rollbackframe = Math.floor(
      (Math.floor((256 * rollbackframe + Math.floor((256 * acceleration) / 100) * rollback2) / 256) *
        fertigkeiten[state.skill_selected][4]) /
        100,
    );
    rollback3 = berechneFPA(state, _frames, acceleration, rollbackframe, anzMax);
    if (rollback3 > berechneFPA(state, _frames, 175, rollbackframe, anzMax)) {
      grenze = 0;
    }
    if (grenze == 1) {
      anzMax = '(Further IAS useless)';
    }
    grenze = 1;
    result_fpa = rollback1 + '/' + rollback2 + '/' + rollback3?.toString() ?? '';
    result_frqu = (Math.round(((100 * 25) / ((rollback1 + rollback2 + rollback3) / 2) / 100) * 100) / 100).toString(10);
  }
  if (fertigkeiten[state.skill_selected][4] == 100) {
    result_fpa = ergebnis?.toString() ?? '';
    result_frqu = (Math.round(((100 * 25) / (ergebnis ?? 1) / 100) * 100) / 100).toString(10);
    if (state.classes_selected > 6) {
      result_frqu = (Math.round(((100 * 25) / ((ergebnis ?? 1) + 2) / 100) * 100) / 100).toString(10);
    }
    if ((state.classes_selected == 8 && state.skill_selected == 3) || (state.classes_selected == 9 && state.skill_selected == 0)) {
      result_frqu = Math.round((100 * 25) / (ergebnis ?? 1 + 1) / 100).toString(10);
    }
    if (state.char_form_selected > 0 && state.weapon_selected_second > 0 && state.skill_selected == 0) {
      result_fpa = 'Calculation makes no sense';
      result_frqu = '';
    }
  }
  return { anzMax, result_fpa, result_frqu };
}

function berechneSIAS(state: AttackCalcState) {
  const fana = Math.floor((Math.floor((110 * state.fanta_selected) / (6 + state.fanta_selected)) * (40 - 10)) / 100) + 10;
  const frenzy = Math.floor((Math.floor((110 * state.frenzy_selected) / (6 + state.frenzy_selected)) * (50 - 0)) / 100);
  let wolf = Math.floor((Math.floor((110 * state.wolf_selected) / (6 + state.wolf_selected)) * (80 - 10)) / 100) + 10;
  const tempo = Math.floor((Math.floor((110 * state.burst_selected) / (6 + state.burst_selected)) * (60 - 15)) / 100) + 15;
  const holyfrost = Math.floor((Math.floor((110 * state.holy_freeze_selected) / (6 + state.holy_freeze_selected)) * (60 - 25)) / 100) + 25;
  if (state.char_form_selected != 2) {
    wolf = 0;
  }
  SIAS = fana + frenzy + wolf + tempo - holyfrost;
  if (state.skill_selected == 16) {
    SIAS = SIAS + 50;
  }
  if (state.skill_selected == 13) {
    SIAS = SIAS - 40;
  }
  // todo - seems to always be false?
  // if (isDecrepify) {
  //   SIAS = SIAS - 50;
  // }
  if (fertigkeiten[state.skill_selected][2] == 7 && state.classes_selected < 7) {
    SIAS = SIAS - 30;
  }
}

function berechneEIAS(state: AttackCalcState) {
  if (state.weapon_selected == 0) {
    IASprimaer = state.ias_selected;
  }
  if (state.weapon_selected > 0 && state.weapon_selected_second == 0) {
    IASprimaer = state.ias_selected + state.wIAS1_selected;
  }
  if (state.weapon_selected_second > 0) {
    IASprimaer = state.ias_selected + state.wIAS1_selected;
    IASsekundaer = state.ias_selected + state.wIAS2_selected;
  }
  EIASprimaer = Math.floor((120 * IASprimaer) / (120 + IASprimaer));
  EIASsekundaer = Math.floor((120 * IASsekundaer) / (120 + IASsekundaer));
}

function berechneWSM(state: AttackCalcState) {
  if (state.classes_selected != 1 && state.classes_selected != 2) {
    WSMprimaer = waffen[state.weapon_selected][1];
  }
  if ((state.classes_selected == 1 || state.classes_selected == 2) && state.weapon_selected_second == 0) {
    // todo bug might be here: was 'document.myform.zweitwaffe.selectedIndex'
    WSMprimaer = waffen[state.weapon_selected][1];
  }
  if ((state.classes_selected == 1 || state.classes_selected == 2) && state.weapon_selected_second > 0) {
    const isPrimaryWeapon = false; // todo
    if (isPrimaryWeapon) {
      WSMprimaer = (waffen[state.weapon_selected][1] + waffen[state.weapon_selected_second][1]) / 2;
      WSMsekundaer =
        waffen[state.weapon_selected][1] +
        waffen[state.weapon_selected_second][1] / 2 +
        waffen[state.weapon_selected_second][1] -
        waffen[state.weapon_selected][1];
    } else {
      WSMprimaer =
        waffen[state.weapon_selected][1] +
        waffen[state.weapon_selected_second][1] / 2 +
        waffen[state.weapon_selected][1] -
        waffen[state.weapon_selected_second][1];
      WSMsekundaer = (waffen[state.weapon_selected][1] + waffen[state.weapon_selected_second][1]) / 2;
    }
  }
}

function berechneFPA(state: AttackCalcState, FramesPerDirection: number, Acceleration: number, StartingFrame: number, anzMax: string) {
  // var Acceleration;
  let AnimationSpeed = 256;
  if (state.classes_selected == 1 && waffen[state.weapon_selected][2] == 1) {
    AnimationSpeed = 208;
  }
  if ((fertigkeiten[state.skill_selected][2] == 3 || fertigkeiten[state.skill_selected][2] == 7) && state.skill_selected != 19) {
    AnimationSpeed = 256;
  }
  if (fertigkeiten[state.skill_selected][2] == 5) {
    AnimationSpeed = 128;
  }
  if (state.char_form_selected == 1) {
    if (waffen[state.weapon_selected][2] == 3) {
      FramesPerDirection = waffengattung[2][state.classes_selected][0];
    }
    AnimationSpeed = Math.floor(
      (256 * 10) /
        Math.floor(
          (256 * FramesPerDirection) /
            Math.floor(((100 + IASprimaer - state.ias_selected - waffen[state.weapon_selected][1]) * AnimationSpeed) / 100),
        ),
    );
    FramesPerDirection = 12;
    if (fertigkeiten[state.skill_selected][2] == 6) {
      FramesPerDirection = 10;
    }
    StartingFrame = 0;
  }
  if (state.char_form_selected == 2) {
    if (waffen[state.weapon_selected][2] == 3) {
      FramesPerDirection = waffengattung[2][state.classes_selected][0];
    }
    AnimationSpeed = Math.floor(
      (256 * 9) /
        Math.floor(
          (256 * FramesPerDirection) /
            Math.floor(((100 + IASprimaer - state.ias_selected - waffen[state.weapon_selected][1]) * AnimationSpeed) / 100),
        ),
    );
    FramesPerDirection = 13;
    if (state.skill_selected == 29 && StartingFrame == 0) {
      FramesPerDirection = 7;
    }
    if (fertigkeiten[state.skill_selected][2] == 6) {
      FramesPerDirection = 10;
    }
    StartingFrame = 0;
  }
  FPA = Math.ceil((256 * (FramesPerDirection - StartingFrame)) / Math.floor((AnimationSpeed * Acceleration) / 100)) - 1;
  FPAmax = Math.ceil((256 * (FramesPerDirection - StartingFrame)) / Math.floor((AnimationSpeed * 175) / 100)) - 1;
  if (state.skill_selected == 19) {
    FPA = Math.floor((256 * FramesPerDirection) / Math.floor((AnimationSpeed * Acceleration) / 100));
    FPAmax = 0;
  }
  if (state.skill_selected == 26) {
    FPA =
      Math.ceil((256 * 7) / Math.floor((AnimationSpeed * Acceleration) / 100)) +
      Math.ceil(
        (256 * 13 -
          Math.floor((AnimationSpeed * Acceleration) / 100) * Math.ceil((256 * 7) / Math.floor((AnimationSpeed * Acceleration) / 100))) /
          (2 * Math.floor((AnimationSpeed * Acceleration) / 100)),
      ) -
      1;
    FPAmax =
      Math.ceil((256 * 7) / Math.floor((AnimationSpeed * 175) / 100)) +
      Math.ceil(
        (256 * 13 - Math.floor((AnimationSpeed * 175) / 100) * Math.ceil((256 * 7) / Math.floor((AnimationSpeed * 175) / 100))) /
          (2 * Math.floor((AnimationSpeed * 175) / 100)),
      ) -
      1;
  }
  if (cap == 1) {
    anzMax = '';
    if (fertigkeiten[state.skill_selected][4] == 100 && fertigkeiten[state.skill_selected][2] != 1 && FPA <= FPAmax) {
      anzMax = '(Further IAS useless)';
    }
  }
  return FPA;
}

function wirbelwind(temp: number) {
  // var temp;
  let ergebnis = 4;
  if (temp > 11) {
    ergebnis = 6;
  }
  if (temp > 14) {
    ergebnis = 8;
  }
  if (temp > 17) {
    ergebnis = 10;
  }
  if (temp > 19) {
    ergebnis = 12;
  }
  if (temp > 22) {
    ergebnis = 14;
  }
  if (temp > 25) {
    ergebnis = 16;
  }
  return ergebnis;
}

export function berechneBreakpoints(
  state: AttackCalcState,
  anzMax: string,
  swordIsUsedTwoHanded: boolean,
  showDialog: (args: Omit<DialogProps, 'onClose'>) => void,
  presentToast: (val: string) => void,
) {
  let ergebnis = 0;
  let RBframe = 0;
  let temp: any = 0;
  let temp1 = 0;
  let OIAS = state.ias_selected;
  const WIAS = state.wIAS1_selected;

  const fenster =
    state.char_form_selected > 0 && (state.weapon_selected == 0 || (state.weapon_selected_second > 0 && state.skill_selected == 0));
  cap = 0;
  let breakpoints = [];
  const breakpoints1 = [];
  let breakpoints2 = [];
  const breakpointsAPS = [];
  if (state.char_form_selected == 0) {
    temp1 = 0;
    if (
      (fertigkeiten[state.skill_selected][2] == 0 ||
        fertigkeiten[state.skill_selected][2] == 2 ||
        fertigkeiten[state.skill_selected][2] == 3 ||
        fertigkeiten[state.skill_selected][2] == 4 ||
        fertigkeiten[state.skill_selected][2] == 5) &&
      fertigkeiten[state.skill_selected][4] == 100
    ) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        ergebnis = berechneFPA(state, _frames, i, start, anzMax);
        if (temp1 != ergebnis && i - 100 - SIAS + WSMprimaer < 120) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            ergebnis,
          ];
          temp1 = ergebnis;
        }
      }
    }
    if (fertigkeiten[state.skill_selected][2] == 1 && state.weapon_selected_second == 0 && fertigkeiten[state.skill_selected][4] == 100) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 16;
        }
        ergebnis = berechneFPA(state, _frames, i, start, anzMax);
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][1];
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 16;
        }
        temp = berechneFPA(state, _frames, i, start, anzMax);
        ergebnis = (ergebnis + temp) / 2;
        if (state.classes_selected == 9) {
          ergebnis = ergebnis / 2;
        }
        if (temp1 != ergebnis && i - 100 - SIAS + WSMprimaer < 120) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            ergebnis,
          ];
          temp1 = ergebnis;
        }
      }
    }
    if (fertigkeiten[state.skill_selected][2] == 1 && state.weapon_selected_second > 0 && fertigkeiten[state.skill_selected][4] == 100) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 16;
        }
        ergebnis = berechneFPA(state, _frames, i, 0, anzMax);
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][1];
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 16;
        }
        temp = berechneFPA(state, _frames, i, 0, anzMax);
        ergebnis = (ergebnis + temp) / 2;
        if (temp1 != ergebnis && i - 100 - SIAS + WSMprimaer < 120) {
          breakpoints1[breakpoints1.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))) - IASprimaer,
            ergebnis,
          ];
          temp1 = ergebnis;
        }
        if (breakpoints1.length > 1 && breakpoints1[1][0] < 0 && breakpoints1[0][0] == 0) {
          breakpoints1.reverse();
          breakpoints1.length = breakpoints1.length - 1;
          breakpoints1.reverse();
        }
        if (breakpoints1.length > 0 && breakpoints1[0][0] < 0) {
          breakpoints1[0][0] = 0;
        }
      }
      temp1 = 0;
      for (let i = Math.max(100 + SIAS - WSMsekundaer, 15); i <= 175; i++) {
        ergebnis = berechneFPA(state, 12, i, 0, anzMax);
        if (temp1 != ergebnis && i - 100 - SIAS + WSMsekundaer < 120) {
          breakpoints2[breakpoints2.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMsekundaer)) / (120 - (i - 100 - SIAS + WSMsekundaer))) - IASsekundaer,
            ergebnis,
          ];
          temp1 = ergebnis;
        }
        if (breakpoints2.length > 1 && breakpoints2[1][0] < 0 && breakpoints2[0][0] == 0) {
          breakpoints2.reverse();
          breakpoints2.length = breakpoints2.length - 1;
          breakpoints2.reverse();
        }
        if (breakpoints2.length > 0 && breakpoints2[0][0] < 0) {
          breakpoints2[0][0] = 0;
        }
      }
      const IASmax1 = breakpoints1[breakpoints1.length - 1][0];
      const IASmax2 = breakpoints2[breakpoints2.length - 1][0];
      for (let i = 0; i <= Math.max(IASmax1, IASmax2); i++) {
        if (breakpoints1.length > 1 && breakpoints1[1][0] == i) {
          breakpoints1.reverse();
          breakpoints1.length = breakpoints1.length - 1;
          breakpoints1.reverse();
        }
        if (breakpoints2.length > 1 && breakpoints2[1][0] == i) {
          breakpoints2.reverse();
          breakpoints2.length = breakpoints2.length - 1;
          breakpoints2.reverse();
        }
        if (breakpoints1[0][0] == i || breakpoints2[0][0] == i) {
          breakpoints[breakpoints.length] = [i, (breakpoints1[0][1] + breakpoints2[0][1]) / 2];
        }
      }
    }
    if (fertigkeiten[state.skill_selected][2] == 7 && state.skill_selected != 19) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        ergebnis = berechneFPA(state, _frames, i, 0, anzMax);
        ergebnis++;
        if (state.skill_selected == 3 && state.classes_selected < 7) {
          ergebnis = (100 * ergebnis) / 3 / 100;
        }
        if (state.classes_selected == 8) {
          ergebnis = ergebnis / 2;
        }
        if (state.skill_selected > 15 && state.skill_selected < 19) {
          ergebnis = ergebnis / 2;
        }
        if (state.skill_selected > 8 && state.skill_selected < 13 && state.weapon_selected_second > 0) {
          ergebnis = ergebnis / 2;
        }
        if (temp1 != ergebnis && i - 100 - SIAS + WSMprimaer < 120) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            ergebnis,
          ];
          temp1 = ergebnis;
        }
      }
    }
    if (state.skill_selected == 19) {
      for (let i = 100 + IASprimaer - OIAS - WSMprimaer; i <= 175; i++) {
        temp = berechneFPA(state, _frames, i, 0, anzMax);
        ergebnis = wirbelwind(temp);
        if (temp1 != ergebnis) {
          breakpoints[breakpoints.length] = [i - 100 + WSMprimaer, ergebnis];
          temp1 = ergebnis;
        }
      }
    }
    if (fertigkeiten[state.skill_selected][4] == 0) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        if (state.skill_selected == 14) {
          _frames = 4;
        }
        if (state.skill_selected == 24) {
          _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
        }
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 7;
        }
        rollback1 = berechneFPA(state, _frames, i, start, anzMax);
        rollback1++;
        rollback2 = berechneFPA(state, _frames, i, 0, anzMax);
        rollback2++;
        if (state.skill_selected == 14) {
          _frames = 13;
        }
        if (state.skill_selected == 24) {
          _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
        }
        if (waffen[state.weapon_selected][2] == 3 && !swordIsUsedTwoHanded) {
          _frames = 16;
        }
        rollback3 = berechneFPA(state, _frames, i, 0, anzMax);
        ergebnis = rollback1 + rollback2 + rollback3;
        if (temp1 != ergebnis && i - 100 - SIAS + WSMprimaer < 120) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            rollback1 + '/' + rollback2 + '/' + rollback2 + '/' + rollback2 + '/' + rollback3,
          ];
          breakpointsAPS[breakpointsAPS.length] = 2500 / ((rollback1 + rollback2 * 3 + rollback3) / 5) / 100;
          temp1 = ergebnis;
        }
      }
    }
    if (fertigkeiten[state.skill_selected][4] == 50) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 149; i++) {
        _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
        rollback1 = berechneFPA(state, _frames, i, start, anzMax);
        rollback1++;
        RBframe = Math.floor(
          (Math.floor((256 * start + Math.floor((256 * i) / 100) * rollback1) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback2 = berechneFPA(state, _frames, i, RBframe, anzMax);
        rollback2++;
        RBframe = Math.floor(
          (Math.floor((256 * RBframe + Math.floor((256 * i) / 100) * rollback2) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback3 = berechneFPA(state, _frames, i, RBframe, anzMax);
        rollback3++;
        RBframe = Math.floor(
          (Math.floor((256 * RBframe + Math.floor((256 * i) / 100) * rollback3) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback4 = berechneFPA(state, _frames, i, RBframe, anzMax);
        rollback4++;
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
        RBframe = Math.floor(
          (Math.floor((256 * RBframe + Math.floor((256 * i) / 100) * rollback4) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback5 = berechneFPA(state, _frames, i, RBframe, anzMax);
        if (rollback2 == rollback3 || rollback3 == rollback4) {
          ergebnis = rollback1 + rollback2 + rollback3 + rollback4 + rollback5;
        }
        if (temp1 != ergebnis) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            rollback1 + '/' + rollback2 + '/' + rollback3 + '/' + rollback4 + '/' + rollback5,
          ];
          breakpointsAPS[breakpointsAPS.length] = 2500 / ((rollback1 + rollback2 + rollback3 * 4 + rollback4 * 3 + rollback5) / 10) / 100;
          temp1 = ergebnis;
        }
      }
    }
    if (fertigkeiten[state.skill_selected][4] == 40) {
      for (let i = Math.max(100 + SIAS - WSMprimaer, 15); i <= 175; i++) {
        _frames = aktionsframe[waffen[state.weapon_selected][2]][state.classes_selected];
        rollback1 = berechneFPA(state, _frames, i, start, anzMax);
        rollback1++;
        RBframe = Math.floor(
          (Math.floor((256 * start + Math.floor((256 * i) / 100) * rollback1) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback2 = berechneFPA(state, _frames, i, RBframe, anzMax);
        rollback2++;
        _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
        RBframe = Math.floor(
          (Math.floor((256 * RBframe + Math.floor((256 * i) / 100) * rollback2) / 256) * fertigkeiten[state.skill_selected][4]) / 100,
        );
        rollback3 = berechneFPA(state, _frames, i, RBframe, anzMax);
        ergebnis = rollback1 + rollback2 + rollback3;
        if (temp1 != ergebnis) {
          breakpoints[breakpoints.length] = [
            Math.ceil((120 * (i - 100 - SIAS + WSMprimaer)) / (120 - (i - 100 - SIAS + WSMprimaer))),
            rollback1 + '/' + rollback2 + '/' + rollback3,
          ];
          breakpointsAPS[breakpointsAPS.length] = 2500 / ((rollback1 + rollback2 + rollback3) / 3) / 100;
          temp1 = ergebnis;
        }
      }
    }
    let aidel = 0;
    if (state.classes_selected > 6) {
      aidel = 2;
    }
    if ((state.classes_selected == 8 && state.skill_selected == 3) || (state.classes_selected == 9 && state.skill_selected == 0)) {
      aidel = 1;
    }
    if (fertigkeiten[state.skill_selected][4] == 100) {
      showDialog({ open: true, data: breakpoints, aidel, type: 0 });
    }
    if (fertigkeiten[state.skill_selected][4] != 100) {
      showDialog({ open: true, data: breakpoints, aidel: undefined, type: 2, aps: breakpointsAPS });
    }
  }
  if (state.char_form_selected > 0) {
    while (OIAS / 5 != OIAS / 5) {
      OIAS--;
    }
    breakpoints = [];
    breakpoints2 = [];
    if (state.weapon_selected == 0 || (state.weapon_selected_second > 0 && state.skill_selected == 0)) {
      if (state.skill_selected == 0 && state.weapon_selected_second > 0) {
        presentToast("Theres a bug when using 'Standard Attack' and two weapons in wereform. Therefore, it will be skipped");
      }
      if (state.weapon_selected == 0) {
        presentToast('Please select a weapon.');
      }
    } else {
      _frames = waffengattung[waffen[state.weapon_selected][2]][state.classes_selected][0];
      if (waffen[state.weapon_selected][2] == 3) {
        _frames = waffengattung[2][state.classes_selected][0];
      }
      let AnimSpeed = 256;
      if (waffen[state.weapon_selected][2] == 1) {
        AnimSpeed = 208;
      }
      let tempframe = 0;
      let tempframe2 = 0;
      for (let i = 0; i <= 24; i++) {
        for (let j = 0; j <= 14; j++) {
          if (state.skill_selected == 26) {
            breakpoints[breakpoints.length] =
              Math.ceil(
                (256 * 7) /
                  Math.floor(
                    (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                      Math.min(
                        Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                        175,
                      )) /
                      100,
                  ),
              ) +
              Math.ceil(
                (256 * 13 -
                  Math.floor(
                    (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                      Math.min(
                        Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                        175,
                      )) /
                      100,
                  ) *
                    Math.ceil(
                      (256 * 7) /
                        Math.floor(
                          (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                            Math.min(
                              Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                              175,
                            )) /
                            100,
                        ),
                    )) /
                  (2 *
                    Math.floor(
                      (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                          175,
                        )) /
                        100,
                    )),
              ) -
              1;
            if (OIAS > 70 && j == 0) {
              breakpoints2[breakpoints2.length] =
                Math.ceil(
                  (256 * 7) /
                    Math.floor(
                      (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                          175,
                        )) /
                        100,
                    ),
                ) +
                Math.ceil(
                  (256 * 13 -
                    Math.floor(
                      (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                          175,
                        )) /
                        100,
                    ) *
                      Math.ceil(
                        (256 * 7) /
                          Math.floor(
                            (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                              Math.min(
                                Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                                175,
                              )) /
                              100,
                          ),
                      )) /
                    (2 *
                      Math.floor(
                        (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                          Math.min(
                            Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                            175,
                          )) /
                          100,
                      )),
                ) -
                1;
            }
          }
          if (state.skill_selected == 29) {
            temp =
              (Math.ceil(
                (256 * 7) /
                  Math.floor(
                    (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                      Math.min(
                        Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                        175,
                      )) /
                      100,
                  ),
              ) *
                4 +
                Math.ceil(
                  (256 * 13) /
                    Math.floor(
                      (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                          175,
                        )) /
                        100,
                    ),
                ) -
                1) /
              5;
            if (parseInt(temp, 10) == parseFloat(temp)) {
              temp = temp + '.0';
            }
            breakpoints[breakpoints.length] = temp;
            if (OIAS > 70 && j == 0) {
              temp =
                (Math.ceil(
                  (256 * 7) /
                    Math.floor(
                      (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                          175,
                        )) /
                        100,
                    ),
                ) *
                  4 +
                  Math.ceil(
                    (256 * 13) /
                      Math.floor(
                        (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * 256) / 100))) *
                          Math.min(
                            Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                            175,
                          )) /
                          100,
                      ),
                  ) -
                  1) /
                5;
              if (parseInt(temp, 10) == parseFloat(temp)) {
                temp = temp + '.0';
              }
              breakpoints2[breakpoints2.length] = temp;
            }
          }

          if (state.skill_selected != 26 && state.skill_selected != 29) {
            tempframe = 12;
            tempframe2 = 10;
            if (state.char_form_selected == 2) {
              tempframe = 13;
              tempframe2 = 9;
            }
            if (fertigkeiten[state.skill_selected][2] == 6) {
              tempframe = 10;
            }
            breakpoints[breakpoints.length] =
              Math.ceil(
                (256 * tempframe) /
                  Math.floor(
                    (Math.floor(
                      (256 * tempframe2) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * AnimSpeed) / 100)),
                    ) *
                      Math.min(
                        Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + 5 * j)) / (120 + (5 * i + 5 * j))), 15),
                        175,
                      )) /
                      100,
                  ),
              ) - 1;
            if (OIAS > 70 && j == 0) {
              breakpoints2[breakpoints2.length] =
                Math.ceil(
                  (256 * tempframe) /
                    Math.floor(
                      (Math.floor(
                        (256 * tempframe2) / Math.floor((256 * _frames) / Math.floor(((100 + 5 * i - WSMprimaer) * AnimSpeed) / 100)),
                      ) *
                        Math.min(
                          Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (5 * i + OIAS)) / (120 + (5 * i + OIAS))), 15),
                          175,
                        )) /
                        100,
                    ),
                ) - 1;
            }
          }
        }
      }
      for (let k = 0; k <= 14; k++) {
        if (WIAS / 5 != WIAS / 5 && state.skill_selected == 26) {
          breakpoints[breakpoints.length] =
            Math.ceil(
              (256 * 7) /
                Math.floor(
                  (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                    Math.min(Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 15), 175)) /
                    100,
                ),
            ) +
            Math.ceil(
              (256 * 13 -
                Math.floor(
                  (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                    Math.min(Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 15), 175)) /
                    100,
                ) *
                  Math.ceil(
                    (256 * 7) /
                      Math.floor(
                        (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                          Math.min(
                            Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 15),
                            175,
                          )) /
                          100,
                      ),
                  )) /
                (2 *
                  Math.floor(
                    (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                      Math.min(Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 15), 175)) /
                      100,
                  )),
            ) -
            1;
        }
        if (WIAS / 5 != WIAS / 5 && state.skill_selected == 29) {
          temp =
            (Math.ceil(
              (256 * 7) /
                Math.floor(
                  (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                    Math.min(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 175)) /
                    100,
                ),
            ) *
              4 +
              Math.ceil(
                (256 * 13) /
                  Math.floor(
                    (Math.floor((256 * 9) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * 256) / 100))) *
                      Math.min(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 175)) /
                      100,
                  ),
              ) -
              1) /
            5;
          if (parseInt(temp, 10) == parseFloat(temp)) {
            temp = temp + '.0';
          }
          breakpoints[breakpoints.length] = temp;
        }
        if (WIAS / 5 != WIAS / 5 && state.skill_selected != 26 && state.skill_selected != 29) {
          breakpoints[breakpoints.length] =
            Math.ceil(
              (256 * tempframe) /
                Math.floor(
                  (Math.floor(
                    (256 * tempframe2) / Math.floor((256 * _frames) / Math.floor(((100 + WIAS - WSMprimaer) * AnimSpeed) / 100)),
                  ) *
                    Math.min(Math.max(100 - WSMprimaer + SIAS + Math.floor((120 * (WIAS + 5 * k)) / (120 + (WIAS + 5 * k))), 15), 175)) /
                    100,
                ),
            ) - 1;
        }
      }
      let HTMLData = '';
      HTMLData +=
        '</table><p align="center">Your primary weapon&rsquo;s WIAS is plotted vertically, your equipment&rsquo;s IAS is plotted horizontally.</p>';
      HTMLData +=
        '<table style="border-collapse:collapse" align="center" border="1" cellpadding="0" cellspacing="0"><tr><td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="wertitle" width="60" align="center">---</td>';
      for (let i = 0; i <= 14; i++) {
        HTMLData +=
          '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="wertitle" width="40" align="center">' +
          5 * i +
          '</td>';
      }
      if (OIAS > 70) {
        HTMLData +=
          '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="wertitle" width="40" align="center">' +
          OIAS +
          '</td>';
      }
      HTMLData += '</tr><tr>';
      for (let j = 0; j <= 24; j++) {
        HTMLData +=
          '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="wertitle" align="center">' + 5 * j + '</td>';
        for (let i = 15 * j; i <= 15 * (j + 1) - 1; i++) {
          if (OIAS == (i - 15 * j) * 5 && WIAS == j * 5) {
            HTMLData +=
              '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="auswahl" align="center"><b>' +
              breakpoints[i] +
              '</b></td>';
          } else {
            if (OIAS == (i - 15 * j) * 5 || WIAS == j * 5) {
              HTMLData +=
                '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="iaswahl" align="center">' +
                breakpoints[i] +
                '</td>';
            } else {
              HTMLData +=
                '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" align="center">' + breakpoints[i] + '</td>';
            }
          }
        }
        if (OIAS > 70) {
          HTMLData +=
            '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="iaswahl" align="center">' +
            breakpoints2[j] +
            '</td>';
        }
        if (j < 24) {
          HTMLData += '</tr><tr>';
        }
        if (WIAS / 5 != WIAS / 5 && WIAS > j * 5 && WIAS < (j + 1) * 5) {
          HTMLData +=
            '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="wertitle" align="center">' + WIAS + '</td>';
          for (let k = 0; k <= 14; k++) {
            if (OIAS == k * 5) {
              HTMLData +=
                '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="auswahl" align="center"><b>' +
                breakpoints[breakpoints.length - 15 + k] +
                '</b></td>';
            } else {
              HTMLData +=
                '<td style="border-width:1px; border-style:solid; border-color:#FFFFFF" class="iaswahl" align="center">' +
                breakpoints[breakpoints.length - 15 + k] +
                '</td>';
            }
          }
          HTMLData += '</tr><tr>';
        }
      }
      showDialog({ open: true, data: HTMLData, aidel: undefined, type: 1 });
    }
  }
  cap = 1;
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, useIonAlert } from '@ionic/react';
import { DbContext } from '../../../common/providers/ProvidersWrapper';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { HolyGrail, HolyGraiLContext } from '../../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { MuleContext } from '../../../common/providers/MuleProvider/MuleProvider';
import { ItemContext } from '../../../common/providers/ItemProvider/ItemProvider';
import { RuneContext } from '../../../common/providers/RuneProvider/RuneProvider';
import styled from 'styled-components/macro';
import { Share } from '@capacitor/share';

interface BackUpData {
  [key: string]: any[][] | undefined;
}

interface RestoreResultState {
  grail: RestoreResult;
  mules: RestoreResult;
  items: RestoreResult;
  runes: RestoreResult;
}

interface RestoreResult {
  success: number;
  fail: number;
}

const initialRestoreResult = {
  grail: { success: 0, fail: 0 },
  mules: { success: 0, fail: 0 },
  items: { success: 0, fail: 0 },
  runes: { success: 0, fail: 0 },
};

export const BackUpRestore: React.FC = () => {
  const [fileReadError, setFileReadError] = useState(false);
  const [restoreFinished, setRestoreFinished] = useState(false);
  const [restoreResult, setRestoreResult] = useState<RestoreResultState>({ ...initialRestoreResult });
  const muleIdMap = useRef(new Map<number, number>());
  const { doBackUp } = useContext(DbContext);
  const { addGrailItems, resetGrailTable } = useContext(HolyGraiLContext);
  const { insertMule, resetMuleTable, mules: allMules, addMule } = useContext(MuleContext);
  const { addItem, resetItemsTable } = useContext(ItemContext);
  const { resetRunesTable, incRune } = useContext(RuneContext);
  const [ionAlert] = useIonAlert();
  const textarea = useRef<HTMLTextAreaElement>(null);

  // useEffect(() => {
  //   resetGrailTable();
  //   resetRunesTable();
  //   resetMuleTable();
  //   resetItemsTable();
  // }, []);

  const handleCreateBackupData = async () => {
    const data: BackUpData = {};

    try {
      const allData = await doBackUp();

      const tables = allData.export?.tables ?? [];
      tables.forEach(t => {
        data[t.name] = t.values;
      });

      void saveData(data);

      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };

  const saveData = async (data: BackUpData) => {
    const { uri } = await Filesystem.writeFile({
      path: `td2-backup.txt`,
      data: window.btoa(JSON.stringify(data)),
      directory: Directory.Cache,
      recursive: true,
    });

    // const a = document.createElement('a');
    // a.href = uri;
    // a.download = 'td2-backup.txt';
    // a.target = '_blank';
    // a.click();

    Share.share({
      title: 'Tome of D2 backup data',
      url: uri,
    });
  };

  const pickFile = async () => {
    try {
      const res = await FilePicker.pickFiles({
        types: ['text/plain'],
        multiple: false,
        readData: true,
      });

      await Filesystem.checkPermissions();
      const res2 = await Filesystem.readFile({ path: res.files[0]?.path ?? '' });
      const parsed = JSON.parse(window.atob(res2.data as string));
      console.log(parsed);
      void handleRestore(parsed);
    } catch (e) {
      console.error(e);
      setFileReadError(true);
      alert('An error occurred reading the file. Please copy the content of the file and paste into the input box.');
    }
  };

  const handleParseInput = (val: string) => {
    try {
      if (textarea.current) textarea.current.blur();
      const parsed = JSON.parse(val);
      void handleRestore(parsed);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRestore = async (data: BackUpData) => {
    const { grail, items, mules, runes } = data;

    if (grail && grail.length) {
      await presentAlert(data, 'grail');
    }

    if (mules && mules.length) {
      await presentAlert(data, 'items');
    }

    if (runes && runes.length) {
      await presentAlert(data, 'runes');
    }
    setRestoreFinished(true);
  };

  const presentAlert = async (data: BackUpData, type: 'grail' | 'items' | 'runes') => {
    const { grail, items, mules, runes } = data;

    let header = '';

    switch (type) {
      case 'grail':
        header = `${grail!.length} Holy Grail Items Found`;
        break;
      case 'items':
        header = `${mules?.length} Mules and ${items?.length} items found`;
        break;
      case 'runes':
        header = `${runes?.length} Runes Found`;
    }

    return new Promise<void>(resolve => {
      ionAlert({
        header,
        subHeader: SUB_HEADER,
        message: MESSAGE,
        buttons: BUTTONS,
        onDidDismiss: async ({ detail }) => {
          const action = detail.role as 'merge' | 'replace' | 'skip';

          if (action === 'skip') return resolve();

          switch (type) {
            case 'grail':
              await handleRestoreGrail(grail!, action);
              return resolve();
            case 'items':
              await handleRestoreMules(mules!, action);
              if (items?.length) {
                await handleRestoreItems(items, action);
              }
              return resolve();
            case 'runes':
              await handleRestoreRunes(runes!, action);
              return resolve();
          }
        },
        backdropDismiss: false,
      });
    });
  };

  const handleRestoreGrail = async (grail: any[][], action: 'merge' | 'replace') => {
    console.log('--- RESTORING GRAIL ---');
    if (action === 'replace') {
      await resetGrailTable();
    }

    const items: HolyGrail[] = [];
    grail.forEach(d => {
      try {
        items.push({ id: d[0], item_type: d[1], item_id: d[2] });
      } catch (e) {
        console.error(e);
      }
    });

    let success = false;
    try {
      await addGrailItems(items);
      success = true;
    } catch (e) {
      console.error(e);
    }

    setRestoreResult(prev => ({
      ...prev,
      grail: { success: success ? items.length : 0, fail: grail.length - items.length },
    }));
  };

  const handleRestoreMules = async (mules: any[][], action: 'merge' | 'replace') => {
    console.log('--- RESTORING MULES ---');

    if (action === 'replace') {
      await resetMuleTable();
    }

    let success = 0;

    for (const mule of mules) {
      try {
        const newId = await insertMule(mule[1]);
        if (newId !== undefined) {
          muleIdMap.current.set(mule[0], newId);
          success++;
        }
      } catch (e) {
        console.error(e);
        if (e === 'Duplicate name') {
          await handleDupeMuleName(mule[1], mule[0]);
          success++;
        }
      }
    }

    setRestoreResult(prev => ({ ...prev, mules: { success, fail: mules.length - success } }));
  };

  const handleDupeMuleName = async (oldMuleName: string, oldMuleId: number) => {
    return new Promise<void>((resolve, reject) => {
      ionAlert({
        header: `A mule with name ${oldMuleName} already exists`,
        message: "Do you want to rename this mule or merge this mule's items with the existing mule?",
        buttons: [
          { text: 'Rename', role: 'rename' },
          { text: 'Merge', role: 'merge' },
        ],
        onDidDismiss: async ({ detail }) => {
          const action = detail.role as 'merge' | 'rename';

          let newId: number | undefined;

          if (action === 'merge') {
            const existingMule = allMules.find(m => m.mule_name === oldMuleName);
            newId = existingMule?.mule_id;
          } else if (action === 'rename') {
            newId = await addMule(false);
          }

          if (newId !== undefined) {
            muleIdMap.current.set(oldMuleId, newId);
            resolve();
          } else {
            reject();
          }
        },
      });
    });
  };

  const handleRestoreItems = async (items: any[][], action: 'merge' | 'replace') => {
    console.log('--- RESTORING ITEMS ---');

    if (action === 'replace') {
      await resetItemsTable();
    }

    let success = 0;

    for (const item of items) {
      try {
        const muleId = muleIdMap.current.get(item[1]);

        if (muleId !== undefined) {
          await addItem({
            id: -1,
            mule_id: muleId,
            item_type: item[2],
            item_id: item.at(3) ?? null,
            name: item.at(4) ?? null,
            base_id: item.at(5) ?? null,
            base_name: item.at(6) ?? null,
            ethereal: item.at(7) === 1,
            sockets: item.at(8) ?? null,
            props: item.at(9) ?? null,
            mule_name: '',
          });
        }

        success++;
      } catch (e) {
        console.error(e);
      }
    }

    setRestoreResult(prev => ({ ...prev, items: { success, fail: items.length - success } }));
  };

  const handleRestoreRunes = async (runes: any[][], action: 'merge' | 'replace') => {
    console.log('--- RESTORING RUNES ---');

    if (action === 'replace') {
      await resetRunesTable();
    }

    let success = 0;
    for (const data of runes) {
      try {
        const runeId = data[1];
        const count = data[2];

        for (let i = 0; i < count; i++) {
          await incRune(runeId);
          success++;
        }
      } catch (e) {
        console.error(e);
      }
    }

    setRestoreResult(prev => ({ ...prev, runes: { success, fail: runes.length - success } }));
  };

  useEffect(() => {
    if (restoreFinished) {
      ionAlert({
        header: 'Restore finished!',
        message: `<div class="restore-result">
<h3>Holy Grail</h3>
<div><span>${restoreResult.grail.success} restored. </span> <span class="warning">${
          restoreResult.grail.fail ? `${restoreResult.grail.fail} failed.` : ''
        }</span></div>
<h3>Mules</h3>
<div><span>${restoreResult.mules.success} restored. </span> <span class="warning">${
          restoreResult.mules.fail ? `${restoreResult.mules.fail} failed.` : ''
        }</span></div>
<h3>Items</h3>
<div><span>${restoreResult.items.success}  restored. </span> <span class="warning">${
          restoreResult.items.fail ? `${restoreResult.items.fail} failed.` : ''
        }</span></div>
<h3>Runes</h3>
<div><span>${restoreResult.runes.success} restored. </span> <span class="warning">${
          restoreResult.runes.fail ? `${restoreResult.runes.fail} failed.` : ''
        }</span></div>
</div>`,
        buttons: ['Ok'],
        onDidDismiss: () => {
          setRestoreFinished(false);
          setRestoreResult({ ...initialRestoreResult });
        },
      });
    }
  }, [restoreFinished, restoreResult]);

  return (
    <Container>
      <IonButton onClick={handleCreateBackupData} expand={'block'}>
        Back up
      </IonButton>
      {!fileReadError && (
        <IonButton onClick={pickFile} expand={'block'}>
          Restore
        </IonButton>
      )}
      {fileReadError && <Textarea ref={textarea} placeholder={'Paste backup data here'} onChange={e => handleParseInput(e.target.value)} />}
    </Container>
  );
};

const SUB_HEADER = 'Would you like to replace or merge your existing data with these items?';
const MESSAGE = `WARNING: If you select 'Replace' your existing data will be deleted and replaced with your backup data.`;

const BUTTONS = [
  { text: 'Merge', role: 'merge' },
  { text: 'Replace', role: 'replace' },
  { text: 'Skip', role: 'skip' },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  min-height: 400px;
  border-radius: 4px;
  outline: none;
`;

import React, { useRef } from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import styled from 'styled-components/macro';

interface Props {
  children?: React.ReactNode;
  title?: string | JSX.Element;
  buttons?: React.ReactNode;
  padding?: boolean;
  style?: React.CSSProperties;
  noHeader?: boolean;
  navBar?: boolean;
  contentClass?: string;
  headerContent?: JSX.Element;
  onScroll?: () => void;
  buttonsRight?: boolean;
}

export const AppPage: React.FC<Props> = ({
  children,
  title,
  buttons,
  padding,
  style,
  noHeader,
  navBar,
  contentClass,
  headerContent,
  onScroll,
  buttonsRight,
}) => {
  const wrapper = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   Keyboard.setResizeMode({ mode: KeyboardResize.None });
  //   Keyboard.addListener('keyboardWillShow', () => setKeyboardOpen(true));
  //   Keyboard.addListener('keyboardWillHide', () => setKeyboardOpen(false));
  //   if (wrapper.current && onScroll) {
  //     wrapper.current.addEventListener('scroll', onScroll);
  //   }
  //   return () => {
  //     if (wrapper.current && onScroll) {
  //       wrapper.current.removeEventListener('scroll', onScroll);
  //     }
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   console.log('keyboard', keyboardOpen);
  // }, [keyboardOpen]);

  return (
    <IonPage>
      {noHeader !== true && (
        <IonHeader>
          <IonToolbar color={'dark'}>
            <IonButtons slot={'start'} style={{ gap: '9px', marginLeft: '16px' }}>
              {buttonsRight ? <IonBackButton /> : buttons}
            </IonButtons>
            <IonButtons slot={'end'} style={{ gap: '9px', marginRight: '16px' }}>
              {buttonsRight ? buttons : <IonBackButton />}
            </IonButtons>
            {title && <IonTitle>{title}</IonTitle>}
          </IonToolbar>
          {headerContent}
        </IonHeader>
      )}
      <IonContent className={contentClass} forceOverscroll={false}>
        <Wrapper
          ref={wrapper}
          navBar={!navBar}
          padding={!!padding}
          style={style}
          className={noHeader ? 'ios-safe-area' : ''}
          onScroll={onScroll}
        >
          {children}
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

const Wrapper = styled('div')<{ padding: boolean; navBar: boolean }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${props => (props.navBar ? (props.padding ? '16px 16px 94px' : '0 0 78px') : props.padding ? '16px' : 0)};
  background-color: #222222;
`;

import { MiscItem } from '../../types';

export const MISC_ITEMS: MiscItem[] = [
  { name: 'Small Charm', filename: 'small_charm.gif', type: 'charm', id: 1000, item_type: 'misc' },
  { name: 'Large Charm', filename: 'large_charm.gif', type: 'charm', id: 1001, item_type: 'misc' },
  { name: 'Grand Charm', filename: 'grand_charm.gif', type: 'charm', id: 1002, item_type: 'misc' },
  { name: 'Jewel', filename: 'jewel.png', type: 'Jewel', id: 1003, item_type: 'misc' },
  { name: 'Ring', filename: 'ring.gif', type: 'jewelery', id: 1004, item_type: 'misc' },
  { name: 'Amulet', filename: 'amulet.gif', type: 'jewelery', id: 1005, item_type: 'misc' },
  { name: 'Key of Terror', filename: 'key.png', type: 'misc', id: 1006, item_type: 'misc' },
  { name: 'Key of Hate', filename: 'key.png', type: 'misc', id: 1007, item_type: 'misc' },
  { name: 'Key of Destruction', filename: 'key.png', type: 'misc', id: 1008, item_type: 'misc' },
  { name: 'Token of Absolution', filename: 'token.png', type: 'misc', id: 1009, item_type: 'misc' },
  { name: "Baal's Eye", filename: 'baal_eye.png', type: 'misc', id: 1010, item_type: 'misc' },
  { name: "Diablo's Horn", filename: 'diablo_horn.png', type: 'misc', id: 1011, item_type: 'misc' },
  { name: "Mephisto's Brain", filename: 'mephisto_brain.png', type: 'misc', id: 1012, item_type: 'misc' },
  { name: 'Burning Essence of Terror', filename: 'burning_essence.png', type: 'misc', id: 1013, item_type: 'misc' },
  {
    name: 'Festering Essence of Destruction',
    filename: 'festering_essence.png',
    type: 'misc',
    id: 1014,
    item_type: 'misc',
  },
  { name: 'Charged Essence of Hatred', filename: 'charged_essence.png', type: 'misc', id: 1015, item_type: 'misc' },
  { name: 'Twisted Essence of Suffering', filename: 'twisted_essence.png', type: 'misc', id: 1016, item_type: 'misc' },
  { name: 'Scroll of Town Portal', filename: 'scroll_blue.png', type: 'misc', id: 1017, item_type: 'misc' },
  { name: 'Rejuvenation Potion', filename: 'rejuv.png', type: 'misc', id: 1018, item_type: 'misc' },
];

import React from 'react';
import { AppPage } from '../../common/components/AppPage/AppPage';
import { Rune } from '../../common/components/Rune';
import styled from 'styled-components/macro';
import { ALL_RUNES } from '../../common/data/runes/all_runes';
import { GemImage } from '../../common/components/GemImage';
import { IonIcon, useIonRouter } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';
import { useAppSelector } from '../../store/store';
import { selectorCraftingSettings } from '../../store/settings/selectors';

export const CubeRuneCombining: React.FC = () => {
  const router = useIonRouter();
  return (
    <AppPage
      padding={true}
      title={'Rune Combining'}
      buttons={
        <IonIcon icon={settingsOutline} style={{ fontSize: '22px' }} onClick={() => router.push(appRoutes.settings.path('crafting'))} />
      }
    >
      <RuneCombining />
    </AppPage>
  );
};

export const RuneCombining: React.FC = () => {
  const settings = useAppSelector(selectorCraftingSettings);
  return (
    <Container>
      {ALL_RUNES.map(
        (r, i) =>
          r.id < 9 && (
            <Row cols={5} key={`r-${r.id}`}>
              <Rune
                id={r.id}
                size={'35px'}
                forceInStock={!settings.showRunesInStock}
                withCount={settings.showRuneCount}
                withName={settings.showRuneNames}
              />
              <Rune
                id={r.id}
                size={'35px'}
                forceInStock={!settings.showRunesInStock}
                withCount={settings.showRuneCount}
                withName={settings.showRuneNames}
              />
              <Rune
                id={r.id}
                size={'35px'}
                forceInStock={!settings.showRunesInStock}
                withCount={settings.showRuneCount}
                withName={settings.showRuneNames}
              />
              <span>=</span>
              <Rune
                id={r.id + 1}
                size={'35px'}
                forceInStock={!settings.showRunesInStock}
                withCount={settings.showRuneCount}
                withName={settings.showRuneNames}
              />
            </Row>
          ),
      )}
      <Row cols={7}>
        <Rune
          id={9}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={9}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={9}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'topaz'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={10}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={10}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={10}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={10}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'amethyst'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={11}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={11}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={11}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={11}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'sapphire'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={12}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={12}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={12}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={12}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'ruby'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={13}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={13}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={13}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={13}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'emerald'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={14}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={14}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={14}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={14}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'chipped'} type={'diamond'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={15}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={15}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={15}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={15}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'topaz'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={16}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={16}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={16}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={16}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'amethyst'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={17}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={17}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={17}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={17}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'sapphire'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={18}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={18}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={18}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={18}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'ruby'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={19}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={7}>
        <Rune
          id={19}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={19}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={19}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'emerald'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={20}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={20}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={20}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawed'} type={'diamond'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={21}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={21}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={21}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'topaz'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={22}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={22}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={22}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'amethyst'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={23}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={23}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={23}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'sapphire'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={24}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={24}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={24}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'ruby'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={25}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={25}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={25}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'emerald'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={26}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={26}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={26}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'normal'} type={'diamond'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={27}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={27}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={27}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawless'} type={'topaz'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={28}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={28}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={28}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawless'} type={'amethyst'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={29}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={29}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={29}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawless'} type={'sapphire'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={30}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={30}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={30}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawless'} type={'ruby'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={31}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>

      <Row cols={5}>
        <Rune
          id={31}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
        <Rune
          id={31}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />

        <GemImage quality={'flawless'} type={'emerald'} size={'35px'} withName={settings.showRuneNames} />
        <span>=</span>
        <Rune
          id={32}
          size={'35px'}
          forceInStock={!settings.showRunesInStock}
          withCount={settings.showRuneCount}
          withName={settings.showRuneNames}
        />
      </Row>
    </Container>
  );
};

const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div<{ cols: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 10%);
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 16px;
  color: white;
`;

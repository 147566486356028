import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../store/store';
import { selectorIsD2r } from '../../store/settings/selectors';
import styled from 'styled-components/macro';
import { randomInteger } from '../utils';

export const allGemTypes = ['topaz', 'sapphire', 'emerald', 'ruby', 'diamond', 'amethyst', 'skull', 'all'] as const;
export type GemType = (typeof allGemTypes)[number];
export const allGemQualities = ['chipped', 'flawed', 'normal', 'flawless', 'perfect'] as const;
export type GemQuality = (typeof allGemQualities)[number];

interface Props {
  quality: GemQuality;
  type: GemType;
  size: string;
  style?: React.CSSProperties;
  withName?: boolean;
}

export const GemImage: React.FC<Props> = ({ quality, type, size, style, withName }) => {
  const [src, setSrc] = useState('');
  const timeout = useRef<NodeJS.Timeout>();
  const prevAllType = useRef<GemType>('all');

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, [quality, type]);

  useEffect(() => {
    if (type === 'all') {
      timeout.current = setInterval(() => setSrc(getSrc(allGemTypes[randomInteger(0, allGemTypes.length - 2)])), 800);
    } else {
      setSrc(getSrc(type));
    }
  }, [quality, type]);

  // function getNextType() {
  //   if (prevAllType.current === 'all') {
  //     return allGemTypes[0];
  //   }
  //   for (let i = 0; i < allGemTypes.length; i++) {
  //     if (allGemTypes[i] === prevAllType.current) {
  //       if (allGemTypes[i + 1] === 'all') return allGemTypes[0];
  //       return allGemTypes[i + 1];
  //     }
  //   }
  //   return allGemTypes[0];
  // }

  const getSrc = (t: GemType) => {
    let fileName = `${quality}_${t}`;
    fileName += isD2r ? '.png' : '.gif';

    return `/images/item-images/${isD2r ? 'd2r/' : ''}gems/${fileName}`;
  };

  const isD2r = useAppSelector(selectorIsD2r);

  return (
    <Container>
      <Img src={src} size={size} inStock={true} style={style} />
      {withName && type !== 'all' && (
        <div style={{ color: `var(--${type})` }}>
          {quality} {type}
        </div>
      )}
      {withName && type === 'all' && <div style={{ color: `var(--${type})` }}>Any {quality}</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > div {
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
  }
`;

const Img = styled.div<{ src: string; size: string; inStock: boolean }>`
  width: ${props => props.size};
  height: ${props => props.size};
  filter: ${props => (props.inStock ? 'none' : 'grayscale(100%) brightness(60%)')};

  &:before {
    content: '';
    width: ${props => props.size};
    height: ${props => props.size};
    background-image: url('${props => props.src}');
    background-size: cover;
    display: inline-block;
  }
`;

/* tslint:disable*/

import {SetBonus, SetItem} from '../../types';

export const SETS_24: SetItem[] = [
  {
    name: 'Angelic Mantle',
    type: 'Ring Mail',
    image: 'http://www.d2tomb.com/images/setitems/angelic_mantle.gif',
    props: [
      'Defense:',
      '68-218',
      'Durability:',
      '26',
      'Req Strength:',
      '36',
      'Req Level:',
      '12',
      '40% Enhanced Defense',
      'Damage Reduced by 3',
      '+150 Defense (2 set items)',
      '+50% Fire Resist (3 set items)',
    ],
    category: 'Angelic Raiment',
    id: 384,
    filename: 'angelic_mantle.gif',
    set_id: 0,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: 'Angelic Sickle',
    type: 'Sabre',
    image: 'http://www.d2tomb.com/images/setitems/angelic_sickle.gif',
    props: [
      'One Hand Damage:',
      '3-14',
      'Durability:',
      '32',
      'Req Dexterity:',
      '25',
      'Req Strength:',
      '25',
      'Req Level:',
      '12',
      '250% Damage to Undead',
      '+75 to Attack Rating',
      '+75% Enhanced Damage (2 set items)',
      '30% increased attack speed (3 set items)',
    ],
    category: 'Angelic Raiment',
    id: 385,
    filename: 'angelic_sickle.gif',
    set_id: 0,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: 'Angelic Halo',
    type: 'Ring',
    image: 'http://www.d2tomb.com/images/setitems/ring2.gif',
    props: [
      'Req Level:',
      '12',
      'Replenish Life +6',
      '+20 to Life',
      '+ 12 per character level to Attack Rating (2 set items)',
      '+50% better chance of getting magic items (3 set items)',
    ],
    category: 'Angelic Raiment',
    id: 386,
    filename: 'ring2.gif',
    set_id: 0,
    category_id: 7,
    item_type: 'set',
  },
  {
    name: 'Angelic Wings',
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu2.gif',
    props: [
      'Req Level:',
      '12',
      '20% Damage taken goes to Mana',
      '+3 to Light Radius',
      '+75 to Life (2 set items)',
      '+1 to all Skill levels (3 set items)',
    ],
    category: 'Angelic Raiment',
    id: 387,
    filename: 'amulet.gif',
    set_id: 0,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Arcanna's Head",
    type: 'Skull Cap',
    image: 'http://www.d2tomb.com/images/setitems/arcannas_head.gif',
    props: [
      'Defense:',
      '8-308',
      'Req Level:',
      '15',
      'Durability:',
      '18',
      'Req Strength:',
      '15',
      '+4 Replenish Life',
      'Attacker takes damage of 2',
      '+3 per character level to Defense (2 set items)',
      '+15% resist Lightning (3 set items)',
    ],
    category: "Arcanna's Tricks",
    id: 388,
    filename: 'arcannas_head.gif',
    set_id: 1,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Arcanna's Flesh",
    type: 'Light Plate',
    image: 'http://www.d2tomb.com/images/setitems/arcannas_flesh.gif',
    props: [
      'Defense:',
      '90-207',
      'Durability:',
      '60',
      'Req Level:',
      '15',
      'Req Strength:',
      '41',
      '+2 to Light Radius',
      'Damage reduced by 3',
      '+100 to Defense (2 set items)',
      '+10 to Energy (3 set items)',
    ],
    category: "Arcanna's Tricks",
    id: 389,
    filename: 'arcannas_flesh.gif',
    set_id: 1,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Arcanna's Deathwand",
    type: 'War Staff',
    image: 'http://www.d2tomb.com/images/setitems/arcannas_deathwand.gif',
    props: [
      'Two-Hand Damage:',
      '12-28',
      'Durability:',
      '50',
      'Req Level:',
      '15',
      '+1 to all Sorceress Skills',
      '25% Deadly Strike',
      '+50% Damage to Undead',
      '+50 to Mana (2 set items)',
      '+5% regenerate Mana (3 set items)',
    ],
    category: "Arcanna's Tricks",
    id: 390,
    filename: 'arcannas_deathwand.gif',
    set_id: 1,
    category_id: 27,
    item_type: 'set',
  },
  {
    name: "Arcanna's Sign",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu3.gif',
    props: [
      '20% regenerate Mana',
      'Req Level:',
      '15',
      '+15 to Mana',
      '+50% better chance to find magic items (2 set items)',
      '+20% Fire Resist (3 set items)',
    ],
    category: "Arcanna's Tricks",
    id: 391,
    filename: 'amu3.gif',
    set_id: 1,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: 'Arctic Furs',
    type: 'Quilted Armor',
    image: 'http://www.d2tomb.com/images/setitems/arctic_furs.gif',
    props: [
      'Defense:',
      '45-348',
      'Durability:',
      '20',
      'Req Strength:',
      '12',
      'Req Level:',
      '2',
      '+275-325% Enhanced Defense',
      '+10 to all Resistances 10%',
      '+3 per character level to Defense (2 set items)',
      '+15% Cold Resist (3 set items)',
    ],
    category: 'Arctic Gear',
    id: 392,
    filename: 'arctic_furs.gif',
    set_id: 2,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: 'Arctic Binding',
    type: 'Light Belt',
    image: 'http://www.d2tomb.com/images/setitems/arctic_binding.gif',
    props: [
      'Defense:',
      '33',
      'Durability:',
      '14',
      'Req Level:',
      '2',
      '+40% Cold Resist',
      '+30 Defense',
      '+40% better chance of getting magic items (2 set items)',
      '+10% Cold Resist (3 set items)',
    ],
    category: 'Arctic Gear',
    id: 393,
    filename: 'arctic_binding.gif',
    set_id: 2,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: 'Arctic Mitts',
    type: 'Light Gauntlets',
    image: 'http://www.d2tomb.com/images/setitems/arctic_mitts.gif',
    props: [
      'Defense:',
      '9-11',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '2',
      '+20 to Life',
      '10% increased Attack Speed',
      '+50 to Attack Rating (2 set items)',
      '+10 to Dexterity (3 set items)',
    ],
    category: 'Arctic Gear',
    id: 394,
    filename: 'arctic_mitts.gif',
    set_id: 2,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: 'Arctic Horn',
    type: 'Short War Bow',
    image: 'http://www.d2tomb.com/images/setitems/arctic_horn.gif',
    props: [
      'Two-Hand Damage:',
      '9-21',
      'Req Dexterity:',
      '55',
      'Req Strength:',
      '35',
      'Req Level:',
      '2',
      '20% Bonus to Attack Rating',
      '+50% Enhanced Damage',
      '+8 per character level to Attack Rating (2 set items)',
      '+20-30 Cold Damage (3 set items)',
    ],
    category: 'Arctic Gear',
    id: 395,
    filename: 'arctic_horn.gif',
    set_id: 2,
    category_id: 19,
    item_type: 'set',
  },
  {
    name: "Berserker's Headgear",
    type: 'Helm',
    image: 'http://www.d2tomb.com/images/setitems/berserkers_headgear.gif',
    props: [
      'Defense:',
      '30-33',
      'Durability:',
      '24',
      'Req Strength:',
      '26',
      'Req Level:',
      '3',
      '25% Fire Resist',
      '+15 Defense',
      '+8 per character level to Attack Rating (2 set items)',
    ],
    category: "Berserker's Arsenal",
    id: 396,
    filename: 'berserkers_headgear.gif',
    set_id: 3,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Berserker's Hauberk",
    type: 'Splint Mail',
    image: 'http://www.d2tomb.com/images/setitems/berserkers_hauberk.gif',
    props: [
      'Defense:',
      '90-392',
      'Durability:',
      '30',
      'Req Strength:',
      '51',
      'Req Level:',
      '3',
      '+1 to all Barbarian Skills',
      'Magic Damage Reduced by 2',
      '+3 per character level to Defense (2 set items)',
    ],
    category: "Berserker's Arsenal",
    id: 397,
    filename: 'berserkers_hauberk.gif',
    set_id: 3,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Berserker's Hatchet",
    type: 'Double Axe',
    image: 'http://www.d2tomb.com/images/setitems/berserkers_hatchet.gif',
    props: [
      'One-Hand Damage:',
      '5-13',
      'Durability:',
      '24',
      'Req Strength:',
      '43',
      'Req Level:',
      '3',
      '30% Bonus to Attack Rating',
      '5% Mana stolen per hit',
      '+50% Enhanced Defense (2 set items)',
    ],
    category: "Berserker's Arsenal",
    id: 398,
    filename: 'berserkers_hatchet.gif',
    set_id: 3,
    category_id: 18,
    item_type: 'set',
  },
  {
    name: "Cathan's Visage",
    type: 'Mask',
    image: 'http://www.d2tomb.com/images/setitems/cathans_visage.gif',
    props: [
      'Defense:',
      '9-225',
      'Durability:',
      '20',
      'Req Strength:',
      '23',
      'Req Level:',
      '11',
      '+25% Cold Resist',
      '+20 to Mana',
      '+2 per character level to Defense (2 set items)',
    ],
    category: "Cathan's Traps",
    id: 399,
    filename: 'cathans_visage.gif',
    set_id: 4,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Cathan's Mesh",
    type: 'Chain Mail',
    image: 'http://www.d2tomb.com/images/setitems/cathans_mesh.gif',
    props: [
      'Defense:',
      '87-90',
      'Durability:',
      '45',
      'Req Strength:',
      '24',
      'Req Level:',
      '11',
      '-50% to Requirements',
      '+15 Defense',
      'Attacker takes damage of 5 (2 set items)',
      '+30% Resist Fire (3 set items)',
    ],
    category: "Cathan's Traps",
    id: 400,
    filename: 'cathans_mesh.gif',
    set_id: 4,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Cathan's Rule",
    type: 'Battle Staff',
    image: 'http://www.d2tomb.com/images/setitems/cathans_rule.gif',
    props: [
      'Two-Hand Damage:',
      '6-13',
      'Durability:',
      '40',
      'Req Level:',
      '11',
      '+1 to Fire Skills',
      '+10 maximum Fire Damage',
      '+50% Damage to Undead',
      '+50 to Mana (2 set items)',
      '+10 to all Resistances (3 set items)',
    ],
    category: "Cathan's Traps",
    id: 401,
    filename: 'cathans_rule.gif',
    set_id: 4,
    category_id: 27,
    item_type: 'set',
  },
  {
    name: "Cathan's Sigil",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu1.gif',
    props: [
      'Attack takes 5 Lightning Damage',
      'Req Level:',
      '11',
      '10% Faster Hit Recovery',
      '+50 to Attack Rating (2 set items)',
      '+25% better chance of finding magic items (3 set items)',
    ],
    category: "Cathan's Traps",
    id: 402,
    filename: 'amu1.gif',
    set_id: 4,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Cathan's Seal",
    type: 'Ring',
    image: 'http://www.d2tomb.com/images/setitems/ring4.gif',
    props: ['6% Life stolen per hit', 'Req Level:', '11', 'Damage reduced by 2', '+10 to Strength (2 set items)'],
    category: "Cathan's Traps",
    id: 403,
    filename: 'ring4.gif',
    set_id: 4,
    category_id: 7,
    item_type: 'set',
  },
  {
    name: "Civerb's Cudgel",
    type: 'Grand Scepter',
    image: 'http://www.d2tomb.com/images/setitems/civerbs_cudgel.gif',
    props: [
      'One-Hand Damage:8-140',
      'Durability:',
      '60',
      'Req Strength:',
      '37',
      'Req Level:',
      '9',
      '+75 to Attack Rating',
      '+17-23 to max Damage',
      '+50% Damage to Undead',
      '+1 per character level to maximum Damage',
    ],
    category: "Civerb's Vestments",
    id: 404,
    filename: 'civerbs_cudgel.gif',
    set_id: 5,
    category_id: 25,
    item_type: 'set',
  },
  {
    name: "Civerb's Icon",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu1.gif',
    props: ['Replenish Life +4', 'Req Level:', '9', 'Regenerate Mana 40%', '+25% Cold Resist (2 set items)', '+25 Defense (complete set)'],
    category: "Civerb's Vestments",
    id: 405,
    filename: 'amu1.gif',
    set_id: 5,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Civerb's Ward",
    type: 'Large Shield',
    image: 'http://www.d2tomb.com/images/setitems/civerbs_ward.gif',
    props: [
      'Defense:',
      '27-29',
      '52% Chance to block',
      'Durability:',
      '24',
      'Req Strength:',
      '34',
      'Req Level:',
      '9',
      '+15 Defense',
      '15% increased block',
      "+21-22 Mana (when worn with Civerb's Icon)",
      "+25-26% Poison Resist (when worn with Civerb's Cudgel)",
    ],
    category: "Civerb's Vestments",
    id: 406,
    filename: 'civerbs_ward.gif',
    set_id: 5,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Cleglaw's Tooth",
    type: 'Long Sword',
    image: 'http://www.d2tomb.com/images/setitems/cleglaws_tooth.gif',
    props: [
      'One-Hand Damage:',
      '3-142',
      'Durability:',
      '44',
      'Req Strength:',
      '55',
      'Req Dexterity:',
      '39',
      'Req Level:',
      '4',
      '50% Deadly Strike',
      '30% Bonus to Attack rating',
      '+1.25 per character level to maximum Damage (2 set items)',
    ],
    category: "Cleglaw's Brace",
    id: 407,
    filename: 'cleglaws_tooth.gif',
    set_id: 6,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Cleglaw's Pincers",
    type: 'Chain Gloves',
    image: 'http://www.d2tomb.com/images/setitems/cleglaws_pincers.gif',
    props: [
      'Defense:',
      '8-9',
      'Durability:',
      '16',
      'Req Strength:',
      '25',
      'Req Level:',
      '4',
      'Slows Target by 25%',
      'Knockback',
      '+10 per character level to Attack Rating (2 set items)',
    ],
    category: "Cleglaw's Brace",
    id: 408,
    filename: 'cleglaws_pincers.gif',
    set_id: 6,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Cleglaw's Claw",
    type: 'Small Shield',
    image: 'http://www.d2tomb.com/images/setitems/cleglaws_claw.gif',
    props: [
      'Defense:',
      '25-27',
      '30% Chance to Block',
      'Durability:',
      '16',
      'Req Strength:',
      '22',
      'Req Level:',
      '4',
      'Poison Length reduced by 75%',
      '+17 Defense',
      '+15 to all Resistances (2 set items)',
    ],
    category: "Cleglaw's Brace",
    id: 409,
    filename: 'cleglaws_claw.gif',
    set_id: 6,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Death's Touch",
    type: 'War Sword',
    image: 'http://www.d2tomb.com/images/setitems/deaths_touch.gif',
    props: [
      'One-Hand Damage:',
      '10-25',
      'Durability:',
      '44',
      'Req Strength:',
      '71',
      'Req Dexterity:',
      '45',
      'Req Level:',
      '6',
      '4% Life stolen per hit',
      '+25% Enhanced Damage',
      'Adds 25-75 Cold Damage (2 set items)',
    ],
    category: "Death's Disguise",
    id: 410,
    filename: 'deaths_touch.gif',
    set_id: 7,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Death's Hand",
    type: 'Leather Gloves',
    image: 'http://www.d2tomb.com/images/setitems/deaths_hand.gif',
    props: [
      'Defense:',
      '2-3',
      'Durability:',
      '12',
      'Req Level:',
      '6',
      'Poison Length reduced by 75%',
      '50% Poison Resist',
      '30% increased attack speed (2 set items)',
    ],
    category: "Death's Disguise",
    id: 411,
    filename: 'deaths_hand.gif',
    set_id: 7,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Death's Guard",
    type: 'Sash',
    image: 'http://www.d2tomb.com/images/setitems/deaths_guard.gif',
    props: [
      'Defense:',
      '22',
      'Durability:',
      '12',
      'Req Level:',
      '6',
      'Cannot be frozen',
      '+20 Defense',
      '+15 to all Resistances (2 set items)',
    ],
    category: "Death's Disguise",
    id: 412,
    filename: 'deaths_guard.gif',
    set_id: 7,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Hsarus' Iron Fist",
    type: 'Buckler',
    image: 'http://www.d2tomb.com/images/setitems/hsarus_ironfist.gif',
    props: [
      'Defense:',
      '4-253',
      '25% chance to Block',
      'Durability:',
      '12',
      'Req Strength:',
      '12',
      'Req Level:',
      '3',
      'Damage reduced by 2',
      '+10 to Strength',
      '+2.5 per character level to Defense (2 set items)',
    ],
    category: "Hsaru's Defense",
    id: 413,
    filename: 'hsarus_ironfist.gif',
    set_id: 8,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Hsarus' Iron Stay",
    type: 'Belt',
    image: 'http://www.d2tomb.com/images/setitems/hsarus_ironstay.gif',
    props: [
      'Defense:',
      '5-252',
      'Durability:',
      '16',
      'Req Strength:',
      '25',
      'Req Level:',
      '3',
      '+20% Cold Resist',
      '+20 to Life',
      '+2.5 per character level to Defense (2 set items)',
    ],
    category: "Hsaru's Defense",
    id: 414,
    filename: 'hsarus_ironstay.gif',
    set_id: 8,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Hsarus' Iron Heel",
    type: 'Chain Boots',
    image: 'http://www.d2tomb.com/images/setitems/hsarus_ironheel.gif',
    props: [
      'Defense:',
      '8-9',
      'Assassin Kick Damage:',
      '6-12',
      'Durability:',
      '16',
      'Req Strength:',
      '30',
      'Req Level:',
      '3',
      '20% Faster Run/Walk',
      '+25% Fire Resist',
      '+10 per character level to Attack Rating (2 set items)',
    ],
    category: "Hsaru's Defense",
    id: 415,
    filename: 'hsarus_ironheel.gif',
    set_id: 8,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: 'Infernal Cranium',
    type: 'Cap',
    image: 'http://www.d2tomb.com/images/setitems/infernal_cranium.gif',
    props: [
      'Defense:',
      '3-203',
      'Durability:',
      '12',
      'Req Level:',
      '5',
      '20% Damage taken goes to Mana',
      '+10 to all Resistances',
      '+2 per character level to Defense (2 set items)',
    ],
    category: 'Infernal Tools',
    id: 416,
    filename: 'infernal_cranium.gif',
    set_id: 9,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: 'Infernal Sign',
    type: 'Heavy Belt',
    image: 'http://www.d2tomb.com/images/setitems/infernal_buckle.gif',
    props: [
      'Defense:',
      '31',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '5',
      '+25 Defense',
      '+20 to Life',
      '+25% Poison Resist (2 set items)',
      'Half Freeze Duration (3 set items)',
    ],
    category: 'Infernal Tools',
    id: 417,
    filename: 'infernal_buckle.gif',
    set_id: 9,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: 'Infernal Torch',
    type: 'Grim Ward',
    image: 'http://www.d2tomb.com/images/setitems/infernal_torch.gif',
    props: [
      'One-Hand Damage:',
      '13-14',
      'Durability:',
      '15',
      'Req Level:',
      '5',
      '+1 to Necromancer Skill Levels',
      '+8 to minimum Damage',
      '150% Damage to Undead',
      '+10 per character level to Attack Rating (2 set items)',
    ],
    category: 'Infernal Tools',
    id: 418,
    filename: 'infernal_torch.gif',
    set_id: 9,
    category_id: 30,
    item_type: 'set',
  },
  {
    name: "Iratha's Coil",
    type: 'Crown',
    image: 'http://www.d2tomb.com/images/setitems/irathas_coil.gif',
    props: [
      'Defense:',
      '25-243',
      'Durability:',
      '50',
      'Req Strength:',
      '55',
      'Req Level:',
      '15',
      '+30% Lightning Resist',
      '+30% Fire Resist',
      '+2 per character level to Defense (2 set items)',
    ],
    category: "Iratha's Finery",
    id: 419,
    filename: 'irathas_coil.gif',
    set_id: 10,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Iratha's Collar",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu1.gif',
    props: ['Req Level:', '15', 'Poison Length reduced by 75%', '+30% Poison Resist', '+15 to all Resistances (2 set items)'],
    category: "Iratha's Finery",
    id: 420,
    filename: 'amu1.gif',
    set_id: 10,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Iratha's Cord",
    type: 'Heavy Belt',
    image: 'http://www.d2tomb.com/images/setitems/irathas_cord.gif',
    props: [
      'Defense:',
      '31',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '15',
      '+25 Defense',
      '+5 to Minimum Damage',
      '+10 to Dexterity (2 set items)',
    ],
    category: "Iratha's Finery",
    id: 421,
    filename: 'irathas_cord.gif',
    set_id: 10,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Iratha's Cuff",
    type: 'Light Gauntlets',
    image: 'http://www.d2tomb.com/images/setitems/irathas_cuff.gif',
    props: [
      'Defense:',
      '9-11',
      'Durability:',
      '18',
      'Req Strength:',
      '45',
      'Req Level:',
      '15',
      'Half Freeze Duration',
      '+30% Cold Resist',
      '20% Increased Attack Speed (2 set items)',
    ],
    category: "Iratha's Finery",
    id: 422,
    filename: 'irathas_cuff.gif',
    set_id: 10,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Isenhart's Lightbrand",
    type: 'Broad Sword',
    image: 'http://www.d2tomb.com/images/setitems/isenharts_lightbrand.gif',
    props: [
      'One-Hand Damage:',
      '17-18',
      'Durability:',
      '32',
      'Req Strength:',
      '48',
      'Req Level:',
      '8',
      '20% Increased Attack Speed',
      '+10 to min Damage',
      '+5 per character level to Attack Rating (2 set items)',
    ],
    category: "Isenhart's Armory",
    id: 423,
    filename: 'isenharts_lightbrand.gif',
    set_id: 11,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Isenhart's Horns",
    type: 'Full Helm',
    image: 'http://www.d2tomb.com/images/setitems/isenharts_horns.gif',
    props: [
      'Defense:',
      '23-26',
      'Durability:',
      '30',
      'Req Strength:',
      '41',
      'Req Level:',
      '8',
      'Damage reduced by 2',
      '+6 to Dexterity',
      '+8 to all Resistances (2 set items)',
    ],
    category: "Isenhart's Armory",
    id: 424,
    filename: 'isenharts_horns.gif',
    set_id: 11,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Isenhart's Case",
    type: 'Breast Plate',
    image: 'http://www.d2tomb.com/images/setitems/isenharts_case.gif',
    props: [
      'Defense:',
      '105-306',
      'Durability:',
      '50',
      'Req Strength:',
      '30',
      'Req Level:',
      '8',
      'Magic damage reduced by 2',
      '+40 Defense',
      '+2 per character level to Defense (2 set items)',
    ],
    category: "Isenhart's Armory",
    id: 425,
    filename: 'isenharts_case.gif',
    set_id: 11,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Isenhart's Parry",
    type: 'Gothic Shield',
    image: 'http://www.d2tomb.com/images/setitems/isenharts_parry.gif',
    props: [
      'Defense:',
      '70-75',
      '41% chance to block',
      'Durability:',
      '40',
      'Req Strength:',
      '60',
      'Req Level:',
      '8',
      'Attacker takes 4 Lightning Damage',
      '+40 Defense',
      '+8 to all Resistances (2 set items)',
    ],
    category: "Isenhart's Armory",
    id: 426,
    filename: 'isenharts_parry.gif',
    set_id: 11,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Milabrega's Diadem",
    type: 'Crown',
    image: 'http://www.d2tomb.com/images/setitems/milabregas_diadem.gif',
    props: [
      'Defense:',
      '25-45',
      'Durability:',
      '50',
      'Req Strength:',
      '55',
      'Req Level:',
      '17',
      '+15 to Mana',
      '+15 to Life',
      '+40% Cold Resist (2 set items)',
    ],
    category: "Milabrega's Regalia",
    id: 427,
    filename: 'milabregas_diadem.gif',
    set_id: 12,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Milabrega's Robe",
    type: 'Ancient Armor',
    image: 'http://www.d2tomb.com/images/setitems/milabregas_robe.gif',
    props: [
      'Defense:',
      '218-468',
      'Durability:',
      '60',
      'Req Strength:',
      '100',
      'Req Level:',
      '17',
      'Damage Reduced by 2',
      'Attacker takes 3 Damage',
      '+100% Enhanced Defense (2 set items)',
    ],
    category: "Milabrega's Regalia",
    id: 428,
    filename: 'milabregas_robe.gif',
    set_id: 12,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Milabrega's Orb",
    type: 'Kite Shield',
    image: 'http://www.d2tomb.com/images/setitems/milabregas_orb.gif',
    props: [
      'Defense:',
      '44-53',
      '33% chance to block',
      'Durability:',
      '30',
      'Req Strength:',
      '47',
      'Req Level:',
      '17',
      '+25 Defense',
      '20% better chance of getting magic item',
      '+50 to Life (2 set items)',
      '+50% Enhanced Defense (3 set items)',
    ],
    category: "Milabrega's Regalia",
    id: 429,
    filename: 'milabregas_orb.gif',
    set_id: 12,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Milabrega's Rod",
    type: 'War Scepter',
    image: 'http://www.d2tomb.com/images/setitems/milabregas_rod.gif',
    props: [
      'One-Hand Damage:',
      '15-25',
      'Durability:',
      '70',
      'Req Strength:',
      '55',
      'Req Level:',
      '17',
      '+2 to Light Radius',
      '+1 to Paladin Skills',
      '+50% Enhanced Damage',
      '+50% Damage to Undead',
    ],
    category: "Milabrega's Regalia",
    id: 430,
    filename: 'milabregas_rod.gif',
    set_id: 12,
    category_id: 25,
    item_type: 'set',
  },
  {
    name: "Sigon's Visor",
    type: 'Great Helm',
    image: 'http://www.d2tomb.com/images/setitems/sigons_visor.gif',
    props: [
      'Defense:',
      '55-60',
      'Durability:',
      '40',
      'Req Level:',
      '6',
      'Req Strength:',
      '63',
      '+25 to Defense',
      '+30 to Mana',
      '+8 per character level to Attack Rating (2 set items)',
    ],
    category: "Sigon's Complete Steel",
    id: 431,
    filename: 'sigons_visor.gif',
    set_id: 13,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Sigon's Shelter",
    type: 'Gothic Plate',
    image: 'http://www.d2tomb.com/images/setitems/sigons_shelter.gif',
    props: [
      'Defense:',
      '128-170',
      'Durability:',
      '55',
      'Req Strength:',
      '70',
      'Req Level:',
      '6',
      '+25% Enhanced Defense',
      '+30% Lightning Resist',
      'Attacker takes damage fo 20 (2 set items)',
    ],
    category: "Sigon's Complete Steel",
    id: 432,
    filename: 'sigons_shelter.gif',
    set_id: 13,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Sigon's Sabot",
    type: 'Greaves',
    image: 'http://www.d2tomb.com/images/setitems/sigons_sabot.gif',
    props: [
      'Defense:',
      '12-15',
      'Durability:',
      '24',
      'Assassin Kick Damage:',
      '10-20',
      'Req Strength:',
      '70',
      'Req Level:',
      '6',
      '20% Faster Run/Walk',
      '+40% Cold Resist',
      '+50 to Attack Rating (2 set items)',
      '+50% better chance to find magic items (3 set items)',
    ],
    category: "Sigon's Complete Steel",
    id: 433,
    filename: 'sigons_sabot.gif',
    set_id: 13,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Sigon's Guard",
    type: 'Tower Shield',
    image: 'http://www.d2tomb.com/images/setitems/sigons_guard.gif',
    props: [
      'Defense:',
      '22-25',
      '69% Chance to Block',
      'Durability:',
      '60',
      'Req Strength:',
      '75',
      'Req Level:',
      '6',
      '+20% Chance to Block',
      '+1 to all Skills',
    ],
    category: "Sigon's Complete Steel",
    id: 434,
    filename: 'sigons_guard.gif',
    set_id: 13,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Sigon's Wrap",
    type: 'Plated Belt',
    image: 'http://www.d2tomb.com/images/setitems/sigons_wrap.gif',
    props: [
      'Defense:',
      '8-209',
      'Durability:',
      '24',
      'Req Strength:',
      '60',
      'Req Level:',
      '6',
      '+20% Fire Resist',
      '+20 to Life',
      '+2 per character level Defense (2 set items)',
    ],
    category: "Sigon's Complete Steel",
    id: 435,
    filename: 'sigons_wrap.gif',
    set_id: 13,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Sigon's Gage",
    type: 'Gauntlets',
    image: 'http://www.d2tomb.com/images/setitems/sigons_gage.gif',
    props: [
      'Defense:',
      '12-15',
      'Durability:',
      '24',
      'Req Strength:',
      '60',
      'Req Level:',
      '6',
      '+20 to Attack Rating',
      '+10 to Strength',
      '30% Increased Attack Speed ( 2 set items)',
    ],
    category: "Sigon's Complete Steel",
    id: 436,
    filename: 'sigons_gage.gif',
    set_id: 13,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Tancred's Skull",
    type: 'Bone Helm',
    image: 'http://www.d2tomb.com/images/setitems/tancreds_skull.gif',
    props: [
      'Defense:',
      '33-36',
      'Durability:',
      '40',
      'Req Strength:',
      '25',
      'Req Level:',
      '20',
      '+10% Enhanced Damage',
      '+40 to Attack Rating',
      '+10 to all Resistances (2 set items)',
    ],
    category: "Tancred's Battlegear",
    id: 437,
    filename: 'tancreds_skull.gif',
    set_id: 14,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Tancred's Spine",
    type: 'Full Plate Mail',
    image: 'http://www.d2tomb.com/images/setitems/tancreds_spine.gif',
    props: [
      'Defense:',
      '150-359',
      'Durability:',
      '70',
      'Req Strength:',
      '80',
      'Req Level:',
      '20',
      '+40 to Life',
      '+15 to Strength',
      '+2 to character level to Defense (2 set items)',
    ],
    category: "Tancred's Battlegear",
    id: 438,
    filename: 'tancreds_spine.gif',
    set_id: 14,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Tancred's Hobnails",
    type: 'Boots',
    image: 'http://www.d2tomb.com/images/setitems/tancreds_hobnails.gif',
    props: [
      'Defense:',
      '2-3',
      'Durability:',
      '12',
      'Req Level:',
      '20',
      'Assassin Kick Damage:',
      '3-8',
      '+25% Heal Stamina',
      '+10 to Dexterity',
      '30% Faster Run/Walk (2 set items)',
      '+10 to Strength (3 set items)',
    ],
    category: "Tancred's Battlegear",
    id: 439,
    filename: 'tancreds_hobnails.gif',
    set_id: 14,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Tancred's Crowbill",
    type: 'Military Pick',
    image: 'http://www.d2tomb.com/images/setitems/tancreds_crowbill.gif',
    props: [
      'One-Hand Damage:',
      '12-19',
      'Durability:',
      '26',
      'Req Dexterity:',
      '33',
      'Req Strength:',
      '49',
      'Req Level:',
      '20',
      '+80% Enhanced Damage',
      '+75 to Attack Rating',
      '+20 to Mana (2 set items)',
      '20% Increased Attack Speed (3 set items)',
    ],
    category: "Tancred's Battlegear",
    id: 440,
    filename: 'tancreds_crowbill.gif',
    set_id: 14,
    category_id: 18,
    item_type: 'set',
  },
  {
    name: "Tancred's Weird",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu3.gif',
    props: [
      'Req Level:',
      '20',
      'Damage Reduced by 2',
      'Magic Damage reduced by 1',
      '+78% better chance of getting magic items (2 set items)',
      '+60 to Attack Rating (3 set items)',
    ],
    category: "Tancred's Battlegear",
    id: 441,
    filename: 'amu3.gif',
    set_id: 14,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Vidala's Barb",
    type: 'Long Battle Bow',
    image: 'http://www.d2tomb.com/images/setitems/vidalas_barb.gif',
    props: [
      'Two-Hand Damage:',
      '3-18',
      'Req Dexterity:',
      '50',
      'Req Strength:',
      '40',
      'Req Level:',
      '14',
      'Adds 1-20 Lightning Damage',
      '+8 to character level to Attack Rating (2 set items)',
    ],
    category: "Vidala's Rig",
    id: 442,
    filename: 'vidalas_barb.gif',
    set_id: 15,
    category_id: 19,
    item_type: 'set',
  },
  {
    name: "Vidala's Ambush",
    type: 'Leather Armor',
    image: 'http://www.d2tomb.com/images/setitems/vidalas_ambush.gif',
    props: [
      'Defense:',
      '64-314',
      'Durability:',
      '24',
      'Req Strength:',
      '15',
      'Req Level:',
      '14',
      '+50 Defense',
      '+11 to Dexterity',
      '+24% Fire Resist (2 set items)',
      '+2.5 per character level to Defense (3 set items)',
    ],
    category: "Vidala's Rig",
    id: 443,
    filename: 'vidalas_ambush.gif',
    set_id: 15,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Vidala's Fetlock",
    type: 'Light Plated Boots',
    image: 'http://www.d2tomb.com/images/setitems/vidalas_fetlock.gif',
    props: [
      'Defense:',
      '9-11',
      'Durability:',
      '18',
      'Assassin Kick Damage:',
      '8-16',
      'Req Level:',
      '14',
      'Req Strength:',
      '50',
      '30% Faster Run/Walk',
      '+150 to max Stamina',
      '+8 to all Resistances (2 set items)',
    ],
    category: "Vidala's Rig",
    id: 444,
    filename: 'vidalas_fetlock.gif',
    set_id: 15,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Vidala's Snare",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu2.gif',
    props: ['Req Level:', '14', '20% Cold Resist', '+15 to Life', '50% better chance of getting magic items (2 set items)'],
    category: "Vidala's Rig",
    id: 445,
    filename: 'amulet.gif',
    set_id: 15,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Aldur's Stony Gaze",
    type: "Hunter's Guise",
    image: 'http://www.d2tomb.com/images/setitems/aldurs_stoney_gaze.gif',
    props: [
      'Defense:',
      '157-171',
      'Req Strength:',
      '56',
      'Req Level:',
      '36',
      'Durability:',
      '20',
      '25% Faster Hit Recovery',
      '+5 to Light Radius',
      '17% Regenerate Mana',
      '+40-50% Cold Resist',
      '+90 Defense',
      '2 Sockets',
      '+15 To Energy (2 Item)',
      '+15 To Energy (3 Items)',
      '+15 To Energy (Completed Set)',
      'Druid Only',
    ],
    category: "Aldur's Watchtower",
    id: 446,
    filename: 'aldurs_stoney_gaze.gif',
    set_id: 16,
    category_id: 3,
    item_type: 'set',
  },
  {
    name: "Aldur's Advance",
    type: 'Battle Boots',
    image: 'http://www.d2tomb.com/images/setitems/aldurs_advance.gif',
    props: [
      'Defense:',
      '39-47',
      'Req Strength:',
      '95',
      'Req Level:',
      '45',
      'Indestructible',
      'Assassin Kick Damage:',
      ' 37-64',
      '10% Damage taken goes to Mana',
      '40% Faster Run/Walk',
      '+32% Heal Stamina',
      '+180 to max Stamina',
      '+50 to Life',
      '+40-50% Fire Resist',
      '+15 To Dexterity (2 Items)',
      '+15 To Dexterity (3 Items)',
      '+15 To Dexterity (Completed Set)',
    ],
    category: "Aldur's Watchtower",
    id: 447,
    filename: 'aldurs_advance.gif',
    set_id: 16,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Aldur's Deception",
    type: 'Shadow Plate',
    image: 'http://www.d2tomb.com/images/setitems/aldurs_deception.gif',
    props: [
      'Defense:',
      '746-857',
      'Req Strength:',
      '115',
      'Req Level:',
      '76',
      'Durability:',
      '70',
      '-50% to Requirements',
      '+40-50% Lightning Resist',
      '+300 to Defense',
      '+15 to Dexterity',
      '+20 to Strength',
      '+1 to Elemental Skills - Druid Only',
      '+1 to ShapeShifting Skills - Druid Only',
      '+15 To Vitality (2 Item2)',
      '+15 To Vitality (3 Items)',
      '+15 To Vitality (Completed Set)',
    ],
    category: "Aldur's Watchtower",
    id: 448,
    filename: 'aldurs_deception.gif',
    set_id: 16,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Aldur's Rhythm",
    type: 'Jagged Star',
    image: 'http://www.d2tomb.com/images/setitems/aldurs_rhythm.gif',
    props: [
      'Damage:',
      ' 60-93',
      'Durability:',
      '72',
      'Req Strength:',
      '74',
      'Req Level:',
      '42',
      '+200% Damage to Demons',
      '10% Life Stolen per hit',
      '5% Mana stolen per hit',
      'Adds 50-75 Lightning Damage',
      '30% Increased Attack Speed',
      '+50% Damage to Undead',
      'Adds 40-62 Damage',
      '2-3 Sockets (varies)*',
      '+15 To Strength (2 Items)',
      '+15 To Strength (3 Items)',
      '+15 To Strength (Completed Set)',
    ],
    category: "Aldur's Watchtower",
    id: 449,
    filename: 'aldurs_rhythm.gif',
    set_id: 16,
    category_id: 23,
    item_type: 'set',
  },
  {
    name: "Bul-Kathos' Sacred Charge",
    type: 'Colossus Blade',
    image: 'http://www.d2tomb.com/images/setitems/bulkathos_sacred_charge.gif',
    props: [
      'One-handed Damage:',
      '75-195',
      'Two-handed Damage:',
      '174-345',
      'Req Strength:',
      '189',
      'Req Dexterity:',
      '110',
      'Req Level:',
      '63',
      'Durability:',
      '50',
      '35% chance of crushing blow',
      '+200% Enhanced Damage',
      '+20 to all Resistances',
      '20% increased attack speed',
      'Knockback',
    ],
    category: "Bul-Kathos' Children",
    id: 450,
    filename: 'bulkathos_sacred_charge.gif',
    set_id: 17,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Bul-Kathos' Tribal Guardian",
    type: 'Mythical Sword',
    image: 'http://www.d2tomb.com/images/setitems/bulkathos_tribal_guardian.gif',
    props: [
      'One-handed Damage:',
      '120-156',
      'Req Strength:',
      '147',
      'Req Dexterity:',
      '124',
      'Req Level:',
      '66',
      'Durability:',
      '44',
      '+50% Fire Resist',
      '+200% Enhanced Damage',
      '+50 Poison Damage for 2 seconds',
      '+20 to Strength',
      '+20% increased attack speed',
    ],
    category: "Bul-Kathos' Children",
    id: 451,
    filename: 'bulkathos_tribal_guardian.gif',
    set_id: 17,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Cow King's Horns",
    type: 'War Hat',
    image: 'http://www.d2tomb.com/images/setitems/cowkings_horns.gif',
    props: [
      'Defense:',
      '120-128',
      'Req Strength:',
      '20',
      'Req Level:',
      '25',
      'Durability:',
      '12',
      '35% Damage taken goes to Mana',
      'Half Freeze Duration',
      '+75 to Defense',
      'Attacker takes damage of 10',
    ],
    category: "Cow King's Leathers",
    id: 452,
    filename: 'cowkings_horns.gif',
    set_id: 18,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Cow King's Hide",
    type: 'Studded Leather',
    image: 'http://www.d2tomb.com/images/setitems/cowkings_hide.gif',
    props: [
      'Defense:',
      '57',
      'Req Strength:',
      '27',
      'Req Level:',
      '18',
      'Durability:',
      '32',
      '18% chance to cast level 5 Chain Lightning when struck',
      '+60% Defense',
      '+18 to all Resistances',
      '+30 to Life',
    ],
    category: "Cow King's Leathers",
    id: 453,
    filename: 'cowkings_hide.gif',
    set_id: 18,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Cow King's Hooves",
    type: 'Heavy Boots',
    image: 'http://www.d2tomb.com/images/setitems/cowkings_hooves.gif',
    props: [
      'Defense:',
      '30-41',
      'Req Strength:',
      '18',
      'Req Level:',
      '13',
      'Durability:',
      '14',
      'Assassin Kick Damage:',
      '4-10',
      '+30% Faster Run/Walk',
      'Adds 25-35 Fire Damage',
      '+25-35 Defense',
      '+20 to Dexterity',
      '25% better chance of getting magic items',
    ],
    category: "Cow King's Leathers",
    id: 454,
    filename: 'cowkings_hooves.gif',
    set_id: 18,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: 'Telling of Beads',
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu2.gif',
    props: ['Req Level:', '30', '+1 to all Skill levels', '+35-50% Poison Resist', '+18% Cold Resist', 'Attacker takes damage of 8-10'],
    category: 'The Disciple',
    id: 455,
    filename: 'amulet.gif',
    set_id: 21,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: 'Laying of Hands',
    type: 'Bramble Mitts',
    image: 'http://www.d2tomb.com/images/setitems/laying_of_hands.gif',
    props: [
      'Defense:',
      '79-87',
      'Req Strength:',
      '50',
      'Req Level:',
      '63',
      'Durability:',
      '12',
      '10% chance to cast level 3 Holy Bolt on striking',
      '+350% Damage to Demons',
      '+50% Fire Resist',
      '+25 to Defense',
      '+20% increased Attack Speed',
    ],
    category: 'The Disciple',
    id: 456,
    filename: 'laying_of_hands.gif',
    set_id: 21,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: 'Dark Adherent',
    type: 'Dusk Shroud',
    image: 'http://www.d2tomb.com/images/setitems/dark_adherent.gif',
    props: [
      'Defense 666-882',
      'Req Strength:',
      '77',
      'Req Level:',
      '49',
      'Durability:',
      '20',
      '25% chance to cast level 3 Nova when struck',
      'Adds 24-34 Poison Damage for 2 seconds',
      '+24% Fire Resist',
      '+305-415 to Defense',
    ],
    category: 'The Disciple',
    id: 457,
    filename: 'dark_adherent.gif',
    set_id: 21,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: 'Rite of Passage',
    type: 'Demonhide Boots',
    image: 'http://www.d2tomb.com/images/setitems/rite_of_passage.gif',
    props: [
      'Defense:',
      '53-60',
      'Req Strength:',
      '20',
      'Req Level:',
      '29',
      'Durability:',
      '12',
      'Assassin Kick Damage:',
      '26-46',
      'Half Freeze Duration',
      '30% Faster Run/Walk',
      '+25 to Defense',
      '+15-25 to maximum Stamina',
    ],
    category: 'The Disciple',
    id: 458,
    filename: 'rite_of_passage.gif',
    set_id: 21,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: 'Credendum',
    type: 'Mithril Coil',
    image: 'http://www.d2tomb.com/images/setitems/credendum.gif',
    props: [
      'Defense:',
      '108-115',
      'Req Strength:',
      '106',
      'Req Level:',
      '65',
      'Durability:',
      '16',
      '+15 to all Resistances',
      '+50 to Defense',
      '+10 to Dexterity',
      '+10 to Strength',
    ],
    category: 'The Disciple',
    id: 459,
    filename: 'credendum.gif',
    set_id: 21,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Griswold's Heart",
    type: 'Ornate Armor',
    image: 'http://www.d2tomb.com/images/setitems/griswolds_heart.gif',
    props: [
      'Defense:',
      '917-950',
      'Req Strength:',
      '102',
      'Req Level:',
      '45',
      'Durability:',
      '60',
      '-40% Requirements',
      '+500 Defense',
      '+20 to Strength',
      '+2 to Paladin Defensive Aura Skills',
      '3 Sockets',
    ],
    category: "Griswold's Legacy",
    id: 460,
    filename: 'griswolds_heart.gif',
    set_id: 22,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Griswold's Valor",
    type: 'Corona',
    image: 'http://www.d2tomb.com/images/setitems/griswolds_valor.gif',
    props: [
      'Defense:',
      '249-290',
      'Req Strength:',
      '105',
      'Req Level:',
      '69',
      'Durability:',
      '50',
      '-40% Requirements',
      '+50-70% Enhanced Defense',
      '+5 to all Resistances',
      '20-30% better chance of getting Magic items',
      '+0.25 per character level to Absorbs Cold',
      '2 Sockets',
      '+2 To Offensive Auras (Paladin Only) (2 Items)',
    ],
    category: "Griswold's Legacy",
    id: 461,
    filename: 'griswolds_valor.gif',
    set_id: 22,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Griswold's Redemption",
    type: 'Caduceus',
    image: 'http://www.d2tomb.com/images/setitems/griswolds_redemption.gif',
    props: [
      'Damage:',
      '111-146',
      'Damage:',
      '',
      '121-166 (3 Items)',
      'Damage:',
      '131-186 (Complete Set)',
      'Req Strength:',
      '78',
      'Req Dexterity:',
      '56',
      'Req Level:',
      '66',
      'Durability:',
      '70',
      '+200-240% Enhanced Damage',
      '-20% Requirements',
      '40% increased Attack Speed',
      '+250% damage to Undead',
      '3 or 4 Sockets',
      '+2 To Combat Skills (Paladin Only) (2 Items)',
      'Adds 10-20 Damage (3 Items)',
      'Adds 10-20 Damage (Complete Set)',
    ],
    category: "Griswold's Legacy",
    id: 462,
    filename: 'griswolds_redemption.gif',
    set_id: 22,
    category_id: 25,
    item_type: 'set',
  },
  {
    name: "Griswold's Honor",
    type: 'Vortex Shield',
    image: 'http://www.d2tomb.com/images/setitems/griswolds_honor.gif',
    props: [
      'Defense:',
      '290-333',
      'Req Strength:',
      '148',
      'Req Level:',
      '68',
      'Durability:',
      '90',
      '69% Chance to Block',
      '5-87 Paladin Smite Damage',
      '65% Faster Block Rate',
      '20% Increased Chance of Blocking',
      '+108 Defense',
      '+45 to all Resistances',
      '3 Sockets',
    ],
    category: "Griswold's Legacy",
    id: 463,
    filename: 'griswolds_honor.gif',
    set_id: 22,
    category_id: 4,
    item_type: 'set',
  },
  {
    name: "Haemosu's Adamant",
    type: 'Cuirass',
    image: 'http://www.d2tomb.com/images/setitems/haemosus_adament.gif',
    props: [
      'Defense:',
      '688-702',
      'Req Strength:',
      '52',
      'Req Level:',
      '44',
      'Durability:',
      '50',
      '-20% Requirements',
      '+40 defense against melee',
      '+35 defense against missile',
      '+500 Defense',
      '+75 to Life',
    ],
    category: "Heaven's Brethren",
    id: 464,
    filename: 'haemosus_adament.gif',
    set_id: 23,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Dangoon's Teaching",
    type: 'Reinforced Mace',
    image: 'http://www.d2tomb.com/images/setitems/dangoons_teaching.gif',
    props: [
      'Damage:',
      '41-197',
      'Req Strength:',
      '145',
      'Req Dexterity:',
      '46',
      'Req Level:',
      '68',
      'Durability:',
      '60',
      '10% chance to cast level 3 Frost Nova on striking',
      '+1.5 per character level to maximum Damage',
      'Adds 20-30 Fire Damage',
      '+40% increased Attack Speed',
      '+50% damage to Undead',
    ],
    category: "Heaven's Brethren",
    id: 465,
    filename: 'dangoons_teaching.gif',
    set_id: 23,
    category_id: 23,
    item_type: 'set',
  },
  {
    name: "Taebaek's Glory",
    type: 'Ward',
    image: 'http://www.d2tomb.com/images/setitems/taebaeks_glory.gif',
    props: [
      'Defense:',
      '203-220',
      'Req Strength:',
      '185',
      'Req Level:',
      '81',
      'Indestructible',
      '30% Faster Block Rate',
      '+25% increased chance of Blocking',
      '+30% Lightning Resist',
      '+50 to Defense',
      '+100 to Mana',
      'Attacker takes damage of 30',
    ],
    category: "Heaven's Brethren",
    id: 466,
    filename: 'taebaeks_glory.gif',
    set_id: 23,
    category_id: 14,
    item_type: 'set',
  },
  {
    name: "Ondal's Almighty",
    type: 'Spired Helm',
    image: 'http://www.d2tomb.com/images/setitems/ondals_almighty.gif',
    props: [
      'Defense:',
      '164-209',
      'Req Strength:',
      '116',
      'Req Level:',
      '69',
      'Durability:',
      '40',
      '10% chance to cast level 3 Weaken on striking',
      '24% Faster Hit Recovery',
      '-40% Requirements',
      '+50 Defense',
      '+15 to Dexterity',
      '+10 to Strength',
    ],
    category: "Heaven's Brethren",
    id: 467,
    filename: 'ondals_almighty.gif',
    set_id: 23,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Hwanin's Splendor",
    type: 'Grand Crown',
    image: 'http://www.d2tomb.com/images/setitems/hwanins_splendor.gif',
    props: [
      'Defense:',
      '156-226',
      'Req Strength:',
      '103',
      'Req Level:',
      '45',
      'Durability:',
      '50',
      '+100% Enhanced Defense',
      '+20 Replenish Life',
      'Magic Damage Reduced by 10',
      '+37% Cold Resist',
    ],
    category: "Hwanin's Majesty",
    id: 468,
    filename: 'hwanins_splendor.gif',
    set_id: 24,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Hwanin's Justice",
    type: 'Bill',
    image: 'http://www.d2tomb.com/images/setitems/hwanins_justice.gif',
    props: [
      'Damage:',
      '42-159',
      'Req Strength:',
      '95',
      'Req Level:',
      '28',
      'Indestructible',
      '10% chance to cast level 3 Ice Blast on striking',
      '+200% Enhanced Damage',
      'Adds 5-25 Lightning Damage',
      '+330 to Attacl Rating',
      '40% increased Attack Speed',
    ],
    category: "Hwanin's Majesty",
    id: 469,
    filename: 'hwanins_justice.gif',
    set_id: 24,
    category_id: 24,
    item_type: 'set',
  },
  {
    name: "Hwanin's Refuge",
    type: 'Tigulated Mail',
    image: 'http://www.d2tomb.com/images/setitems/hwanins_refuge.gif',
    props: [
      'Defense:',
      '375-390',
      'Req Strength:',
      '86',
      'Req Level:',
      '30',
      'Durability:',
      '36',
      '10% chance to cast level 3 Static Field when struck',
      '+27% to Poison Resist',
      '+200 Defense',
      '+100 to Life',
    ],
    category: "Hwanin's Majesty",
    id: 470,
    filename: 'hwanins_refuge.gif',
    set_id: 24,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Hwanin's Blessing",
    type: 'Belt',
    image: 'http://www.d2tomb.com/images/setitems/hwanins_blessing.gif',
    props: [
      'Defense:',
      '6-153',
      'Req Strength:',
      '25',
      'Req Level:',
      '35',
      'Durability:',
      '16',
      '+1.5 per character level to Defense',
      'Prevent Monster Heal',
      '12% Damage taken goes to Mana',
      'Adds 3-33 Lightning Damage',
    ],
    category: "Hwanin's Majesty",
    id: 471,
    filename: 'hwanins_blessing.gif',
    set_id: 24,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Immortal King's Will",
    type: 'Avenger Guard',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_will.gif',
    props: [
      'Defense:',
      '160-175',
      'Req Strength:',
      '65',
      'Req Level:',
      '47',
      'Durability:',
      '55',
      '+4 to Light Radius',
      '+125 to Defense',
      '37% extra Gold from monsters',
      '25-40% better chance of finding Magic items',
      '+2 to Warcries Skills (Barbarian)',
      '2 Sockets',
    ],
    category: 'Immortal King',
    id: 472,
    filename: 'immortal_kings_will.gif',
    set_id: 25,
    category_id: 2,
    item_type: 'set',
  },
  {
    name: "Immortal King's Stone Crusher",
    type: 'Ogre Maul',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_stone_crusher.gif',
    props: [
      'Damage:',
      ' 231-318',
      'Req Strength:',
      '225',
      'Req Level:',
      '76',
      'Indestructible',
      '35-40% chance of Crushing Blow',
      '+250% Damage to Undead',
      '+200% Damage to Demons',
      '+200% Enhanced Damage',
      '40% increased Attack Speed',
      '2 Sockets',
      'Adds 211-397 Fire Damage (2 items)',
      'Adds 7-477 Lightning Damage (3 Items)',
      'Adds 127-364 Cold Damage, 6 sec. Duration (4 items)',
      '+204 Poison Damage Over 6 Seconds (5 Items)',
      'Adds 250-361 Magic Damage (Complete Set)',
    ],
    category: 'Immortal King',
    id: 473,
    filename: 'immortal_kings_stone_crusher.gif',
    set_id: 25,
    category_id: 23,
    item_type: 'set',
  },
  {
    name: "Immortal King's Soul Cage",
    type: 'Sacred Armor',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_soul_cage.gif',
    props: [
      'Defense:',
      '487-1301',
      'Req Strength:',
      '232',
      'Req Level:',
      '76',
      'Durability:',
      '60',
      '5% chance to cast level 5 Enchant when struck',
      '+50% Poison Resist',
      '+400 to Defense',
      '+2 to Combat Skills (Barbarian)',
      '+25% Faster Hit Recovery (2 Items)',
      'Cold Resist +40% (3 Items)',
      'Fire Resist +40% (4 Items)',
      'Lightning Resist +40% (5 Items)',
      '+50% Enhanced Defense (Complete Set)',
    ],
    category: 'Immortal King',
    id: 474,
    filename: 'immortal_kings_soul_cage.gif',
    set_id: 25,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Immortal King's Detail",
    type: 'War Belt',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_detail.gif',
    props: [
      'Defense:',
      '89-247',
      'Req Strength:',
      '110',
      'Req Level:',
      '29',
      'Durability:',
      '24',
      '+31% Lightning Resist',
      '+28% Fire Resist',
      '+36 to Defense',
      '+25 to Strength',
      '+105 Defense (2 items)',
      '+25% Faster Hit Recovery (3 Items)',
      '+100% Enhanced Defense (4 Items)',
      'Damage Reduced By 20% (5 Items)',
      '+2 To Masteries (Barbarian Only) (Complete Set)',
    ],
    category: 'Immortal King',
    id: 475,
    filename: 'immortal_kings_detail.gif',
    set_id: 25,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Immortal King's Forge",
    type: 'War Gauntlets',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_forge.gif',
    props: [
      'Defense:',
      '108-238',
      'Req Strength:',
      '110',
      'Req Level:',
      '30',
      'Durability:',
      '24',
      '12% chance to cast level 4 Charged Bolt when struck',
      '+65 to Defense',
      '+20 to Dexterity',
      '+20 to Strength',
      '+25% Increased Attack Speed (2 items)',
      '+120 Defense (3 Items)',
      '10% Life Stolen Per Hit (4 Items)',
      '10% Mana Stolen Per Hit (5 Items)',
      'Freezes Target +2 (Complete Set)',
    ],
    category: 'Immortal King',
    id: 476,
    filename: 'immortal_kings_forge.gif',
    set_id: 25,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Immortal King's Pillar",
    type: 'War Boots',
    image: 'http://www.d2tomb.com/images/setitems/immortal_kings_pillar.gif',
    props: [
      'Defense:',
      '118-288',
      'Req Strength:',
      '125',
      'Req Level:',
      '31',
      'Durability:',
      '24',
      '40% Faster Run/Walk',
      '+75 to Defense',
      '+110 to Attack Rating',
      '+44 to Life',
      '25% Better Chance of Getting Magic Items (2 items)',
      '+2 To Combat Skills (Barbarian Only) (3 Items)',
      '+160 Defense (4 Items)',
      'Half Freeze Duration (5 Items)',
    ],
    category: 'Immortal King',
    id: 477,
    filename: 'immortal_kings_pillar.gif',
    set_id: 25,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "M'avina's True Sight",
    type: 'Diadem',
    image: 'http://www.d2tomb.com/images/setitems/mavinas_true_sight.gif',
    props: [
      'Defense:',
      '200-210',
      'Req Level:',
      '64',
      'Durability:',
      '20',
      '+10 Replenish Life',
      '+150 to Defense',
      '+25 to Mana',
      '+30% increased Attack Speed',
      '+1 To All Skills (2 Items)',
      '50% Bonus To Attack Rating (3 Items)',
      'All Resistances +25 (4 Items)',
    ],
    category: "M'avina's Battle Hymn",
    id: 478,
    filename: 'mavinas_true_sight.gif',
    set_id: 26,
    category_id: 11,
    item_type: 'set',
  },
  {
    name: "M'avina's Caster",
    type: 'Grand Matron BowAmazon only',
    image: 'http://www.d2tomb.com/images/setitems/mavinas_caster.gif',
    props: [
      'Damage 40-207',
      'Req Strength:',
      '108',
      'Req Dexterity:',
      '152',
      'Req Level:',
      '70',
      '+188% Enhanced Damage',
      '+50 to Attack Rating',
      '+40% increased Attack Speed',
      'Fires Magic Arrows',
      'Adds 114-377 Magic Damage (2 Items)',
      '10% Chance To Cast Level 15 Nova On Striking (3 Items)',
      '+2 To Bow and Crossbow Skills (Amazon Only) (4 Items)',
    ],
    category: "M'avina's Battle Hymn",
    id: 479,
    filename: 'mavinas_caster.gif',
    set_id: 26,
    category_id: 0,
    item_type: 'set',
  },
  {
    name: "M'avina's Embrace",
    type: 'Kraken Shell',
    image: 'http://www.d2tomb.com/images/setitems/mavinas_embrace.gif',
    props: [
      'Defense:',
      '771-1269',
      'Req Strength:',
      '122',
      'Req Level:',
      '70',
      'Durability:',
      '48',
      '-30% Requirements',
      '10% chance to cast level 3 Glacial Spike when struck',
      'Magic Damage reduced by 5-12',
      '+4 per character level to Defense',
      '+2 to Passive and Magic Skills (Amazon)',
      '+350 to Defense',
      '+30% Faster Hit Recovery (3 Items)',
    ],
    category: "M'avina's Battle Hymn",
    id: 480,
    filename: 'mavinas_embrace.gif',
    set_id: 26,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "M'avina's Icy Clutch",
    type: 'Battle Gauntlets',
    image: 'http://www.d2tomb.com/images/setitems/mavinas_icy_clutch.gif',
    props: [
      'Defense:',
      '84-97',
      'Req Strength:',
      '88',
      'Req Level:',
      '32',
      'Durability:',
      '18',
      '+45-50 to Defense',
      '6-18 Cold Damage for 6 seconds',
      'Half Freeze Duration',
      '56% extra Gold from Monsters',
      '+10 to Strength',
      '+15 to Dexterity',
      'Adds 131-252 Cold Damage, 4 sec. Duration (Normal) (4 Items)',
      '+20% To Cold Skill Damage (Complete Set)',
    ],
    category: "M'avina's Battle Hymn",
    id: 481,
    filename: 'mavinas_icy_clutch.gif',
    set_id: 26,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "M'avina's Tenet",
    type: 'Sharkskin Belt',
    image: 'http://www.d2tomb.com/images/setitems/mavinas_tenet.gif',
    props: [
      'Defense:',
      '81-86',
      'Req Strength:',
      '20',
      'Req Level:',
      '45',
      'Durability:',
      '14',
      '+20% Faster Run/Walk',
      '+5 to Light Radius',
      '5% Mana stolen per hit',
      '+50 to Defense',
      'All Resistances +25 (4 Items)',
    ],
    category: "M'avina's Battle Hymn",
    id: 482,
    filename: 'mavinas_tenet.gif',
    set_id: 26,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Naj's Circlet",
    type: 'Circlet',
    image: 'http://www.d2tomb.com/images/setitems/najs_circlet.gif',
    props: [
      'Defense:',
      '95-105',
      'Req Level:',
      '28',
      'Durability:',
      '35',
      '+75 to Defense',
      '+25-35 Fire Damage',
      '+5 to Light Radius',
      '12% chance to cast level 5 Chain Lightning when struck',
      '+15 to Strength',
    ],
    category: "Naj's Ancient Vestige",
    id: 483,
    filename: 'najs_circlet.gif',
    set_id: 27,
    category_id: 11,
    item_type: 'set',
  },
  {
    name: "Naj's Light Plate",
    type: 'Hellforge Plate',
    image: 'http://www.d2tomb.com/images/setitems/najs_light_plate.gif',
    props: [
      'Defense:',
      '721-830',
      'Req Strength:',
      '79',
      'Req Level:',
      '71',
      'Durability:',
      '60',
      '45% Damage taken goes to Mana',
      '-60% Requirements',
      '+1 to all Skill Levels',
      '+25 to all Resistances',
      '+300 to Defense',
      '+65 to Life',
    ],
    category: "Naj's Ancient Vestige",
    id: 484,
    filename: 'najs_light_plate.gif',
    set_id: 27,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Naj's Puzzler",
    type: 'Elder Staff',
    image: 'http://www.d2tomb.com/images/setitems/najs_puzzler.gif',
    props: [
      'Damage:',
      '200-232',
      'Req Strength:',
      '44',
      'Req Dexterity:',
      '37',
      'Req Level:',
      '78',
      'Durability:',
      '35',
      '+1 to all Skill Levels',
      '+30% Faster Cast Rate',
      '+150% Enhanced Damage',
      '69 charges level 11 Teleport',
      'Adds 6-45 Lightning Damage',
      '+70 to Mana',
      '+35 to Energy',
      '40% increased Attack Speed',
      '50% Damage to Undead',
    ],
    category: "Naj's Ancient Vestige",
    id: 485,
    filename: 'najs_puzzler.gif',
    set_id: 27,
    category_id: 27,
    item_type: 'set',
  },
  {
    name: "Natalya's Totem",
    type: 'Grim Helm',
    image: 'http://www.d2tomb.com/images/setitems/natalyas_totem.gif',
    props: [
      'Defense:',
      '195-300',
      'Req Strength:',
      '58',
      'Req Level:',
      '59',
      'Durability:',
      '40',
      'Magic Damage reduced by 3',
      '+10-20 to all Resistances',
      '+135-175 to Defense',
      '+20-30 to Dexterity',
      '+10-20 to Strength',
    ],
    category: "Natalya's Odium",
    id: 486,
    filename: 'natalyas_totem.gif',
    set_id: 28,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Natalya's Mark",
    type: 'Scissors SuwayyahAssassin only',
    image: 'http://www.d2tomb.com/images/setitems/natalyas_mark.gif',
    props: [
      'Damage:',
      '120-153',
      'Req Strength:',
      '118',
      'Req Dexterity:',
      '118',
      'Req Level:',
      '79',
      'Durability:',
      '68',
      '+200% Damage to Undead',
      '+200% Damage to Demons',
      "Ignores Target's Defense",
      '+200% Enhanced Damage',
      '+50 Cold Damage for 4 seconds',
      'Adds 12-17 Fire Damage',
      '+40% increased Attack Speed',
    ],
    category: "Natalya's Odium",
    id: 487,
    filename: 'natalyas_mark.gif',
    set_id: 28,
    category_id: 1,
    item_type: 'set',
  },
  {
    name: "Natalya's Shadow",
    type: 'Loricated Mail',
    image: 'http://www.d2tomb.com/images/setitems/natalyas_shadow.gif',
    props: [
      'Defense:',
      '540-721',
      'Req Strength:',
      '149',
      'Req Level:',
      '73',
      'Durability:',
      '36',
      '+1 to Life per character level',
      'Poison duration reduced by 75%',
      '+25% Poison resist',
      '+150-225 to Defense',
      '1-3 sockets',
      '+2 to Shadow Disciplines Skills (Assassin only)',
    ],
    category: "Natalya's Odium",
    id: 488,
    filename: 'natalyas_shadow.gif',
    set_id: 28,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Natalya's Soul",
    type: 'Mesh Boots',
    image: 'http://www.d2tomb.com/images/setitems/natalyas_soul.gif',
    props: [
      'Defense:',
      '112-169',
      'Req Strength:',
      '65',
      'Req Level:',
      '25',
      'Durability:',
      '66',
      'Assassin Kick Damage:',
      '23-52',
      'Heal Stamina Plus (0.25 Per Character Level) 0-24% (Based On Character Level)',
      '+40% Faster Run/Walk',
      '+15-25% Cold Resist',
      '+15-25% Lightning Resist',
      '+75-125 to Defense',
    ],
    category: "Natalya's Odium",
    id: 489,
    filename: 'natalyas_soul.gif',
    set_id: 28,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Guillaume's Face",
    type: 'Winged Helm',
    image: 'http://www.d2tomb.com/images/setitems/guillaumes_face.gif',
    props: [
      'Defense:',
      '187-217',
      'Req Strength:',
      '115',
      'Req Level:',
      '34',
      'Durability:',
      '40',
      '15% Deadly Strike',
      '35% chance of Crushing Blow',
      '+30% Faster Hit Recovery',
      '+120% Enhanced Defense',
      '+15 to Strength',
    ],
    category: "Orphan's Call",
    id: 490,
    filename: 'guillaumes_face.gif',
    set_id: 29,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Whitstan's Guard",
    type: 'Round Shield',
    image: 'http://www.d2tomb.com/images/setitems/whitstans_guard.gif',
    props: [
      'Defense:',
      '154',
      'Req Strength:',
      '53',
      'Req Level:',
      '29',
      'Durability:',
      '64',
      'Half Freeze Duration',
      '92% Chance to Block',
      '40% Faster Block Rate',
      '+55% increased chance of Blocking',
      '+5 to Light Radius',
      '+175% Enhanced Defense',
    ],
    category: "Orphan's Call",
    id: 491,
    filename: 'whitstans_guard.gif',
    set_id: 29,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Magnus' Skin",
    type: 'Sharkskin Glove',
    image: 'http://www.d2tomb.com/images/setitems/magnus_skin.gif',
    props: [
      'Defense:',
      '60',
      'Req Strength:',
      '20',
      'Req Level:',
      '37',
      'Durability:',
      '14',
      '+3 to Light Radius',
      '+50% Enhanced Defense',
      '+15% Fire Resist',
      '+100 to Attack Rating',
      '+20% increased Attack Speed',
    ],
    category: "Orphan's Call",
    id: 492,
    filename: 'magnus_skin.gif',
    set_id: 29,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Wilhelm's Pride",
    type: 'Battle Belt',
    image: 'http://www.d2tomb.com/images/setitems/wilhelms_pride.gif',
    props: [
      'Defense:',
      '75',
      'Req Strength:',
      '88',
      'Req Level:',
      '42',
      'Durability:',
      '18',
      '5% Life stolen per hit',
      '5% Mana stolen per hit',
      '+75% Enhanced Defense',
      '+10% Cold Resist',
    ],
    category: "Orphan's Call",
    id: 493,
    filename: 'wilhelms_pride.gif',
    set_id: 29,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Sander's Paragon",
    type: 'Cap',
    image: 'http://www.d2tomb.com/images/setitems/sanders_paragon.gif',
    props: [
      'Defense:',
      '4-104',
      'Req Level:',
      '25',
      'Durability:',
      '12',
      '+1 per character level to Defense',
      'Attacker takes Damage of 8',
      '35% better chance of getting Magic items',
    ],
    category: "Sander's Folly",
    id: 494,
    filename: 'sanders_paragon.gif',
    set_id: 30,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Sander's Superstition",
    type: 'Bone Wand',
    image: 'http://www.d2tomb.com/images/setitems/sanders_superstition.gif',
    props: [
      'Damage:',
      '5-12',
      'Req Level:',
      '25',
      'Durability:',
      '15',
      '+75% Enhanced Damage',
      '8% Mana steal per hit',
      '20% Faster Cast Rate',
      '25-75 Cold Damage for 2 seconds',
      '+25 to Mana',
      '50% Damage to Undead',
    ],
    category: "Sander's Folly",
    id: 495,
    filename: 'sanders_superstition.gif',
    set_id: 30,
    category_id: 30,
    item_type: 'set',
  },
  {
    name: "Sander's Taboo",
    type: 'Heavy Gloves',
    image: 'http://www.d2tomb.com/images/setitems/sanders_taboo.gif',
    props: [
      'Defense:',
      '25-31',
      'Req Level:',
      '28',
      'Durability:',
      '14',
      'Adds 9-11 Poison Damage for 3 seconds',
      '+20-25 Defense',
      '+40 to Life',
      '+20% increased Attack Speed',
    ],
    category: "Sander's Folly",
    id: 496,
    filename: 'sanders_taboo.gif',
    set_id: 30,
    category_id: 15,
    item_type: 'set',
  },
  {
    name: "Sander's Riprap",
    type: 'Heavy Boots',
    image: 'http://www.d2tomb.com/images/setitems/sanders_riprap.gif',
    props: [
      'Defense:',
      '5-6',
      'Assassin Kick Damage:',
      '4-10',
      'Req Strength:',
      '18',
      'Req Level:',
      '20',
      'Durability:',
      '14',
      '40% Faster Run/Walk',
      '+100 to Attack Rating',
      '+10 to Dexterity',
      '+5 to Strength',
    ],
    category: "Sander's Folly",
    id: 497,
    filename: 'sanders_riprap.gif',
    set_id: 30,
    category_id: 16,
    item_type: 'set',
  },
  {
    name: "Sazabi's Mental Sheath",
    type: 'Basinet',
    image: 'http://www.d2tomb.com/images/setitems/sazabis_mental_sheath.gif',
    props: [
      'Defense:',
      '175-184',
      'Req Strength:',
      '82',
      'Req Level:',
      '43',
      'Durability:',
      '30',
      '+1 to all Skill levels',
      '+15-20% Lightning Resist',
      '+15-20% Fire Resist',
      '+100 Defense',
    ],
    category: "Sazabi's Grand Tribute",
    id: 498,
    filename: 'sazabis_mental_sheath.gif',
    set_id: 31,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Sazabi's Cobalt Redeemer",
    type: 'Cryptic Sword',
    image: 'http://www.d2tomb.com/images/setitems/sazabis_cobalt_redeemer.gif',
    props: [
      'Damage:',
      '12-192',
      'Req Strength:',
      '99',
      'Req Dexterity:',
      '109',
      'Req Level:',
      '73',
      'Indestructible',
      '+318% Damage to Demons',
      '+150% Enhanced Damage',
      'Adds 25-35 Cold Damage for 2 seconds',
      '+15 to Dexterity',
      '+5 to Strength',
      '+40% increased Attack Speed',
    ],
    category: "Sazabi's Grand Tribute",
    id: 499,
    filename: 'sazabis_cobalt_redeemer.gif',
    set_id: 31,
    category_id: 28,
    item_type: 'set',
  },
  {
    name: "Sazabi's Ghost Liberator",
    type: 'Balrog Skin',
    image: 'http://www.d2tomb.com/images/setitems/sazabis_ghost_liberator.gif',
    props: [
      'Defense:',
      '810-917',
      'Req Strength:',
      '165',
      'Req Level:',
      '67',
      'Durability:',
      '30',
      '+300 to Attack Rating against Demons',
      '+30% Faster Hit Recovery',
      '+400 to Defense',
      '+50-75 to Life',
      '+25 to Strength',
    ],
    category: "Sazabi's Grand Tribute",
    id: 500,
    filename: 'sazabis_ghost_liberator.gif',
    set_id: 31,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Tal Rasha's Lidless Eye",
    type: 'Swirling Crystal',
    image: 'http://www.d2tomb.com/images/setitems/talrashas_lidless_eye.gif',
    props: [
      'Damage:',
      ' 18-42',
      'Req Strength:',
      'Req Dexterity:',
      'Req Level:',
      '65',
      'Durability:',
      '50',
      '20% Faster Cast Rate',
      '+77 to Mana',
      '+57 to Life',
      '+10 to Energy',
      '+1-2 to Lightning Mastery (Sorceress only)',
      '+1-2 to Cold Mastery (Sorceress only)',
      '+1-2 to Fire Mastery (Sorceress only)',
      '+1 To Sorceress Skill Levels (2 Items)',
      '-15% To Enemy Fire Resistance (3 Items)',
      '-15% To Enemy Lightning Resistance (4 Items)',
      '+15% To Cold Skills Damage (Complete Set)',
    ],
    category: "Tal Rasha's Wrappings",
    id: 501,
    filename: 'talrashas_lidless_eye.gif',
    set_id: 32,
    category_id: 6,
    item_type: 'set',
  },
  {
    name: "Tal Rasha's Horadric Crest",
    type: 'Death Mask',
    image: 'http://www.d2tomb.com/images/setitems/talrashas_horadric_crest.gif',
    props: [
      'Defense:',
      '99-131',
      'Req Strength:',
      '55',
      'Req level:',
      '66',
      'Durability:',
      '20',
      '10% Life stolen per hit',
      '10% Mana stolen per hit',
      '+15 to all Resistances',
      '+45 to Defense',
      '+30 to Mana',
      '+60 to Life',
    ],
    category: "Tal Rasha's Wrappings",
    id: 502,
    filename: 'talrashas_horadric_crest.gif',
    set_id: 32,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Tal Rasha's Guardianship",
    type: 'Lacquered Plate',
    image: 'http://www.d2tomb.com/images/setitems/talrashas_guardianship.gif',
    props: [
      'Defense:',
      '833-941',
      'Req Strength:',
      '84',
      'Req Level:',
      '71',
      'Durability:',
      '55',
      '-60% to Requirements',
      'Magic damage reduced by 15',
      '+40% Cold Resist',
      '+40% Lightning Resist',
      '+40% Fire Resist',
      '+400 to Defense',
      '88% better chance of getting Magic items',
      '+10% Faster Cast Rate (2 Items)',
    ],
    category: "Tal Rasha's Wrappings",
    id: 503,
    filename: 'talrashas_guardianship.gif',
    set_id: 32,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Tal Rasha's Fine Spun Cloth",
    type: 'Mesh Belt',
    image: 'http://www.d2tomb.com/images/setitems/talrashas_fine_spun_cloth.gif',
    props: [
      'Defense:',
      '35-100',
      'Req Strength:',
      '47',
      'Req Level:',
      '53',
      'Durability:',
      '16',
      '37% damage taken goes to Mana',
      '-20% to Requirements',
      '+30 to Mana',
      '+20 to Dexterity',
      '10-15% better chance of Magic items',
      '+60 Defense (2 Items)',
      '+10% Faster Cast Rate (3 Items',
    ],
    category: "Tal Rasha's Wrappings",
    id: 504,
    filename: 'talrashas_fine_spun_cloth.gif',
    set_id: 32,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Tal Rasha's Adjudication",
    type: 'Amulet',
    image: 'http://www.d2tomb.com/images/setitems/amu2.gif',
    props: [
      'Req Level:',
      '67',
      '+2 to Sorceress Skill Levels',
      '+33% Lightning Resist',
      'Adds 3-32 Lightning Damage',
      '+42 to Mana',
      '+50 to Life',
      '+10% Faster Cast Rate (4 Items)',
    ],
    category: "Tal Rasha's Wrappings",
    id: 505,
    filename: 'amulet.gif',
    set_id: 32,
    category_id: 8,
    item_type: 'set',
  },
  {
    name: "Trang-Oul's Guise",
    type: 'Bone Visage',
    image: 'http://www.d2tomb.com/images/setitems/trangouls_guise.gif',
    props: [
      'Defense:',
      '180-257',
      'Req Strength:',
      '106',
      'Req Level:',
      '65',
      'Durability:',
      '40',
      '25% Faster Hit Recovery',
      '+5 Replenish Life',
      '+80-100 to Defense',
      '+150 to Mana',
      'Attack takes damage of 20',
    ],
    category: "Trang-Oul's Avatar",
    id: 506,
    filename: 'trangouls_guise.gif',
    set_id: 33,
    category_id: 12,
    item_type: 'set',
  },
  {
    name: "Trang-Oul's Scales",
    type: 'Chaos Armor',
    image: 'http://www.d2tomb.com/images/setitems/trangouls_scales.gif',
    props: [
      'Defense:',
      '857',
      'Req Strength:',
      '84',
      'Req Level:',
      '49',
      'Durability:',
      '70',
      '40% faster Run/Walk',
      '-40% to Requirements',
      '+150% Enhanced Defense',
      '+40% Poison Resist',
      '+100 Defense against Missiles',
      '+2 to Summoning Skills (Necro only)',
      'Lightning Resist +50% (3 Items)',
      'Damage Reduced By 25% (Complete Set)',
    ],
    category: "Trang-Oul's Avatar",
    id: 507,
    filename: 'trangouls_scales.gif',
    set_id: 33,
    category_id: 13,
    item_type: 'set',
  },
  {
    name: "Trang-Oul's Wing",
    type: 'Cantor Trophy',
    image: 'http://www.d2tomb.com/images/setitems/trangouls_wing.gif',
    props: [
      'Defense:',
      '175-189',
      'Req Strength:',
      '50',
      'Req Level:',
      '54',
      'Durability:',
      '20',
      '+30% increased chance of Blocking',
      '+40% Poison Resist',
      '+38-45% Fire Resist',
      '+125 to Defense',
      '+15 to Dexterity',
      '+25 to Strength',
      '+2 to Poison and Bone Skills (Necro only)',
      '-25% To Enemy Poison Resistance (3 Items)',
      'Replenish Life +15 (4 Items)',
    ],
    category: "Trang-Oul's Avatar",
    id: 508,
    filename: 'trangouls_wing.gif',
    set_id: 33,
    category_id: 5,
    item_type: 'set',
  },
  {
    name: "Trang-Oul's Girth",
    type: 'Troll Belt',
    image: 'http://www.d2tomb.com/images/setitems/trangouls_girth.gif',
    props: [
      'Defense:',
      '134-166',
      'Req Strength:',
      '91',
      'Req Level:',
      '62',
      'Durability:',
      '18',
      'Cannot be Frozen',
      '-40% to Requirements',
      '+5 Replenish Life',
      '+75-100 to Defense',
      '+25-50 to Mana',
      '+30 to maximum Stamina',
      '+66 to Life',
      'Cold Resist +40% (3 Items)',
    ],
    category: "Trang-Oul's Avatar",
    id: 509,
    filename: 'trangouls_girth.gif',
    set_id: 33,
    category_id: 17,
    item_type: 'set',
  },
  {
    name: "Trang-Oul's Claws",
    type: 'Heavy Bracers',
    image: 'http://www.d2tomb.com/images/setitems/trangouls_claws.gif',
    props: [
      'Defense:',
      '67-74',
      'Req Strength:',
      '58',
      'Req Level:',
      '45',
      'Durability:',
      '16',
      '+20% Faster Cast Rate',
      '+30% Cold Resist',
      '+30 to Defense',
      '+25% Poison Skill Damage',
      '+2 to Curses (Necro only)',
    ],
    category: "Trang-Oul's Avatar",
    id: 510,
    filename: 'trangouls_claws.gif',
    set_id: 33,
    category_id: 15,
    item_type: 'set',
  },
];

export const SET_BONUSES_24: SetBonus[] = [
  {
    partial: '+10 Dexterity (2 Items)\n' + '+50 Mana (3 Items)',
    complete:
      'Half Freeze Duration\n' +
      'All Resistances +25\n' +
      '40% Better Chance of Getting Magic Item\n' +
      'Regenerate Mana +8%\n' +
      '+10 Dexterity\n' +
      '+50 Mana',
    set_id: 0,
  },
  {
    partial: '+50 To Mana (2 Items)\n' + '+50 To Life (3 Items)\n' + 'Regenerate Mana 12% (3 Items)',
    complete: '+50 To Mana\n' + '+50 To Life\n' + '20% Faster Cast Rate\n' + '5% Mana Stolen Per Hit \n' + '+1 to All Skills',
    set_id: 1,
  },
  {
    partial: '+5 To Strength (2 Items)\n' + '+50 To Life (3 Items)\n',
    complete:
      '+5 To Strength\n' +
      '+50 To Life\n' +
      'Cannot Be Frozen\n' +
      '+2-198 to Maximum Cold Damage (+2 Per Character Level)\n' +
      'Cold Duration: 4 seconds',
    set_id: 2,
  },
  {
    partial: '+50 To Life (2 Items)',
    complete: '+50 To Life\n' + '+75 Defense\n' + 'Poison Length Reduced by 75%\n' + 'Adds 5-9 Poison Damage over 3 Seconds',
    set_id: 3,
  },
  {
    partial: '+15-20 Fire Damage (2 Items)\n' + 'Resist Lightning +25% (3 Items)\n' + 'Regenerate Mana +16% (2 Items)\n',
    complete:
      '+15-20 Fire Damage\n' +
      'Resist Lightning +25%\n' +
      '10% Faster Cast Rate\n' +
      'Magic Damage Reduced by 3\n' +
      'All Resistances +25\n' +
      '+60 to Attack Rating\n' +
      '+20 to Mana',
    set_id: 4,
  },
  {
    partial: 'Fire Resist +25% (2 Items)\n',
    complete:
      '+200% Damage to Undead\n' +
      '+15 To Strength\n' +
      'Fire Resist +25%\n' +
      'Lightning Resist +25%\n' +
      '25% Bonus to Attack Rating\n' +
      '4-396 Defense (+4 Per Character Level)\n' +
      '+50 Defense',
    set_id: 5,
  },
  {
    partial: '+50 Defense (2 Items)\n',
    complete: '+100 Defense\n' + '35% Chance of Crushing Blow\n' + '6% Mana Stolen Per Hit\n' + '20% Increased Attack Speed',
    set_id: 6,
  },
  {
    partial: '+8% Life Steal (2 Items)\n',
    complete: '+8% Life Steal\n' + '40% Bonus to Attack Rating\n' + 'All Resistances +25\n' + '+10 to Minimum Damage',
    set_id: 7,
  },
  {
    partial: 'Attacker Takes Damage of 5 (2 Items)\n',
    complete: 'Attacker Takes Damage of 5\n' + 'Cannot be Frozen\n' + 'Lightning Resist +25%\n' + '+5 to Max Damage',
    set_id: 8,
  },
  {
    partial: '+8 Poison Damage Over 3 Seconds (2 Items)\n',
    complete:
      '+8 Poison Damage Over 3 Seconds\n' +
      '20% Chance of Open Wounds\n' +
      '+1 to Necromancer Skill Levels\n' +
      '20% Bonus to Attack Rating\n' +
      'Increase Maximum Mana 20%\n' +
      '6% Mana Stolen Per Hit \n' +
      'Cannot Be Frozen',
    set_id: 9,
  },
  {
    partial: '+50 To Defense (2 Items)\n' + '20% Faster Run/Walk (3 Items) \n' + '+24% Piercing Attack (3 Items)\n',
    complete:
      '+50 To Defense\n' +
      '20% Faster Run/Walk\n' +
      '+15 To Dexterity\n' +
      '10% To Maximum Poison Resist\n' +
      '10% To Maximum Cold Resist\n' +
      '10% To Maximum Lightning Resist\n' +
      '10% To Maximum Fire Resist\n' +
      'All Resistances +20',
    set_id: 10,
  },
  {
    partial: '+10 To Strength (2 Items)\n' + '+10 To Dexterity (3 Items)',
    complete:
      '+10 To Strength\n' +
      '+10 To Dexterity\n' +
      '20% Faster Run/Walk\n' +
      '+30% Increased Chance of Blocking\n' +
      '35% Bonus to Attack Rating\n' +
      '5% Life Stolen Per Hit\n' +
      'All Resistances +10',
    set_id: 11,
  },
  {
    partial:
      '+75 To Attack Rating (2 Items)\n' +
      '+2-198 To Lightning Damage (+2 Per Character Level) (2 Items)\n' +
      '+125 To Attack Rating (3 Items) \n' +
      'Cannot Be Frozen (3 Items)\n',
    complete:
      '+200 To Attack Rating\n' +
      '+2 to Paladin Skill Levels\n' +
      '8% Life Stolen Per Hit\n' +
      '10% Mana Stolen Per Hit\n' +
      'Poison Resist +15%',
    set_id: 12,
  },
  {
    partial: '+10% Life Stolen Per Hit (2 Items)\n' + '+100 Defense (3 Items)',
    complete:
      '+10% Life Stolen Per Hit\n' +
      '+100 Defense\n' +
      '+20 To Mana\n' +
      'Fire Resist +12%\n' +
      '+24 To Max Fire Damage\n' +
      'Attacker Takes Damage of 12\n' +
      'Damage Reduced By 7',
    set_id: 13,
  },
  {
    partial: '+15 Lightning Damage (2 Items)\n' + '+5% Life Stolen Per Hit (3 Items)',
    complete:
      '+15 Lightning Damage\n' +
      '+5% Life Stolen Per Hit\n' +
      'Slows Targets by 35%\n' +
      '5% Mana Stolen Per Hit\n' +
      'All Resistances +10\n' +
      '75% Extra Gold From Monsters',
    set_id: 14,
  },
  {
    partial: '+75 To Attack Rating (2 Items)\n' + '+15 To Dexterity (3 Items)\n' + '7% Mana Stolen Per Hit (2 Items)',
    complete:
      '+75 To Attack Rating\n' +
      '+1-148 to Maximum Cold Damage (+1.5 Per Character Level)\n' +
      'Piercing Attack [50]\n' +
      'Freezes Target\n' +
      '+15 To Dexterity\n' +
      '+10 To Strength',
    set_id: 15,
  },
  {
    partial: '150% Bonus To Attack Rating (2 Items)\n' + '50% Better Chance Of Getting Magic Items (3 Items)',
    complete:
      '+3 To Druid Skills\n' +
      '+350% Enhanced Damage*\n' +
      '150% Bonus To Attack Rating\n' +
      '50% Better Chance Of Getting Magic Items\n' +
      '10% Mana Stolen Per Hit\n' +
      'All Resistances +50\n' +
      '+150 Defense\n' +
      '+150 To Mana\n' +
      'Display Aura',
    set_id: 16,
    notes:
      "The partial bonuses are listed multiple times because you get a partial bonus for each of the other items in the set. You receive +15 Energy on Aldur's Stony Gaze for equipping one other item. You receive +15 additional Energy (+30 total) when you have equipped two other items in the set. You get a total of +45 to Energy on Aldur's Stony Gaze if you complete the set.\n" +
      '\n' +
      'When you complete this set you get a special aura that, although it does not do anything, makes your character look unique.\n' +
      '\n' +
      "Aldur's Watchtower - The 350% Enhanced Damage is on the complete set, not on the weapon. Enhanced Damage from non-weapon equipment works like Enhanced Damage from skills and the strength bonus, which is different from how Enhanced Damage on the weapon works. Essentially, the 350% ED will add 350% of your weapon damage to your total. With a 60-93 damage weapon, that means the 350% Enhanced Damage will add 210-325 total damage. This is really not very significant, but it's not a bug, just a balance issue.\n" +
      '\n' +
      "Aldur's Rhythm The weapon gets 3 sockets 3/4 of the time and 2 sockets 1/4 of the time.",
    filename: 'Aldurs.jpg',
  },
  {
    partial: '',
    complete:
      '+2 To All Skill Levels\n' +
      '+200 To Attack Rating\n' +
      '+200% Damage To Demons\n' +
      '+200% Damage To Undead\n' +
      '+200 Fire Damage\n' +
      '10% Life Stolen Per Hit\n' +
      '+20% Deadly Strike\n' +
      '+200 Defense',
    set_id: 17,
  },
  {
    partial: 'Poison Resist +25% (2 items)\n' + '+5-495 Defense (+5 Per Character Level)',
    complete:
      'Poison Resist +25%\n' +
      '25% Chance To Cast Level 5 Static Field When Struck\n' +
      '+100 Maximum Stamina\n' +
      '+100 Defense\n' +
      '+100 to Life\n' +
      '+20 To Strength\n' +
      '+30% Increased Attack Speed\n' +
      '100% Better Chance Of Getting Magic Items\n' +
      '100% Extra Gold From Monsters\n' +
      '+1 To All Skills',
    set_id: 18,
    notes: 'This Set can only be collected in the Cow Level.',
  },
  {
    partial: '',
    complete: '',
    set_id: 19,
  },
  {
    partial: '',
    complete: '',
    set_id: 20,
  },
  {
    partial: '+150 Defense (2 Items)\n' + '+22 Poison Damage Over 3 Seconds (3 Items)*\n' + '+10 To Strength (4 Items)',
    complete:
      '+150 Defense\n' +
      '+22 Poison Damage Over 3 Seconds*\n' +
      '+10 To Strength\n' +
      '+2 To All Skills\n' +
      'All Resistances +50\n' +
      '+100 Mana',
    set_id: 21,
    notes:
      'If you have any other sources of poison damage (including the damage on the Dark Adherent), the partial/complete set bonus will show some other, inaccurate value.\n' +
      '\n',
  },
  {
    partial: '+20 To Strength (2 Items)\n' + '+30 To Dexterity (3 Items)',
    complete:
      '+3 To Paladin Skill Levels\n' +
      '+30% Faster Hit Recovery\n' +
      '+200 To Attack Rating\n' +
      '+20 To Strength\n' +
      '+30 To Dexterity\n' +
      '+150 To Life\n' +
      'All Resistances +50\n' +
      'Display Aura',
    set_id: 22,
    filename: "Griswold's_Legacy.gif",
  },
  {
    partial: 'Heal Stamina Plus 50% (2 Items)\n' + 'Replenish Life +20 (3 Items)',
    complete:
      '+2 To All Skills\n' +
      'Replenish Life +20\n' +
      'Heal Stamina Plus 50%\n' +
      'All Resistances +50\n' +
      'Cannot Be Frozen\n' +
      '+5 To Light Radius',
    set_id: 23,
  },
  {
    partial: '+100 Defense (2 Items)\n' + '+200 Defense (3 Items)',
    complete: '+2 To All Skills\n' + '+30% Faster Run/Walk\n' + '20% Life Stolen Per Hit\n' + '+300 Defense\n' + 'All Resistances +30',
    set_id: 24,
  },
  {
    partial:
      '+50 To Attack Rating (2 Items)\n' +
      'Additional +75 To Attack Rating (3 Items)\n' +
      'Additional +125 To Attack Rating (4 Items)\n' +
      'Additional +200 To Attack Rating (5 Items)',
    complete:
      '+3 To Barbarian Skill Levels\n' +
      '+450 To Attack Rating\n' +
      '+150 To Life\n' +
      'All Resistances +50\n' +
      'Magic Damage Reduced By 10\n' +
      'Display Aura',
    set_id: 25,
    filename: 'Full_IK.JPG.jpg',
  },
  {
    partial: '+20 To Strength (2 Items)\n' + '+30 To Dexterity (3 Items)',
    complete:
      '+3 To Amazon Skill Levels\n' +
      '+20 To Strength\n' +
      '+30 To Dexterity\n' +
      'All Resistances +50\n' +
      '+100 Defense\n' +
      '+100 To Attack Rating\n' +
      '100% Better Chance Of Getting Magic Items\n' +
      'Display Aura',
    set_id: 26,
    filename: 'Mav_set.jpg',
  },
  {
    partial: '+175 Defense (2 Items) \n' + '+1-148% Better Chance of Getting Magic Items (+1.5 Per Character Level) (2 Items)',
    complete:
      '+175 Defense\n' +
      '+15 To Dexterity\n' +
      'All Resistances +50\n' +
      '+20 To Strength\n' +
      '+100 To Mana\n' +
      'Replenish Life +20\n' +
      '+2 To Fire Skills \n' +
      'Maximum Life +12%\n' +
      '+1 To All Skills',
    set_id: 27,
  },
  {
    partial: 'Magic Damage Reduced By 15 (2 Items)\n' + '+200 Defense (3 Items)',
    complete:
      '3 To Assassin Skill Levels\n' +
      '+350 Defense\n' +
      '14% Life Stolen Per Hit\n' +
      '14% Mana Stolen Per Hit\n' +
      'All Resistances +50\n' +
      'Damage Reduced by 30% (Awesome!)\n' +
      'Magic Damage Reduced By 15\n' +
      'Fade*',
    set_id: 28,
    filename: "Natalya's_Odium.jpg",
  },
  {
    partial: '+35 Life (2 Items)\n' + 'Attacker Takes Damage of 5 (3 Items)',
    complete:
      '+85 To Life\n' +
      'Attacker Takes Damage of 5\n' +
      'All Resistances +15\n' +
      '+100 Defense\n' +
      '+10 To Dexterity\n' +
      '+20 To Strength\n' +
      '80% Better Chance of Getting Magic Items',
    set_id: 29,
  },
  {
    partial: '+50 Defense (2 Items)\n' + '+75 To Attack Rating (3 Items)',
    complete:
      '+50 Defense\n' +
      '+75 To Attack Rating\n' +
      '+1 To All Skills\n' +
      '4% Life Stolen Per Hit\n' +
      '+50 To Mana\n' +
      '50% Better Chance Of Getting Magic Items',
    set_id: 30,
  },
  {
    partial: '+40% Faster Run/Walk (2 Items)\n' + 'Poison Length Reduced by 75% (2 Items)',
    complete:
      '+40% Faster Run/Walk\n' +
      '15% Life Stolen Per Hit\n' +
      'Increase Maximum Life 27%\n' +
      'All Resistances +30\n' +
      'Damage Reduced by 16%\n' +
      '+1 To All Skills (Full Set)\n',
    set_id: 31,
  },
  {
    partial:
      'Replenish Life +10 (2 Items)\n' + '65% Better Chance Of Getting Magic Items (3 Items)\n' + '25% Faster Hit Recovery (4 Items)',
    complete:
      '+3 To Sorceress Skill Levels\n' +
      'Replenish Life +10\n' +
      '65% Better Chance Of Getting Magic Items\n' +
      '25% Faster Hit Recovery\n' +
      '+150 To Life\n' +
      'All Resistances +50\n' +
      '+50 Defense vs. Missile\n' +
      '+150 Defense\n' +
      'Display Aura',
    set_id: 32,
    filename: "Tal_Rasha's_Wrappings.jpg",
  },
  {
    partial:
      '+18 To Fire Ball (2 Items)*\n' +
      'Regenerate Mana 15% (2 Items)\n' +
      '+13 To Fire Wall (3 Items)\n' +
      'Additional Regenerate Mana 15% (3 Items)\n' +
      'Additional Regenerate Mana 15% (4 Items)\n' +
      '+10 To Meteor (4 Items)',
    complete:
      '+3 To Necromancer Skill Levels\n' +
      '20% Life Stolen Per Hit\n' +
      '+3 To Fire Mastery\n' +
      '+10 To Meteor\n' +
      '+13 To Fire Wall\n' +
      '+18 To Fire Ball*\n' +
      '+200 Defense\n' +
      '+100 To Mana\n' +
      'Regenerate Mana 60%\n' +
      'All Resistances +50\n' +
      'Transforms into Vampire',
    set_id: 33,
    notes:
      '*Players that have the Fire Ball or Fire Wall skills (Sorceresses) have a cap of +3 on those skills rather than the full number.',
    filename: 's_Avatar_set.JPG.jpg',
  },
];

import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useAppSelector } from '../../store/store';
import { selectorIsD2r } from '../../store/settings/selectors';
import { IRune } from '../types';
import { ALL_RUNES } from '../data/runes/all_runes';
import { RuneContext } from '../providers/RuneProvider/RuneProvider';

interface Props {
  id: number;
  size: string;
  withName?: boolean;
  withCount?: boolean;
  forceInStock?: boolean;
  withProps?: boolean;
}

export const Rune: React.FC<Props> = ({ id, size, withName, forceInStock, withCount, withProps }) => {
  const [r, setR] = useState<IRune>();
  const isD2r = useAppSelector(selectorIsD2r);
  const { runes } = useContext(RuneContext);

  useEffect(() => {
    const res = ALL_RUNES.find(rn => rn.id === id);
    setR(res);
  }, [id]);

  const inStock = useMemo(() => {
    if (forceInStock) return true;
    const count = runes.find(rune => rune.rune_id === r?.id)?.count ?? 0;
    return count > 0;
  }, [forceInStock, runes, r]);

  return (
    <Wrapper>
      <Img
        size={size}
        inStock={inStock}
        src={`/images/item-images/${
          isD2r ? `d2r/runes/${r?.name.toLocaleLowerCase() + '.png' ?? ''}` : `runes/${r?.name.toLocaleLowerCase() + '.gif' ?? ''}`
        }`}
      />
      {(withName || withCount) && (
        <InfoContainer className={'rune'}>
          {withName && <span>{r?.name}</span>} {withCount && <span>({runes.find(v => v.rune_id === r?.id)?.count ?? 0})</span>}
        </InfoContainer>
      )}

      {withProps && (
        <PropsWrapper>
          <div>Armors</div>
          <div>{r?.props_armor}</div>
          <div>Weapons</div>
          <div>{r?.props_weapon}</div>
        </PropsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Img = styled.div<{ src: string; size: string; inStock: boolean }>`
  width: ${props => props.size};
  height: ${props => props.size};
  filter: ${props => (props.inStock ? 'none' : 'grayscale(100%) brightness(60%)')};

  &:before {
    content: '';
    width: ${props => props.size};
    height: ${props => props.size};
    background-image: url('${props => props.src}');
    background-size: cover;
    display: inline-block;
  }
`;

const InfoContainer = styled.div`
  white-space: nowrap;
  font-size: 14px;
`;

const PropsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > div:nth-of-type(odd) {
    color: var(--base);
  }

  > div:nth-of-type(even) {
    color: var(--magic);
  }
`;

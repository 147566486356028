export const JUNK_ITEM_CODES = [
  'apot',
  'hpot',
  'mpot',
  'rpot',
  'bowq',
  'xboq',
  'scro',
  'gema',
  'gems',
  'gemt',
  'gemr',
  'gemd',
  'geme',
  'gemz',
  'gld',
  'tpot',
  'gold',
  'key',
  'wpot',
  'amul',
  'ring',
];

export const dropCalcBaseItems: [
  string,
  number,
  string,
  string,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number[],
  number[],
  number,
][] = [
  ['Aegis', 3, 'shie', 'uow', 79, 1, 0, 0, 1, 3, 3, 4, 0, 1, [190, 0], [0], 0], // 0
  ['Aerin Shield', 1, 'ashd', 'pa4', 20, 2, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 1
  ['Akaran Rondache', 2, 'ashd', 'pa7', 40, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 2
  ['Akaran Targe', 2, 'ashd', 'pa6', 35, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 3
  ['Alpha Helm', 2, 'pelt', 'dr6', 35, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 4
  ['Amethyst', 0, 'gema', 'gsv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 5
  ['Amn Rune', 0, 'rune', 'r11', 25, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 6
  [
    'Amulet',
    0,
    'amul',
    'amu',
    1,
    0,
    0,
    1,
    1,
    0,
    0,
    0,
    0,
    0,
    [10, 63, 150, 188, 192, 199, 240, 242, 303, 309, 322, 329, 0],
    [7, 11, 24, 27, 64, 105, 114, 115, 124, 0],
    0,
  ], // 7
  ['Ancient Armor', 1, 'tors', 'aar', 40, 0, 0, 0, 1, 3, 4, 4, 0, 1, [248, 0], [80, 0], 0], // 8
  ['Ancient Axe', 2, 'axe', '9gi', 51, 1, 0, 0, 1, 4, 5, 6, 0, 1, [324, 0], [0], 0], // 9
  ['Ancient Shield', 2, 'shie', 'xts', 56, 1, 0, 0, 1, 3, 3, 3, 0, 1, [212, 0], [0], 0], // 10
  ['Ancient Sword', 2, 'swor', '9wd', 49, 1, 0, 0, 1, 3, 3, 3, 0, 1, [301, 0], [0], 0], // 11
  ['Antidote Potion', 0, 'apot', 'yps', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 12
  ['Antlers', 1, 'pelt', 'dr3', 16, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 13
  ['Arbalest', 2, 'xbow', '8lx', 34, 1, 0, 0, 1, 3, 3, 3, 0, 0, [174, 0], [0], 0], // 14
  ['Archon Plate', 3, 'tors', 'utp', 84, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 15
  ['Archon Staff', 3, 'staf', '6ws', 85, 1, 0, 0, 1, 5, 6, 6, 1, 1, [187, 0], [0], 0], // 16
  ['Armet', 3, 'helm', 'ulm', 68, 1, 0, 0, 1, 2, 2, 2, 0, 1, [276, 0], [0], 0], // 17
  ['Arrows', 0, 'bowq', 'aqv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 18
  ['Ashwood Bow', 2, 'abow', 'am6', 39, 3, 0, 0, 1, 3, 4, 5, 0, 0, [0], [0], 0], // 19
  ['Assault Helmet', 1, 'phlm', 'ba4', 20, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 20
  ['Ataghan', 3, 'swor', '7sm', 61, 1, 0, 0, 1, 2, 2, 2, 0, 1, [84, 0], [0], 0], // 21
  ['Avenger Guard', 1, 'phlm', 'ba5', 24, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [59, 0], 0], // 22
  ['Axe', 1, 'axe', 'axe', 7, 0, 0, 0, 1, 4, 4, 4, 0, 1, [78, 0], [0], 0], // 23
  ['Balanced Axe', 1, 'taxe', 'bal', 16, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 24
  ['Balanced Knife', 1, 'tkni', 'bkf', 13, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 25
  ['Ballista', 2, 'xbow', '8hx', 47, 1, 0, 0, 1, 3, 4, 6, 0, 0, [47, 0], [0], 0], // 26
  ['Balrog Blade', 3, 'swor', '7gs', 71, 1, 0, 0, 1, 3, 4, 4, 0, 1, [101, 0], [0], 0], // 27
  ['Balrog Skin', 3, 'tors', 'upl', 76, 1, 0, 0, 1, 3, 4, 4, 0, 1, [5, 0], [96, 0], 0], // 28
  ['Balrog Spear', 3, 'jave', '7s7', 71, 1, 0, 0, 1, 0, 0, 0, 0, 1, [81, 0], [0], 0], // 29
  ['Barbed Club', 2, 'club', '9sp', 32, 1, 0, 0, 1, 3, 3, 3, 0, 1, [102, 0], [0], 0], // 30
  ['Barbed Shield', 2, 'shie', 'xpk', 42, 1, 0, 0, 1, 2, 2, 2, 0, 1, [172, 0], [0], 0], // 31
  ['Bardiche', 1, 'pole', 'bar', 5, 0, 0, 0, 1, 3, 3, 3, 0, 1, [83, 0], [0], 0], // 32
  ['Basinet', 2, 'helm', 'xhl', 45, 1, 0, 0, 1, 2, 2, 2, 0, 1, [73, 0], [97, 0], 0], // 33
  ['Bastard Sword', 1, 'swor', 'bsw', 24, 0, 0, 0, 1, 3, 4, 4, 0, 1, [25, 0], [0], 0], // 34
  ['Battle Axe', 1, 'axe', 'btx', 17, 0, 0, 0, 1, 4, 5, 5, 0, 1, [305, 0], [0], 0], // 35
  ['Battle Belt', 2, 'belt', 'ztb', 49, 1, 0, 0, 1, 0, 0, 0, 0, 1, [258, 0], [126, 0], 0], // 36
  ['Battle Boots', 2, 'boot', 'xtb', 49, 1, 0, 0, 1, 0, 0, 0, 0, 1, [360, 0], [0, 0], 0], // 37
  ['Battle Cestus', 3, 'h2h2', '7cs', 73, 3, 0, 0, 1, 2, 2, 2, 0, 1, [245, 0], [0], 0], // 38
  ['Battle Dart', 2, 'tkni', '9tk', 31, 1, 0, 0, 1, 0, 0, 0, 0, 1, [77, 0], [0], 0], // 39
  ['Battle Gauntlets', 2, 'glov', 'xtg', 49, 1, 0, 0, 1, 0, 0, 0, 0, 1, [175, 0], [74, 0], 0], // 40
  ['Battle Hammer', 2, 'hamm', '9wh', 48, 1, 0, 0, 1, 3, 4, 4, 0, 1, [94, 0], [0], 0], // 41
  ['Battle Scythe', 2, 'pole', '9s8', 40, 1, 0, 0, 1, 3, 4, 5, 0, 1, [9, 0], [0], 0], // 42
  ['Battle Staff', 1, 'staf', 'bst', 17, 0, 0, 0, 1, 4, 4, 4, 1, 1, [330, 0], [22, 0], 0], // 43
  ['Battle Sword', 2, 'swor', '9bs', 40, 1, 0, 0, 1, 3, 4, 4, 0, 1, [138, 0], [0], 0], // 44
  ['Bearded Axe', 2, 'axe', '9ba', 38, 1, 0, 0, 1, 4, 5, 5, 0, 1, [265, 0], [0], 0], // 45
  ['Bec-de-Corbin', 2, 'pole', '9h9', 51, 1, 0, 0, 1, 3, 4, 6, 0, 1, [157, 0], [0], 0], // 46
  ['Belt', 1, 'belt', 'mbl', 12, 0, 0, 0, 1, 0, 0, 0, 0, 1, [197, 0], [49, 50, 0], 0], // 47
  ['Ber Rune', 0, 'rune', 'r30', 63, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 48
  ['Berserker Axe', 3, 'axe', '7wa', 85, 1, 0, 0, 1, 4, 5, 6, 0, 1, [74, 0], [0], 0], // 49
  ['Bill', 2, 'pole', '9vo', 37, 1, 0, 0, 1, 3, 4, 4, 0, 1, [23, 0], [51, 0], 0], // 50
  ['Blade', 1, 'knif', 'bld', 23, 0, 0, 0, 1, 2, 2, 2, 0, 1, [264, 0], [0], 0], // 51
  ['Blade Barrier', 3, 'shie', 'upk', 68, 1, 0, 0, 1, 3, 3, 3, 0, 1, [266, 0], [0], 0], // 52
  ['Blade Bow', 3, 'bow', '6hb', 60, 1, 0, 0, 1, 3, 4, 4, 0, 0, [0], [0], 0], // 53
  ['Blade Talons', 1, 'h2h', 'btl', 21, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 54
  ['Blood Spirit', 3, 'pelt', 'drb', 62, 3, 0, 0, 1, 2, 3, 3, 0, 1, [52, 0], [0], 0], // 55
  ['Bloodlord Skull', 3, 'head', 'nef', 85, 3, 0, 0, 1, 2, 2, 2, 0, 1, [71, 0], [0], 0], // 56
  ['Bolts', 0, 'xboq', 'cqv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 57
  ['Bone Helm', 1, 'helm', 'bhm', 22, 0, 0, 0, 1, 2, 2, 2, 0, 1, [375, 0], [112, 0], 0], // 58
  ['Bone Knife', 3, 'knif', '7dg', 58, 1, 0, 0, 1, 1, 1, 1, 0, 1, [371, 0], [0], 0], // 59
  ['Bone Shield', 1, 'shie', 'bsh', 19, 0, 0, 0, 1, 2, 2, 2, 0, 1, [359, 0], [0], 0], // 60
  ['Bone Visage', 3, 'helm', 'uh9', 84, 1, 0, 0, 1, 2, 2, 3, 0, 1, [111, 0], [118, 0], 0], // 61
  ['Bone Wand', 1, 'wand', 'bwn', 18, 0, 0, 0, 1, 2, 2, 2, 1, 1, [123, 0], [93, 0], 0], // 62
  ['Boneweave', 3, 'tors', 'uhn', 62, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 63
  ['Boneweave Boots', 3, 'boot', 'umb', 72, 1, 0, 0, 1, 0, 0, 0, 0, 1, [189, 0], [0], 0], // 64
  ['Boots', 1, 'boot', 'lbt', 3, 0, 0, 0, 1, 0, 0, 0, 0, 1, [154, 0], [111, 0], 0], // 65
  ['Bramble Mitts', 3, 'glov', 'ulg', 57, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [71, 0], 0], // 66
  ['Brandistock', 1, 'spea', 'brn', 16, 0, 0, 0, 1, 3, 4, 5, 0, 1, [37, 0], [0], 0], // 67
  ['Breast Plate', 1, 'tors', 'brs', 18, 0, 0, 0, 1, 3, 3, 3, 0, 1, [355, 0], [67, 0], 0], // 68
  ['Broad Axe', 1, 'axe', 'bax', 12, 0, 0, 0, 1, 4, 5, 5, 0, 1, [122, 0], [0], 0], // 69
  ['Broad Sword', 1, 'swor', 'bsd', 15, 0, 0, 0, 1, 3, 4, 4, 0, 1, [128, 0], [69, 0], 0], // 70
  ['Buckler', 1, 'shie', 'buc', 1, 0, 0, 0, 1, 1, 1, 1, 0, 1, [205, 0], [47, 0], 0], // 71
  ['Burnt Wand', 2, 'wand', '9wn', 31, 1, 0, 0, 1, 1, 1, 1, 1, 1, [294, 0], [0], 0], // 72
  ['Caduceus', 3, 'scep', '7ws', 85, 1, 0, 0, 1, 3, 5, 5, 0, 1, [8, 0], [43, 0], 0], // 73
  ['Cantor Trophy', 2, 'head', 'ne9', 49, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [120, 0], 0], // 74
  ['Cap', 1, 'helm', 'cap', 1, 0, 0, 0, 1, 2, 2, 2, 0, 1, [17, 0], [60, 91, 0], 0], // 75
  ['Carnage Helm', 3, 'phlm', 'bab', 60, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 76
  ['Casque', 2, 'helm', 'xlm', 42, 1, 0, 0, 1, 2, 2, 2, 0, 1, [273, 0], [0], 0], // 77
  ['Cedar Bow', 2, 'bow', '8lb', 35, 1, 0, 0, 1, 3, 4, 5, 0, 0, [170, 0], [0], 0], // 78
  ['Cedar Staff', 2, 'staf', '8cs', 38, 1, 0, 0, 1, 4, 4, 4, 1, 1, [54, 0], [0], 0], // 79
  ['Ceremonial Bow', 2, 'abow', 'am7', 47, 3, 0, 0, 1, 3, 4, 5, 0, 0, [181, 0], [0], 0], // 80
  ['Ceremonial Javelin', 2, 'ajav', 'ama', 35, 3, 0, 0, 1, 0, 0, 0, 0, 1, [340, 0], [0], 0], // 81
  ['Ceremonial Pike', 2, 'aspe', 'am9', 51, 3, 0, 0, 1, 3, 4, 6, 0, 1, [182, 0], [0], 0], // 82
  ['Ceremonial Spear', 2, 'aspe', 'am8', 43, 3, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 83
  ['Cestus', 1, 'h2h', 'ces', 15, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 84
  ['Chain Boots', 1, 'boot', 'mbt', 12, 0, 0, 0, 1, 0, 0, 0, 0, 1, [345, 0], [48, 0], 0], // 85
  ['Chain Gloves', 1, 'glov', 'mgl', 12, 0, 0, 0, 1, 0, 0, 0, 0, 1, [53, 0], [30, 0], 0], // 86
  ['Chain Mail', 1, 'tors', 'chn', 15, 0, 0, 0, 1, 2, 2, 2, 0, 1, [263, 0], [21, 0], 0], // 87
  ['Cham Rune', 0, 'rune', 'r32', 67, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 88
  ['Champion Axe', 3, 'axe', '7ga', 82, 1, 0, 0, 1, 4, 5, 6, 0, 1, [191, 0], [0], 0], // 89
  ['Champion Sword', 3, 'swor', '7b7', 77, 1, 0, 0, 1, 3, 4, 4, 0, 1, [85, 0], [0], 0], // 90
  ['Chaos Armor', 2, 'tors', 'xul', 61, 1, 0, 0, 1, 3, 4, 4, 0, 1, [19, 0], [119, 0], 0], // 91
  ['Chipped Amethyst', 0, 'gema', 'gcv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 92
  ['Chipped Diamond', 0, 'gemd', 'gcw', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 93
  ['Chipped Emerald', 0, 'geme', 'gcg', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 94
  ['Chipped Ruby', 0, 'gemr', 'gcr', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 95
  ['Chipped Sapphire', 0, 'gems', 'gcb', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 96
  ['Chipped Skull', 0, 'gemz', 'skc', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 97
  ['Chipped Topaz', 0, 'gemt', 'gcy', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 98
  ['Choking Gas Potion', 1, 'tpot', 'gpm', 20, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 99
  ['Chu-Ko-Nu', 2, 'xbow', '8rx', 54, 1, 0, 0, 1, 3, 4, 5, 0, 0, [80, 0], [0], 0], // 100
  ['Cinquedeas', 2, 'knif', '9kr', 42, 1, 0, 0, 1, 2, 3, 3, 0, 1, [20, 0], [0], 0], // 101
  ['Circlet', 1, 'circ', 'ci0', 24, 0, 0, 0, 1, 1, 2, 2, 3, 1, [0], [82, 0], 0], // 102
  ['Clasped Orb', 1, 'orb', 'ob4', 17, 2, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 103
  ['Claws', 1, 'h2h', 'clw', 18, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 104
  ['Claymore', 1, 'swor', 'clm', 17, 0, 0, 0, 1, 3, 4, 4, 0, 1, [262, 0], [0], 0], // 105
  ['Cleaver', 2, 'axe', '9ax', 34, 1, 0, 0, 1, 4, 4, 4, 0, 1, [48, 0], [0], 0], // 106
  ['Cloudy Sphere', 2, 'orb', 'ob8', 41, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 107
  ['Club', 1, 'club', 'clb', 1, 0, 0, 0, 1, 2, 2, 2, 0, 1, [99, 0], [0], 0], // 108
  ['Colossus Blade', 3, 'swor', '7gd', 85, 1, 0, 0, 1, 3, 4, 6, 0, 1, [316, 0], [19, 0], 0], // 109
  ['Colossus Crossbow', 3, 'xbow', '6hx', 75, 1, 0, 0, 1, 3, 4, 6, 0, 0, [146, 0], [0], 0], // 110
  ['Colossus Girdle', 3, 'belt', 'uhc', 85, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 111
  ['Colossus Sword', 3, 'swor', '7fb', 80, 1, 0, 0, 1, 3, 4, 5, 0, 1, [0], [0], 0], // 112
  ['Colossus Voulge', 3, 'pole', '7vo', 64, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 113
  ['Composite Bow', 1, 'bow', 'cbw', 12, 0, 0, 0, 1, 3, 4, 4, 0, 0, [236, 0], [0], 0], // 114
  ['Conqueror Crown', 3, 'phlm', 'bae', 80, 3, 0, 0, 1, 2, 3, 3, 0, 1, [133, 0], [0], 0], // 115
  ['Conquest Sword', 3, 'swor', '7bs', 78, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 116
  ['Corona', 3, 'helm', 'urn', 85, 1, 0, 0, 1, 2, 2, 3, 0, 1, [65, 0], [44, 0], 0], // 117
  ['Coronet', 1, 'circ', 'ci1', 52, 0, 0, 0, 1, 1, 2, 2, 8, 1, [0], [0], 0], // 118
  ['Crossbow', 1, 'xbow', 'mxb', 15, 0, 0, 0, 1, 3, 4, 4, 0, 0, [159, 0], [0], 0], // 119
  ['Crowbill', 2, 'axe', '9mp', 43, 1, 0, 0, 1, 4, 5, 6, 0, 1, [209, 0], [0], 0], // 120
  ['Crown', 1, 'helm', 'crn', 29, 0, 0, 0, 1, 2, 2, 3, 0, 1, [350, 0], [63, 78, 0], 0], // 121
  ['Crown Shield', 1, 'ashd', 'pa5', 24, 2, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 122
  ['Crusader Bow', 3, 'bow', '6l7', 77, 1, 0, 0, 1, 3, 4, 6, 0, 0, [92, 0], [0], 0], // 123
  ['Crusader Gauntlets', 3, 'glov', 'utg', 76, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 124
  ['Cryptic Axe', 3, 'pole', '7pa', 79, 1, 0, 0, 1, 3, 4, 5, 0, 1, [342, 0], [0], 0], // 125
  ['Cryptic Sword', 3, 'swor', '7ls', 82, 1, 0, 0, 1, 3, 4, 4, 0, 1, [105, 0], [95, 0], 0], // 126
  ['Crystal Sword', 1, 'swor', 'crs', 11, 0, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 127
  ['Crystalline Globe', 2, 'orb', 'ob7', 37, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 128
  ['Cudgel', 2, 'club', '9cl', 30, 1, 0, 0, 1, 2, 2, 2, 0, 1, [70, 0], [0], 0], // 129
  ['Cuirass', 2, 'tors', 'xrs', 47, 1, 0, 0, 1, 3, 3, 3, 0, 1, [89, 0], [46, 0], 0], // 130
  ['Cutlass', 2, 'swor', '9sm', 43, 1, 0, 0, 1, 2, 2, 2, 0, 1, [59, 0], [0], 0], // 131
  ['Dacian Falx', 2, 'swor', '9cm', 42, 1, 0, 0, 1, 3, 4, 4, 0, 1, [18, 0], [0], 0], // 132
  ['Dagger', 1, 'knif', 'dgr', 3, 0, 0, 0, 1, 1, 1, 1, 0, 1, [131, 0], [0], 0], // 133
  ['Death Mask', 2, 'helm', 'xsk', 48, 1, 0, 0, 1, 2, 2, 3, 0, 1, [22, 0], [108, 0], 0], // 134
  ['Decapitator', 3, 'axe', '7bt', 73, 1, 0, 0, 1, 4, 5, 5, 0, 1, [147, 0], [0], 0], // 135
  ['Defender', 2, 'shie', 'xuc', 34, 1, 0, 0, 1, 1, 1, 1, 0, 1, [358, 0], [0], 0], // 136
  ['Demon Crossbow', 3, 'xbow', '6rx', 84, 1, 0, 0, 1, 3, 4, 5, 0, 0, [132, 0], [0], 0], // 137
  ['Demon Head', 1, 'head', 'ne5', 24, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 138
  ['Demon Heart', 3, 'orb', 'obd', 75, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 139
  ['Demonhead', 3, 'helm', 'usk', 74, 1, 0, 0, 1, 2, 2, 3, 0, 1, [1, 0], [0], 0], // 140
  ['Demonhide Armor', 2, 'tors', 'xla', 37, 1, 0, 0, 1, 2, 2, 2, 0, 1, [251, 0], [0], 0], // 141
  ['Demonhide Boots', 2, 'boot', 'xlb', 36, 1, 0, 0, 1, 0, 0, 0, 0, 1, [160, 0], [90, 0], 0], // 142
  ['Demonhide Gloves', 2, 'glov', 'xlg', 33, 1, 0, 0, 1, 0, 0, 0, 0, 1, [354, 0], [0], 0], // 143
  ['Demonhide Sash', 2, 'belt', 'zlb', 36, 1, 0, 0, 1, 0, 0, 0, 0, 1, [293, 0], [0], 0], // 144
  ['Destroyer Helm', 3, 'phlm', 'bad', 73, 3, 0, 0, 1, 2, 3, 3, 0, 1, [82, 0], [0], 0], // 145
  ['Devil Star', 3, 'mace', '7mt', 70, 1, 0, 0, 1, 3, 3, 3, 0, 1, [16, 0], [0], 0], // 146
  ['Diadem', 3, 'circ', 'ci3', 85, 1, 0, 0, 1, 1, 2, 3, 18, 1, [126, 0], [76, 0], 0], // 147
  ['Diamond', 0, 'gemd', 'gsw', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 148
  ['Diamond Bow', 3, 'bow', '6s7', 72, 1, 0, 0, 1, 3, 4, 5, 0, 0, [0], [0], 0], // 149
  ['Diamond Mail', 3, 'tors', 'ung', 72, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 150
  ['Dimensional Blade', 2, 'swor', '9cr', 37, 1, 0, 0, 1, 3, 4, 6, 0, 1, [113, 0], [0], 0], // 151
  ['Dimensional Shard', 3, 'orb', 'obf', 85, 3, 0, 0, 1, 2, 3, 3, 1, 1, [75, 0], [0], 0], // 152
  ['Dirk', 1, 'knif', 'dir', 9, 0, 0, 0, 1, 1, 1, 1, 0, 1, [307, 0], [0], 0], // 153
  ['Divine Scepter', 2, 'scep', '9ws', 45, 1, 0, 0, 1, 3, 5, 5, 0, 1, [134, 0], [0], 0], // 154
  ['Dol Rune', 0, 'rune', 'r14', 31, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 155
  ['Double Axe', 1, 'axe', '2ax', 13, 0, 0, 0, 1, 4, 5, 5, 0, 1, [27, 0], [16, 0], 0], // 156
  ['Double Bow', 2, 'bow', '8cb', 39, 1, 0, 0, 1, 3, 4, 4, 0, 0, [95, 0], [0], 0], // 157
  ['Dragon Shield', 2, 'shie', 'xit', 45, 1, 0, 0, 1, 3, 3, 3, 0, 1, [339, 0], [0], 0], // 158
  ['Dream Spirit', 3, 'pelt', 'drf', 85, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 159
  ['Dusk Shroud', 3, 'tors', 'uui', 65, 1, 0, 0, 1, 3, 4, 4, 0, 1, [203, 0], [37, 0], 0], // 160
  ['Eagle Orb', 1, 'orb', 'ob1', 1, 2, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 161
  ['Earth Spirit', 3, 'pelt', 'drd', 76, 3, 0, 0, 1, 2, 3, 3, 0, 1, [271, 0], [0], 0], // 162
  ['Edge Bow', 2, 'bow', '8sb', 30, 1, 0, 0, 1, 3, 3, 3, 0, 0, [256, 0], [0], 0], // 163
  ['El Rune', 0, 'rune', 'r01', 11, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 164
  ['Eld Rune', 0, 'rune', 'r02', 11, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 165
  ['Elder Staff', 3, 'staf', '6cs', 74, 1, 0, 0, 1, 4, 4, 4, 1, 1, [202, 0], [84, 0], 0], // 166
  ['Eldritch Orb', 3, 'orb', 'obc', 67, 3, 0, 0, 1, 2, 2, 2, 1, 1, [96, 0], [0], 0], // 167
  ['Elegant Blade', 3, 'swor', '7sb', 63, 1, 0, 0, 1, 2, 2, 2, 0, 1, [35, 0], [0], 0], // 168
  ['Embossed Plate', 2, 'tors', 'xth', 58, 1, 0, 0, 1, 3, 4, 4, 0, 1, [11, 0], [0], 0], // 169
  ['Emerald', 0, 'geme', 'gsg', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 170
  ['Espandon', 2, 'swor', '92h', 37, 1, 0, 0, 1, 3, 3, 3, 0, 1, [61, 0], [0], 0], // 171
  ['Eth Rune', 0, 'rune', 'r05', 15, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 172
  ['Ettin Axe', 3, 'axe', '72a', 70, 1, 0, 0, 1, 4, 5, 5, 0, 1, [237, 0], [0], 0], // 173
  ['Executioner Sword', 2, 'swor', '9gd', 54, 1, 0, 0, 1, 3, 4, 6, 0, 1, [297, 0], [0], 0], // 174
  ['Exploding Potion', 1, 'tpot', 'opm', 16, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 175
  ['Fal Rune', 0, 'rune', 'r19', 41, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 176
  ['Falcata', 3, 'swor', '7ss', 56, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 177
  ['Falchion', 1, 'swor', 'flc', 11, 0, 0, 0, 1, 2, 2, 2, 0, 1, [114, 0], [0], 0], // 178
  ['Falcon Mask', 1, 'pelt', 'dr4', 20, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 179
  ['Fanged Helm', 1, 'phlm', 'ba2', 8, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 180
  ['Fanged Knife', 3, 'knif', '7kr', 83, 1, 0, 0, 1, 2, 3, 3, 0, 1, [103, 0], [0], 0], // 181
  ['Fascia', 2, 'h2h', '9xf', 36, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 182
  ['Feral Axe', 3, 'axe', '7la', 57, 1, 0, 0, 1, 4, 4, 4, 0, 1, [0], [0], 0], // 183
  ['Feral Claws', 3, 'h2h2', '7lw', 78, 3, 0, 0, 1, 2, 3, 3, 0, 1, [100, 0], [0], 0], // 184
  ['Fetish Trophy', 2, 'head', 'ne7', 39, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 185
  ['Field Plate', 1, 'tors', 'fld', 28, 0, 0, 0, 1, 2, 2, 2, 0, 1, [234, 0], [0], 0], // 186
  ['Flail', 1, 'mace', 'fla', 19, 0, 0, 0, 1, 3, 4, 5, 0, 1, [313, 0], [0], 0], // 187
  ['Flamberge', 1, 'swor', 'flb', 27, 0, 0, 0, 1, 3, 4, 5, 0, 1, [232, 0], [0], 0], // 188
  ['Flanged Mace', 2, 'mace', '9ma', 35, 1, 0, 0, 1, 2, 2, 2, 0, 1, [295, 0], [0], 0], // 189
  ['Flawed Amethyst', 0, 'gema', 'gfv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 190
  ['Flawed Diamond', 0, 'gemd', 'gfw', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 191
  ['Flawed Emerald', 0, 'geme', 'gfg', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 192
  ['Flawed Ruby', 0, 'gemr', 'gfr', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 193
  ['Flawed Sapphire', 0, 'gems', 'gfb', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 194
  ['Flawed Skull', 0, 'gemz', 'skf', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 195
  ['Flawed Topaz', 0, 'gemt', 'gfy', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 196
  ['Flawless Amethyst', 0, 'gema', 'gzv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 197
  ['Flawless Diamond', 0, 'gemd', 'glw', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 198
  ['Flawless Emerald', 0, 'geme', 'glg', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 199
  ['Flawless Ruby', 0, 'gemr', 'glr', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 200
  ['Flawless Sapphire', 0, 'gems', 'glb', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 201
  ['Flawless Skull', 0, 'gemz', 'skl', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 202
  ['Flawless Topaz', 0, 'gemt', 'gly', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 203
  ['Flying Axe', 3, 'taxe', '7ta', 56, 1, 0, 0, 1, 0, 0, 0, 0, 1, [112, 0], [0], 0], // 204
  ['Flying Knife', 3, 'tkni', '7tk', 64, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 205
  ['Francisca', 2, 'taxe', '9ta', 34, 1, 0, 0, 1, 0, 0, 0, 0, 1, [331, 0], [0], 0], // 206
  ['Full Helm', 1, 'helm', 'fhl', 15, 0, 0, 0, 1, 2, 2, 2, 0, 1, [90, 0], [68, 0], 0], // 207
  ['Full Plate Mail', 1, 'tors', 'ful', 37, 0, 0, 0, 1, 3, 4, 4, 0, 1, [117, 0], [113, 0], 0], // 208
  ['Full Rejuvenation Potion', 0, 'rpot', 'rvl', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 209
  ['Fulminating Potion', 1, 'tpot', 'opl', 4, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 210
  ['Fury Visor', 3, 'phlm', 'bac', 66, 3, 0, 0, 1, 2, 3, 3, 0, 1, [374, 0], [0], 0], // 211
  ['Fuscina', 2, 'spea', '9tr', 36, 1, 0, 0, 1, 3, 4, 4, 0, 1, [166, 0], [0], 0], // 212
  ['Gargoyle Head', 1, 'head', 'ne4', 20, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 213
  ['Gauntlets', 1, 'glov', 'hgl', 27, 0, 0, 0, 1, 0, 0, 0, 0, 1, [104, 0], [98, 0], 0], // 214
  ['Ghost Armor', 2, 'tors', 'xui', 34, 1, 0, 0, 1, 2, 2, 2, 0, 1, [332, 0], [0], 0], // 215
  ['Ghost Glaive', 3, 'jave', '7gl', 79, 1, 0, 0, 1, 0, 0, 0, 0, 1, [376, 0], [0], 0], // 216
  ['Ghost Spear', 3, 'spea', '7st', 83, 1, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 217
  ['Ghost Wand', 3, 'wand', '7yw', 65, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 218
  ['Giant Axe', 1, 'axe', 'gix', 27, 0, 0, 0, 1, 4, 5, 6, 0, 1, [156, 0], [0], 0], // 219
  ['Giant Conch', 3, 'helm', 'uhl', 54, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 220
  ['Giant Sword', 1, 'swor', 'gis', 21, 0, 0, 0, 1, 3, 4, 4, 0, 1, [167, 0], [0], 0], // 221
  ['Giant Thresher', 3, 'pole', '7wc', 85, 1, 0, 0, 1, 3, 4, 6, 0, 1, [290, 0], [0], 0], // 222
  ['Gilded Shield', 2, 'ashd', 'pa9', 51, 3, 0, 0, 1, 3, 4, 4, 0, 1, [148, 0], [0], 0], // 223
  ['Gladius', 2, 'swor', '9ss', 30, 1, 0, 0, 1, 2, 2, 2, 0, 1, [34, 0], [0], 0], // 224
  ['Glaive', 1, 'jave', 'glv', 23, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 225
  ['Glorious Axe', 3, 'axe', '7gi', 85, 1, 0, 0, 1, 4, 5, 6, 0, 1, [98, 0], [0], 0], // 226
  ['Glowing Orb', 2, 'orb', 'ob6', 32, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 227
  ['Gnarled Staff', 1, 'staf', 'cst', 12, 0, 0, 0, 1, 4, 4, 4, 1, 1, [269, 0], [0], 0], // 228
  ['Gold', 0, 'gold', 'gld', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 229
  ['Gorgon Crossbow', 3, 'xbow', '6mx', 67, 1, 0, 0, 1, 3, 4, 4, 0, 0, [0], [0], 0], // 230
  ['Gothic Axe', 2, 'axe', '9ga', 46, 1, 0, 0, 1, 4, 5, 6, 0, 1, [43, 0], [0], 0], // 231
  ['Gothic Bow', 2, 'bow', '8lw', 52, 1, 0, 0, 1, 3, 4, 6, 0, 0, [118, 0], [0], 0], // 232
  ['Gothic Plate', 1, 'tors', 'gth', 32, 0, 0, 0, 1, 3, 4, 4, 0, 1, [222, 0], [101, 0], 0], // 233
  ['Gothic Shield', 1, 'shie', 'gts', 30, 0, 0, 0, 1, 3, 3, 3, 0, 1, [336, 0], [70, 0], 0], // 234
  ['Gothic Staff', 2, 'staf', '8bs', 42, 1, 0, 0, 1, 4, 4, 4, 1, 1, [362, 0], [0], 0], // 235
  ['Gothic Sword', 2, 'swor', '9b9', 48, 1, 0, 0, 1, 3, 4, 4, 0, 1, [56, 0], [0], 0], // 236
  ['Grand Charm', 0, 'lcha', 'cm3', 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, [108, 0], [0], 0], // 237
  ['Grand Crown', 2, 'helm', 'xrn', 55, 1, 0, 0, 1, 2, 2, 3, 0, 1, [66, 0], [53, 0], 0], // 238
  ['Grand Matron Bow', 3, 'abow', 'amc', 78, 3, 0, 0, 1, 3, 4, 5, 0, 0, [0], [72, 0], 0], // 239
  ['Grand Scepter', 1, 'scep', 'gsc', 15, 0, 0, 0, 1, 3, 3, 3, 0, 1, [238, 0], [26, 0], 0], // 240
  ['Grave Wand', 2, 'wand', '9gw', 49, 1, 0, 0, 1, 2, 2, 2, 1, 1, [21, 0], [0], 0], // 241
  ['Great Axe', 1, 'axe', 'gax', 23, 0, 0, 0, 1, 4, 5, 6, 0, 1, [45, 0], [0], 0], // 242
  ['Great Bow', 3, 'bow', '6cb', 68, 1, 0, 0, 1, 3, 4, 4, 0, 0, [0], [0], 0], // 243
  ['Great Hauberk', 3, 'tors', 'urs', 75, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 244
  ['Great Helm', 1, 'helm', 'ghm', 23, 0, 0, 0, 1, 2, 2, 3, 0, 1, [155, 0], [102, 0], 0], // 245
  ['Great Maul', 1, 'hamm', 'gma', 32, 0, 0, 0, 1, 3, 4, 6, 0, 1, [278, 0], [0], 0], // 246
  ['Great Pilum', 2, 'jave', '9pi', 37, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 247
  ['Great Poleaxe', 3, 'pole', '7h7', 84, 1, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 248
  ['Great Sword', 1, 'swor', 'gsd', 33, 0, 0, 0, 1, 3, 4, 6, 0, 1, [326, 0], [0], 0], // 249
  ['Greater Claws', 2, 'h2h2', '9lw', 45, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 250
  ['Greater Healing Potion', 0, 'hpot', 'hp4', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 251
  ['Greater Mana Potion', 0, 'mpot', 'mp4', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 252
  ['Greater Talons', 2, 'h2h2', '9tw', 50, 3, 0, 0, 1, 2, 3, 3, 0, 1, [69, 0], [0], 0], // 253
  ['Greaves', 1, 'boot', 'hbt', 27, 0, 0, 0, 1, 0, 0, 0, 0, 1, [299, 0], [100, 0], 0], // 254
  ['Griffon Headdress', 2, 'pelt', 'dr7', 40, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 255
  ['Grim Helm', 2, 'helm', 'xh9', 50, 1, 0, 0, 1, 2, 2, 2, 0, 1, [352, 0], [88, 0], 0], // 256
  ['Grim Scythe', 2, 'pole', '9wc', 55, 1, 0, 0, 1, 3, 4, 6, 0, 1, [127, 0], [0], 0], // 257
  ['Grim Shield', 2, 'shie', 'xsh', 48, 1, 0, 0, 1, 2, 2, 2, 0, 1, [179, 0], [0], 0], // 258
  ['Grim Wand', 1, 'wand', 'gwn', 26, 0, 0, 0, 1, 2, 2, 2, 1, 1, [349, 0], [62, 0], 0], // 259
  ['Guardian Crown', 3, 'phlm', 'baf', 85, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 260
  ['Gul Rune', 0, 'rune', 'r25', 53, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 261
  ['Halberd', 1, 'pole', 'hal', 29, 0, 0, 0, 1, 3, 4, 6, 0, 1, [373, 0], [0], 0], // 262
  ['Hand Axe', 1, 'axe', 'hax', 3, 0, 0, 0, 1, 2, 2, 2, 0, 1, [315, 0], [0], 0], // 263
  ['Hand Scythe', 2, 'h2h2', '9cs', 41, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 264
  ['Hard Leather Armor', 1, 'tors', 'hla', 5, 0, 0, 0, 1, 2, 2, 2, 0, 1, [304, 0], [0], 0], // 265
  ['Harpoon', 2, 'jave', '9ts', 51, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 266
  ['Hatchet', 2, 'axe', '9ha', 31, 1, 0, 0, 1, 2, 2, 2, 0, 1, [58, 0], [0], 0], // 267
  ['Hatchet Hands', 1, 'h2h', 'axf', 12, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 268
  ['Hawk Helm', 1, 'pelt', 'dr2', 8, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 269
  ['Healing Potion', 0, 'hpot', 'hp3', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 270
  ['Heater', 3, 'shie', 'uuc', 58, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 271
  ['Heavenly Stone', 3, 'orb', 'obb', 59, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 272
  ['Heavy Belt', 1, 'belt', 'tbl', 20, 0, 0, 0, 1, 0, 0, 0, 0, 1, [119, 0], [61, 65, 0], 0], // 273
  ['Heavy Boots', 1, 'boot', 'vbt', 7, 0, 0, 0, 1, 0, 0, 0, 0, 1, [121, 0], [33, 92, 0], 0], // 274
  ['Heavy Bracers', 2, 'glov', 'xmg', 43, 1, 0, 0, 1, 0, 0, 0, 0, 1, [110, 0], [116, 0], 0], // 275
  ['Heavy Crossbow', 1, 'xbow', 'hxb', 24, 0, 0, 0, 1, 3, 4, 6, 0, 0, [142, 0], [0], 0], // 276
  ['Heavy Gloves', 1, 'glov', 'vgl', 7, 0, 0, 0, 1, 0, 0, 0, 0, 1, [33, 0], [94, 0], 0], // 277
  ['Hel Rune', 0, 'rune', 'r15', 33, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 278
  ['Hellforge Plate', 3, 'tors', 'ult', 78, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [83, 0], 0], // 279
  ['Hellspawn Skull', 3, 'head', 'neg', 67, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 280
  ['Helm', 1, 'helm', 'hlm', 11, 0, 0, 0, 1, 2, 2, 2, 0, 1, [57, 0], [18, 0], 0], // 281
  ['Heraldic Shield', 1, 'ashd', 'pa3', 16, 2, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 282
  ['Hierophant Trophy', 2, 'head', 'nea', 54, 3, 0, 0, 1, 2, 2, 2, 0, 1, [151, 0], [0], 0], // 283
  ['Highland Blade', 3, 'swor', '7cm', 66, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 284
  ['Holy Water Sprinkler', 2, 'scep', '9qs', 40, 1, 0, 0, 1, 3, 3, 3, 0, 1, [311, 0], [0], 0], // 285
  ['Horned Helm', 1, 'phlm', 'ba3', 16, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 286
  ["Hunter's Bow", 1, 'bow', 'hbw', 5, 0, 0, 0, 1, 3, 4, 4, 0, 0, [370, 0], [0], 0], // 287
  ["Hunter's Guise", 2, 'pelt', 'dr8', 46, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [3, 0], 0], // 288
  ['Hurlbat', 2, 'taxe', '9b8', 41, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 289
  ['Hydra Bow', 3, 'bow', '6lw', 85, 1, 0, 0, 1, 3, 4, 6, 0, 0, [366, 0], [0], 0], // 290
  ['Hydra Edge', 3, 'swor', '7fc', 69, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 291
  ['Hydraskull', 3, 'helm', 'ukp', 63, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 292
  ['Hyperion', 3, 'shie', 'urg', 64, 1, 0, 0, 1, 3, 3, 3, 0, 1, [0], [0], 0], // 293
  ['Hyperion Javelin', 3, 'jave', '7ja', 54, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 294
  ['Hyperion Spear', 3, 'spea', '7sr', 58, 1, 0, 0, 1, 3, 3, 3, 0, 1, [4, 0], [0], 0], // 295
  ['Io Rune', 0, 'rune', 'r16', 35, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 296
  ['Ist Rune', 0, 'rune', 'r24', 51, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 297
  ['Ith Rune', 0, 'rune', 'r06', 15, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 298
  ['Jagged Star', 2, 'mace', '9mt', 39, 1, 0, 0, 1, 3, 3, 3, 0, 1, [193, 0], [2, 0], 0], // 299
  ['Jah Rune', 0, 'rune', 'r31', 65, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 300
  ["Jared's Stone", 1, 'orb', 'ob5', 24, 2, 0, 0, 1, 2, 3, 3, 1, 1, [0], [0], 0], // 301
  ['Javelin', 1, 'jave', 'jav', 1, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 302
  ['Jawbone Cap', 1, 'phlm', 'ba1', 4, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 303
  ['Jawbone Visor', 2, 'phlm', 'ba6', 33, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 304
  ['Jewel', 0, 'jewl', 'jew', 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, [213, 214, 215, 216, 217, 218, 219, 220, 0], [0], 0], // 305
  ['Jo Staff', 2, 'staf', '8ss', 30, 1, 0, 0, 1, 2, 2, 2, 1, 1, [227, 0], [0], 0], // 306
  ['Katar', 1, 'h2h', 'ktr', 1, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 307
  ['Key', 0, 'key', 'key', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 308
  ['Kite Shield', 1, 'shie', 'kit', 15, 0, 0, 0, 1, 3, 3, 3, 0, 1, [277, 0], [79, 0], 0], // 309
  ['Knout', 2, 'mace', '9fl', 43, 1, 0, 0, 1, 3, 4, 5, 0, 1, [14, 0], [0], 0], // 310
  ['Ko Rune', 0, 'rune', 'r18', 39, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 311
  ['Kraken Shell', 3, 'tors', 'uld', 81, 1, 0, 0, 1, 3, 4, 4, 0, 1, [178, 0], [73, 0], 0], // 312
  ['Kris', 1, 'knif', 'kri', 17, 0, 0, 0, 1, 2, 3, 3, 0, 1, [321, 0], [0], 0], // 313
  ['Kurast Shield', 3, 'ashd', 'pad', 74, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 314
  ['Lacquered Plate', 3, 'tors', 'uth', 82, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [107, 0], 0], // 315
  ['Lance', 2, 'spea', '9p9', 47, 1, 0, 0, 1, 3, 4, 6, 0, 1, [268, 0], [0], 0], // 316
  ['Large Axe', 1, 'axe', 'lax', 6, 0, 0, 0, 1, 4, 4, 4, 0, 1, [12, 0], [0], 0], // 317
  ['Large Charm', 0, 'mcha', 'cm2', 14, 0, 0, 1, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 318
  ['Large Shield', 1, 'shie', 'lrg', 11, 0, 0, 0, 1, 3, 3, 3, 0, 1, [285, 0], [28, 0], 0], // 319
  ['Large Siege Bow', 2, 'bow', '8l8', 46, 1, 0, 0, 1, 3, 4, 6, 0, 0, [55, 0], [0], 0], // 320
  ['Leather Armor', 1, 'tors', 'lea', 3, 0, 0, 0, 1, 2, 2, 2, 0, 1, [30, 0], [121, 0], 0], // 321
  ['Leather Gloves', 1, 'glov', 'lgl', 3, 0, 0, 0, 1, 0, 0, 0, 0, 1, [318, 0], [39, 0], 0], // 322
  ['Legend Spike', 3, 'knif', '7bl', 85, 1, 0, 0, 1, 2, 2, 2, 0, 1, [109, 0], [0], 0], // 323
  ['Legend Sword', 3, 'swor', '72h', 59, 1, 0, 0, 1, 3, 3, 3, 0, 1, [0], [0], 0], // 324
  ['Legendary Mallet', 3, 'hamm', '7wh', 82, 1, 0, 0, 1, 3, 4, 4, 0, 1, [241, 281, 0], [0], 0], // 325
  ['Lem Rune', 0, 'rune', 'r20', 43, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 326
  ['Lich Wand', 3, 'wand', '7bw', 75, 1, 0, 0, 1, 2, 2, 2, 0, 1, [42, 0], [0], 0], // 327
  ['Light Belt', 1, 'belt', 'vbl', 7, 0, 0, 0, 1, 0, 0, 0, 0, 1, [257, 0], [12, 0], 0], // 328
  ['Light Crossbow', 1, 'xbow', 'lxb', 6, 0, 0, 0, 1, 3, 3, 3, 0, 0, [176, 0], [0], 0], // 329
  ['Light Gauntlets', 1, 'glov', 'tgl', 20, 0, 0, 0, 1, 0, 0, 0, 0, 1, [184, 0], [15, 66, 0], 0], // 330
  ['Light Healing Potion', 0, 'hpot', 'hp2', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 331
  ['Light Mana Potion', 0, 'mpot', 'mp2', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 332
  ['Light Plate', 1, 'tors', 'ltp', 35, 0, 0, 0, 1, 3, 3, 3, 0, 1, [141, 0], [9, 0], 0], // 333
  ['Light Plated Boots', 1, 'boot', 'tbt', 20, 0, 0, 0, 1, 0, 0, 0, 0, 1, [116, 0], [123, 0], 0], // 334
  ['Linked Mail', 2, 'tors', 'xng', 42, 1, 0, 0, 1, 3, 3, 3, 0, 1, [270, 0], [0], 0], // 335
  ['Lion Helm', 2, 'phlm', 'ba7', 38, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 336
  ['Lo Rune', 0, 'rune', 'r28', 59, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 337
  ['Lochaber Axe', 2, 'pole', '9b7', 33, 1, 0, 0, 1, 3, 3, 3, 0, 1, [323, 0], [0], 0], // 338
  ['Long Battle Bow', 1, 'bow', 'lbb', 23, 0, 0, 0, 1, 3, 4, 6, 0, 0, [372, 0], [122, 0], 0], // 339
  ['Long Bow', 1, 'bow', 'lbw', 8, 0, 0, 0, 1, 3, 4, 5, 0, 0, [223, 0], [0], 0], // 340
  ['Long Staff', 1, 'staf', 'lst', 8, 0, 0, 0, 1, 3, 3, 3, 1, 1, [243, 0], [0], 0], // 341
  ['Long Sword', 1, 'swor', 'lsd', 20, 0, 0, 0, 1, 3, 4, 4, 0, 1, [145, 0], [31, 0], 0], // 342
  ['Long War Bow', 1, 'bow', 'lwb', 31, 0, 0, 0, 1, 3, 4, 6, 0, 0, [29, 0], [0], 0], // 343
  ['Loricated Mail', 3, 'tors', 'ucl', 73, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [86, 0], 0], // 344
  ['Lum Rune', 0, 'rune', 'r17', 37, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 345
  ['Luna', 3, 'shie', 'uml', 61, 1, 0, 0, 1, 2, 2, 2, 0, 1, [24, 0], [0], 0], // 346
  ['Mace', 1, 'mace', 'mac', 8, 0, 0, 0, 1, 2, 2, 2, 0, 1, [67, 0], [0], 0], // 347
  ['Mage Plate', 2, 'tors', 'xtp', 60, 1, 0, 0, 1, 3, 3, 3, 0, 1, [211, 0], [0], 0], // 348
  ['Maiden Javelin', 1, 'ajav', 'am5', 23, 2, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 349
  ['Maiden Pike', 1, 'aspe', 'am4', 27, 2, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 350
  ['Maiden Spear', 1, 'aspe', 'am3', 18, 2, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 351
  ['Mal Rune', 0, 'rune', 'r23', 49, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 352
  ['Mana Potion', 0, 'mpot', 'mp3', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 353
  ['Mancatcher', 3, 'spea', '7br', 74, 1, 0, 0, 1, 3, 4, 5, 0, 1, [357, 0], [0], 0], // 354
  ['Martel de Fer', 2, 'hamm', '9gm', 53, 1, 0, 0, 1, 3, 4, 6, 0, 1, [312, 0], [0], 0], // 355
  ['Mask', 1, 'helm', 'msk', 19, 0, 0, 0, 1, 2, 2, 3, 0, 1, [310, 0], [25, 0], 0], // 356
  ['Matriarchal Bow', 3, 'abow', 'amb', 53, 3, 0, 0, 1, 3, 4, 5, 0, 0, [32, 0], [0], 0], // 357
  ['Matriarchal Javelin', 3, 'ajav', 'amf', 65, 3, 0, 0, 1, 0, 0, 0, 0, 1, [338, 0], [0], 0], // 358
  ['Matriarchal Pike', 3, 'aspe', 'ame', 81, 3, 0, 0, 1, 3, 4, 6, 0, 1, [0], [0], 0], // 359
  ['Matriarchal Spear', 3, 'aspe', 'amd', 61, 3, 0, 0, 1, 3, 4, 6, 0, 1, [282, 0], [0], 0], // 360
  ['Maul', 1, 'hamm', 'mau', 21, 0, 0, 0, 1, 3, 4, 6, 0, 1, [44, 0], [0], 0], // 361
  ['Mesh Armor', 2, 'tors', 'xhn', 45, 1, 0, 0, 1, 3, 3, 3, 0, 1, [247, 0], [0], 0], // 362
  ['Mesh Belt', 2, 'belt', 'zmb', 43, 1, 0, 0, 1, 0, 0, 0, 0, 1, [115, 0], [106, 0], 0], // 363
  ['Mesh Boots', 2, 'boot', 'xmb', 43, 1, 0, 0, 1, 0, 0, 0, 0, 1, [249, 0], [87, 0], 0], // 364
  ['Mighty Scepter', 3, 'scep', '7sc', 62, 1, 0, 0, 1, 2, 2, 2, 0, 1, [140, 328, 0], [0], 0], // 365
  ['Military Axe', 2, 'axe', '9la', 34, 1, 0, 0, 1, 4, 4, 4, 0, 1, [361, 0], [0], 0], // 366
  ['Military Pick', 1, 'axe', 'mpi', 19, 0, 0, 0, 1, 4, 5, 6, 0, 1, [254, 0], [110, 0], 0], // 367
  ['Minion Skull', 3, 'head', 'neb', 59, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 368
  ['Minor Healing Potion', 0, 'hpot', 'hp1', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 369
  ['Minor Mana Potion', 0, 'mpot', 'mp1', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 370
  ['Mirrored Boots', 3, 'boot', 'utb', 81, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 371
  ['Mithril Coil', 3, 'belt', 'umc', 75, 1, 0, 0, 1, 0, 0, 0, 0, 1, [356, 0], [35, 0], 0], // 372
  ['Mithril Point', 3, 'knif', '7di', 70, 1, 0, 0, 1, 1, 1, 1, 0, 1, [0], [0], 0], // 373
  ['Monarch', 3, 'shie', 'uit', 72, 1, 0, 0, 1, 3, 3, 4, 0, 1, [288, 0], [0], 0], // 374
  ['Morning Star', 1, 'mace', 'mst', 13, 0, 0, 0, 1, 3, 3, 3, 0, 1, [36, 0], [0], 0], // 375
  ['Mummified Trophy', 2, 'head', 'ne6', 33, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 376
  ['Myrmidon Greaves', 3, 'boot', 'uhb', 85, 1, 0, 0, 1, 0, 0, 0, 0, 1, [244, 0], [0], 0], // 377
  ['Mythical Sword', 3, 'swor', '7wd', 85, 1, 0, 0, 1, 3, 3, 3, 0, 1, [0], [20, 0], 0], // 378
  ['Naga', 2, 'axe', '9wa', 48, 1, 0, 0, 1, 4, 5, 6, 0, 1, [130, 0], [0], 0], // 379
  ['Nef Rune', 0, 'rune', 'r04', 13, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 380
  ['Ogre Axe', 3, 'pole', '7o7', 60, 1, 0, 0, 1, 3, 3, 3, 0, 1, [41, 0], [0], 0], // 381
  ['Ogre Gauntlets', 3, 'glov', 'uhg', 85, 1, 0, 0, 1, 0, 0, 0, 0, 1, [280, 0], [0], 0], // 382
  ['Ogre Maul', 3, 'hamm', '7m7', 69, 1, 0, 0, 1, 3, 4, 6, 0, 1, [367, 0], [58, 0], 0], // 383
  ['Ohm Rune', 0, 'rune', 'r27', 57, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 384
  ['Oil Potion', 1, 'tpot', 'ops', 28, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 385
  ['Ornate Plate', 2, 'tors', 'xar', 64, 1, 0, 0, 1, 3, 4, 4, 0, 1, [60, 0], [41, 0], 0], // 386
  ['Ort Rune', 0, 'rune', 'r09', 21, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 387
  ['Overseer Skull', 3, 'head', 'ned', 66, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 388
  ['Partizan', 2, 'pole', '9pa', 35, 1, 0, 0, 1, 3, 4, 5, 0, 1, [206, 0], [0], 0], // 389
  ['Pavise', 2, 'shie', 'xow', 50, 1, 0, 0, 1, 3, 3, 3, 0, 1, [107, 0], [0], 0], // 390
  ['Pellet Bow', 3, 'xbow', '6lx', 57, 1, 0, 0, 1, 3, 3, 3, 0, 0, [0], [0], 0], // 391
  ['Perfect Amethyst', 0, 'gema', 'gpv', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 392
  ['Perfect Diamond', 0, 'gemd', 'gpw', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 393
  ['Perfect Emerald', 0, 'geme', 'gpg', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 394
  ['Perfect Ruby', 0, 'gemr', 'gpr', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 395
  ['Perfect Sapphire', 0, 'gems', 'gpb', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 396
  ['Perfect Skull', 0, 'gemz', 'skz', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 397
  ['Perfect Topaz', 0, 'gemt', 'gpy', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 398
  ['Petrified Wand', 2, 'wand', '9yw', 38, 1, 0, 0, 1, 2, 2, 2, 1, 1, [50, 0], [0], 0], // 399
  ['Phase Blade', 3, 'swor', '7cr', 73, 1, 0, 0, 1, 3, 4, 6, 0, 0, [13, 180, 0], [0], 0], // 400
  ['Pike', 1, 'spea', 'pik', 24, 0, 0, 0, 1, 3, 4, 6, 0, 1, [334, 0], [0], 0], // 401
  ['Pilum', 1, 'jave', 'pil', 10, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 402
  ['Plate Mail', 1, 'tors', 'plt', 24, 0, 0, 0, 1, 2, 2, 2, 0, 1, [40, 0], [0], 0], // 403
  ['Plated Belt', 1, 'belt', 'hbl', 27, 0, 0, 0, 1, 0, 0, 0, 0, 1, [28, 0], [103, 0], 0], // 404
  ['Poignard', 2, 'knif', '9dg', 31, 1, 0, 0, 1, 1, 1, 1, 0, 1, [267, 0], [0], 0], // 405
  ['Poleaxe', 1, 'pole', 'pax', 21, 0, 0, 0, 1, 3, 4, 5, 0, 1, [302, 0], [0], 0], // 406
  ['Polished Wand', 3, 'wand', '7wn', 55, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 407
  ['Preserved Head', 1, 'head', 'ne1', 4, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 408
  ['Protector Shield', 2, 'ashd', 'pa8', 46, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 409
  ['Pul Rune', 0, 'rune', 'r21', 45, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 410
  ['Quarterstaff', 2, 'staf', '8ls', 35, 1, 0, 0, 1, 3, 3, 3, 1, 1, [230, 0], [0], 0], // 411
  ['Quhab', 2, 'h2h', '9ar', 28, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 412
  ['Quilted Armor', 1, 'tors', 'qui', 1, 0, 0, 0, 1, 2, 2, 2, 0, 1, [125, 0], [13, 0], 0], // 413
  ['Rage Mask', 2, 'phlm', 'ba8', 44, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 414
  ['Ral Rune', 0, 'rune', 'r08', 19, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 415
  ['Rancid Gas Potion', 1, 'tpot', 'gps', 32, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 416
  ['Razor Bow', 2, 'bow', '8hb', 33, 1, 0, 0, 1, 3, 4, 4, 0, 0, [231, 0], [0], 0], // 417
  ['Reflex Bow', 1, 'abow', 'am2', 27, 2, 0, 0, 1, 3, 4, 5, 0, 0, [0], [0], 0], // 418
  ['Reinforced Mace', 3, 'mace', '7ma', 63, 1, 0, 0, 1, 2, 2, 2, 0, 1, [0], [36, 0], 0], // 419
  ['Rejuvenation Potion', 0, 'rpot', 'rvs', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 420
  ['Repeating Crossbow', 1, 'xbow', 'rxb', 33, 0, 0, 0, 1, 3, 4, 5, 0, 0, [86, 0], [0], 0], // 421
  ['Ring', 0, 'ring', 'rin', 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, [46, 51, 91, 186, 195, 196, 224, 333, 368, 0], [4, 23, 0], 0], // 422
  ['Ring Mail', 1, 'tors', 'rng', 11, 0, 0, 0, 1, 3, 3, 3, 0, 1, [72, 0], [5, 0], 0], // 423
  ['Rondache', 1, 'ashd', 'pa2', 8, 2, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 424
  ['Rondel', 2, 'knif', '9di', 36, 1, 0, 0, 1, 1, 1, 1, 0, 1, [139, 0], [0], 0], // 425
  ['Round Shield', 2, 'shie', 'xml', 37, 1, 0, 0, 1, 2, 2, 2, 0, 1, [194, 0], [125, 0], 0], // 426
  ['Royal Shield', 2, 'ashd', 'paa', 55, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 427
  ['Ruby', 0, 'gemr', 'gsr', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 428
  ['Rune Bow', 2, 'bow', '8sw', 49, 1, 0, 0, 1, 3, 4, 5, 0, 0, [185, 0], [0], 0], // 429
  ['Rune Scepter', 2, 'scep', '9sc', 31, 1, 0, 0, 1, 2, 2, 2, 0, 1, [377, 0], [0], 0], // 430
  ['Rune Staff', 2, 'staf', '8ws', 47, 1, 0, 0, 1, 5, 6, 6, 1, 1, [253, 0], [0], 0], // 431
  ['Rune Sword', 2, 'swor', '9ls', 44, 1, 0, 0, 1, 3, 4, 4, 0, 1, [207, 0], [0], 0], // 432
  ['Runic Talons', 3, 'h2h2', '7tw', 81, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 433
  ['Russet Armor', 2, 'tors', 'xpl', 49, 1, 0, 0, 1, 3, 3, 3, 0, 1, [255, 0], [0], 0], // 434
  ['Sabre', 1, 'swor', 'sbr', 8, 0, 0, 0, 1, 2, 2, 2, 0, 1, [250, 0], [6, 0], 0], // 435
  ['Sacred Armor', 3, 'tors', 'uar', 85, 1, 0, 0, 1, 3, 4, 4, 0, 1, [300, 347, 0], [57, 0], 0], // 436
  ['Sacred Feathers', 2, 'pelt', 'dr9', 50, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 437
  ['Sacred Globe', 1, 'orb', 'ob2', 8, 2, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 438
  ['Sacred Rondache', 3, 'ashd', 'pac', 70, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0, 0], [0], 0], // 439
  ['Sacred Targe', 3, 'ashd', 'pab', 63, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 440
  ['Sallet', 2, 'helm', 'xkp', 37, 1, 0, 0, 1, 2, 2, 2, 0, 1, [235, 0], [0], 0], // 441
  ['Sapphire', 0, 'gems', 'gsb', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 442
  ['Sash', 1, 'belt', 'lbl', 3, 0, 0, 0, 1, 0, 0, 0, 0, 1, [177, 0], [38, 0], 0], // 443
  ['Savage Helmet', 2, 'phlm', 'ba9', 49, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 444
  ['Scale Mail', 1, 'tors', 'scl', 13, 0, 0, 0, 1, 2, 2, 2, 0, 1, [136, 0], [0], 0], // 445
  ['Scarab Husk', 3, 'tors', 'ula', 68, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 446
  ['Scarabshell Boots', 3, 'boot', 'uvb', 66, 1, 0, 0, 1, 0, 0, 0, 0, 1, [239, 0], [0], 0], // 447
  ['Scepter', 1, 'scep', 'scp', 3, 0, 0, 0, 1, 2, 2, 2, 0, 1, [169, 0], [0], 0], // 448
  ['Scimitar', 1, 'swor', 'scm', 5, 0, 0, 0, 1, 2, 2, 2, 0, 1, [31, 0], [0], 0], // 449
  ['Scissors Katar', 1, 'h2h', 'skr', 24, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 450
  ['Scissors Quhab', 2, 'h2h2', '9qr', 54, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 451
  ['Scissors Suwayyah', 3, 'h2h2', '7qr', 85, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [85, 0], 0], // 452
  ['Scourge', 3, 'mace', '7fl', 76, 1, 0, 0, 1, 3, 4, 5, 0, 1, [153, 286, 0], [0], 0], // 453
  ['Scroll of Identify', 0, 'scro', 'isc', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 454
  ['Scroll of Town Portal', 0, 'scro', 'tsc', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 455
  ['Scutum', 2, 'shie', 'xrg', 42, 1, 0, 0, 1, 3, 3, 3, 0, 1, [283, 0], [0], 0], // 456
  ['Scythe', 1, 'pole', 'scy', 15, 0, 0, 0, 1, 3, 4, 5, 0, 1, [260, 0], [0], 0], // 457
  ['Seraph Rod', 3, 'scep', '7qs', 76, 1, 0, 0, 1, 3, 3, 3, 0, 1, [0], [0], 0], // 458
  ['Serpentskin Armor', 2, 'tors', 'xea', 36, 1, 0, 0, 1, 2, 2, 2, 0, 1, [252, 0], [0], 0], // 459
  ['Sexton Trophy', 2, 'head', 'ne8', 45, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 460
  ['Shadow Bow', 3, 'bow', '6lb', 63, 1, 0, 0, 1, 3, 4, 5, 0, 0, [0], [0], 0], // 461
  ['Shadow Plate', 3, 'tors', 'uul', 83, 1, 0, 0, 1, 3, 4, 4, 0, 1, [274, 0], [1, 0], 0], // 462
  ['Shael Rune', 0, 'rune', 'r13', 29, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 463
  ['Shako', 3, 'helm', 'uap', 58, 1, 0, 0, 1, 2, 2, 2, 0, 1, [135, 0], [0], 0], // 464
  ['Shamshir', 2, 'swor', '9sb', 35, 1, 0, 0, 1, 2, 2, 2, 0, 1, [149, 0], [0], 0], // 465
  ['Sharkskin Belt', 2, 'belt', 'zvb', 39, 1, 0, 0, 1, 0, 0, 0, 0, 1, [228, 0], [75, 0], 0], // 466
  ['Sharkskin Boots', 2, 'boot', 'xvb', 39, 1, 0, 0, 1, 0, 0, 0, 0, 1, [364, 0], [0], 0], // 467
  ['Sharkskin Gloves', 2, 'glov', 'xvg', 39, 1, 0, 0, 1, 0, 0, 0, 0, 1, [124, 0], [77, 0], 0], // 468
  ['Sharktooth Armor', 2, 'tors', 'xld', 55, 1, 0, 0, 1, 3, 3, 3, 0, 1, [343, 0], [0], 0], // 469
  ['Shillelagh', 3, 'staf', '6bs', 83, 1, 0, 0, 1, 4, 4, 4, 1, 1, [0], [0], 0], // 470
  ['Short Battle Bow', 1, 'bow', 'sbb', 18, 0, 0, 0, 1, 3, 4, 5, 0, 0, [291, 0], [0], 0], // 471
  ['Short Bow', 1, 'bow', 'sbw', 1, 0, 0, 0, 1, 3, 3, 3, 0, 0, [208, 0], [0], 0], // 472
  ['Short Siege Bow', 2, 'bow', '8s8', 43, 1, 0, 0, 1, 3, 4, 5, 0, 0, [369, 0], [0], 0], // 473
  ['Short Spear', 1, 'jave', 'ssp', 15, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 474
  ['Short Staff', 1, 'staf', 'sst', 1, 0, 0, 0, 1, 2, 2, 2, 1, 1, [15, 0], [0], 0], // 475
  ['Short Sword', 1, 'swor', 'ssd', 1, 0, 0, 0, 1, 2, 2, 2, 0, 1, [233, 0], [0], 0], // 476
  ['Short War Bow', 1, 'bow', 'swb', 27, 0, 0, 0, 1, 3, 4, 5, 0, 0, [143, 0], [14, 0], 0], // 477
  ['Siege Crossbow', 2, 'xbow', '8mx', 40, 1, 0, 0, 1, 3, 4, 4, 0, 0, [210, 0], [0], 0], // 478
  ['Silver-edged Axe', 3, 'axe', '7ba', 65, 1, 0, 0, 1, 4, 5, 5, 0, 1, [97, 0], [0], 0], // 479
  ['Simbilan', 2, 'jave', '9s9', 40, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 480
  ['Skull', 0, 'gemz', 'sku', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 481
  ['Skull Cap', 1, 'helm', 'skp', 5, 0, 0, 0, 1, 2, 2, 2, 0, 1, [298, 0], [10, 0], 0], // 482
  ['Sky Spirit', 3, 'pelt', 'dre', 83, 3, 0, 0, 1, 2, 3, 3, 0, 1, [225, 0], [0], 0], // 483
  ['Slayer Guard', 2, 'phlm', 'baa', 54, 3, 0, 0, 1, 2, 3, 3, 0, 1, [7, 0], [0], 0], // 484
  ['Small Charm', 0, 'scha', 'cm1', 28, 0, 0, 1, 0, 0, 0, 0, 0, 0, [2, 0], [0], 0], // 485
  ['Small Crescent', 3, 'axe', '7ax', 61, 1, 0, 0, 1, 4, 4, 4, 0, 1, [0], [0], 0], // 486
  ['Small Shield', 1, 'shie', 'sml', 5, 0, 0, 0, 1, 2, 2, 2, 0, 1, [348, 0], [29, 0], 0], // 487
  ['Smoked Sphere', 1, 'orb', 'ob3', 12, 2, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 488
  ['Sol Rune', 0, 'rune', 'r12', 27, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 489
  ['Sparkling Ball', 2, 'orb', 'ob9', 46, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 490
  ['Spear', 1, 'spea', 'spr', 5, 0, 0, 0, 1, 3, 3, 3, 0, 1, [308, 0], [0], 0], // 491
  ['Spetum', 1, 'spea', 'spt', 20, 0, 0, 0, 1, 3, 4, 6, 0, 1, [173, 0], [0], 0], // 492
  ['Spiculum', 2, 'jave', '9gl', 46, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 493
  ['Spider Bow', 3, 'bow', '6sb', 55, 1, 0, 0, 1, 3, 3, 3, 0, 0, [0], [0], 0], // 494
  ['Spiderweb Sash', 3, 'belt', 'ulc', 61, 1, 0, 0, 1, 0, 0, 0, 0, 1, [3, 0], [0], 0], // 495
  ['Spiked Club', 1, 'club', 'spc', 4, 0, 0, 0, 1, 2, 2, 2, 0, 1, [292, 0], [0], 0], // 496
  ['Spiked Shield', 1, 'shie', 'spk', 11, 0, 0, 0, 1, 2, 2, 2, 0, 1, [296, 0], [0], 0], // 497
  ['Spired Helm', 3, 'helm', 'uhm', 79, 1, 0, 0, 1, 2, 2, 3, 0, 1, [198, 353, 0], [89, 0], 0], // 498
  ['Spirit Mask', 1, 'pelt', 'dr5', 24, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 499
  ['Splint Mail', 1, 'tors', 'spl', 20, 0, 0, 0, 1, 2, 2, 2, 0, 1, [158, 0], [17, 0], 0], // 500
  ['Stag Bow', 1, 'abow', 'am1', 18, 2, 0, 0, 1, 3, 4, 5, 0, 0, [0], [0], 0], // 501
  ['Stalagmite', 3, 'staf', '6ls', 66, 1, 0, 0, 1, 3, 3, 3, 1, 1, [0], [0], 0], // 502
  ['Stamina Potion', 0, 'spot', 'vps', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 503
  ['Stiletto', 2, 'knif', '9bl', 46, 1, 0, 0, 1, 2, 2, 2, 0, 1, [289, 0], [0], 0], // 504
  ['Strangling Gas Potion', 1, 'tpot', 'gpl', 8, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 505
  ['Studded Leather', 1, 'tors', 'stu', 8, 0, 0, 0, 1, 2, 2, 2, 0, 1, [346, 0], [32, 0], 0], // 506
  ['Stygian Pike', 3, 'spea', '7tr', 66, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 507
  ['Stygian Pilum', 3, 'jave', '7pi', 62, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 508
  ['Succubus Skull', 3, 'head', 'nee', 81, 3, 0, 0, 1, 2, 2, 2, 0, 1, [39, 0], [0], 0], // 509
  ['Sun Spirit', 3, 'pelt', 'drc', 69, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 510
  ['Super Healing Potion', 0, 'hpot', 'hp5', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 511
  ['Super Mana Potion', 0, 'mpot', 'mp5', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 512
  ['Sur Rune', 0, 'rune', 'r29', 61, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 513
  ['Suwayyah', 3, 'h2h2', '7ar', 59, 3, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 514
  ['Swirling Crystal', 2, 'orb', 'oba', 50, 3, 0, 0, 1, 2, 3, 3, 1, 1, [325, 0], [109, 0], 0], // 515
  ['Tabar', 2, 'axe', '9bt', 42, 1, 0, 0, 1, 4, 5, 5, 0, 1, [287, 0], [0], 0], // 516
  ['Tal Rune', 0, 'rune', 'r07', 17, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 517
  ['Targe', 1, 'ashd', 'pa1', 4, 2, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 518
  ['Templar Coat', 2, 'tors', 'xlt', 52, 1, 0, 0, 1, 3, 3, 3, 0, 1, [129, 0], [0], 0], // 519
  ['Thawing Potion', 0, 'wpot', 'wms', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 520
  ['Thresher', 3, 'pole', '7s8', 71, 1, 0, 0, 1, 3, 4, 5, 0, 1, [327, 0], [0], 0], // 521
  ['Throwing Axe', 1, 'taxe', 'tax', 7, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 522
  ['Throwing Knife', 1, 'tkni', 'tkf', 2, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 523
  ['Throwing Spear', 1, 'jave', 'tsp', 29, 0, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 524
  ['Thul Rune', 0, 'rune', 'r10', 23, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 525
  ['Thunder Maul', 3, 'hamm', '7gm', 85, 1, 0, 0, 1, 3, 4, 6, 0, 1, [93, 306, 0], [0], 0], // 526
  ['Tiara', 2, 'circ', 'ci2', 70, 1, 0, 0, 1, 1, 2, 3, 13, 1, [168, 0], [0], 0], // 527
  ['Tigulated Mail', 2, 'tors', 'xcl', 43, 1, 0, 0, 1, 3, 3, 3, 0, 1, [64, 0], [52, 0], 0], // 528
  ['Tir Rune', 0, 'rune', 'r03', 13, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 529
  ['Tomahawk', 3, 'axe', '7ha', 54, 1, 0, 0, 1, 2, 2, 2, 0, 1, [226, 0], [0], 0], // 530
  ['Tomb Wand', 2, 'wand', '9bw', 43, 1, 0, 0, 1, 2, 2, 2, 1, 1, [6, 0], [0], 0], // 531
  ['Topaz', 0, 'gemt', 'gsy', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 532
  ['Totemic Mask', 2, 'pelt', 'dra', 55, 3, 0, 0, 1, 2, 3, 3, 0, 1, [165, 0], [0], 0], // 533
  ['Tower Shield', 1, 'shie', 'tow', 22, 0, 0, 0, 1, 3, 3, 3, 0, 1, [49, 0], [99, 0], 0], // 534
  ['Trellised Armor', 2, 'tors', 'xtu', 40, 1, 0, 0, 1, 2, 2, 2, 0, 1, [161, 0], [0], 0], // 535
  ['Trident', 1, 'spea', 'tri', 9, 0, 0, 0, 1, 3, 4, 4, 0, 1, [229, 0], [0], 0], // 536
  ['Troll Belt', 3, 'belt', 'utc', 82, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [117, 0], 0], // 537
  ['Troll Nest', 3, 'shie', 'ush', 76, 1, 0, 0, 1, 3, 3, 3, 0, 1, [137, 0], [0], 0], // 538
  ['Truncheon', 3, 'club', '7cl', 52, 1, 0, 0, 1, 2, 2, 2, 0, 1, [200, 0], [0], 0], // 539
  ['Tulwar', 2, 'swor', '9fc', 37, 1, 0, 0, 1, 2, 2, 2, 0, 1, [26, 0], [0], 0], // 540
  ['Tusk Sword', 2, 'swor', '9gs', 45, 1, 0, 0, 1, 3, 4, 4, 0, 1, [335, 0], [0], 0], // 541
  ['Twin Axe', 2, 'axe', '92a', 39, 1, 0, 0, 1, 4, 5, 5, 0, 1, [163, 0], [0], 0], // 542
  ['Two-Handed Sword', 1, 'swor', '2hs', 10, 0, 0, 0, 1, 3, 3, 3, 0, 1, [246, 0], [0], 0], // 543
  ['Tyrant Club', 3, 'club', '7sp', 57, 1, 0, 0, 1, 3, 3, 3, 0, 1, [79, 0], [0], 0], // 544
  ['Um Rune', 0, 'rune', 'r22', 47, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 545
  ['Unearthed Wand', 3, 'wand', '7gw', 86, 1, 0, 0, 1, 2, 2, 2, 0, 1, [76, 0], [0], 0], // 546
  ['Unraveller Head', 1, 'head', 'ne3', 16, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 547
  ['Vambraces', 3, 'glov', 'umg', 69, 1, 0, 0, 1, 0, 0, 0, 0, 1, [259, 0], [0], 0], // 548
  ['Vampirebone Gloves', 3, 'glov', 'uvg', 63, 1, 0, 0, 1, 0, 0, 0, 0, 1, [87, 0], [0], 0], // 549
  ['Vampirefang Belt', 3, 'belt', 'uvc', 68, 1, 0, 0, 1, 0, 0, 0, 0, 1, [201, 0], [0], 0], // 550
  ['Vex Rune', 0, 'rune', 'r26', 55, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 551
  ['Vortex Orb', 3, 'orb', 'obe', 84, 3, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 552
  ['Vortex Shield', 3, 'ashd', 'paf', 85, 3, 0, 0, 1, 3, 4, 4, 0, 1, [0], [42, 0], 0], // 553
  ['Voulge', 1, 'pole', 'vou', 11, 0, 0, 0, 1, 3, 4, 4, 0, 1, [279, 0], [0], 0], // 554
  ['Walking Stick', 3, 'staf', '6ss', 58, 1, 0, 0, 1, 2, 2, 2, 1, 1, [0], [0], 0], // 555
  ['Wand', 1, 'wand', 'wnd', 2, 0, 0, 0, 1, 1, 1, 1, 1, 1, [344, 0], [0], 0], // 556
  ['War Axe', 1, 'axe', 'wax', 25, 0, 0, 0, 1, 4, 5, 6, 0, 1, [221, 0], [0], 0], // 557
  ['War Belt', 2, 'belt', 'zhb', 54, 1, 0, 0, 1, 0, 0, 0, 0, 1, [337, 0], [54, 0], 0], // 558
  ['War Boots', 2, 'boot', 'xhb', 54, 1, 0, 0, 1, 0, 0, 0, 0, 1, [120, 0], [56, 0], 0], // 559
  ['War Club', 2, 'hamm', '9m9', 45, 1, 0, 0, 1, 3, 4, 6, 0, 1, [38, 0], [0], 0], // 560
  ['War Dart', 2, 'tkni', '9bk', 39, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 561
  ['War Fist', 3, 'h2h2', '7xf', 68, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 562
  ['War Fork', 2, 'spea', '9br', 41, 1, 0, 0, 1, 3, 4, 5, 0, 1, [261, 0], [0], 0], // 563
  ['War Gauntlets', 2, 'glov', 'xhg', 54, 1, 0, 0, 1, 0, 0, 0, 0, 1, [144, 0], [55, 0], 0], // 564
  ['War Hammer', 1, 'hamm', 'whm', 25, 0, 0, 0, 1, 3, 4, 4, 0, 1, [162, 0], [0], 0], // 565
  ['War Hat', 2, 'helm', 'xap', 34, 1, 0, 0, 1, 2, 2, 2, 0, 1, [204, 0], [34, 0], 0], // 566
  ['War Javelin', 2, 'jave', '9ja', 30, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 567
  ['War Pike', 3, 'spea', '7p7', 85, 1, 0, 0, 1, 3, 4, 6, 0, 1, [275, 0], [0], 0], // 568
  ['War Scepter', 1, 'scep', 'wsp', 21, 0, 0, 0, 1, 3, 5, 5, 0, 1, [284, 0], [81, 0], 0], // 569
  ['War Scythe', 1, 'pole', 'wsc', 34, 0, 0, 0, 1, 3, 4, 6, 0, 1, [317, 0], [0], 0], // 570
  ['War Spear', 2, 'spea', '9sr', 33, 1, 0, 0, 1, 3, 3, 3, 0, 1, [319, 0], [0], 0], // 571
  ['War Spike', 3, 'axe', '7mp', 79, 1, 0, 0, 1, 4, 5, 6, 0, 1, [62, 0], [0], 0], // 572
  ['War Staff', 1, 'staf', 'wst', 24, 0, 0, 0, 1, 5, 6, 6, 1, 1, [320, 0], [8, 0], 0], // 573
  ['War Sword', 1, 'swor', 'wsd', 27, 0, 0, 0, 1, 3, 3, 3, 0, 1, [68, 0], [40, 0], 0], // 574
  ['Ward', 3, 'shie', 'uts', 84, 1, 0, 0, 1, 3, 3, 4, 0, 1, [272, 0], [104, 0], 0], // 575
  ['Ward Bow', 3, 'bow', '6sw', 80, 1, 0, 0, 1, 3, 4, 5, 0, 0, [365, 0], [0], 0], // 576
  ['Winged Axe', 3, 'taxe', '7b8', 80, 1, 0, 0, 1, 0, 0, 0, 0, 1, [171, 0], [0], 0], // 577
  ['Winged Harpoon', 3, 'jave', '7ts', 85, 1, 0, 0, 1, 0, 0, 0, 0, 1, [106, 0], [0], 0], // 578
  ['Winged Helm', 2, 'helm', 'xhm', 51, 1, 0, 0, 1, 2, 2, 3, 0, 1, [351, 0], [45, 0], 0], // 579
  ['Winged Knife', 3, 'tkni', '7bk', 77, 1, 0, 0, 1, 0, 0, 0, 0, 1, [363, 0], [0], 0], // 580
  ['Wire Fleece', 3, 'tors', 'utu', 70, 1, 0, 0, 1, 3, 4, 4, 0, 1, [314, 0], [0], 0], // 581
  ['Wolf Head', 1, 'pelt', 'dr1', 4, 2, 0, 0, 1, 2, 3, 3, 0, 1, [0], [0], 0], // 582
  ['Wrist Blade', 1, 'h2h', 'wrb', 9, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 583
  ['Wrist Spike', 2, 'h2h', '9wb', 32, 3, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 584
  ['Wrist Sword', 3, 'h2h2', '7wb', 62, 3, 0, 0, 1, 2, 3, 3, 0, 1, [164, 0], [0], 0], // 585
  ['Wyrmhide', 3, 'tors', 'uea', 67, 1, 0, 0, 1, 3, 4, 4, 0, 1, [0], [0], 0], // 586
  ['Wyrmhide Boots', 3, 'boot', 'ulb', 60, 1, 0, 0, 1, 0, 0, 0, 0, 1, [0], [0], 0], // 587
  ['Yari', 2, 'spea', '9st', 44, 1, 0, 0, 1, 3, 4, 6, 0, 1, [152, 0], [0], 0], // 588
  ['Yew Wand', 1, 'wand', 'ywn', 12, 0, 0, 0, 1, 1, 1, 1, 1, 1, [183, 0], [0], 0], // 589
  ['Zakarum Shield', 3, 'ashd', 'pae', 82, 3, 0, 0, 1, 3, 4, 4, 0, 1, [88, 0], [0], 0], // 590
  ['Zod Rune', 0, 'rune', 'r33', 69, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 591
  ['Zombie Head', 1, 'head', 'ne2', 8, 2, 0, 0, 1, 2, 2, 2, 0, 1, [0], [0], 0], // 592
  ['Zweihander', 2, 'swor', '9fb', 49, 1, 0, 0, 1, 3, 4, 5, 0, 1, [341, 0], [0], 0], // 593
  ['Key of Hate', 0, 'ques', 'pk1', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 594
  ['Key of Terror', 0, 'ques', 'pk2', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 595
  ['Key of Destruction', 0, 'ques', 'pk3', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 596
  ['Twisted Essence of Suffering', 0, 'ques', 'tes', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 597
  ['Charged Essence of Hatred', 0, 'ques', 'ceh', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 598
  ['Burning Essence of Terror', 0, 'ques', 'bet', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 599
  ['Festering Essence of Destruction', 0, 'ques', 'fed', 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, [0], [0], 0], // 600
];

export const dropCalcUniques: [string, number, number, number, number, number][] = [
  ['Alma Negra', 3, 1, 85, 439, 331],
  ["Andariel's Visage", 3, 1, 85, 140, 347],
  ['Annihilus', 0, 1, 110, 485, 383],
  ['Arachnid Mesh', 3, 1, 87, 495, 375],
  ["Arioc's Needle", 3, 1, 85, 295, 384],
  ["Arkaine's Valor", 3, 1, 85, 28, 253],
  ['Arm of King Leoric', 2, 1, 44, 531, 143],
  ["Arreat's Face", 2, 1, 50, 484, 281],
  ["Astreon's Iron Ward", 3, 1, 68, 73, 382],
  ["Athena's Wrath", 2, 1, 50, 42, 181],
  ["Atma's Scarab", 0, 5, 60, 7, 275],
  ["Atma's Wail", 2, 1, 59, 169, 222],
  ['Axe of Fechmar', 1, 1, 11, 317, 6],
  ['Azurewrath', 3, 1, 87, 400, 303],
  ["Baezil's Vortex", 2, 1, 53, 310, 152],
  ['Bane Ash', 1, 1, 7, 475, 55],
  ["Baranar's Star", 3, 1, 70, 146, 258],
  ["Biggin's Bonnet", 1, 1, 4, 75, 72],
  ['Bing Sz Wang', 2, 1, 51, 132, 165],
  ['Black Hades', 2, 1, 61, 91, 223],
  ["Blackbog's Sharp", 2, 1, 46, 101, 172],
  ['Blackhand Key', 2, 1, 49, 241, 144],
  ["Blackhorn's Face", 2, 1, 49, 134, 209],
  ['Blackleach Blade', 2, 1, 50, 50, 180],
  ['Blackoak Shield', 3, 1, 67, 346, 254],
  ['Blacktongue', 1, 1, 35, 34, 37],
  ['Blade of Ali Baba', 2, 1, 43, 540, 159],
  ['Bladebone', 1, 1, 20, 156, 3],
  ['Bladebuckle', 1, 1, 39, 404, 117],
  ['Blastbark', 1, 1, 38, 343, 67],
  ["Blinkbat's Form", 1, 1, 16, 321, 81],
  ['Blood Crescent', 1, 1, 10, 449, 27],
  ["Blood Raven's Charge", 3, 1, 79, 357, 334],
  ['Bloodfist', 1, 1, 12, 277, 104],
  ['Bloodletter', 2, 1, 38, 224, 156],
  ['Bloodmoon', 3, 1, 69, 168, 291],
  ['Bloodrise', 1, 1, 20, 375, 21],
  ['Bloodthief', 1, 1, 23, 67, 46],
  ['Bloodtree Stump', 2, 1, 56, 560, 154],
  ['Boneflame', 3, 1, 80, 509, 343],
  ['Boneflesh', 1, 1, 35, 403, 89],
  ['Bonehew', 3, 1, 72, 381, 389],
  ['Boneshade', 3, 1, 84, 327, 353],
  ['Boneslayer Blade', 2, 1, 50, 231, 139],
  ['Bonesnap', 1, 1, 32, 361, 24],
  ['Brainhew', 1, 1, 34, 242, 9],
  ["Bul-Kathos' Wedding Band", 0, 1, 66, 422, 270],
  ['Buriza-Do Kyanon', 2, 1, 59, 26, 200],
  ["Butcher's Pupil", 2, 1, 47, 106, 132],
  ['Bverrit Keep', 1, 1, 26, 534, 101],
  ['Carin Shard', 2, 1, 43, 399, 142],
  ['Carrion Wind', 0, 3, 68, 422, 380],
  ["Cerebus' Bite", 3, 1, 71, 55, 312],
  ['Chance Guards', 1, 1, 20, 86, 105],
  ['Chromatic Ire', 2, 1, 43, 79, 187],
  ['Cliffkiller', 2, 1, 49, 320, 195],
  ['Cloudcrack', 2, 1, 53, 236, 167],
  ['Coif of Glory', 1, 1, 19, 281, 74],
  ['Coldkill', 2, 1, 44, 267, 131],
  ['Coldsteel Eye', 2, 1, 39, 131, 157],
  ['Corpsemourn', 2, 1, 63, 386, 224],
  ['Crainte Vomir', 2, 1, 50, 171, 164],
  ['Cranebeak', 3, 1, 71, 572, 385],
  ['Crescent Moon', 0, 5, 58, 7, 273],
  ['Crow Caw', 2, 1, 45, 528, 216],
  ['Crown of Ages', 3, 1, 86, 117, 346],
  ['Crown of Thieves', 2, 1, 57, 238, 208],
  ['Crushflange', 1, 1, 12, 347, 20],
  ["Culwen's Point", 1, 1, 39, 574, 33],
  ["Bartuc's Cut-Throat", 2, 1, 50, 253, 288],
  ['Dark Clan Crusher', 2, 1, 42, 129, 145],
  ['Darkforce Spawn', 3, 1, 72, 56, 332],
  ['Darkglow', 1, 1, 19, 423, 84],
  ['Darksight Helm', 2, 1, 46, 33, 206],
  ['Death Cleaver', 3, 1, 78, 49, 316],
  ["Death's Fathom", 3, 1, 81, 152, 356],
  ["Death's Web", 3, 1, 74, 546, 301],
  ['Deathbit', 2, 1, 52, 39, 293],
  ['Deathspade', 1, 1, 12, 23, 2],
  ['Demon Limb', 3, 1, 71, 544, 298],
  ['Demon Machine', 2, 1, 57, 100, 201],
  ["Demon's Arch", 3, 1, 76, 29, 342],
  ["Demonhorn's Edge", 3, 1, 69, 145, 327],
  ["Dimoak's Hew", 1, 1, 11, 32, 49],
  ['Djinn Slayer', 3, 1, 73, 21, 292],
  ['Doombringer', 3, 1, 75, 90, 262],
  ['Doomslinger', 1, 1, 38, 421, 71],
  ["Dracul's Grasp", 3, 1, 84, 549, 366],
  ['Dragonscale', 3, 1, 84, 590, 349],
  ["Duriel's Shell", 2, 1, 49, 130, 218],
  ['Duskdeep', 1, 1, 23, 207, 75],
  ['Dwarf Star', 0, 10, 53, 422, 276],
  ['Eaglehorn', 3, 1, 77, 123, 267],
  ['Earth Shifter', 3, 1, 77, 526, 387],
  ['Earthshaker', 2, 1, 51, 41, 153],
  ['Endlesshail', 2, 1, 44, 157, 193],
  ["Eschuta's Temper", 3, 1, 80, 167, 369],
  ['Ethereal Edge', 3, 1, 82, 479, 326],
  ["Executioner's Justice", 3, 1, 83, 226, 317],
  ['Felloak', 1, 1, 4, 108, 15],
  ["Firelizard's Talons", 3, 1, 75, 184, 370],
  ['Flamebellow', 3, 1, 79, 27, 355],
  ['Fleshrender', 2, 1, 46, 30, 149],
  ['Fleshripper', 3, 1, 76, 181, 306],
  ['Frostburn', 1, 1, 39, 214, 107],
  ['Frostwind', 3, 1, 78, 126, 367],
  ["Gargoyle's Bite", 3, 1, 78, 578, 322],
  ["Gerke's Sanctuary", 2, 1, 52, 390, 230],
  ["Gheed's Fortune", 0, 1, 70, 237, 361],
  ['Ghostflame', 3, 1, 70, 323, 335],
  ['Ghoulhide', 2, 1, 44, 275, 236],
  ['Giant Skull', 3, 1, 73, 61, 381],
  ['Gimmershred', 3, 1, 78, 204, 337],
  ["Ginther's Rift", 2, 1, 45, 151, 160],
  ['Gleamscythe', 1, 1, 18, 178, 29],
  ["Gloom's Trap", 2, 1, 45, 363, 246],
  ['Goblin Toe', 1, 1, 30, 334, 111],
  ['Goldskin', 1, 1, 38, 208, 92],
  ['Goldstrike Arch', 2, 1, 54, 232, 197],
  ['Goldwrap', 1, 1, 36, 273, 116],
  ['Gore Rider', 2, 1, 55, 559, 243],
  ['Gorefoot', 1, 1, 12, 274, 109],
  ['Goreshovel', 1, 1, 19, 69, 7],
  ['Gravenspine', 1, 1, 27, 62, 13],
  ['Gravepalm', 2, 1, 39, 468, 235],
  ['Greyform', 1, 1, 10, 413, 80],
  ["Griffon's Eye", 3, 1, 84, 147, 338],
  ["Grim's Burning Dead", 2, 1, 52, 257, 184],
  ["Griswold's Edge", 1, 1, 23, 70, 31],
  ['Guardian Angel', 2, 1, 53, 519, 220],
  ['Guardian Naga', 2, 1, 56, 379, 135],
  ['Gull', 1, 1, 6, 133, 40],
  ['Gut Siphon', 3, 1, 79, 137, 295],
  ["Halaberd's Reign", 3, 1, 85, 115, 363],
  ['Hand of Blessed Light', 2, 1, 50, 154, 148],
  ['Harlequin Crest', 3, 1, 69, 464, 250],
  ['Hawkmail', 1, 1, 20, 445, 85],
  ["Head Hunter's Glory", 3, 1, 83, 538, 392],
  ['Headstriker', 2, 1, 47, 44, 161],
  ['Heart Carver', 2, 1, 44, 425, 171],
  ["Heaven's Light", 3, 1, 69, 365, 373],
  ['Heavenly Garb', 1, 1, 39, 333, 94],
  ['Hellcast', 1, 1, 36, 276, 70],
  ['Hellclap', 1, 1, 36, 477, 66],
  ['Hellmouth', 2, 1, 55, 564, 238],
  ['Hellplague', 1, 1, 30, 342, 32],
  ['Hellrack', 3, 1, 84, 110, 330],
  ['Hellslayer', 3, 1, 71, 135, 256],
  ['Herald of Zakarum', 2, 1, 50, 223, 287],
  ['Hexfire', 2, 1, 41, 465, 158],
  ["Highlord's Wrath", 0, 5, 73, 7, 278],
  ['Homunculus', 2, 1, 50, 283, 282],
  ['Hone Sundan', 2, 1, 45, 588, 177],
  ["Horizon's Tornado", 3, 5, 72, 453, 308],
  ['Hotspur', 1, 1, 7, 65, 108],
  ['Howltusk', 1, 1, 34, 245, 77],
  ['Humongous', 1, 1, 39, 219, 10],
  ['Husoldal Evo', 2, 1, 52, 46, 183],
  ['Iceblink', 1, 1, 30, 500, 88],
  ['Ichorsting', 1, 1, 24, 119, 69],
  ['Infernostride', 2, 1, 37, 142, 239],
  ['Iron Pelt', 2, 1, 41, 535, 214],
  ['Ironstone', 1, 1, 36, 565, 23],
  ['Islestrike', 2, 1, 51, 542, 133],
  ['Jade Talon', 3, 1, 74, 585, 310],
  ["Jalal's Mane", 2, 1, 50, 533, 289],
  ['Kelpie Snare', 2, 1, 41, 212, 175],
  ["Kinemil's Awl", 1, 1, 31, 221, 36],
  ["Kira's Guardian", 2, 1, 85, 527, 359],
  ['Knell Striker', 1, 1, 7, 448, 16],
  ['Kuko Shakaku', 2, 1, 41, 78, 192],
  ['Lacerator', 3, 1, 76, 577, 323],
  ['Lance Guard', 2, 1, 43, 31, 233],
  ['Lance of Yaggai', 1, 1, 30, 492, 47],
  ['Langer Briser', 2, 1, 40, 14, 198],
  ['Lava Gout', 2, 1, 50, 40, 237],
  ['Leadcrow', 1, 1, 12, 329, 68],
  ['Lenymo', 1, 1, 10, 443, 113],
  ['Leviathan', 3, 1, 73, 312, 319],
  ['Lidless Wall', 2, 1, 49, 258, 232],
  ['Lightsabre', 3, 5, 66, 400, 261],
  ["Lycander's Aim", 2, 1, 50, 80, 284],
  ["Lycander's Flank", 2, 1, 50, 82, 285],
  ['Maelstrom', 1, 1, 19, 589, 12],
  ['Magefist', 1, 1, 31, 330, 106],
  ['Magewrath', 2, 1, 51, 429, 196],
  ['Manald Heal', 0, 15, 20, 422, 122],
  ["Mang Song's Lesson", 3, 1, 86, 16, 324],
  ["Mara's Kaleidoscope", 0, 5, 80, 7, 274],
  ['Marrowwalk', 3, 1, 74, 64, 372],
  ["Medusa's Gaze", 3, 1, 84, 0, 351],
  ["Messerschmidt's Reaver", 3, 1, 75, 89, 257],
  ['Metalgrid', 0, 2, 85, 7, 377],
  ['Moonfall', 2, 1, 50, 299, 151],
  ["Moser's Blessed Circle", 2, 1, 39, 426, 227],
  ['Nagelring', 0, 15, 10, 422, 121],
  ["Nature's Peace", 0, 3, 77, 422, 302],
  ['Nightsmoke', 1, 1, 27, 47, 115],
  ["Nightwing's Veil", 3, 1, 75, 498, 345],
  ['Nokozan Relic', 0, 20, 14, 7, 118],
  ["Nord's Tenderizer", 3, 1, 76, 539, 386],
  ["Nosferatu's Coil", 3, 1, 68, 550, 376],
  ["Ondal's Wisdom", 3, 1, 74, 166, 390],
  ["Ormus' Robes", 3, 1, 83, 160, 360],
  ['Peasant Crown', 2, 1, 36, 566, 203],
  ['Pelta Lunata', 1, 1, 3, 71, 95],
  ['Pierre Tombale Couant', 2, 1, 51, 389, 182],
  ['Plague Bearer', 2, 1, 49, 432, 162],
  ['Pluckeye', 1, 1, 10, 472, 60],
  ["Pompeii's Wrath", 2, 1, 53, 120, 134],
  ['Pus Spitter', 2, 1, 44, 478, 199],
  ["Que-Hegan's Wisdom", 2, 1, 59, 348, 225],
  ["Radament's Sphere", 2, 1, 58, 10, 231],
  ['Rainbow Facet', 0, 1, 85, 305, 401],
  ['Rainbow Facet', 0, 1, 85, 305, 400],
  ['Rainbow Facet', 0, 1, 85, 305, 399],
  ['Rainbow Facet', 0, 1, 85, 305, 398],
  ['Rainbow Facet', 0, 1, 85, 305, 397],
  ['Rainbow Facet', 0, 1, 85, 305, 396],
  ['Rainbow Facet', 0, 1, 85, 305, 395],
  ['Rainbow Facet', 0, 1, 85, 305, 394],
  ['Rakescar', 1, 1, 36, 557, 5],
  ['Rattlecage', 1, 1, 39, 233, 91],
  ['Raven Claw', 1, 1, 20, 340, 62],
  ['Raven Frost', 0, 10, 53, 422, 277],
  ['Ravenlore', 3, 1, 82, 483, 352],
  ["Razor's Edge", 3, 1, 75, 530, 296],
  ['Razorswitch', 2, 1, 36, 306, 185],
  ['Razortail', 2, 1, 39, 466, 245],
  ['Razortine', 1, 1, 16, 536, 45],
  ['Ribcracker', 2, 1, 39, 411, 186],
  ['Riphook', 2, 1, 39, 417, 191],
  ['Ripsaw', 1, 1, 35, 188, 38],
  ["Rixot's Keen", 1, 1, 3, 476, 26],
  ['Rockfleece', 1, 1, 38, 186, 90],
  ['Rockstopper', 2, 1, 39, 441, 204],
  ["Rogue's Bow", 1, 1, 27, 114, 63],
  ['Rune Master', 3, 1, 80, 173, 315],
  ['Rusthandle', 1, 1, 23, 240, 17],
  ['Sandstorm Trek', 3, 1, 72, 447, 371],
  ["Saracen's Chance", 0, 5, 55, 7, 279],
  ["Schaefer's Hammer", 3, 1, 83, 325, 259],
  ["Seraph's Hymn", 0, 3, 73, 7, 304],
  ['Serpent Lord', 1, 1, 12, 341, 56],
  ['Shadow Dancer', 3, 1, 79, 377, 311],
  ['Shadow Killer', 3, 1, 85, 38, 336],
  ['Shadowfang', 1, 1, 16, 543, 34],
  ['Shaftstop', 2, 1, 46, 362, 217],
  ['Silks of the Victor', 1, 1, 38, 8, 93],
  ['Silkweave', 2, 1, 44, 364, 241],
  ['Skewer of Krintiz', 1, 1, 14, 435, 28],
  ['Skin of the Flayed One', 2, 1, 39, 141, 213],
  ['Skin of the Vipermagi', 2, 1, 37, 459, 212],
  ['Skull Collector', 2, 1, 49, 431, 189],
  ['Skull Splitter', 1, 1, 28, 367, 4],
  ["Skullder's Ire", 2, 1, 50, 434, 219],
  ['Skystrike', 2, 1, 36, 163, 190],
  ['Snakecord', 1, 1, 16, 328, 114],
  ['Snowclash', 2, 1, 49, 36, 247],
  ['Soul Drainer', 3, 1, 82, 548, 314],
  ['Soul Harvest', 1, 1, 26, 457, 51],
  ['Soulfeast Tine', 2, 1, 43, 563, 176],
  ['Soulflay', 1, 1, 26, 105, 35],
  ['Sparking Mail', 1, 1, 23, 87, 86],
  ['Spectral Shard', 1, 1, 34, 51, 43],
  ['Spellsteel', 2, 1, 47, 45, 137],
  ['Spike Thorn', 3, 1, 78, 52, 365],
  ['Spineripper', 2, 1, 40, 405, 170],
  ['Spire of Honor', 2, 1, 47, 316, 178],
  ['Spire of Lazarus', 1, 1, 24, 228, 57],
  ['Spirit Forge', 2, 1, 43, 335, 215],
  ['Spirit Keeper', 3, 1, 75, 162, 329],
  ['Spirit Ward', 3, 1, 76, 575, 358],
  ['Stealskull', 2, 1, 43, 77, 205],
  ['Steel Carapace', 3, 1, 74, 462, 350],
  ['Steel Pillar', 3, 1, 77, 568, 344],
  ['Steel Shade', 3, 1, 70, 17, 299],
  ['Steelclash', 1, 1, 23, 309, 100],
  ['Steeldriver', 1, 1, 39, 246, 25],
  ['Steelgoad', 1, 1, 19, 554, 50],
  ['Steelrend', 3, 1, 78, 382, 393],
  ['Stone Crusher', 3, 4, 76, 325, 309],
  ['Stoneraven', 3, 1, 72, 360, 318],
  ['Stormchaser', 2, 1, 43, 456, 228],
  ['Stormeye', 1, 1, 31, 569, 18],
  ['Stormguild', 1, 1, 18, 319, 97],
  ['Stormlash', 3, 1, 86, 453, 362],
  ['Stormrider', 2, 1, 49, 516, 138],
  ['Stormshield', 3, 1, 77, 374, 255],
  ['Stormspike', 2, 1, 49, 504, 173],
  ['Stormspire', 3, 1, 78, 222, 266],
  ['Stormstrike', 1, 1, 34, 471, 64],
  ['Stoutnail', 1, 1, 7, 496, 19],
  ['String of Ears', 2, 1, 37, 144, 244],
  ['Suicide Branch', 2, 1, 41, 72, 141],
  ['Sureshrill Frost', 2, 1, 47, 189, 150],
  ['Swordback Hold', 1, 1, 20, 497, 99],
  ['Swordguard', 2, 1, 55, 174, 169],
  ['Tarnhelm', 1, 1, 20, 482, 73],
  ['Tearhaunch', 1, 1, 39, 254, 112],
  ["Templar's Might", 3, 8, 82, 436, 368],
  ['The Atlantean', 2, 1, 50, 11, 163],
  ['The Battlebranch', 1, 1, 34, 406, 52],
  ["The Cat's Eye", 0, 5, 58, 7, 271],
  ['The Centurion', 1, 1, 19, 265, 82],
  ['The Chieftain', 1, 1, 26, 35, 8],
  ['The Cranium Basher', 3, 1, 85, 526, 260],
  ['The Diggler', 1, 1, 15, 153, 41],
  ['The Dragon Chang', 1, 1, 11, 491, 44],
  ['The Eye of Etlich', 0, 5, 20, 7, 119],
  ['The Face of Horror', 1, 1, 27, 356, 79],
  ['The Fetid Sprinkler', 2, 1, 46, 285, 147],
  ['The Gavel of Pain', 2, 1, 53, 355, 155],
  ["The General's Tan Do Li Ga", 1, 1, 28, 187, 22],
  ["The Gladiator's Bane", 3, 1, 85, 581, 252],
  ['The Gnasher', 1, 1, 7, 263, 1],
  ['The Grandfather', 3, 1, 85, 109, 263],
  ['The Grim Reaper', 1, 1, 39, 570, 54],
  ['The Hand of Broc', 1, 1, 7, 322, 103],
  ['The Impaler', 2, 1, 39, 571, 174],
  ['The Iron Jang Bong', 1, 1, 38, 573, 59],
  ['The Jade Tan Do', 1, 1, 26, 313, 42],
  ['The Mahim-Oak Curio', 0, 10, 34, 7, 120],
  ['The Meat Scraper', 2, 1, 49, 338, 179],
  ['The Minotaur', 2, 1, 53, 9, 140],
  ['The Oculus', 2, 1, 50, 515, 286],
  ['The Patriarch', 1, 1, 39, 249, 39],
  ["The Reaper's Toll", 3, 1, 83, 521, 328],
  ['The Redeemer', 3, 1, 80, 365, 391],
  ['The Rising Sun', 0, 5, 73, 7, 272],
  ['The Salamander', 1, 1, 28, 43, 58],
  ['The Scalper', 2, 1, 65, 206, 290],
  ['The Spirit Shroud', 2, 1, 36, 215, 211],
  ['The Stone of Jordan', 0, 1, 39, 422, 123],
  ['The Tannr Gorerod', 1, 1, 36, 401, 48],
  ['The Vile Husk', 2, 1, 52, 541, 166],
  ['The Ward', 1, 1, 35, 234, 102],
  ["Thundergod's Vigor", 2, 1, 55, 558, 248],
  ['Thunderstroke', 3, 1, 77, 358, 340],
  ["Tiamat's Rebuke", 2, 1, 46, 158, 229],
  ["Titan's Revenge", 2, 1, 50, 81, 283],
  ['Todesfaelle Flamme', 2, 1, 54, 593, 168],
  ['Tomb Reaver', 3, 1, 86, 125, 300],
  ['Toothrow', 2, 1, 56, 469, 221],
  ['Torch of Iro', 1, 1, 7, 556, 11],
  ['Treads of Cthon', 1, 1, 20, 85, 110],
  ['Twitchthroe', 1, 1, 22, 506, 83],
  ["Tyrael's Might", 3, 1, 87, 436, 313],
  ['Umbral Disk', 1, 1, 12, 487, 96],
  ["Ume's Lament", 1, 1, 38, 259, 14],
  ['Undead Crown', 1, 1, 39, 121, 78],
  ['Valkyrie Wing', 2, 1, 52, 579, 207],
  ['Vampire Gaze', 2, 1, 49, 256, 210],
  ['Veil of Steel', 3, 1, 77, 498, 251],
  ['Venom Grip', 2, 1, 37, 143, 234],
  ['Venom Ward', 1, 1, 27, 68, 87],
  ["Verdungo's Hearty Cord", 3, 1, 71, 372, 378],
  ['Viperfork', 3, 1, 79, 354, 325],
  ['Visceratuant', 2, 1, 36, 136, 226],
  ['Wall of the Eyeless', 1, 1, 27, 60, 98],
  ['War Traveler', 2, 1, 50, 37, 242],
  ["Warlord's Trust", 2, 1, 43, 366, 136],
  ['Warpspear', 2, 1, 47, 235, 188],
  ['Warshrike', 3, 1, 83, 580, 294],
  ['Waterwalk', 2, 1, 40, 467, 240],
  ['Widowmaker', 3, 1, 73, 576, 333],
  ['Windforce', 3, 1, 80, 290, 268],
  ['Windhammer', 3, 1, 76, 383, 339],
  ['Wisp Projector', 0, 1, 84, 422, 321],
  ['Witchwild String', 2, 1, 47, 473, 194],
  ['Witherstring', 1, 1, 18, 287, 61],
  ['Wizardspike', 3, 1, 69, 59, 264],
  ['Wizendraw', 1, 1, 35, 339, 65],
  ['Woestave', 1, 1, 38, 262, 53],
  ['Wolfhowl', 3, 1, 85, 211, 357],
  ['Wormskull', 1, 1, 28, 58, 76],
  ['Wraith Flight', 3, 1, 84, 216, 388],
  ["Zakarum's Hand", 2, 1, 45, 430, 146],
];

export const sets = [
  "Aldur's Watchtower",
  'Angelic Raiment',
  "Arcanna's Tricks",
  'Arctic Gear',
  "Berserker's Arsenal",
  "Bul-Kathos' Children",
  "Cathan's Traps",
  "Civerb's Vestments",
  "Cleglaw's Brace",
  "Cow King's Leathers",
  "Death's Disguise",
  "Griswold's Legacy",
  "Heaven's Brethren",
  "Hsarus' Defense",
  "Hwanin's Majesty",
  'Immortal King',
  'Infernal Tools',
  "Iratha's Finery",
  "Isenhart's Armory",
  "M'avina's Battle Hymn",
  "Milabrega's Regalia",
  "Naj's Ancient Vestige",
  "Natalya's Odium",
  "Orphan's Call",
  "Sander's Folly",
  "Sazabi's Grand Tribute",
  "Sigon's Complete Steel",
  "Tal Rasha's Wrappings",
  "Tancred's Battlegear",
  'The Disciple',
  "Trang-Oul's Avatar",
  "Vidala's Rig",
  0,
];

export const dropCalcSetItems: [string, number, number, number, number, number][] = [
  ["Aldur's Advance", 0, 7, 29, 37, 2],
  ["Aldur's Deception", 0, 7, 29, 462, 2],
  ["Aldur's Rhythm", 0, 7, 29, 299, 2],
  ["Aldur's Stony Gaze", 0, 7, 29, 288, 2],
  ['Angelic Halo', 1, 3, 17, 422, 1],
  ['Angelic Mantle', 1, 7, 17, 423, 1],
  ['Angelic Sickle', 1, 7, 17, 435, 1],
  ['Angelic Wings', 1, 7, 17, 7, 1],
  ["Arcanna's Deathwand", 2, 7, 20, 573, 1],
  ["Arcanna's Flesh", 2, 7, 20, 333, 1],
  ["Arcanna's Head", 2, 7, 20, 482, 1],
  ["Arcanna's Sign", 2, 1, 20, 7, 0],
  ['Arctic Binding', 3, 7, 3, 328, 1],
  ['Arctic Furs', 3, 7, 3, 413, 1],
  ['Arctic Horn', 3, 7, 3, 477, 1],
  ['Arctic Mitts', 3, 7, 3, 330, 1],
  ["Berserker's Hatchet", 4, 7, 5, 156, 1],
  ["Berserker's Hauberk", 4, 7, 5, 500, 1],
  ["Berserker's Headgear", 4, 7, 5, 281, 1],
  ["Bul-Kathos' Sacred Charge", 5, 7, 50, 109, 3],
  ["Bul-Kathos' Tribal Guardian", 5, 7, 50, 378, 3],
  ["Cathan's Mesh", 6, 7, 15, 87, 1],
  ["Cathan's Rule", 6, 7, 15, 43, 1],
  ["Cathan's Seal", 6, 7, 15, 422, 0],
  ["Cathan's Sigil", 6, 7, 15, 7, 0],
  ["Cathan's Visage", 6, 7, 15, 356, 1],
  ["Civerb's Cudgel", 7, 7, 13, 240, 1],
  ["Civerb's Icon", 7, 7, 13, 7, 0],
  ["Civerb's Ward", 7, 7, 13, 319, 1],
  ["Cleglaw's Claw", 8, 7, 6, 487, 1],
  ["Cleglaw's Pincers", 8, 7, 6, 86, 1],
  ["Cleglaw's Tooth", 8, 7, 6, 342, 1],
  ["Cow King's Hide", 9, 7, 20, 506, 1],
  ["Cow King's Hooves", 9, 7, 20, 274, 1],
  ["Cow King's Horns", 9, 7, 20, 566, 2],
  ['Credendum', 29, 7, 39, 372, 3],
  ["Dangoon's Teaching", 12, 7, 55, 419, 3],
  ['Dark Adherent', 29, 7, 39, 160, 3],
  ["Death's Guard", 10, 7, 8, 443, 1],
  ["Death's Hand", 10, 7, 8, 322, 1],
  ["Death's Touch", 10, 7, 8, 574, 1],
  ["Griswold's Heart", 11, 7, 44, 386, 2],
  ["Griswold's Honor", 11, 7, 44, 553, 3],
  ["Griswold's Redemption", 11, 7, 44, 73, 3],
  ["Griswold's Valor", 11, 7, 44, 117, 3],
  ["Guillaume's Face", 23, 7, 41, 579, 2],
  ["Haemosu's Adamant", 12, 7, 55, 130, 2],
  ["Hsarus' Iron Fist", 13, 7, 4, 71, 1],
  ["Hsarus' Iron Heel", 13, 7, 4, 85, 1],
  ["Hsarus' Iron Stay", 13, 7, 4, 47, 1],
  ["Hwanin's Blessing", 14, 7, 28, 47, 2],
  ["Hwanin's Justice", 14, 7, 28, 50, 2],
  ["Hwanin's Refuge", 14, 7, 28, 528, 2],
  ["Hwanin's Splendor", 14, 7, 28, 238, 2],
  ["Immortal King's Detail", 15, 7, 37, 558, 2],
  ["Immortal King's Forge", 15, 7, 37, 564, 2],
  ["Immortal King's Pillar", 15, 7, 37, 559, 2],
  ["Immortal King's Soul Cage", 15, 7, 37, 436, 3],
  ["Immortal King's Stone Crusher", 15, 7, 37, 383, 3],
  ["Immortal King's Will", 15, 7, 37, 22, 1],
  ['Infernal Cranium', 16, 7, 7, 75, 1],
  ['Infernal Sign', 16, 7, 7, 273, 1],
  ['Infernal Torch', 16, 7, 7, 259, 1],
  ["Iratha's Coil", 17, 7, 21, 121, 1],
  ["Iratha's Collar", 17, 7, 21, 7, 0],
  ["Iratha's Cord", 17, 7, 21, 273, 1],
  ["Iratha's Cuff", 17, 7, 21, 330, 1],
  ["Isenhart's Case", 18, 7, 11, 68, 1],
  ["Isenhart's Horns", 18, 7, 11, 207, 1],
  ["Isenhart's Lightbrand", 18, 7, 11, 70, 1],
  ["Isenhart's Parry", 18, 7, 11, 234, 1],
  ['Laying of Hands', 29, 7, 39, 66, 2],
  ["M'avina's Caster", 19, 7, 21, 239, 3],
  ["M'avina's Embrace", 19, 7, 21, 312, 3],
  ["M'avina's Icy Clutch", 19, 7, 21, 40, 2],
  ["M'avina's Tenet", 19, 7, 21, 466, 2],
  ["M'avina's True Sight", 19, 7, 21, 147, 3],
  ["Magnus' Skin", 23, 7, 41, 468, 2],
  ["Milabrega's Diadem", 20, 7, 23, 121, 1],
  ["Milabrega's Orb", 20, 7, 23, 309, 1],
  ["Milabrega's Robe", 20, 7, 23, 8, 1],
  ["Milabrega's Rod", 20, 7, 23, 569, 1],
  ["Naj's Circlet", 21, 7, 43, 102, 1],
  ["Naj's Light Plate", 21, 7, 43, 279, 3],
  ["Naj's Puzzler", 21, 7, 43, 166, 3],
  ["Natalya's Mark", 22, 7, 22, 452, 3],
  ["Natalya's Shadow", 22, 7, 22, 344, 3],
  ["Natalya's Soul", 22, 7, 22, 364, 2],
  ["Natalya's Totem", 22, 7, 22, 256, 2],
  ["Ondal's Almighty", 12, 7, 55, 498, 3],
  ['Rite of Passage', 29, 7, 39, 142, 2],
  ["Sander's Paragon", 24, 3, 20, 75, 1],
  ["Sander's Riprap", 24, 7, 20, 274, 1],
  ["Sander's Superstition", 24, 7, 20, 62, 1],
  ["Sander's Taboo", 24, 7, 20, 277, 1],
  ["Sazabi's Cobalt Redeemer", 25, 7, 34, 126, 3],
  ["Sazabi's Ghost Liberator", 25, 7, 34, 28, 3],
  ["Sazabi's Mental Sheath", 25, 7, 34, 33, 2],
  ["Sigon's Gage", 26, 7, 9, 214, 1],
  ["Sigon's Guard", 26, 7, 9, 534, 1],
  ["Sigon's Sabot", 26, 7, 9, 254, 1],
  ["Sigon's Shelter", 26, 7, 9, 233, 1],
  ["Sigon's Visor", 26, 7, 9, 245, 1],
  ["Sigon's Wrap", 26, 7, 9, 404, 1],
  ["Taebaek's Glory", 12, 7, 55, 575, 3],
  ["Tal Rasha's Adjudication", 27, 1, 26, 7, 0],
  ["Tal Rasha's Fine-Spun Cloth", 27, 7, 26, 363, 2],
  ["Tal Rasha's Guardianship", 27, 7, 26, 315, 3],
  ["Tal Rasha's Horadric Crest", 27, 7, 26, 134, 2],
  ["Tal Rasha's Lidless Eye", 27, 7, 26, 515, 2],
  ["Tancred's Crowbill", 28, 7, 27, 367, 1],
  ["Tancred's Hobnails", 28, 7, 27, 65, 1],
  ["Tancred's Skull", 28, 7, 27, 58, 1],
  ["Tancred's Spine", 28, 7, 27, 208, 1],
  ["Tancred's Weird", 28, 7, 27, 7, 0],
  ['Telling of Beads', 29, 1, 39, 7, 0],
  ["Trang-Oul's Claws", 30, 7, 32, 275, 2],
  ["Trang-Oul's Girth", 30, 7, 32, 537, 3],
  ["Trang-Oul's Guise", 30, 7, 32, 61, 3],
  ["Trang-Oul's Scales", 30, 7, 32, 91, 2],
  ["Trang-Oul's Wing", 30, 7, 32, 74, 2],
  ["Vidala's Ambush", 31, 7, 19, 321, 1],
  ["Vidala's Barb", 31, 7, 19, 339, 1],
  ["Vidala's Fetlock", 31, 7, 19, 334, 1],
  ["Vidala's Snare", 31, 7, 19, 7, 0],
  ["Whitstan's Guard", 23, 7, 41, 426, 2],
  ["Wilhelm's Pride", 23, 7, 41, 36, 2],
];

import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { IonButton, IonContent, IonIcon, IonLabel, IonSearchbar } from '@ionic/react';
import { caretDown, closeSharp } from 'ionicons/icons';
import { ItemType } from '../../providers/ItemProvider/ItemProvider';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { setPickItemModal } from '../../../store/app/appSlice';
import { Gem, Item } from '../../types';
import { selectorPickItemModal } from '../../../store/app/selectors';

interface Props {
  isOpen: boolean;
  onClick: () => void;
  onDismiss: () => void;
  label?: string;
  selected: {
    name: string;
    type: ItemType;
  };
  items: Item[];
  onSelect?: (item: Item) => void;
  presentingElement?: HTMLElement;
  multipleSelect?: {
    onSelect: (items: Item[]) => void;
    selectedIds: number[];
  };
  disabled?: boolean;
  classOverride?: string;
  filters?: {
    quality?: boolean;
    handed?: boolean;
    slot?: boolean;
  };
}

export const PickItem: React.FC<Props> = props => {
  const { isOpen, onClick, onDismiss, label, selected, items, onSelect, multipleSelect, disabled, classOverride, filters } = props;
  const dispatch = useAppDispatch();
  const pickItemModal = useAppSelector(selectorPickItemModal);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        setPickItemModal({
          onDismiss,
          items,
          onSelect,
          itemType: selected.type,
          multipleSelect,
          filters,
        }),
      );
    } else {
      onDismiss();
      dispatch(setPickItemModal(undefined));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!pickItemModal) onDismiss();
  }, [pickItemModal]);

  return (
    <>
      <SelectedItem onClick={() => (disabled ? undefined : onClick())}>
        {label && <IonLabel className={disabled ? 'disabled' : undefined}>{label}</IonLabel>}
        <div className={classOverride ? classOverride : selected.type}>{selected.name}</div>
        <IonIcon color={disabled ? 'medium' : undefined} icon={caretDown} />
      </SelectedItem>
    </>
  );
};

export interface PickItemProps {
  onDismiss: () => void;
  items: Item[];
  onSelect?: (item: Item) => void;
  multipleSelect?: {
    onSelect: (items: Item[]) => void;
    selectedIds: number[];
  };
  itemType: ItemType;
  filters?: {
    quality?: boolean;
    handed?: boolean;
    slot?: boolean;
  };
}

export const PickItemModal: React.FC<PickItemProps> = ({ onDismiss, items, onSelect, itemType, multipleSelect }) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [multiple, setMultiple] = useState<number[]>([]);
  const [touchMoveDisabled, setTouchMoveDisabled] = useState(false);

  const filtered = useMemo(() => {
    if (!items) return [];
    return items.filter(item => item.name.toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : ''));
  }, [items, searchTerm]);

  useEffect(() => {
    if (multipleSelect) setMultiple(multipleSelect.selectedIds);
  }, [multipleSelect]);

  useLayoutEffect(() => {
    setTouchMoveDisabled(true);
  }, []);

  const handleClick = (item: Item) => {
    if (onSelect) {
      onSelect(item);
      onDismiss();
      setTouchMoveDisabled(false);
    } else if (multipleSelect) {
      if (multiple.includes(item.id)) {
        setMultiple(prev => prev.filter(id => id !== item.id));
      } else {
        setMultiple(prev => [...prev, item.id]);
      }
    }
  };

  const handleConfirmSelection = () => {
    if (multipleSelect) {
      multipleSelect.onSelect(items.filter(item => multiple.includes(item.id)));
      setTouchMoveDisabled(false);
      onDismiss();
    }
  };

  return (
    <IonContent className={'ion-padding'} style={{ '--background': 'black' }}>
      <Container>
        <Header>
          <div>
            {multipleSelect && (
              <IonButton onClick={handleConfirmSelection} style={{ marginLeft: '8px' }}>
                Save
              </IonButton>
            )}
            <IonSearchbar
              animated={true}
              placeholder={'Search name...'}
              mode={'ios'}
              color={'medium'}
              onIonInput={e => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSearchTerm(e.target?.value as string);
              }}
            />

            <IonIcon icon={closeSharp} color={'light'} size={'large'} onClick={onDismiss} style={{ marginRight: '8px' }} />
          </div>

          {/*{filters && (*/}
          {/*  <div>*/}
          {/*    {filters.quality && (*/}
          {/*      <div>*/}
          {/*        <IonCheckbox className={'white'}>Normal</IonCheckbox>*/}
          {/*        <IonCheckbox className={'white'}>Exceptional</IonCheckbox>*/}
          {/*        <IonCheckbox className={'white'}>Elite</IonCheckbox>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}
        </Header>
        <ItemWrapper onTouchMove={e => (touchMoveDisabled ? e.stopPropagation() : undefined)}>
          {filtered.map((item, i) => (
            <ItemD
              key={`item-${item.name}-${i}`}
              className={itemType !== 'gem' ? itemType : ''}
              style={itemType === 'gem' ? { color: `var(--${(item as unknown as Gem).type})` } : undefined}
              onClick={() => handleClick(item)}
              selected={multiple.includes(item.id)}
            >
              {item.name}
            </ItemD>
          ))}
        </ItemWrapper>
      </Container>
    </IonContent>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: visible;
  //background: black;
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #222;
  display: flex;
  flex-direction: column;
  gap: 2px;

  > div {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  max-height: calc(60vh - 80px);
  position: fixed;
  left: 0;
  top: 74px;
  overflow: auto;
  //background: black;
  z-index: 10000;
`;

const ItemD = styled.div<{ selected: boolean }>`
  padding: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  text-align: center;
  z-index: 10000;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 37px;
  box-sizing: border-box;

  & + & {
    border-bottom: 1px solid #222;
  }

  ${({ selected }) =>
    selected &&
    css`
      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('/images/icons/check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        filter: brightness(100%) invert(1);
      }
    `}
`;

const SelectedItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: white;
  gap: 10px;

  > div:first-of-type {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
    flex-grow: 1;
  }
`;

import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useIonToast } from '@ionic/react';
import { HolyGrail, HolyGraiLContext } from '../../../common/providers/HolyGrailProvider/HolyGrailProvider';
import { useAppSelector } from '../../../store/store';
import {
  selectorArmorBases,
  selectorRunewords,
  selectorSets,
  selectorUniques,
  selectorWeaponBases,
} from '../../../store/activeData/selector';
import { MuleContext } from '../../../common/providers/MuleProvider/MuleProvider';
import { ItemContext } from '../../../common/providers/ItemProvider/ItemProvider';
import { MISC_ITEMS } from '../../../common/data/misc-items/misc_items';
import { RuneContext } from '../../../common/providers/RuneProvider/RuneProvider';
import { ALL_GEMS } from '../../../common/data/gems/gems';

export const RestoreLegacyData: React.FC = () => {
  const [addMuledItemsToGrail, setAddMuleItemsToGrail] = useState(true);
  const [addMuledRunesToCalc, setAddMuledRunesToCalc] = useState(true);
  const { addGrailItems } = useContext(HolyGraiLContext);
  const { insertMule } = useContext(MuleContext);
  const { setCount } = useContext(RuneContext);
  const { addItem } = useContext(ItemContext);
  const [toast] = useIonToast();
  const uniques = useAppSelector(selectorUniques);
  const sets = useAppSelector(selectorSets);
  const baseWeapons = useAppSelector(selectorWeaponBases);
  const baseArmors = useAppSelector(selectorArmorBases);
  const runewords = useAppSelector(selectorRunewords);
  const parseData = async (data: string) => {
    try {
      const parsed = JSON.parse(data);
      await parseGrailItems(parsed);
      await parseMules(parsed);
      await parseRunes(parsed);
    } catch (e) {
      console.error(e);
      await toast({ message: 'Failed to parse data', duration: 2000 });
    }
  };

  const parseGrailItems = async (parsed: any) => {
    try {
      if (Object.hasOwn(parsed, 'grail')) {
        const grail = parsed.grail;
        const items: HolyGrail[] = [];
        for (const item of grail) {
          for (const key in item) {
            if (uniques.some(u => u.id === item[key])) {
              items.push({ id: -1, item_id: item[key], item_type: 'unique' });
            } else if (sets.some(s => s.id === item[key])) {
              items.push({ id: -1, item_id: item[key], item_type: 'set' });
            }
          }
        }
        if (items.length) {
          await addGrailItems(items);
          await toast({ message: `Added ${items.length} grail items`, duration: 2000 });
        }
      }
    } catch (e) {
      await toast({ message: 'Failed to parse grail items', duration: 2000 });
    }
  };

  const parseMules = async (parsed: any) => {
    if (Object.hasOwn(parsed, 'mules')) {
      const mules = parsed.mules;
      let itemCount = 0;
      let muleCount = 0;
      for (const mule of mules) {
        const mule_id = await insertMule(mule.name);
        if (mule_id) {
          muleCount++;
          for (const item of mule.items) {
            switch (item.quality) {
              case 'unique': {
                const unique = uniques.find(u => u.id === item.itemId);
                if (unique) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: 'unique',
                      item_id: unique.id,
                      name: unique.name,
                      base_id: null,
                      base_name: null,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                    if (addMuledItemsToGrail) {
                      await addGrailItems([{ id: -1, item_type: 'unique', item_id: unique.id }]);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'set': {
                const set = sets.find(s => s.id === item.itemId);
                if (set) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: 'set',
                      item_id: set.id,
                      name: set.name,
                      base_id: null,
                      base_name: null,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                    if (addMuledItemsToGrail) {
                      await addGrailItems([{ id: -1, item_type: 'set', item_id: set.id }]);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'magic':
              case 'rare': {
                const base = item.isWeapon ? baseWeapons.find(b => b.id === item.itemId) : baseArmors.find(b => b.id === item.itemId);
                if (base) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: item.quality,
                      item_id: base.id,
                      name: base.name,
                      base_id: base.id,
                      base_name: null,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'base': {
                const type = item.isWeapon ? 'base_weapon' : 'base_armor';
                const base = item.isWeapon ? baseWeapons.find(b => b.id === item.itemId) : baseArmors.find(b => b.id === item.itemId);
                if (base) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: type,
                      item_id: base.id,
                      name: base.name,
                      base_id: null,
                      base_name: null,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'misc': {
                const misc = MISC_ITEMS.find(m => m.id === item.itemId);
                if (misc) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: 'misc',
                      item_id: misc.id,
                      name: misc.name,
                      base_id: null,
                      base_name: null,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'runeword': {
                const base = item.isWeapon ? baseWeapons.find(b => b.id === item.itemId) : baseArmors.find(b => b.id === item.itemId);
                const runeword = runewords.find(rw => rw.name === item.name);
                if (base && runeword) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: 'runeword',
                      item_id: runeword.id,
                      name: item.name,
                      base_id: base.id,
                      base_name: base.name,
                      ethereal: item.ethereal,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;
                  } catch (e) {
                    console.error(e);
                  }
                }
                break;
              }
              case 'gem':
              case 'rune': {
                const gem = ALL_GEMS.find(g => g.name === item.name);
                if (gem) {
                  try {
                    await addItem({
                      id: -1,
                      mule_id,
                      mule_name: mule.name,
                      item_type: item.quality,
                      item_id: gem.id,
                      name: item.name,
                      base_id: null,
                      base_name: null,
                      ethereal: false,
                      sockets: null,
                      props: item.props,
                    });
                    itemCount++;

                    if (item.quality === 'rune' && addMuledRunesToCalc) {
                      await setCount(item.itemId, 1);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }
              }
            }
          }
        }
      }
      await toast({ message: `Added ${muleCount} mules and ${itemCount} items`, duration: 2000 });
    }
  };

  const parseRunes = async (parsed: any) => {
    if (Object.hasOwn(parsed, 'runewordCalcState')) {
      const state = JSON.parse(parsed.runewordCalcState);
      const runeCount = state.runeCount;
      let count = 0;
      for (let i = 0; i < runeCount.length; i++) {
        if (runeCount[i] > 0) {
          await setCount(i, runeCount[i]);
          await timer(300);
          count += runeCount[i];
        }
      }
      await toast({ message: `Added ${count} runes`, duration: 2000 });
    }
  };

  return (
    <Container>
      <TextArea placeholder={'Paste legacy backup data here...'} onChange={e => parseData(e.target.value)} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 25vh;
  background: #8b8888;
  padding: 10px;

  &::placeholder {
    color: white;
  }
`;

const timer = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

import React, { useState } from 'react';
import { IonIcon, useIonRouter } from '@ionic/react';
import { Runeword } from '../../types';
import styled, { css } from 'styled-components/macro';
import { star, starOutline } from 'ionicons/icons';
import { Rune } from '../Rune';
import { appRoutes } from '../../../AppRoutes';
import { ItemCard, ItemStats, ItemTitle } from '../StyledComponents';
import { LocalStorage } from '../../Storage';

interface Props {
  runeword: Runeword;
  forceRuneInStock?: boolean;
}

export const ViewRuneword: React.FC<Props> = ({ runeword, forceRuneInStock }) => {
  const [isFave, setIsFave] = useState(LocalStorage.getFaveRuneword(runeword.id));
  const router = useIonRouter();

  const handleFaveClick = () => {
    LocalStorage.setFaveRuneword(runeword.id);
    setIsFave(prev => !prev);
  };

  return (
    <ItemCard onClick={() => router.push(appRoutes.runewordBases.path(runeword.id))}>
      <Title color={'var(--unique)'}>
        <div>{runeword.name}</div>
        <IonIcon
          icon={isFave ? star : starOutline}
          onClick={e => {
            e.stopPropagation();
            handleFaveClick();
          }}
        />
      </Title>

      <LadderOnly isLadder={runeword.isLadderOnly} />

      <RuneOrder order={runeword.runeOrder} />

      <Runes>
        {runeword.runeOrderInt.map((id, i) => (
          <Rune size={'30px'} key={`${runeword.name}-${i}-${id}`} id={id} forceInStock={forceRuneInStock} />
        ))}
      </Runes>

      <ItemStats>{runeword.stats}</ItemStats>

      <Info>
        <div>
          {runeword.basetype.join(', ')} ({runeword.sockets})
        </div>
        <div>Level: {runeword.cLvlReq}</div>
      </Info>
    </ItemCard>
  );
};

const Title = styled(ItemTitle)`
  justify-content: space-between;

  &:before {
    content: '';
    width: 20px;
  }
`;

const LadderOnly = styled.div<{ isLadder: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: var(--ion-color-danger);
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isLadder }) =>
    isLadder &&
    css`
      &:before {
        content: 'LADDER ONLY';
      }
    `}
`;

const RuneOrder = styled.div<{ order: string }>`
  width: 100%;
  color: var(--rune);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '${props => props.order}';
  }
`;

const Runes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  > div:first-of-type {
    color: var(--base);
  }

  > div:last-of-type {
    color: #f52828;
    font-weight: 700;
  }
`;
